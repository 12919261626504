<div class="patient-group-header">
  <h3 class="patient-group-title">
    Edit groups for patient
  </h3>
  <button class="btn patient-group-close" (click)="handleCancel()">
    <span class="material-icons">close</span>
  </button>
</div>

<div class="patient-group-content">
  <div class="patient-group-badge" *ngIf="patientGroups">
    <div class="patient-group-badge__item" [class.active]="checkGroupLinkToPatient(group.id)"
         *ngFor="let group of patientGroups" (click)="selectOrDeselectGroup(group)">
      <span class="material-icons">{{checkGroupLinkToPatient(group.id) ? 'done' : 'close'}}</span>
      <span>{{group.name}}</span>
    </div>
  </div>
</div>

<div class="patient-group-footer">
  <button class="btn btn-done" (click)="submitChangeGroup()">
    Done
  </button>
</div>
