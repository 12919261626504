// src/app/auth/role-guard.service.ts
import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
// import { routerNgProbeToken } from '@angular/router/src/router_module';
import {APIService} from '../api/api.service';
import {catchError, map, mergeMap, retry} from 'rxjs/operators';
import {Observable, of } from 'rxjs';
import {CommsModule} from '../utility/comms/comms.module';
import {SpinnerService} from '../utility/loading-spinner/spinner.service';
import {CalendarEventService} from '../booking-calendar/services/calendar-event.service';
import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarWidgetAuthGuardService implements CanActivate {
  // eslint-disable-next-line no-useless-constructor
  public constructor (
      public apiService: APIService,
      private comms: CommsModule,
      private router: Router,
      private spinnerService: SpinnerService,
      private calendarService: CalendarEventService,
      private configService: AppConfigService,
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      @Inject('SESSIONSTORAGE') private sessionStorage: any) {
  }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public canActivate (route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    const enabled = this.configService.getConfig().features.BookingCalender
                  && this.configService.getConfig().features.BookingCalender.ShowStaffBookingUrl;
    if (!enabled) {
      return false;
    }
    const calendarToken = route.params.calendarToken;
    if (!calendarToken) {
      sessionStorage.clear();
      this.router.navigateByUrl('message');
      return of(false);
    }

    this.spinnerService.startLoading();

    this.comms.setPage({ pageTitle: 'Services', page: 'Calendar', pageIcon: '' });

    return this.apiService.getAuthCalendarTokenAsync(calendarToken)
      .pipe(
        mergeMap(value => {
          if (!value.accessToken) {
            throw new Error('Invalid token');
          }
          sessionStorage.clear();
          this.sessionStorage.setItem('calendarAccessToken', value.accessToken);
          this.sessionStorage.setItem('pharmacyId', value.pharmacyId);
          console.log('widget was authenticated');
          return this.apiService.retrievePharmacySettings(value.pharmacyId);
        }),
        retry(3),
        mergeMap(pharmacySettings => {
          this.calendarService.setPharmacyInfo(pharmacySettings);
          return this.calendarService.loadResources(pharmacySettings.id);
        }),
        
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        map(_ => true),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError((err) => {
          sessionStorage.clear();
          this.router.navigateByUrl('message');
          window.location.reload();
          return of(false);
        }),
      );
  }
}

