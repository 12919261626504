// src/app/auth/role-guard.service.ts
import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import { AuthenticationService } from './authentication.service';
// import { routerNgProbeToken } from '@angular/router/src/router_module';
import { AppConfigService, DisplayMode } from '../app-config.service';
import { DialogService, DialogResponse } from '../utility/base-dialog/dialog.service';
import { CommsModule } from '../utility/comms/comms.module';
@Injectable({
    providedIn: 'root',
  })
export class RoleGuardService implements CanActivate {
  currentComponent = null;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  configSettings: any = null;
  logOut = false;
  constructor(
      public authenticator: AuthenticationService,
        private config: AppConfigService,
        public router: Router,
        public dialogService: DialogService,
        private commsModule: CommsModule) {
            this.commsModule.getConfigSettingAccess().subscribe(settings => {
                this.configSettings = settings;
            });
        }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
//    console.log('Checking Roleguard.canActivate');
    let pageContentSaved = true;
    // If the page supports interface IHaveEditableContent
    // then pageContentSaved = page.isContentSaved
    const redirectToSelf = (this.router.url === `/${route.routeConfig.path}`) && this.logOut === false;

    if (this.currentComponent && this.currentComponent.isContentSaved && !redirectToSelf) {
        pageContentSaved = this.currentComponent.isContentSaved();
    }

    const groups = this.authenticator.getUserGroups() || [];
    // console.log('User groups are', groups);
    let allowedToAccess = false;

    if ( groups.indexOf('GodMode') >= 0 || groups.indexOf('Pharmacy') >= 0) {
//        console.log('Halleluja');
        allowedToAccess = true;
    }
    let expectedRoles = route.data.expectedRoles;

    if (expectedRoles === null || expectedRoles === undefined) {
        expectedRoles = [];
    }

    // console.log('Expected roles:', expectedRoles);

    const token = this.authenticator.getAccessToken();
    if (token == null) {
//        console.log('no access token');
        allowedToAccess = allowedToAccess || route.data.displayWhileAuthenticating || false;
    }

    const enableKey = route.data.enableKey;
    let featureLocked = false;
    // check the enable key agains the conf to make sure if access is allowed in this domain.
    if (this.configSettings != null && this.configSettings.features != null && this.configSettings.features.IncludeMenus != null) {
        const enabled = this.configSettings.features.IncludeMenus[enableKey] || false;
//        console.log(enableKey, ': ', enabled );
        if (!enabled) {
//            console.log('Sorry, the ' + enableKey + ' feature is not enabled in this region');
            featureLocked = true;
            allowedToAccess = false;
        }
    }

    if ( groups.indexOf('Prescriber') >= 0 ) {
        this.config.setDisplayMode(DisplayMode.Prescriber);
    }

    if (allowedToAccess === false && featureLocked === false) {
        for (const role of expectedRoles) {
            if ( role && groups.indexOf(role) >= 0 ) {
//                console.log('found matching role');
                allowedToAccess = true;
                break;
            }
        }
    }
    if (allowedToAccess === false) {
//        console.log('No access allowed for this page');
        if (this.currentComponent != null && this.currentComponent.WarnNoPermissionToLeave != null  && !redirectToSelf) {
            this.currentComponent.WarnNoPermissionToLeave(expectedRoles, groups);
        }
        if (route.data.failTo === undefined || route.data.failTo === null) {
//            console.log('don\'t jump to this page');
            return false;
        }
//        console.log('Redirect to page',[route.data.failTo]);
        this.router.navigate([route.data.failTo], );
        return true;
    }


    if (!pageContentSaved) {
        if (this.currentComponent != null && this.currentComponent.WarnUnsavedData != null) {
            return this.currentComponent.WarnUnsavedData();
        } else {
            const confirmationString = ['You have unsaved data on the page - are you sure you want to leave without saving?'];
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
            return new Promise<any>((resolve, reject) => {
                this.dialogService.dialog.closeAll();
                this.dialogService.openConfirmationDialog(confirmationString, 'Leave', 'Cancel').then((response) => {
                    resolve(response === DialogResponse.confirm);
                });
            });
        // DialogResponse.confirm
        }
    }
//    console.log('access allowed.');
    return true;
  }
}
