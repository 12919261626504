import { AddressModel } from './GenericApiModels';


export class PharmacyModel {
  id: number;
  systemCode: string;
  systemKey: string;
  name: string;
  phone: string;
  email: string;
  fax: string;
  address: AddressModel;
  logoURL: string;
  externalUpdateDate: Date;
  fields: PharmacyModelFields;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  skin: any;
  hours: string;
}

export class PharmacyModelFields {
  onotes: string;
  web: string;
  dispensesoftware: string;
  open: string;
  gpApprovedAction: string;
  overduesetting: string;
  hoursuntilscriptoverdue: string;
  pcomms;
  sbook: string;
}
