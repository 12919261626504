import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastContainerDirective, ToastrService} from 'ngx-toastr';
import {EventResourceModel} from 'src/app/api/ApiRecordTypes/EventResourceModel';
import {BasicDialogComponent} from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import {CalendarDialogData} from 'src/app/booking-calendar/models/view-models/layout-calendar-dialog.view-model';
import {CalendarDialogService} from 'src/app/booking-calendar/services/calendar-dialog.service';
import {CalendarEventService} from 'src/app/booking-calendar/services/calendar-event.service';
import {DialogResponse} from 'src/app/utility/base-dialog/general-dialog/general-dialog.component';
import {from, of, throwError} from 'rxjs';
import {catchError, first, mergeMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-edit-resource-dialog',
  templateUrl: './edit-resource-dialog.component.html',
  styleUrls: ['./edit-resource-dialog.component.css']
})
export class EditResourceDialogComponent extends BasicDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  //#region Properties

  @ViewChild('deleteResourceContent', {static: true})
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public confirmDeleteDialogContent: TemplateRef<any>;

  @ViewChildren('resourceTemplate')
  public resourcesChildren: QueryList<ElementRef>;

  @ViewChild(ToastContainerDirective)
  toastContainer: ToastContainerDirective;

  public recentResourceClickedDelete: EventResourceModel;

  public palette = [
    '#000',
    '#444',
    '#666',
    '#999',
    '#ccc',
    '#eee',
    '#f3f3f3',
    '#fff',
    '#f4cccc',
    '#fce5cd',
    '#fff2cc',
    '#d9ead3'
  ];

  //#endregion

  //#region Form
  public resourceArrayControl: UntypedFormArray;

  public resourceForm: UntypedFormGroup;

  public resources: EventResourceModel[] = [];

  private pharmacyId: number;

  //#endregion

  //#region Accessors

  //#endregion

  //#region Constructor
  public constructor(
    protected router: Router,
    protected calendarService: CalendarEventService,
    protected toastr: ToastrService,
    protected dialogService: CalendarDialogService,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any
  ) {
    super(router);
    this.acceptButtonTitle = 'Done';

    this.resourceArrayControl = new UntypedFormArray([]);

    this.resourceForm = new UntypedFormGroup({
      resourceArray: this.resourceArrayControl
    });

  }

  //#endregion
  public ngOnInit() {
    this.toastr.overlayContainer = this.toastContainer;
    const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
    if (!accountSetting || !accountSetting.id) {
      return;
    }

    this.pharmacyId = accountSetting.id;
  }

  public ngAfterViewInit(): void {
    this.toastr.overlayContainer = this.toastContainer;
  }

  //#region Life cycle

  //#endregion

  //#region Methods
  public setData(data: CalendarDialogData) {
    super.setData(data);
    if (!data || !data.settings) {
      return;
    }

    if (data.settings.resources) {
      this.resources = data.settings.resources;
    }

    this.resources.forEach(resource => {
      this.resourceArrayControl.push(new UntypedFormControl(resource.title, [Validators.required]));
    });
  }

  //#endregion

  public handleChangeResourceName(resource: EventResourceModel, resourceControl: AbstractControl): void {
    if (!resourceControl.value) {
      return;
    }

    if (resource.title === resourceControl.value) {
      resourceControl.setErrors(null);
      return;
    }

    const resourceNames = this.resources.map(r => r.title && r.title.toLowerCase());
    if (resourceNames.includes(resourceControl.value.toLowerCase())) {
      resourceControl.setErrors({uniqueName: true});
    }
  }

  public addNewResource(): void {
    this.resourceArrayControl.push(new UntypedFormControl('', [Validators.required]));
    const newResource = new EventResourceModel();
    newResource.id = 0;
    newResource.pharmacyId = this.pharmacyId;
    newResource.color = '#008000';
    this.resources.push(newResource);
    const scrollToNewResourceSubscription = this.resourcesChildren.changes.pipe(first()).subscribe(changes => {
      changes.last.nativeElement.scrollIntoView(false);
    });
    this._subscription.add(scrollToNewResourceSubscription);
  }

  public saveResourceName(resource: EventResourceModel, newName: string): void {
    if (!resource || !newName) {
      return;
    }
    resource.pharmacyId = this.pharmacyId;
    if (resource.id && resource.title === newName) {
      return;
    }
    const resourceNames = this.resources.map(r => r.title && r.title.toLowerCase());
    if (resourceNames.includes(newName.toLowerCase())) {
      return;
    }
    resource.title = newName;
    resource.supplemental = `{color}${resource.color}{/color}`;

    const updateResourceSubscription = this.calendarService.updateResources(resource).subscribe(
      id => {
        if (id < 0) {
          this.showToastMessage('An error occurred', 'custom-toast-error');
          return;
        }
        if (!resource.id) {
          resource.id = id;
          this.showToastMessage('Resource is created', 'custom-toast-success');
          return;
        }
        this.showToastMessage('Resource is updated', 'custom-toast-success');
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _ => this.showToastMessage('An error occurred', 'custom-toast-error')
    );
    this._subscription.add(updateResourceSubscription);
  }

  public deleteResource(resource: EventResourceModel): void {
    if (!resource) {
      return;
    }
    const deletedResourceIndex = this.resources.findIndex(r => r.id === resource.id);
    if (!resource.id) {
      this.resources.splice(deletedResourceIndex, 1);
      this.resourceArrayControl.removeAt(deletedResourceIndex);
      return;
    }
    const setting = {content: this.confirmDeleteDialogContent, cancelButtonTitle: 'No', acceptButtonTitle: 'Yes, delete'};
    const openConfirmDialogPromise = this.dialogService.openConfirmDialog(setting);
    const deleteResourceSubscription = from(openConfirmDialogPromise).pipe(
      mergeMap(response => {
        if (response === DialogResponse.confirm) {
          return this.calendarService.deleteEventResourceById(resource.id);
        }
        return throwError(DialogResponse.cancel);
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap(_ => {
        this.showToastMessage('Resource is removed', 'custom-toast-success');
        this.resources.splice(deletedResourceIndex, 1);
        this.resourceArrayControl.removeAt(deletedResourceIndex);
      }),
      catchError(error => {
        if (error !== DialogResponse.cancel) {
          this.showToastMessage('An error occurred', 'custom-toast-error');
        }
        return of(null);
      })
    ).subscribe();
    this._subscription.add(deleteResourceSubscription);
  }

  public colorPicked(color: string, resource: EventResourceModel): void {
    if (!resource || !resource.id) {
      resource.color = color;
      return;
    }

    if (resource.color === color) {
      return;
    }
    resource.color = color;
    resource.pharmacyId = this.pharmacyId;
    resource.supplemental = `{color}${resource.color}{/color}`;
    const updateResourceSubscription = this.calendarService.updateResources(resource).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _ => {
        this.showToastMessage('Resource is updated', 'custom-toast-success');
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _ => this.showToastMessage('An error occurred', 'custom-toast-error')
    );
    this._subscription.add(updateResourceSubscription);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private showToastMessage(message: string, toastClass: string, timeOut = 3000): any {
    this.toastr.clear();
    const positionClass = new Date().getTime().toString() + Math.random().toString();
    return this.toastr.show(message, null, {toastClass, timeOut, positionClass});
  }

  public handleAccept () {
    this.calendarService.setToast('Resource Updated');
    super.handleCancel();
  }
}
