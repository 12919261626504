import { Directive, ElementRef, Input, HostListener, AfterViewInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SortSelector } from './filtertypes';

@Directive({selector: '[appSorter]'})
export class SortControlDirective implements SortSelector, AfterViewInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appSorter') private sortName: string;
  @Input() private controlSet = 'default';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('default') private defaultValue = '';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value') private sortValue = '';

  private controlType: string;
  private lastChanged: number;
  private changesubscribers: Subject<SortSelector> = new Subject<SortSelector>();

  constructor(
    private el: ElementRef,
  ) {
    this.controlType = this.el.nativeElement.tagName.toUpperCase();
    this.sortValue = this.el.nativeElement.value;
  }

  ngAfterViewInit() {
    this.sortValue = this.el.nativeElement.value;
    if (this.defaultValue !== '' && (this.sortValue === '')) {
      this.sortValue = this.defaultValue;
    }

  }
  Clear() {
    this.sortValue = this.defaultValue;
    if (this.defaultValue !== '') {
      this.lastChanged = undefined;
    }
    if (this.controlType === 'SELECT') {
      this.el.nativeElement.value = this.defaultValue;
    }
  }

  LastChanged() {
    return this.lastChanged;
  }

  GetValue() {
    return this.sortValue;
  }

  GetName() {
    return this.sortName;
  }

  GetSet() {
    return this.controlSet;
  }

  OnChanged(): Observable<SortSelector> {
    return this.changesubscribers;
  }

  @HostListener('change', ['$event']) onChanged(event) {
    if (event.target.id === 'dayLeft') {
      this.lastChanged = Date.now();
      this.sortValue = event.target.value;
      this.changesubscribers.next(this);
    } else {
      if (event.target.value !== this.sortValue) {
        this.lastChanged = Date.now();
        this.sortValue = event.target.value;
        this.changesubscribers.next(this);
      }
    }
  }
}
