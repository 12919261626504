import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-msg-loadingrequest',
  templateUrl: './loadingrequest.component.html',
  styleUrls: ['./loadingrequest.component.css']
})
export class LoadingRequestComponent {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {
  }
}
