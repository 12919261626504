<div>
	<div class="Icon">
	</div>
	<div class="Title">
	  {{Title}}
	</div>
	<div *ngFor="let line of BodyText; let last = last" class="mainMessage" id="mainDialogMessage">
	  {{line}}<br/>
	</div>
	<div class="buttonBar">
		<button id='dialogCancelButton' class="inverse-btn submit-btn" *ngIf="HasCancel" type="button"  (click)="HandleCancel()">{{CancelText}}</button>
		<button id='dialogConfirmButton' class="btn-orange submit-btn" *ngIf="HasAccept" type="button"  (click)="HandleAccept()">{{AcceptText}}</button>
	</div>
</div>
