import { ViewEncapsulation, Component, ViewChild, Inject } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { IAPIService } from 'src/app/api/api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/utility/base-dialog/general-dialog/general-dialog.component';
import { BehaviorSubject } from 'rxjs';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-emergency-access',
  templateUrl: './emergency-access.component.html',
})

export class EmergencyAccessComponent {

  emergencyAccessReason: string = null;
  validEmergencyAccessReason$: BehaviorSubject<string> = new BehaviorSubject(null);

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  constructor(
      public dialogRef: MatDialogRef<EmergencyAccessComponent>,
      private api: IAPIService,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      }

  validateEmergencyAccessReason(): boolean {
    if (this.emergencyAccessReason) {
      this.validEmergencyAccessReason$.next(null);
      return true;
    } else {
      this.validEmergencyAccessReason$.next('You must enter an emergency access reason');
      return false;
    }
  }

  onUpdateMedication() {
    if (this.validateEmergencyAccessReason()) {
      this.dialogRef.close({emergencyAccessReason: this.emergencyAccessReason});
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
