import { AddressModel } from './GenericApiModels';
import { PracticePharmacyCommunicationChannels } from './PracticeRecord';
import { PharmacyModel } from './PharmacyRecord';

export enum PatientRequestFulfilment {
  OnPremise = '0',
  Delivery = '1',
}

export class PendingTaskRecord {
  id: number;
  orderNo: string;
  type: string;
  status: string;
  statusCode: string;
  createDateUtc?: Date;
  patient: EnrichedPatientModel;
  taskData: PendingTaskPatientRequestData;
  jSonTaskData: string;
  practice: PharmacyPracticeModel;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  actionsAvailable?: any;
  fulfilment: PatientRequestFulfilment;
}

export class PatientModel {
  address: AddressModel;
  carerCode_MedAdvisorGUID: string;
  carerName: string;
  carerPhone: string;
  carerStatus: number;
  caringFor: number;
  careeName: string;
  title: string;
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  phone: string;
  externalSystemCode: string;
  patientCode_MedAdvisorGUID: string;
  dateOfBirth: Date;
  createDate: Date;
  patientCode: string;
  practice: PharmacyPracticeModel;
  id: number;
  isGuest: boolean;
  isConnectedToIM1: boolean;
}

// Add feilds that can be populated with enriched data
export class EnrichedPatientModel extends PatientModel {
  formattedPhone: string;
  fullName: string;
  formattedFirstName: string;
  formattedLastName: string;

}

export class PublicAddressModel {
  line1: string;
  line2: string;
  suburb: string;
  city: string;
  country: string;
  postCode: string;
}

export class PharmacyPracticeModel {
  id: number;
  name: string;
  channel: string;
}

export class PendingTaskPatientRequestData {
  practice: PracticeModel;
  drugs: DrugModel[];
  patientRequest: PatientRequestModel;
  communicationChannels: PracticePharmacyCommunicationChannels;
}

export class PatientRequestModel {
  id: number;
  patientId: number;
  patientCode_MedAdvisorGuid: string
  type: number;
  status: number;
  fulfilment: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  properties: any;
  createDate: Date;
  pharmacy: PharmacyModel;
  deliveryAddress: AddressModel;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  charge: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  chargeAdjustments: any;
  notes: string;
  components: PatientRequestComponentModel[];
  price: number;
}

export class DrugModel {
  id: number;
  labelName: string;
  activeName: string;
  strength: string;
  form: string;
  status: string;
  packetSize: number;
  systemCode: string;
  systemKey: string;
  properties: {
    hasrepeats?: string;
    supply?: number;
    daysleft?: number;
    refillnumber?: number;
    numberofrefill?: number; // how many more times the patient can get a refill without needing to involve a doctor.
    instructionsread?: string;
    deterministic?: string;
    source?: number;
    repeatcycle?: number;
    repeatcyclefrequency?: string;
  };
  type: 0;
  supply: number;
}
export class DrugSearchModel {
  labelName: string;
  activeName: string;
  labelOrActiveName: string;
  pageNumber: number;
  numberPerPage: number;
  sortOrder: string;
  properties: {
    isMobileSearch: string
  };
}
export class PatientRequestComponentModel {
  patientId: number;
  patientCode_MedAdvisorGuid: string
  quantity: number;
  properties: string;
  type: number;
  unitPrice: number;
  taxRate: number;
  grossAmount: number;
  netAmount: number;
  discountDescription: string;
  discountReference: string;
  drug?: DrugModel;
}
export class PracticeModel {
  id: number;
  systemCode: string;
  systemKey: string;
  name: string;
  addressId: number;
  phone: string;
  fax: string;
  email: string;
  logoUrl: string;
  preferences: {
    notes: string;
    IM1_SoftwareProviderPreference: string;
  };
  address: AddressModel;
}
