<app-basic-dialog
  [dialogTitle]="headerTitle"
  [buttonAcceptTitle]="acceptButtonTitle"
  [buttonCancel]="cancelButtonTitle"
  (OnComplete)="listenAction($event)">
  <div class="resource-time">
    <div class="resource-time__wrapper">
      <div class="resource-time__single">
        <div class="d-flex resource-time__single-time">
          <div class="resource-time__single-choose-time">
            <label>Start time</label>
            <timepicker (paste)="handleTimePaste($event)" (keydown)="handleTimeInput($event)" [(ngModel)]="startTime"
                        [formControl]="startTimeControl"></timepicker>
            <p class="resource-time__input-error" *ngIf="startTimeControl.dirty && startTimeControl.errors?.required">
              Please enter start time
            </p>
          </div>
          <div class="resource-time__single-to">to</div>
          <div class="resource-time__single-choose-time">
            <label>End time</label>
            <timepicker (paste)="handleTimePaste($event)" (keydown)="handleTimeInput($event)" [(ngModel)]="endTime"
                        [formControl]="endTimeControl"></timepicker>
            <p class="resource-time__input-error" *ngIf="endTimeControl.dirty && endTimeControl.errors?.required">
              Please enter end time
            </p>
          </div>
        </div>
        <p class="resource-time__input-error"
           *ngIf="(startTimeControl.dirty || endTimeControl.dirty) && timeForm.errors?.mustGreaterThanStartTime">
          Please specify an End Time after the Start Time.
        </p>
        <div class="resource-time__single-date">
          <label>Date</label>
          <div class="d-flex">
            <mat-form-field appearance="outline">
              <input matInput (paste)="handlePasteDate($event)" (keydown)="handleDateInput($event)"
                     [formControl]="startDateControl" [matDatepicker]="picker" placeholder="dd/mm/yyyy">
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>event</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker panelClass="date-picker-add-time" #picker></mat-datepicker>
            </mat-form-field>
            <div class="make-recurring-section">
              <label>Make recurring</label>
              <mat-checkbox color="primary" [(ngModel)]="isRecurringChecked"></mat-checkbox>
            </div>
          </div>
          <p class="resource-time__input-error" *ngIf="startDateControl.touched && startDateControl.errors as errors">
            <ng-container *ngIf="errors?.required && !errors?.matDatepickerParse">
              Please enter an {{resource.availabilityType ? 'available' : 'unavailable'}} date
            </ng-container>
            <ng-container *ngIf="errors?.matDatepickerParse">
              {{resource.availabilityType ? 'Available' : 'Unavailable'}} date is invalid. Please enter a valid format eg
              dd/mm/yyyy
            </ng-container>
          </p>
          <p class="resource-time__input-success"
             *ngIf="startDateControl.touched && !startDateControl.errors && startDateControl.value">
            {{resource.availabilityType ? 'Available' : 'Unavailable'}}  date is valid
          </p>
        </div>
      </div>
      <div class="resource-time__recurring" *ngIf="isRecurringChecked">
        <div class="d-flex resource-time__recurring-header">
          <div class="resource-time__recurring-pattern">
            <label>Recurrence pattern</label>
            <mat-form-field class="width-100" appearance="outline">
              <mat-select panelClass="select-recurrence-panel"
                          [(ngModel)]="recurrenceType"
                          (selectionChange)="handleRecurrenceTypeChange()"
                          [disableOptionCentering]="true">
                <mat-option [value]="item.value" *ngFor="let item of timeList; let i = index">{{item.text}}</mat-option>
              </mat-select>
              <mat-icon matSuffix>expand_more</mat-icon>
            </mat-form-field>
          </div>
          <div class="resource-time__recurring-every">
            <label>Recur every</label>
            <div class="d-flex">
                <input
                       #recurEvery
                       type="number"
                       min="1" max="100"
                       [(ngModel)]="timeList[recurrenceType].recurEvery"
                       (focusout)="handleRecurEvery(timeList[recurrenceType])"
                       placeholder="0">
                <div class="wrapper-indicator">
                  <span class="mat-elevation-z0 up"  (click)="recurEvery.value = toString(+recurEvery.value + 1)">
                    <mat-icon>expand_less</mat-icon>
                  </span>
                  <span class="mat-elevation-z0 down" (click)="recurEvery.value = toString(+recurEvery.value - 1)">
                    <mat-icon>expand_more</mat-icon>
                  </span>
                </div>
<!--              <input type="number" min="1" max="100" (focusout)="handleRecurEvery(timeList[recurrenceType])"-->
<!--                     [(ngModel)]="timeList[recurrenceType].recurEvery">-->
              <label>{{recurEveryUnits[recurrenceType]}}</label>
            </div>
          </div>
        </div>
        <div class="resource-time__recurring-body">
          <label *ngIf="recurrenceType !== 0">Recur on</label>
          <ng-container [ngSwitch]="recurrenceType">
            <!-- Weekly -->
            <ng-container *ngSwitchCase="1">
              <div class="weekly-wrapper d-flex">
                <div class="weekly-item" *ngFor="let day of dayList">
                  <mat-checkbox color="primary" (change)="atLeastOneDayError = false"
                                [(ngModel)]="day.isSelected">{{day.text}}</mat-checkbox>
                </div>
              </div>
              <p class="resource-time__input-error" *ngIf="atLeastOneDayError">
                Please specify at least one day of the week.
              </p>
            </ng-container>
            <!-- Monthly -->
            <ng-container *ngSwitchCase="2">
              <div class="monthly-wrapper d-flex">
                <div class="monthly-item" *ngFor="let date of dateOfMonth">
                  <mat-checkbox color="primary" (change)="atLeastOneDateError = false"
                                [(ngModel)]="date.isSelected">{{date.text}}</mat-checkbox>
                </div>
              </div>
              <p class="resource-time__input-error" *ngIf="atLeastOneDateError">
                Please specify at least one day of the month.
              </p>
            </ng-container>
            <!-- Yearly -->
            <ng-container *ngSwitchCase="3">
              <div class="yearly-wrapper d-flex">
                <div class="yearly-item" *ngFor="let month of monthList">
                  <mat-checkbox color="primary" (change)="atLeastOneMonthError = false"
                                [(ngModel)]="month.isSelected">{{month.text}}</mat-checkbox>
                </div>
              </div>
              <p class="resource-time__input-error" *ngIf="atLeastOneMonthError">
                Please specify at least one month of the year.
              </p>
            </ng-container>
          </ng-container>
        </div>
        <div class="resource-time__recurring-footer">
          <label>End occurrence after (Leave empty if no after)</label>
          <mat-form-field appearance="outline" >
            <input matInput (paste)="handlePasteDate($event)" (keydown)="handleDateInput($event)"
                   [formControl]="endDateControl" [matDatepicker]="endPicker" placeholder="dd/mm/yyyy">
            <mat-datepicker-toggle matSuffix [for]="endPicker">
              <mat-icon matDatepickerToggleIcon>event</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker panelClass="date-picker-add-time" #endPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <p class="resource-time__input-error" *ngIf="endDateControl.touched && endDateControl.errors as errors">
          <ng-container *ngIf="errors?.matDatepickerParse">
            {{resource.availabilityType ? 'Available' : 'Unavailable'}} date is invalid. Please enter a valid format eg
            dd/mm/yyyy
          </ng-container>
        </p>
        <p class="resource-time__input-error" *ngIf="dateForm.errors?.mustGreaterThanEndDate">
          Please specify an End Date after the Start Date.
        </p>
        <p class="resource-time__input-success"
           *ngIf="endDateControl.touched && !dateForm.errors && !endDateControl.errors && endDateControl.value">
          {{resource.availabilityType ? 'Available' : 'Unavailable'}}  date is valid
        </p>
      </div>
    </div>
  </div>
</app-basic-dialog>

<ng-template #dialogTitleAddUnavailable>
  <h3 class="custom-row-header__left">
    Add a time the resource <b>IS UNAVAILABLE</b>
  </h3>
</ng-template>

<ng-template #dialogTitleAddAvailable>
  <h3 class="custom-row-header__left">
    Add a time the resource <b>IS AVAILABLE</b>
  </h3>
</ng-template>
