import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IAPIService } from 'src/app/api/api.service';
import { PatientModel } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-patient-invite-success',
  templateUrl: './patient-invite-success.component.html',
  styleUrls: ['./patient-invite-success.component.scss']
})
export class PatientInviteSuccessComponent implements OnInit {
  @Input() patientFirstName: string;
  @Input() patientLastName: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Input() patientGUID: any;
  @Input() carerPatient: PatientModel;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Input() screenMode: any;
  @Input() code: string;
  message = '';
  message$ = new BehaviorSubject<string>(this.message);
  constructor(private router: Router, private api: IAPIService, private configService: AppConfigService) {}

  ngOnInit() {
    this.initialisePatientSMS();
  }
  initialisePatientSMS() {
    this.configService.loadCurrentPharmacySettings().subscribe((pharmacyModel) => {
    if (this.screenMode === 'InvitePatient') {
      this.api.getTemplateSmsText('InviteSmsText').subscribe(result => {
        this.message = result;
        this.message = this.message.replace('{0}', this.patientFirstName);
        this.message = this.message.replace('{1}', this.code);
        this.message = this.message.replace('{2}', pharmacyModel.name);
        this.message$.next(this.message);
        });
      }
      if (this.screenMode === 'InviteCarer') {
        this.api.getTemplateSmsText('LinkPatientSmsText').subscribe(result => {
          this.message = result;
          this.message = this.message.split('{0}').join(this.carerPatient.firstName);
          this.message = this.message.split('{1}').join(this.patientFirstName);
          this.message = this.message.split('{2}').join(this.patientLastName);
          this.message = this.message.replace('{3}', pharmacyModel.name);
          this.message$.next(this.message);
        });
      }
    });
  }

  openPatientInvite() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/patients/invite/' + this.patientGUID]);
    });
  }
}
