export enum OperationResultEnum {
    BADREQUEST = -6,
    CONCURRENCYDELETED = -5,
    CONCURRENCY = -4,
    EXCEPTION = -3,
    VALIDATEFAILED = -2,
    FAILED = -1,
    NOTFOUND = 0,
    SUCCESS = 1
  }

  export class OperationResultNew<Tdata, Terror> {
    result: OperationResultEnum;
    resultMessage: string;
    data: Tdata;
    errorData: Terror;
  }


  export class OperationResult<Tdata> extends OperationResultNew<Tdata, NoData> {}

  export class ErrorResult<TError> {
    errorType = 'unknown';
    errorData: TError = null;
    resultMessage: string = null;

    constructor(errorType: string, errorData: TError, resultMessage: string) {
      this.errorType = errorType;
      this.errorData = errorData;
      this.resultMessage = resultMessage;
    }
  }

  // Used to provide a type to OperationResult when data is not returned
  export class NoData { }

  export class FilteredContext {
    FilterId: string;
  }

  export class PagingContext extends FilteredContext {
    PageNumber: number;
    NumberPerPage: number;
    SortOrder: string;
    FilterId: string;
  }

  export class AuditSearchContext extends FilteredContext {
    PageNumber: number;
    NumberPerPage: number;
    SortOrder: string;
    patientCode: number;
    sdsUserId: string;
    startDate: Date;
    endDate: Date;
  }

  export class PatientSearchContext extends FilteredContext {
    PageNumber: number;
    NumberPerPage: number;
    SortOrder: string;
    pharmacyId: number;
    firstName: string;
    phone: string;
    dateOfBirth: Date;
    patientStatus: string;
    patientCode: string;

  }
  export class OperationPagedResult<T, TError> extends OperationResultNew<T[], TError> {
    currentPage?: number;
    totalPages?: number;
    totalItem?: number;
    pageSize?: number;
  }
