// This is a common environment file for all the npa enviornments
export const environment = {
    allowedRoles: ['Pharmacy', 'Audit'],
    defaultRoles: ['Pharmacy'],
    hasOdsCodeDefaultRole: ['Pharmacy'],
    privacyLink:              'https://www.mymedadvisor.co.uk/privacy',
    termsAndConditionsLink:   'https://www.mymedadvisor.co.uk/Terms',
    carerModeConsentFormLink: 'https://www.mymedadvisor.co.uk/hubfs/3.%20Documents/Legal/UK/MedAdvisor%20UK%20Carer%20Mode%20Consent%20Form%2012.04.21.pdf',
    proteQtInstallerLink:     'https://npa.medadvisor.health/downloads/Quicksilva+proteQt.msi',
    remoteSupportLink:        'https://medadvisor-uk.screenconnect.com/',
    baseUrl_GlobalPharmacyPortal: 'https://example.com/',
    baseUrl_App:                  'https://example.com/',
    logging: 'webApi',
};
