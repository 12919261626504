import { Component, OnInit, ViewEncapsulation, AfterViewInit, ChangeDetectionStrategy, Inject, DoCheck } from '@angular/core';
import { CommsModule } from 'src/app/utility/comms/comms.module';
import { IAPIService, PracticeRecord} from '../../api/iapi.service';
import { DataService } from 'src/app/surgeries/practiceData.service';
import { DialogConfig, DialogService } from 'src/app/utility/base-dialog/dialog.service';
import { PatientAddComponent } from 'src/app/patients/patientadd/patientadd.component';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';
import { PracticeModel } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';
import { PracticeDetails, PracticePharmacyCommunicationChannels } from 'src/app/api/ApiRecordTypes/PracticeRecord';
import { Router } from '@angular/router';

enum ScreenState {
  gpSearch = 'gpSearch',
  gpListDefault = 'gpListDefault',
  practiceDetail = 'practiceDetail',
  errorMessage = 'errorMessage'
}

@Component({
  selector: 'app-surgerylist',
  templateUrl: './surgerylist.component.html',
  styleUrls: ['./surgerylist.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class SurgeryListComponent implements OnInit, AfterViewInit, DoCheck {

  datasource: PracticeRecord[] = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  datasource$ = new BehaviorSubject<any>(this.datasource);
  API: IAPIService;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accountSettings: any = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accountSettings$ = new BehaviorSubject<any>(this.accountSettings);
  gpNameSearch: string;
  // Initial Screen
  displayScreen = ScreenState.gpListDefault;

  practices: PracticeModel[] = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  practices$ = new BehaviorSubject<any>(this.practices);

  current = new PracticeRecord();
  current$ = new BehaviorSubject<PracticeRecord>(this.current);
  saved = new PracticeDetails();

  savingInProgress = false;
  isVaild = true;
  isFaxVaild = true;
  errorMessage = '';
  errorFaxMessage = '';
  pharmacyId: number;
  communicationsTarget = '';
  faxNumber = '';
  communicationsMethod = 'Print';
  hoursuntilscriptoverdue = '';

  constructor(
    private api: IAPIService,
    private comms: CommsModule,
    private dialogs: DialogService,
    private practiceService: DataService,
    private authService: AuthenticationService,
    public router: Router,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any,
    ) {
    this.API = api;
    this.accountSettings = JSON.parse(this.sessionStorage.getItem('accountSettings'));
  }
  ngOnInit() {
    this.authService.authDataSource$.subscribe(
      mission => {
        this.accountSettings = mission;
        this.getPracticeList(mission);
      }
    );
    this.comms.setPage({pageTitle: 'GP Surgeries', page: 'surgerieslist', pageIcon: ''});
  }

  getPracticeList(account) {
    let pharmacyId = account && account.id;
    if (!account) {
      const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
      if (accountSetting && accountSetting.role === 1) {
        pharmacyId = accountSetting.id;
      }
      account = accountSetting;
    }
    this.api.getPharmacySettings(pharmacyId).subscribe((result) => {
      this.hoursuntilscriptoverdue = result.fields && result.fields.hoursuntilscriptoverdue;
    });
    this.API.getPracticeList(account ? account.id : pharmacyId).subscribe((result) => {
      this.datasource = result['data'];
      this.datasource$.next(this.datasource);
    }, error => {
      console.log(error); }
    );
  }
  ngAfterViewInit() {
    this.getPracticeList(this.accountSettings);
  }
  ngDoCheck() {
    this.accountSettings = JSON.parse(this.sessionStorage.getItem('accountSettings'));
  }
  getRowClass(datasource: PracticeRecord): string {
    return datasource ? 'Practice' : 'loading';
  }
  deliveryMethod(record): string {
    if (record && record.communicationChannels && record.communicationChannels.chosenMethod) {
      switch (record.communicationChannels.chosenMethod) {
        case 'EmailPlainText':
          return 'Email';
        case 'Email':
          return 'Email';
        case 'Sms':
          return 'SMS';
        case 'Fax':
          return 'Fax';
        case 'Print':
        default:
          return 'Manual (PDF)';
      }
    }
    return 'Manual (PDF)';
  }
  name(record): string {
    return record.practice && record.practice.name ? record.practice.name : '';
  }
  phone(record): string {
    return record && record.phone ? UtilsService.formatPhone(record.phone) : '';
  }
  fax(record): string {
    return record && record.fax ? UtilsService.formatPhone(record.fax) : '';
  }
  email(record): string {
    if (record && record.communicationChannels) {
      const endpoint = record.communicationChannels.endpoints.find(x => x.method === 'Email' || x.method === 'EmailPlainText' );
      if (endpoint) {
        return endpoint.target;
      }
    }
  }
  address(record): string {
    const fields =  record && record.address &&
      [record.address.line1, record.address.line2,
        record.address.suburb, record.address.postCode, record.address.state,  record.address.country ];
    return fields && fields.filter( x => x !== null).join(', ');
  }
  notes(record): string {
    return record.preferences.notes ? record.preferences.notes : '';
  }
  overdue(record): string {
    return record.preferences &&  record.preferences.hoursuntilscriptoverdue ? `${record.preferences.hoursuntilscriptoverdue} hours` : '';
  }
  handleClick(prams: string) {
    this.practiceService.setAction(prams);
  }
  invite() {
    const dialogConfig = new DialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '1200px';
    dialogConfig.height = '690px';
    this.dialogs.OpenComponentDialog(PatientAddComponent, { ShowCancel: true }, dialogConfig).then((result) => {
      if (result != null) {
        console.log('New patient code was: ' + result.data.id);
      } else {
        console.log('Closed externally');
      }
    });
  }

  switchScreen() {
    this.displayScreen = ScreenState.gpSearch;
  }
  practiceName(practice): string {
    return practice && practice.name ? practice.name : '';
  }
  onGPSurgerySearch() {
    this.displayScreen = ScreenState.gpSearch;
    if (this.gpNameSearch !== undefined) { const detail = {
      systemCodeOrName: this.gpNameSearch,
      numberPerPage: 20,
      sortOrder: 'Name'
    };
    this.API.searchGPSurgery(detail).subscribe(result => {
      this.practices = result['data'];
      this.practices$.next(this.practices);
    }); }

  }
  onPracticeSelected(practice: PracticeModel) {
    this.displayScreen = ScreenState.practiceDetail;
    if (practice) {
      this.current.practice = practice;
      this.current.preferences = {
        hoursuntilscriptoverdue: this.hoursuntilscriptoverdue,
        notes: ''
      };
      this.communicationsTarget = practice.email ? practice.email : '';
      this.faxNumber = practice.fax ? practice.fax : '';
    }

  }
  savePracticeDetails() {
    if (this.formValid()) {
      this.savingInProgress = true;
      const channels = new PracticePharmacyCommunicationChannels();
      channels.chosenMethod = this.communicationsMethod;
      if (this.communicationsMethod === 'Fax') {
        this.communicationsTarget = this.faxNumber;
      }
      channels.endpoints = [
        {
          target: this.communicationsTarget,
          method: this.communicationsMethod
        }
      ];
      channels.usePracticeEndpoint = false;
      const details = {
        pharmacyId: this.accountSettings.id,
        practiceId: this.current.practice.id,
        preferences: this.current.preferences,
        communicationChannels: channels
      };
      this.api.setPracticeDetails(details).subscribe(
        
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        result => {
          this.dialogs.openInformationDialog(['GP Surgery details added successfully'], 'OK');
        }, error => {
          console.log(Error, error);

        }
      );
    }
  }
  handleButton() {
    this.displayScreen = ScreenState.gpListDefault;
    this.router.navigate(['/surgeries']);
  }
  onEndpointChange(value) {
    const endpoints = this.current && this.current.communicationChannels && this.current.communicationChannels.endpoints;
    if (endpoints) {
      const possibleEndpoint = endpoints.find(x => x.method === value);
      if (possibleEndpoint) {
        this.communicationsTarget = possibleEndpoint.target;

      }
    }
  }
  formValid(): boolean {
    const target = this.communicationsTarget;
    const fax = this.faxNumber;
    const method = this.communicationsMethod;
    this.isVaild = true;
    this.isFaxVaild = true;
    if (method === 'Email' && (target === null || target === '' || target === undefined)) {
      this.errorMessage = `Email is not valid`;
      this.isVaild = false;
      return false;
    }
    if (method === 'Email' && (target) && (!UtilsService.EmailValidate(target))) {

        this.errorMessage = `Email is not valid`;
        this.isVaild = false;
        return false;
    }
    if (method === 'Fax' && (fax === null || fax === '' || fax === undefined)) {
      this.errorFaxMessage = `Fax is not valid`;
      this.isFaxVaild = false;
      return false;
    }
    return true;
  }
  clearSearch() {
    this.gpNameSearch = undefined;
  }
}
