<div class="manage-order">
  <div [class]="orderClass(statusCode$ | async) === ''? 'medication-status' : 'medication-status ' + orderClass(statusCode$ | async)">
    <img class="status-img" [src]="materialIconMobileOrder(statusCode$ | async)"/>
    <p class="status">
      {{ displayStatus(statusCode$ | async) }}
    </p>
  </div>
  <div class="main-content">
    <div class="medication-instructions">
      <div>
        Mark the status of the medications in the case of an incomplete
        fulfilment.
      </div>

      <div class="margin-top20">
        <div>
          <b>Item on order and Ready to collect:</b> If 'Item on order' or 'Ready to collect' is selected for any item, this order will remain in the Inbox tab.
        </div>
        <div class="margin-top20">
          <b>Unable to fill:</b> If all items are 'Unable to fill', this order will move to the Completed tab.
        </div>
      </div>
    </div>

    <div class="medication-row" *ngIf="isComponentLoading">
      <app-load-spinner [diameter]="32"></app-load-spinner>
    </div>

    <div
      [class]="
        isProcessOrder && !isCompletedOrder
          ? 'medication-list space-bottom'
          : 'medication-list'
      "
    >
      <div
        *ngFor="let component of medicationlist$ | async"
        class="medication-item"
      >
        <div class="message-actions">
          <span
            class="material-icons action-icon"
            *ngIf="component.actionsAvailable.length > 0"
            [matMenuTriggerFor]="dropdownMenu"
            >more_vert</span
          >

          <mat-menu #dropdownMenu="matMenu">
            <button mat-menu-item *ngFor="let item of component.actionsAvailable">
              <div (click)="onActionChange(statusToNumber(item), component)" class="medication-action-item">
                {{displayStatus(item)}}
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="drugdetails">
          <strong>{{ component?.drug?.labelName }}</strong>
          <div class="active-name">{{ component?.drug?.activeName }}</div>
        </div>

        <div class="drug-item-status">
          <img [src]="materialIconMobile(component)" />
          <span class="text-margin">{{
            displayStatus(component?.statusModel?.status)
          }}</span>
        </div>
      </div>
    </div>

    <div
      class="textarea-wrapper"
      *ngIf="isUnableToFill || (isProcessOrder && isCompletedOrder)"
    >
      <div class="note-title">
        <strong>Add a message to this order:</strong>
      </div>
      <div>
        <textarea
          [(ngModel)]="patientMessage"
          class="message-text-area"
          maxlength="170"
          placeholder="Type message here"
        >
        </textarea>
      </div>
    </div>
  </div>

  <div class="bottom-button" *ngIf="isProcessOrder && !isCompletedOrder">
    <div class="save-button"  *ngIf ="isClickSave"><i class="fa fa-circle-o-notch fa-spin"></i></div>
    <div [style.pointer-events]="isSaveDone? 'none' : 'auto'" [class] = "isSaveDone? 'save-button saved' : 'save-button'" (click)="onSave()" *ngIf ="!isClickSave">{{ isSaveDone? 'SAVED' : 'SAVE'}}<span *ngIf = "isSaveDone" class="material-icons save-done-icon">done</span>
    </div>
  </div>

  <div
    class="bottom-button"
    *ngIf="isUnableToFill || (isProcessOrder && isCompletedOrder)"
  >
    <div class="complete-button" (click)="onSave()">
      <i *ngIf="isClickSave" class="fa fa-circle-o-notch fa-spin"></i
      >{{ !isClickSave ? "COMPLETE & SEND" : "" }}
    </div>
  </div>
</div>

<ng-template #navigationTitle>
  <div class="left">
    <span class="material-icons left-sidebar" (click)="goBackMessageList()"
      >keyboard_backspace</span
    >
  </div>
  <div class="title">
    <div class="name">
      <span class="first-name">{{this.data?.patient?.firstName}}</span><span>&nbsp;</span><span class="last-name">{{this.data?.patient?.lastName}}</span>
    </div>
  </div>
  <div class="right">
    <div class="right-sidebar">
      <div class="order">{{ orderNo() }}</div>
      <div class="date">{{ calendarTime(data?.createDateUtc) }}</div>
    </div>
  </div>
</ng-template>
