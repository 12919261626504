import {CollectionViewer, DataSource, ListRange} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { PagingContext, OperationPagedResult } from '../../api/iapi.service';


export type PagedResultProvider<T, TError>
    = (context: PagingContext, externalAuthToken: string) => Observable<OperationPagedResult<T, TError>>;

export type FormatAndEnrichData<T, TError> =
    (data: OperationPagedResult<T, TError>) => void;

export class PagedDataSource<T, TError> extends DataSource<T | undefined> {
  constructor(remoteDataSource: PagedResultProvider<T, TError>,
    definitionId = '',
    preventDataFetch = false,
    externalAuthToken = '',

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    formatAndEnrichData: FormatAndEnrichData<T, TError> = (data) => {}) {
    super();
    this._definitionId = definitionId;
    this._externalAuthToken = externalAuthToken;
    this._dataSource = remoteDataSource;
    this._preventDataFetch = preventDataFetch;
    this._formatAndEnrichData = formatAndEnrichData;
  }

  private _formatAndEnrichData: FormatAndEnrichData<T, TError> = null;
  private _preventDataFetch = false;
  private _pageSize = 0;
  public _cachedData: (T | undefined)[] = new Array(1);
  private _fetchedPages = new Set<number>();
  private _dataStream = new BehaviorSubject<(T | undefined)[]>(this._cachedData);
  private _subscription = new Subscription();
  private _initialLoadComplete = false;
  private _dataSource: PagedResultProvider<T, TError> = null;
  public _currentViewLength = 1;
  /** may emit null' */
  public _errorData$: BehaviorSubject<TError> = new BehaviorSubject(null);
  private _definitionId: string;
  private _externalAuthToken: string;
  public isPageLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** may emit 0 or null */
  public numberOfResults$: BehaviorSubject<number> = new  BehaviorSubject(null);

  enableDataLoading() {
    this._preventDataFetch = false;
  }

  disableDataLoading() {
    this._preventDataFetch = true;
  }
  removeItem(data) {
    const index = this._cachedData.indexOf(data);
    if (index > -1) {
      this._cachedData.splice(index, 1);
      this._dataStream.next(this._cachedData);
    }
  }

  addItem(data) {
    this._cachedData.push(data);
    this._dataStream.next(this._cachedData);
  }

  removeAll() {
    this._cachedData = new Array(0);
    this._dataStream.next(this._cachedData);
    this.isPageLoading$.next(false);
  }

  connect(collectionViewer: CollectionViewer): Observable<(T | undefined)[]> {
    this._subscription = collectionViewer.viewChange.subscribe(range => {
      this._handleViewChange(range);
    });
    return this._dataStream.asObservable();
  }

  private _handleViewChange(range: ListRange) {
    if (!this._preventDataFetch && this._definitionId) {
      // track current view length to allow for datasource originated refreshing.
      this._currentViewLength = range.end - range.start;
      const startPage = this._getPageForIndex(range.start);
      let endPage = this._getPageForIndex(range.end - 1);
      if (range.end === 0 ) {
        endPage = 0;
      }
      for (let i = startPage; i <= endPage; i++) {
        this._getPage(i);
      }
    }
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  empty() {
    this._definitionId = '';
    this._externalAuthToken = '';
    this._cachedData = new Array(0);
    this._fetchedPages = new Set<number>();
    this._dataStream.next(this._cachedData);
    this._initialLoadComplete = false;
    this.numberOfResults$.next(null);

  }

  setPageSize(pageSize: number) {
    this._pageSize = pageSize;
  }

  reset(definitionId= '', externalAuthToken = ''): void {
    this.empty();
    this._definitionId = definitionId;
    this._externalAuthToken = externalAuthToken;
    // because we've no way to tell cdk-virtual-scroll-viewport to ask for the pages again.
    this._handleViewChange({start: 0, end: 0});
  }

  private _getPageForIndex(index: number): number {
    if (this._pageSize === 0) { return 0; }
    return Math.floor(index / this._pageSize);
  }

  private _getPage(page: number)  {
    if (this._fetchedPages.has(page)) {
      return;
    }
    this._fetchPage(page);
    this._fetchedPages.add(page);
  }

  private _fetchPage(page: number) {
    const context = new PagingContext();
    context.PageNumber = page;
    context.NumberPerPage = this._pageSize;
    context.SortOrder = 'patientName';
    context.FilterId = this._definitionId;
    this.isPageLoading$.next(true);

    this._dataSource(context, this._externalAuthToken).subscribe(result => {
      this._errorData$.next(result.errorData);
      if (result.errorData === null) {
        if (!this._initialLoadComplete) {
          this._cachedData = new Array<T>(result.totalItem);
          this._initialLoadComplete = true;
          this._pageSize = result.pageSize;
          this.numberOfResults$.next(result.totalItem);
        }
        this._formatAndEnrichData(result);
        this._cachedData.splice((result.currentPage) * result.pageSize, result.data.length, ...result.data);
        this._dataStream.next(this._cachedData);
      }
    },
    err => {
      if (err && err.error && 'error' in err && 'errorData' in err.error && err.error.errorData !== null) {
        console.log('Non-data error with error data', err);
        this._errorData$.next(err.error.errorData);
      } else {
        // Likely to be an http error.
        console.log('Non-data error without error data', err);
      }
    },
    () => {
      this.isPageLoading$.next(false);
    });
  }
}
