import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { BasicDialogComponent } from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import { CalendarEventService } from 'src/app/booking-calendar/services/calendar-event.service';
import { CalendarDialogData } from '../../models/view-models/layout-calendar-dialog.view-model';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';
import { AppConfigService } from 'src/app/app-config.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class BookingText {
  // #region Properties
  public static cancelText = 'Cancel';

  public static acceptText = 'Accept';

  public static addResource = 'Add Calendar';

  // #endregion
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Item {
  constructor (
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public value: any,
    public isSelected?: boolean) {
  }
}

@Component({
  selector: 'app-website-access-dialog',
  templateUrl: './website-access-dialog.component.html',
  styleUrls: ['./website-access-dialog.component.css']
})
export class WebsiteAccessDialogComponent extends BasicDialogComponent implements OnInit, OnDestroy {
  // #region Properties
  public isCalendarWidget = false;

  public customerBookingLabel = 'Customer Booking Website';

  public staffBaseUrl = '';
  public customerBaseUrl = '';

  public customerBookingIntructionText = 'This is the website your customer can access to make bookings at your pharmacy.';

  public staffBookingLabel = 'Staff Booking Website';
  public staffBooking = '';
  public customerBooking = '';
  public staffBookingIntructionText = 'This is the website staff members can access the calendar so that they can make, delete and edit bookings in the calendar.';

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public config: any;

  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  // #endregion

  // #region Constructor
  public constructor (
    protected router: Router,
    protected calendarService: CalendarEventService,
    protected toastr: ToastrService,
    private location: Location,
    private configService: AppConfigService,
  ) {
    super(router);
    this.headerTitle = 'Public website access';
    this.acceptButtonTitle = 'Close';
    this.isCalendarWidget = location.path().includes('/Pharmacist/Staff/');
    this.staffBaseUrl    = UtilsService.includeTrailingSlash(environment.baseUrl_GlobalPharmacyPortal);
    this.customerBaseUrl = UtilsService.includeTrailingSlash(environment.baseUrl_App);
    this.config = this.configService.getConfig();
  }

  // #endregion

  // #region Life cycle
  public ngOnInit () {
    this.toastr.overlayContainer = this.toastContainer;
  }

  // #endregion

  // #region Methods
  public setData (data: CalendarDialogData) {
    super.setData(data);
    this.customerBooking = `${this.customerBaseUrl}${data.settings.customerBookingToken}`;
    if (data.settings.staffBookingToken) {
      this.staffBooking = `${this.staffBaseUrl}${data.settings.staffBookingToken}`;
    } else {
      this.staffBooking = `Staff booking url is unavailable. This may mean it is disabled via a backend feature-flagged.`;
    }
  }

  public copyCustomerLink () {
    navigator.clipboard.writeText(this.customerBooking);
    this.showToastMessage();
  }

  public copyStaffLink () {
    navigator.clipboard.writeText(this.staffBooking);
    this.showToastMessage();
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private showToastMessage (timeOut = 3000): any {
    this.toastr.clear();
    const positionClass = new Date().getTime().toString() + Math.random().toString();
    return this.toastr.show('Link copied', null, { toastClass: 'custom-toast-success', timeOut, positionClass });
  }

  // #endregion
}
