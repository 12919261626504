import * as moment from 'moment';
import * as unescape from 'lodash.unescape';
export class UtilsService {

    private static readonly regex_dd_mm_yyyy =
        /^(0?[1-9]|[12][0-9]|3[01])[./\-\\ ](0?[1-9]|1[0-2])[./\-\\ ]([0-9]{4})$/;
    private static readonly regex_ddmmyyyy = // optional separators requires day & month be 0-padded
        /^(0[1-9]|[12][0-9]|3[01])[./\-\\ ]?(0[1-9]|1[0-2])[./\-\\ ]?([0-9]{4})$/;
    private static readonly regex_yyyymmdd = // forced separators allows day & month not to be 0-padded
        /^([0-9]{4})[./\-\\ ](0?[1-9]|1[0-2])[./\-\\ ](0?[1-9]|[12][0-9]|3[01])$/;

    private static chunk(str: string, position: number): string[] {
        const ret = [];
        for (let i = 0; i < str.length; i += position) {
            ret.push(str.substr(i, position));
        }
        return ret;
    }

    public static AUPhoneValid(phone): boolean {
        const pattern = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
        return pattern.test(phone.replace(/\s/g, ''));
    }
    public static UKPhoneVaild(phone): boolean {
        const pattern = /^(?:07)(?:\d\s?){8,9}$/;
        return pattern.test(phone.replace(/\s/g, ''));
    }
    public static FaxNumberValid(fax): boolean {
        const pattern = /^(\+?\d{1,}(\s?|-?)\d*(\s?|-?)\(?\d{2,}\)?(\s?|-?)\d{3,}\s?\d{3,})$/;
        return pattern.test(fax.replace(/\s/g, ''));
    }
    public static EmailValidate(email): boolean {
        const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return pattern.test(email.replace(/\s/g, ''));
    }
    public static DateValid_DayMonthYear(dateString: string): boolean {
        if (typeof dateString === 'string') {
            return UtilsService.regex_dd_mm_yyyy.test(dateString.trim()) ||
                UtilsService.regex_ddmmyyyy.test(dateString.trim());
        }
        return false;
    }

    public static toLocalDateString(date: Date): string {
        return moment(date).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    }

    public static validateNHS(code) {
        let str = code.toString();

        // validate the string length and value
        const strLen = str.length, strVal = parseInt(str, 10);
        if (strLen < 8 || strLen > 10 || isNaN(strVal) || strVal === 0) {
            return false;
        }

        // make sure the string is padded with zeros
        while (str.length < 10) { str = '0' + str; }

        // invalidate consecutive arrangement of the same digit
        if (str.match(/^(\d)\1+$/g)) { return false; }

        const checkDigit = parseInt(code.slice(-1), 10); // rightmost digit
        str = code.slice(0, -1); // remove the check digit
        let sum = 0;
        for (let i = str.length; i > 0; i--) {
            sum += (i + 1) * str.substr(-i, 1);
        }

        // calculate sum modulo 11
        const mod = sum % 11;

        return (mod < 2 && mod === checkDigit) || (mod >= 2 && mod + checkDigit === 11);

    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static formatPhone(phone: any): string {
        if (phone && phone.includes('+44')) {
            return phone.replace('+44', '0').replace(/^(.{4})(.*)$/, '$1 $2');
        } else {
            return  phone && phone.replace('+61', '0').replace(/^(.{4})(.*)$/, '$1 $2');
        }
    }

    /** Set first character to uppcase (after trim()), and rest to lowercase */
    public static Capitalize(s: string): string {
        s = s.trim();
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    }

    /**
     * @returns dateString unchanged, unless dateString is in a format recognized by
     * DateValid_DayMonthYear(), in which case it returns d/m/yyyy formatted with slashes.
     */
    public static formatDate_DayMonthYear(dateString: string): string {
        if (typeof dateString === 'string')  {
            let matches_array: string[] = dateString.trim().match(UtilsService.regex_dd_mm_yyyy);
            if (matches_array === null) { matches_array = dateString.trim().match(UtilsService.regex_ddmmyyyy); }

            if (matches_array !== null && matches_array.length === 4) {
                dateString = matches_array[1] + '/' + matches_array[2] + '/' + matches_array[3];
            }
        }
        return dateString;
    }
    /**
     * @returns null, unless dateString is in a day-month-year format that passes DateValid_DayMonthYear(),
     *  in which case it returns a YYYY-MM-DD string, which the backend will not confuse with month-day-year.
     */
    public static formatDate_DayMonthYear_to_YearMonthDay(dateString: string | null): string | null {
        if (typeof dateString === 'string') {
            let matches_array: string[] = dateString.trim().match(UtilsService.regex_dd_mm_yyyy);
            if (matches_array === null) { matches_array = dateString.trim().match(UtilsService.regex_ddmmyyyy); }

            if (matches_array !== null && matches_array.length === 4) {
                return matches_array[3] + '-' + matches_array[2].padStart(2, '0') + '-' + matches_array[1].padStart(2, '0');
            }
        }
        return null;
    }

    public static isFormattedYearMonthDay(dateString: string | null): boolean {
        if (typeof dateString === 'string') {
            const matches_array: string[] = dateString.trim().match(UtilsService.regex_yyyymmdd);
            if (matches_array !== null) {
                return true;
            }
        }
        return false;
    }

    public static getUnambiguousDate(dateString: string | null): string | null {
        let unambiguousDate = dateString;
        if (!UtilsService.isFormattedYearMonthDay(dateString)) {
            unambiguousDate = UtilsService.formatDate_DayMonthYear_to_YearMonthDay(dateString);
        }
        return unambiguousDate;
    }

    public static formatNhsNumber(nhsNumber: string): string {
        if (typeof nhsNumber === 'string')  {
            nhsNumber = nhsNumber.replace(/[^0-9]/g, '');
            nhsNumber = UtilsService.chunk(nhsNumber, 3).join(' ');
            nhsNumber = nhsNumber.substr(0, 11) + '' + nhsNumber.substr(12);
        }
        return nhsNumber;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static calendarTime(date: any): string {
        return date && moment(date).local().calendar(null, {
            sameDay: '[Today,] h:mm A',
            nextDay: '[Tomorrow,] h:mm A',
            nextWeek: 'MM/DD/YYYY h:mm A',
            lastDay: `[Yesterday,] h:mm A`,
            lastWeek: 'MM/DD/YYYY h:mm A',
            sameElse: 'MM/DD/YYYY h:mm A'
        }) || '';
    }

    public static UKMobileNoValid(phone): boolean {
        const pattern1 = /^(?:\+447)(?:\d\s?){9}$/;
        const pattern2 = /^(?:07)(?:\d\s?){9}$/;
        return pattern1.test(phone.replace(/\s/g, '')) || pattern2.test(phone.replace(/\s/g, ''));
    }

    public static unescapeString (value: string, numberOfDecode = 1): string {
        if (!value) {
            return '';
        }
        const isAMP = value.split('amp;').length > 2;
        if (isAMP) {
            value = value.replace(/amp;/g, '');
        }
        for (let i = 0; i <= numberOfDecode; i++) {
            value = unescape(value);
        }
        return value;
    }

    /**
     * Returns a base64 encoded UTF8 string.
     * btoa() on its own will throw an exception if the input string contains any unicode
     * characters, and will encode the British pound symbol as extended ascii byte 0xA3, which breaks UTF8.
     * (The British pound symbol is common in passwords typed on UK-keyboard)
     */
    public static b64EncodeUnicode(str: string): string {
        // stackoverflow.com/a/30106551/3343347
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode(parseInt(p1, 16));
        }));
    }

    public static includeTrailingSlash(s: string, delimiterChar = '/'): string {
        const lastChar = s.charAt(s.length - 1);
        return lastChar === delimiterChar ? s : s + delimiterChar;
    }
}
