<ng-container *ngIf="(menuItemData$ | async) as menuItemData">
    <ng-container *ngIf="(isHidden$ | async) === false">
        <ng-container *ngIf="isEnabled$ | async">
            <!-- these should be LI not div -->
            <div matRipple (click)="MenuSelected(menuItemData.target)"
                [ngClass]="{'link-like-button menu-item': true, 'router-active': (isActive$ | async)}">
                <span [class]="'menu-icons ' + (isActive$ | async) ? 'material-icons' : 'material-icons-outlined'+((menuItemData$ | async).classes || '')" style="padding-right: 10px;">{{(menuItemData$ | async).icon}}</span>
                <span>{{menuItemData.label}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="(isEnabled$ | async) === false">
            <div [attr.title]="menuItemData.disabledTooltip" [class]="'link-like-button menu-item disabled '+(menuItemData.classes || '')">{{menuItemData.label}}</div>
        </ng-container>
    </ng-container>
</ng-container>
