import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html'
})
export class LoadingSpinnerComponent {
  public mode = 'indeterminate';

  public value = 1000;

  public isLoading: Observable<boolean>;

  @Input() showOverlay: false;

  @Input() loadPageRequest: boolean;

  // eslint-disable-next-line no-useless-constructor
  public constructor (private spinnerService: SpinnerService) {
    this.isLoading = this.spinnerService.loadingState();
  }
}
