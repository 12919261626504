import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { OperationResult } from 'src/app/api/api.service';


export interface DataFilter {
    IsList(): boolean;
    GetName(): string;
    GetSet(): string;
    GetValue(): string;
    OnChanged(): Observable<DataFilter>;
    OnValidationError(): BehaviorSubject<string>;
    UpdateFilter(options: FilterOptions);
}

export interface SortSelector {
    GetName(): string;
    GetSet(): string;
    GetValue(): string;
    OnChanged(): Observable<SortSelector>;
    Clear();
    LastChanged(): number;
}

export class FilterOptions { // Glenn: maps to to AvailableFilterCriteria in backend
    field: string;
    fieldOptions: { // This type maps to a FieldValueOption in backend
      label: string,
      key: string,
      selected?: boolean,
      disabled?: boolean
    }[];
}

export class FilterRequest {
    filters: FilterSelection[];
    uuid: string; // report current filter value, particularly for optimised sorting.
    sortOrder: SortItem[];
    externalAuthToken: string;
}

export class SortItem {
    field: string;
    sorting: string;
}

export class FilterSelection {
    field: string;
    selection: string;
    isList = true; // Glenn: not sure what this is. Indicate whether selection is a key for a list vs. free text?
}

// What the API returns when you create a filter
export class FilterResult {
    filters: FilterOptions[];
    uuid: string;
    records; // Glenn: not sure this is needed? Doesn't seem to be used
}

export class FilterResultWithActions extends FilterResult {
    actions: ActionItem[];
}

export class ActionItem {
    label: string;
    token: string;
    count: number;
}


export type SourceControlProvider =
    (settings: FilterRequest) => Observable<OperationResult<FilterResult>>;
export type SourceControlWithActionsProvider =
    (settings: FilterRequest) => Observable<OperationResult<FilterResultWithActions>>;

export class DataFilterExtend implements DataFilter {
    private filterType: string;
    private filterName: string;
    private controlSet = 'default';
    private filterValue: string;
    private changesubscribers: Subject<DataFilter> = new Subject<DataFilter>();
    private validationError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(filterName: string, filterValue: any) {
        this.filterName = filterName;
        this.filterValue = filterValue;
    }
    IsList(): boolean {
        return this.filterType === 'SELECT';
    }
    GetValue() {
        return this.filterValue;
    }
    GetName() {
        return this.filterName;
    }
    GetSet() {
        return this.controlSet;
    }

    OnValidationError(): BehaviorSubject<string> {
        return this.validationError$;
    }
    OnChanged(): Observable<DataFilter> {
        return this.changesubscribers;
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    UpdateFilter(options: FilterOptions) {

    }
}
