import { OperationResult, PagingContext, OperationPagedResult } from './api.service';
import { FilterRequest, FilterResult } from '../utility/filtering/filtertypes';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export enum CrudOps {
    Create = 1,
    Read = 2,
    Update = 4,
    Delete = 8,
    CreateList = 16,
    ReadListPage = 32,
    BasicCRUD = 15,
    ListOnly = 48,
    All = 63
}


export class CRUD<T> {
    _path: string;
    _http: HttpClient;
    _supports: CrudOps = 0;

    constructor(http: HttpClient,  Version: string, Path: string, Supports: CrudOps = CrudOps.All) {
        this._path = this.getBaseApiPath(Version) + Path;
        this._http = http;
        this._supports = Supports;
    }

    public getBaseApiPath(version: string): string {
        return `${environment.apiPath}v${version}/`;
    }

    protected checkMask(functionName: string, requires: CrudOps) {
        // tslint:disable-next-line:no-bitwise
        if (!(this._supports | requires)) { // Deliberate Bitwise operation.
            throw new Error('CRUD operation not available: ' + functionName + ' not supported.');
        }
    }

    create<Tid>(data): Observable<OperationResult<{Id: Tid}>> {
        this.checkMask('create', CrudOps.Create);
        return this._http.post<OperationResult<{Id: Tid}>>(this._path, data);
    }

    read<Tid>(Id: Tid): Observable<OperationResult<T>>  {
        this.checkMask('read', CrudOps.Read);
        return this._http.get<OperationResult<T>>(this._path + Id);
    }

    update<Tid>(Id: Tid, data): Observable<OperationResult<{Id: Tid}>> {
        this.checkMask('update', CrudOps.Update);
        return this._http.put<OperationResult<{Id: Tid}>>(this._path + Id, data);
    }

    delete<Tid>(Id: Tid): Observable<OperationResult<{Id: Tid}>> {
        this.checkMask('delete', CrudOps.Delete);
        return this._http.delete<OperationResult<{Id: Tid}>>(this._path + Id);
    }
}

export class CRUDPagedList<T, TError> extends CRUD<T> {

    constructor(http: HttpClient,  Version: string, Path: string, Supports: CrudOps = 0) {
        super(http, Version, Path, Supports);
    }

    createListFilter(selections: FilterRequest): Observable<OperationResult<FilterResult>> {
        this.checkMask('createListFilter', CrudOps.CreateList);
       // console.log('PostFilterRequest(' + this._path + '/CreateFilter)', selections);
        return this._http.post<OperationResult<FilterResult>>(this._path + '/CreateFilter', selections);
    }

    getListPage(settings: PagingContext, externalAuthToken: string = null): Observable<OperationPagedResult<T, TError>> {
        this.checkMask('getListPage', CrudOps.ReadListPage);

        let Url = this._path + '/GetFilter/' + settings.FilterId;

        if (settings.PageNumber !== null) { Url += '/Page/' + settings.PageNumber; }

       // console.log('GetPagedData(' + Url + ')', settings);

        const queryParameters: string[] = [];

        if (settings.NumberPerPage !== null) { queryParameters.push('pagesize=' + settings.NumberPerPage); }
        if (externalAuthToken !== null) {
            const encodedToken = externalAuthToken && encodeURIComponent(externalAuthToken);
            queryParameters.push(`externalAuthToken=${encodedToken}`);
        }
        if (settings.SortOrder !== null) { queryParameters.push('sortOrder=' + settings.SortOrder); }

        if (queryParameters.length > 0) {
        Url += '?' + queryParameters.join('&');
        }

        return this._http.get<OperationPagedResult<T, TError>>(Url);
    }
}
