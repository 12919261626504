import { Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { IAPIService } from 'src/app/api/iapi.service';
import { LogEntryModel } from '../log-entry.model';
import { LogLevelEnum } from '../log-level.enum';
import { ILogService } from '../log-service.interface';

@Injectable()
export class LogWebApiService implements ILogService {
  constructor(private api: IAPIService) {
  }

  // Log message to the api, which will log the message to cloudwatch
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public logAsync(message: string, stackTrace: string, logLevel: LogLevelEnum, optionalParams: string[]): Observable<any> {
    const logEntry = new LogEntryModel();
    logEntry.message = message;
    logEntry.stackTrace = stackTrace;
    logEntry.logLevel = logLevel;
    logEntry.extraInfo = optionalParams;
    return this.api.logAsync(logEntry);
  }
}
