import { Pipe, PipeTransform } from '@angular/core';
import * as unescape from 'lodash.unescape';

@Pipe({
  name: 'unescape'
})
export class UnescapePipe implements PipeTransform {

  transform(value: string, numberOfDecode = 1): string {
    if (!value) {
      return '';
    }
    for (let i = 0; i <= numberOfDecode; i++) {
      value = unescape(value);
    }
    return value;
  }

}
