<div class='pageContent cdk-holder audit-list-container desktop-view'>
  <div class="filters" id="filter-header">
    <div class="col1">NHS Number:
        <div class="filter-item">
          <input appFilter="NHSNumber" type="text" name="NHSNumber" id="NHSNumber"
              class="audit-search-input"  placeholder="xxx xxx xxxx" [validator]="nhsNumberValidator" />
        </div>
        <label class="validation-error" >
          <span *ngIf="(nhsNumberValidation$ | async )?.value !== null">
              *{{ nhsNumberValidation$ | async }}
          </span>
        </label>
    </div>

    <div class="col2">SDS User Id
        <div class="filter-item">
            <input maxlength="12" appFilter="sdsUserId" type="text" name="sdsUserId" id="sdsUserId"
                class="audit-search-input" placeholder="XXXX XXXX XXXX" [validator]="sdsUserIdValidation" />
        </div>
        <label class="validation-error" >
          <span *ngIf="(sdsUserIdValidation$ | async )?.value !== null">
              *{{ sdsUserIdValidation$ | async }}
          </span>
        </label>
    </div>

    <div class="col3">Start Date
        <div class="filter-item">
          <mat-form-field appearance="outline" class="audit-search-input">
            <input placeholder="DD/MM/YYYY" matInput required [matDatepicker]="startDatepicker"
            appFilter="startDate" id="startDate" class="audit-search-input"  [validator]="startDateValidator"   [value]="initialStartDate">
            <mat-datepicker-toggle matSuffix [for]="startDatepicker" ></mat-datepicker-toggle>
            <mat-datepicker #startDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
        <label class="validation-error" >
          <span *ngIf="(startDateValidation$ | async )?.value !== null">
              *{{ startDateValidation$ | async }}
          </span>
          <span *ngIf="(this.datacontroller.multiFieldValidationErrorMessage$ | async )?.value !== null">
              *{{ this.datacontroller.multiFieldValidationErrorMessage$ | async }}
          </span>


        </label>
    </div>
    <div class="col4">End Date
        <div class="filter-item">
          <div style="font-size: 12px">
            <mat-form-field appearance="outline" class="audit-search-input">
              <input placeholder="DD/MM/YYYY" matInput required [matDatepicker]="endDatepicker" appFilter="endDate"
                name="endDate" id="endDate" class="audit-search-input" [validator]="endDateValidator" [value]="initialEndDate">
              <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <label class="validation-error" >
          <span *ngIf="(endDateValidation$ | async )?.value !== null">
              *{{ endDateValidation$ | async }}
          </span>
        </label>
    </div>
    <div class="search-field col5">
        <button class="btn btn-primary btn-search" (click)="searchAudit()" [disabled]="(datacontroller.validFilters$ | async) === false">Search</button>
        <a class="clear-button" (click)="clearSearch()">Clear</a>
    </div>
  </div>
  <div class="main-grid-panel">
    <div class="audit-row audit-headers withfilters">
      <div class="header-field col1">
        Organisation
      </div>
      <div class="header-field col2">
        ODS Code
      </div>
      <div class="header-field col3">
        NHS Number
      </div>
      <div class="header-field col4">
        Care Professional
      </div>
      <div class="header-field col5">
        Emergency Access
      </div>
      <div class="header-field col6">
        Date Time
      </div>
    </div>

    <app-loader></app-loader>

    <cdk-virtual-scroll-viewport class="list-container" id="scroll-content" #mainViewport [itemSize]="70" templateCacheSize=0>
      <div *ngIf="(datacontroller.datasource._errorData$ | async) === null">
        <ng-container *cdkVirtualFor="let record of datacontroller.datasource; let i = index" [ngSwitch]="getRowClass(record)">
          <ng-container class="audits" *ngSwitchCase="'AuditList'">
            <div class="audit gridrow audit-row">
              <div class="auditrow col1">
                <div class="organisation-name-col audit-col">
                  <div class="audit-name-item audit-item">
                    <div class="audit-bold">{{record.organisationName}} </div>
                    <div class="audit-bold">{{record.principalName}} </div>
                  </div>
                </div>
              </div>
              <div class="auditrow col2 ods-code">
                  <div class="row-details">{{record.organisationOdsCode}}</div>
              </div>
              <div class="auditrow col3">
                  <div class="row-details">{{displayNSHNumber(record)}}</div>
              </div>
              <div class="auditrow col4">
                  <div class="organisation-name-col audit-col">
                    <div class="audit-name-item audit-item">
                      <div class="audit-bold">{{record?.externalSession?.userName || 'Unknown'}}</div>
                    </div>
                    <div class="audit-subtext">Id: {{record?.externalSession?.userId || 'Unknown'}}</div>
                    <div class="audit-subtext">Role: {{record?.externalSession?.roleId || 'Unknown'}}</div>
                  </div>
              </div>
              <div class="auditrow col5">
                  <div class="emergency-access-col audit-col">
                    <div class="audit-name-item audit-item">
                      <div class="audit-bold">{{displayEmergencyAccess(record)}}</div>
                    </div>
                    <div class="audit-subtext">{{record.emergencyAccessReason}}</div>
                  </div>
              </div>
              <div class="auditrow col6 date-time-field">
                  <span class="datepart">{{displayEventTime(record, 'date')}}</span>
                  <span class="timepart">{{displayEventTime(record, 'time')}}</span>
                  <span class="offsetpart">{{displayEventTime(record, 'offset')}}</span>
              </div>
            </div>
          </ng-container>
          <ng-container class="audit" *ngSwitchDefault>
              <app-msg-loadingrequest class="audit gridrow"></app-msg-loadingrequest>
          </ng-container>
        </ng-container>
        <div *ngIf="(datacontroller.datasource.numberOfResults$ | async) === 0" class="audit gridrow">
          No Result
        </div>
      </div>
      <div *ngIf="(datacontroller.datasource._errorData$ | async)" class="audit gridrow data-row-error ">
        <fa name="exclamation-triangle"></fa>
        {{ (datacontroller.datasource._errorData$ | async).userFriendlyErrorMessage }}
      </div>
      <div *ngIf="(hasSearched$ | async) === false || (datacontroller.validFilters$ | async) === false" class="audit gridrow">
        Please select filters and click search
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
