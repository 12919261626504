import { Component, OnInit, ViewEncapsulation, ViewChildren, QueryList,
  AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { CommsModule } from 'src/app/utility/comms/comms.module';
import * as _ from 'lodash';
import { AuditRecord } from '../api/api.service';
import { DataSourceController } from '../utility/filtering/datasourcecontroller';


import { IAPIService } from 'src/app/api/api.service';
import { FilterControlDirective } from 'src/app/utility/filtering/filtercontrol.directive';
import { SortControlDirective } from 'src/app/utility/filtering/sortcontrol.directive';
import { BehaviorSubject } from 'rxjs';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';
import { AppConfigService } from '../app-config.service';
import { DataService } from 'src/app/utility/dataservice/data.service';
import { AuditSearchContext } from '../api/ApiRecordTypes/OperationResults';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { DataFilter } from '../utility/filtering/filtertypes';
import { UserAuditError } from '../api/ApiRecordTypes/AuditRecord';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-audit',
  templateUrl: './audit.component.html'
})

export class AuditComponent implements OnInit, AfterViewInit {

  constructor(
    private comms: CommsModule,
    private api: IAPIService,
    private configService: AppConfigService,
    public dataService: DataService
    ) {
      this.API = api;
  }
  API: IAPIService;

  datacontroller: DataSourceController<AuditRecord, UserAuditError> = new DataSourceController<AuditRecord, UserAuditError>(
    (x) => this.API.auditList.createListFilter(x),
    (y, z) => this.API.auditList.getListPage(y, z)
  );
  settings: AuditSearchContext;
  NumberOfResults = 0;
  NumberOfSelected  = 0;

  // Search Fields
  patientCodeSearch = '';



  pharmacyId = 1;
  auditName = null;
  NHSNumber = null;
  phoneNumber = null;
  dateOfBirth = null;
  status = null;
  numberPerPage = 50;
  isAuditsLoading = false;
  auditNameSearch = '';
  auditFilterStatus = 'All';
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  toggleSearchBox = false;
  CustomerText = 'Audit';
  CustomerTextPlural = 'Audits';
  isSearched = false;
  numberOfAudits = 0;
  hasSearched$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  initialStartDate: string = moment(new Date()).subtract(30, 'days').format('yyyy-MM-DD');
  initialEndDate: string = moment(new Date()).format('yyyy-MM-DD');

  nhsNumberValidation$: BehaviorSubject<string> = null;
  sdsUserIdValidation$: BehaviorSubject<string> = null;
  startDateValidation$: BehaviorSubject<string> = null;
  endDateValidation$: BehaviorSubject<string> = null;
  allFiltersValid$: BehaviorSubject<boolean> = null;

  @ViewChildren(FilterControlDirective) appFilterItems: QueryList<FilterControlDirective>;
  @ViewChildren(SortControlDirective) appSorterItems: QueryList<SortControlDirective>;
  @ViewChild('statusMobile', {static: true}) statusMobile: ElementRef;
  @ViewChild('filterStatusMobile') filterStatusMobile: ElementRef;

  @Output()
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  dateChange: EventEmitter<MatDatepickerInputEvent<any>> = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public multiFieldValidation: (items: DataFilter[]) => string = (items: DataFilter[]): string => {

    const startDateFilter = this.appFilterItems.find(x => x.GetName() === 'startDate');
    const endDateFilter = this.appFilterItems.find(x => x.GetName() === 'endDate');
    return this.startDateBeforeEndDate(startDateFilter.GetValue(), endDateFilter.GetValue());
  }

  public nhsNumberValidator: (item: FilterControlDirective) => string = (item: FilterControlDirective): string => {
    return this.nhsErrorMessage(item.GetValue());
  }

  public sdsUserIdValidation: (item: FilterControlDirective) => string = (item: FilterControlDirective): string => {
    return this.sdsUserIdErrorMessage(item.GetValue());
  }

  public startDateValidator: (item: FilterControlDirective) => string = (item: FilterControlDirective): string => {
    return this.dateErrorMessage(item.GetValue(), 'Start date');
  }

  public endDateValidator: (item: FilterControlDirective) => string = (item: FilterControlDirective): string => {
    return this.dateErrorMessage(item.GetValue(), 'End date');
  }

  onDateChange(): void {
    this.dateChange.emit();
  }

  ngOnInit() {
    this.config = this.configService.getConfig();
    if (this.config && this.config.features && this.config.features.ShowCustomerText) {
        this.CustomerText = 'Customer';
        this.CustomerTextPlural = 'Customers';
    }
    this.comms.setPage({pageTitle: `${this.CustomerTextPlural}`, page: 'auditlist', pageIcon: ''});
    this.datacontroller.datasource.isPageLoading$.subscribe(value => {
      this.isAuditsLoading = value;
      this.numberOfAudits = this.datacontroller.datasource._cachedData.length;
      if (value) {
        this.hasSearched$.next(true);
      }
    });
  }
  ngAfterViewInit() {
    this.datacontroller.InitialiseSortersIndirectly(this.appSorterItems);
    this.datacontroller.InitialiseFiltersIndirectly(this.appFilterItems);
    this.datacontroller.externalAuthToken$.next(this.comms.getCardDetailsAccess().value.sessionId);
    this.setUpValidation();
  }

  setUpValidation() {
    this.datacontroller.multiFieldValidation = this.multiFieldValidation;
    this.appFilterItems.forEach(item => {
      const itemName = item.GetName();
      switch (itemName) {
        case 'NHSNumber':
          this.nhsNumberValidation$ = item.OnValidationError();
          break;
        case 'sdsUserId':
          this.sdsUserIdValidation$ = item.OnValidationError();
          break;

        case 'startDate':
            this.startDateValidation$ = item.OnValidationError();
            break;

        case 'endDate':
            this.endDateValidation$ = item.OnValidationError();
            break;
      }
    });
  }

  getRowClass(record) {
    if (_.isEmpty(record)) {
      return 'Loading';
    }
    switch (record) {
      case record:
        return 'AuditList';
      default:
        return 'Loading';
    }
  }
  searchAudit() {
    this.datacontroller.enableDataLoading();
  }
  clearSearch() {
    this.appFilterItems.forEach(item => {
      item.resetValue();
      item.GetValue();
    });
    this.auditNameSearch = '';
    this.auditFilterStatus = 'All';
    this.datacontroller.datasource.empty();
  }

  openSearchFilter() {
    this.toggleSearchBox = !this.toggleSearchBox;
  }

  displayNSHNumber(record: AuditRecord): string {
    const NhsNumber = record && record.targetNhsNumber;
    return (NhsNumber && NhsNumber.replace(/^(.{3})(.{3})(.*)$/, '$1-$2-$3')) || '';
  }

  /**
   * @param partname is optional, allowing a part of the datetime to be returned. It can be 'date', 'time', or 'offset', it defaults to all.
   * @returns a string containing the datetime of the audit record (or a part of the datetime)
   */
  displayEventTime(record: AuditRecord, partname: string): string {
    // NHS requires:
    //   "The timestamps in Audit Trail entries shall be stored in UTC(GMT), to the nearest second.
    //    When displaying audit entries to authorised users, they shall be displayed by default in local
    //    time (with clear indication how this may differ from UTC)."

    const localDatetime = moment(record.createDate).local();

    switch (partname) {
      case 'date':
        return localDatetime.format('DD MMM YYYY');
      case 'time':
        return localDatetime.format('hh:mm:ss A');
    }

    const utcOffset_ms = localDatetime.utcOffset() * 60 * 1000; // utcOffset() returns value in minutes
    const utcOffsetSuffix =  (utcOffset_ms >= 0 ? '+' : '-') + moment.utc(utcOffset_ms).format('HHmm');
    if (partname === 'offset') {
      return `(UTC${utcOffsetSuffix})`;
    } else {
      return `${localDatetime.format('DD MMM YYYY hh:mm:ss A')} (UTC${utcOffsetSuffix})`;
    }
  }

  displayEmergencyAccess(record: AuditRecord): string {
    return (record && record.emergencyAccess) ? 'Yes' : 'No';
  }



  private startDateBeforeEndDate(startDateString: string, endDateString: string) {
    let errorMessage = null;
    const startErrorMessage = this.dateErrorMessage(startDateString, 'Start date');
    const endErrorMessage = this.dateErrorMessage(endDateString, 'End date');
    if (startErrorMessage === null && endErrorMessage === null) {
      const startDate = moment(UtilsService.getUnambiguousDate(startDateString));
      const endDate = moment(UtilsService.getUnambiguousDate(endDateString));
      if (startDate.diff(endDate) > 0) {
        errorMessage = 'Start Date must be before End Date';
      }
    }
    return errorMessage;
  }

  private nhsErrorMessage(nhsNumber: string) {
    let errorMessage = null;
    if (nhsNumber !== null && nhsNumber !== '') {
      nhsNumber = nhsNumber.replace(/\s*-*/g, '');
      const valid = UtilsService.validateNHS(nhsNumber);
      if (!valid) {
        errorMessage = 'Invalid NHS number';
      }
    }
    return errorMessage;
  }

  private sdsUserIdErrorMessage(sdsUserId: string) {

    let errorMessage = null;
    if (sdsUserId !== null && sdsUserId !== '') {
      sdsUserId = sdsUserId.replace(/\s*-*/g, '');
      const valid = /^\d{12}$/.test(sdsUserId);
      if (!valid) {
        errorMessage = 'Invalid SDS User Id';
      }
    }
    return errorMessage;
  }

  private dateErrorMessage(dateString: string, dateName: string) {
    if (dateString === null || dateString === '') {
      return `${dateName} is required.`;
    }
    const unambigiousDate = UtilsService.getUnambiguousDate(dateString);
    const date = moment(unambigiousDate);
    if (unambigiousDate === null || !date.isValid()) {
      return `${dateName} is invalid. Please use dd/mm/yyyy format.`;
    }
    return null;
  }
}
