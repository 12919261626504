import { Injectable, EventEmitter } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

import { GeneralDialogComponent, DialogResponse } from './general-dialog/general-dialog.component';
import { LoginComponent, LoginOutput } from 'src/app/authentication/login/login.component';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
export { DialogResponse } from './general-dialog/general-dialog.component';

export class IDialogEmbeddable<TIn, TOut> {
  public OnComplete: EventEmitter<TOut>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public setData(data: TIn) {}
}

export class DialogConfig {
  public width = '440px';
  public height = null;
  public panelClass: string[] = ['DefaultDialog'];
  public disableClose = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public externalCloser: Promise<any> = null;
  public autoFocus = true;
  public maxWidth = null;
  public maxHeight = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public AddExternalCloser(): (value?: any) => void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let closer: (value?: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    this.externalCloser = new Promise<any>((resolve, reject) => { closer = resolve; });
    return closer;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor( public dialog: MatDialog ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  OpenComponentDialog<TIn= any, TOut= any>(
        componentType: ComponentType<IDialogEmbeddable<TIn, TOut>>,
        dialogdata: TIn = null,
        config: DialogConfig= new DialogConfig()): Promise<TOut> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let dialogRef: MatDialogRef<any>;
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise<TOut>((resolve, reject) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = config.disableClose;
      dialogConfig.autoFocus = config.autoFocus;
      dialogConfig.data = dialogdata;
      dialogConfig.panelClass = config.panelClass;
      dialogConfig.width = config.width;
      dialogConfig.height = config.height;
      dialogConfig.maxWidth = config.maxWidth;
      dialogConfig.maxHeight = config.maxHeight;
      dialogRef = this.dialog.open(componentType, dialogConfig);

      dialogRef.componentInstance.setData(dialogdata);
      // If the config has an external closer, watch for an event on it and close on getting one.
      if (config.externalCloser) {
        config.externalCloser.then((result) => { dialogRef.close(result); }) ;
      }

      // This is the internal closure - when the component indicates it is complete.
      dialogRef.componentInstance.OnComplete.subscribe((result) => {
        dialogRef.close(result);
      });

      dialogRef.afterClosed().subscribe((result) => {
        // check result and reset rows if appropriate
        if (result !== DialogResponse.cancel) {
          resolve(result);
        }
      });
    });
  }

  public openInformationDialog(
        BodyText= ['Nothing to do but accept the inevitable'],
        AcceptString= 'Accept',
        config: DialogConfig = new DialogConfig()): Promise<DialogResponse>  {
    const dialogData = {
      success: DialogResponse.accept,
      fail: null,
      successString: AcceptString,
      failString : null,
      bodyText: BodyText,
      HasCancel: false
    };

    return this.OpenComponentDialog(GeneralDialogComponent, dialogData, config);
  }

  public openConfirmationDialog(
        BodyText= ['confirm or deny'],
        ConfirmString= 'Confirm',
        cancelString= 'Cancel',
        config: DialogConfig = new DialogConfig()): Promise<DialogResponse> {
    const dialogData =  {
      success: DialogResponse.confirm,
      fail: DialogResponse.cancel,
      successString: ConfirmString,
      failString: cancelString,
      bodyText: BodyText,
      HasCancel: false
    };

    return this.OpenComponentDialog(GeneralDialogComponent, dialogData, config);
  }
  public openLoginDialog(authService: AuthenticationService, message: string = null, config: DialogConfig = null): Promise<LoginOutput> {
    if (config == null) {
      config = new DialogConfig();
      config.disableClose = true;
      config.width = 'max-content';
      config.panelClass = ['login-panel'];
    }
    return this.OpenComponentDialog(LoginComponent,
       { ShowCancel: false, AuthService : authService, Message: message }, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public openCustomDialog(component: ComponentType<any>, config: DialogConfig, dialogData: any): Promise<any>  {
    return this.OpenComponentDialog(component, dialogData, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public openBroadcastPatientDialog(component: ComponentType<any>, config: DialogConfig = new DialogConfig(), dialogData): Promise<any> {
    return this.OpenComponentDialog(component, dialogData, config);
  }
}
