<div class="heading-invite">
  <div class="invite-header">
    <div class="dialog-patient-name">
      <div class="patient-name-focus">
        <span class="first-name">{{this.data?.patient?.firstName}}</span><span>&nbsp;</span>
        <span class="last-name">{{this.data?.patient?.lastName}}</span>
      </div>
      <div (click)="onViewPatient()" class="patient-name-heading">
        <strong>View {{customerText}} Profile</strong>
      </div>

    </div>
    <div class="right-header">
      <div class="order-no">
        {{orderNo()}}
      </div>
      <div id="closeButton" (click)="cancelManageOrder()" class="close-button">
        <i class="material-icons">
          clear
        </i>
      </div>
    </div>
  </div>
</div>
<div class="dialog-main-content">
  <div class="medication-order-header">
    <div class="row-header__left"><strong>Order status: {{data?.status}}</strong></div>
    <div class="btn-right">
      <button type id="resend-button" class="btn-ghost btn-icon-text" (click)="onResendMessage()"
        [disabled]="resendMessageDisabled">
        <span class="material-icons">email</span>
        <span>Resend to GP</span>
      </button>
    </div>
  </div>
  <div class="error-wrapper">
    <div id="resend-error" [class]="sendError ? 'error-message' : 'success-view'" *ngIf="messageResendSucces !== ''">{{
      messageResendSucces }}</div>
  </div>
  <details>
    <summary>
      <h4>Mark the status of the medications in the case of an incomplete fulfilment.</h4>
    </summary>
    <ul>
      <li><strong>Item on order and Ready to collect:</strong> If 'Item on order' or 'Ready to collect' is selected for
        any
        item, this order will remain in the Inbox tab.</li>
      <li><strong>Unable to fill:</strong> If all items are 'Unable to fill', this order will move to the Completed
        tab.</li>
    </ul>
  </details>
  <div class="medication-table">
    <div class="main-grid-drugs">
      <div class="medication-headers headers withfilters">
        <div class="medication-header-field col1">Medication</div>
        <div class="medication-header-field col2">Status</div>
        <div class="medication-header-field col3"></div>
      </div>
      <cdk-virtual-scroll-viewport class="list-container" #mainViewport [itemSize]="70" templateCacheSize=0>
        <ng-container *cdkVirtualFor="let component of dispenseRequest?.components let i = index">
          <ng-container id="medicationItem" class="medication">
            <div class="gridrow medication-row">
              <div class="medicationrow col1"><strong>{{component?.drug?.labelName}}</strong></div>
              <div class="medicationrow col2">
                <div class="contact-details">
                  {{displayStatus(component)}}
                </div>
              </div>
              <div class="medicationrow col3 actions">
                <div class="actions">

                  <select [ngModel]="statusToNumber(component?.statusModel?.status)" class="filter-drugs-item"
                    (change)="onActionChange($event, component)" [disabled]="isTerminalState">
                    <option hidden value="0" selected>Select response</option>
                    <option *ngIf="!isDelivery()" value="1">Ready to collect</option>
                    <option *ngIf="isDelivery()" value="15">Mark for delivery</option>
                    <option value="13">Item on order</option>
                    <option value="12">Unable to fill</option>
                  </select>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>        
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
  <div class="note-from-patient">
    <strong> {{Notes()}} </strong>
  </div>
  <div class="divide-line"></div>
  <div>
    <div class="message-patient">
      <p>Add a note for your {{customerText | lowercase}} to explain the situation</p>
      <div class="form-group" enctype="multipart/form-data">
        <div class="input-wrapper">
          <textarea [(ngModel)]="patientMessage" rows="4" cols="100" class="form-input message-form" placeholder=""
            [disabled]="isMessageFormDisabled"></textarea>
        </div>
      </div>
      <div *ngIf="!isTerminalState">
        <button class="btn-default-blue btn-text send-message-button" (click)="onSave()" [disabled]="isMessageFormDisabled">
          Send Message
        </button>
        <button class="btn-default-blue btn-text send-message-button" (click)="onSave()">
          Save
        </button>
      </div>
      <button *ngIf="isTerminalState" class="btn-default-blue btn-text send-message-button" (click)="cancelManageOrder()">
        Close
      </button>
    </div>
  </div>
  <div class="loader-new" *ngIf="isComponentLoading">
    <ng-container>
      <app-msg-loadingrequest></app-msg-loadingrequest>
    </ng-container>
  </div>
</div>
  