<div class='pageContent'>
  <div class="button-invite">
    <button class="btn-default-blue btn-invite" (click)="switchScreen()" id="addNewPatientRecordNavigation">
      <i class="material-icons">
        add
      </i>
      ADD NEW GP SURGERY</button>
  </div>
  <div *ngIf="displayScreen ==='gpListDefault'" class="page-surgery-list">
    <div class="main-grid-panel">
      <div class="gridrow headers withfilters">
        <div class="header-field col1">GP Surgery Name</div>
        <div class="header-field col2">Contact details</div>
        <div class="header-field col3">Delivery method
          <!-- <div class="input-wrapper">
            <select name="delivery" id="delivery" appFilter="Method" class="form-input filter-header extrawide"
              placeholder="<Please Select>">
              <option value="all">All</option>
              <option value="pdf">Manual (PDF)</option>
              <option value="email">Email</option>
              <option value="fax">Fax</option>
            </select> 
          </div>-->
        </div>
        <div class="header-field col4">Notes</div>
        <div class="header-field col5">How long before prescriptions are overdue?</div>
        <div class="header-field col6">Last updated</div>
        <div class="header-field col7">Actions</div>
      </div>

      <app-loader></app-loader>

      <cdk-virtual-scroll-viewport class="list-container" #mainViewport [itemSize]="70" templateCacheSize=0>
        <ng-container *cdkVirtualFor="let record of datasource;let i = index" [ngSwitch]="getRowClass(record)">
          <ng-container id="practiceItem" class="record" *ngSwitchCase="'Practice'">
            <div class="gridrow">
              <div class="surgeryrow col1"><strong>{{name(record)}}</strong></div>
              <div class="surgeryrow col2">
                <div class="contact-details">
                  {{record?.practice?.phone && 'Phone: '}}{{phone(record?.practice)}}<br />{{record?.practice?.fax &&
                  'Fax: '}}{{fax(record?.practice)}}<br />{{email(record) }}<br />{{ address(record?.practice) }}
                </div>
              </div>
              <div class="surgeryrow col3">
                <div class="delivery-method">
                  {{deliveryMethod(record)}}
                </div>
              </div>
              <div class="surgeryrow col4">
                <div class="notes">
                  {{ notes(record) }}
                </div>
              </div>
              <div class="surgeryrow col5">
                <div class="overdue">
                  {{ overdue(record) }}
                </div>
              </div>
              <div class="surgeryrow col6">
                <div class="last-updated">
                  {{record.updateDate | date: 'mediumTime' }}<br />{{record.updateDate | date: 'dd-MM-yyyy'}}
                </div>
              </div>
              <div class="surgeryrow col7 actions">
                <button id="updateSurgeryNavigationButton" (click)="handleClick('Update')"
                  [routerLink]="['/surgeries', record.practiceId]" class="btn-default-blue btn-text">UPDATE</button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container class="practice" *ngIf="(datasource$ | async)?.length === 0">
          <div class="gridrow">
            <div class="surgeryrow col1"></div>
            <div class="surgeryrow col2"></div>
            <div class="surgeryrow col3">
              <div class="loading-spiner" style="border:solid 1px red;"
                style="grid-column:1/7; -ms-grid-column:1; -ms-grid-column-span:7">
                <strong>
                  No result
                </strong>
              </div>
            </div>
            <div class="surgeryrow col4"></div>
            <div class="surgeryrow col5"></div>
            <div class="surgeryrow col6"></div>
            <div class="surgeryrow col7 actions"></div>
          </div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
  <div *ngIf="displayScreen === 'gpSearch'">
    <div [hidden]=true>{{ (practices$ | async) }}</div>
    <div class="add-new-gp-workflow">
      <div class="search-form">
        <div class="GP-search-label">Enter GP surgery name or ODS code</div>
        <div class="GP-search-input">
          <input [(ngModel)]="gpNameSearch" type="text" class="gp-search" name="gpNameSearch" id="gpNameSearch"
            placeholder="Surgery name or ODS code" (keyup.enter)="onGPSurgerySearch()">
        </div>
        <div class="search-buttons">
          <button class="btn-default-blue btn-text" (click)="onGPSurgerySearch()">Search</button>
          <a class="clear-button" (click)="clearSearch()">Clear</a>
        </div>
      </div>
      <div class="blue-line"></div>
      <div class="search-result">
        <div class="search-summary">
          We found {{practices ? practices.length : 0}} matching surgeries
        </div>
        <div class="search-surgery-list">
          <div class="main-grid-panel">
            <cdk-virtual-scroll-viewport class="list-container" #mainViewport [itemSize]="100" [minBufferPx]="2000"
              [maxBufferPx]="2000" templateCacheSize=0>
              <ng-container *cdkVirtualFor="let practice of practices;let i = index">
                <ng-container id="practiceList">
                  <div class="gridrow">
                    <div class="surgeryrow col1"><strong>{{practiceName(practice)}}</strong></div>
                    <div class="surgeryrow col2">
                      <div class="contact-details">
                        Ph: {{phone(practice)}}<br />Fax: {{fax(practice)}}<br />{{ address(practice) }}<br /><a>{{
                          practice?.email }}</a>
                      </div>
                    </div>
                    <div class="surgeryrow col3 actions">
                      <button class="btn-default-blue btn-text " (click)="onPracticeSelected(practice)">SELECT</button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="displayScreen === 'practiceDetail'">
    <div [hidden]=true>{{ (practices$ | async) }}</div>

    <div class="add-new-gp-workflow">
      <div class="search-form">
        <div class="GP-search-label">Enter GP surgery name or ODS code</div>
        <div class="GP-search-input">
          <input [(ngModel)]="gpNameSearch" type="text" class="gp-search" name="gpNameSearch" id="gpNameSearch"
            placeholder="Surgery name or ODS code" (keyup.enter)="onGPSurgerySearch()">
        </div>
        <div class="search-buttons">
          <button class="btn-default-blue btn-text " (click)="onGPSurgerySearch()">Search</button>
          <a class="clear-button" (click)="clearSearch()">Clear</a>
        </div>
      </div>
      <div class="blue-line"></div>
      <div [hidden]=true>{{ (current$ | async) }}</div>

      <div class="surgery-detail-form">
        <div class="row surgery-item-detail">
          <div class="grid-row" enctype="multipart/form-data">
            <div><strong>Name:</strong></div>
            <div class="item-info"><strong>{{ current?.practice?.name}}</strong></div>
          </div>
        </div>
        <div class="row surgery-item-detail">
          <div class="grid-row" enctype="multipart/form-data">
            <div> <strong>Phone and fax:</strong> </div>
            <div class="item-info">
              Ph: {{current?.practice?.phone}} <br>
              Fax: {{current?.practice?.fax}}
            </div>
          </div>
        </div>
        <div class="row surgery-item-detail">
          <div class="grid-row" enctype="multipart/form-data">
            <div> <strong>Address:</strong> </div>
            <div class="item-info">
              {{address(current?.practice)}}
            </div>
          </div>
        </div>
        <div class="row surgery-item-detail">
          <div class="grid-row" enctype="multipart/form-data">
            <div><strong>Email: (nhs.net)</strong></div>
            <div class="input-wrapper">
              <input [(ngModel)]="communicationsTarget" type="text" name="email" (blur)="!formValid()" id="email"
                [class]="isVaild ? 'surgery-form surgery-form' :'surgery-form error '" placeholder="" />
              <div *ngIf="!isVaild" for="email" class="validation-messages">{{errorMessage}}</div>
            </div>
          </div>
        </div>
        <div class="row surgery-item-detail">
          <div class="grid-row" enctype="multipart/form-data">
            <div><strong>Fax:</strong></div>
            <div class="input-wrapper">
              <input [(ngModel)]="faxNumber" type="text" name="fax" (blur)="!formValid()" id="fax"
                [class]="isFaxVaild ? 'surgery-form surgery-form' :'surgery-form error '" placeholder="" />
              <div *ngIf="!isFaxVaild" for="fax" class="validation-messages">{{errorFaxMessage}}</div>
            </div>
          </div>
        </div>
        <div class="row surgery-item-detail">
          <div class="grid-row" enctype="multipart/form-data">
            <div><strong>How does this GP receive scripts requests?</strong></div>
            <div class="input-wrapper">
              <select [(ngModel)]="communicationsMethod" name="delivery" id="delivery"
                (ngModelChange)="onEndpointChange($event)" class="surgery-form extrawide" placeholder="<Please Select>">
                <option value="Print">Manual (PDF)</option>
                <option value="Email">Email</option>
                <option value="Fax">Fax</option>
              </select>
              <!-- <label *ngIf="validation.roleValidation" for="firstname" class="validation-messages"><sup>&#9642;</sup>{{validation.roleValidation}}</label> -->
            </div>
          </div>
        </div>
        <div class="row surgery-item-detail">
          <div class="grid-row" enctype="multipart/form-data">
            <div><strong>How long before scripts from this GP are overdue?</strong></div>
            <div class="input-wrapper overdue-timer">
              <input [(ngModel)]="current && current.preferences && current.preferences.hoursuntilscriptoverdue"
                type="text" name="overdue" (blur)="!formValid()" id="overdue"
                [class]="isVaild ? 'input-overdue' :'input-overdue error '" placeholder="" />
              <div class="hour-des">Hours</div>
            </div>
          </div>
        </div>
        <div class="form-group button-group" enctype="multipart/form-data">
          <button id="addPracticeButton" (click)="savePracticeDetails()" class="button-dialog btn-primary">Add</button>
          <button (click)="handleButton('cancel')" class="button-dialog btn-cancel-dialog">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>