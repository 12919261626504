import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {AuthenticationService} from '../../authentication/authentication.service';
import { AppConfigService } from '../../app-config.service';
import { CommsModule } from 'src/app/utility/comms/comms.module';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogConfig, DialogService } from 'src/app/utility/base-dialog/dialog.service';
import { PatientAddComponent } from 'src/app/patients/patientadd/patientadd.component';
import { APIService } from 'src/app/api/api.service';
import { DataService } from 'src/app/utility/dataservice/data.service';
import { RoleGuardService } from 'src/app/authentication/roleguard.service';
import { PharmacyModel } from 'src/app/api/ApiRecordTypes/PharmacyRecord';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  // tslint:disable-next-line: no-inferrable-types
  isAuthenticated = false;
  isAuthenticated$ = new BehaviorSubject<boolean>(this.isAuthenticated);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  menuItems: any = null;
  public settings: PharmacyModel;
  public settings$ = new BehaviorSubject<PharmacyModel>(this.settingService.setting);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  showSidebar$ = new BehaviorSubject<boolean>(false);
  customerTextPlural = 'Patients';
  constructor(
    private authenticator: AuthenticationService,
    private configService: AppConfigService,
    private comms: CommsModule,
    private router: Router,
    private dialogs: DialogService,
    private api: APIService,
    public settingService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    private roleguard: RoleGuardService,
    private route: ActivatedRoute
  ) {
    this.comms.getAuthChanges().subscribe(status => {
      this.isAuthenticated = status;
      this.isAuthenticated$.next(this.isAuthenticated);
    });
    this.comms.getConfigSettingAccess().subscribe(accessSettings => {
      this.menuItems = (accessSettings && accessSettings.features && accessSettings.features.IncludeMenus) || null;
    });
  }

  ngOnInit() {
    this.config = this.configService.getConfig();
    if (this.config && this.config.features && this.config.features.ShowCustomerText) {
      this.customerTextPlural = 'Customers';
    }

    this.authenticator.authDataSource$.subscribe(
      mission => {
        this.getPharmacySettings(mission);
      }
    );
    this.comms.getSidebarStateAccess().subscribe((state: boolean) => {
     if (state !== this.showSidebar$.value) {
       this.showSidebar$.next(state);
     }
    });
  }

  getPharmacySettings(account) {
    this.api.getPharmacySettings(account.id).subscribe((result) => {
        this.settingService.setSettingsObs(result);
        this.changeDetectorRef.detectChanges();
    });
  }


  onClickLink() {
    this.comms.requestSidebarStateToggle(false);
    this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };

    const currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate(['/messages']);
      });
  }

  async logout() {
    try {
      this.comms.requestSidebarStateToggle(false);
      const curr_route = this.route.root.children['0'];
      this.roleguard.logOut = true;
      const result =  await this.roleguard.canActivate(curr_route.snapshot);
      if (result) {
            return this.authenticator.logout();
      }
    } catch (error) {
        console.error(error);
    }
  }

  formatAddress(pharmacySettings) {
    return pharmacySettings
      && `${pharmacySettings.address.city && `${pharmacySettings.address.city}, `}${pharmacySettings.address.postCode && `${pharmacySettings.address.postCode}`}`;
  }

  invite() {
    if (this.isAuthenticated) {
      const dialogConfig = new DialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.width = '1200px';
      dialogConfig.height = '650px';
      this.dialogs.OpenComponentDialog(PatientAddComponent, { ShowCancel: true }, dialogConfig).then((result) => {
        if (result != null) {
          console.log('New patient code was: ' + result.data.id);
        } else {
          console.log('Closed externally');
        }
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSwipeLeft(e) {
    this.comms.requestSidebarStateToggle(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSwipeRight(e) {
    this.comms.requestSidebarStateToggle(true);
  }
}
