import {
  AfterContentInit, AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {catchError, finalize, map, mergeMap, tap} from 'rxjs/operators';
import { BasicDialogComponent } from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import { PatientSearchDialogComponent } from 'src/app/booking-calendar/components/patient-search-dialog/patient-search-dialog.component';
import { CalendarDialogService } from 'src/app/booking-calendar/services/calendar-dialog.service';
import { DialogConfig } from 'src/app/utility/base-dialog/dialog.service';
import {
  BookingAvailabilityModel,
  EventBookingModel,
  EventRecurrenceModel,
} from '../../../api/ApiRecordTypes/EventResourceModel';
import { CalendarEventService } from '../../services/calendar-event.service';
import { CalendarValidation } from '../../utils/calendar-validation.validator';
import { IAPIService } from '../../../api/iapi.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {EMPTY, Observable, of} from 'rxjs';
import { PrescreenFormModel } from '../../../api/ApiRecordTypes/PreScreenFormModel';
import { CalendarConstant } from '../../constant/calendar.constant';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { cloneDeep } from 'lodash';
import { HSHCoreStructurePatientPatientModel } from 'src/app/api/ApiRecordTypes/swaggerSchema-hsh-v1';
import { EventResourceResult } from '../../../api/ApiRecordTypes/EventResourceResult';
import {ToastrService} from 'ngx-toastr';
import {UtilsService} from '../../../utility/utilsservice/UtilsService';

class BookingText {
  // #region Properties
  public static cancelText = 'Cancel';

  public static acceptText = 'Add';

  public static saveText = 'Save';

  public static addBookingCalendar = 'Add a booking for a patient';

  public static editBookingCalendar = 'Edit booking';

  public static patientName = 'Patient name';

  public static patientFirstName = 'First name';

  public static patientLastName = 'Last name';

  public static patientNoteTitle = 'Patient notes';

  public static patientNotes = 'Add notes about this patient';

  public static patientEmail = 'Email address';

  public static patientPhone = 'Mobile number';

  public static templateServiceAvailable = 'ServiceAvailable';

  public static templateNoServiceAvailable = 'NoServiceAvailable';

  public static duration = 'NoServiceAvailable';

  public static deleteDialogContent = 'Are you sure you want to delete this booking?';

  public static room = 'Room / Resource *';

  public static serviceTypeTitle = 'Service type *';
  // #endregion
}

export enum PrescreenDisplay {
  // eslint-disable-next-line no-unused-vars
  Optional,
  // eslint-disable-next-line no-unused-vars
  Disabled,
  // eslint-disable-next-line no-unused-vars
  Mandatory
}

export enum RecurrenceType {
  Daily,
  Weekly,
  Monthly,
  Yearly
}

@Component({
  selector: 'app-booking-dialog',
  templateUrl: './booking-dialog.component.html',
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY'
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMM YYYY'
        }
      }
    }
  ]
})
export class BookingDialogComponent extends BasicDialogComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterContentInit, AfterViewChecked {
  // #region Properties
  public bookingText = BookingText;

  public isAddBookingService = true;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public resources: any[];

  public servicesAvailable: BookingAvailabilityModel[];

  public durations = [5, 10, 15, 20, 30, 45, 60];

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public selectedResource: any;

  public selectedService: BookingAvailabilityModel;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public settings: any;

  public eventId: number;

  public startTime: Date | string = moment().startOf('day').hour(9).toDate();

  public endTime: Date | string;

  // #region FormControl
  public patientFirstNameControl: UntypedFormControl;

  public patientLastNameControl: UntypedFormControl;

  public emailControl: UntypedFormControl;

  public mobileControl: UntypedFormControl;

  public dobControl: UntypedFormControl;

  public resourceControl: UntypedFormControl;

  public serviceControl: UntypedFormControl;

  public dateControl: UntypedFormControl;

  public timeControl: UntypedFormControl;

  public durationControl: UntypedFormControl;

  public sendConfirmationControl: UntypedFormControl;

  public sendReminderControl: UntypedFormControl;

  public notesControl: UntypedFormControl;

  public manageBookingForm: UntypedFormGroup;
  // #endregion

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public config: any;

  public patientId: number;

  public bookingRef: string;

  public bookedResourceId: number;

  public currentTime = new Date(Math.round(new Date().getTime() / (30 * 1000 * 60)) * (30 * 1000 * 60));

  public time = new Date(this.currentTime.getTime() + this.currentTime.getTimezoneOffset() * 60000);

  public patientList: HSHCoreStructurePatientPatientModel[];

  public isPatientListNotLoaded = true;

  public shouldShowReminderCheckbox: boolean;

  public shouldSendEmail = true;

  public shouldSendReminder = true;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public editButton: string | TemplateRef<any>;

  public submitButton: string;

  public hasPreForm;

  public openHourRange: string[] = [];

  public openHourGapRange: number[] = [];

  public numberPartOfMinutes = [];

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public WeekDayOperatingHours: any;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public earliestOpening: any;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public latestClosing: any;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public currentOpeningHour: any;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public currentOpeningMin: any;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public currentCloseHour: any;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public currentCloseMin: any;

  public prescreenFormModel: PrescreenFormModel[];

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public prescreenData: {[key: string]: any};

  public maxDate = moment().toDate();

  public minDate = moment('01/01/1901').toDate();

  public currentResourceDetail: EventResourceResult;

  public dobErrorMsg = CalendarConstant.dobErrorMsg;

  public dobValidation = {
    format: true,
    dayInPast: true,
    dayBefore1901: true
  };

  public dateErrorMsg = CalendarConstant.dateErrorMsg;

  public dateValidation = {
    required: true,
    format: true
  };

  public preVaccinationQuestions = CalendarConstant.preVaccinationQuestions;

  public deletedServiceId: number;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private stylesMap: Map<any, Node> = new Map();

  private host: Node;

  public formState: boolean;
  // #endregion

  // #region Accessors
  @ViewChild('deleteBookingContent', { static: true })
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public deleteContentDialog: TemplateRef<any>;

  @ViewChild('MoreOption', { static: true })
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public moreOption: TemplateRef<any>;

  @ViewChildren('hourIndicator', { read: ElementRef })
  public hourIndicator: QueryList<ElementRef>;

  @ViewChild('firstNameInput', { static: true, read: ElementRef })
  public firstNameInput: ElementRef<HTMLInputElement>;

  public get handleSelectServiceByResource (): string {
    if (!this.servicesAvailable) { return ''; }
    if (this.servicesAvailable.length === 0) { return this.bookingText.templateNoServiceAvailable; }
    return this.bookingText.templateServiceAvailable;
  }

  public get shouldShowAvailabilitySection () {
    if (this.resourceControl.value &&
        this.resourceControl.value > 0 &&
        this.serviceControl.value &&
        this.serviceControl.value > 0) {
      return true;
    }
    return false;
  }

  public get dateFormated () {
    return moment(this.dateControl.value).format('MMMM DD, YYYY');
  }
  // #endregion

  // #region Constructor
  public constructor (
    protected router: Router,
    protected eventService: CalendarEventService,
    protected dialogService: CalendarDialogService,
    protected api: IAPIService,
    protected changeDetect: ChangeDetectorRef,
    protected toast: ToastrService
  ) {
    super(router);

    this.patientFirstNameControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(2)]);
    this.patientLastNameControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(2)]);
    this.emailControl = new UntypedFormControl(null, [CalendarValidation.emailValidator()]);
    this.mobileControl = new UntypedFormControl(null, [CalendarValidation.mobileNoValidator()]);
    this.dobControl = new UntypedFormControl('', [CalendarValidation.dobValidator()]);
    this.resourceControl = new UntypedFormControl(null, [Validators.required]);
    this.serviceControl = new UntypedFormControl(null, [Validators.required]);
    this.dateControl = new UntypedFormControl(moment.utc().toDate(), [Validators.required]);
    this.timeControl = new UntypedFormControl(moment.utc().format('HH:mm'), [Validators.required]);
    this.durationControl = new UntypedFormControl(30);
    this.sendConfirmationControl = new UntypedFormControl(true);
    this.sendReminderControl = new UntypedFormControl(true);
    this.notesControl = new UntypedFormControl('');

    this.manageBookingForm = new UntypedFormGroup({
      firstName: this.patientFirstNameControl,
      lastName: this.patientLastNameControl,
      email: this.emailControl,
      mobile: this.mobileControl,
      dob: this.dobControl,
      resource: this.resourceControl,
      service: this.serviceControl,
      date: this.dateControl,
      time: this.timeControl,
      duration: this.durationControl,
      sendConfirmation: this.sendConfirmationControl,
      sendReminder: this.sendReminderControl,
      notes: this.notesControl
    });

    this.host = document.head;
  }

  // #endregion

  // #region Life cycle
  public ngOnInit () {
    this.shouldSendReminderSMS();

    this.preloadPatientList();
    // Preload screening form
    this.getBookingPrescreenFormInfo();
  }

  public ngAfterContentInit () {
    const formStateSubscription = this.manageBookingForm.statusChanges.pipe(
      map(value => {
        if (this.serviceControl.value === 0 || value === 'INVALID') {
          return true;
        }
        return false;
      })
    ).subscribe(state => {
      this.formState = state;
      this.changeDetect.detectChanges();
    });
    this._subscription.add(formStateSubscription);
  }

  public ngAfterViewInit () {
    if (this.firstNameInput) {
      this.firstNameInput.nativeElement.focus();
    }
    const dateChangeSubscription = this.dateControl.valueChanges
      .subscribe(value => {
        this.shouldSendReminderSMS();
        this.genHourTimelineArray(value);
        this.changeDetect.markForCheck();
      });
    this._subscription.add(dateChangeSubscription);

    const initBookingToTimeLineSubscription = this.hourIndicator.changes.subscribe(elements => {
      const currentHour = moment(this.time).hours();
      const currentMin = moment(this.time).minutes();
      // tslint:disable-next-line:max-line-length
      let startIndex = ((currentHour - this.currentOpeningHour) * 60 + (currentMin - this.currentOpeningMin)) / 5 + (this.currentOpeningMin === 30 ? 6 : 0);
      const endIndex = startIndex + this.durationControl.value / 5;
      const indicatorElementArray = elements.toArray();

      // Fill close hour background
      const startIndexArray = [];
      if (this.currentOpeningMin > 0) {
        const openEndIndex = ((this.currentOpeningMin) / 5) - 1;
        for (let i = 0; i <= openEndIndex; i++) {
          startIndexArray.push(i);
        }
      }
      const endIndexArray = [];
      if (this.currentCloseMin > 0) {
        const closeStartIndex = (((this.currentCloseHour - this.currentOpeningHour) * 60) / 5) + 6;
        const closeEndIndex = closeStartIndex + (this.currentCloseMin / 5) - 1;
        if (closeStartIndex > 0 && closeEndIndex > 0) {
          for (let i = closeStartIndex; i <= closeEndIndex; i++) {
            endIndexArray.push(i);
          }
        }
      }
      const closedHourIndexArray = [...Array.from(new Set([...startIndexArray, ...endIndexArray]))];

      indicatorElementArray.forEach((ele, index) => {
        if (closedHourIndexArray.includes(index)) {
          ele.nativeElement.classList.add('styleClosedHour');
        }
      });

      // remove booking style
      indicatorElementArray.forEach(ele => ele.nativeElement.classList.remove('styleBooking'));
      if (endIndex < startIndex || (endIndex < 0 && startIndex < 0)) {
        return;
      }
      if (startIndex < 0 && endIndex > 0) {
        startIndex = 0;
      }

      indicatorElementArray.slice(startIndex, endIndex).forEach(ele => {
        ele.nativeElement.classList.add('styleBooking');
      });
    });
    this._subscription.add(initBookingToTimeLineSubscription);
  }

  public ngAfterViewChecked(): void {
    if (this.firstNameInput && document.activeElement ===  this.firstNameInput.nativeElement) {
      this.changeDetect.detectChanges();
    }
  }

  // #endregion

  // #region Methods
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public setData (data: any) {
    super.setData(data);
    this.resources = data.settings.resources;
    this.resourceControl.patchValue(0);
    this.WeekDayOperatingHours = data.settings.operatingHours;
    this.earliestOpening = data.settings.earliestOpening;
    this.latestClosing = data.settings.latestClosing;

    // Edit booking: check data.settings.eventId;
    if (data.settings.eventId && data.settings.event) {
      this.isAddBookingService = false;
      this.editButton = this.moreOption;
      this.submitButton = this.bookingText.saveText;
      this.headerTitle = this.bookingText.editBookingCalendar;
      this.getEventBooked(data.settings.event);
      this.eventId = data.settings.eventId;
      this.genHourTimelineArray(data.settings.event.startDate);
      if (!data.settings.event.isGuest) {
        this.patientFirstNameControl.disable();
        this.patientLastNameControl.disable();
        this.dobControl.disable();
        this.emailControl.disable();
        this.mobileControl.disable();
      }
      return;
    }

    // Add booking with button booking click: default, none check
    // Date: local date now
    // Time: local time now and round down 30min
    // Timeline: current operating hour time for business day and holiday are earliest opening, latest closing
    this.editButton = this.bookingText.cancelText;
    this.submitButton = this.bookingText.acceptText;
    this.headerTitle = this.bookingText.addBookingCalendar;

    // Add booking with button booking click: default, none check
    // Date: local date now
    // Time: local time now and round down 30min
    // Timeline: current operating hour time for business day and holiday are earliest opening, latest closing

    // Add from dblclick on date: check data.settings.currentDate
    // Date: local date now
    // Time: local time now and round down 30min
    if (data.settings.currentDate) {
      const weekDay = moment(data.settings.currentDate).day();
      const weekDayOperatingHour = this.WeekDayOperatingHours.find(day => day.weekday === weekDay);
      this.genHourTimelineArray(data.settings.currentDate);
      this.dateControl.patchValue(data.settings.currentDate);
      this.time.setMinutes(weekDayOperatingHour.startMinute);
      this.time.setHours(weekDayOperatingHour.startHour);
    } else {
      const currentDate = moment.utc().format('YYYY-MM-DDTHH:mm');
      this.genHourTimelineArray(currentDate);
    }
  }

  // Preload patient list when user click on select patient button
  public preloadPatientList () {
    const getPatientListSubscription = this.eventService.retrieveListPatientAsync()
      .pipe(
        tap(patients => {
          this.patientList = patients;
          this.isPatientListNotLoaded = false;
        })
      )
      .subscribe();
    this._subscription.add(getPatientListSubscription);
  }

  public handleAccept (): void {
    const controls = this.manageBookingForm.controls;
    for (const key of Object.keys(controls)) {
      if (controls[key].errors) {
        return;
      }
      continue;
    }
    const bookingRequestBody = new EventBookingModel();

    const resourceName = this.selectedResource && this.selectedResource.title;
    const service = this.selectedService || this.servicesAvailable.find(serviceItem => serviceItem.id === this.serviceControl.value);
    const serviceName = service && service.name;
    const bookedTime = moment(this.timeControl.value, 'HH:mm A').format('HH:mm');
    const dateControlValue = typeof this.dateControl.value === 'string' ? this.dateControl.value : moment(this.dateControl.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const startDate = `${dateControlValue}T${bookedTime}`;
    const endDate = moment(startDate, 'YYYY-MM-DDTHH:mm').add('minutes', this.durationControl.value).format('YYYY-MM-DDTHH:mm');

    if (this.dobControl.value) {
      const dob = typeof this.dobControl.value === 'string'
        ? moment(this.dobControl.value).format('DD/MM/yyyy')
        : this.dobControl.value.format('DD/MM/yyyy');
      bookingRequestBody.dateOfBirth = dob;
    }
    bookingRequestBody.includeResourceAvailability = true;
    bookingRequestBody.id = this.eventId ? this.eventId : 0;
    bookingRequestBody.startDate = startDate;
    bookingRequestBody.endDate = endDate;
    bookingRequestBody.resourceId = this.resourceControl.value;
    bookingRequestBody.firstName = this.patientFirstNameControl.value;
    bookingRequestBody.lastName = this.patientLastNameControl.value;
    bookingRequestBody.resourceName = resourceName;
    bookingRequestBody.capabilityId = this.serviceControl.value;
    bookingRequestBody.capabilityName = serviceName;
    bookingRequestBody.notes = this.notesControl.value;
    bookingRequestBody.phone = this.mobileControl.value;
    bookingRequestBody.email = this.emailControl.value;
    // tslint:disable-next-line:max-line-length
    bookingRequestBody.duration = typeof this.durationControl.value === 'string' ? +this.durationControl.value.split(' ')[0] : this.durationControl.value;
    bookingRequestBody.shouldSendConfirmation = this.sendConfirmationControl.value;
    bookingRequestBody.type = 'booking';
    bookingRequestBody.reminderPriorHours = '';
    if (this.patientId) {
      bookingRequestBody.patientId = this.patientId;
    }
    if (this.shouldShowReminderCheckbox) {
      bookingRequestBody.shouldSendReminder = this.sendReminderControl.value;
    }

    const message = (this.eventId === 0 || this.eventId) ? 'Success|Booking saved' : 'Success|Booking added';
    const bookingRequestSubscription = this.eventService.createOrEditEventBooking(bookingRequestBody)
      .pipe(
        finalize(() => {
          this.eventService.setToast(message);
          super.handleAccept();
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError(err => {
          this.eventService.setToast('Error|An error occurred!');
          return EMPTY;
        })
      )
      .subscribe();
    this._subscription.add(bookingRequestSubscription);
  }

  // #region Actions
  public handleSelectResource (event, serviceId?: number, serviceName?: string): void {
    const resourceId = this.resourceControl.value;
    if (!resourceId) {
      this.servicesAvailable = [];
      return;
    }
    this.selectedResource = this.resources.find(resource => resource.id === Number(this.resourceControl.value));
    const getEventResourceSubscription = this.eventService.getResourceById(resourceId)
      .pipe(
        map(data => {
          if (!data || !data.resources || !data.resources.length) {
            this.servicesAvailable = [];
            return true;
          }
          // Assign resource detail
          this.currentResourceDetail = data;
          // Get the service list belonged to the resource
          const servicesAssignedWithResource = data.resources[0].availableServices;
          // Whether which service is available to choose and sort them
          this.servicesAvailable = servicesAssignedWithResource.filter(service => service.resourceAvailable).sort(function (a, b) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          // Case: Edit booking
          if (serviceId) {
            this.selectedService = this.servicesAvailable.find(service => service.id === Number(serviceId));
            // Deleted service
            if (!this.selectedService) {
              const deletedService = new BookingAvailabilityModel();
              deletedService.id = serviceId;
              this.deletedServiceId = serviceId;
              deletedService.friendlyName = serviceName;
              this.servicesAvailable.push(deletedService);
            }
            this.serviceControl.patchValue(serviceId);
            this.hasPreForm = !(!this.selectedService || this.selectedService.prescreenRequirement === PrescreenDisplay.Disabled);
            return this.servicesAvailable;
          } else {
            this.serviceControl.patchValue(0);
            this.serviceControl.markAsUntouched();
            this.serviceControl.markAsPristine();
          }

          return true;
        }),
        mergeMap(servicesAvailable => {
          if (servicesAvailable === true) {
            return of(true);
          }
          // Case: Edit booking
          const date = moment(this.dateControl.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
          return this.retrieveAvailableTimeslotNewBooking(resourceId, serviceId, date);
        })
      ).subscribe();

    this._subscription.add(getEventResourceSubscription);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleSelectServiceOrSelectDate (event?: Event): void {
    const serviceId = this.serviceControl.value;
    const resourceId = this.resourceControl.value;
    const date = typeof this.dateControl.value === 'string'
      ? moment(this.dateControl.value, 'YYYY-MM-DD').format('DD/MM/YYYY')
      : moment(this.dateControl.value, 'DD/MM/YYYY').format('DD/MM/YYYY');

    if (!serviceId || !date) {
      return;
    }

    this.selectedService = this.servicesAvailable.find(service => service.id === Number(serviceId));
    if (this.selectedService.prescreenRequirement === PrescreenDisplay.Disabled) {
      this.hasPreForm = false;
    } else {
      this.hasPreForm = true;
    }
    this.handleDurationChange(this.selectedService.standardDuration);

    const getAvailableTimeslotSubscription = this.retrieveAvailableTimeslotNewBooking(resourceId, serviceId, date)
      .subscribe();
    this._subscription.add(getAvailableTimeslotSubscription);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public handleDateChange (event: MatDatepickerInputEvent<any>) {
    if (!event.value) {
      return;
    }

    const dateChange = moment(event.value).format('DD/MM/yyyy');
    if (!this.selectedService || !this.selectedService.id || !this.resourceControl.value) {
      return;
    }
    // tslint:disable-next-line:max-line-length
    const getAvailableTimeslotSubscription = this.retrieveAvailableTimeslotNewBooking(this.resourceControl.value, this.selectedService.id, dateChange)
      .subscribe();
    this._subscription.add(getAvailableTimeslotSubscription);
  }

  private getEventBooked (event) {
    this.resourceControl.patchValue(event.resourceId);
    // this.serviceControl.patchValue(event.capabilityId);
    this.handleSelectResource(event.resourceId, event.capabilityId, event.capabilityName);
    const firstName = UtilsService.unescapeString(event.firstName);
    const lastName = UtilsService.unescapeString(event.lastName);
    const mobile = event.phone || event.mobile;

    this.patientFirstNameControl.patchValue(firstName);
    this.patientLastNameControl.patchValue(lastName);
    this.emailControl.patchValue(event.email);
    this.mobileControl.patchValue(mobile);
    if (event.dateOfBirth) {
      this.dobControl.patchValue(moment(event.dateOfBirth, 'DD/MM/YYYY'));
    }
    this.resourceControl.patchValue(event.resourceId);
    this.dateControl.patchValue(this.formatDate(event.startDate));
    this.timeControl.patchValue(moment(event.startDate).format('hh:mm A'));
    const hour = moment(event.startDate).get('hours');
    const min = moment(event.startDate).get('minute');
    this.time.setHours(hour);
    this.time.setMinutes(min);
    this.durationControl.patchValue(event.duration);
    if (!this.durations.includes(event.duration)) {
      this.durations.push(event.duration);
    }
    this.sendConfirmationControl.patchValue(event.shouldSendConfirmation);
    this.shouldSendEmail = event.shouldSendConfirmation;
    this.sendReminderControl.patchValue(event.shouldSendReminder);
    this.shouldSendReminder = event.shouldSendReminder;
    this.notesControl.patchValue(event.notes);
    this.bookingRef = event.bookingRef;
    this.patientId = event.patientId;
    this.bookedResourceId = event.resourceId;

    this.shouldSendReminderSMS();
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public onTimeChange (event: any) {
    this.shouldSendReminderSMS();
    if (this.hourIndicator) {
      const currentHour = moment(event).hours();
      const currentMin = moment(event).minutes();
      // tslint:disable-next-line:max-line-length
      let startIndex = ((currentHour - this.currentOpeningHour) * 60 + (currentMin - this.currentOpeningMin)) / 5 + (this.currentOpeningMin === 30 ? 6 : 0);
      const endIndex = startIndex + this.durationControl.value / 5;
      const indicatorElementArray = this.hourIndicator.toArray();
      indicatorElementArray.forEach(ele => ele.nativeElement.classList.remove('styleBooking'));
      if (endIndex < startIndex || (endIndex < 0 && startIndex < 0)) {
        return;
      }
      if (endIndex > 0 && startIndex < 0) {
        startIndex = 0;
      }
      indicatorElementArray.slice(startIndex, endIndex).forEach(ele => {
        ele.nativeElement.classList.add('styleBooking');
      });
    }
  }

  public handleDurationChange (duration) {
    this.durationControl.patchValue(duration);
    if (this.hourIndicator) {
      const indicatorElementArray = this.hourIndicator.toArray();
      const startIndex = indicatorElementArray.findIndex(ele => ele.nativeElement.className.includes('styleBooking'));
      const endIndex = startIndex + duration / 5;
      indicatorElementArray.forEach(ele => ele.nativeElement.classList.remove('styleBooking'));
      indicatorElementArray.slice(startIndex, endIndex).forEach(ele => {
        ele.nativeElement.classList.add('styleBooking');
      });
    }
  }

  // Handle edit actions
  public optionButtonChange (event: string) {
    console.log(event);
    switch (event) {
      case 'Print pre-screening form':
        this.printForm();
        break;
      case 'Create a follow-up booking':
        this.createNewBooking();
        break;
      case 'Delete booking':
        this.deleteBooking();
        break;
      default:
        break;
    }
  }

  public deleteBooking () {
    const setting = { content: this.deleteContentDialog, cancelButtonTitle: 'No', acceptButtonTitle: 'Yes, delete' };
    this.dialogService.openConfirmDialog(setting)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(r => {
        super.handleAccept();
        return this.eventService.deleteBookingById(this.bookedResourceId, this.eventId).toPromise();
      })
      .catch(err => err);
  }

  public createNewBooking () {
    this.bookingRef = undefined;
    this.eventId = 0;
    this.isAddBookingService = true;
    this.notesControl.setValue(null);
    this.dateControl.patchValue(moment().utc(false));
    if (this.selectedService && this.selectedService.id && this.resourceControl.value) {
      const dateChange = moment().utc(false).format('DD/MM/yyyy');
      // tslint:disable-next-line:max-line-length
      const getAvailableTimeslotSubscription = this.retrieveAvailableTimeslotNewBooking(this.resourceControl.value, this.selectedService.id, dateChange)
        .subscribe();
      this._subscription.add(getAvailableTimeslotSubscription);
    }
    this.headerTitle = this.bookingText.addBookingCalendar;
    this.editButton = this.bookingText.cancelText;
    this.submitButton = this.bookingText.acceptText;
  }

  public printForm () {
    if (!this.prescreenFormModel) {
      return;
    }
    const printContents = document.getElementById('booking-print-section').innerHTML;
    // const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    // popupWin.document.open();
    // popupWin.document.write(CalendarConstant.printTemplate(printContents));
    // popupWin.document.close();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const frame = document.getElementById('bookingFormFrame') as HTMLIFrameElement as any;

    const newDocument = document.implementation.createHTMLDocument('Print Pre-Screen Form');
    newDocument.write(CalendarConstant.printTemplate(printContents));

    // Copy the new HTML document into the frame
    const destDocument = frame.contentDocument;
    const srcNode = newDocument.documentElement;
    const newNode = destDocument.importNode(srcNode, true);

    destDocument.replaceChild(newNode, destDocument.documentElement);
    setTimeout(() => {
      window.frames['bookingFormFrame'].focus();
      window.frames['bookingFormFrame'].print();
    }, 400);
  }

  public getBookingPrescreenFormInfo () {
    if (!this.bookingRef) { return; }
    const printSubscription = this.eventService.printPreScreenFormAsync(this.bookingRef)
      .pipe(
        tap(preSreenResult => {
          if (preSreenResult.length === 0) {
            this.prescreenFormModel = [new PrescreenFormModel()];
          } else {
            this.prescreenFormModel = preSreenResult.map(result => {
              const questions = cloneDeep(this.preVaccinationQuestions);
              questions.forEach(question => {
                question.answer = result.formField[question.question];
              });
              result.formField = questions;
              return result;
            });
          }
        })
      )
      .subscribe();
    this._subscription.add(printSubscription);
  }
  // #endregion

  // #region Modal
  public handleSearchPatient () {
    const config = new DialogConfig();
    config.width = '700px';
    config.panelClass = ['select-patient-dialog'];
    config.autoFocus = false;

    const settings = { patientList: this.patientList };
    this.dialogService.openEditDialog(PatientSearchDialogComponent, settings, config)
      .then((patient: HSHCoreStructurePatientPatientModel) => {
        if (!patient || !patient.identityPrivate) {
          this.manageBookingForm.markAsUntouched();
          return;
        }
        if (patient.identityPrivate.firstName) {
          this.patientFirstNameControl.patchValue(patient.identityPrivate.firstName);
        }
        this.patientFirstNameControl.disable();

        if (patient.identityPrivate.lastName) {
          this.patientLastNameControl.patchValue(patient.identityPrivate.lastName);
        }
        this.patientLastNameControl.disable();

        if (patient.identityPrivate.email) {
          this.emailControl.patchValue(patient.identityPrivate.email);
        }
        this.emailControl.disable();

        if (patient.identityPrivate.phone) {
          this.mobileControl.patchValue(patient.identityPrivate.phone);
        }
        this.mobileControl.disable();

        if (patient.identityPrivate.dateOfBirth) {
          this.dobControl.patchValue(moment(patient.identityPrivate.dateOfBirth, 'DD/MM/YYYY'));
        }
        this.dobControl.disable();

        this.patientId = patient.id;
      });
  }

  public handleOpenSettingModal () {
    this.data.settings = { openSettingModal: true, resourceId: this.resourceControl.value };
    this.OnComplete.emit(this.data.settings);
  }
  // #endregion

  // #region Utils
  // Whether shoudl send reminder or not
  public shouldSendReminderSMS () {
    const bookedTimeHour = moment(this.time).hours();
    const bookedTimeMin = moment(this.time).minutes();
    const date = typeof this.dateControl.value === 'string' ? this.dateControl.value : moment(this.dateControl.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const startTime = moment(`${date}T${bookedTimeHour}:${bookedTimeMin}`, 'YYYY-MM-DDTHH:mm').utc();

    const currentDate = new Date().getUTCDate();
    const currentMonth = new Date().getUTCMonth() + 1;
    const currentYear = new Date().getUTCFullYear();
    const currentHour = new Date().getUTCHours();
    const currentMin = new Date().getUTCMinutes();
    const currentTime = moment(`${currentYear}-${currentMonth}-${currentDate}T${currentHour}:${currentMin}`, 'YYYY-MM-DDTHH:mm').utc();

    const duration = startTime.diff(currentTime, 'minutes');
    this.shouldShowReminderCheckbox = duration > 24 * 60;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private retrieveAvailableTimeslotNewBooking (resourceId, serviceId, date): Observable<any> {
    const noOfDays = moment(date, 'DD/MM/YYYY').day();
    return this.eventService.retrieveAvailableTimeslotNewBooking(resourceId, serviceId, date, noOfDays)
      .pipe(tap(eventBookedSlots => {
        if (!eventBookedSlots) {
          return;
        }

        // Mapping booked time to index array
        const availableTimeslotArray = [];
        const unavailableTimeslots = eventBookedSlots[0];
        for (const unavailableTimeslot of unavailableTimeslots) {
          if (!unavailableTimeslot || !unavailableTimeslot.startDate || unavailableTimeslot.type === 'available') {
            continue;
          }
          const startHour = moment(unavailableTimeslot.startDate).hours();
          const startMin = moment(unavailableTimeslot.startDate).minutes();
          // tslint:disable-next-line:max-line-length
          const startIndex = ((startHour - this.currentOpeningHour) * 60 + (startMin - this.currentOpeningMin)) / 5 + (this.currentOpeningMin === 30 ? 6 : 0);

          const endHour = moment(unavailableTimeslot.endDate).hours();
          const endMin = moment(unavailableTimeslot.endDate).minutes();
          // tslint:disable-next-line:max-line-length
          const endIndex = ((((endHour - this.currentOpeningHour) * 60 + (endMin - this.currentOpeningMin)) / 5) - 1) + (this.currentOpeningMin === 30 ? 6 : 0);
          if (endIndex < startIndex) {
            continue;
          }
          for (let i = startIndex; i <= endIndex; i++) {
            availableTimeslotArray.push(i);
          }
        }

        // tslint:disable-next-line:max-line-length
        const unavailableTimeSlotIndex = [...Array.from(new Set(availableTimeslotArray))];
        const indicatorElementArray = this.hourIndicator.toArray();
        indicatorElementArray.forEach(ele => ele.nativeElement.classList.remove('styleBooked'));
        indicatorElementArray.forEach((ele, index) => {
          if (unavailableTimeSlotIndex.includes(index)) {
            ele.nativeElement.classList.add('styleBooked');
          }
        });
      }));
  }

  private formatDate (date) {
    if (!date) {
      return '';
    }
    return date.format('YYYY-MM-DD');
  }

  private formatTime (date) {
    const d = new Date(date);
    let hourTime = 'AM';
    const hour = '' + (d.getHours());
    let min = '' + d.getMinutes();
    min = min.length < 2 ? `0${min}` : min;
    if (+hour >= 12) {
      hourTime = 'PM';
    }
    if (hour === 'NaN' || min === 'NaN') {
      return '';
    }
    return `${hour}: ${min} ${hourTime}`;
  }

  private roundToNearestMinutes (start, roundTo) {
    let remainder = roundTo - (start.minute() + start.second() / 60) % roundTo;

    remainder = (remainder > roundTo / 2) ? remainder = -roundTo + remainder : remainder;
    return moment(start).add(remainder, 'minutes').seconds(0).format('hh:mm a');
  }

  private genHourTimelineArray (currentDate: string) {
    if (!currentDate) {
      return;
    }
    let start;
    let end;
    const weekDay = moment(currentDate).day();
    const currentOperatingHour = this.WeekDayOperatingHours.find(day => day.weekday === weekDay);

    if (currentOperatingHour.startHour === 0 &&
      currentOperatingHour.endHour === 0 &&
      currentOperatingHour.startMinute === 0 &&
      currentOperatingHour.endMinute === 0) {
      start = moment(this.earliestOpening, 'HH:mm:ss').hours();
      const latestClosing = moment(this.latestClosing, 'HH:mm:ss');
      end = latestClosing.minutes() === 30 ? latestClosing.hour() : moment(this.latestClosing, 'HH:mm:ss').hours() - 1;
      // start = moment('8:00:00', 'HH:mm:ss').hours();
      // end = moment('22:00:00', 'HH:mm:ss').hours();
      this.currentOpeningHour = start;
      this.currentOpeningMin = moment(this.earliestOpening, 'HH:mm:ss').minutes();
      this.currentCloseHour = end;
      this.currentCloseMin = moment(this.latestClosing, 'HH:mm:ss').minutes();
    } else {
      start = currentOperatingHour.startHour;
      end = currentOperatingHour.endMinute === 30 ? currentOperatingHour.endHour : currentOperatingHour.endHour - 1;
      this.currentOpeningHour = currentOperatingHour.startHour;
      this.currentOpeningMin = currentOperatingHour.startMinute;
      this.currentCloseHour = currentOperatingHour.endHour;
      this.currentCloseMin = currentOperatingHour.endMinute;
    }
    this.openHourRange.length = 0;
    for (let i = start; i <= end; i++) {
      this.openHourRange.push(moment(i, 'H').format('ha'));
    }

    this.numberPartOfMinutes.length = this.openHourRange.length * 12;
    this.numberPartOfMinutes.fill(0);
    this.openHourGapRange.length = this.openHourRange.length * 2;
    this.openHourGapRange.fill(0);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private filterAvailableTimeslotNewBooking (resourceId, date): Observable<any> {
    const startDay = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const endDay = moment(date, 'DD/MM/YYYY').add(1, 'days').format('YYYY-MM-DD');
    return this.eventService.retrieveEventBookings(startDay, endDay, resourceId)
      .pipe(tap((events) => {
        if (!events.bookings) {
          return;
        }

        // Array of available time: availabilityType = 1
        // Single
        // Recurrence
        const availableTimeSingle = [];
        const availableTimeRecurrence = [];
        // Array of unavailable time: availabilityType = 0
        // Single
        // Recurrence
        const unavailableTimeSingle = [];
        const unavailableTimeRecurrence = [];

        this.currentResourceDetail.resources.forEach(resource => {
          const availibilityExceptions = resource.availibilityExceptions
            .filter(booking => moment.utc(booking.startDate, 'DD/MM/YYYY').isSame(moment.utc(date, 'DD/MM/YYYY'), 'day'));
          const recurrenceBookings = resource.recurrencePattern
            .filter(booking => moment.utc(booking.startDate, 'DD/MM/YYYY').isSame(moment.utc(date, 'DD/MM/YYYY'), 'day'));
          if (resource.availabilityType === 1) {
            availableTimeSingle.push(...availibilityExceptions);
            availableTimeRecurrence.push(...recurrenceBookings);
          } else {
            unavailableTimeSingle.push(...availibilityExceptions);
            unavailableTimeRecurrence.push(...recurrenceBookings);
          }
        });

        // Merge availableTime and unavailableTime
        const availableTimeIndex = [];
        if (availableTimeSingle.length !== 0 || availableTimeRecurrence.length !== 0) {
          const availableTimes = [this.getTimeFromSetting(availableTimeRecurrence, date), ...availableTimeSingle];
          for (const availableTime of availableTimes) {
            if (!availableTime || !availableTime.startDate) {
              continue;
            }
            const currentHour = moment(availableTime.startDate).hours();
            const currentMin = moment(availableTime.startDate).minutes();
            const startIndex = ((currentHour - this.currentOpeningHour) * 60 + (currentMin - this.currentOpeningMin)) / 5;
            const endIndex = startIndex + (availableTime.duration / 5) - 1;
            for (let i = startIndex; i <= endIndex; i++) {
              availableTimeIndex.push(i);
            }
          }
        }
        const unavailableTimeIndex = [];
        if (unavailableTimeSingle.length !== 0 || unavailableTimeRecurrence.length !== 0) {
          const unavailableTimes = [this.getTimeFromSetting(unavailableTimeRecurrence, date), ...unavailableTimeSingle];
          for (const unavailableTime of unavailableTimes) {
            if (!unavailableTime || !unavailableTime.startDate) {
              continue;
            }
            const currentHour = moment(unavailableTime.startDate).hours();
            const currentMin = moment(unavailableTime.startDate).minutes();
            const startIndex = ((currentHour - this.currentOpeningHour) * 60 + (currentMin - this.currentOpeningMin)) / 5;
            const endIndex = startIndex + (unavailableTime.duration / 5) - 1;
            for (let i = startIndex; i <= endIndex; i++) {
              unavailableTimeIndex.push(i);
            }
          }
        }

        // Array of booked time index
        const bookedTimesArray = [];
        const bookedTimes = events.bookings;

        for (const bookedTime of bookedTimes) {
          if (!bookedTime || !bookedTime.startDate) {
            continue;
          }
          const currentHour = moment(bookedTime.startDate).hours();
          const currentMin = moment(bookedTime.startDate).minutes();
          const startIndex = ((currentHour - this.currentOpeningHour) * 60 + (currentMin - this.currentOpeningMin)) / 5;
          const endIndex = startIndex + (bookedTime.duration / 5) - 1;
          for (let i = startIndex; i <= endIndex; i++) {
            bookedTimesArray.push(i);
          }
        }

        // Array of unavailable time index

        // Array of available time index

        // Get hour indicator element array and reset class list
        const bookedTimesArrayIndex = Array.from(new Set(bookedTimesArray));
        const indicatorElementArray = this.hourIndicator.toArray();
        const closeHourIndexFirst = indicatorElementArray.length - 6;
        indicatorElementArray.forEach(ele => {
          ele.nativeElement.classList.remove('styleBooked');
          ele.nativeElement.classList.remove('styleAvailableTime');
        });

        const isPastDay = moment.utc(date, 'DD/MM/YYYY').isBefore(moment().utc(false), 'day');
        // Case: day in past: fill available time from booking
        if (isPastDay) {
          indicatorElementArray.forEach((ele, index) => {
            // booked color exclude available time
            if (!availableTimeIndex.includes(index)) {
              ele.nativeElement.classList.add('styleBooked');
            }
            // refill if availableTime was booked
            if (bookedTimesArrayIndex.includes(index)) {
              ele.nativeElement.classList.add('styleBooked');
            }
          });
          return;
        }
        // Case: to day and future
        indicatorElementArray.forEach((ele, index) => {
          // Merge booked time and unavailable time
          const unavailableTimes = Array.from(new Set([...bookedTimesArrayIndex, ...unavailableTimeIndex]));
          if (unavailableTimes.includes(index)) {
            ele.nativeElement.classList.add('styleBooked');
          }
          if (index >= closeHourIndexFirst) {
            ele.nativeElement.classList.add('styleCloseHour');
          }
        });
      }));
  }

  private getTimeFromSetting (recurrenceArray: EventRecurrenceModel[], date) {
    const newMappingRecurrence = [];
    recurrenceArray.forEach(recurrencePattern => {
    // if endDate in the past, skip check
      if (recurrencePattern.endDate) {
        const isPastDay = moment.utc(recurrencePattern.endDate, 'DD/MM/YYYY')
          .isBefore(moment().utc(false), 'day');
        if (isPastDay) {
          return;
        }
      }

      switch (recurrencePattern.recurrenceType) {
        case RecurrenceType.Daily: {
          // start plus 1 and end add 1 day to use isBetween function as expected
          const startDate = moment.utc(recurrencePattern.startDate, 'YYYY-MM-DDTHH:mm:ss').add(-1, 'days');
          const endDate = startDate.add(recurrencePattern.recurEvery + 1, 'days');
          const isInRecurrenceDay = moment.utc(date, 'DD/MM/YYYY').isBetween(startDate, endDate);
          if (!isInRecurrenceDay) {
            return;
          }
          newMappingRecurrence.push({ startTime: recurrencePattern.startTime, endTime: recurrencePattern.endTime });
          return;
        }
        case RecurrenceType.Weekly: {
          const startDate = moment.utc(recurrencePattern.startDate, 'YYYY-MM-DDTHH:mm:ss').add(-1, 'days');
          const startDateStr = startDate.format('YYYY-MM-DD');
          const endDate = startDate.add(recurrencePattern.recurEvery * 7 + 1, 'days');
          const endDateStr = endDate.format('YYYY-MM-DD');
          const currentDateStr = moment.utc(date, 'DD/MM/YYYY').format('YYYY-MM-DD');

          const isInRecurrenceDay = moment(currentDateStr).isBetween(startDateStr, endDateStr);
          const dayOfWeek = moment.utc(date, 'DD/MM/YYYY').day();
          if (!isInRecurrenceDay || !recurrencePattern.dates.includes(dayOfWeek)) {
            return;
          }
          newMappingRecurrence.push({ startTime: recurrencePattern.startTime, endTime: recurrencePattern.endTime });
          return;
        }
        case RecurrenceType.Monthly: {
          const currentMonth = moment.utc(date, 'DD/MM/YYYY').month();
          const startMonth = moment.utc(recurrencePattern.startDate, 'YYYY-MM-DDTHH:mm:ss').year();
          const endMonth = startMonth + recurrencePattern.recurEvery;
          if (currentMonth < startMonth || currentMonth > endMonth) {
            return;
          }
          const dateOfMonth = moment.utc('2021-12-18', 'YYYY-MM-DD').month();
          if (!recurrencePattern.dates.includes(dateOfMonth)) {
            return;
          }
          newMappingRecurrence.push({ startTime: recurrencePattern.startTime, endTime: recurrencePattern.endTime });
          return;
        }
        case RecurrenceType.Yearly: {
          const currentYear = moment.utc(date, 'DD/MM/YYYY').year();
          const startYear = moment.utc(recurrencePattern.startDate, 'YYYY-MM-DDTHH:mm:ss').year();
          const endYear = startYear + recurrencePattern.recurEvery;
          if (currentYear < startYear || currentYear > endYear) {
            return;
          }
          const monthOfDate = moment.utc(date, 'DD/MM/YYYY').month() + 1;
          if (!recurrencePattern.months.includes(monthOfDate)) {
            return;
          }
          newMappingRecurrence.push({ startTime: recurrencePattern.startTime, endTime: recurrencePattern.endTime });
          return;
        }
        default:
          return {};
      }
    });
    return newMappingRecurrence;
  }

  public print () {
    const originalTitle = document.title;
    document.title = 'MedAdvisor - Calendar';
    const printContents = document.getElementById('booking-print-section').innerHTML;
    const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(CalendarConstant.printTemplate(printContents));
    popupWin.document.close();
    document.title = originalTitle;
  }

  public handleResourceError () {
    if (this.resourceControl.value === 0) {
      this.resourceControl.setErrors({ required: true });
    } else {
      this.resourceControl.setErrors(null);
    }
  }

  public handleServiceError () {
    if (this.serviceControl.value === 0) {
      this.serviceControl.setErrors({ required: true });
    } else {
      this.serviceControl.setErrors(null);
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public handleDateInput (event: any): boolean {
    const key = event.key;
    if (event.key.length > 1 || key === '/' || key === '-') {
      return true;
    }
    const numberOnlyRegex = /^[0-9]*$/;
    if (numberOnlyRegex.test(key)) {
      return true;
    }
    return false;
  }

  public getErrorMessage (date: string, controlName: string, validator = {}, mesError = {}): string {
    if (!this.manageBookingForm.controls[controlName].touched) {
      this.manageBookingForm.controls[controlName].setErrors(null);
      return '';
    }
    if (!this.manageBookingForm.controls[controlName].invalid) {
      return '';
    }

    const format = date.split('/');
    const dob = moment(date, 'DD/MM/YYYY');
    const currentDate = moment(moment().utc(), 'DD/MM/YYYY');
    if (validator[`required`]) {
      if (!date || date === '') {
        this.manageBookingForm.controls[controlName].setErrors({});
        return mesError[`required`];
      }
    } else {
      if (!date || date === '') {
        this.manageBookingForm.controls[controlName].setErrors(null);
        return '';
      }
    }
    if (validator[`format`]) {
      if (
        format.length !== 3 ||
        format[0].length > 2 ||
        format[1].length > 2 ||
        format[2].length !== 4 ||
        !moment(date, 'DD/MM/YYYY').isValid()) {
        this.manageBookingForm.controls[controlName].setErrors({});
        return mesError[`format`];
      }
    }
    if (validator[`dayInPast`]) {
      if (moment(dob).diff(moment(currentDate), 'days') > 0) {
        this.manageBookingForm.controls[controlName].setErrors({});
        return mesError[`dayInPast`];
      }
    }
    if (validator[`dayBefore1901`]) {
      if (moment(dob).diff(moment('01/01/1901'), 'days') < 0) {
        this.manageBookingForm.controls[controlName].setErrors({});
        return mesError[`dayBefore1901`];
      }
    }
    this.manageBookingForm.controls[controlName].setErrors(null);
    return '';
  }

  public generateLastHour (i: number, hour: string) {
    if (i !== this.openHourRange.length - 1) {
      return '';
    }
    return moment(hour, 'ha').add(1, 'hour').format('ha');
  }
  // #endregion

  // #endregion
}
