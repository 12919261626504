import { Injectable} from '@angular/core';
import {v4 as uuid} from 'uuid';
import { IGlobalLoggingInterface } from './global-logging.interface';
import { ILogService } from './log-service.interface';
import { LogLevelEnum } from './log-level.enum';
import { LogEntryModel } from './log-entry.model';
import { LogTypeEnum } from './log-type.enum';
import { LogConsoleService } from './logging-services/log-console.service';
import { LogWebApiService } from './logging-services/log-web-api.service';
import { IAPIService } from '../api/iapi.service';
import {environment} from '../../environments/environment';


/**
 * Global logging service (handle logging for the whole application)
 */

@Injectable()
export class GlobalLoggingService implements IGlobalLoggingInterface  {
  private _transactionId: string;
  private _logService: ILogService[];

  constructor(
    private api: IAPIService) {
      this._transactionId = uuid();
      this._logService = this.getConfiguredLogger(LogTypeEnum[environment.logging]);
  }

  logDebug(message: string, methodName: string, className: string): void {
    this.log(message, methodName, className, LogLevelEnum.debug);
  }

  logInformation(message: string, methodName: string, className: string): void {
    this.log(message, methodName, className, LogLevelEnum.info);
  }

  logWarning(message: string, methodName: string, className: string): void {
    this.log(message, methodName, className, LogLevelEnum.warn);
  }

  logError(message: string, methodName: string, className: string): void {
    this.log(message, methodName, className, LogLevelEnum.error);
  }

  private log(message: string, methodName: string, className: string, logLevel: LogLevelEnum) {
    const logEntry = new LogEntryModel();
    logEntry.extraInfo = [`Log message location: ${methodName} in ${className}`, `client: global-pharmacy-portal`];
    logEntry.message = message;
    logEntry.logLevel = logLevel;
    this.logAsync(logEntry);
  }

  /**
   * Return a logger based on log type (in app.config.json).
   *
   * @param  logType log type (0: console, 1: storage, 2: webApi).
   */
  protected getConfiguredLogger(logType: LogTypeEnum): ILogService[] {
    switch (logType) {
      case LogTypeEnum.console:
        return [new LogConsoleService()];
      case LogTypeEnum.webApi:
        return [new LogConsoleService(), new LogWebApiService(this.api)];
      default:
        return [new LogConsoleService()];
    }
  }

  public logAsync(logEntry: LogEntryModel ) {
    if (!logEntry.extraInfo) {
      logEntry.extraInfo = [];
    }
    logEntry.extraInfo.push(`Transaction Id: ${this._transactionId}`);

    if (!logEntry.stackTrace && logEntry.logLevel > LogLevelEnum.warn) {
      logEntry.stackTrace = (new Error()).stack.replace('Error\n', '');
    }
    this.logToEachService(this._logService, logEntry);
  }

  private logToEachService(logServices: ILogService[], logEntry: LogEntryModel) {
    logServices.forEach(x => x.logAsync(logEntry.message, logEntry.stackTrace, logEntry.logLevel, logEntry.extraInfo).subscribe());
  }
}
