import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

import { PendingTaskRecord } from '../../../api/iapi.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-msg-patientsignup',
  templateUrl: './patientsignup.component.html'
})
export class PatientSignupComponent {
  @Input() patientData: PendingTaskRecord;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {
  }
}
