import { DrugModel } from './PendingTaskRecord';

export class PublicExternalSessionDetailsWithAuth {
    result: string;
    sessionId: string;
    userId: string;
    orgId: string;
    roleId: string;
    userName: string;
    userOrg: string;
    AuthJWT: string;
    activityCodes: string[];
    jobTitle: string;
}


export enum PatientProfileStatusEnum {
    Exist,
    NotExists,
    NoConsentHasRecord,
    IncorrectPermissions,
    UnableToDetermine,
    NoConsentAndNoRecord
  }

export enum ExternalPatientProfileErrorCode {
    ThirdPartySeverError,
    Unauthorised,
    Forbidden,
    Exception,
    ProfileNotFound
}

export enum SourceMedicines {
    Dispense = 0,
    Manual = 1,
    SCR = 2,
    IM1 = 3
}


export class NhsScrRequest {
    nhsSessionId: string;
    nhsNumber: string;
    dob: string;
    patientCode: string;
    emergencyAccessReason: string;

    parseDrugList?: boolean;

    public constructor(init?: Partial<NhsScrRequest>) {
      Object.assign(this, init);
    }
}

export class ExternalPatientProfileStatus {
    status: PatientProfileStatusEnum;
    authorisationStatus: ExternalPatientProfileAuthorisationStatus;
}

export class ExternalPatientProfileAuthorisationStatus {
    allowedScrAccessWithConsent: string;
    allowedScrAccessInEmergency: string;
}

export class ExternalPatientProfileError {
    error: string;
    errorType: ExternalPatientProfileErrorCode;
    retry: boolean;
}

export function cloneParsedScrRow(source: ParsedScrRow, destination: ParsedScrRow) {
    destination.drug = source.drug;
    destination.form = source.form;
    destination.strength = source.strength;
    destination.quantity = source.quantity;
    destination.days = source.days;
    destination.source = source.source;
    destination.maDrugId = source.maDrugId;
    destination.activeName = source.activeName;
    destination._drugtext = source._drugtext;
    destination._instructions = source._instructions;
    destination._prescribed = source._prescribed;
    destination.erd = source.erd;
    destination.repeatLeft = source.repeatLeft;
    destination.supply = source.supply;
    destination.repeatCycle = source.repeatCycle;
    destination.repeatCycleFrequency = source.repeatCycleFrequency;
    destination.systemKey = source.systemKey;
}

export class ParsedScrRow {
    drug: string;
    form: string;
    strength: string;
    quantity: number;
    days: number;
    source: SourceMedicines;
    maDrugId: number;
    activeName: string;
    _drugtext: string;
    _instructions: string;
    _prescribed: string;
    erd: boolean;
    repeatLeft: number;
    supply: number;
    repeatCycle: number;
    repeatCycleFrequency: string;
    systemKey: string;
    startDate: Date;
    selectedForOrder: boolean;

    constructor(source: ParsedScrRow = null) {
        if (source != null) {
            cloneParsedScrRow(source, this);
        }
    }

    /** Manufactures a ParsedScrRow using data from a DrugModel instance instead of an SCR row */
    public static fromDrugModel(model: DrugModel): ParsedScrRow {
        const result: ParsedScrRow = new ParsedScrRow();

        if (model != null) {
            result.maDrugId   = model.id;
            result.source     = SourceMedicines.Manual;
            result.form       = model.form;
            result.strength   = model.strength;
            result.activeName = model.activeName;
            result._drugtext  = model.labelName;
            result.systemKey  = model.systemKey;

            // drug models which are independent of a patient aren't going to contain these
            // properties, but I've included them for completeness.
            if (model.properties.hasrepeats != null) {
                result.erd = model.properties.hasrepeats === '1';
            }
            if (model.properties.numberofrefill != null) {
                result.repeatLeft = model.properties.numberofrefill;
            }
            if (model.properties.repeatcycle != null) {
                result.repeatCycle = model.properties.repeatcycle;
            }
            if (model.properties.repeatcyclefrequency != null) {
                result.repeatCycleFrequency = model.properties.repeatcyclefrequency;
            }
            if (model.properties.supply != null) {
                result.supply = model.properties.supply;
            }
            if (model.properties.instructionsread != null) {
                result._instructions = model.properties.instructionsread;
            }
        }
        return result;
    }
}

export class ParsedScrData {
    html_Summary: string;
    drugEntries: ParsedScrRow[] = [];
    retrievalTime: Date;
    properties: ParsedScrDataProperties;
}

export class ParsedScrDataProperties {
    isEmergencyAccess: string;
}
