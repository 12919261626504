import {PatientRecord} from './PatientRecord';

export class PatientGroupModel {
  id: number;
  name: string;
  gender: string;
  minAge: number;
  maxAge: number;
  medications: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  updateDate?: any;
  createDate: Date;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  deleteDate?: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  preferences?: any;
  pharmacyId: number;
  linkPatientPatientGroup: LinkPatientGroupModel[];
}

export class LinkPatientGroupModel {
  id: number;
  patientId:	number;
  patientGroupId:	number;
  updateDate:	string;
  createDate:	string;
  deleteDate:	string;
  type: number;
  patient: PatientRecord;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  patientGroup: {[key: string]: any};
}

export class PatientFromGroupRequestModel {
  includedPatientIds:	number[];
  excludedPatientIds:	number[];
  patientGroupId: number;
}

export class GroupsForPatientRequestModel {
  includeGroupIds:	number[];
  excludeGroupIds:	number[];
  patientId: number;
}

export class TemplateBroadcastModel {
  id: number;
  pharmacyId: number;
  code: string;
  name: string;
  subject: string;
  body: string;
  createDate: string;
  updateDate: string;
  deleteDate: string;
  preferences: string;
}

export class BroadcastModel {
  pharmacyId:	number;
  broadcastTemplateId: number;
  patientGroupId:	number;
}

export class IdResultModel {
  id:	string;
}
