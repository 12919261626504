<div class="patient-add-search-row">
    <div class="patient-add-search-row-content">
        <div class="nhs-toggle" *ngIf="customizedSiteConfig === 'NPA'">     
          <div class="toggle-button-cover">
              <div class="button b2" id="button-10">
                <input type="checkbox" [(ngModel)]="toggleNHSPatientName" (ngModelChange)="onTogglingNHSPatientName()" class="checkbox">
                <div class="knobs">
                  <span>NHS</span>
                </div>
                <div class="layer"></div>
              </div>
          </div>
        </div>
        <div class="patient-add-barcode" *ngIf="customizedSiteConfig === 'DayLewis'">
          <mat-form-field *ngIf="!toggleNHSPatientName" appearance="fill" class="patient-add-field-control">
            <mat-label>Scan script barcode</mat-label>
            <input
            matInput
            [(ngModel)]="prescriptionCode"
            (blur)="onBarcodeBlur()"
            #barcode="ngModel"
            />  
            <div class="message-alert-danger invite-row-space" *ngIf="!isPrescriptionCodeValid">
              Please Enter a valid Barcode
            </div>
          </mat-form-field >
          <button mat-raised-button (click)="onScriptSearch()" style="margin-right: 15px; background-color: #052F57; position: relative; bottom: 5px">
            <mat-icon [style.color]="'#fff'">search</mat-icon>
          </button>
          <b style="font-size: larger; padding-left: 35px;">or</b>
        </div>
        <div>
            <div class="controls-wrapper">
              <mat-form-field *ngIf="!toggleNHSPatientName" appearance="fill" class="patient-add-field-control">
                <mat-label>NHS number</mat-label>
                <input
                maxlength="12"
                required            
                [(ngModel)]="NHSNumber"
                size="12"
                matInput
                (blur)="onNHSBlur()"
                placeholder="XXX XXX XXXX"
                id="nhsNumberInput"
                name="NHSNumber"
                inputmode="numeric"
                (keypress)="keyPress($event)"
                #NHSNumberInput="ngModel"
                />
              <div *ngIf="!NHSNumber && !isNHSValid" class="message-alert-danger invite-row-space">
                Please enter NHS number.
              </div> 
              <div *ngIf="!(!NHSNumber && !isNHSValid) && messageErr==='Invalid NHS Number'" class="message-alert-danger invite-row-space error-width">
                Invalid NHS Number - Please try again.
              </div> 
              </mat-form-field >
              <mat-form-field *ngIf="toggleNHSPatientName" appearance="fill" class="patient-add-field-control">
                <mat-label>Patient first name</mat-label>
                <input matInput [(ngModel)]="patientFirstName" (blur)="onTextFirstNameBlur()"
                required (keypress)="onFirstNameChanged($event)" #firstname="ngModel" maxlength="255" id="firstname" name="firstname"/>
                <div [hidden]="firstname.valid || isFirstnameValid" class="message-alert-danger invite-row-space">
                  Please enter patient first name.
                </div> 
              </mat-form-field>
              <mat-form-field *ngIf="toggleNHSPatientName" appearance="fill" class="patient-add-field-control">
                <mat-label>Patient surname</mat-label>
                <input matInput [(ngModel)]="patientSurName" (blur)="onTextLastNameBlur()" required (keypress)="onSurNameChanged($event)" #surname="ngModel" maxlength="255" id="surname" name="surname"/>
                <div [hidden]="surname.valid || isSurnameValid" class="message-alert-danger invite-row-space">
                  Please enter patient surname.
                </div> 
              </mat-form-field>
              <mat-form-field *ngIf="toggleNHSPatientName" appearance="fill" class="patient-add-field-control invite-dob">
                <mat-label>Date of birth</mat-label>
                <input placeholder="DD/MM/YYYY" matInput required [matDatepicker]="myDatepicker" [(ngModel)]="DateOfBirth" [max]="maxDate" (blur)="onDateInput($event)" (dateChange)="onDateInput($event)">
                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
                <div [hidden]="isDOBValid" class="message-alert-danger invite-row-space">
                  Please enter Date Of Birth.
                </div> 
              </mat-form-field>
              <mat-form-field *ngIf="!toggleNHSPatientName" appearance="fill" class="patient-add-field-control invite-dob">
                <mat-label>Date of birth</mat-label>
                <input placeholder="DD/MM/YYYY" matInput required [matDatepicker]="myDatepicker" [(ngModel)]="DateOfBirth" [max]="maxDate" (blur)="onDateInput($event)" (dateChange)="onDateInput($event)">
                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
                <div [hidden]="isDOBValid" class="message-alert-danger invite-row-space">
                  Please enter Date Of Birth.
                </div> 
              </mat-form-field>
              <mat-form-field *ngIf="toggleNHSPatientName" appearance="fill" class="patient-add-field-control">
                <mat-label>Sex</mat-label>
                <mat-select required [(ngModel)]="gender" name="sex" (selectionChange)="onSexChanged($event)">
                  <mat-option value="M">Male</mat-option>
                  <mat-option value="F" selected>Female</mat-option>
                  <mat-option value="O">Other</mat-option>
                </mat-select>          
              </mat-form-field>
              <mat-form-field *ngIf="toggleNHSPatientName" appearance="fill" class="patient-add-field-control">
                <mat-label>Postcode</mat-label>
                <input matInput (keypress)="onPostCodeChanged($event)" (blur)="onTextPostcodeBlur()" required [(ngModel)]="postCode" #postcode="ngModel" maxlength="8" id="postcode" name="postcode"/>
                <div *ngIf="checkPostcodeRequired" class="message-alert-danger invite-row-space">
                  Please provide your postcode.
                </div>
                <div *ngIf="!checkPostcodeRequired && !isPostcodeValid" class="message-alert-danger invite-row-space">
                  Enter a valid postcode in the format XX XXX, XXX XXX, or XXXX XXX.
                </div>
              </mat-form-field>
              <div class="patient-add-search-action">
                <button type="button"
                  id="searchPatientButton"
                  (click)="onSearch()"
                  class="btn-default-blue btn-icon-text">
                  <span class="material-icons">search</span>
                  <span>Search</span>
                </button>
                <button type="button"
                  id="clearSearchPatientButton"
                  (click)="clearPatientFields()"
                  class="btn-ghost btn-text">
                  <span>Clear</span>
                </button>
              </div>
    
            </div>


        </div>
     </div>
 </div>
 <div *ngIf="displayScreen ==='errorMessage' && (messageErr$|async) === 'No results found'">
  <div class="result-empty space-bottom">
      <span class="no-result-found">No results</span> found in PDS (Personal Demographic Service) search. Check the patient's details.
  </div>
</div>
 <div *ngIf="displayScreen === 'errorMessage' && ((messageErr$|async) !== 'No results found')">
   <div class="result-empty-default space-bottom">
        {{ messageErr$|async }} - Check the details and try again
   </div>
 </div>