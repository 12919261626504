import { Injectable, Inject } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { IRegionBehaviour } from 'src/app/region/iregion-behaviour.module';
import { PublicExternalSessionDetailsWithAuth } from 'src/app/api/iapi.service';

export class PageDetails {
    pageTitle = 'Unspecified';
    pageIcon = '';
    page = 'unknown';
}

@Injectable({ providedIn: 'root' })
export class CommsModule {
    constructor(
        private regionCode: IRegionBehaviour,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      @Inject('SESSIONSTORAGE') private sessionStorage: any,
      ) {}

    private page = new Subject<PageDetails>();
    private auth = new BehaviorSubject<boolean>(false);
    private roles = new BehaviorSubject<string[]>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private config = new BehaviorSubject<any>(null); // Later replace any with a config object and null with defaults.
    private configLoaded = false;
    private cardDetails = new BehaviorSubject<PublicExternalSessionDetailsWithAuth>(null);
    private sideBarState = new BehaviorSubject<boolean>(false);


    setPage(pageDetails: PageDetails) {
      this.page.next(pageDetails);
    }

    getPageChanges(): Observable<PageDetails> {
      return this.page.asObservable();
    }

    setAuth(status: boolean) {
      this.auth.next(status);
    }
    getAuthChanges(): Observable<boolean> {
        return this.auth.asObservable();
    }

    getCardDetailsAccess(): BehaviorSubject<PublicExternalSessionDetailsWithAuth> {
        return this.cardDetails;
    }

    getRolesAccess(): BehaviorSubject<string[]> {
        return this.roles;
    }

    setRoles(roles: string[]) {
        this.roles.next(roles);
    }

    updateCardData(cardData: PublicExternalSessionDetailsWithAuth) {
        this.cardDetails.next(cardData);
    }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setConfigSettings(settings: any) {
        this.config.next(settings);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConfigSettingAccess(): BehaviorSubject<any> {
        return this.config;
    }
    /// toggle the sidebar state, or force it to a set state if force is set.
    requestSidebarStateToggle(force: boolean= null) {
        if (force == null) {
            force = !this.sideBarState.value;
        }
        this.sideBarState.next(force);
    }
    getSidebarStateAccess(): BehaviorSubject<boolean> {
        return this.sideBarState;
    }
}
