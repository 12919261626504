import {Component, Input, OnInit, Inject, ViewChild, ElementRef,
  OnDestroy, EventEmitter, Output, AfterViewChecked, ViewChildren, QueryList} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { IAPIService, MessageInbox, PagingContext } from 'src/app/api/iapi.service';
import { DataSourceController } from 'src/app/utility/filtering/datasourcecontroller';
import * as moment from 'moment';
import { DataFilterExtend } from 'src/app/utility/filtering/filtertypes';
import { NoData } from 'src/app/api/ApiRecordTypes/OperationResults';
@Component({
  selector: 'app-message-inbox',
  templateUrl: './messageinbox.component.html',
})
export class MessageInboxComponent implements OnInit, OnDestroy, AfterViewChecked {
  API: IAPIService;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() patientCode: string;
  @Input() isPatientDetailPage = false;
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() eventMessageInbox: Observable<any>;
  @Input() eventTabSwitchMessageInbox: Observable<void>;
  showErrorEmptyMessage = false;
  content: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
  totalPage: number;
  lastMessagesLength: number;
  paging: PagingContext = new PagingContext();
  private eventMessageInboxSubscription: Subscription;
  private eventTabSwitchMessageInboxSubscription: Subscription;
  appFilterItems: DataFilterExtend[] = [];
  pharmacyId: number;
  pharmacyName: string;
  messageId: number;
  initialLoad: boolean;
  viewMessages = false;
  newMessage: MessageInbox = new MessageInbox();
  @Input() isShowMessageInbox: boolean;
  numberOfmessageInboxs = 0;
  messageInboxs: MessageInbox[] = [];
  @ViewChild('bodyMessageBox') private bodyMessageBox: ElementRef;
  @ViewChild('messageContent') private messageContent: ElementRef;
  @ViewChildren('messageElement') private messageElementList: QueryList<ElementRef>;
  isMessageInboxLoading = false;
  datacontroller: DataSourceController<MessageInbox, NoData> = new DataSourceController<MessageInbox, NoData>(
    (x) => this.API.messageInboxList.createListFilter(x),
    (y) => this.API.messageInboxList.getListPage(y)
  );
  @Output() eventCloseMessageInbox = new EventEmitter();
  messages$ = new BehaviorSubject<MessageInbox[]>(this.messageInboxs);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  constructor(private api: IAPIService, @Inject('SESSIONSTORAGE') private sessionStorage: any) {
    this.API = api;
  }
  ngOnInit() {
    this.datacontroller.datasource.isPageLoading$.subscribe(value => {
      this.isMessageInboxLoading = value;
      this.numberOfmessageInboxs = this.datacontroller.datasource._cachedData.length;
      if (this.numberOfmessageInboxs > 0) {
        this.totalPage = Math.floor(this.numberOfmessageInboxs / 10);
      }
      this.datacontroller.datasource._cachedData.forEach((item) => {
        this.messageInboxs.push(item);
      });
      this.messages$.next(this.messageInboxs);
    });
    this.datacontroller.definitionId$.subscribe(value => {
      this.paging.FilterId = value;
    });
    if (this.eventTabSwitchMessageInbox) {
      this.eventTabSwitchMessageInboxSubscription = this.eventTabSwitchMessageInbox.subscribe(() => {
        this.closeMessageInbox(false);
      });
    }
    if (this.eventMessageInbox) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      this.eventMessageInboxSubscription = this.eventMessageInbox.subscribe((value: any) => {
        this.lastMessagesLength = 0;
        this.viewMessages = false;
        if (this.numberOfmessageInboxs > 0) {
          this.datacontroller.datasource.isPageLoading$.next(true);
          this.datacontroller.datasource.removeAll();
        }
        const taskData = JSON.parse(value.jSonTaskData);
        this.messageId = taskData['MessageId'];
        this.messageInboxs = [];
        this.initialLoad = false;
        this.paging.PageNumber = 0;
        this.paging.NumberPerPage = 10;
        this.viewMessages = value.viewMessage;
        const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
        if (accountSetting && accountSetting.role === 1) {
          this.pharmacyId = accountSetting.id;
          this.API.getPharmacySettings(this.pharmacyId).subscribe(result => {
            this.pharmacyName = result.name;
          });
        }
        this.showErrorEmptyMessage = false;
        this.patientCode = value.patientCode;
        this.firstName = value.firstName;
        this.lastName = value.lastName;
        this.appFilterItems.push(new DataFilterExtend('patientCode', this.patientCode));
        this.appFilterItems.push(new DataFilterExtend('Pharmacy_Id', this.pharmacyId));
        this.datacontroller.InitialiseFiltersDirectly(this.appFilterItems);
        this.datacontroller.enableDataLoading();
      });
    } else {
      // case access from patient details page
      this.initialLoad = false;
      this.paging.PageNumber = 0;
      this.paging.NumberPerPage = 10;
      this.lastMessagesLength = 0;
      const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
        if (accountSetting && accountSetting.role === 1) {
          this.pharmacyId = accountSetting.id;
          this.API.getPharmacySettings(this.pharmacyId).subscribe((result) => {
            this.pharmacyName = result.name;
          });
        }
      this.appFilterItems.push(new DataFilterExtend('patientCode', this.patientCode));
      this.appFilterItems.push(new DataFilterExtend('Pharmacy_Id', this.pharmacyId));
      this.datacontroller.InitialiseFiltersDirectly(this.appFilterItems);
      this.datacontroller.enableDataLoading();
    }

  }
  ngAfterViewChecked() {
    if (this.bodyMessageBox && !this.initialLoad) {
      this.scrollToBottom();
    }
  }
  scrollToBottom() {
    this.bodyMessageBox.nativeElement.scrollTop = this.bodyMessageBox.nativeElement.scrollHeight;
  }
  ngOnDestroy() {
    if (this.eventMessageInboxSubscription) {
      this.eventMessageInboxSubscription.unsubscribe();
    }
    if (this.eventTabSwitchMessageInboxSubscription) {
      this.eventMessageInboxSubscription.unsubscribe();
    }
  }

  onScroll(e) {
    const startScrollHeight = e.target.scrollHeight;
    this.initialLoad = true;
    if (e.target.scrollTop < 50) {
      ++this.paging.PageNumber;
      this.isMessageInboxLoading = true;

      if (this.paging.PageNumber > this.totalPage) {
        this.isMessageInboxLoading = false;
        return;
      }
      if (this.paging.PageNumber <= this.totalPage) {
        this.API.messageInboxList.getListPage(this.paging).subscribe((value) => {
          value.data.forEach((item) => {
            this.messageInboxs.push(item);
          });
          this.messages$.next(this.messageInboxs);
          this.messages$.subscribe(() => {
            setTimeout(() => {
              const newScrollHeight = this.bodyMessageBox.nativeElement.scrollHeight;
              if (newScrollHeight > startScrollHeight) {
                this.bodyMessageBox.nativeElement.scrollTo(0, newScrollHeight - startScrollHeight);
              }
              this.isMessageInboxLoading = false;
            });
          });
        });
      }
    }
  }
  displayCreateDate(createDate) {
    if (createDate === null) {
      return '';
    }
    return moment(createDate).format('LT, DD/MMM/YYYY');
  }
  closeMessageInbox(reload: boolean) {
    this.isShowMessageInbox = false;
    this.messageInboxs = [];
    this.eventCloseMessageInbox.emit(reload);
  }
  sendMessage() {
    this.content = this.messageContent.nativeElement.value;
    if (this.content === '' && this.showErrorEmptyMessage === false) {
      this.showErrorEmptyMessage = true;
    }
    if (this.content !== '' && this.showErrorEmptyMessage === true) {
      this.showErrorEmptyMessage = false;
    }
    if (this.content !== '') {
      let parentId = null;
      for (let i = 0; i < this.messageInboxs.length; i++) {
        if (this.messageInboxs[i].fromPharmacy === false) {
          parentId = this.messageInboxs[i].id;
          break;
        }
      }
      if (this.datacontroller.datasource._cachedData.length > 0) {
        this.newMessage.patientId = this.datacontroller.datasource._cachedData[0].patientId;
      }
      this.newMessage.pharmacyId = this.pharmacyId;
      this.newMessage.content = this.content;
      this.newMessage.fromPharmacy = true;
      this.newMessage.patientCode = this.patientCode;
      this.newMessage.messageId = 0;
      this.newMessage.parentId = parentId;
      this.API.messageInboxList.create(this.newMessage).subscribe(() => {
        this.scrollToBottom();
      });
      this.messageInboxs.unshift(this.newMessage);
      this.messages$.next(this.messageInboxs);
      this.numberOfmessageInboxs = this.messageInboxs.length;
      this.messageContent.nativeElement.value = '';
      this.newMessage = new MessageInbox();
      if (!this.isPatientDetailPage) {
          this.closeMessageInbox(true);
      }
    }
  }
}
