
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {environment} from '../../environments/environment';
import { IApiHelperService } from './api-helper.interface';
import { ErrorResult, OperationResultNew } from './ApiRecordTypes/OperationResults';

@Injectable({
    providedIn: 'root'
  })
export class ApiHelperService implements IApiHelperService {

    public getBaseApiPath (version: string): string {
      return `${environment.apiPath}v${version}/`;
    }

    /** This provides a direct interpreter of operationResults
        It creates the Subject for itself.
        This allows very easy OpResult conversion, but with no option to inject error results
        and refuse to make the call. */
    public CreateObservableResult<TData, TError> (
        call: Observable<OperationResultNew<TData, TError>>,
        errorType: string
    ): Subject<TData> {
        if (!errorType) {
            errorType = 'NoData';
        }
        const result = new Subject<TData>();
        this.ExtractOperationalResult(result, call, errorType);
        return result;
    }

    /** This takes a manually created Subject and an Observable<OperationResult> and responds
        with data if the Observable is a success, or with an error otherwise.
        ErrorType is horrible, but its hard to extract a type name from a template type so we pass it in.*/
    public ExtractOperationalResult<TData, TError> (result: Subject<TData>,
        call: Observable<OperationResultNew<TData, TError>>,
        errorType: string ) {
        if (!errorType) {
            errorType = 'NoData';
        }
        call.subscribe(
            (callResult) => {
                if (callResult.result > 0) {
                    result.next(callResult.data);
                    result.complete();
                } else {
                    result.error(new ErrorResult<TError>(errorType, callResult.errorData,null));
                }
            },
            (err) => {
                if ('error' in err && 'errorData' in err.error && err.error.errorData !== null) {
                    console.log('Non-data error with error data', err);
                    result.error(new ErrorResult<TError>(errorType, err.error.errorData, err.error.resultMessage));
                } else {
                    console.log('Non-data error without error data', err);
                    result.error(new ErrorResult('http error', err, err.error?.resultMessage));
                }
            }
        );
    }


}
