import {Directive, TemplateRef} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'navigation-bar ng-template[title]'
})
export class NavigationBarTitleDirective {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public constructor(public templateRef: TemplateRef<any>) {
  }
}
