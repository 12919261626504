import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { IAPIService } from 'src/app/api/api.service';
import {
  DrugModel,
  DrugSearchModel,
} from 'src/app/api/ApiRecordTypes/PendingTaskRecord';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from 'lodash';
export class DrugsDataSource extends DataSource<DrugModel | undefined> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cachedData: any[] = Array.from<DrugModel>({ length: 0 });
  private dataStream = new BehaviorSubject<(DrugModel | undefined)[]>(
    this.cachedData
  );
  private subscription = new Subscription();
  private pageSize = 20;
  private lastPage = 0;
  private drugSearchModel = null;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * @param api
   * @param searchString
   * @param searchByActiveName If you know a drug's active ingredient you can
   * set this true to search for all variants and brands of that drug.
   * If this is false then searching by active ingredient name will only return
   * generics, since [in the UK DB] those are named with the activeName.
   * Normal drug searches should set this to false, so that brands only come up
   * when they are specifically searched for.
   */
  constructor(
    private api: IAPIService,
    private searchString: string,
    searchByActiveName: boolean
  ) {
    super();
    this.drugSearchModel = new DrugSearchModel();
    if (searchByActiveName) {
      // Search for any variant or brand of drug having this active ingredient
      this.drugSearchModel.activeName = searchString;
    } else {
      // Don't return brandnames unless they were specifically searched for
      // Don't return generics if a brand name is specifically searched for
      this.drugSearchModel.labelName = searchString;
    }
    this.drugSearchModel.pageNumber = 1;
    this.drugSearchModel.numberPerPage = this.pageSize;
    this.drugSearchModel.properties = {
        isMobileSearch: 'isMobileSearch'
    };
    this._fetchFactPage(this.drugSearchModel);
  }

  private _fetchFactPage(drugModel: DrugSearchModel): void {
    this.isLoading$.next(true);
    this.api.searchDrug(drugModel).subscribe((res) => {
      this.cachedData = res.data && this.cachedData.concat(res.data);
      this.dataStream.next(this.cachedData);
      this.isLoading$.next(false);
    },
    err => console.log(err),
    () => this.isLoading$.next(false));
  }

  private _getPageForIndex(i: number): number {
    return Math.floor(i / this.pageSize);
  }

  public isEmpty() {
    return !this.cachedData || (this.cachedData && this.cachedData.length <= 0);
  }

  connect(
    collectionViewer: CollectionViewer
  ): Observable<(DrugModel | undefined)[]> {
    this.subscription.add(
      collectionViewer.viewChange.subscribe((range) => {
        const currentPage = this._getPageForIndex(range.end);
        if (currentPage > this.lastPage) {
          this.lastPage = currentPage;
          setTimeout(() => {
            this.drugSearchModel.pageNumber++;
            this._fetchFactPage(this.drugSearchModel);
          }, 300);
        }
      })
    );
    return this.dataStream;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disconnect(collectionViewer: CollectionViewer): void {
    this.subscription.unsubscribe();
  }
}
