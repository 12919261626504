<div class="surgery-summary-form">
    <div class="surgery-summary-header">
        <strong>Do you want to delete this surgery?</strong>
        <div (click)="cancelDeleteSurgery()" class="close-button">
            <i class="material-icons">
                clear
            </i>
        </div>
    </div>
    <div class="surgery-summary">
        <div class="contents">
            <div class="surgery-name">
                <strong>{{data.practice.name}}</strong>
            </div>
            <div class="surgery-name">
                {{data.practice.phone}}
            </div>
            <div class="surgery-name">
                {{data.practice.address.state}}
            </div>
        </div>
    </div>
    <div class="form-action">
        <button (click)="deleteSurgery()" class="btn-default-blue btn-text button-delete">Delete Surgery</button>
    </div>
</div>
