<app-basic-dialog
  [dialogTitle]="headerTitle"
  [buttonAcceptTitle]="acceptButtonTitle"
  [buttonCancel]="cancelButtonTitle"
  [iconClose]="false"
  (OnComplete)="listenAction($event)">
  <ng-container [ngSwitch]="confirmDialogContentType()">
    <ng-container *ngSwitchCase="'template'">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </ng-container>
    <div class="body-content" *ngSwitchDefault>
      {{content}}
    </div>
  </ng-container>
</app-basic-dialog>
