<div class="login-page">

    <div class="layout-left  hide-on-small-screen">
        <div class="header-login">
            <img class="img-logo" src="../../../assets/images/MedAdvisor_Logo_Horizontal_on_dark.png">
        </div>
        <H2>Pharmacy login</H2>
    </div>
    <div class="layout-center">
        <div *ngIf="!ForgotPassword">
            <H3 class="tittle-message">{{TitleMessage}}</H3>
            <p *ngIf="Notice!==''">{{Notice}}</p>
        </div>
        <div *ngIf="ForgotPassword">
            <H3 class="tittle-message">Forgot password?</H3>
            <p class="sub-tittle-message">Send a new-password request to our admins</p>
        </div>

        <div class="flow-h">
            <form method="post">
                <div class="loginpanel">
                    <div class="inputline">
                        <label>Pharmacy login name</label>
                    </div>
                    <div class="inputline">
                        <input [class]="!isValid ? 'error-show': ''" type="text" [(ngModel)]="LoginName" size="25" id="LoginName" [ngModelOptions]="{standalone: true}" />
                    </div>
                    <div *ngIf="!ForgotPassword" class="forgot-password">
                        <div *ngIf="!ForgotPassword" class="inputline">
                            <label>Password</label>
                        </div>
                        <div *ngIf="!ForgotPassword" class="inputline">
                            <input
                              #passwordInput
                              [class]="!isValid ? 'error-show' : ''"
                              [type]="shouldPasswordRevealed ? 'text' : 'password'"
                              placeholder="********"
                              [(ngModel)]="Password"
                              size="25"
                              id="password"
                              [ngModelOptions]="{ standalone: true }"
                            />
              
                            <span
                              role="button" style="cursor: pointer; padding-left: 2px;"
                              (click)="shouldPasswordRevealed = !shouldPasswordRevealed; $event.stopPropagation(); passwordInput.blur()"
                              class="material-icons text-muted cursor-pointer"
                            >
                              {{ shouldPasswordRevealed ? "visibility" : "visibility_off" }}
                            </span>
                        </div>
                    </div>
                    <div class="errorMessage">
                        {{ErrorMessage}}
                    </div>
                    <div *ngIf="!ForgotPassword" class="buttonbar">
                        <button type="submit" class="btn primary btn-login btn-primary" (click)="login()"
                            id="loginButton"><i *ngIf='inProgress' class="fa fa-spinner fa-spin login-spinner"></i>Login</button>
                        <!-- <div class='link-reset-pwd' (click)="forgotPassword()">Forgot Password?</div> -->
                    </div>
                    <div *ngIf="ForgotPassword" class="buttonbar">
                        <button class="btn primary btn-login btn-primary" (click)="forgot()" id="forgotButton">Remind
                            me</button>
                        <div class='link-reset-pwd' (click)="cancel()">Cancel</div>
                    </div>

                    <div *ngIf="ShowPrivacyLinks && !ForgotPassword" class="disclaimer">
                        By signing in, you agree to <a href="https://support.medadvisor.co.uk/hc/en-us/articles/360049306114-MedAdvisor-Welam-UK-Limited-UK-End-User-Licence-Agreement" target="_blank" class="a-link">PlusOne's Terms and Conditions</a> and <a
                        href="https://support.medadvisor.co.uk/hc/en-us/articles/360049357834-MedAdvisor-UK-Privacy-Policy" target="_blank"
                        class="a-link">Privacy Policy</a>
                    </div>

                </div>
            </form>
        </div>
    </div>
    <div class="layout-right  hide-on-small-screen">
        <!-- <div class="login-help">
            <strong>Don't have an <br />account?</strong>
            <div>Help your patients make medication manageable with <strong>PlusOne</strong></div>
        </div> -->

    </div>
</div>
