<div class="heading-invite">
  <div class="invite-header">
    <div class="dialog-patient-name">
      <span class="first-name">{{ firstName }}</span
      ><span>&nbsp;</span>
      <span class="last-name">{{ lastName }}</span>
    </div>
    <div class="right-header"></div>
    <div id="closeButton" (click)="handleCancel()" class="close-button">
      <i class="material-icons"> clear </i>
    </div>
  </div>
</div>
<ng-container [ngSwitch]="display">
  <ng-container *ngSwitchCase="'orderPage'">
    <div class="dialog-main-content">
      <span class="order-header">
        The following medication request will be sent:
      </span>
      <h4>
        <div id="order-details">
          <div>Patient:</div>
          <div>
            <span class="first-name">{{ firstName }}</span
            ><span>&nbsp;</span> <span class="last-name">{{ lastName }}</span>
          </div>
          <div>GP Surgery:</div>
          <div>{{ practiceName }}</div>
          <div>Method:</div>
          <div>{{ transmissionMethod }}</div>
        </div>
      </h4>
      <div class="medication-list">
        <h3>Medication list</h3>
        <ul *ngFor="let drug of drugs">
          <li>{{ drug._drugtext }}</li>
        </ul>
      </div>
      <div class="gpMessage-header">
        <h3>Comment for GP</h3>
        <button mat-icon-button tabindex="-1" (click)="gpMessage = ''">
          <mat-icon>clear</mat-icon>
        </button>
      </div>

      <div>
        <textarea [(ngModel)]="gpMessage" rows="2" cols="54" Maxlength="150" [disabled]="!dataLoaded">
        </textarea>
      </div>
      <div>
        <div class="info-block">
          <mat-icon class="order-icon__info">info</mat-icon>
          <div class="acknowledge-text">
            Ensure you follow local NHS guidance and only order medication where
            you have consent. <br />
            <a
              target="_blank"
              href="{{ env.carerModeConsentFormLink }}"
              class="consent-link"
              >Consent form (PDF)</a
            >
          </div>
        </div>
      </div>
      <div class="order-buttons">
        <button
          id="order-confirm-button"
          class="btn-default-blue btn-icon-text"
          (click)="placeOrder()"
          [disabled]="inProgress"
        >
          <ng-container *ngIf="inProgress">
            <app-load-spinner [diameter]="32"></app-load-spinner>
          </ng-container>
          <ng-container *ngIf="!inProgress">
            <mat-icon class="prefix">fact_check</mat-icon>
          </ng-container>
          Confirm
        </button>
        <button (click)="handleCancel()" class="button-text btn-cancel-dialog">
          Cancel
        </button>
      </div>
    </div>
  </ng-container>

  <!-- Success and error screens-->

  <ng-container *ngSwitchCase="'error'">
    <div class="dialog-main-content">
      <div>
        <mat-icon
          class="order-icon order-icon__error"
          role="img"
          aria-hidden="true"
        >
          report_problem</mat-icon
        >
        <br />
        <span class="order-header-result">This order could not be placed</span>
        <p class="confirm-text">
          Check the GP Surgery transmission method and try again
        </p>
        <div class="order-error-message">
          <p *ngIf="errorMessage !== null">
            {{ errorMessage }}
          </p>
          <button
            mat-button
            matTooltip="Copy to clipboard"
            matTooltipPosition="right"
            cdkCopyToClipboard="{{ errorMessage }}"
          >
            <mat-icon>content_copy</mat-icon>
            Copy
          </button>
        </div>
      </div>
      <div class="order-buttons">
        <button class="btn-default-blue btn-text" (click)="goToOrderScreen()">
          Retry
        </button>

        <button (click)="handleCancel()" class="button-text btn-cancel-dialog">
          Cancel
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'success'">
    <div class="dialog-main-content">
      <div>
        <mat-icon
          class="order-icon order-icon__success"
          role="img"
          aria-hidden="true"
        >
          check_circle</mat-icon
        >
        <span class="order-header-result"
          >Script request sent to {{ practiceName }}
        </span>
        <p class="confirm-text">
          You can view the inbox to see the status of this order
        </p>
      </div>
      <div class="order-buttons">
        <button (click)="handleAccept()" class="btn-default-blue btn-text">
          Done
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
