<div class="pageContent-patient" *ngIf="patient$ | async as patient">
  <div class="patient-content">
    <div class="patient-details-section border-bottom">
      <div class="row-header__left">Patient information</div>
      <div class="patient-panel">
        <div class="patient-information-panel">
          <div class="patient-item">
            <div class="patient-label">
              <div class="patient-name-title">
                <span class="first-name">{{firstName$ | async}}</span><span>&nbsp;</span>
                <span class="last-name">{{lastName$ | async}}</span>
              </div>
            </div>
            <div *ngIf="patientPageState.isCarerMode" class="patient-info">
              <div *ngIf="patient?.carerStatus === caregiverStatus.IsCaringFor">
                <div *ngIf="patient?.caringFor === 1"><span class="material-icons pullleft">people</span><div class="edit-role-link" (click)="openPatientDetails(listCaree[0]?.identity?.patientCode_MedAdvisorGuid)">Caring for {{ patient?.careeName }}</div></div>
                <div *ngIf="patient?.caringFor !== 1"><span class="material-icons pullleft">people</span><div class="edit-role-link" (click)="scrollToCarerRoles()">Caring for {{ patient?.caringFor + ' people'}}</div></div>
              </div>
              <div *ngIf="patient?.carerStatus === caregiverStatus.IsCaredFor"><span class="material-icons pullleft">people_outline</span><div class="edit-role-link" (click)="openPatientDetails(patient?.carerCode_MedAdvisorGUID)">Cared for by {{patient?.carerName}}</div></div>
            </div>
          </div>
          <div class="patient-item">
            <div class="patient-label">
              Date of birth
            </div>
            <div class="patient-info">
              {{patient?.dateOfBirth | date: 'dd-MM-yyyy'}}
            </div>
          </div>
          <div class="patient-item">
            <div class="patient-label">
              Sex assigned at birth
            </div>
            <div class="patient-info">
              {{displayGender(patient?.gender)}}
            </div>
          </div>
          <div class="patient-item">
            <div class="patient-label">
              Address
            </div>
            <div class="patient-info">
              {{ address(patient) }}
            </div>
          </div>
          <div class="patient-item">
            <div class="patient-label">
              NHS number
            </div>
            <div class="patient-info">
              {{NHSnumber(patient)}}
            </div>
          </div>
          <div class="patient-item">
            <div class="patient-label">
              Mobile number
            </div>
            <div class="patient-info">
              <!-- <mat-icon class="edit-icon">create</mat-icon><a class="edit-role-link">{{ formatPhone(patient?.phone) }}</a> -->
             {{ formatPhone(patient?.phone) }}
            </div>
          </div>
          <div *ngIf="patientPageState.isCarerMode && patient?.carerStatus === caregiverStatus.IsCaredFor" class="patient-item">
            <div class="patient-label">
              Carer mobile number
            </div>
            <div class="patient-info">
              <!-- <mat-icon class="edit-icon">create</mat-icon><a class="edit-role-link">{{ formatPhone(patient?.carerPhone) }}</a> -->
              {{ formatPhone(patient?.carerPhone) }}
            </div>
          </div>
          <div class="patient-item">
            <div class="patient-label">
              Email address
            </div>
            <div class="patient-info">
              {{ patient?.email }}
            </div>
          </div>
          <div class="patient-item">
            <div class="patient-label">
              GP Surgery
            </div>
            <div class="patient-info">
              {{ GPName(patient)}}
            </div>
          </div>
          <div *ngIf="patientPageState.isCarerMode" class="patient-item">
            <div class="patient-label">
              Transmission method
            </div>
            <div class="patient-info">
              {{ patient?.practice?.channel }}
            </div>
          </div>
          <div class="patient-item">
            <div class="patient-label">
              Status
            </div>
            <div class="patient-info">
              Activated
            </div>
          </div>
          <div class="patient-item">
            <div class="patient-label">
              Date joined
            </div>
            <div class="patient-info">
              {{patient?.createDate | date: 'dd-MM-yyyy'}}
            </div>
          </div>
          <div class="patient-item" *ngIf="config?.features?.EditPatientPage?.ShowPatientGroup">
            <div class="patient-label">Groups</div>
            <div class="patient-group-list">
              <div class="patient-group-badge" *ngIf="linkPatientGroups && linkPatientGroups.length">
                <div class="patient-group-badge__item" *ngFor="let group of linkPatientGroups">
                  <span class="material-icons">done</span>
                  <span>{{group.name}}</span>
                </div>
              </div>
              <div class="patient-group-btn-edit">
                <a class="clear-button" (click)="changePatientGroup()">Edit groups</a>
              </div>
            </div>
          </div>

          <div class="button-panel">
            <button [disabled]="drugReloadDisabled" *ngIf = "config?.features?.ShowReloadDrugButton" (click)="reloadDrugProfile(patient?.patientCode_MedAdvisorGUID)" class="btn-default-blue btn-text reload-drug-profile">
            {{ drugReloadText }}
            </button>
            <ng-container *ngIf='config.features.EditPatientPage.ShowDeleteButton'>
              <button [disabled]="patient?.carerStatus === caregiverStatus.IsCaringFor" (click)="deletePatient(patient?.patientCode_MedAdvisorGUID)" [class]="patient?.carerStatus === caregiverStatus.IsCaringFor ? 'btn-default-blue btn-text remove-patient remove-disabled' : 'btn-default-blue btn-text remove-patient'">
                <span class="material-icons">delete</span>{{customizedSiteConfig === 'NPA' ? 'DELETE THIS PATIENT' : 'Remove ' + CustomerText }}
              </button>
              <div style="margin-left: 15px;" *ngIf="patient?.carerStatus === caregiverStatus.IsCaringFor"><span class="material-icons pullleft">error_outline</span><span class="warning-delete">Patient has carer role and cannot be deleted.</span></div>
            </ng-container>
          </div>
        </div>
         <div [hidden]=true>{{ (pcomms$ | async) }}</div>
         <div *ngIf="patient?.carerStatus === caregiverStatus.IsCaredFor else elseBlock">
           <div class="chat-box-area">
             <p>Cared for patients cannot send or receive messages</p>
             <div class="image-warning">
               <img class="centered" src="../../../assets/images/message-warning.PNG" alt="">
             </div>
           </div>
         </div>
         <ng-template #elseBlock>
          <div class="chat-box"  *ngIf="pcomms">
            <app-message-inbox
            [isShowMessageInbox]="true"
            [patientCode]="patientPageState.patientCode_MedAdvisorGuid"
            [firstName]="patient?.firstName"
            [lastName]="patient?.lastName"
            [isPatientDetailPage]="true"
            ></app-message-inbox>
          </div>
         </ng-template>
      </div>
    </div>
    <div [class]="patientPageState.isCarerMode? 'patient-details-section border-bottom' : 'patient-details-section'">
      <div id="uk-medication-list" *ngIf="customizedSiteConfig === 'DayLewis'">
        <div class="heading-table">Medications</div>
        <div [hidden]=true>{{ (medications$ | async) }}</div>
        <div class="history-table">
          <table class="patient-details-table">
            <tr>
              <th>Medication</th>
              <th>Directions</th>
              <th>Last dispense date</th>
            </tr>
            <tr *ngIf="(isMedicationLoading$ | async)">
              <th>
                <div class="loading-spiner">
                  <strong>
                    Loading
                  </strong>
                  <div class="fa-1x">
        
                    <mat-spinner diameter="50"></mat-spinner>
                  </div>
                </div>
              </th>
            </tr>
            <tr *ngFor="let medication of medications">
              <td>{{medication?.labelName}}</td>
              <td>{{medication?.relationshipProperties?.instructionsread}}</td>
              <td>{{medication?.lastDispenseDate | date: 'dd-MM-yyyy'}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div id="npa-medication-list" *ngIf="customizedSiteConfig === 'NPA'">
        <app-patientdrugupdate
          (patientDrugRecordRetreivalRequested) = "onPatientDrugRecordRetreivalRequested($event)"
          (saveMedication) = "onSaveMedicationChangesRequested(patient?.patientCode_MedAdvisorGUID,$event)"
          [duplicatedrug] = 'duplicatedrug'
          [MedicationList$] = "MedicationList$"
          [DateOfBirth] = "patient?.dateOfBirth"
          [NhsNumber] = "patient?.externalSystemCode"
          [errorMessageNoDrug] = "scrPageState.errorMessageNoDrug"
          [isScrAvailable$] = "isScrAvailable$"
          [isIM1Patient] = "isIM1Patient"
          [patientInformation$] = "patientInformation$"
          [NewPatient] = "(false)"
          [ScrErrorState$] = "ScrErrorState$"
          [editDaysSupply] = "config.features.EditDaysSupplyInEditPatient"
          [patientCode_MedAdvisorGUID] = "patient?.patientCode_MedAdvisorGUID"
          [practice] = "patient?.practice"
          [isMedicationLoading$] = "isMedicationLoading$"

        >
        </app-patientdrugupdate>
      </div>
    </div>
    <div *ngIf="patientPageState.isCarerMode" class="edit-role">
      <div [hidden]=true>{{ (listCaree$ | async) }}</div>
      <div #navigationEditCarerRoles class="row-header__left">{{patient?.carerStatus === caregiverStatus.IsCaringFor ? 'Manage carer role' : 'Carer role'}}</div>
      <div *ngIf="patient?.carerStatus === caregiverStatus.None" style="padding-bottom:10px; display:flex;align-items: center;"><span class="material-icons link-icon">add_link</span><a [routerLink]="['/patients/invite', patientPageState.patientCode_MedAdvisorGuid]" class="edit-role-link">Link new patient</a></div>
      <ul *ngIf="patient?.carerStatus === caregiverStatus.None">
        <li class="first-row standard-patient">
          <div class="main-tree">
            <div class="pullleft">
              <span class="material-icons people-icon">people</span>
              <div class="title-role">Carer role:</div>
            </div>
            <div class="root-panel">
              <div class="grid-layout-invite-caree">
                <div>Linking a patient will give this person a carer role</div>
                <div>
                  <p *ngIf="!config.features.InvitePatient.ShowConsentForm">&nbsp;</p>
                  <a  *ngIf="config.features.InvitePatient.ShowConsentForm" target="_blank" href="{{ env.carerModeConsentFormLink }}" class="edit-role-link">Consent form (PDF)</a>
                </div>
                <div style="display: flex;align-items: center"><b>Link patients to carer:</b>&nbsp;
                <!-- <div style="margin-left: 30px;"><span class="material-icons link-icon pullleft">link</span><a class="edit-role-link">Existing PlusOne patient</a></div> -->
                <span class="material-icons link-icon">add_link</span><a [routerLink]="['/patients/invite', patientPageState.patientCode_MedAdvisorGuid]" class="edit-role-link">Link new patient</a>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- <li>
          <div class="root">
            <div class="title-tree">
              <div class="pullleft">
                <span class="material-icons pullleft">people_outline</span>
                <div class="title-leaf tree-leaf-title">Cared for role:</div>
              </div>
              <div class="leaf-panel" style="margin-top: -11px;">
                <div>Give this patient a carer will let the carer order their medication</div>
                <div style="margin-left: 40px;"><span class="material-icons link-icon pullleft">link</span><a class="edit-role-link">Link to a carer</a></div>
              </div>
            </div>
          </div>
        </li> -->
      </ul>
      <ul id="carer" *ngIf="patient?.carerStatus === caregiverStatus.IsCaringFor">
        <li class="first-row">
          <div class="main-tree">
            <div class="pullleft">
              <div class="title-role">Carer:</div>
            </div>
            <div class="root-panel">
              <div class="grid-layout-invite-caree">
                <div><span class="material-icons pullleft">people</span><div class="title-leaf">
                  <span class="first-name">{{firstName$ | async}}</span><span>&nbsp;</span>
                  <span class="last-name">{{lastName$ | async}}</span>
                </div></div>
                <div>
                  <p *ngIf="!config.features.InvitePatient.ShowConsentForm">&nbsp;</p>
                  <a  *ngIf="config.features.InvitePatient.ShowConsentForm" target="_blank" href="{{ env.carerModeConsentFormLink }}" class="edit-role-link">Consent form (PDF)</a>
                </div>
                <div style="display: flex;align-items: center"><b>Linking more patients to carer:</b>&nbsp;
                  <!-- <div style="margin-left: 30px;"><span class="material-icons link-icon pullleft">link</span><a class="edit-role-link">Existing PlusOne patient</a></div> -->
                  <span class="material-icons link-icon">add_link</span><a [routerLink]="['/patients/invite', patientPageState.patientCode_MedAdvisorGuid]" class="edit-role-link">Link new patient</a>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li *ngFor="let caree of listCaree">
          <div class="root">
            <div class="title-tree">
              <div class="pullleft">
                <div class="tree-leaf-title">Caring for:</div>
              </div>
              <div class="leaf-panel">
                <div class="col-1">
                  <span class="material-icons patient-caree pullleft">people_outline</span>
                  <a (click) = "openPatientDetails(caree?.identity?.patientCode_MedAdvisorGuid)" class="title-leaf a-link link-cursor">
                    <span class="first-name">{{caree?.identity?.firstName}}</span><span>&nbsp;</span><span class="last-name">{{caree?.identity?.lastName}}</span>
                  </a></div>
                <!-- <div class="col-2"><span class="material-icons link-icon pullleft">call_split</span><a class="edit-role-link">UNLINK from carer</a></div> -->
                <!-- <div class="col-3"><span class="material-icons link-icon pullleft">link</span><a class="edit-role-link">Link to different carer</a></div> -->
                <div class="col-4"><span class="material-icons link-icon pullleft">delete</span><a (click)="deletePatient(caree?.identity?.patientCode_MedAdvisorGuid, true)" class="edit-role-link">Delete</a></div>
              </div>
            </div>
            <div class="drug-list-panel">
              <div  *ngFor="let medication of caree?.listDrug" class="drug-list-item">
                <b>{{medication?.labelName}} </b> {{medication?.form}} {{medication?.strength}}
              </div>
            </div>
          </div>
      </li>
      </ul>
      <ul *ngIf="patient?.carerStatus === caregiverStatus.IsCaredFor">
        <li class="first-row">
          <div class="main-tree">
            <div class="pullleft">
              <div class="title-role">Carer:</div>
            </div>
            <div class="root-panel">
              <div><span class="material-icons patient-caree pullleft">people</span><div class="title-leaf"><a (click)="openPatientDetails(patient?.carerCode_MedAdvisorGUID)" class="a-link link-cursor">{{ patient?.carerName }}</a></div></div>
            </div>
          </div>
        </li>
        <li>
          <div class="root">
            <div class="title-tree">
              <div class="pullleft">
                <div class="tree-leaf-title">Caring for:</div>
              </div>
              <div class="leaf-panel">
                <div class="col-1"><span class="material-icons people-icon-outline pullleft">people_outline</span>
                  <div class="title-leaf">
                    <span class="first-name">{{patient.firstName}}</span><span>&nbsp;</span>
                    <span class="last-name">{{patient.lastName}}</span>
                  </div>
                </div>
                <!-- <div class="col-2"><span class="material-icons link-icon pullleft">call_split</span><a class="edit-role-link">UNLINK from carer</a></div>
                <div class="col-3"><span class="material-icons link-icon pullleft">link</span><a class="edit-role-link">Link to different carer</a></div> -->
              </div>
            </div>
            <div class="drug-list-panel">
              <div *ngFor="let medication of medications" class="drug-list-item">
                <b>{{medication?.labelName}} </b> {{medication?.form}} {{medication?.strength}}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div #navigationEditCarerRolesEnd>{{''// this empty div is for scrolling to the base of #navigationEditCarerRoles because using scrollIntoView() with block:center has issues - was somehow scrolling the screen (body element?) such that you can't scroll back (noticed in Mozilla, but might be https://stackoverflow.com/a/69800779/3343347) }}</div>
  </div>
</div>

<ng-template #navigationTitle>
  <div class="left">
    <span class="material-icons left-sidebar" [routerLink]="['/patients']"
      >keyboard_backspace</span
    >
  </div>
  <div class="title">
    <div class="name">{{ patientFullName$ | async }}</div>
  </div>
</ng-template>
