<div class="pageContent">
    <div class="qxlva-card-check"><p>Access to this computer/system and any information
        it contains is limited to authorised users only. Legal action may be taken against unauthorised use of, or
        unauthorised access to, this computer/system and/or any information it contains, including pursuant to the
        Computer Misuse Act 1990. If you are an authorised user, by proceeding to access and use this computer/system
        and/or the information it contains, you are accepting any terms of use, notices and policies which are contained
        or referenced within it or which have otherwise been drawn to your attention as an authorised user.</p>
        <button *ngIf="(authenticating$ | async)===AuthenticationStatus.Unauthenticated" (click)="OnLegalAgreementAcceptance()" class="btn primary btn-login btn-primary">Accept</button>
    </div>
    <div class="qxlva-card-check" *ngIf="(authenticating$ | async)===AuthenticationStatus.Authenticating"><p>Authenticating card</p></div>
    <div class="qxlva-card-check" *ngIf="(authenticating$ | async)===AuthenticationStatus.Authenticated">Card is authenticated</div>
    <div class="qxlva-card-check" *ngIf="(authenticating$ | async)===AuthenticationStatus.AuthenticationFailed">Authentication failed</div>
</div>
