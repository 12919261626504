<app-basic-dialog
  [dialogTitle]="headerTitle"
  [buttonAcceptTitle]="acceptButtonTitle"
  (OnComplete)="listenAction($event)"
  [buttonAcceptState] ="!this.selectedRowId"
>
  <div class="patient-search">
    <!--    Table title-->
    <div class="patient-search__header">
      <!--      Filter-->
      <div class="patient-search__header-search-text">
        <div class="input-dialog-label">Search:</div>
        <mat-form-field appearance="outline" class="patient-first-name">
          <input  matInput
                  placeholder="Name, email or mobile"
                  (keyup)="handleFilter($event)"
                  #input>
        </mat-form-field>
      </div>
    </div>

    <div class="patient-search__table">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name</th>
          <td mat-cell *matCellDef="let row"> <span class="first-name">{{row?.firstName}}</span> </td>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name</th>
          <td mat-cell *matCellDef="let row"> <span class="last-name">{{row?.lastName}}</span> </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
          <td mat-cell *matCellDef="let row"> {{row?.email}} </td>
        </ng-container>

        <!-- Mobile Column -->
        <ng-container matColumnDef="mobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile</th>
          <td mat-cell *matCellDef="let row"> {{row?.phone}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row
              *matRowDef="let row;
              columns: displayedColumns;"
              [class.selected]="selectedRowId === row?.id"
              (click)="handleChoosePatient(row)"></tr>

        <!-- Row shown when there is no matching data. -->
      </table>
      <div class="not-data-found" *ngIf="dataSource.filteredData.length === 0">
        No matching records found!
      </div>
    </div>

    <!--  showFirstLastButtons pageIndex Option-->
    <div class="patient-search__footer">
      <div class="input-dialog-label item-label">Item per page</div>
      <ng-select
        appendTo="body"
        class="select-page-size"
        [searchable]="false"
        [formControl]="pageSizeOptionControl"
        (change)="handlePageSizeOptionsChange($event)"
        [clearable]="false">
        <ng-option  *ngFor="let pageSizeOption of pageSizeOptions" [value]="pageSizeOption">{{pageSizeOption}}
        </ng-option>
      </ng-select>
        <mat-paginator
          #paginator
          class="patient-search__footer"
          (page)="handlePageChangeEvent($event)"
          [length]="pageLength"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    </div>


  </div>
</app-basic-dialog>
