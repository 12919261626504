import { Directive, ElementRef, Input, HostListener , Output, EventEmitter} from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { DataFilter, FilterOptions } from './filtertypes';
import { AppConfigService } from 'src/app/app-config.service';
import { DataService } from '../dataservice/data.service';

@Directive({selector: '[appFilter]'})
export class FilterControlDirective implements DataFilter {
  private filterType: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appFilter') private filterName: string;
  @Input() private controlSet = 'default';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value') private filterValue: string;
  @Input() private validator: (item: FilterControlDirective) => string = null;
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() updateFilterCallback: EventEmitter<any> = new EventEmitter();

  private changesubscribers: Subject<DataFilter> = new Subject<DataFilter>();
  private validationError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  sanitizeValueCallback: (value: string | null) => string | null = (value) => value;
  optionsPreprocessor: (options: FilterOptions) => FilterOptions = (options) => options;

  constructor(
    private el: ElementRef,
    private config: AppConfigService,
    private dataService: DataService,
//    private viewContainer: ViewContainerRef
  ) {
    this.filterType = this.el.nativeElement.tagName.toUpperCase();
  }

  IsList(): boolean {
    return this.filterType === 'SELECT';
  }

  GetValue(): string | null | undefined {
    return this.sanitizeValueCallback(this.filterValue);
  }
  GetName() {
    return this.filterName;
  }
  GetSet() {
    return this.controlSet;
  }
  OnChanged(): Observable<DataFilter> {
    return this.changesubscribers;
  }
  OnValidationError(): BehaviorSubject<string> {
    return this.validationError$;
  }
  UpdateFilter(options: FilterOptions) {
    options = this.optionsPreprocessor(options);
    if (this.filterType === 'SELECT') {
      // Use options to rebuild select list
      let optionlist = '';
      options.fieldOptions.forEach(option => {
        let optionItem = '<OPTION value=\'' + option.key + '\'';
        if (option.disabled === true) { optionItem += ' disabled'; }
        if (option.selected === true) { optionItem += ' selected'; }
        optionItem += '>' + option.label + '</OPTION>';
        optionlist = optionlist + optionItem;
      } );
      this.el.nativeElement.innerHTML = optionlist;

      this.updateFilterCallback.emit(optionlist);
    }
  }
  resetValue() {
    this.filterValue = null;
    this.el.nativeElement.value = null;

  }
  @HostListener('change', ['$event.target.value']) onChanged(value) {
    this.applyFilter(value);
  }
  @HostListener('dateChange', ['$event.target.value']) onDateChange(value) {
    if (value) {
      this.applyFilter(value.format('YYYY-MM-DD'));
    } else {
      this.applyFilter(null);
    }
  }

  applyFilter(value) {
    if (value !== this.filterValue) {
      this.filterValue = value;

      setTimeout(() => {
        /** changesubscribers will call after 1 second */
        if (this.validator != null) {
          const error = this.validator(this);
          this.validationError$.next(error);
        }
        if (this.validationError$.value === null) {
          this.changesubscribers.next(this);
        }
      }, 1000);
    }
  }

}
