import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, SecurityContext, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NhsScrRequest, ParsedScrData, ParsedScrRow, ExternalPatientProfileError,
  PatientProfileStatusEnum, ExternalPatientProfileStatus} from 'src/app/api/iapi.service';
import { AppConfigService } from 'src/app/app-config.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { PatientPharmacyInformation } from 'src/app/api/ApiRecordTypes/PatientRecord';
import { MatDialog } from '@angular/material/dialog';
import { DrugUpdateDialogComponent } from '../drugupdatedialog/drugupdatedialog.component';
import { DialogConfig, DialogResponse, DialogService } from 'src/app/utility/base-dialog/dialog.service';
import { CommsModule } from 'src/app/utility/comms/comms.module';
import { DomSanitizer } from '@angular/platform-browser';
import { EmergencyAccessComponent } from '../emergency-access/emergency-access.component';
import { ExternalPatientProfileAuthorisationStatus } from 'src/app/api/ApiRecordTypes/NHSRecords';
import { PatientDrugRecordComponent } from '../patientdrugrecord_editable/patientdrugrecord_editable.component';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';
import { Im1ConnectComponent } from '../npa-provider-im1-connect/im1-connect.component';
import { PharmacyOrderComponent } from '../pharmacy-order-drugs/pharmacy-order.component';
import { PharmacyPracticeModel } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-patientdrugupdate',
  templateUrl: './patientdrugupdate.component.html',
  styleUrls: ['./patientdrugupdate.component.scss']
})

export class PatientDrugUpdateComponent implements OnInit {
  @Input() MedicationList$: BehaviorSubject<ParsedScrData>;
  @Input() patientInformation$: BehaviorSubject<PatientPharmacyInformation>;
  @Input() DateOfBirth: Date;
  @Input() NhsNumber: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('NewPatient') isNewPatient = false;
  @Input() isScrAvailable$: BehaviorSubject<ExternalPatientProfileStatus>;
  @Input() isIM1Patient = false;
  @Input() editDaysSupply = false;
  @Input() duplicatedrug: string;
  @Input() errorMessageNoDrug: string;
  @Input() ScrErrorState$: BehaviorSubject<ExternalPatientProfileError>;
  @Input() DisplayMedicationList$: BehaviorSubject<ParsedScrData>
              = new BehaviorSubject<ParsedScrData>(new ParsedScrData());
  @Input() patientCode_MedAdvisorGUID: string;
  @Input() practice: PharmacyPracticeModel;
  @Input() isMedicationLoading$: BehaviorSubject<boolean>;

  @ViewChild('addDrug') addDrugElement: PatientDrugRecordComponent = null;
  @ViewChild('drugList') drugListElement: ElementRef<HTMLElement> = null;
  // local variables for communicating to the screeen
  scrDisplayLevel$: BehaviorSubject<string> = new BehaviorSubject<string>('Loading');  // Consider an enum later
  disableScrFetchButton = true;
  allowedScrAccessInEmergency = false;
  allowedScrAccessWithConsent = false;
  disableAddButton = false;
  addButtonToolTip = '';
  hasDrugsSelected = false;
  showDrugs = 'drugs-list';
  expandIcon = false;

//  DisplayMedicationList$: BehaviorSubject<ParsedScrData> = new BehaviorSubject<ParsedScrData>(new ParsedScrData());
  CurrentMedicationList: ParsedScrData;
  initialMedicationList: ParsedScrRow[];
  cardHolderName = '';
  forceStopEdit$ = new Subject<boolean>();

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() patientDrugRecordRetreivalRequested = new EventEmitter<any>();
  @Output() saveMedication = new EventEmitter<ParsedScrData>();

  customizedSiteConfig: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  verifyPatientConsent = false;
  isChanged = false;
  isConfirmPermission = false;
  cardInserted = false;

  constructor(
    private configService: AppConfigService,
    public dialog: MatDialog,
    public dialogService: DialogService,
    private comms: CommsModule,
    private sanitizer: DomSanitizer,
    
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private renderer: Renderer2
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.forceStopEdit$.subscribe((value: boolean) => {
      this.updateAddButtonState(true);
    });
  }

  medicationSectionlabel(patientDetails: PatientPharmacyInformation, showPatientName = true): string {
    if (showPatientName && patientDetails) {
      if (patientDetails.patient_LastName.slice(-1) === 's') {
        return '\' medication';
      } else {
        return '\'s medication';
      }
    } else {
      return '2 Load Medication (optional)';
    }
  }

  // Historical
  ngOnInit() {
    this.config = this.configService.getConfig();
    this.customizedSiteConfig = this.config.features.CustomConfig;
    this.comms.getCardDetailsAccess().subscribe(card => {
      this.cardInserted = card !== null && card.sessionId !== null;
      this.cardHolderName = card && card.userName;
    });
    this.MedicationList$.subscribe((value) => {
      this.CurrentMedicationList = new ParsedScrData();
      this.CurrentMedicationList.html_Summary = value.html_Summary;
      this.CurrentMedicationList.retrievalTime = value.retrievalTime;
      this.CurrentMedicationList.properties = value.properties;
      this.CurrentMedicationList.drugEntries = this.cloneMedicationList(value.drugEntries);
      this.DisplayMedicationList$.next(this.CurrentMedicationList);
      this.initialMedicationList = this.cloneMedicationList(value.drugEntries);
      this.setupScrDisplayState();
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.isScrAvailable$.subscribe((value) => {
      this.setupScrDisplayState();
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.ScrErrorState$.subscribe((err) => {
      this.setupScrDisplayState();
    });
    this.updateAddButtonState(false);
  }

  updateAddButtonState(editInProgress: boolean) {
    this.addButtonToolTip = '';
    if (editInProgress) {
      this.disableAddButton = true;
    } else if (this.isIM1Patient) {
      this.addButtonToolTip = 'Medications will come from GP Online Services';
      this.disableAddButton = true;
    } else {
      this.disableAddButton = false;
    }
  }

  toLocalDateString(date: Date): string {
    return UtilsService.toLocalDateString(new Date(date));
  }

  encodeHtml(html: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  setupScrDisplayState() {
    const scrStatus = this.isScrAvailable$ && this.isScrAvailable$.value && this.isScrAvailable$.value.status;
    const scrAuthorisationStatus = this.isScrAvailable$ && this.isScrAvailable$.value && this.isScrAvailable$.value.authorisationStatus;
    const errorState = this.ScrErrorState$ && this.ScrErrorState$.value;
    const htmlSummary = this.CurrentMedicationList && this.CurrentMedicationList.html_Summary;
    const retry = errorState && errorState.retry;

    const displayState = this.getScrDisplayState(this.cardInserted, scrStatus, errorState, htmlSummary);

    this.changeDisplayState(displayState, scrAuthorisationStatus, retry);
  }


  getScrDisplayState(cardInserted: boolean, scrStatus: PatientProfileStatusEnum,
    scrErrorState: ExternalPatientProfileError, scrHtmlSummary: string): string {
    let selectedState = 'BrokenValue';

    const scrStatusLoaded = scrStatus != null;
    const scrExists = scrStatusLoaded && scrStatus === PatientProfileStatusEnum.Exist;
    const scrLoaded = (scrHtmlSummary || '') !== '';
    const isError = scrErrorState != null;

    if (!cardInserted) {
      selectedState = 'NoNhsCard';
    } else if (!scrStatusLoaded && !isError) {
      selectedState = 'LoadingAvail';
    } else if (!scrStatusLoaded && isError) {
      selectedState = 'ErrorNoScr';
    } else if (!scrExists) {
      selectedState = this.getDisplayStateFromNotExistStatus(scrStatus);
    } else if (scrExists && !scrLoaded && !isError) {
      selectedState = 'ScrAvail';
    } else if (scrExists && isError) {
      selectedState = 'ScrLoadError';
    } else if (scrLoaded && !isError) {
      selectedState = 'ScrLoaded';
    }
    return selectedState;
  }

  getDisplayStateFromNotExistStatus(scrStatus: PatientProfileStatusEnum) {
    let selectedState: string;
    switch (scrStatus) {
      // case PatientProfileStatusEnum.Exist, eh? should not be getting here
      case PatientProfileStatusEnum.NotExists: selectedState = 'NoScr'; break;
      case PatientProfileStatusEnum.NoConsentHasRecord: selectedState = 'NoPermission'; break;
      case PatientProfileStatusEnum.IncorrectPermissions: selectedState = 'WrongPermission'; break;
      // case PatientProfileStatusEnum.UnableToDetermine, make this the default case
      case PatientProfileStatusEnum.NoConsentAndNoRecord: selectedState = 'NoPermissionNoScr'; break;
      default: selectedState = 'ErrorNoScr'; break;
    }
    return selectedState;
  }

  changeDisplayState(selectedState: string, scrAuthStatus: ExternalPatientProfileAuthorisationStatus, retry: boolean) {
    switch (selectedState) {
      case 'ScrLoadError':
        this.disableScrFetchButton = !retry;
        break;
      case 'ScrAvail':
        this.disableScrFetchButton = false;
        if (scrAuthStatus.allowedScrAccessInEmergency) {
          this.allowedScrAccessInEmergency = true;
        }
        if (scrAuthStatus.allowedScrAccessWithConsent) {
          this.allowedScrAccessWithConsent = true;
        }
        break;
      case 'Disabled':
        this.allowedScrAccessWithConsent = false;
        this.disableScrFetchButton = false;
        break;
      case 'NoNhsCard':
      case 'LoadingAvail':
      case 'ErrorNoScr':
      case 'NoPermission':
      case 'WrongPermission':
      case 'NoPermissionNoScr':
      case 'ScrLoaded':
      default:
        this.disableScrFetchButton = true;
        break;
    }

    this.scrDisplayLevel$.next(selectedState);
  }

  retreivePatientDrugRecord(emergencyAccessReason: string = null) {
    if (this.NhsNumber == null || this.DateOfBirth == null) {
      console.log('Attempting to retrieve NHS SCR while missing NHS Number or DoB');
      return;
    }
    this.scrDisplayLevel$.next('Loading');
    this.disableScrFetchButton = true;
    this.patientDrugRecordRetreivalRequested.emit({
      request: new NhsScrRequest(
        {
          nhsNumber: this.NhsNumber,
          dob: this.DateOfBirth.toISOString(),
          emergencyAccessReason: emergencyAccessReason
        })
    });
  }

  disableScrButton() {
    this.changeDisplayState('Disabled', null, null);
  }

  addMedication(drugRecord: ParsedScrRow) {
    console.log('Adding drug record', drugRecord);
    this.CurrentMedicationList.drugEntries.unshift(drugRecord);
    this.DisplayMedicationList$.next(this.CurrentMedicationList);
    this.isChanged = true;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  deleteMedication(mDrugID: any) {
    const confirmationString = ['Delete medication?'];
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    return new Promise<any>((resolve, reject) => {
      this.dialogService.dialog.closeAll();
      this.dialogService.openConfirmationDialog(confirmationString, 'Yes', 'No').then((response) => {
        if (response === DialogResponse.confirm) {
          this.CurrentMedicationList.drugEntries = this.CurrentMedicationList.drugEntries.filter((i) => i.maDrugId !== mDrugID);
          this.DisplayMedicationList$.next(this.CurrentMedicationList);
          this.saveUpdates();
        }
      });
    });
  }

  onUpdateERD(drugID: number) {
    const index = this.CurrentMedicationList.drugEntries.findIndex(i => i.maDrugId === drugID);
    if (index >= 0) {
      this.CurrentMedicationList.drugEntries[index].repeatLeft = null;
    }
    this.isChanged = true;
    this.DisplayMedicationList$.next(this.CurrentMedicationList);
  }

  onUpdateRepeatLeft() {
    this.isChanged = true;
    this.DisplayMedicationList$.next(this.CurrentMedicationList);
  }

  sourcetoText(source: number) {
    switch (source) {
      case 0:
        return 'Pharmacy';
      case 1:
        return 'Patient';
      case 2:
        return 'Summary Care Record';
      case 3:
        return 'GP';
      default:
        return 'N/A';
    }
  }

  addDrug() {
    const dialogRef = this.dialog.open(DrugUpdateDialogComponent, {
      width: '900px',
      data: { medicationList: this.CurrentMedicationList, mode: 'Add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.returnedDrugModel) {
        const returnedDrugModel = result.returnedDrugModel;
        const drug = new ParsedScrRow();
        drug.startDate = returnedDrugModel.startDate;
        drug.activeName = returnedDrugModel.activeName;
        drug._drugtext = returnedDrugModel.labelName;
        drug._instructions = returnedDrugModel._instructions;
        drug.erd = returnedDrugModel.erd;
        drug.quantity = returnedDrugModel.supply;
        drug.supply = returnedDrugModel.supply;
        drug.maDrugId = returnedDrugModel.id;
        drug.source = 0;
        drug.repeatLeft = returnedDrugModel.rptsLeft;
        drug.repeatCycle = returnedDrugModel.repeatCycle;
        drug.repeatCycleFrequency = returnedDrugModel.repeatCycleFrequency;
        if (!this.CurrentMedicationList) {
          this.CurrentMedicationList = new ParsedScrData();
          this.CurrentMedicationList.html_Summary = '';
        }
        if (!this.CurrentMedicationList.drugEntries) {
          this.CurrentMedicationList.drugEntries = [];
        }

        this.isChanged = true;
        this.CurrentMedicationList.drugEntries.push(drug);
        this.DisplayMedicationList$.next(this.CurrentMedicationList);
      }
    });
  }

  limitLength(e) {
    if (e.target.value.length === 4) {
      e.preventDefault();
    }
  }
  
  showAllDrugs() {
    this.expandIcon =!this.expandIcon
    if(this.showDrugs == 'drugs-list-all') {
      this.showDrugs = 'drugs-list';
    } else {
      this.showDrugs = 'drugs-list-all';
    }
    
  }

  updateDrug(item) {
    if (!this.isIM1Patient) {
      const dialogRef = this.dialog.open(DrugUpdateDialogComponent, {
        width: '900px',
        data: { selectedDrug: item, medicationList: this.CurrentMedicationList, mode: 'Edit' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.returnedDrugModel) {
          const returnedDrugModel = result.returnedDrugModel;
          const foundDrugIndex = this.CurrentMedicationList.drugEntries.findIndex((i) => i.maDrugId === item.maDrugId);

          if (foundDrugIndex >= 0) {
            this.CurrentMedicationList.drugEntries[foundDrugIndex] = {
              ...this.CurrentMedicationList.drugEntries[foundDrugIndex],
              activeName: returnedDrugModel.activeName,
              _drugtext: returnedDrugModel.labelName,
              _instructions: returnedDrugModel._instructions,
              erd: returnedDrugModel.erd,
              quantity: returnedDrugModel.supply,
              repeatLeft: returnedDrugModel.rptsLeft,
              supply: returnedDrugModel.supply,
              maDrugId: returnedDrugModel.id,
              source: returnedDrugModel.source,
              repeatCycle: returnedDrugModel.repeatCycle,
              repeatCycleFrequency: returnedDrugModel.repeatCycleFrequency
            };

            this.DisplayMedicationList$.next(this.CurrentMedicationList);
            this.isChanged = true;
          }
        }
      });
    }
  }

  drugSelectedForOrderChange() {
    this.hasDrugsSelected = this.DisplayMedicationList$.value.drugEntries.some(x => x.selectedForOrder);
  }

  showUpdates() {
    console.log(this.CurrentMedicationList);
  }

  cancelUpdate() {
    this.updateAddButtonState(false);
  }

  saveUpdates() {
    this.saveMedication.emit(this.CurrentMedicationList);
    this.updateAddButtonState(false);
    this.isChanged = false;
  }

  pharmacyOrderDrugs() {
    this.dialogService.openCustomDialog(PharmacyOrderComponent, new DialogConfig(),
    { 
      patientCode_MedAdvisorGUID: this.patientCode_MedAdvisorGUID, 
      practiceId: this.practice.id,
      transmissionMethod: this.practice.channel, 
      practiceName: this.practice.name, 
      patientInformation$: this.patientInformation$,
      drugs: this.DisplayMedicationList$.value.drugEntries.filter(x => x.selectedForOrder)
    
    })
    .then();
  }

  addIm1Creditials() {
    this.dialogService.openCustomDialog(Im1ConnectComponent, new DialogConfig(),
    { patientCode_MedAdvisorGUID: this.patientCode_MedAdvisorGUID, practiceId: this.practice.id, practiceName: this.practice.name })
    .then(() => {
      // Ideally should just reload the relevent information, but this will do for now.
      window.location.reload();
    }) ;
  }

  addDrugNew() {
    console.log(this.addDrugElement);
    console.log(this.drugListElement);
    this.updateAddButtonState(true);
    this.addDrugElement.edit();
    this.drugListElement.nativeElement.scrollTo(0, 0);
//    scroll(this.addDrugElement);
//    this.drugListElement.scroll(0, 0);
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }
  cloneMedicationList(source: ParsedScrRow[]): ParsedScrRow[] {
    if (source == null) {
      return null;
    }
    const result: ParsedScrRow[] = [];
    source.forEach(element => {
      const drugModel: ParsedScrRow = new ParsedScrRow();
      Object.assign(drugModel, element);
      result.push(drugModel);
    });
    return result;
  }
  popupEmergencyAccessComponent() {
    const dialogRef = this.dialog.open(EmergencyAccessComponent, {
      width: '900px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.emergencyAccessReason) {
        this.retreivePatientDrugRecord(result.emergencyAccessReason);
      }

    });
  }
}
