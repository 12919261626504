import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-load-spinner',
  templateUrl: './load-spinner.component.html',
})
export class LoadSpinnerComponent {
  mode = 'indeterminate';
  value = 50;
  @Input() diameter: number;
}
