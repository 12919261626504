import {DialogResponse} from 'src/app/utility/base-dialog/general-dialog/general-dialog.component';

export class CalendarDialogData {
  success: DialogResponse;
  fail: DialogResponse;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  settings: { [key: string]: any };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public constructor(settings: { [key: string]: any }) {
    this.settings = settings;
  }
}
