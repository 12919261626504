import { NhsScrRequest, ParsedScrData } from './../../api/ApiRecordTypes/NHSRecords';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Inject,
} from '@angular/core';
import { CommsModule } from 'src/app/utility/comms/comms.module';
import {
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import * as _ from 'lodash';
import { MAT_DATE_FORMATS } from '@angular/material/core';

import {
  IAPIService,
  OperationResult,
  OperationResultEnum,
  ExternalPatientProfileStatus,
  ExternalPatientProfileError
} from '../../api/iapi.service';
import { BehaviorSubject } from 'rxjs';
import { PatientPharmacyInformation, PDSRecord } from 'src/app/api/ApiRecordTypes/PatientRecord';
import { AppConfigService } from 'src/app/app-config.service';
import { PatientModel } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientPageState, ScrPageState } from 'src/app/api/ApiRecordTypes/PatientRecord';
import { ErrorResult } from 'src/app/api/ApiRecordTypes/OperationResults';
import { environment } from 'src/environments/environment';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
  useUtc: true,
};

enum ScreenState {
  search = 'Search',
  confirmPatientDetails = 'confirmPatientDetails',
  notFound = 'notFound',
  inviteSucess = 'inviteSucess',
  errorMessage = 'errorMessage',
}

enum ScreenMode {
  invitePatient = 'InvitePatient',
  inviteCarer = 'InviteCarer'
}

enum SearchType {
  nhsNumber = 'NHS',
  prescriptionCode = 'script barcode'
}

export class PatientAddOutput extends OperationResult<{ id: string }> {}

export class PatientAddConfig {
  public ShowCancel = false;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-patient-addpage',
  templateUrl: './patientaddpage.component.html',
  styleUrls: ['./patientaddpage.component.css'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class PatientAddPageComponent implements OnInit {
  @Input() public data: PatientAddConfig = new PatientAddConfig();
  @Output() public OnComplete: EventEmitter<PatientAddOutput> = new EventEmitter<PatientAddOutput>(true);

  env = environment;
  // Initial Screen
  displayScreen = ScreenState.search;
  searchingState = null;

  patientPageState: PatientPageState = new PatientPageState();
  scrPageState: ScrPageState = new ScrPageState();
  // data fields
  NHSNumber: string;
  DateOfBirth: Date;
  MobileNumber: string;
  patientFirstName = '';
  patientSurName = '';
  postCode = '';
  gender = 'F';
  prescriptionCode: string;
  verifyPatientConsent: false;
  isInviteEnabled = true;
  code = '';
  // Validate Search Fields
  validPhone = true;
  patientConsent = false;
  searchingInProgress = false;
  searchingStateSuccess = false;
  maxDate: Date = new Date();
  messageErr$ = new BehaviorSubject<string> ('');
  errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  toggleNHSPatientName = false;

  // Inital patientSearchModel
  patientSearch = new PDSRecord();
  patientSearch$ = new BehaviorSubject<PDSRecord>(this.patientSearch);
  isVerifiedSCRConsent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  MedicationList = new ParsedScrData();
  MedicationList$ = new BehaviorSubject<ParsedScrData>(this.MedicationList);
  DisplayMedicationList$: BehaviorSubject<ParsedScrData> = new BehaviorSubject<ParsedScrData>(this.MedicationList);
  isLoading = false;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  customizedSiteConfig: string;
  duplicatedrug = '';
  carerPatient: PatientModel = null;
  carerPatient$ = new BehaviorSubject<PatientModel | null>(this.carerPatient);
  screenMode: ScreenMode = ScreenMode.invitePatient;
  screenMode$ = new BehaviorSubject<ScreenMode | null>(this.screenMode);
  isScrAvailable$: BehaviorSubject<ExternalPatientProfileStatus> = new BehaviorSubject<ExternalPatientProfileStatus>(null);
  ScrErrorState$: BehaviorSubject<ExternalPatientProfileError> = new BehaviorSubject<ExternalPatientProfileError>(null);
  patientInformation: PatientPharmacyInformation = null;
  patientInformation$ = new BehaviorSubject<PatientPharmacyInformation>(this.patientInformation);

  constructor(
    private comms: CommsModule,
    private api: IAPIService,
    private cdr: ChangeDetectorRef,
    private configService: AppConfigService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.patientPageState.patientCode_MedAdvisorGuid = params.patientId;
    });
  }

  public setData(data: PatientAddConfig): void {
    this.data = data;
  }

  validForm(): boolean {
    const { MobileNumber } = this;

    if (MobileNumber) {
      return true;
    } else {
      return false;
    }
  }

  onPatientDrugRecordRetreivalRequested(data) {
    this.scrPageState.errorMessageNoDrug = '';
    this.scrPageState.errorMessageNoSCR = '';
    this.scrPageState.is500InternalServer = false;
    this.isLoading = true;
    let detail = new NhsScrRequest();
    if (data.request !== undefined) {
      detail = data.request;
    }
    const card = this.comms.getCardDetailsAccess().value;
    detail.nhsSessionId = card && card.sessionId;
    this.api.fetchPatientExternalDruglist(detail, this.config.ParseExternalDrugLists).subscribe(result => {
      this.ScrErrorState$.next(null);
      if (result.html_Summary === '' && result.drugEntries.length === 0) {
        this.scrPageState.errorMessageNoSCR = 'No SCR available';
      }
      if (result.html_Summary !== '' && result.drugEntries.length === 0) {
        this.scrPageState.isHtmlNotNullAndDrugNull = true;
      }
      if (result.drugEntries.length === 0) {
        this.scrPageState.errorMessageNoDrug = 'No medications added yet';

      }
      if (this.MedicationList) {
        const tempMedicationList = this.MedicationList;
        this.MedicationList = result;

        if (tempMedicationList && tempMedicationList.drugEntries) {
          tempMedicationList.drugEntries.forEach(element => {
            const index = this.MedicationList.drugEntries.findIndex(m => m.maDrugId === element.maDrugId);
            if (index >= 0) {
              this.MedicationList.drugEntries[index] = element;
              this.duplicatedrug += element._drugtext + ', ';
            } else {
              this.MedicationList.drugEntries.push(element);
            }
          });
        }

        this.duplicatedrug = this.duplicatedrug.substring(0, this.duplicatedrug.length - 2);

        this.MedicationList$.next(this.MedicationList);
      } else {
        this.MedicationList = result;
        this.MedicationList$.next(this.MedicationList);
      }

      this.isVerifiedSCRConsent$.next(true);
      this.isLoading = false;
      this.cdr.markForCheck();
    }, (err) => {
      // handle special case errors.
      if (err instanceof ErrorResult && err.errorType === 'ExternalPatientProfileError') {
          this.ScrErrorState$.next(err.errorData);
      }
      if (err === 'nhsSessionId must have a value' || err.status === 500) {
        this.scrPageState.errorMessageNoSCR = 'Error retrieving SCR. Please add any drugs manually';
        this.scrPageState.is500InternalServer = true;
      }
      this.isLoading = false;
      this.cdr.markForCheck();
    }
    );
  }

  onChangeIsHtmlNotNullAndDrugNull() {
    this.scrPageState.isHtmlNotNullAndDrugNull = false;
  }

  onUpdateMedicationModel(e) {
    this.scrPageState.errorMessageNoDrug = '';
    this.MedicationList = e;
    if (this.MedicationList.drugEntries.length < 4 && this.MedicationList.html_Summary) {
      this.scrPageState.isHtmlNotNullAndDrugNull = true;
    } else {
      this.scrPageState.isHtmlNotNullAndDrugNull = false;
    }
  }

  onPhoneChanged(data) {
    this.MobileNumber = data;
  }

  onValidPhoneOutput(data) {
    this.validPhone = data;
  }

  onSourceChanged(data) {
    this.duplicatedrug = data;
  }

  NHSchanged(e) {
    this.NHSNumber = e;
  }

  postCodeChanged(e) {
    this.postCode = e;
  }

  sexChanged(e) {
    this.gender = e;
  }

  surNameChanged(e) {
    this.patientSurName = e;
  }

  firstNameChanged(e) {
    this.patientFirstName = e;
  }

  DOBChanged(e) {
    this.DateOfBirth = new Date(e);
  }

  onSearchingInProgress(value) {
    this.searchingInProgress = value;
  }

  screenValueChanged(value) {
    this.displayScreen = value;
    if (value === ScreenState.search || value === ScreenState.notFound ) {
      this.searchingStateSuccess = false;
    }
  }

  onPhoneStatusChanged(value) {
    this.errorMessage$.next(value);
  }

  toggleNHSPatientNameEvent() {
    this.toggleNHSPatientName = !this.toggleNHSPatientName;
    this.displayScreen = ScreenState.search;
  }

  barcodeChanged(e) {
    this.prescriptionCode = e;
  }

  onVerifyPatientConsentCb(e) {
    this.verifyPatientConsent = e;
  }

  onSearchForPatient(data) {
    this.searchingStateSuccess = false;
    this.api.getExternalPatient(data).subscribe(
      (result) => {
        this.patientSearch = result.data;
        const identityPrivate = result.data.identityPrivate;
        this.MobileNumber = result.data.identityPrivate.phone;
        this.patientSearch$.next(this.patientSearch);
        if (this.patientInformation == null) {
          this.patientInformation = new PatientPharmacyInformation();
        }
        this.patientInformation.patient_FirstName = result.data && result.data.identityPrivate.firstName;
        this.patientInformation.patient_LastName = result.data && result.data.identityPrivate.lastName;
        this.patientInformation$.next(this.patientInformation);
        // update Screen - AfterViewChange
        this.ScrErrorState$.next(null);
        this.displayScreen = ScreenState.confirmPatientDetails;
        this.searchingStateSuccess = true;
        this.searchingInProgress = false;
        this.MedicationList = new ParsedScrData();
        this.MedicationList.html_Summary = '';
        this.cdr.markForCheck();

        const card = this.comms.getCardDetailsAccess().value;
        const nhsSessionId = (card && card.sessionId) || null;
        const dob = new Date(identityPrivate.dateOfBirth).toLocaleDateString();
        this.api.checkIsStatusAvailable(identityPrivate.externalSystemCode, dob, nhsSessionId).subscribe(
          (statusResult) => {
            this.isScrAvailable$.next(statusResult);
          },
          (err) => {
            // handle special case errors.
            if (err instanceof ErrorResult && err.errorType === 'ExternalPatientProfileError') {
              this.ScrErrorState$.next(err.errorData);
            }
            console.error(err);
          }
        );
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (err) => {
        // update Screen - AfterViewChange
        this.displayScreen = ScreenState.errorMessage;
        this.isVerifiedSCRConsent$.next(false);
        this.messageErr$.next (`No results found`);
        this.searchingInProgress = false;
        this.cdr.markForCheck();
      }
    );
  }

  ngOnInit() {
    this.config = this.configService.getConfig();
    this.customizedSiteConfig = this.config.features.CustomConfig;
    this.scrPageState.errorMessageNoDrug = '';
    this.scrPageState.errorMessageNoSCR = '';
    this.scrPageState.isHtmlNotNullAndDrugNull = false;

    const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
    this.api.getPharmacySettings(accountSetting.id).subscribe( (result) => {
      if (this.patientInformation == null) {
        this.patientInformation = new PatientPharmacyInformation();
      }
      this.patientInformation.pharmacy_Name = result && result.name;
      this.patientInformation$.next(this.patientInformation);
    });

    if (this.patientPageState.patientCode_MedAdvisorGuid) {
      this.api.getPatientDetails(this.patientPageState.patientCode_MedAdvisorGuid).subscribe(result => {
       if (result) {
         this.carerPatient = result;
         this.carerPatient$.next(this.carerPatient);
         this.screenMode = this.carerPatient ? ScreenMode.inviteCarer : ScreenMode.invitePatient;
         this.screenMode$.next(this.screenMode);
         this.comms.setPage({
          pageTitle: 'Link patient to carer',
          page: 'patientadd',
          pageIcon: ''
         });
       }
     });
    }

    this.comms.setPage({
      pageTitle: this.screenMode === ScreenMode.invitePatient ? 'Invite Patient' : 'Link patient to carer',
      page: 'patientadd',
      pageIcon: ''
    });
  }

  onScriptSearch() {
    if (_.isEmpty(this.prescriptionCode)) {
     this.displayScreen = ScreenState.errorMessage;
     this.messageErr$.next ('Please Enter a valid Barcode');
   }
   if (!_.isEmpty(this.prescriptionCode)) {
     const patient = {
       prescriptionCode: this.prescriptionCode,
     };
     this.displayScreen = ScreenState.search;
     this.searchingState = SearchType.prescriptionCode;
     this.searchingInProgress = true;

     this.api.getExternalPatient(patient).subscribe(result => {
       this.patientSearch = result.data;
       this.MobileNumber = result.data.identityPrivate.phone;
       this.patientSearch$.next(this.patientSearch);
       // update Screen - AfterViewChange
       this.displayScreen = ScreenState.confirmPatientDetails;
       this.searchingInProgress = false;
       this.cdr.markForCheck();
     }, () => {
       // update Screen - AfterViewChange
       this.displayScreen = ScreenState.errorMessage;
       this.messageErr$.next ('Please Enter a valid Barcode');
       this.searchingInProgress = false;
       this.cdr.markForCheck();
     });
    }
  }

  invitePatient(details) {
    this.api.invitePatient(details).subscribe(result => {
      // this.displayScreen = ScreenState.inviteSucess;
      if (result.result === OperationResultEnum.SUCCESS) {
        this.displayScreen = ScreenState.inviteSucess;
        this.errorMessage$.next('');
        this.isVerifiedSCRConsent$.next(false);
        this.comms.setPage({
          pageTitle: 'Invite sent',
          page: 'patientadd',
          pageIcon: 'check_circle'
        });
        this.code = result.data.id;
      } else {
        alert(JSON.stringify(result));
      }
        this.isInviteEnabled = true;
        this.cdr.markForCheck();
      }, (err) => {
        this.errorMessage$.next(err.error.resultMessage.replace('not found.', ''));
        this.isInviteEnabled = true;
        this.cdr.markForCheck();
      });
  }

  invitePatientCarer(details) {
    this.api.invitePatientCarer(details).subscribe(result => {
      if (result.result === OperationResultEnum.SUCCESS) {
        this.displayScreen = ScreenState.inviteSucess;
        this.errorMessage$.next('');
        this.isVerifiedSCRConsent$.next(false);
        this.comms.setPage({
          pageTitle: 'Patient linked to carer',
          page: 'patientadd',
          pageIcon: 'check_circle'
        });
      } else {
        alert(JSON.stringify(result));
      }
      this.isInviteEnabled = true;
      this.cdr.markForCheck();
    }, (err) => {
      this.errorMessage$.next(err.error.resultMessage.replace('not found.', ''));
      this.isInviteEnabled = true;
      this.cdr.markForCheck();
    });
  }

  onInvitePatient(details) {
    if (this.screenMode === ScreenMode.invitePatient) {
      this.isInviteEnabled = false;
      this.patientFirstName = details.properties.firstname;
      this.invitePatient(details);
    } else if (this.screenMode === ScreenMode.inviteCarer) {
      this.invitePatientCarer(details);
    }
  }
}
