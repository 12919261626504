import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {AuthenticationService} from '../../authentication/authentication.service';
import { CommsModule } from 'src/app/utility/comms/comms.module';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { PublicExternalSessionDetailsWithAuth } from 'src/app/api/api.service';
import { DataService } from 'src/app/utility/dataservice/data.service';
import { menuOptions, MenuOption, MenuItemData} from 'src/app/app-menu.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent {
  menuItemData: MenuItemData[] = [];
  menuItemData$ = new BehaviorSubject<MenuItemData[]>(this.menuItemData);

  constructor(
    private comms: CommsModule,
    private authService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    public settingService: DataService,
    private router: Router,
  ) {
    this.LoadBaseMenuData();
    const roles = this.comms.getRolesAccess().value;
    this.updateMenuItemVisibility(roles);
    this.activateCustomHandlers(true, true); // Initialise values and setup callbacks for custom handlers.

    this.PushMenuItemChanges();

    this.comms.getRolesAccess().subscribe(currentRoles => {
      // update the menu based on the current roles.
      this.processRoleOrPageChange(currentRoles);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.comms.getPageChanges().subscribe(pagedetails => {
      const currentRoles = this.comms.getRolesAccess().value;
      this.processRoleOrPageChange(currentRoles);
    });
  }

  processRoleOrPageChange(roles: string[]) {
    this.updateMenuItemVisibility(roles);
    this.activateCustomHandlers(true, false); // Only collect current values.  Don't reset callbacks.
    this.PushMenuItemChanges();

  }

  activateCustomHandlers(withCurrentValues: boolean, withCallbacks= false): boolean {
    let changesMade = false;
    for (let i = 0; i < this.menuItemData.length; i++) {
      const menuItem = this.menuItemData[i];
      if (menuItem.baseOptions.enableKey !== null) {
        const currentChangesMade = this.setupMenuConditions(i, menuItem, withCurrentValues, withCallbacks);
        changesMade =  currentChangesMade || changesMade;
      }
    }
    return changesMade;
  }
  // Combine option data and general menu data to produce the base list of menu items to use.
  LoadBaseMenuData() {
    this.menuItemData = [];
    const configSettings = this.comms.getConfigSettingAccess().value;
    const menus = (configSettings && configSettings.features && configSettings.features.IncludeMenus) || null;

    for (const menuName in menus) {
      if (menuName in menuOptions) {
        const singleMenuItem = new MenuItemData();
        singleMenuItem.baseOptions = this.mergeMenuConfig(menuOptions[menuName], menus[menuName]);
        this.menuItemData.push(singleMenuItem);
      }
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  mergeMenuConfig(menuItem: MenuOption, menuSettings: any): MenuOption {
    for (const field of Object.keys(menuSettings)) {
      menuItem[field] = menuSettings[field];
    }

    return menuItem;
  }

  setupMenuConditions(menuItemNumber: number, menuItemData: MenuItemData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    withCurrentValues: boolean, withSubscriptions= false): boolean {
    // Each entry in this list needs to set current visibility if withCurrentValues is true,
    // and needs to registers subscriptions to update values if withSubscriptions is set - Generally
    // withCurrentValues should always have code (it's for reinitialising values after they get reset by
    // other system events such as role changes.) withSubscriptions is only needed if events can cause the menu to change state
    // - this is a very likely occurance is would be expected for most of these.
    // If the function is disabling a menu item, it should set a tool-tip to explain why the function is not available.
    switch (menuItemData.baseOptions.enableKey) {
      case 'NHSCardRequired': { return this.setupNHSCardRequirments(menuItemNumber, menuItemData, withCurrentValues, withCurrentValues); }
    }
  }

  // move this code into domain specific code by using a domain specific version of setupMenuConditions.
  setupNHSCardRequirments(menuItemNumber: number, menuItem: MenuItemData,
    withCurrentValues: boolean, withCallbacks= false): boolean {
    if (withCallbacks) {
      this.comms.getCardDetailsAccess().subscribe( result => {
        if (this.NHSCardValueResponder(result, menuItemNumber)) {
          this.PushMenuItemChanges();
        }
      });
      // Setup subscriptions to watch for events that change the display of this record.
    }
    if (withCurrentValues) {
      return this.NHSCardValueResponder(this.comms.getCardDetailsAccess().value, menuItemNumber);
    }
    return false;
  }

  NHSCardValueResponder(cardDetails: PublicExternalSessionDetailsWithAuth, menuItemNumber: number): boolean {
    if ((cardDetails != null) && (
            cardDetails.activityCodes.indexOf('B0010_CaldicottGuardian') !== -1 ||
            cardDetails.activityCodes.indexOf('B0011_AnalyseAuditTrails') !== -1)
        ) {
      return this.setMenuItemView(this.menuItemData[menuItemNumber], true, null, null);
    }
    return this.setMenuItemView(this.menuItemData[menuItemNumber], false, null, null);
  }

  // Determine which menu items should be visible and which should be enabled.
  updateMenuItemVisibility(roles: string[]): boolean {
    let changesMade = false;
    for (let i = 0; i < this.menuItemData.length; i++) {
      const menuItem = this.menuItemData[i];
      const roleMatch = this.checkRole(menuItem.baseOptions.requireRole, roles);
      // check hidden
      const isHidden = this.checkItemIsHidden(menuItem.baseOptions, roleMatch);
      // check enabled
      const isEnabled = !isHidden && this.checkItemIsEnabled(menuItem.baseOptions, roleMatch);
      // check active
      const isActive = !isHidden && this.checkItemIsActive(menuItem.baseOptions, roleMatch);
      changesMade = this.setMenuItemView(menuItem, isEnabled, isActive, isHidden) || changesMade;
    }
    return changesMade;
  }

  checkItemIsHidden(item: MenuOption, roleMatch: boolean): boolean {
    return !(roleMatch || item.showAlways);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkItemIsEnabled(item: MenuOption, roleMatch: boolean): boolean {
    return (roleMatch);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkItemIsActive(item: MenuOption, roleMatch: boolean): boolean {
    return this.router.isActive(item.target, true);
  }

  // processes all menu items to check for ones flagged as needing a refresh
  // and creates new objects for them so the call to .next triggers a response.
  PushMenuItemChanges() {
    for (let i = 0; i < this.menuItemData.length; i++) {
      const menuItem = this.menuItemData[i];
      if (menuItem.refresh) {
        const newMenuItem = new MenuItemData();
        newMenuItem.isActive = menuItem.isActive;
        newMenuItem.isEnabled = menuItem.isEnabled;
        newMenuItem.isHidden = menuItem.isHidden;
        newMenuItem.baseOptions = menuItem.baseOptions;
        newMenuItem.refresh = false; // no refresh needed after this call completes
        this.menuItemData[i] = newMenuItem;
      }
    }
    this.menuItemData$.next(this.menuItemData);
  }

  // Determines if the current user roles match an entry in a roleRequirements array
  checkRole(roleRequirements: string[], roles: string[]): boolean {
    if (roleRequirements == null) { return true; }
    if (roles === null) { roles = []; }
    for (const roleOption of roleRequirements) {
      // RoleRequirements are a list of rules that define access to a menu item.
      // All roles in a rule must match to pass the rule
      // Any rule must match to pass the check

      let roleMatches = true;
      for (const roleSegment of roleOption.split('+')) {
        const segment = roleSegment.trim();
        if (!roles.includes(segment)) {
          roleMatches = false;
          break;
        }
      }

      if (roleMatches) { return true; }
    }
    return false;
  }

  toggleMenu() {
    this.comms.requestSidebarStateToggle();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSwipeLeft(e) {
      this.toggleMenu();
    }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSwipeRight(e) {
    this.toggleMenu();
  }

  onMenuItemSelected(source: MenuOption) {
    this.toggleMenu();
    const uri = source.target;
    if (source.target === '') {
      // handle option by id.
      switch (source.id) {
        case 'logoutNavigation': {
          this.authService.logout();
          break; }
      }
      return;
    }
    // Only navigate to calendar module
    if (uri === '/calendar') {
      this.router.navigate([uri]);
      return;
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([uri]);
    });
  }

  // This method handles setting data on an item and sets the refresh flag if any data has changed.
  setMenuItemView(menuItem: MenuItemData, isEnabled: boolean= null,
    isActive: boolean= null, isHidden: boolean= null, baseOptions: MenuOption= null): boolean {
    // If the call causes no changes, exit without marking the menuItem to be refreshed.
    if (baseOptions === null &&
        (isEnabled === null || isEnabled === menuItem.isEnabled) &&
        (isHidden === null || isHidden === menuItem.isHidden) &&
        (isActive === null || isActive === menuItem.isActive)
      ) { return false; }

    if (baseOptions !== null) { menuItem.baseOptions = baseOptions; }
    if (isEnabled !== null) { menuItem.isEnabled = isEnabled; }
    if (isHidden !== null) { menuItem.isHidden = isHidden; }
    if (isActive !== null) { menuItem.isActive = isActive; }
    menuItem.refresh = true;
    return true;
  }
}
