import { LogEntryModel } from './log-entry.model';

export class IGlobalLoggingInterface {
   
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logAsync(logEntry: LogEntryModel): void  {
      throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logDebug(message: string, methodName: string, className: string): void  {
      throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logInformation(message: string, methodName: string, className: string): void  {
      throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logWarning(message: string, methodName: string, className: string): void  {
      throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logError(message: string, methodName: string, className: string): void {
      throw new Error('Method not implemented.');
    }

  }

