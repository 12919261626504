import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IApiHelperService } from '../../api-helper.interface';
import { OperationResult } from '../../iapi.service';
import { Im1PatientPracticeModel } from './ApiRecordsTypes/Im1PatientPracticeModel';
import { IUkApiService } from './uk-api.interface';
@Injectable({
    providedIn: 'root'
  })
export class UkApiService implements IUkApiService {

    constructor (public http: HttpClient, public apiHelperService: IApiHelperService) {

    }
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public connectToIM1Async(im1PatientPracticeModel: Im1PatientPracticeModel): Subject<any> {
      return this.apiHelperService.CreateObservableResult(
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        this.http.post<OperationResult<any>>(
          `${this.apiHelperService.getBaseApiPath('1')}practice/im1connect`, im1PatientPracticeModel
        ),
        null
      );
    }
}
