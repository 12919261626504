import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IApiHelperService } from "../api-helper.interface";
import { OperationResult } from "../api.service";
import { IdResultModel } from "../ApiRecordTypes/PatientGroup";
import { PatientRequestModel } from "../ApiRecordTypes/PendingTaskRecord";
import { IDispenseRequestApiService } from "./dispense-request-api.interface";

@Injectable({
    providedIn: 'root'
})
export class DispenseRequestApiService implements IDispenseRequestApiService {
    constructor (public http: HttpClient, public apiHelperService: IApiHelperService) {
    }

    public createOrUpdateAsync (model: PatientRequestModel): Observable<number> {
        return this.apiHelperService.CreateObservableResult(
            this.http.post<OperationResult<IdResultModel>>(
            `${this.apiHelperService.getBaseApiPath('1')}patient/dispenserequest`, model),
            null
        ).pipe(
            map((data: IdResultModel) => {
                return parseInt(data.id, 10);
            })
        );
    }

}

