import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output,
  EventEmitter, Inject, ViewChild, TemplateRef, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ManageOrderOutput } from '../order/order.component';
import { Router } from '@angular/router';
import { PendingTaskRecord, IAPIService } from 'src/app/api/api.service';
import { BehaviorSubject } from 'rxjs';
import { DataService } from 'src/app/utility/dataservice/data.service';
import { DomSanitizer } from '@angular/platform-browser';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'lodash';
import { NAVIGATION_BAR_SERVICE_PROVIDER } from 'src/app/navigation/header/headerDirectives/headerConstants';
import { INavigationBarService } from 'src/app/navigation/header/headerDirectives/headerServiceInterface';
import { AppConfigService } from 'src/app/app-config.service';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';



@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-manage-order',
  templateUrl: './manageorder.component.html',
})
export class ManageOrderComponent implements OnInit, AfterViewInit, OnDestroy {
  public data: PendingTaskRecord = new PendingTaskRecord();
  @Output() public OnComplete: EventEmitter<ManageOrderOutput> = new EventEmitter<ManageOrderOutput>(true);

  patientMessage = '';
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  dispenseRequest:  any;
  medicationlist = [];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  medicationlist$ = new BehaviorSubject<any>(this.medicationlist);
  statusCode: string = null;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  statusCode$ = new BehaviorSubject<any>(this.statusCode);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  drugs: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  actionAvailable: any;
  componentStatus = [];
  isComponentLoading = true;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  medicationData: any;
  isProcessOrder = false;
  isCompletedOrder = false;
  showBanner = true;
  countReadyItem = 0;
  countCancelItem = 0;
  countPendingItem = 0;
  countItemOnOrder = 0;
  isClickSave = false;
  isSaveDone = false;
  isUnableToFill = false;
  dateFormat: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  @ViewChild('navigationTitle', {static: true} )
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public readonly navBarTitle: TemplateRef<any>;

  constructor(
    private router: Router,
    private api: IAPIService,
    private performAction: DataService,
    private configService: AppConfigService,
    @Inject(NAVIGATION_BAR_SERVICE_PROVIDER) protected navigationBarService: INavigationBarService,
    protected chagneDetector: ChangeDetectorRef,
    public sanitizer: DomSanitizer
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as PendingTaskRecord;
    this.data = state;
  }

  getOrderStatus() {
    this.countReadyItem = 0;
    this.countCancelItem = 0;
    this.countPendingItem  = 0;
    this.countItemOnOrder = 0;

    this.componentStatus.forEach(element => {
      if (element.status === '1') {
        this.countReadyItem ++;
      } else {
        if (element.status === '12') {
          this.countCancelItem ++;
        } else if (element.status === '13') {
          this.countItemOnOrder ++;
        } else {
          this.countPendingItem ++;
        }
      }
    });
  }

  ngOnInit(): void {
    this.config = this.configService.getConfig();

    if (this.data == null) {
      this.router.navigate(['/messages']);
      return;
    }

    const taskData = JSON.parse(this.data.jSonTaskData);
    this.statusCode = this.data.statusCode;
    this.statusCode$.next(this.statusCode);
    if (this.performAction.getPerformAction() === 'UnableToFill') {
      this.isUnableToFill = true;
    }

    this.drugs = taskData && taskData.Drugs;
    this.api.getDispenseRequest(this.data.patient.patientCode_MedAdvisorGUID, taskData.PatientRequestId).subscribe(result => {
        this.dispenseRequest = result.data;
        this.medicationlist = this.dispenseRequest.components;
        this.isComponentLoading = false;

        this.medicationlist.forEach(element => {
          if (this.performAction.getPerformAction() === 'UnableToFill') {
            element.statusModel.status = 'UnableToFill';
          }
          const componentObj = {
            componentId: element && element.id,
            status: this.statusToNumber(element.statusModel.status),
            ischanged: false
          };

          this.componentStatus.push(componentObj);
        });

        if (this.performAction.getPerformAction() === 'UnableToFill') {
          this.medicationlist = this.changeStatusIcon();
        }
        this.medicationlist$.next(this.medicationlist);
    });
  }

  ngAfterViewInit(): void {
    this.navigationBarService.editTemplate('title', this.navBarTitle);
    this.chagneDetector.detectChanges();
  }

  orderNo(): string {
    return this.data && this.data.orderNo ? `#${this.data.orderNo}` : '';
  }

  ngOnDestroy(): void {
    this.navigationBarService.deleteTemplate('title');
  }

  statusToNumber(status: string): string {
    switch (status) {
      case 'Ready':
      case 'ReadyToCollect':
        return '1';
      case 'Cancelled':
        return '3';
      case 'UnableToFill':
        return '12';
      case 'OnOrder':
        return '13';
      case 'ReadyForDelivery':
      case 'MarkForDelivery':
        return '15';
    }
    return '0';
  }

  numberToStatus(status: string): string {
    switch (status) {
      case '1':
        return 'Ready';
      case '3':
        return 'Cancelled';
      case '12':
        return 'UnableToFill';
      case '13':
        return 'OnOrder';
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  calendarTime(date: any): string {
    return UtilsService.calendarTime(date);
  }

  goBackMessageList() {
    this.router.navigateByUrl('/messages');
  }

  displayStatus(status: string): string {
      switch (status) {
        case 'GPApproved':
          return 'GP Approved';
        case 'PartiallyReady':
          return 'Partially Ready';
        case 'ReadyToCollect':
        case 'Ready':
          return 'Ready to Collect';
        case 'UnableToFill':
          return 'Unable to Fill';
        case 'Cancelled':
          return 'Cancelled';
        case 'Pending':
          return 'Order Received';
        case 'AwaitingGP':
          return 'Sent to GP';
        case 'AwaitingPharmacyApproval':
          return 'Awaiting Pharmacy Approval';
        case 'AwaitingGPOverdue':
          return 'Sent to GP — Overdue';
        case 'RemoteSignupRequested':
          return 'Remote Signup Requested';
        case 'Collected':
          return 'Collected';
        case 'OnOrder':
          return 'Item on Order';
        default:
          return 'Unknown';
      }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  materialIconMobile(component: any): string {
    switch (component.statusModel.status) {
      case 'Pending':
        return '../../../assets/images/statusPDFCreated.svg';
      case 'ReadyToCollect':
      case 'Ready':
        return '../../../assets/images/statusReadyToCollect.svg';
      case 'PartiallyReady':
        return '../../../assets/images/statusPartiallyReady.svg';
      case 'Cancelled':
        return '../../../assets/images/statusUnableToFill.svg';
      case 'UnableToFill':
        return '../../../assets/images/statusUnableToFill.svg';
      case 'AwaitingGP':
        return '../../../assets/images/statusSentToGP.svg';
      case 'GPApproved':
        return '../../../assets/images/statusGPApproved.svg';
      case 'AwaitingGPOverdue':
        return '../../../assets/images/statusOverdueAlert.svg';
      case 'AwaitingPharmacyApproval':
        return '../../../assets/images/statusAwaiting.svg';
      case 'RemoteSignupRequested':
        return '../../../assets/images/statusSignUpRequested.svg';
      case 'Collected' :
        return '../../../assets/images/statusCollected.svg';
      case 'OnOrder':
        return '../../../assets/images/statusAwaiting.svg';
      default:
        return '';
    }
  }

  materialIconMobileOrder(status: string): string {
    switch (status) {
      case 'ReadyToCollect':
        return '../../../assets/images/statusReadyToCollect.svg';
      case 'PartiallyReady':
        return '../../../assets/images/statusPartiallyReady.svg';
      case 'Cancelled':
        return '../../../assets/images/statusUnableToFill.svg';
      case 'UnableToFill':
        return '../../../assets/images/statusUnableToFill.svg';
      case 'Pending':
        return '../../../assets/images/statusPDFCreated.svg';
      case 'Collected' :
        return '../../../assets/images/statusCollected.svg';
      case 'OnOrder':
        return '../../../assets/images/statusAwaiting.svg';
      default:
        return '';
    }
  }

  orderClass(status: string): string {
    return `status-${status}`;
  }

  onActionChange(value, component) {
    const componentObj = {
      componentId: component && component.id,
      status: value,
      ischanged: true
    };

    this.isProcessOrder = true;
    this.isSaveDone = false;
    const index = this.componentStatus.findIndex((item) => item.componentId === component.id);
    if (index >= 0) {
      this.componentStatus.splice(index, 1);
    }

    this.componentStatus.push(componentObj);
    this.medicationlist = this.changeStatusIcon();
    this.medicationlist$.next(this.medicationlist);
    this.isCompletedOrder = this.checkOrderCompleted();
  }

  checkOrderCompleted(): boolean {
    this.getOrderStatus();
    const countCompletedItems = this.countReadyItem + this.countItemOnOrder + this.countReadyItem;
    if (this.componentStatus.length === this.countReadyItem
      || this.componentStatus.length === this.countCancelItem
      || this.componentStatus.length === this.countItemOnOrder
      || this.componentStatus.length === countCompletedItems
      ) {
      return true;
    }

    return false;
  }

  updateOrderStatus(): string {
    this.getOrderStatus();

    if (this.componentStatus.length === this.countReadyItem) {
      return 'ReadyToCollect';
    }

    if (this.componentStatus.length === this.countCancelItem) {
      return 'UnableToFill';
    }

    if (this.countCancelItem > 0 && (this.componentStatus.length - this.countReadyItem - this.countItemOnOrder) === this.countCancelItem) {
      return 'PartiallyReady';
    }

    if (this.countReadyItem > 0 && this.countPendingItem > 0) {
      return 'PartiallyReady';
    }

    return this.statusCode;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  updateItemStatus(listMedication, listStatus): any[] {
    for (let i = 0; i < listMedication.length; i++) {
      for (let j = 0; j < listStatus.length; j++) {
        listStatus[j].ischanged = false;
        if (listMedication[i].id === listStatus[j].componentId) {
          const status = this.numberToStatus(listStatus[j].status);
          if (status === 'OnOrder') {
            listMedication[i].actionsAvailable = ['ReadyToCollect', 'UnableToFill', 'OnOrder'];
          } else if (status === 'Ready') {
            listMedication[i].actionsAvailable = ['UnableToFill', 'OnOrder'];
          } else if (status === 'UnableToFill') {
            listMedication[i].actionsAvailable = ['ReadyToCollect', 'OnOrder'];
          }
        }
      }
    }
    return this.medicationlist;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  changeStatusIcon(): any[] {
    for (let i = 0; i < this.medicationlist.length; i++) {
      for (let j = 0; j < this.componentStatus.length; j++) {
        if (this.medicationlist[i].id === this.componentStatus[j].componentId) {

          if (this.componentStatus[j].status === '1'
                || this.componentStatus[j].status === '12'
                || this.componentStatus[j].status === '13') {
            this.medicationlist[i].statusModel.status = this.numberToStatus(this.componentStatus[j].status);
          }
        }
      }
    }

    return this.medicationlist;
  }

  onSave() {
    this.isClickSave = true;
    const taskData = JSON.parse(this.data.jSonTaskData);

    const dispenseDetails = {
      patientRequestId: taskData.PatientRequestId,
      statusMessage: this.patientMessage,
      componentStatus: this.componentStatus.filter(item => item.status !== '0' && item.ischanged),
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.api.updateComponentStatus(dispenseDetails).subscribe(result => {
        this.statusCode = this.updateOrderStatus();
        this.statusCode$.next(this.statusCode);
        if (this.isUnableToFill || this.isCompletedOrder) {
          setTimeout(() => {
            this.router.navigate(['/messages']);
          }, 1000);
        } else {
          this.updateItemStatus(this.medicationlist, this.componentStatus.filter(item => item.status !== '0' && item.ischanged));
          this.medicationlist$.next(this.medicationlist);
          this.isSaveDone = true;
          this.isClickSave = false;
        }
      }, err => {
         console.log('Error, please try again - ', err);
       }
     );
  }
}
