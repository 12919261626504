import { Component, Input } from '@angular/core';
import { PrescreenFormModel } from '../../../api/ApiRecordTypes/PreScreenFormModel';
import * as moment from 'moment';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-flu-vax-form',
  templateUrl: './flu-vax-form.component.html'
})
export class FluVaxFormComponent {
  // #region Properties
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input() public prescreenResults: PrescreenFormModel[];

  public preVaccinationQuestions = [
    {
      question: 'question1',
      questionText: 'You have a disease that lowers immunity (e.g. leukemia, cancer, HIV/AIDS) or having treatment that lowers immunity (e.g. oral steroid medicines such as cortisone or prednisolone, radiotherapy or chemotherapy)',
      answer: null
    },
    {
      question: 'question2',
      questionText: 'You have severe allergies (to anything)',
      answer: null
    },
    {
      question: 'question3',
      questionText: 'You have had a severe reaction following any vaccine',
      answer: null
    },
    {
      question: 'question4',
      questionText: 'You have had any vaccine in the past month',
      answer: null
    },
    {
      question: 'question5',
      questionText: 'You have had an injection of immunoglobulin, or have received any blood products or a whole blood transfusion within the past year',
      answer: null
    },
    {
      question: 'question6',
      questionText: 'You are pregnant',
      answer: null
    },
    {
      question: 'question7',
      questionText: 'You have a history of Guillain - Barre syndrome',
      answer: null
    },
    {
      question: 'question8',
      questionText: 'You were a pre-term infant',
      answer: null
    },
    {
      question: 'question9',
      questionText: 'You have a chronic illness',
      answer: null
    },
    {
      question: 'question10',
      questionText: 'You have a bleeding disorder',
      answer: null
    },
    {
      question: 'question11',
      questionText: 'You are of Aboriginal or Torres Strait Islander descent',
      answer: null
    },
    {
      question: 'question12',
      questionText: 'You do not have a functioning spleen',
      answer: null
    },
    {
      question: 'question13',
      questionText: 'You are planning pregnancy or anticipating parenthood',
      answer: null
    },
    {
      question: 'question14',
      questionText: 'You are a parent, grandparent or a carer of a newborn',
      answer: null
    },
    {
      question: 'question15',
      questionText: 'You live with someone who has a disease that lowers immunity (e.g. leukemia, cancer, HIV/AIDS) or live with someone who is having treatment that lowers immunity (e.g. oral steroid medicines such as cortisone or prednisolone, radiotherapy or chemotherapy)',
      answer: null
    },
    {
      question: 'question16',
      questionText: 'You are planning travel',
      answer: null
    },
    {
      question: 'question17',
      questionText: 'You have an occupation or lifestyle factor(s) for which vaccination may be needed (discuss with doctor/pharmacist/nurse). Please specify below.',
      answer: null
    },
    {
      question: 'question18',
      questionText: '',
      answer: null
    }
  ];

  public lifeStyleQuestion = 'You have an occupation or lifestyle factor(s) for which vaccination may be needed (discuss with doctor/pharmacist/nurse). Please specify below.';

  public prescreenFormGroup = new UntypedFormGroup({});
  // #region Constructor
  // #endregion

  // #region Constructor
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public constructor () {
  }

  // #endregion

  public formatCompletedDate (date) {
    if (!date) {
      return '';
    }
    return moment(date).format('DD/MM/YYYY');
  }

  public formatStartDate (date) {
    if (!date) {
      return '';
    }
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

  public formatDateOfBirth (date) {
    if (!date) {
      return '';
    }
    return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD/MM/YYYY');
  }
}
