import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MessageListComponent } from './messages/messagelist/messagelist.component';
import { SurgeryListComponent} from './surgeries/surgerylist/surgerylist.component';
import { SurgeryDetailsComponent} from './surgeries/surgerydetails/surgerydetails.component';
import { PatientAddPageComponent } from './patients/patientaddpage/patientaddpage.component';
import { AuditComponent } from './audit/audit.component';
import { QxlvaAuthComponent } from './authentication/qxlvaauth/qxlvaauth.component';
import { PatientListComponent } from './patients/patientlist/patientlist.component';
import { RoleGuardService as RoleGuard } from './authentication/roleguard.service';
import { PatientDetailsComponent } from './patients/patientdetails/patientdetails.component';
import { SettingsComponent } from './settings/settings.component';
import { ManageOrderComponent } from './messages/manageorder/manageorder.component';
import {CalendarWidgetAuthGuardService} from './authentication/calendar-widget-auth-guard.service';
import {CalendarGuard} from './booking-calendar/guard/calendar-guard';
import {ViewCalendarComponent} from './booking-calendar/components/view-calendar/view-calendar.component';

const routes: Routes = [
  { path: '', redirectTo: 'messages', pathMatch: 'full' },
  {
    path: 'set-password',
    loadChildren: () => import('../app/set-password/set-password.module').then(m => m.SetPasswordModule)
  },
  { path: 'messages',

        component: MessageListComponent,
        data: {
          requestType: 'inbox',
          expectedRoles: ['GPCUsers', 'Prescriber'],
          failTo: 'settings',
          displayWhileAuthenticating: true,
          enableKey: 'Inbox'
        },
        canActivate: [RoleGuard],
        runGuardsAndResolvers: 'always'
  },
  { path: 'messages/order',
    component: ManageOrderComponent,
    data: {
      expectedRoles: ['GPCUsers', 'Prescriber'],
      failTo: 'messages',
      displayWhileAuthenticating: true,
      enableKey: 'Inbox'
    },
    canActivate: [RoleGuard],
    runGuardsAndResolvers: 'always'
  },
  { path: 'patients',
      component: PatientListComponent,
      data: {
          expectedRoles: ['GPCUsers', 'Prescriber'],
          failTo: 'settings',
          displayWhileAuthenticating: true,
          enableKey: 'Patients'
      },
        canActivate: [RoleGuard],
        runGuardsAndResolvers: 'always'
  },
  {
    path: 'patients/invite',
    component: PatientAddPageComponent,
    data: {
      expectedRoles: ['GPCUsers', 'Prescriber'],
      failTo: 'settings',
      displayWhileAuthenticating: true,
      enableKey: 'Patients'
    },
    canActivate: [RoleGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'patients/invite/:patientId',
    component: PatientAddPageComponent,
    data: {
      expectedRoles: ['GPCUsers', 'Prescriber'],
      failTo: 'settings',
      displayWhileAuthenticating: true,
      enableKey: 'Patients'
    },
    canActivate: [RoleGuard],
    runGuardsAndResolvers: 'always'
  },
  { path: 'patients/:patientId',
    component: PatientDetailsComponent,
    data: {
            expectedRoles: ['GPCUsers', 'Prescriber'],
            failTo: 'settings',
            displayWhileAuthenticating: true,
            enableKey: 'Patients'
    },
    canActivate: [RoleGuard],
    runGuardsAndResolvers: 'always'
  },
  { path: 'addpatient',
      component: PatientAddPageComponent,
      data: {
          expectedRoles: ['GPCUsers', 'Prescriber'],
          failTo: 'settings',
          displayWhileAuthenticating: true,
          enableKey: 'Patients'
      },
        canActivate: [RoleGuard],
        runGuardsAndResolvers: 'always'
  },
  { path: 'qxlvaauth',
      component: QxlvaAuthComponent,
      data: {
          enableKey: 'QxlvaAuth'
      }
  },
  { path: 'surgeries',
      component: SurgeryListComponent,
      data: {
          expectedRoles: ['GPCUsers', 'Prescriber'],
          failTo: 'settings',
        displayWhileAuthenticating: true,
        enableKey: 'Surgeries'
      },
        canActivate: [RoleGuard],
        runGuardsAndResolvers: 'always'
  },
  { path: 'surgeries/:surgeryId',
      component: SurgeryDetailsComponent,
      data: {
          expectedRoles: ['GPCUsers', 'Prescriber'],
          failTo: 'settings',
          displayWhileAuthenticating: true,
          enableKey: 'Surgeries'
      },
        canActivate: [RoleGuard],
        runGuardsAndResolvers: 'always'
  },
  { path: 'settings',
        component: SettingsComponent,
        data: {
          expectedRoles: ['GPCAccountInfoPending', 'GPCUsers'],
//          failTo: 'settings',
          displayWhileAuthenticating: true,
          enableKey: 'Settings'
        },
        canActivate: [RoleGuard],
        runGuardsAndResolvers: 'always'
  },
  {
    path: 'audit',
    component: AuditComponent,
    data: {
      expectedRoles: ['Audit'],
      failTo: 'settings',
      displayWhileAuthenticating: true,
      enableKey: 'Audit'
    },
    canActivate: [RoleGuard],
    runGuardsAndResolvers: 'always'
  },
  { path: 'calendar',
    component: ViewCalendarComponent,
    data: {
      expectedRoles: ['GPCUsers', 'Prescriber'],
      failTo: 'settings',
      displayWhileAuthenticating: true,
      enableKey: 'Calendar'
    },
    canActivate: [RoleGuard, CalendarGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'Pharmacist/Staff/:calendarToken',
    canActivate: [CalendarWidgetAuthGuardService],
    component: ViewCalendarComponent
  },
  { path: '**', redirectTo: 'messages', pathMatch: 'full' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload', enableTracing: false }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
