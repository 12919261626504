import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { APIService } from '../../api/api.service';
import { CaregiverStatus, MixedPatientModel } from '../../api/ApiRecordTypes/PatientRecord';
import { PatientGroupModel } from '../../api/ApiRecordTypes/PatientGroup';
import { AppConfigService } from '../../app-config.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UtilsService } from '../../utility/utilsservice/UtilsService';
import * as moment from 'moment';
import { debounceTime, switchMap } from 'rxjs/operators';

class FilterConstant {
  public static included = '0';
  public static notIncluded = '1';
}

class SelectOptionConstant {
  public static includeAll = '0';
  public static includeNone = '1';
}

@Component({
  selector: 'app-broadcast-patient-dialog',
  templateUrl: './broadcastpatientdialog.component.html',
})
export class BroadcastPatientDialogComponent implements OnInit, OnDestroy {

  //#region Properties
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public OnComplete: EventEmitter<any> = new EventEmitter<any>(true);

  public patients: MixedPatientModel[] = [];

  public patientGroup: PatientGroupModel;

  public patientSearchForm: UntypedFormGroup;

  public patientNameControl: UntypedFormControl;

  public dobControl: UntypedFormControl;

  public filterControl: UntypedFormControl;

  public filteredPatients: MixedPatientModel[] = [];

  public searchedPatients: MixedPatientModel[] = [];

  public checkedPatientIds: number[] = [];

  private searchPatient$: Subject<void>;

  public includePatientIds: number[] = [];

  public excludePatientIds: number[] = [];

  public patientMatchCriteriaIds: number[] = [];

  public isOptionListShow = false;

  // Subscription watch list.
  private readonly _subscription: Subscription;

  //#endregion

  //#region Dialog methods
  public handleCancel(): void {
    this.OnComplete.emit(null);
  }

  public handleAccept(): void {
    const data = {
      checkedPatientIds: this.checkedPatientIds,
      includePatientIds: this.includePatientIds,
      excludePatientIds: this.excludePatientIds,
    };
    this.OnComplete.emit(data);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public setData(data: any) {
    this.checkedPatientIds = [];
    if (!data) {
      return;
    }
    this.patientGroup = data.selectedPatientGroup;
    this.patients = data.allPatients.map(mixedPatient => mixedPatient.mixedPatientModel)
      .sort((firstPatient, secondPatient) => {
        return firstPatient.lastName.localeCompare(secondPatient.lastName);
      });
    this.filteredPatients = [...this.patients];
    this.searchedPatients = this.filteredPatients;
    if (data.checkedPatientIds && data.checkedPatientIds.length) {
      this.checkedPatientIds = [...data.checkedPatientIds];
    }
    this.patientMatchCriteriaIds = [...data.patientMatchCriteriaIds];
    this.includePatientIds = data.includePatientIds.filter(id => !this.patientMatchCriteriaIds.includes(id));
    this.excludePatientIds = data.excludePatientIds.filter(id => this.patientMatchCriteriaIds.includes(id));
  }

  //#endregion

  //#region Constructor
  constructor(
    protected apiService: APIService,
    private configService: AppConfigService,
  ) {
    this.searchPatient$ = new Subject();
    this._subscription = new Subscription();
    this.buildSearchForm();
  }

  //#endregion

  //#region Methods
  public ngOnInit(): void {
    const searchPatientSubscription = this.searchPatient$.asObservable().pipe(
      debounceTime(500),
      switchMap(() => {
        this.searchPatient();
        return of(null);
      })
    ).subscribe();
    this._subscription.add(searchPatientSubscription);
    this.handleSelectFilter();
  }

  public buildSearchForm(): void {
    this.filterControl = new UntypedFormControl('0');
    this.patientNameControl = new UntypedFormControl('');
    this.dobControl = new UntypedFormControl('');

    this.patientSearchForm = new UntypedFormGroup({
      patientName: this.patientNameControl,
      dob: this.dobControl,
    });
  }

  public handleSelectFilter(): void {
    this.filterPatientByStatus();
    this.searchPatient();
  }

  public searchPatient(): void {
    const nameFilter = this.patientNameControl.value;
    const dobFilter = this.dobControl.value;
    this.filterPatientByStatus();

    if (!nameFilter && !dobFilter) {
      this.searchedPatients = this.filteredPatients;
      return;
    }

    this.searchedPatients = this.filteredPatients.filter(patient => {
      const conditions: boolean[] = [];
      if (nameFilter) {
        const isMatchName = `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(nameFilter.toLowerCase());
        conditions.push(isMatchName);
      }

      if (dobFilter) {
        const isMatchDob = moment(patient.dateOfBirth).format('DD/MM/yyyy').startsWith(dobFilter);
        conditions.push(isMatchDob);
      }

      return conditions.reduce((acc, condition) => acc && condition, true);
    });
  }

  public handleInputNameAndDob(): void {
    this.searchPatient$.next();
  }

  private filterPatientByStatus(): MixedPatientModel[] {
    const filterValue = this.filterControl.value;

    if (filterValue === FilterConstant.included) {
      this.filteredPatients = this.patients.filter(mixedPatient => {
        return this.isPatientSelected(mixedPatient);
      });
      return this.filteredPatients;
    }

    if (filterValue === FilterConstant.notIncluded) {
      this.filteredPatients = this.patients.filter(mixedPatient => {
        return !this.isPatientSelected(mixedPatient);
      });
      return this.filteredPatients;
    }

    this.filteredPatients = [...this.patients];
    return this.filteredPatients;
  }

  public isPatientSelected(patient: MixedPatientModel): boolean {
    if (!this.checkedPatientIds) {
      return false;
    }
    return this.checkedPatientIds.includes(patient.id);
  }

  public isIncludePatient(patient: MixedPatientModel): boolean {
    if (!this.includePatientIds) {
      return false;
    }
    return this.includePatientIds.includes(patient.id);
  }

  public isExcludePatient(patient: MixedPatientModel): boolean {
    if (!this.excludePatientIds) {
      return false;
    }
    return this.excludePatientIds.includes(patient.id);
  }

  private isMatchCriteriaPatient(id: number): boolean {
    if (!this.patientMatchCriteriaIds) {
      return false;
    }
    return this.patientMatchCriteriaIds.includes(id);
  }

  public manualSelectPatient(patient: MixedPatientModel): void {
    if (this.isPatientSelected(patient)) {
      const patientIndex = this.checkedPatientIds.indexOf(patient.id);
      this.checkedPatientIds.splice(patientIndex, 1);
      if (this.isMatchCriteriaPatient(patient.id)) {
        this.excludePatientIds.push(patient.id);
      }
      if (this.includePatientIds.includes(patient.id)) {
        const indexRemoved = this.includePatientIds.indexOf(patient.id);
        this.includePatientIds.splice(indexRemoved, 1);
      }
      return;
    }
    this.checkedPatientIds.push(patient.id);
    if (this.excludePatientIds.includes(patient.id)) {
      const indexAdded = this.excludePatientIds.indexOf(patient.id);
      this.excludePatientIds.splice(indexAdded, 1);
    }
    if (!this.isMatchCriteriaPatient(patient.id)) {
      this.includePatientIds.push(patient.id);
    }
  }

  public handleOpenSelectOptions(): void {
    this.isOptionListShow = !this.isOptionListShow;
  }

  public handleSelectOption(option: string): void {
    this.isOptionListShow = false;
    const searchedPatientIds = this.searchedPatients.map(patient => patient.id);
    if (option === SelectOptionConstant.includeAll) {
      this.checkedPatientIds = Array.from(new Set(this.checkedPatientIds.concat(searchedPatientIds)));
      searchedPatientIds.forEach(id => {
        if (!this.isMatchCriteriaPatient(id) && !this.includePatientIds.includes(id)) {
          this.includePatientIds.push(id);
        }

        if (this.isMatchCriteriaPatient(id) && this.excludePatientIds.includes(id)) {
          const removedIndex = this.excludePatientIds.indexOf(id);
          this.excludePatientIds.splice(removedIndex, 1);
        }
      });
      return;
    }
    if (option === SelectOptionConstant.includeNone) {
      this.checkedPatientIds = this.checkedPatientIds.filter(id => !searchedPatientIds.includes(id));
      searchedPatientIds.forEach(id => {
        if (this.isMatchCriteriaPatient(id) && !this.excludePatientIds.includes(id)) {
          this.excludePatientIds.push(id);
        }
        if (this.includePatientIds.includes(id)) {
          const removedIndex = this.includePatientIds.indexOf(id);
          this.includePatientIds.splice(removedIndex, 1);
        }
      });
    }
  }

  public ngOnDestroy(): void {
    if (this._subscription && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }

  //#endregion

  //#region Display format data
  public displayPhone(patient: MixedPatientModel): string {
    return (patient.phone && UtilsService.formatPhone(patient.phone)) || '';
  }

  public formatGPName(patient: MixedPatientModel): string {
    return patient.practiceName ? patient.practiceName.toString().toLowerCase().replace(/\b\w/g, l => l.toUpperCase()) : '';
  }

  public displayDOB(patient: MixedPatientModel): string {
    return patient.dateOfBirth || '';
  }

  public displayCarerMode(patient: MixedPatientModel): string {
    switch (patient.carerStatus) {
      case CaregiverStatus.IsCaringFor:
        return patient && patient.caringFor === CaregiverStatus.IsCaringFor ? `(Cares for: ${patient.careeName})` : `(Cares for: ${patient.caringFor} patients)`;
      case CaregiverStatus.IsCaredFor:
        return patient && patient.carerName ? `(Carer: ${patient.carerName})` : '';
      default:
        return '';
    }
  }
  //#endregion
}
