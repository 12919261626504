import { Injectable, Inject, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, Subscriber, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IAuthUi } from './authui';
import { IAPIService, OperationResultEnum} from '../api/iapi.service';
import { KJUR, b64utoutf8 } from 'jsrsasign';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from '../app-config.service';
// import { routerNgProbeToken } from '@angular/router';
// import { debug } from 'util';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '../utility/base-dialog/dialog.service';
import { CommsModule } from '../utility/comms/comms.module';
import { DataService } from '../surgeries/practiceData.service';
import {APIService} from '../api/api.service';
import {map} from 'rxjs/operators';
import {Location} from '@angular/common';

import { AuthResultProperties } from '../api/ApiRecordTypes/AuthResult';
export enum AuthState {
  NoAuth,
  Auth,
  NearExpiry,
  Expired
}

export class VerificationData {
  verificationCode: string;
  newPassword: string;

  constructor(code, password) {
    this.verificationCode = code;
    this.newPassword = password;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService  { // implements IHandleAuth
  private accessToken: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private accessData: any;
  uiRef: IAuthUi = null;
  forgotPasswordResolver = null;
  newPasswordResolver = null;
  loginDisplayed = false;
  authCompletionObservers: Subscriber<boolean>[] = [];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private _autDataSource = new Subject<any>();
  authDataSource$ = this._autDataSource.asObservable();

  constructor(
      private api: IAPIService,
      private comms: CommsModule,
      private route: ActivatedRoute,
      private router: Router,
      private config: AppConfigService,
      private injector: Injector,
      private dialogs: DialogService,
      private practiceService: DataService,
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      @Inject('SESSIONSTORAGE') private sessionStorage: any,
      private location: Location,
      private apiService: APIService
  ) {
    const state = this.getAuthState();
    if (state === AuthState.Expired || state === AuthState.NoAuth) {
      sessionStorage.clear();

    } else {
      comms.setAuth(true);
    }

    if (window.addEventListener) {
      window.addEventListener('message', (event) => this.receiveMessage(event), false);
    } else {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      (<any>window).attachEvent('onmessage', (event) => this.receiveMessage(event));
    }
  }


    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  receiveMessage: any = (event: MessageEvent) =>  {
    // console.debug('event', event);

    if (this.accessToken) { // do not replace an existing token
      console.warn('External auth refused - accessToken already exists');
      return;
    }

    if (typeof event.data !== 'string') { // probably webpack
      console.warn('External auth refused - event lacks data object');
      return;
    }

    if (environment.allowedTokenOrigin[0] !== '*' && !environment.allowedTokenOrigin.includes(event.origin)) {
      console.warn('External auth refused - Origin \'' + event.origin + '\' not in allowed list');
      return;
    }

    // console.debug('ready to try the data');
    if (event.data && this.verifyToken(event.data)) {
      // console.debug('Role is', this.getUserGroups(true));
      this.getUserGroups();
      this.setAccount();
    } else {
      console.warn('External auth refused - verifyToken failed');
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  authData(mission: any) {
    this._autDataSource.next(mission);
  }

  loadAuth() {
    if (this.accessToken == null) {
      if (sessionStorage != null) {
        this.accessToken = this.sessionStorage.getItem('accessToken') || this.sessionStorage.getItem('calendarAccessToken');
        this.accessData = JSON.parse(this.sessionStorage.getItem('accessData'));
      }
    }
  }

  saveAuth() {
    if (sessionStorage != null) {
      sessionStorage.setItem('accessToken', this.accessToken);
      sessionStorage.setItem('accessData', JSON.stringify(this.accessData));
    }
  }

  getAuthState(): AuthState {
    let result: AuthState = AuthState.NoAuth;

    const expiry = this.getAuthExpiry();
    if (expiry !== null) {
      result = AuthState.Auth;

      const remaining = expiry - Date.now() / 1000;
      if (remaining < 0) {
        result = AuthState.Expired;
      } else if (remaining < 900) {
        result = AuthState.NearExpiry;
      }
    }
    return result;
  }

  logout() {
    sessionStorage.clear();
    this.loginDisplayed = false;
    this.comms.setAuth(false);
    window.location.reload();
  }

  // Deliberately incomplete pending a refresh token api call.
   refreshTokens(): Promise<void> {
  //   const refresh_token = this.getRefreshToken(); // receive session from calling cognitoUser.getSession()
  //   const username = this.getIdToken().decodePayload()['cognito:username'];
  //   const cognitoUser = this.getCognitoUser(username);
     return new Promise<void>((resolve) => {
  //     cognitoUser.refreshSession(refresh_token, (err, session) => {
         resolve();
  //       if ( !err && session) {
  //         this.authentication = session;
  //       }
  //     });

     });

   }

  waitForAuthentication (): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      switch (this.getAuthState()) {
        case AuthState.Auth:
          observer.next(true);
          observer.complete();
          break;
        case AuthState.NearExpiry:
          observer.next(true);
          observer.complete();
          if (this.sessionStorage.getItem('calendarAccessToken')) {
            this.refreshAuthCalendarToken();
          } else {
            this.refreshTokens();
          }
          break;
        case AuthState.Expired:
          this.requestAuth(observer, 'Your session expired, please login again.');
          break;
        case AuthState.NoAuth:
          // Disable login modal in case set password
          if (this.router.url.includes('/set-password')) { return; }
          if (location.pathname.includes('/Pharmacist/Staff/')) {
            observer.next(true);
            observer.complete();
            return;
          }
          this.requestAuth(observer);
          break;
      }
    });
  }

  loginDialog(observer: Subscriber<boolean> = null, message: string = null) {
    this.dialogs.openLoginDialog(this, message).then((result) => {
      if (result != null) {
        // console.debug('login complete');
        this.comms.setAuth(true);
        this.loginDisplayed = false;
        if (observer !== null) {
          observer.next(true);
          observer.complete();
        }
      } else {
        console.error('dialog closed without login. WTF?');
      }
    });
  }

  /* Private method to trigger the opening of the login dialog, with registration of an observer for completion */
   private requestAuth(observer: Subscriber<boolean>, message = '') {
    // this.authCompletionObservers.push(observer);
    // Use a promise model to isolate the popup opening from the event cycle in angular.
    Promise.resolve(null).then(() => {
      if (!this.loginDisplayed) {
        this.loginDisplayed = true;
        this.loginDialog(observer, message);
      }
    });
  }

//   requestChangePwd() {
//     // Request password-change auth code to be emailed.
//     Promise.resolve(null).then(() => {
// //      this.uiRef = this.uiConnector.displayInterface(this,{context:DisplayContext.change, message:'Please enter your new password'});
//     });
//   }

//   changePwd(username, newPwd, oldPwd) {
//     var currentUser = this.getCurrentUser();
//     const thisService = this;
//     currentUser.changePassword(oldPwd, newPwd, function(err,result){

//       if (err) {
//         if(err.name === "NotAuthorizedException" && err.message === "Incorrect username or password."){
//           thisService.uiRef.setError("IncorrectPassword", err.message);
//           return;
//         }
//         thisService.uiRef.setError(err.name, err.message);
//         return;
//       }
//   //    thisService.uiConnector.closeInterface();
//       return;
//     });
//   }

//   confirmPwdChange(newPassword) {
//     if (this.newPasswordResolver != null) {
//       this.newPasswordResolver(newPassword);
//     } else {
//       this.uiRef.setError('NoNewPasswordRequested', '');
//     }
//   }

  // getIdToken(): string {
  //   this.loadAuth();
  //   return this.idToken;
  //  }

  getAccessToken(): string {
    if (this.accessToken != null) {
      this.loadAuth();
    }
    return this.accessToken;
  }

  // getRefreshToken(): CognitoRefreshToken {
  //   this.loadAuth();
  //   return this.authentication && this.authentication.getRefreshToken();
  // }

  getUserGroups(forceRoleSet= false): string[] {
    if (this.accessData == null) {
      this.loadAuth();
    }
    if (this.accessData == null) {
      return null;
    }
    if ('role' in this.accessData) {
      let roles = this.accessData.role;
      if (!Array.isArray(this.accessData.role)) {
        roles = [this.accessData.role];
      }

      // console.debug('UserGroups:', roles);
      if (roles.length === 0) {
        roles = null;
      }
      if (forceRoleSet || JSON.stringify(this.comms.getRolesAccess().value) !== JSON.stringify(roles)) {
        this.comms.setRoles(roles);
      }
      return roles;
    }

    if ('ods_code' in this.accessData) {
      const roles = environment.hasOdsCodeDefaultRole;
      this.comms.setRoles(roles);
      return roles;
    }

    if (forceRoleSet) { this.comms.setRoles(environment.defaultRoles); }
    return environment.defaultRoles;
  }

  setAccount() {
    this.api.getAccountDetails().subscribe( result => {
      this.authData(result.data);
      sessionStorage.setItem('accountSettings', JSON.stringify(result.data));
    });
  }

  getAuthExpiry() {
    if (this.accessData == null) {
      this.loadAuth();
    }
    const calendarAccessToken = this.sessionStorage.getItem('calendarAccessToken');
    if (calendarAccessToken) {
      const jwtHelp = new JwtHelperService();

      const decodedToken = jwtHelp.decodeToken(calendarAccessToken);
      return decodedToken.exp;
    }
    if (this.accessData == null) {
      return null;
    }
    return this.accessData.exp;
  }


  // checkPageAccess(requiredGroup) {
  //   if (!this.getUserGroup().includes(requiredGroup))
  //   {
  //     this.uiRef.setDisplayContext(DisplayContext.accountPage);
  //   }

  // }

  // closeInterface() {
  // //  this.uiConnector.closeInterface();
  // }

  // validateToken(token) {
  //   const helper = new JwtHelperService();
  //   const isExpired = helper.isTokenExpired(token.getJwtToken());
  //   return isExpired;
  // }

  // recordTokens(result) {
  //   if(result){
  //     this.authentication = result;
  //   }
  //   this.saveAuth();
  // }

  // Dirty hack for angular2 routing recheck
  // private forceRunAuthGuard(): Promise<void> {
  //   if (this.route.root.children.length) {
  //     // gets current route
  //     const curr_route = this.route.root.children[ '0' ];
  //     // gets first guard class
  //     const AuthGuard = curr_route.snapshot.routeConfig.canActivate[ '0' ];
  //     // injects guard
  //     const authGuard = this.injector.get(AuthGuard);
  //     // makes custom RouterStateSnapshot object
  //     const routerStateSnapshot: RouterStateSnapshot = Object.assign({}, curr_route.snapshot, { url: this.router.url });
  //     // runs canActivate
  //     return authGuard.canActivate(curr_route.snapshot, routerStateSnapshot);
  //   }
  //   return new Promise<void>((resolve) => {resolve();});
  // }

  attemptLogin(username: string, password: string): Promise<string> {
    // Check params
    if (!username) {
      return Promise.reject('Please enter an username');
    }

    if (!password) {
      return Promise.reject('Please enter a password');
    }
    const comms = this.comms; // needed to make comms available inside the callback
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const authService = this;
    return new Promise<string>(function(resolve, reject) {
      // perform API login call.
      authService.api.getAuthToken(username, password).subscribe(
        success => {
          const errorMessage = authService.getErrorMessage(success.data.status, success.data.properties);
          if ( errorMessage !== null ) {
            reject(errorMessage);
          }
          if (success.data.token == null) {
            reject('An unexpected error has happened. Please try again later.');
          }
          if (success.result === OperationResultEnum.SUCCESS) {
            // Now validate the results
            if (success.data.token && authService.verifyToken(success.data.token)) {
              const groups = authService.getUserGroups();
              // console.debug('Role is', groups);

              comms.setRoles(groups);
              if (!Object.values(groups).includes('Pharmacy')) {
                reject('Username or password did not match');
              }
              authService.setAccount();
              resolve(null);
            } else {
              reject('An unexpected error has happened. Please try again later.');
            }
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (error: HttpErrorResponse) => {
          // simple logging, but you can do a lot more, see below
          //        alert(`Authentication failed with result ${error.error.result} (${error.error.resultMessage})`);
          reject('An unexpected error has happened. Please try again later.');
        }
      );
    });
  }

  getErrorMessage(errorStatus: string, properties: AuthResultProperties): string {
    console.log('here');
    switch (errorStatus) {
      case 'Success':
        return null;
      case 'InvalidUser':
        return 'Username or password did not match';
      case 'InvalidLastName':
      case 'MultipleUser':
      case 'InvalidFirstName':
      case 'InvalidDateOfBirth':
      case 'InvalidClient':
      case 'InvalidToken':
      case 'InvalidKey':
      case 'Error':
      case 'Expired':
      case 'FailedAuthenticationAttempt':
        return 'An unexpected error has happened. Please try again later.';
      case 'FailedLoginAttempt':
        return this.getTooManyAttemptsErrorMessage(properties);
      default:
        return 'An unexpected error has happened. Please try again later.';
    }
  }

  getTooManyAttemptsErrorMessage(properties: AuthResultProperties): string {
    if (properties && properties.timeRemaining) {
      if (properties.timeRemaining > 60) {
        const timeInMinutes = Math.round(properties.timeRemaining / 60).toString();
        return `You have entered a wrong password too many times. Please try again in ${timeInMinutes} minutes.`;
      } else {
        const timeInSeconds = properties.timeRemaining.toString();
        return `You have entered a wrong password too many times. Please try again in ${timeInSeconds} seconds.`;
      }
    }
    return 'You have entered a wrong password too many times.';
  }

  verifyToken(token: string): boolean {
//    const pubkey = KEYUTIL.getKey('1hcesems81030h44oh25b9u8bm2q0b2oj2u6om4vh4p7542rv4qd');
    const tokenParts = token && token.split('.');
    if (token == null) {
      return false; // This is the correct one
      // return true; // This is just for testing
    }
    const headerObj = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(tokenParts[0]));
    const payloadObj = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(tokenParts[1]));
    // console.debug(headerObj);
    // console.debug(payloadObj);
    if ('nocertcheck' in environment.keys) {
      // console.debug('nocertcheck');
      this.accessToken = token;
      this.accessData = payloadObj;
      this.saveAuth();
      this.comms.setAuth(true);
      return true;
    }
    let keysource = 'fail';
    if ('all' in environment.keys[payloadObj.iss]) {
      keysource = 'all';
    } else {
      keysource = headerObj.kid;
    }

    const pubkey = environment.keys[payloadObj.iss][keysource];

    // console.debug('pubkey', pubkey);

    if (pubkey === 'nodomaincertcheck') {
      // console.debug('nodomaincertcheck');
      this.accessToken = token;
      this.accessData = payloadObj;
      this.saveAuth();
      this.comms.setAuth(true);
      return true;
    }

    // const isValid = true;
    // console.debug('valid authservers:', environment.authservers);
    const isValid = KJUR.jws.JWS.verifyJWT(token, pubkey,
                            {
                              alg: ['ES512', 'ES384', 'ES256', 'PS512', 'PS384', 'PS256', 'RS512', 'RS384', 'RS256'],
                              iss: environment.authservers,
                              gracePeriod: 86400 // 3600
                            }
                          );
    if (!isValid) {
      console.warn('Auth token not valid');
      return false;
    }
    // console.debug('Auth token validated');
    // Then record the results
    this.accessToken = token;
    this.accessData = payloadObj;
    this.saveAuth();
    this.comms.setAuth(true);
    if (this.loginDisplayed) {
      // close the login dialog
    }

    return true;
  }

  forgotPassword(username): string {
    // Check params
    if (!username) {
      return 'UsernameNullOrEmpty';
    }

    // perform API resend-password call.

    // validate call result returning fail string if no success.

    // Store returned tokens.
    return 'success';
  }

  refreshAuthCalendarToken (): Observable<boolean> {
    const calendarToken = this.sessionStorage.getItem('calendarAccessToken');
    return this.apiService.getAuthCalendarTokenAsync(calendarToken)
      .pipe(
        map(value => {
          if (!value.accessToken) {
            return false;
          }
          this.sessionStorage.setItem('calendarAccessToken', value.accessToken);
          this.sessionStorage.setItem('pharmacyId', value.pharmacyId);
          return true;
        })
      );
  }
}
