import { Component, OnInit, ViewEncapsulation, Inject, Output, ContentChild, TemplateRef,
  EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { AppConfigService } from '../../app-config.service';
import { CommsModule, PageDetails } from 'src/app/utility/comms/comms.module';
import { DialogService, DialogConfig, DialogResponse } from 'src/app/utility/base-dialog/dialog.service';
import { PatientAddComponent } from 'src/app/patients/patientadd/patientadd.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NAVIGATION_BAR_SERVICE_PROVIDER, NAVIGATION_BAR_SECTIONS } from 'src/app/navigation/header/headerDirectives/headerConstants';
import { INavigationBarService } from 'src/app/navigation/header/headerDirectives/headerServiceInterface';
import { NavigationBarTitleDirective } from 'src/app/navigation/header/headerDirectives/headerMiddleDirective';
import { Router } from '@angular/router';
import { DataService } from 'src/app/utility/dataservice/data.service';
import { APIService } from 'src/app/api/api.service';
import {Location} from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint insists 'The selector should be kebab-cased and include a dash (https://angular.io/guide/styleguide#style-05-02)'
  // As far as I can see, it complies with this.
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'header[app-header]',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  pageDetails = new PageDetails();
  pageDetails$ = new BehaviorSubject<PageDetails>(this.pageDetails);
  version = '';
  // tslint:disable-next-line: no-inferrable-types
  isAuthenticated = false;
  isAuthenticated$ = new BehaviorSubject<boolean>(this.isAuthenticated);
 // Title to be displayed on child navigation bar.
  @Input() public title = 'OnePlus';

  @Input()
  public shouldShowIconMenu = true;

  @Input()
  public shouldShowLoginIconMenu = true;

  @ContentChild(NavigationBarTitleDirective, {static: true})
  public titleTemplateRef: NavigationBarTitleDirective;
  hasAuthCardDisplay = true;
  hasAuthCardDisplay$ = new BehaviorSubject<boolean>(this.hasAuthCardDisplay);
  authCardIcon = '';
  authCardIcon$ = new BehaviorSubject<string>(this.authCardIcon);
  authCardOwner = 'Card not available';
  authCardOwner$ = new BehaviorSubject<string>(this.authCardOwner);

  // Mapping between section and template
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private readonly _sectionToTemplateMappings: {[section: string]: TemplateRef<any>; };
  // Subscription watch list.
  private _subscription: Subscription;
  @Output() private goBack;
  public readonly clickGoBackEvent: EventEmitter<void>;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public config: any;
  CustomerText = 'Invite patient ';

  constructor(
    private authenticator: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private comms: CommsModule,
    private dialogs: DialogService,
    private router: Router,
    private configService: AppConfigService,
    private dataService: DataService,
    @Inject(NAVIGATION_BAR_SERVICE_PROVIDER) protected navigationBarService: INavigationBarService,
    private api: APIService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any,
    private location: Location
  ) {
    this._sectionToTemplateMappings = {};
    this._subscription = new Subscription();
    this.clickGoBackEvent = new EventEmitter<void>();
  }

  ngOnInit() {
    this.comms.getPageChanges().subscribe(pageDetails => {
      this.pageDetails = pageDetails;
      this.pageDetails$.next(this.pageDetails);
      this.config = this.configService.getConfig();
      if (this.config && this.config.features && this.config.features.ShowCustomerText) {
        this.CustomerText = 'Invite customer';
      }

      if (this.config && this.config.features && this.config.features.ShowVersionInTitle) {
        this.version = '(' + this.config.version + ')';
      }
    });

    this.comms.getAuthChanges().subscribe(status => {
      console.log('Setting Auth status = ' + status + ' (getAuthChanges)');
      this.isAuthenticated = status;
      this.isAuthenticated$.next(this.isAuthenticated);
    });

    const hookTemplateEditedSubscription = this.navigationBarService
    .templateUpdatedEvent
    .subscribe(value => {
      this._sectionToTemplateMappings[value.section] = value.templateRef;
      this.cdr.detectChanges();
    });

    this._subscription.add(hookTemplateEditedSubscription);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.comms.getCardDetailsAccess().subscribe(card => {
      // this.hasAuthCardDisplay = card.cardsUsed;
      // this.hasAuthCardDisplay$.next(this.hasAuthCardDisplay);
      // if (card.cardsUsed) {

      // }
    });
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([uri]);
    });
  }

  faq() {
    this.dialogs.openConfirmationDialog([
      'FAQ',
      'Can you use html markup in dialog lines?',
      'No.  You may not use html tags in dialog lines!',
      'How many lines of text can you get in a dialog?',
      'You may get several lines of text in a Dialog.  It will expand (vertically) to fit them.  They will of course wrap if you make them too long.'
    ]).then((result) => {
      this.dialogs.openInformationDialog([
        (result === DialogResponse.confirm ? 'Good!' : 'To late!')
        // eslint-disable-next-line  @typescript-eslint/no-empty-function
      ]).then(() => { });
    });
  }

  invite() {
    if (this.isAuthenticated) {
      if (this.config.features.InvitePatientPage) {
        this.router.navigateByUrl('/patients/invite');
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        };
      } else {
        const dialogConfig = new DialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '1200px';
        dialogConfig.height = '690px';
        this.dialogs.OpenComponentDialog(PatientAddComponent, { ShowCancel: true }, dialogConfig).then((result) => {
          if (result != null) {
            console.log('New patient code was: ' + result.data.id);
          } else {
            console.log('Closed externally');
          }
        });
      }
    }
  }

  toggleMenu() {
    this.comms.requestSidebarStateToggle();
//    this.dataService.setSaved(false);
  }

    // Called when component is destroyed.
    public ngOnDestroy(): void {
      if (this._subscription && !this._subscription.closed) {
        this._subscription.unsubscribe();
      }
    }

        // Called when go back button is clicked.
    public clickGoBack(): void {
      if (!this.clickGoBackEvent) {
        return;
      }

      this.clickGoBackEvent.emit(void (0));
    }

    getPharmacySettings(account) {
      this.api.getPharmacySettings(account.id).subscribe((result) => {
        this.dataService.setSettingsObs(result);
      });
    }

    // Get left side template.
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public getSectionTemplate(section: NAVIGATION_BAR_SECTIONS): TemplateRef<any> {
      if (this.dataService.setting == null) {
        const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
        if (accountSetting && accountSetting.role === 1) {
          this.getPharmacySettings(accountSetting);
        }
      }

      if (this._sectionToTemplateMappings[section]) {
        return this._sectionToTemplateMappings[section];
      }

      if (section === 'title' && !this.titleTemplateRef) {
        return null;
      }

      return this.titleTemplateRef.templateRef;
    }


  logout() {
    if (this.isAuthenticated) {
      this.authenticator.logout();
    }
  }

  // temporary login function for testing login dialog till we have auth system runing again
  login() {
    if (!this.isAuthenticated) {
      this.authenticator.loginDialog();
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  logObject(obj): any {
    console.log(obj);
    return obj;
  }
  log(obj): string {
    console.log(obj);
    return '';
  }

  help(page: string) {
    this.dialogs.openInformationDialog([
      'Help for page - ' + page
      // eslint-disable-next-line  @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    ]).then((result) => { });
  }

  diableToucheMove(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  logoutCalendarWidget() {
    sessionStorage.clear();
    this.location.go('');
    window.location.reload();
  }
}
