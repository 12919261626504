export class PatientSearchModel {
  pageNumber?: number; // int32
  numberPerPage?: number; // int32
  sortOrder?: string | null;
  patientId?: string | null;
  patientId_typed?: number | null; // int32
  externalSystemCode?: string | null;
  search?: boolean;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  partialFullName?: string | null;
  phone?: string | null;
  email?: string | null;
  excludeDeleted?: boolean;
  includeGroup?: boolean;
  includePharmacy?: boolean;
  dateOfBirth?: string | null; // date-time
  zipCode?: string | null;
  gender?: string | null;
  pharmacyGroupName?: string | null;
  pharmacyId?: number | null; // int32
  patientStatus?: string | null;
  pharmacyName?: string | null;
  patientCode_MedAdvisorGuid?: string | null;
  patientType?: string | null;
  convertTimeZone?: boolean;
  prescriptionCode?: string | null;

  constructor (pharmacyId: number) {
    this.pageNumber = 1;
    this.numberPerPage = 1000;
    this.search = false;
    this.excludeDeleted = true;
    this.includeGroup = false;
    this.includePharmacy = false;
    this.pharmacyId = pharmacyId;
  }
}
