import { FormatAndEnrichData, PagedDataSource, PagedResultProvider } from './pageddatasource.module';
import { QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OperationResult } from 'src/app/api/api.service';
import { FilterControlDirective } from './filtercontrol.directive';
import { SortControlDirective } from './sortcontrol.directive';
import { DataFilter, FilterRequest, FilterResult, FilterSelection, SortSelector, SortItem, SourceControlProvider } from './filtertypes';

export enum SortBehaviours {
    SINGLE,
    MULTI_NEWESTFIRST,
    MULTI_OLDESTFIRST,
}

export class DataSourceControllerConfig {
    sourcename ? = 'default';
    sortBehaviour?: SortBehaviours = SortBehaviours.SINGLE;
    deferLoading ? = false;
}

/**
 * This class is to provide a datasource object that is updated automatically when filters or sort orders change
 * It also maintains the list of filter options for all list type filters (if  supported by the backend),
 * and provides a feed for a list of activities that are available in the datasource data (even if not all rows have been loaded)
 *
 * It depends on two data sources
 *   - controllersource which provides general information on the table,
 *      and allows the creation of dataset definitions (sorted/filtered datasets)
 *   - remoteDataSource which provides pages of data from a remote source according to the id of the dataset definition.
 *
 * It makes no attempt to load any data till in knows it's initial filter set.
 * (future improvments would be being able to update filter sets dynamically)

 * To use this you will generally have a declariation in the form... (this collects all filter items declared in the component)
 * ```
 *    "@ViewChildren(FilterControlDirective) filterItems:QueryList<FilterControlDirective>;"
 * ```
 *
 * and then in ngAfterViewInit (once the directives have been attached)
 *
 * ```
 *    "datacontroller.InitialiseFiltersIndirectly(this.filterItems);"
 * ```
 */
export class DataSourceController<T, TError> {
    datasource: PagedDataSource<T, TError>;
    private controllersource: SourceControlProvider;
    private remotedatasource: PagedResultProvider<T, TError>;
    private definitionId: string;
    private datafilters: DataFilter[];
    private sortControls: SortSelector[] = [];
    private configuration: DataSourceControllerConfig;
    private validationChanges: BehaviorSubject<string>[] = [];
    public multiFieldValidation: (items: DataFilter[]) => string = null;
    public multiFieldValidationErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public validFilters$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public definitionId$: BehaviorSubject<string> = new BehaviorSubject('');
    public externalAuthToken$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
                filterOptionsSource: SourceControlProvider,
                remoteDataSource: PagedResultProvider<T, TError>,
                configuration?: DataSourceControllerConfig,
                dataSource?: PagedDataSource<T, TError>,
                // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
                dataEnricher: FormatAndEnrichData<T, TError> = (_) => {}
                ) {
        this.controllersource = filterOptionsSource;
        this.remotedatasource = remoteDataSource;
        this.configuration = configuration || new DataSourceControllerConfig();
        if (dataSource) {
            this.datasource = dataSource;
        } else {
            this.datasource = new PagedDataSource<T, TError>(
                this.remotedatasource, null, this.configuration.deferLoading, '', dataEnricher);
        }
    }

    /**
     * WARNING: Not Implemented
     *
     * This method will disconnect the data list from its source
     */
    Disconnect() {
        throw new Error('Method not implemented.');
    }

    enableDataLoading() {
        this.configuration.deferLoading = false;
        this.datasource.enableDataLoading();
        this.loadData();
    }
    /**
     * Triggers the loading of filter data (including sorting, filtering, and actions),
     * which then triggers the reload of the general data.
     *
     * Call in ngAfterViewInit() to allow for the filter registration
     */
    private loadData() {
        if (!this.configuration.deferLoading && this.validFilters$.getValue()) {
            // collect filterset values,
            this.isLoading$.next(true);
            const request = new FilterRequest();
            request.filters = this.buildFilterSelection();
            request.sortOrder = this.buildSortSelection();
            request.externalAuthToken = this.externalAuthToken$.value;
            request.uuid = this.definitionId;
            console.log(request);
            // call api
            if (request) {
              this.controllersource(request).subscribe(
                result => this.resolveSourceControlResponse(result),
                err => console.log(err),
                () => this.isLoading$.next(false));
            }
        }
    }

    ClearSorters() {
        this.resetSortControls();
    }

    ReloadData() {
        this.datasource.reset();
    }
    removeData(data) {
      this.datasource.removeItem(data);
    }

    private resolveSourceControlResponse(result: OperationResult<FilterResult>) {
        if (!result || !result.data) {
            return;
        }
        this.definitionId = result.data.uuid;
        this.definitionId$.next(this.definitionId);
        this.datasource.reset(this.definitionId, this.externalAuthToken$.value);
        // push results to filters.
        if (result.data && result.data.filters) {
            result.data.filters.forEach(element => {
                const datafilter: DataFilter = this.datafilters[element.field.toLowerCase()];
                if (datafilter) {
                    datafilter.UpdateFilter(element);
                }
            });
        }
    }

    private buildFilterSelection(): FilterSelection[] {
        const filterSelection: FilterSelection[] = [];

        for (const key of Object.keys(this.datafilters)) {
            const filter = this.datafilters[key];
            if (filter) {
                filterSelection.push({
                    field: filter.GetName(),
                    selection: filter.GetValue(),
                    isList: filter.IsList()
                });
            }
        }

        return filterSelection;
    }

    private buildSortSelection(): SortItem[] {
        const factor = (this.configuration.sortBehaviour === SortBehaviours.MULTI_OLDESTFIRST) ? -1 : 1;
        return this.sortControls
            .filter(a => { const value = a.GetValue(); return value !== undefined && value !== ''; })
            .sort((a, b) => factor * (b.LastChanged() - a.LastChanged()))
            .map(a => ({ field: a.GetName(), sorting: a.GetValue()}) );
    }

    /**
     * This function allows direct importing of a list of filter items.
     * It is mostly useful for testing where the filter items may not want to be FilterControlDirectives
     * It is called internally by InitialiseFiltersDirectly once it's list is narrowed to filters that
     * match the datasource's filterset name.
     */
    InitialiseFiltersDirectly(filterFields: DataFilter[]) {

        this.datafilters = [];
        this.datafilters['flt1'] = null;

        for (const key of Object.keys(filterFields)) {
            const field: DataFilter = filterFields[key];
            const name = field.GetName().toLowerCase();
            this.datafilters[name] = field;
            field.OnChanged().subscribe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
              _ => { this.loadData(); }
            );
            this.validationChanges.push(field.OnValidationError());
            
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            field.OnValidationError().subscribe(_ => this.OnValidationChange());
        }
    }

    OnValidationChange() {
        const firstErrorMessage = this.validationChanges.find(x => x.getValue() !== null);

        if (firstErrorMessage === undefined && this.multiFieldValidation !== null) {
            const errorMessage = this.multiFieldValidation(this.datafilters);
            this.multiFieldValidationErrorMessage$.next(errorMessage);
            this.validFilters$.next(errorMessage === null);
        } else {
            this.validFilters$.next(firstErrorMessage === undefined);
            this.multiFieldValidationErrorMessage$.next(null);
        }
        this.datasource.empty();
    }

    /**
     * This accepts a list of FilterControlDirectives and reduces them to the ones with filterset
     * matching the name of this datasourcecontroller
     * (by default name is 'default' - see filterSet in the constructor)
     * This is to make it easy to bind to the results of @ViewChildren(FilterControlDirective)
     */
    InitialiseFiltersIndirectly(filters: QueryList<FilterControlDirective>) {
        this.InitialiseFiltersDirectly(filters.filter(x => x.GetSet() === this.configuration.sourcename));
    }

    /**
     * This function allows direct importing of a list of sorting controls.
     * It is mostly useful for testing where the sorting items may not want to be SortControlDirectives
     * It is called internally by InitialiseSortersDirectly once it's list is narrowed to sorters that
     * match the datasource's filterset name.
     */
    InitialiseSortersDirectly(sortControls: SortSelector[]) {
        this.sortControls = [];
        sortControls.forEach(control => {
            this.sortControls.push(control);
            control.OnChanged().subscribe(
                sorter => {
                    this.resetSortControls(sorter);
                    this.loadData();
                }
            );
        });
    }

    private resetSortControls(exclude?: SortSelector) {
        if (this.configuration.sortBehaviour === SortBehaviours.SINGLE) {
            this.sortControls.forEach(control => {
                if (control !== exclude) {
                    control.Clear();
                }
            });
        }
    }

    /**
     * This accepts a list of SortControlDirectives and reduces them to the ones with filterset
     * matching the name of this datasourcecontroller
     * (by default name is 'default' - see filterSet in the constructor)
     * This is to make it easy to bind to the results of @ViewChildren(SortControlDirective)
     */
    InitialiseSortersIndirectly(sorters: QueryList<SortControlDirective>) {
        this.InitialiseSortersDirectly(sorters.filter(x => x.GetSet() === this.configuration.sourcename ));
    }
}
