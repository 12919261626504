import {Directive, ElementRef} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {shareReplay, tap} from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line  @angular-eslint/directive-selector
  selector: 'form, [formGroup]'
})
export class FormSubmitDirective {

  //#region properties
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public submitObservable: Observable<any>;

  public get element() {
    return this.host.nativeElement;
  }

  //#endregion

  //#region constructor
  public constructor(private host: ElementRef<HTMLFormElement>) {
    this.submitObservable = fromEvent(this.element, 'submit')
      .pipe(tap(() => {
        if (this.element.classList.contains('submitted') === false) {
          this.element.classList.add('submitted');
        }
      }), shareReplay(1));
  }

  //#endregion

}
