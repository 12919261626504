import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { CommsModule } from '../utility/comms/comms.module';
import { APIService } from '../api/api.service';
import { AppConfigService } from '../app-config.service';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from '../utility/base-dialog/dialog.service';
import { AuthenticationService } from '../authentication/authentication.service';
import * as moment from 'moment';
import { DataService } from '../utility/dataservice/data.service';
import { PharmacyModel } from '../api/ApiRecordTypes/PharmacyRecord';
import { SettingsValidationService } from './settingsvalidation.service';
import { environment } from '../../../src/environments/environment';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit, AfterViewInit {
  settings: PharmacyModel = null;
  validPhone = true;
  validPostCode = true;
  settings$ = new BehaviorSubject<PharmacyModel>(this.settings);
  saved = new PharmacyModel();
  pharmacyId = 1;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accountSettings: any = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accountSettings$ = new BehaviorSubject<any>(this.accountSettings);
  hours: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listOpenHour: any[];
  tempOpenHour: string;
  validateTime = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listHours: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;
  savingInProgress = false;
  isClickSave = false;
  gpApprovedAction = true;
  isClickSave$ = new BehaviorSubject<boolean>(this.isClickSave);
  fieldValidation: SettingsValidationService;
  dispense: string = null;
  webAddress: string = null;
  openingHoursNote: string = null;
  helpfulLink_proteQt: string = null;
  helpfulLink_remoteSupport: string = null;

  customerText = 'Patient';
  customerTextPlural = 'Patients';
  serviceBookingRequest: boolean;
  constructor(
    private comms: CommsModule,
    private _location: Location,
    private api: APIService,
    private dialogs: DialogService,
    private authService: AuthenticationService,
    private configService: AppConfigService,
    public settingService: DataService,

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any
  ) { }

  ngOnInit() {
    this.config = this.configService.getConfig();
    if (this.config && this.config.features && this.config.features.ShowCustomerText) {
      this.customerText = 'Customer';
      this.customerTextPlural = 'Customers';
    }
    this.helpfulLink_proteQt       = environment.proteQtInstallerLink;
    this.helpfulLink_remoteSupport = environment.remoteSupportLink;
    this.fieldValidation = new SettingsValidationService();

    this.comms.setPage({ pageTitle: 'Settings', page: 'settings', pageIcon: '' });

    const hr = ['00', '01', '02', '03', '04', '05', '06', '07',
                '08', '09', '10', '11', '12', '13', '14', '15',
                '16', '17', '18', '19', '20', '21', '22', '23'];
    const mi = ['00', '15', '30', '45'];
    this.listHours.push('Closed');
    
    // eslint-disable-next-line prefer-spread
    this.listHours = this.listHours.concat.apply(this.listHours, hr.map(h => mi.map(m => `${h}:${m}`)));

    this.listOpenHour = [{Code: 'mon', Title: 'Monday', Open: '09:00', Close: '17:00', Disabled: false, Validate: false},
                         {Code: 'tue', Title: 'Tuesday', Open: '09:00', Close: '17:00', Disabled: false, Validate: false},
                         {Code: 'wed', Title: 'Wednesday', Open: '09:00', Close: '17:00', Disabled: false, Validate: false },
                         {Code: 'thur', Title: 'Thursday', Open: '09:00', Close: '17:00', Disabled: false, Validate: false },
                         {Code: 'fri', Title: 'Friday', Open: '09:00', Close: '17:00', Disabled: false, Validate: false },
                         {Code: 'sat', Title: 'Saturday', Open: '09:00', Close: '17:00', Disabled: false, Validate: false },
                         {Code: 'sun', Title: 'Sunday', Open: '09:00', Close: '17:00', Disabled: false, Validate: false }, ];


    if (this.settingService.setting) {
      this.settings = this.settingService.setting;
      this.dispense = this.settings && this.settings.fields && this.settings.fields.dispensesoftware;
      this.webAddress = this.settings && this.settings.fields && this.settings.fields.web;
      this.openingHoursNote = this.settings && this.settings.fields && this.settings.fields.onotes;
      this.getOpenCloseHours(this.settings && this.settings.fields && this.settings.fields.open);
      this.tempOpenHour = this.settings && this.settings.fields && this.settings.fields.open;
      this.hours = _.split(this.settings && this.settings.fields && this.settings.fields.open, '|').join('\n');
      this.saved = JSON.parse(JSON.stringify(this.settings));
      this.settings$.next(this.settings);
    } else {
      this.authService.authDataSource$.subscribe(
        mission => {
          this.accountSettings = mission;
          this.getPharmacySettings(mission);
        }
      );
    }
  }

  getPharmacySettings(account) {
    if (!account) {
      const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
      if (accountSetting && accountSetting.role === 1) {
        this.pharmacyId = accountSetting.id;
      }
      account = accountSetting;
    }

    this.api.getPharmacySettings(account ? account.id : this.pharmacyId).subscribe(
      result => {
        this.settingService.setSettingsObs(result);
        this.settings = result;
        this.dispense = result && result.fields && result.fields.dispensesoftware;    
        this.webAddress = result && result.fields && result.fields.web;
        this.openingHoursNote = result && result.fields && result.fields.onotes;
        this.getOpenCloseHours(result && result.fields.open);
        this.tempOpenHour = result && result.fields.open;
        this.hours = _.split(result && result.fields.open, '|').join('\n');
        this.gpApprovedAction = result && result.fields.gpApprovedAction === '1' ? true : false;
        this.saved = JSON.parse(JSON.stringify(this.settings));
        this.settings$.next(this.settings);
        this.serviceBookingRequest = result && result.fields && result.fields.sbook === '1';
      }
    );
  }

  ngAfterViewInit() {
    this.getPharmacySettings(this.accountSettings);
  }

  PhillipinesPhoneValid(): boolean {
    const pattern = /^(09|\+639)\d{9}$/;
    return pattern.test(this.settings.phone.replace(/\s/g, ''));
  }

  validatePhone() {
    if (this.PhillipinesPhoneValid()) {
      this.validPhone = true;
    } else {
      this.validPhone = false;
    }
  }

  validatePostcode() {
    if (this.settings && this.settings.address && this.settings.address.postCode && this.settings.address.postCode.length !== 4) {
      this.validPostCode = false;
    } else {
      this.validPostCode = true;
    }
  }
  selectOpenDate(selectedValue, code) {
    const index = this.getIndex(code);
    const item = this.listOpenHour[index];

    if (item.Open !== 'Closed' && item.Close === -1) {
      item.Disabled = false;
      item.Close = '17:00';
    }

    item.Open = selectedValue.toString();

    if (item.Open === 'Closed') {
      item.Disabled = true;
      item.Validate = false;
      item.Close = -1;
    } else {
      const openTime = moment(item.Open, 'HH:mm');
      const closeTime = moment(item.Close, 'HH:mm');
      if (openTime > closeTime) {
        item.Validate = true;
      } else {
        item.Validate = false;
      }
    }
  }

  selectCloseDate(selectedValue, code) {
    const index = this.getIndex(code);
    const item = this.listOpenHour[index];
    item.Close = selectedValue.toString();

    const openTime = moment(item.Open, 'HH:mm');
    const closeTime = moment(item.Close, 'HH:mm');
    if (openTime > closeTime) {
      item.Validate = true;
    } else {
      item.Validate = false;
    }
  }

  getIndex(code) {
    return this.listOpenHour.findIndex(x => x.Code === code);
  }

  getItemsHourList(value) {
      return this.listHours.filter((item) => item !== value);
  }

  getOpenCloseHours(data) {
    const openHours = _.split(data, '|');
    openHours.forEach(element => {
      const code = _.split(element, '#')[0];
      const opencloseHours = _.split(element, '#')[1];

      this.listOpenHour.forEach(e => {
        e.Validate = false;
        if (e.Code === code) {
          const openHour = this.nearestFutureMinutes(15, _.split(opencloseHours, '-')[0]);
          const closeHour = this.nearestFutureMinutes(15, _.split(opencloseHours, '-')[1]);

          if (openHour === '00:00' && closeHour === '00:00') {
            e.Open = 'Closed';
            e.Close = -1;
            e.Disabled = true;
          } else {
            e.Disabled = false;
            e.Open = openHour;
            e.Close = closeHour;
          }
        }
      });
    });
  }

  nearestFutureMinutes(interval, Time): string {
    const minute = moment(Time, 'HH:mm');
    const roundedMinutes = Math.ceil(minute.minute() / interval) * interval;

    return minute.clone().minute(roundedMinutes).second(0).format('HH:mm');
  }

  getOpenCloseTime(): string {
    let time = '';
    if (this.listOpenHour) {
      for (let i = 0; i < this.listOpenHour.length; i++) {
        this.validateTime = true;
        if (this.listOpenHour[i].Validate) {
          this.validateTime = false;
          break;
        }
        let openHour = this.listOpenHour[i].Open;
        let closeHour = this.listOpenHour[i].Close;
        if (this.listOpenHour[i].Open === 'Closed') {
          openHour = '00:00';
          closeHour = '00:00';
        }
        time += this.listOpenHour[i].Code + '#' + openHour + '-' + closeHour + '|';
      }
    }
    return time.substring(0, time.length - 1);
  }

  closeBanner() {
    this.settingService.setSaved(false);
  }

  onSave() {
    if (this.fieldValidation.validateAll(this.settings, this.openingHoursNote, this.webAddress)) {
      const openCloseTime = this.getOpenCloseTime();
      const details = {
        id: this.settings && this.settings.id,
        systemCode: this.settings && this.settings.systemCode,
        systemKey: this.settings && this.settings.systemKey,
        name: this.settings && this.settings.name,
        phone: this.settings && this.settings.phone,
        address: this.settings && this.settings.address,
        fields: {
          hoursuntilscriptoverdue: this.settings && this.settings.fields && this.settings.fields.hoursuntilscriptoverdue,
          gpApprovedAction: this.gpApprovedAction ? '1' : '0',
          open: openCloseTime,
          dispensesoftware: this.dispense,
          web: this.webAddress,
          onotes: this.openingHoursNote,
          sbook: this.serviceBookingRequest ? '1' : '0'
        }
      };
      this.savingInProgress = true;
      this.api.setPharmacySettings(details).subscribe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        result => {
          this.dialogs.openInformationDialog(['Successfully updated'], 'OK');
        }
      );
    }
  }
  isContentSaved(): boolean {
    if (this.savingInProgress) {
      return true;
    }
    return this.dataSaved();
  }
  dataSaved(): boolean {
    if (this.settings &&  this.settings.fields &&
        this.settings.fields.hoursuntilscriptoverdue !== this.saved.fields.hoursuntilscriptoverdue ) {
      return false;
    }
    if (this.settings && this.settings.name && this.settings.name !== this.saved.name) {
      return false;
    }
    if (this.settings && this.settings.phone && this.settings.phone !== this.saved.phone) {
      return false;
    }
    if (this.settings && this.settings.address) {
      if (this.settings.address.line1 !== this.saved.address.line1) {
        return false;
      }
      if (this.settings.address.line2 !== this.saved.address.line2) {
        return false;
      }
      if (this.settings.address.suburb !== this.saved.address.suburb) {
        return false;
      }
      if (this.settings.address.city !== this.saved.address.city) {
        return false;
      }
      if (this.settings.address.postCode !== this.saved.address.postCode) {
        return false;
      }
    }

    const openCloseTime = this.getOpenCloseTime();

    if (openCloseTime !== this.tempOpenHour) {
      return false;
    }
    return true;
  }
  onCancel() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve, reject) => {
      this.dialogs.openConfirmationDialog(['Do you want to leave the setting page ?'], 'Leave', 'Cancel').then((response) => {
        if (response === 3) {
          this.discardChanges();
        }
      });
    });
  }

  discardChanges() {
    this.settings.name = this.saved.name;
    this.settings.phone = this.saved.phone;
    this.settings.address.line1 = this.saved.address.line1;
    this.settings.address.line2 = this.saved.address.line2;
    this.settings.address.city = this.saved.address.city;
    this.settings.address.postCode = this.saved.address.postCode;
    this.validPhone = true;
    this.validPostCode = true;
    this.settings$.next(this.settings);
    this.getOpenCloseHours(this.tempOpenHour);
  }
}
