<header class="header">
    <div href="javascript:void(0);" class="sidebar-icon" id="sidebar-icon" (click)="toggleMenu()" *ngIf="shouldShowIconMenu">
        <span class="material-icons material-icons-ex" id="material-icon">menu</span>
    </div>
    <div class="header-top-img">
      <span class="material-icons">
          {{(pageDetails$ | async).pageIcon}}
        </span>
    </div>
    <div class="page-title" id="pageTitle" [style.text-align]="shouldShowIconMenu ? '' : 'center'"><span [innerHtml]="(pageDetails$ | async).pageTitle"></span>&nbsp;{{shouldShowIconMenu ? version : ''}}</div>
    <ng-container >
        <div class="cardauth" title="{{(authCardOwner$ | async)}}">{{(authCardIcon$ | async)}}</div>
    </ng-container>
  <ng-container *ngIf="shouldShowIconMenu">
    <div class="invite" *ngIf="config?.features?.ShowInviteButton"><button [ngClass]="(isAuthenticated$ | async) ? 'btn-soft btn-text btn-invite' : 'btn-soft btn-text btn-invite'" (click)="invite()" i18n>{{CustomerText}}<span class="material-icons">person_add</span></button></div>
    <!-- <div class="faq"><a (click)="faq()" i18n>Frequently Asked Questions</a></div>
    <div class="help"><a (click)="help(pageDetails.page)" i18n>Help</a></div> -->
    <div class="logout" *ngIf="(isAuthenticated$ | async) === false" ><a (click)="login()" i18n>Login</a></div>
    <div class="logout" *ngIf="(isAuthenticated$ | async)" ><a [ngClass]="{'disabled': (isAuthenticated$ | async)  === false}" (click)="logout()" i18n>Logout</a></div>
  </ng-container>
  <ng-container *ngIf="shouldShowLoginIconMenu">
    <div class="logout"><a (click)="logoutCalendarWidget()" i18n>Logout</a></div>
  </ng-container>
</header>

<!--Default title template-->
<ng-template #defaultTitleTemplate>
    <div class="left">
        <img (click) = "redirectTo('messages')" class="mobile-page-title" src="../../../../assets/images/plusoneLogo.svg" />
    </div>

    <div class="right">
        <div class="button-invite-mobile" *ngIf="config?.features?.ShowInviteButton">
            <button class="btn btn-invite btn-invite-mobile" (click)="invite()" id="addNewPatientRecordNavigation">
                INVITE <span class="material-icons icon-color">person_add_alt_1</span>
            </button>
        </div>
        <div href="javascript:void(0);" class="sidebar-icon" id="sidebar-icon" (click)="toggleMenu()">
            <div class="menu-icon">
                <mat-icon id="material-icon">menu</mat-icon>
            </div>
            <div class="menu-icon">
                <span class="text-menu">MENU</span>
            </div>
        </div>
    </div>
</ng-template>
