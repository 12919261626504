<div *ngIf="isLoading | async" class="overlay-wrapper">
  <div class="overlay">
    <mat-spinner
      class="spinner"
      [mode]="mode"
      [value]="value"
      [diameter]="32"
    >
    </mat-spinner>
  </div>
</div>
