import {ComponentType} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from 'src/app/booking-calendar/components/confirm-dialog/confirm-dialog.component';
import {CalendarDialogData} from 'src/app/booking-calendar/models/view-models/layout-calendar-dialog.view-model';
import {DialogConfig, DialogService} from 'src/app/utility/base-dialog/dialog.service';

@Injectable()
export class CalendarDialogService extends DialogService {
  constructor (public dialog: MatDialog) {
    super(dialog);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public openCustomCalendarDialog (component: ComponentType<any>, layout, config: DialogConfig = new DialogConfig()): Promise<any> {
    config.width = '50%';
    config.panelClass = ['settings-dialog'];
    config.autoFocus = false;
    const dialogData = new CalendarDialogData(layout);
    return this.OpenComponentDialog(component, dialogData, config);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public openCalendarCustomDialog (component: ComponentType<any>, settings: any, config = new DialogConfig()): Promise<any> {
    config.width = config.width || '800px';
    config.panelClass = ['settings-dialog'];
    config.autoFocus = false;
    const dialogData = new CalendarDialogData(settings);
    return this.OpenComponentDialog(component, dialogData, config);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public openEditDialog (component: ComponentType<any>, settings: any, config = new DialogConfig()): Promise<any> {
    const dialogData = new CalendarDialogData(settings);
    return this.OpenComponentDialog(component, dialogData, config);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public openConfirmDialog (settings: any, config = new DialogConfig()): Promise<any> {
    config.width = config.width || '400px';
    config.panelClass = config.panelClass.concat(['confirm-dialog']);
    config.autoFocus = false;
    const dialogData = new CalendarDialogData(settings);
    return this.OpenComponentDialog(ConfirmDialogComponent, dialogData, config);
  }
}
