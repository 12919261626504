import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BasicDialogComponent} from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import {CalendarEventService} from 'src/app/booking-calendar/services/calendar-event.service';
import * as moment from 'moment';
import {CalendarDialogData} from '../../models/view-models/layout-calendar-dialog.view-model';
import {EventBookingExceptionModel, EventRecurrenceModel, EventResourceModel} from '../../../api/ApiRecordTypes/EventResourceModel';
import {RecurrenceType} from '../../constant/service.enum';
import {MAT_DATE_FORMATS} from '@angular/material/core';

class Item {
  constructor(
    public value: number,
    public text: string,
    public isSelected?: boolean) {
  }
}

class RecurrencePatternItem extends Item {
  public recurEvery: number;
  constructor(value, text, recurEvery) {
    super(value, text, null);
    this.recurEvery = recurEvery;
  }
}

@Component({
  selector: 'app-add-resource-time',
  templateUrl: './add-resource-time-dialog.component.html',
  styleUrls: ['./add-resource-time-dialog.component.css'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    }
  ]
})
export class AddResourceTimeDialogComponent extends BasicDialogComponent implements OnDestroy, AfterViewInit {

  //#region Properties

  @ViewChild('dialogTitleAddUnavailable', {static: true})
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  protected dialogTitleAddUnavailable: TemplateRef<any>;

  @ViewChild('dialogTitleAddAvailable', {static: true})
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  protected dialogTitleAddAvailable: TemplateRef<any>;

  public isAddTime = true;

  public dateOfMonth: Array<Item> = [];

  public recurrenceType: RecurrenceType = 0;

  public isRecurringChecked = false;

  public startTime: Date | string = moment().startOf('day').toDate();

  public endTime: Date | string = moment().startOf('day').toDate();

  public timeList = [
    new RecurrencePatternItem(0, 'Daily', 1),
    new RecurrencePatternItem(1, 'Weekly', 1),
    new RecurrencePatternItem(2, 'Monthly', 1),
    new RecurrencePatternItem(3, 'Yearly', 1)
  ];

  public dayList = [
    new Item(1, 'Monday', false),
    new Item(2, 'Tuesday', false),
    new Item(3, 'Wednesday', false),
    new Item(4, 'Thursday', false),
    new Item(5, 'Friday', false),
    new Item(6, 'Saturday', false),
    new Item(0, 'Sunday', false)
  ];

  public monthList = [
    new Item(1, 'January', false),
    new Item(2, 'February', false),
    new Item(3, 'March', false),
    new Item(4, 'April', false),
    new Item(5, 'May', false),
    new Item(6, 'June', false),
    new Item(7, 'July', false),
    new Item(8, 'August', false),
    new Item(9, 'September', false),
    new Item(10, 'October', false),
    new Item(11, 'November', false),
    new Item(12, 'December', false),
  ];

  public recurEveryUnits = {
    0: 'day(s)',
    1: 'week(s)',
    2: 'month(s)',
    3: 'year(s)',
  };

  public resource: EventResourceModel;

  public selectedTime: EventRecurrenceModel | EventBookingExceptionModel;

  public atLeastOneDayError = false;

  public atLeastOneDateError = false;

  public atLeastOneMonthError = false;

  private selectedAvailableTime: { timeDisplay: string, item: EventRecurrenceModel | EventBookingExceptionModel, index: number };

  private availableTimes: { timeDisplay: string, item: EventRecurrenceModel | EventBookingExceptionModel, index: number }[] = [];

  //#endregion

  //#region Form

  public startTimeControl: UntypedFormControl;

  public endTimeControl: UntypedFormControl;

  public timeForm: UntypedFormGroup;

  public startDateControl: UntypedFormControl;

  public endDateControl: UntypedFormControl;

  public dateForm: UntypedFormGroup;

  //#endregion

  //#region Accessors

  //#endregion

  //#region Constructor
  public constructor(
    protected router: Router,
    protected calendarService: CalendarEventService,
    protected toastr: ToastrService,
    protected cdr: ChangeDetectorRef
  ) {
    super(router);
    this.acceptButtonTitle = 'Save';
    this.cancelButtonTitle = 'Cancel';
    // Init date of month
    for (let i = 1; i <= 31; i++) {
      this.dateOfMonth.push(new Item(i, this.toFormattedDaysOfMonth(i), false));
    }

    this.startTimeControl = new UntypedFormControl(null, [Validators.required]);
    this.endTimeControl = new UntypedFormControl(null, [Validators.required]);
    this.timeForm = new UntypedFormGroup({
      startTime: this.startTimeControl,
      endTime: this.endTimeControl
    }, {validators: [this.endTimeGreaterThanStartTimeValidator]});
    this.startDateControl = new UntypedFormControl('', [Validators.required]);
    this.endDateControl = new UntypedFormControl('');
    this.dateForm = new UntypedFormGroup({
      startDate: this.startDateControl,
      endDate: this.endDateControl
    }, {validators: this.endDateGreaterThanStartDateValidator});
  }

  //#endregion

  //#region Life cycle

  public ngAfterViewInit(): void {
    this.startTimeControl.markAsPristine();
    this.endTimeControl.markAsPristine();
    this.cdr.detectChanges();
  }
  //#endregion

  //#region Methods

  public handleAccept() {
    this.startTimeControl.markAsDirty();
    this.endTimeControl.markAsDirty();
    this.startDateControl.markAllAsTouched();
    this.endDateControl.markAllAsTouched();
    this.timeForm.updateValueAndValidity();
    this.startDateControl.updateValueAndValidity();
    this.endDateControl.updateValueAndValidity();
    const allowToSave = this.saveTimeChange();
    if (!allowToSave) {
      return;
    }
    this.calendarService.setToast('Time Updated');
    super.handleAccept();
  }

  public setData(data: CalendarDialogData) {
    super.setData(data);

    if (!data) {
      return;
    }

    this.resource = data.settings.resource;
    this.availableTimes = data.settings.availableTimes;
    this.selectedAvailableTime = data.settings.selectedAvailableTime;
    this.selectedTime = this.selectedAvailableTime && this.selectedAvailableTime.item;
    this.isAddTime = data.settings.isAddTime;
    if (this.isAddTime) {
      this.headerTitle = !this.resource.availabilityType ? this.dialogTitleAddUnavailable : this.dialogTitleAddAvailable;
      return;
    }

    this.headerTitle = 'Edit time';

    if (this.selectedTime['recurrenceType'] != null) {
      const recurrenceModel = this.selectedTime as EventRecurrenceModel;
      this.isRecurringChecked = true;
      this.startDateControl.patchValue(moment(recurrenceModel.startDate));
      if (recurrenceModel.recurrenceEndType === 2) {
        this.endDateControl.patchValue(moment(recurrenceModel.endDate));
      }
      const startTimeArray = recurrenceModel.startTime.split(':');
      const endTimeArray = recurrenceModel.endTime.split(':');
      const startTime = moment(recurrenceModel.startDate).hour(+startTimeArray[0]).minutes(+startTimeArray[1]);
      const endTime = moment(recurrenceModel.endDate).hour(+endTimeArray[0]).minutes(+endTimeArray[1]);
      this.startTime = moment(startTime).format('LLLL');
      this.endTime = moment(endTime).format('LLLL');
      this.recurrenceType = recurrenceModel.recurrenceType;
      this.timeList[this.recurrenceType].recurEvery = recurrenceModel.recurEvery;
      switch (this.recurrenceType) {
        case 1:
          recurrenceModel.weekdays.forEach(day => {
            this.dayList.find(d => d.value === day).isSelected = true;
          });
          break;
        case 2:
          recurrenceModel.dates.forEach(date => {
            this.dateOfMonth.find(d => d.value === date).isSelected = true;
          });
          break;
        case 3:
          recurrenceModel.months.forEach(month => {
            this.monthList.find(m => m.value === month).isSelected = true;
          });
          break;
        default:
          break;
      }
      return;
    }

    const singleModel = this.selectedTime as EventBookingExceptionModel;
    this.startDateControl.patchValue(moment(singleModel.startDate));
    this.startTime = moment(singleModel.startDate).format('LLLL');
    this.endTime = moment(singleModel.endDate).format('LLLL');
  }

  public handleRecurrenceTypeChange(): void {
    this.atLeastOneDayError = false;
    this.atLeastOneDateError = false;
    this.atLeastOneMonthError = false;
  }

  public handleRecurEvery(item: RecurrencePatternItem): void {
    if (item.recurEvery > 100) {
      item.recurEvery = 100;
      return;
    }
    if (item.recurEvery < 1) {
      item.recurEvery = 1;
    }
  }

  protected saveTimeChange(): boolean {
    if (!this.startTime || !this.endTime || !this.startDateControl.value) {
      return;
    }
    if (this.timeForm.hasError('mustGreaterThanStartTime') || this.dateForm.hasError('mustGreaterThanEndDate')) {
      return;
    }
    if (this.isAddTime) {
      if (!this.isRecurringChecked) {
        const newSingleTime = this.generateSingleTime();
        this.resource.availibilityExceptions.push(newSingleTime);
        this.availableTimes.push({timeDisplay: '', index: this.resource.availibilityExceptions.length - 1, item: newSingleTime});
        return true;
      }

      const newRecurrenceTime = this.generateRecurrenceTime();
      if (!newRecurrenceTime) {
        return;
      }
      newRecurrenceTime.isNew = true;
      this.resource.recurrencePattern.push(newRecurrenceTime);
      this.availableTimes.push({timeDisplay: '', index: this.resource.availibilityExceptions.length - 1, item: newRecurrenceTime});
      return true;
    }

    if (!this.selectedAvailableTime || !this.selectedTime) {
      return;
    }

    if (this.isRecurringChecked) {
      if (this.selectedTime['recurrenceType'] != null) {
        const updatedRecurrenceTime = this.generateRecurrenceTime(this.selectedTime as EventRecurrenceModel);
        if (!updatedRecurrenceTime) {
          return;
        }
        return true;
      }

      const newRecurrenceTime = this.generateRecurrenceTime();
      if (!newRecurrenceTime) {
        return;
      }
      newRecurrenceTime.isNew = true;
      this.resource.availibilityExceptions.splice(this.selectedAvailableTime.index, 1);
      this.resource.recurrencePattern.push(newRecurrenceTime);
      this.selectedAvailableTime.item = newRecurrenceTime;
      return true;
    }

    if (this.selectedTime['recurrenceType'] != null) {
      const newSingleTime = this.generateSingleTime();
      this.resource.recurrencePattern.splice(this.selectedAvailableTime.index, 1);
      this.resource.availibilityExceptions.push(newSingleTime);
      this.selectedAvailableTime.item = newSingleTime;
      return true;
    }
    this.generateSingleTime(this.selectedTime as EventBookingExceptionModel);
    return true;
  }

  protected generateSingleTime(newSingleTime: EventBookingExceptionModel = new EventBookingExceptionModel()): EventBookingExceptionModel {
    const startTime = moment(this.startTime);
    const endTime = moment(this.endTime);
    newSingleTime.startDate = moment(this.startDateControl.value).hours(startTime.hours()).minutes(startTime.minutes()).format('YYYY-MM-DDTHH:mm:ss');
    newSingleTime.endDate = moment(this.startDateControl.value).hours(endTime.hours()).minutes(endTime.minutes()).format('YYYY-MM-DDTHH:mm:ss');
    return newSingleTime;
  }

  protected generateRecurrenceTime(newRecurrenceTime: EventRecurrenceModel = new EventRecurrenceModel()): EventRecurrenceModel {
    switch (Number(this.recurrenceType)) {
      case RecurrenceType.weekly:
        // eslint-disable-next-line no-case-declarations
        const weekdays = this.dayList.filter(d => d.isSelected).map(d => d.value);
        if (!weekdays.length) {
          this.atLeastOneDayError = true;
          return null;
        }
        newRecurrenceTime.dates = [];
        newRecurrenceTime.months = [];
        newRecurrenceTime.weekdays = weekdays;
        break;
      case RecurrenceType.monthly:
        // eslint-disable-next-line no-case-declarations
        const dates = this.dateOfMonth.filter(d => d.isSelected).map(d => d.value);
        if (!dates.length) {
          this.atLeastOneDateError = true;
          return null;
        }
        newRecurrenceTime.weekdays = [];
        newRecurrenceTime.months = [];
        newRecurrenceTime.dates = dates;
        break;
      case RecurrenceType.yearly:
        // eslint-disable-next-line no-case-declarations
        const months = this.monthList.filter(m => m.isSelected).map(m => m.value);
        if (!months.length) {
          this.atLeastOneMonthError = true;
          return null;
        }
        newRecurrenceTime.dates = [];
        newRecurrenceTime.weekdays = [];
        newRecurrenceTime.months = months;
        break;
      default:
        newRecurrenceTime.dates = [];
        newRecurrenceTime.weekdays = [];
        newRecurrenceTime.months = [];
        break;
    }
    newRecurrenceTime.recurrenceType = Number(this.recurrenceType);
    newRecurrenceTime.dayOfMonthInYearlyRecurrence = 0;
    if (newRecurrenceTime.recurrenceType === RecurrenceType.yearly) {
      newRecurrenceTime.dayOfMonthInYearlyRecurrence = moment(this.startDateControl.value).date();
    }
    newRecurrenceTime.startTime = moment(this.startTime).format('HH:mm:ss');
    newRecurrenceTime.endTime = moment(this.endTime).format('HH:mm:ss');
    newRecurrenceTime.startDate = moment(this.startDateControl.value).format('YYYY-MM-DDTHH:mm:ss');
    newRecurrenceTime.endDate = moment('9999-12-31').format('YYYY-MM-DDTHH:mm:ss');
    newRecurrenceTime.useEndTime = true;
    newRecurrenceTime.recurEvery = this.timeList[this.recurrenceType].recurEvery;
    if (this.endDateControl.value) {
      newRecurrenceTime.recurrenceEndType = 2;
      newRecurrenceTime.endDate = moment(this.endDateControl.value).format('YYYY-MM-DDThh:mm:ss');
    }
    newRecurrenceTime.endAfterOccurences = 0;
    return newRecurrenceTime;
  }

  public handleDateInput(event: KeyboardEvent): boolean {
    const key = event.key;
    if (event.key.length > 1 || key === '/') {
      return true;
    }
    const numberOnlyRegex = /^[0-9]*$/;
    if (numberOnlyRegex.test(key)) {
      return true;
    }
    return false;
  }

  public handleTimeInput(event: KeyboardEvent): boolean {
    if (event.key === '/') {
      return false;
    }
    return this.handleDateInput(event);
  }

  public handlePasteDate(event: ClipboardEvent): boolean {
    const pastedText = event.clipboardData.getData('text');
    const numberOnlyRegex = /^[/0-9]*$/;
    if (numberOnlyRegex.test(pastedText)) {
      return true;
    }
    return false;
  }

  public handleTimePaste(event: ClipboardEvent): boolean {
    const pastedText = event.clipboardData.getData('text');
    const numberOnlyRegex = /^[0-9]*$/;
    if (numberOnlyRegex.test(pastedText)) {
      return true;
    }
    return false;
  }

  protected endTimeGreaterThanStartTimeValidator = (form: UntypedFormGroup): ValidationErrors | null =>  {
    const controls = form.controls;
    if (!controls || !controls['startTime'] || !controls['endTime']) {
      return null;
    }

    const startTime = controls['startTime'].value as Date;
    const endTime = controls['endTime'].value as Date;

    if (!startTime || !endTime) {
      return null;
    }

    if (startTime.getHours() < endTime.getHours()) {
      return null;
    }
    if (startTime.getHours() === endTime.getHours() && startTime.getMinutes() < endTime.getMinutes()) {
      return null;
    }
    return {mustGreaterThanStartTime: true};
  }

  protected endDateGreaterThanStartDateValidator = (form: UntypedFormGroup): ValidationErrors | null =>  {
    const controls = form.controls;
    if (!controls || !controls['startDate'] || !controls['endDate']) {
      return null;
    }

    const startDate = controls['startDate'].value;
    const endDate = controls['endDate'].value;

    if (!startDate || !endDate) {
      return null;
    }

    if (moment(endDate).diff(moment(startDate), 'days') >= 0) {
      return null;
    }

    return {mustGreaterThanEndDate: true};
  }

  protected toFormattedDaysOfMonth(day: number): string {
    if (day === 1 || day === 21 || day === 31) {
      return (day + 'st');
    }
    if (day === 2 || day === 22) {
      return (day + 'nd');
    }
    if (day === 3 || day === 23) {
      return (day + 'rd');
    }
    return (day + 'th');
  }

  //#endregion
}
