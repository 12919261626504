<div
  class="pageContent patient-add-component"
  *ngIf="
    ((customizedSiteConfig === 'DayLewis' &&
      screenMode !== 'InviteCarer' &&
      displayScreen !== 'inviteSucess') ||
      displayScreen !== 'inviteSucess')
  "
>
  <div [hidden]="true">{{ carerPatient$ | async }}</div>
  <!-- <div class="patient-add-heading"> -->
  <div>
    <!-- comment out header to no longer reference SCR see BREN-2029 
    <p *ngIf="customizedSiteConfig === 'NPA'" style="margin-left: 20px;">
      You must have a valid <b>Smartcard</b> inserted into your Smartcard reader
      to retrieve a Summary Care Record and have either <b>patient</b> or <b>carer permission</b> to do so. 
      <a *ngIf="screenMode$.value ==='InviteCarer' && config.features.InvitePatient.ShowConsentForm" target="_blank" href="{{ env.carerModeConsentFormLink }}">Download consent form (PDF)</a>
    </p>
    
    <div class="button-invite" *ngIf="customizedSiteConfig === 'NPA' && screenMode$.value ==='InvitePatient'">
      <button class="btn secondary-button btn-invite" (click)="reloadPage()">
        <span class="material-icons icon-color">person_add_alt_1</span> INVITE NEW PATIENT
      </button>
    </div>
    -->
    <div
      class="carer-mode-instructions"
      *ngIf="screenMode$.value === 'InviteCarer'"
    >
      <div class="instructions-header">
        <mat-icon
          style="font-size: 36px; font-weight: 400; width: 36px; height: 36px"
          >error_outline</mat-icon
        >
        <p class="instruction-header-text" style="margin-left: 15px">
          Before we get started...you are about to search for a patient that
          <span class="first-name">{{ carerPatient.firstName }}</span
          ><span>&nbsp;</span>
          <span class="last-name">{{ carerPatient.lastName }}</span> will be
          caring for <br />
          <a target="_blank" href="{{ env.carerModeConsentFormLink }}"
            >Download consent form (PDF)</a
          >
        </p>
      </div>
    </div>
  </div>

  <div
    [class]="
      (isVerifiedSCRConsent$ | async) ||
      MedicationList?.drugEntries?.length > 0 ||
      searchingStateSuccess
        ? 'view-wrapper'
        : 'view-wrapper verifedconsent'
    "
  >
    <p
      *ngIf="
        customizedSiteConfig === 'DayLewis' && screenMode === 'InvitePatient'
      "
      style="margin-block-end: 0.5em"
    >
      <b>Search for patient on the NHS patient demographic service:</b>
    </p>

    <h2
      class="search-pds-title"
      *ngIf="
        customizedSiteConfig === 'NPA' && screenMode$.value === 'InvitePatient'
      "
    >
      1 Search the Patient Demographic Service (PDS) for the patient you want to
      invite
    </h2>
    <h2 class="search-pds-title" *ngIf="screenMode$.value === 'InviteCarer'">
      {{ customizedSiteConfig === "NPA" ? "1/3" : "1/2" }} Search for the
      patient, <span class="first-name">{{ carerPatient.firstName }}</span
      ><span>&nbsp;</span>
      <span class="last-name">{{ carerPatient.lastName }}</span> will be set up
      as their carer
    </h2>
    <app-patientexternalsearch
      (barcodeChanged)="barcodeChanged($event)"
      [prescriptionCode]="prescriptionCode"
      (scriptSearchEvent)="onScriptSearch()"
      (toggleNHSPatientNameEvent)="toggleNHSPatientNameEvent()"
      [toggleNHSPatientName]="toggleNHSPatientName"
      (NHSchanged)="NHSchanged($event)"
      (sourceChangeOutput)="onSourceChanged($event)"
      (DOBChanged)="DOBChanged($event)"
      (firstNameChanged)="firstNameChanged($event)"
      (surNameChanged)="surNameChanged($event)"
      (sexChanged)="sexChanged($event)"
      (postCodeChanged)="postCodeChanged($event)"
      [DateOfBirth]="DateOfBirth"
      [patientFirstName]="patientFirstName"
      [patientSurName]="patientSurName"
      [postCode]="postCode"
      [gender]="gender"
      [messageErr$]="messageErr$"
      [displayScreen]="displayScreen"
      [NHSNumber]="NHSNumber"
      (screenValueChanged)="screenValueChanged($event)"
      (searchForPatient)="onSearchForPatient($event)"
      (searchingInProgress)="onSearchingInProgress($event)"
      (phoneStatusOutput)="onPhoneStatusChanged($event)"
      (validPhoneOutput)="onValidPhoneOutput($event)"
      (resetIsHtmlNotNullAndDrugNull)="onChangeIsHtmlNotNullAndDrugNull($event)"
    >
    </app-patientexternalsearch>
    <app-patientexternaldetail
      [screenMode]="screenMode"
      [carerPatient]="carerPatient"
      (validPhoneOutput)="onValidPhoneOutput($event)"
      (invitePatient)="onInvitePatient($event)"
      [isVerifiedSCRConsent$]="isVerifiedSCRConsent$"
      [errorMessage$]="errorMessage$"
      [validPhone]="validPhone"
      (phoneStatusChanged)="onPhoneStatusChanged($event)"
      (phoneChanged)="onPhoneChanged($event)"
      [MobileNumber]="MobileNumber"
      [DateOfBirth]="DateOfBirth"
      [patientSearch]="patientSearch"
      [displayScreen]="displayScreen"
      [isInviteEnabled]="isInviteEnabled"
    >
    </app-patientexternaldetail>
    <app-patientdrugupdate
      *ngIf="
        searchingStateSuccess &&
        config.features.InvitePatient.ShowLoadMedicationSection
      "
      [MedicationList$]="MedicationList$"
      [DisplayMedicationList$]="DisplayMedicationList$"
      [patientInformation$]="patientInformation$"
      [NewPatient]="true"
      [isScrAvailable$]="isScrAvailable$"
      (patientDrugRecordRetreivalRequested)="
        onPatientDrugRecordRetreivalRequested($event)
      "
      (updateMedicationModel)="onUpdateMedicationModel($event)"
      [duplicatedrug]="duplicatedrug"
      [DateOfBirth]="DateOfBirth"
      [NhsNumber]="NHSNumber"
      [ScrErrorState$]="ScrErrorState$"
      [errorMessageNoDrug]="scrPageState.errorMessageNoDrug"
      [editDaysSupply]="config.features.EditDaysSupplyInAddPatient"
    >
    </app-patientdrugupdate>
    <app-patientinviteconfirmation
      [screenMode]="screenMode"
      [carerPatient]="carerPatient"
      [validPhone]="validPhone"
      [errorMessage$]="errorMessage$"
      (validPhoneOutput)="onValidPhoneOutput($event)"
      (invitePatient)="onInvitePatient($event)"
      [validPhone]="validPhone"
      [patientSearch]="patientSearch"
      [isVerifiedSCRConsent$]="isVerifiedSCRConsent$"
      [isLoading]="isLoading"
      [MobileNumber]="MobileNumber"
      [displayScreen]="displayScreen"
      [MedicationList]="DisplayMedicationList$ | async"
    >
    </app-patientinviteconfirmation>
    <div *ngIf="searchingInProgress" class="invite-page-patient-add">
      <div class="loading-page">
        <div>
          <div class="loading-text">
            Searching the {{ searchingState }} patient demographic service
          </div>
          <div class="fa-3x">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>


<app-patient-invite-success
  [screenMode]="screenMode"
  [carerPatient]="carerPatient"
  [patientGUID]="patientPageState.patientCode_MedAdvisorGuid"
  *ngIf="displayScreen === 'inviteSucess'"
  [patientLastName]="patientSearch?.identityPrivate?.lastName"
  [patientFirstName]="patientSearch?.identityPrivate?.firstName"
  [code]="code"
></app-patient-invite-success>
