<div class="drug-dialog-title">{{title}}
  <div class="close-dialog"(click)="closeDialog()">
    <span class="material-icons close-dialog-icon">close</span>
  </div>
</div>
<div class="drug-dialog-content" *ngIf="screenState === 'Search'">
  <div class="drug-row">
    <div><strong>Select a {{data.selectedDrug.activeName}} medication below</strong><span class="or-text">Or</span><button mat-raised-button style="background-color: #052F57; color: #fff; margin-left: 30px;" (click) = "openSearchAll()">SEARCH ALL MEDS</button></div>
  </div>
</div>

<div class="drug-dialog-content" *ngIf="screenState === 'Edit'">
  <div class="drug-edit-dialog-wrapper">
      <p class="title-1"><strong>Medication</strong></p>
      <p class="field-1"><span [ngClass]="{'no-brand': !selectedMedication.labelName}">{{selectedMedication.labelName ? selectedMedication.labelName : 'No brand'}}</span> {{selectedMedication.activeName}} {{selectedMedication.strength}} {{selectedMedication.form}}</p> 
      <p class="title-2"><strong>Ordering cycle</strong></p>
      <div class="field-2">
          <div *ngIf="repeatCycleFrequency === 'days'" class="drug-dialog-days-treatment">
            <input class="days-treatment-input drug-dialog-days-treatment-input" type="number" (keypress)="limitLength($event)" maxlength="4" max="9999" min="0" [(ngModel)]="repeatCycle">
          </div>
          <select class="field-2-select" [(ngModel)]="repeatCycleFrequency" value="days">
            <option value="as required">As required</option>
            <option value="days">Days</option>
          </select>
      </div>
      <p class="title-3"><strong>Supply left</strong><span>&nbsp;(Optional)</span></p>
      <div><input class="field-3 days-treatment-input drug-dialog-days-treatment-input" type="number" (keypress)="limitLength($event)" maxlength="4" max="9999" min="0" [(ngModel)]="supply"><span class="supply-days-left-label">(Days)</span></div>
      <p class="title-4"><strong>eRD/batch</strong></p>
      <div class="field-4">
        <mat-checkbox color="primary" [(ngModel)]="erd"></mat-checkbox>&nbsp;
        <div *ngIf="erd" class="drug-dialog-days-treatment">
          <input class="days-treatment-input drug-dialog-days-treatment-input" type="number" (keypress)="limitLength($event)" maxlength="4" max="9999" min="0" style="width: 50px;" [(ngModel)]="rptsLeft">
          <p style="margin-right: 10px;"><strong>&nbsp;Rpts left</strong></p>
        </div>
      </div>
  </div>
  <div style="display: flex; justify-content: flex-end;">
    <button mat-raised-button style="background-color: #052F57; color: #fff; margin-right: 20px;" (click)="onUpdateMedication()">{{ data.mode === 'Edit' ? 'UPDATE MEDICATION' : 'ADD MEDICATION' }}</button>
    <button mat-stroked-button style="color: #052F57;" (click)="backToSearch()">BACK TO SEARCH</button>
  </div>
</div>
<div class="drug-dialog-content" *ngIf="screenState === 'SearchAll' || screenState === 'Add'">
  <div *ngIf="matchingErr" class="error-message font-size-11">
    The selected medication is duplicated with another medication in your list. Please choose another medication or close the pop-up to edit that medication instead.
  </div>
  <div class="drug-seach-panel">
    <div>
      <div class="first-row"><strong>Enter medication</strong></div>
      <div class="search-all-content">
        <div>
          <input class="search-all-text" type="text" (blur)="validateSearchAll()" [(ngModel)]="searchAll">
          <div class="error-message font-size-11" style="position: absolute;" *ngIf="!isValidSearchText">Please enter at least three letters of the medication name.</div>
        </div>
        <button mat-raised-button style="background-color: #052F57; color: #fff; margin-right: 20px; margin-left: 20px;" (click) = "doSearchAll()">SEARCH</button>
        <button mat-stroked-button style="color: #052F57;"  (click) = "clear()">CLEAR</button>
      </div>
    </div>
  </div>
</div>

<div class="drug-dialog-content" *ngIf = "dataSource && (screenState === 'Search' || screenState === 'SearchAll' || screenState === 'Add') ">
  <div class="drug-content">
      <cdk-virtual-scroll-viewport *ngIf="!dataSource?.isEmpty()" [itemSize]="37" style="height: 400px; overflow-x: hidden;">
        <div class="drug-list" *cdkVirtualFor="let item of dataSource" (click)="openUpdateContent(item)" (scroll)="handleScroll()">
          <div [ngClass]="{'cell': true, 'left': true, 'no-brand': item.labelName === ''}">{{item?.labelName === '' ? 'No brand' : item?.labelName}}</div>
          <div class="cell middle">{{item?.activeName}}</div>
          <div class="cell right">{{item?.form}}/{{item?.strength}}</div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
    <ng-container *ngIf="dataSource?.isLoading$.value">
      <app-load-spinner [diameter]="32"></app-load-spinner>
    </ng-container>
</div>

<div class="drug-dialog-content search-no-results" *ngIf = "!dataSource?.isLoading$.value && dataSource?.isEmpty() && (screenState === 'Search' || screenState === 'SearchAll' || screenState === 'Add') ">
    <h3>No medications found. Please search again.</h3>
</div>


