import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { cloneParsedScrRow, SourceMedicines } from 'src/app/api/ApiRecordTypes/NHSRecords';
import { DrugModel } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';
import { IAPIService, ParsedScrRow } from 'src/app/api/iapi.service';
import { DrugsDataSource } from 'src/app/utility/filtering/drugdatasource';
import * as moment from 'moment';
import { AppConfigService } from 'src/app/app-config.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-patientdrugrecord',
  templateUrl: './patientdrugrecord_editable.component.html',
  styleUrls: ['./patientdrugrecord_editable.component.scss'],
  animations: [
    trigger('displaydrugAnimation', // [@displaydrugAnimation]
    [
      transition(':enter',
      [
      style({height: 0}),
      animate('1s ease-out', style({height: 300}))
      ]),
      transition(':leave',
      [
      style({height: 300}),
      animate('1s ease-out', style({height: 0}))
      ])
    ]
    )
  ]
})
export class PatientDrugRecordComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('record') item: ParsedScrRow  = null;  // drugline
  @Input() editDaysSupply  = false;  // drugline
  @Input() forceStopEdit$: Subject<boolean>;
  @Input() isIM1Patient: boolean;

  @Output() drugDeletionRequest = new EventEmitter<number>();
  @Output() drugAdditionRequest = new EventEmitter<ParsedScrRow>();
  @Output() drugSaveRequest = new EventEmitter();
  @Output() drugCancelRequest = new EventEmitter();
  @Output() drugSelectedForOrderChange = new EventEmitter();

  edititem: ParsedScrRow;  // drugline
  matchingErr = false;
  editmode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchText = '';
  isValidSearchText = true;
  isValidEditItem = false;
  isChanged = false;
  dataSource: DrugsDataSource;
  
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;

  // local variables for communicating to the screeen
  constructor(private api: IAPIService, private configService: AppConfigService) {
    if (this.edititem && this.edititem.activeName) {
      this.dataSource = new DrugsDataSource(api, this.edititem.activeName, true);
    }
  }

  ngOnInit() {
    this.config = this.configService.getConfig();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.forceStopEdit$.subscribe((value) => {
      console.log('Closing all edits');
      if (this.editmode$.value === true) {
        this.editmode$.next(false);
      }
    });
  }

  formatDate(date) {
    if (date) {
      return date.format('yyyy-MM-DD hh:mm A').toString();

    }
    return moment(new Date()).format('yyyy-MM-DD hh:mm A').toString();
  }

  validateSearchText() {
    this.isValidSearchText = this.searchText.length >= 3;
    return this.isValidSearchText;
  }

  /** Updates the state used by Accept & Cancel buttons.
   *  Returns true if edititem is valid
   */
  validateEditItem() {
    this.isValidEditItem = this.edititem != null && this.edititem.maDrugId > 0;
    this.isChanged =
      ((this.edititem == null) ? null : JSON.stringify(this.edititem, Object.keys(this.edititem).sort())) !==
      ((this.item     == null) ? null : JSON.stringify(this.item,     Object.keys(this.item).sort()));

    return this.isValidEditItem;
  }

  doChange() {
    this.validateEditItem();
  }

  doSearchDrugs() {
    this.matchingErr = false;
    if (this.validateSearchText()) {
      this.dataSource = new DrugsDataSource(this.api, this.searchText, false);
    }
  }
  checkLength(minlength: number) {
    if (this.searchText.length >= minlength) {
      this.isValidSearchText = true;
    }
  }
  /** Esc pressed while in searchText box, or search results */
  doCancelSearchDrugs() {
    this.dataSource = null;
    if (this.item != null) {
      this.edit(); // restore this.edititem and this.searchText
    }
  }

  doSearchResultItemSelected(selectedItem: DrugModel) {
    this.edititem = ParsedScrRow.fromDrugModel(selectedItem);

    if (this.item != null) {
      // propegate any existing patient-drug details which can't be obtained from the drug model alone
      this.edititem.source               = this.item.source;
      this.edititem.repeatCycleFrequency = this.item.repeatCycleFrequency;
      this.edititem.repeatCycle          = this.item.repeatCycle;
      this.edititem.erd                  = this.item.erd;
      this.edititem.repeatLeft           = this.item.repeatLeft;
    } else {
      // Choose default values for the new drug which will hopefully reduce the need for more edits from the user
      // Dispense is displayed as "Pharmacy", so using this even though there's no dispense integration
      this.edititem.source = SourceMedicines.Dispense;
      this.edititem.repeatCycleFrequency = 'days';
      this.edititem.repeatCycle = 28;
      this.edititem.erd = false;
      this.edititem.repeatLeft = 0;
    }

    this.dataSource = null; // close the search results
    this.validateEditItem();
    this.searchText = this.edititem._drugtext;
    this.editmode$.next(true);
  }

  getRptsLeft(): string {
    console.log('repeats', this.item.repeatLeft);
    return (this.item == null || this.item.repeatLeft === null  || this.item.repeatLeft < 0 || !this.item.erd )
     ? 'N/A'
     : this.item.repeatLeft.toString();
  }
  /// determine if the whole record should be hidden - true for the empty record when not being edited.
  hide(editMode: boolean): boolean {
    return editMode === false && this.item == null;
  }

  edit() {
    if (this.forceStopEdit$ != null) {
      this.forceStopEdit$.next(false);
    }
    this.edititem = new ParsedScrRow(this.item);
    if (this.item == null) {
      this.edititem.source = SourceMedicines.Dispense;
      this.edititem.repeatCycleFrequency = '';
      this.edititem.repeatCycle = 0;
      this.edititem.erd = false;
      this.edititem.repeatLeft = 0;
      this.edititem.maDrugId = -1; // Not a valid drug that can be added to a patient, yet.
    }
    this.validateEditItem();
    this.searchText = this.edititem._drugtext;
    if (typeof this.edititem.repeatLeft === 'string') {
      this.edititem.repeatLeft = parseInt(this.edititem.repeatLeft, 10);
    }
    if (this.edititem.repeatLeft === -1) {
      this.edititem.repeatLeft = 0;
    }
    this.editmode$.next(true);
  }

  displaySupplyLeft(daysLeft: number) {
    if (!daysLeft) {
      daysLeft = 0;
    }
    const plural = daysLeft === 1 ? '' : 's';
    return `${daysLeft} day${plural}`;
  }

  delete() {
    if (!this.isIM1Patient) {
      this.drugDeletionRequest.next(this.item.maDrugId);
    }
  }

  cancelUpdate() {
    this.editmode$.next(false);
    this.drugCancelRequest.next();
  }

  saveUpdate() {
    if (this.validateEditItem()) {
      // Finalise and validate data
      if (this.edititem.erd === false) {
        this.edititem.repeatLeft = -1;
      }
      this.edititem.supply = this.edititem.quantity;
      // Determine how data is saved.
      if (this.item != null) {
        cloneParsedScrRow(this.edititem, this.item);
      } else {
        this.drugAdditionRequest.next(this.edititem);
      }
      this.editmode$.next(false);
      this.drugSaveRequest.next();
    }
  }

  sourcetoText(source: number) {
    switch (source) {
      case SourceMedicines.Dispense:
        return 'Pharmacy';
      case SourceMedicines.Manual:
        return 'Patient';
      case SourceMedicines.SCR:
        return 'Summary Care Record';
      case SourceMedicines.IM1:
        return 'GP';
      default:
        return 'N/A';
    }
  }

  selectDrug(){
    this.drugSelectedForOrderChange.emit()
  }

  limitLength(e) {
    if (e.target.value.length === 4) {
      e.preventDefault();
    }
  }
}
