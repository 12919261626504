import {Component, OnDestroy, TemplateRef} from '@angular/core';
import {Router} from '@angular/router';
import {BasicDialogComponent} from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import {CalendarDialogData} from 'src/app/booking-calendar/models/view-models/layout-calendar-dialog.view-model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent extends BasicDialogComponent implements OnDestroy {
  //#region Input
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public content: string | TemplateRef<any>;

  //#endregion

  //#region Accessors

  //#endregion

  //#region Constructor
  public constructor(protected router: Router) {
    super(router);
    this.acceptButtonTitle = 'OK';
    this.cancelButtonTitle = 'Cancel';
  }

  //#endregion

  //#region Life cycle
  public ngOnDestroy() {
    if (this._subscription && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }

  //#endregion

  public setData(data: CalendarDialogData) {
    this.headerTitle = data.settings.title;
    this.content = data.settings.content;
    if (data.settings.acceptButtonTitle != null) {
      this.acceptButtonTitle = data.settings.acceptButtonTitle;
    }
    if (data.settings.cancelButtonTitle != null) {
      this.cancelButtonTitle = data.settings.cancelButtonTitle;
    }
  }

  //#region Methods

  public confirmDialogContentType(): 'string' | 'template' {
    if (this.content instanceof TemplateRef) {
      return 'template';
    }
    return 'string';
  }

  //#endregion

}
