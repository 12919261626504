import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import {MatRippleModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';
import {MatSortModule} from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar'
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';

import {ToastrModule} from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AppConfigService } from './app-config.service';
import { FooterComponent } from './navigation/footer/footer.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidebarComponent } from './navigation/sidebar/sidebar.component';
import { MenuComponent } from './navigation/menu/menu.component';
import { MenuItemComponent } from './navigation/menu/menuitem.component';

import { AppRoutingModule } from './app-routing.module';
import { PatientAddComponent } from './patients/patientadd/patientadd.component';
import { LoginComponent } from './authentication/login/login.component';
import { LoginPageComponent } from './authentication/loginpage/loginpage.component';
import { QxlvaAuthComponent } from './authentication/qxlvaauth/qxlvaauth.component';
import { PatientAddPageComponent } from './patients/patientaddpage/patientaddpage.component';
import { PatientListComponent } from './patients/patientlist/patientlist.component';
import { SurgeryListComponent } from './surgeries/surgerylist/surgerylist.component';
import { AuditComponent } from './audit/audit.component';

import { MessageListComponent } from './messages/messagelist/messagelist.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from './utility/auth-interceptor.service';
import { JSONInterceptor } from './utility/json-date-interceptor.service';
import { TranslateModule } from '@ngx-translate/core';
import { APIService, IAPIService } from './api/api.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import 'custom-event-polyfill';
import { GeneralDialogComponent } from './utility/base-dialog/general-dialog/general-dialog.component';
// import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { PatientRequestComponent } from './messages/messagesummaries/patientrequest/patientrequest.component';
import { PatientSignupComponent } from './messages/messagesummaries/patientsignup/patientsignup.component';
import { LoadingRequestComponent } from './messages/messagesummaries/loadingrequest/loadingrequest.component';
import { PatientCommunicationComponent } from './messages/messagesummaries/patientcommunication/patientcommunication.component';
import { LoadSpinnerComponent } from './utility/load-spinner/load-spinner.component';
import { CardStatusComponent } from './utility/card-status/card-status.component';
import { ImagePreloadDirective } from './utility/directives/ImagePreload';
import { NumberDirective } from './utility/number-directive/numbers-only-directive';
import { RemoveComponentHostDirective } from './directives/removecomponenthost/removecomponenthost.directive';
import { FilterControlDirective } from './utility/filtering/filtercontrol.directive';
import { SortControlDirective } from './utility/filtering/sortcontrol.directive';
import { SurgeryDetailsComponent } from './surgeries/surgerydetails/surgerydetails.component';
import { SettingsComponent } from './settings/settings.component';
import { NavigationBarTitleDirective } from './navigation/header/headerDirectives/headerMiddleDirective';
import { NAVIGATION_BAR_SERVICE_PROVIDER } from './navigation/header/headerDirectives/headerConstants';
import { NavigationBarService } from './navigation/header/headerDirectives/headerService';
import { SurgerySummaryComponent } from './surgeries/surgerydetails/surgerysummary/surgerysummary.component';
import { PatientDetailsComponent } from './patients/patientdetails/patientdetails.component';
import { OrderComponent } from './messages/order/order.component';
import { LoaderService } from './utility/utilsservice/loader.services';
import { LoaderInterceptor } from './messages/messagelist/loader.interceptor';
import { ManageOrderComponent } from './messages/manageorder/manageorder.component';
import { LoaderComponent } from './messages/messagesummaries/messageloader/loader.component';
import { IRegionBehaviour } from './region/iregion-behaviour.module';
import { RegionBehavior } from './region/region-behaviour.module';
import { SafePipe } from './utility/pipes/safe.pipe';
import { PatientInviteConfirmationComponent } from './patients/patientinviteconfirmation/patientinviteconfirmation.component';
import { PatientExternalDetailComponent } from './patients/patientexternaldetail/patientexternaldetail.component';
import { PatientExternalSearchComponent } from './patients/patientexternalsearch/patientexternalsearch.component';
import { PatientDrugUpdateComponent } from './patients/patientdrugupdate/patientdrugupdate.component';
import { PatientDrugRecordComponent } from './patients/patientdrugrecord_editable/patientdrugrecord_editable.component';
import { PatientInviteSuccessComponent } from './patients/patient-invite-success/patient-invite-success.component';
import { DrugUpdateDialogComponent } from './patients/drugupdatedialog/drugupdatedialog.component';
import { MessageInboxComponent } from './messages/messageinbox/messageinbox.component';
import { ResizeTextAreaDirective } from './directives/textareaautoresize/textareaautoresize.directive';
import { MessageRequestComponent } from './messages/messagerequest/messagerequest.component';
import { ValidationErrorComponent } from './utility/validation-error/validation-error.component';
import { EmergencyAccessComponent } from './patients/emergency-access/emergency-access.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MessageBookingDetailComponent } from './messages/messagesummaries/messagebookingdetail/messagebookingdetail.component';
import { MessageBookingDialogComponent } from './messages/messagebookingdialog/messagebookingdialog.component';
import { BroadcastMessageComponent } from './messages/broadcastmessage/broadcastmessage.component';
import { BroadcastPatientDialogComponent } from './messages/broadcastpatientdialog/broadcastpatientdialog.component';
import { PatientGroupUpdateDialogComponent } from './patients/patientgroupupdatedialog/patientgroupupdatedialog.component';
import { UnescapePipe } from './utility/pipes/unescape.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {LoadingSpinnerModule} from './utility/loading-spinner/loading-spinner.module';
import {BookingCalendarModules} from './booking-calendar/booking-calendar.module';
import { Im1ConnectComponent } from './patients/npa-provider-im1-connect/im1-connect.component';
import { PharmacyOrderComponent } from './patients/pharmacy-order-drugs/pharmacy-order.component';
import { UkApiService } from './api/regional/uk/uk-api.service';
import { IUkApiService } from './api/regional/uk/uk-api.interface';
import { ApiHelperService } from './api/api-helper.service';
import { IApiHelperService } from './api/api-helper.interface';
import { GlobalLoggingService } from './logging/global-logging.service';
import { IGlobalLoggingInterface } from './logging/global-logging.interface';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DispenseRequestApiService } from './api/methods/dispense-request-api.service';
import { IDispenseRequestApiService } from './api/methods/dispense-request-api.interface';


const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  exports: [
    MatProgressBarModule,
    MatDialogModule,
    MatPaginatorModule,
  ],
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    MenuComponent,
    MenuItemComponent,
    GeneralDialogComponent,
    PatientAddComponent,
    DrugUpdateDialogComponent,
    EmergencyAccessComponent,
    LoginComponent,
    LoginPageComponent,
    QxlvaAuthComponent,
    PatientAddPageComponent,
    PatientListComponent,
    AuditComponent,
    SurgeryListComponent,
    MessageListComponent,
    PatientRequestComponent,
    PatientSignupComponent,
    LoadingRequestComponent,
    PatientCommunicationComponent,
    LoadSpinnerComponent,
    CardStatusComponent,
    ImagePreloadDirective,
    NumberDirective,
    RemoveComponentHostDirective,
    FilterControlDirective,
    SortControlDirective,
    SurgeryDetailsComponent,
    SurgerySummaryComponent,
    PatientDetailsComponent,
    SettingsComponent,
    NavigationBarTitleDirective,
    SurgerySummaryComponent,
    OrderComponent,
    LoaderComponent,
    ManageOrderComponent,
    SafePipe,
    PatientInviteConfirmationComponent,
    PatientExternalDetailComponent,
    PatientExternalSearchComponent,
    PatientDrugUpdateComponent,
    PatientDrugRecordComponent,
    PatientInviteSuccessComponent,
    MessageInboxComponent,
    ResizeTextAreaDirective,
    MessageRequestComponent,
    ValidationErrorComponent,
    BroadcastMessageComponent,
    BroadcastPatientDialogComponent,
    PatientGroupUpdateDialogComponent,
    UnescapePipe,
    MessageBookingDetailComponent,
    MessageBookingDialogComponent,
    Im1ConnectComponent,
    PharmacyOrderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatDialogModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatMomentDateModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatButtonModule,
    MatSelectModule,
    MatPaginatorModule,
    MatChipsModule,
    ReactiveFormsModule,
    CKEditorModule,
    OverlayModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    ReactiveFormsModule,
    TimepickerModule.forRoot(),
    MatSortModule,
    LoadingSpinnerModule,
    BookingCalendarModules,
    RouterModule,
    CommonModule,
    ClipboardModule,
  ],
  providers: [
//    AuthenticationService,
    { provide: 'SESSIONSTORAGE', useFactory: getSessionStorage},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: JSONInterceptor, multi: true},
    { provide: IAPIService, useClass: APIService },
    { provide: IDispenseRequestApiService, useExisting: DispenseRequestApiService },
    { provide: IUkApiService, useClass: UkApiService },
    { provide: IApiHelperService, useClass: ApiHelperService },
    HttpClient,
    { provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [AppConfigService]},
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    { provide: NAVIGATION_BAR_SERVICE_PROVIDER, useClass: NavigationBarService },
    [LoaderService,  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
    { provide: IRegionBehaviour, useClass: RegionBehavior},
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: IGlobalLoggingInterface, useClass: GlobalLoggingService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(LOCALE_ID) public locale: string
  ) {

  }
}

export function getSessionStorage() {
    return (typeof window !== 'undefined') ? window.sessionStorage : null;
}
