import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import * as _moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';

@Injectable()
export class JSONInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {
  }

  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:(Z?)|((-|\+)\d{2}:\d{2}))$/;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return next.handle(req).pipe(map( (val: HttpEvent<any>) => {
      if (val instanceof HttpResponse) {
        const body = val.body;
        this.convert(body, 'obj.body');
      }
      return val;
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isIsoDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return this._isoDateFormat.test(value);
    }
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convert(body: any, name= 'base') {
    if (body === null || body === undefined ) {
      return body;
    }
    if (typeof body !== 'object' ) {
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIsoDateString(value)) {
        body[key] = _moment.utc(value);
//        console.log( 'converting ' + name + '.' + key + ' from ' + value + ' to ' + body[key] );
//        console.log(LOCALE_ID);
      } else if (typeof value === 'object') {
        this.convert(value, name + '.'  + key);      }
    }
  }
}
