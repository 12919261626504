import {Directive, HostListener, Renderer2, ElementRef} from '@angular/core';

@Directive({
  selector: '[appResizeTextarea]'
})
export class ResizeTextAreaDirective {
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @HostListener('input') oninput(eventData: Event) {
    this.renderer.setStyle(this.elRef.nativeElement, 'height', '0px');
    const height = this.elRef.nativeElement.scrollHeight - 10 + 'px';
    this.renderer.setStyle(this.elRef.nativeElement, 'height', height);
    this.renderer.setStyle(this.elRef.nativeElement, 'max-height', '102px');
    this.renderer.setStyle(this.elRef.nativeElement, 'overflow', 'auto');
  }
}
