import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogResponse } from '../../utility/base-dialog/general-dialog/general-dialog.component';
import { Router } from '@angular/router';
import { APIService } from '../../api/api.service';
import { GroupsForPatientRequestModel, PatientGroupModel } from '../../api/ApiRecordTypes/PatientGroup';

@Component({
  selector: 'app-patient-group-update-dialog',
  templateUrl: './patientgroupupdatedialog.component.html'
})
export class PatientGroupUpdateDialogComponent implements OnInit, OnDestroy {
  //#region Properties

  public linkPatientGroups: PatientGroupModel[];

  public patientGroups: PatientGroupModel[];

  public OnComplete: EventEmitter<DialogResponse> = new EventEmitter<DialogResponse>(true);

  protected initialLinkPatientGroup: PatientGroupModel[];

  // Subscription watch list.
  protected readonly _subscription: Subscription;

  protected pharmacyId: number;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private patientId: any;
  //#endregion

  //#region Constructor
  constructor(
    protected router: Router,
    private apiService: APIService,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any
  ) {
    this._subscription = new Subscription();
  }

  //#endregion

  //#region Methods

  public ngOnInit(): void {
    this.patientGroups = [];
    const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
    if (!accountSetting || !accountSetting.id) {
      return;
    }
    this.pharmacyId = accountSetting.id;
    const loadPatientGroupSubscription = this.apiService.retrievesPatientGroupAsync(accountSetting.id)
      .subscribe((patientGroup: PatientGroupModel[]) => {
        if (patientGroup) {
          this.patientGroups = patientGroup.filter(link => !(link.preferences && link.preferences.includes('DefaultGroup')));
        }
        this.patientGroups.sort((firstGroup, secondGroup) => {
          return firstGroup.name.localeCompare(secondGroup.name);
        });
      });
    this._subscription.add(loadPatientGroupSubscription);
  }

  public submitChangeGroup(): void {
    const changedLinkGroupIds = this.linkPatientGroups.map(link => link.id);
    const initialLinkPatientGroupIds = this.initialLinkPatientGroup.map(link => link.id);
    const addedPatientGroupIds = changedLinkGroupIds.filter(id => !initialLinkPatientGroupIds.includes(id));
    const removedPatientGroupIds = initialLinkPatientGroupIds.filter(id => !changedLinkGroupIds.includes(id));
    this.patientGroups.forEach(group => {
      if (addedPatientGroupIds.includes(group.id)) {
        this.initialLinkPatientGroup.push(group);
      }

      if (removedPatientGroupIds.includes(group.id)) {
        const removedGroupIndex = this.initialLinkPatientGroup.findIndex(initialGroup => initialGroup.id === group.id);
        this.initialLinkPatientGroup.splice(removedGroupIndex, 1);
      }
    });

    this.initialLinkPatientGroup.sort((firstLink, secondLink) => {
      return firstLink.name.localeCompare(secondLink.name);
    });

    const bodyRequest = new GroupsForPatientRequestModel();
    bodyRequest.patientId = this.patientId;
    bodyRequest.includeGroupIds = addedPatientGroupIds;
    bodyRequest.excludeGroupIds = removedPatientGroupIds;

    const updateLinkPatientGroupSubscription = this.apiService.includeOrExcludeGroupForPatientAsync(this.pharmacyId, bodyRequest)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe(_ => {
        this.handleAccept();
      });
    this._subscription.add(updateLinkPatientGroupSubscription);
  }

  public checkGroupLinkToPatient(groupId: number): boolean {
    if (!this.linkPatientGroups) {
      return false;
    }
    return this.linkPatientGroups.some(group => group.id === groupId);
  }

  public selectOrDeselectGroup(group: PatientGroupModel): void {
    if (this.isDefaultPatientGroup(group)) {
      return;
    }
    if (!this.checkGroupLinkToPatient(group.id)) {
      this.linkPatientGroups.push(group);
      return;
    }
    const index = this.linkPatientGroups.findIndex(linkedGroup => linkedGroup.id === group.id);
    this.linkPatientGroups.splice(index, 1);
  }

  public isDefaultPatientGroup(group: PatientGroupModel): boolean {
    if (group.preferences && group.preferences.includes('DefaultGroup')) {
      return true;
    }
    return false;
  }

  public handleCancel(): void {
    this.OnComplete.emit();
  }

  public handleAccept(): void {
    this.OnComplete.emit();
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public setData(data: any) {
    this.linkPatientGroups = [];
    this.initialLinkPatientGroup = [];
    if (!data) {
      return;
    }
    this.patientId = data.patientId;
    this.initialLinkPatientGroup = data.linkPatientGroup;
    this.linkPatientGroups = [...this.initialLinkPatientGroup];
  }

  public ngOnDestroy(): void {
    if (this._subscription && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }

  //#endregion
}
