import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsDialogComponent } from 'src/app/booking-calendar/components/settings-dialog/settings-dialog.component';

const routes: Routes = [
  {
    path: 'setup-resource',
    component: SettingsDialogComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    RouterModule
  ],
  exports: [RouterModule]
})
export class BookingCalendarRoutingModules {
}
