import { PatientModel, PracticeModel } from './PendingTaskRecord';
import { PharmacyGroupModel } from './IdentityRecord';
import { AddressModel } from './GenericApiModels';
import { PharmacyModel } from './PharmacyRecord';

 export enum CaregiverStatus {
  None = 0,
  IsCaringFor = 1,
  IsCaredFor = 2
}

export class PatientRecord {
  identityPrivate: PatientModel;
  optOutType: number;
  patientCode: number;
  systemCode: string;
  patientType: null;
  controlPatient: number;
  createDate: Date;
  optOutSmsdate: Date;
  optOutMailDate: Date;
  pharmacyId: number;
  updateDate: null;
  dataExpiry: Date;
  deleteDate: Date;
  drugsLastImported: null;
  drugFetchRequired: null;
  externalOptInStatus: null;
  externalOptInExpiry: null;
  externalOptInRequiredDisplay: null;
  externalOptInExpiryRequiredDisplay: null;
  rowVersion: null;
  userKey: null;
  preferences: null;
  culture: null;
  pharmacyDataSourceGroupId: null;
  identity: null;
  pharmacy: null;
  pharmacyDataSourceGroup: null;
  address: AddressModel;
  practices: PracticeModel[];
  medications: MedicationModel[];
  patientCode_MedAdvisorGuid: string;
}
export class MedicationRelationshipProperties {
  instructionsread: string;
  supply: number;
  deterministic: number;
  hasrepeats: string;
  refillnumber: number;
  numberofrefill: number;
  source: number;
  repeatcycle: number;
  repeatcyclefrequency: string;
}
export class MedicationModel {
  relationshipId: number;
  startDate: Date;
  endDate: Date;
  lastDispenseDate: Date;
  lastDispense: null;
  firstDispense: null;
  legalInstruments: [];
  workflowsValid: boolean;
  /** Will sometimes be null */
  relationshipProperties: MedicationRelationshipProperties;
  requestStatus: null;
  legalInstrumentStatus: number;
  totalRefillsAuthorized: number;
  totalFillsAuthorized: number;
  totalRefillsRemaining: number;
  totalFillsRemaining: number;
  id: number;
  labelName: string;
  activeName: string;
  form: string;
  source: number;
  strength: string;
  status: string;
  packetSize: number;
  systemCode: string;
  systemKey: string;
  properties: {
    basePrice: string;
    orderingMessage: string;
  };
  daysLeft: number;
}
export class IdentityPrivateModel {
  address: AddressModel;
  billingAddress: string;
  deliveryAddress: string;
  pharmacyPatientInviteStatus: string;
  id: number;
  patientCode: string;
  invitedDate: Date;
  patientType: string;
  updateDate: string;
  deleteDate: string;
  zipCode: string;
  createdDate: Date;
  patientStatus: string;
  rowVersion: string;
  addressId: number;
  billingAddressId: number;
  deliveryAddressId: number;
  title: string;
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  phone: string;
  dateOfBirth: Date;
  externalSystemCode: string;
}

export class PDSRecord {
  id: number;
  optOutType: number;
  identityPrivate: IdentityPrivateModel;
  patientCode: string;
  systemCode: string;
  patientType: string;
  controlPatient: number;
  createDate: Date;
  optOutSmsdate: Date;
  optOutMailDate: Date;
  pharmacyId: number;
  updateDate: Date;
  dataExpiry: Date;
  deleteDate: Date;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  drugsLastImported: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  drugFetchRequired: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  externalOptInStatus: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  externalOptInExpiry: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  externalOptInRequiredDisplay: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  externalOptInExpiryRequiredDisplay: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  rowVersion: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  userKey: any;
  preferences: {
    timezone: string,
    externaloptinstatus: string
    externaloptinexpiry: Date
  };
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  culture: any;
  pharmacyDataSourceGroupId: number;
  identity: IdentityPrivateModel;
  pharmacy: PharmacyModel;
  pharmacyDataSourceGroup: PharmacyGroupModel;
  address: AddressModel;
  practices: PracticeModel[];
  medications: MedicationModel;
}

export class PatientRelationshipModel {
  carerStatus: CaregiverStatus;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  patient: any;
  related: PatientModel[];
}

export class PatientPharmacyInformation {
  patient_FirstName: string;
  patient_LastName: string;
  pharmacy_Name: string;
}

export class PatientPageState {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  patientCode_MedAdvisorGuid: any;
  isIM1Patient: boolean;
  isCarerMode: boolean;
}

export class ScrPageState {
  is500InternalServer: boolean;
  isHtmlNotNullAndDrugNull: boolean;
  errorMessageNoSCR: string;
  errorMessageNoDrug: string;
}

export class PatientActiveIngredientsModel {
  activeIngredients: number[];
  mixedPatientModel: MixedPatientModel;
}

export class MixedPatientModel {
  id: number;
  status: number;
  patientCode_MedAdvisorGuid: string;
  patientCode: string;
  externalSystemCode: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  zipCode: any;
  phone: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  email: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  medications: any;
  carerStatus: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  carerName: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  practiceName: any;
  caringFor: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  careeName: any;
  gender: string;
}
