import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarDialogData } from '../../models/view-models/layout-calendar-dialog.view-model';
import { BasicDialogComponent } from '../basic-diglog/basic-dialog.component';

@Component({
  selector: 'app-time-confirm-dialog',
  templateUrl: './time-confirm-dialog.component.html',
  styleUrls: ['./time-confirm-dialog.component.css']
})

export class ConfirmationDialogComponent extends BasicDialogComponent {
 @Input() public timeType = '';
 public comfirmationText = 'Are you sure you want to update your calendar to specify';
 public selectedTime = '';
 public bookingResourceWarningText = 'This will affect all of your booking resources';
 public acceptButtonTitle = 'Save';
 public cancelButtonTitle = 'Cancel';

 public constructor (
  protected router: Router
 ) {
   super(router);
 }

 public setData (data: CalendarDialogData) {
   this.selectedTime = data.settings.timeType;
 }
}
