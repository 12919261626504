import { Component,  ViewEncapsulation, EventEmitter, Output, Input,
  ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import { CommsModule } from 'src/app/utility/comms/comms.module';

import { OperationResult } from '../../api/iapi.service';
import { IDialogEmbeddable } from 'src/app/utility/base-dialog/dialog.service';
import { AuthenticationService } from '../authentication.service';
import { AppConfigService } from 'src/app/app-config.service';

export class LoginOutput extends OperationResult<{id: string}> {}
export class LoginConfig {
  public ShowCancel = false;
  public AuthService: AuthenticationService; // To avoid circular dependencies
  public Message: string = null;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements IDialogEmbeddable<LoginConfig, LoginOutput> {
  @Input() public data: LoginConfig = new LoginConfig();
  @Output() public OnComplete: EventEmitter<LoginOutput> = new EventEmitter<LoginOutput>(true);

  @ViewChild('login', {static: true}) loginInput: ElementRef;

  LoginName = '';
  Password = '';
  ErrorMessage: string;
  TitleMessage = 'Welcome back\n please login to your account';
  Notice = '';
  hide = true;
  // control fields
  NHS = true;

  // Whether password should be revealed or not.
  public shouldPasswordRevealed: boolean;

  // tslint:disable-next-line: no-inferrable-types
  ForgotPassword = false;
  isValid = true;
  isClickSave = false;
  ShowPrivacyLinks = true;
  inProgress = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private comms: CommsModule,
    private configService: AppConfigService
  ) {
    const config = configService.getConfig();
    this.ShowPrivacyLinks = config.features.ShowPrivacyLinksInLogin;
    comms.getAuthChanges().subscribe((authState) => {
      if (authState === true) {
        this.OnComplete.emit(new LoginOutput());
        this.cdr.markForCheck();
      }
    });
   }

  public setData(data: LoginConfig): void {
    this.data = data;
  }

  cancel() {
    this.ForgotPassword = false;
    this.loginInput.nativeElement.focus();
  }

  changeText() {
    this.isValid = true;
  }

  forgotPassword() {
    this.ForgotPassword = true;
    this.isValid = true;
    this.loginInput.nativeElement.focus();
  }

  forgot() {
    if (this.data.AuthService == null) {
      this.displayFailResult('No auth service available');
    }
    const result = this.data.AuthService.forgotPassword(this.LoginName);
    if (result === 'success') {
      this.ForgotPassword = false;
      alert('It was that thing you typed in when you created the account');
      this.Notice = 'You have been sent an email containing a new password.';
    } else {
      this.displayFailResult(result);
    }
  }

  displayFailResult(result: string) {
    this.ErrorMessage =  result;
  }

  login() {
    // clear the error highlights
    this.isValid = true;
    this.displayFailResult('');
    this.cdr.markForCheck();

    if (this.data.AuthService == null) {
      this.displayFailResult('No auth service available');
    }
    if (this.data.AuthService !== undefined) {
      this.inProgress = true;
      this.data.AuthService.attemptLogin(this.LoginName, this.Password).then(
        () => {
          this.OnComplete.emit(new LoginOutput());
          this.cdr.markForCheck();
          this.inProgress = false;
        },
        (error) => {
          this.isValid = false;
          this.displayFailResult(error);
          this.cdr.markForCheck();
          this.inProgress = false;
        });
    }
  }

  loginMobile() {
    if (this.LoginName !== '' && this.Password !== '') {
      this.inProgress = true;
      this.isClickSave = true;
      if (this.data.Message != null) {
        this.TitleMessage = this.data.Message;
      }
      if (this.data.AuthService == null) {
        this.displayFailResult('No auth service available');
      }
      if (this.data.AuthService !== undefined) {
        this.data.AuthService.attemptLogin(this.LoginName, this.Password).then(
          () => {
            this.OnComplete.emit(new LoginOutput());
            this.cdr.markForCheck();
            this.inProgress = false;
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          (error) => {
            this.isValid = false;
            this.isClickSave = false;
            // this.displayFailResult(error);
            this.cdr.markForCheck();
            this.inProgress = false;
          });
      }
    } else {
      this.isValid = true;
    }
  }
}
