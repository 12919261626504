import {Component, Input, OnInit} from '@angular/core';
import { PendingTaskRecord } from 'src/app/api/iapi.service';
@Component({
  selector: 'app-msg-messagerequest',
  templateUrl: './messagerequest.component.html'
})
export class MessageRequestComponent implements OnInit {
  @Input() message: PendingTaskRecord;
  content: string;
  firstName: string;
  lastName: string;
  count: number;
  fromPharmacy: boolean;
  ngOnInit() {
    const taskData = JSON.parse(this.message.jSonTaskData);
    this.content = taskData['Body'];
    this.count = taskData['Count'];
    this.fromPharmacy = taskData['FromPharmacy'];
    this.firstName = this.message.patient.firstName;
    this.lastName = this.message.patient.lastName;
  }
}
