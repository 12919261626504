import { CardDetails, ICardIconDisplay } from '../IBehaviours/ICardIconDisplay.service';
export { ICardIconDisplay };

export class DefaultCardIconDisplay  implements ICardIconDisplay {
    getIconData(): CardDetails {
        const details = new CardDetails();
        details.cardsUsed = false;
        return details;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCardToken(token: string): CardDetails {
        return this.getIconData();

    }
}
