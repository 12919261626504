import {AbstractControl, UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';

export class CalendarValidation {
  public static formGroupValueRequred (): ValidatorFn {
    return (control: UntypedFormGroup): ValidationErrors | null => {
      if (Object.values(control.value).find(value => value === true)) {
        return null;
      }
      return { required: true };
    };
  }

  public static minSelectedCheckboxes(min = 1): ValidatorFn {
    return (formArray: UntypedFormArray): ValidationErrors | null => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };
  }

  public static emailValidator (): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return { required: true };
      }
      return UtilsService.EmailValidate(control.value) ? null : { emailFormat: true };
    };
  }

  public static dobValidator (): ValidatorFn {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (control: AbstractControl): ValidationErrors | null => {
      return null;
    };
  }

  public static mobileNoValidator (): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return { required: true };
      }
      return UtilsService.UKMobileNoValid(control.value) ? null : { mobileNoFormat: true };
    };
  }
}
