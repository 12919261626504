<div *ngIf = "config?.features?.ShowCardStatus" class="card-status" [ngClass]="{'inserted': (cardInserted$ | async), 'not-inserted': (cardInserted$ | async) === false}">
    <div class="card-icon-container">
        <span class="material-icons smart_card">credit_card</span>
    </div>
    <p *ngIf="(cardInserted$ | async) === false">NHS smart card is <span class="highlight">not inserted.</span></p>
    <div *ngIf="(cardInserted$ | async)">
        <p>
            <span class="highlight">{{(cardName$ | async)}}</span>'s NHS smart card is inserted. 
        </p>
        <p class="card-status-details">
            Org: {{(organisation$ | async)}}
        </p>
        <p class="card-status-details">
            Role: {{(jobTitle$ | async)}}
        </p>
    </div>
</div>



