<div class="pageContent">
  <div [hidden]=true>
    {{message$ | async}}
  </div>
<div class="success-invite">
  <div id="successInvite" class="display-example">
      <!-- <div class="patient-name" *ngIf="screenMode==='InvitePatient'">
        This invitation was sent
      </div> -->
      <div class="message-sent-success" *ngIf="message">
        <p [innerHTML]="message"></p>
      </div>
  </div>

  <div class="carer-mode-instructions" *ngIf="screenMode==='InviteCarer'">
    <div class="instructions-header">
      <mat-icon style="font-size: 36px; font-weight: 400; width: 36px; height: 36px;">error_outline</mat-icon>
      <p class="instruction-header-text" style="margin-left: 15px;">Optional: Now that we're done...</p>
    </div>
    <div class="align-center-div">
      <div style="margin-left: 51px; margin-right: 25px;">Is <span class="first-name">{{carerPatient.firstName}}</span><span>&nbsp;</span> <span class="last-name">{{carerPatient.lastName}}</span> carer of a new patient? </div>
      <a (click)="openPatientInvite()" class="a-link link-cursor">Create a patient for <span class="first-name">{{carerPatient.firstName}}</span> to care for</a>
    </div>
  </div>

</div>
</div>
