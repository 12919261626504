import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';
import {CalendarEventService} from 'src/app/booking-calendar/services/calendar-event.service';
import {DataService} from '../../utility/dataservice/data.service';
import {CommsModule} from '../../utility/comms/comms.module';
import {AuthenticationService, AuthState} from '../../authentication/authentication.service';
import {SpinnerService} from '../../utility/loading-spinner/spinner.service';

@Injectable()
export class CalendarGuard implements CanActivate {
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-explicit-any
  public constructor (@Inject('SESSIONSTORAGE') private sessionStorage: any,
                      private dataService: DataService,
                      private comms: CommsModule,
                      private spinnerService: SpinnerService,
               protected authenService: AuthenticationService,
               protected calendarService: CalendarEventService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const authState = this.authenService.getAuthState();
    if (authState !== AuthState.Auth) {
      this.spinnerService.startLoading();
      this.authenService.setAccount();
    }
    this.comms.setPage({ pageTitle: 'Services', page: 'Calendar', pageIcon: '' });
    const accountSettings = JSON.parse(this.sessionStorage.getItem('accountSettings'));
    const pharmacyId = accountSettings ? accountSettings.id : +this.sessionStorage.getItem('pharmacyId');
    const getPharmacySettingObservable = pharmacyId
      ? this.calendarService.retrievePharmacySettings()
      : this.dataService.settings$.pipe(
        filter((value) => value !== null)
      );
    return getPharmacySettingObservable.pipe(
      mergeMap(pharmacySettings => {
        if (pharmacySettings === 'INVALID') {
          return of(true);
        }
        this.calendarService.setPharmacyInfo(pharmacySettings);
        return this.calendarService.loadResources(pharmacySettings.id);
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(value => {
        return true;
      })
    );
  }
}
