import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs/internal/Observable';
import {catchError, mergeMap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {Router} from '@angular/router';
import {EMPTY, throwError} from 'rxjs';
import {Location} from '@angular/common';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authService: AuthenticationService;
  constructor (private auth: AuthenticationService,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
               private router: Router,
               private toast: ToastrService,
               // eslint-disable-next-line @typescript-eslint/no-unused-vars
               private location: Location) {
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any  
  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    // Don't add auth tokens for Systems Settings request, or for requests from other locations.
    console.log('Url is ' + req.url);
    if (!req.url.startsWith(environment.apiPath) ||
      req.url.includes('/System') ||
      req.url.includes('/account/login') ||
      req.url.includes('/account/checkqxlvatoken') ||
      req.url.includes('/account/resetpasswordpharmacist') ||
      req.url.includes('/account/verifyresetpasswordtokenpharmacist') ||
      req.url.includes('/setting/PasswordPolicy')) {
      return next.handle(req);
    }

    return this.auth.waitForAuthentication().pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap((user: boolean) => {
        console.log('Auth completed');
        let authId = '(Token missing)';
        if (this.auth && this.auth.getAccessToken()) {
          authId = this.auth.getAccessToken();
          if (authId === '') { authId = '(Token missing)'; }
        }
        // Clone the request so we can modify it
        const authReq = req.clone({ headers: req.headers.set('Authorization', 'bearer ' + authId) });
        return next.handle(authReq);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // sessionStorage.clear();
          // this.location.go('');
          // window.location.reload();
          this.toast.error('An error occurred!');
          // this response is handled
          // stop the chain of handlers by returning empty
          return EMPTY;
        }

        // rethrow so other error handlers may pick this up
        return throwError(error);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      })) as Observable<HttpEvent<any>>;
  }
}
