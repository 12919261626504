import { Component, ViewEncapsulation, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-validation-error',
    templateUrl: './validation-error.component.html',
    encapsulation: ViewEncapsulation.None,
  })
export class ValidationErrorComponent {
    @Input() errorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);

}
