import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PharmacyModel } from 'src/app/api/ApiRecordTypes/PharmacyRecord';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class DataService {
  messageSource = new BehaviorSubject<string>('default message');
  currentMessage = this.messageSource.asObservable();
  actions: string;

  isSaveDone$ = new BehaviorSubject<boolean>(false);
  isSaveDone = this.isSaveDone$.value;

  settings$ = new BehaviorSubject<PharmacyModel>(null);
  setting = this.settings$.value;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
  getPerformAction(): string {
    return this.actions;
  }

  setPerformAction(action: string) {
    this.actions = action;
  }

  setSaved(status: boolean) {
    this.isSaveDone$.next(status);
    this.isSaveDone = this.isSaveDone$.value;
  }

  changeMessage(message) {
    this.messageSource.next(message);
  }

  getSettingsObs() {
    this.settings$.subscribe(val => {
        this.setting = val;
    });
  }

  setSettingsObs(setting: PharmacyModel) {
    this.settings$.next(setting);
    this.setting = this.settings$.value;
  }
}
