  <div *ngIf="(displayScreen === 'confirmPatientDetails' && customizedSiteConfig === 'NPA') || (displayScreen === 'confirmPatientDetails' && screenMode === 'InviteCarer' && customizedSiteConfig === 'DayLewis')" class="invited-form">
    <div [hidden]=true>{{message$ | async}}</div>
    <h2 class="search-pds-title" *ngIf="screenMode==='InvitePatient'">3 Check consent and send the invite</h2>
    <h2 class="search-pds-title" *ngIf="screenMode==='InviteCarer'">{{customizedSiteConfig === 'NPA' ? '3/3' : '2/2'}} Check consent and link cared for patient</h2>
    <div class="grid-layout-invite">
      <div class="col-left">
        <div class="invite-confirm">
          <p class="invite-row-space" *ngIf="screenMode==='InvitePatient'">
            Before we send the invite, check that the patient has <b> consented </b> to receiving an invite and that this is the patient's <b> nominated pharmacy </b> on the NHS EPS
          </p>
          <ng-container>
          <div class="grid-layout-invite-consent"  *ngIf="screenMode==='InviteCarer'">
            <div class="invite-consent-checkbox">
              <mat-checkbox color="primary" [(ngModel)]="carerInviteConsent"></mat-checkbox>
            </div>
            <div class="col-right">
              <p class="invite-row-space">
                Before we send the invite, check that carer <span class="first-name">{{carerPatient.firstName}}</span> <span class="last-name">{{carerPatient.lastName}}</span> has <b>consented</b> to care for {{displayPatientName()}}. Additionally, confirm that {{carerPatient.firstName}} {{carerPatient.lastName}} has your pharmacy <b>nominated</b> for the NHS EPS. 
              </p>
              <p>
                By ticking this box, I confirm that the patient {{displayPatientName()}} has consented to the carer <span class="first-name">{{carerPatient.firstName}}</span><span>&nbsp;</span> <span class="last-name">{{carerPatient.lastName}}</span> having access to their personal information (including prescription information) 
                and agreed to the <a href="{{ env.termsAndConditionsLink }}" target="_blank" class="login-link-color">Terms and Conditions</a> and <a href="{{ env.privacyLink }}" target="_blank" class="login-link-color">Privacy Policy</a>. 
                <span *ngIf="config.features.InvitePatient.ShowConsentForm">A consent form is available <a target="_blank" class="login-link-color" href="{{ env.carerModeConsentFormLink }}">here</a>.</span>
              </p>
            </div>
          </div>
          </ng-container> 
          <button type="button"
            (click)="addNewPatient()"
            class="btn-primary btn-icon-text">
            <span class="material-icons">send</span>
            <span>{{screenMode==='InvitePatient' ? 'Send Invite' : 'Link Patient'}} </span>
          </button>
          <p class="validation-error">
            {{errorMessage$ | async}}
          </p>
        </div>
      </div>
      <div class="col-right">
        <div id="successInvite" class="displayexample">
          <div class="patient-name">
            <strong>Example SMS notification</strong>
          </div>
          <div class="example-success" *ngIf="message">
            <p [innerHTML]="message"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="displayScreen === 'inviteSucess'" class="success-invite">
    <div id="successInvite" class="displayexample">
        <div class="patient-name">
          <strong>{{ displayPatientName() }} has been sent the following invitation:</strong>
        </div>
        <div class="example-success">
            <p>XXX XXX is your unique Invite Code</p>
            <p>Your code is valid for 7 days.</p>
            <p>Download the Medadvisor App using the link. Then enter your code to complete registration.</p>
            <p> http://npa.co.uk/app/download</p>
        </div>
    </div>
  </div>
