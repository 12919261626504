import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { CommsModule } from 'src/app/utility/comms/comms.module';

import { IAPIService } from '../../api/iapi.service';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

export enum AuthenticationStatus {
  Unauthenticated = 0,
  Authenticating = 1,
  Authenticated = 2,
  AuthenticationFailed = 3
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './qxlvaauth.component.html'
})

export class QxlvaAuthComponent  implements OnInit {

  token = '';
  authenticating$ = new BehaviorSubject<AuthenticationStatus>(AuthenticationStatus.Unauthenticated);
  constructor(
    private comms: CommsModule,
    private api: IAPIService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.comms.setPage({ pageTitle: 'NHS Card Verification', page: 'nhsauth', pageIcon: '' });
  }

  OnLegalAgreementAcceptance() {
    this.authenticating$.next(AuthenticationStatus.Authenticating);
    // Get auth token parameter
    // Check if currently authed .  Display "Already authenticated" and update all relevant statuses
    // If not, call API to confirm auth
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get('token');
      this.api.checkQxlvaToken(this.token).subscribe(
        result => {
          console.log(result);
          this.authenticating$.next(AuthenticationStatus.Authenticated);
          this.comms.updateCardData(result);
          this.router.navigateByUrl('/messages');
        },
        err => {
          console.log('error occurred:' + err);
          this.authenticating$.next(AuthenticationStatus.AuthenticationFailed);
        });
    });
  }

  public get AuthenticationStatus() {
    return AuthenticationStatus;
  }
}
