import { Component, OnInit, ViewEncapsulation,
  ViewChildren, QueryList, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { CommsModule } from 'src/app/utility/comms/comms.module';
import * as _ from 'lodash';
import { IAPIService, PatientRecord } from 'src/app/api/api.service';
import { DataSourceController } from 'src/app/utility/filtering/datasourcecontroller';
import { FilterControlDirective } from 'src/app/utility/filtering/filtercontrol.directive';
import { SortControlDirective } from 'src/app/utility/filtering/sortcontrol.directive';
import { FilterOptions } from 'src/app/utility/filtering/filtertypes';
import { DialogConfig, DialogService } from 'src/app/utility/base-dialog/dialog.service';
import { PatientAddComponent } from '../patientadd/patientadd.component';
import { PatientSearchContext, NoData } from 'src/app/api/ApiRecordTypes/OperationResults';
import { BehaviorSubject } from 'rxjs';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';
import { AppConfigService } from '../../app-config.service';
import { DataService } from 'src/app/utility/dataservice/data.service';
import { Router } from '@angular/router';
import { CaregiverStatus } from 'src/app/api/ApiRecordTypes/PatientRecord';


enum MixedPatientStatus {
  Invite = 0,
  RemoteSignupRequested = 1,
  RemoteSignupConfirmed = 2,
  Active = 3,
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-patients-list',
  templateUrl: './patientlist.component.html',
  styleUrls: ['./patientlist.component.css'],
})

export class PatientListComponent implements OnInit, AfterViewInit {
  API: IAPIService;

  datacontroller: DataSourceController<PatientRecord, NoData> = new DataSourceController<PatientRecord, NoData>(
    (x) => this.API.patientList.createListFilter(x),
    (y) => this.API.patientList.getListPage(y)
  );
  settings: PatientSearchContext;
  NumberOfResults = 0;
  NumberOfSelected  = 0;

  // Search Fields
  pharmacyId = 1;
  patientName = null;
  NHSNumber = null;
  phoneNumber = null;
  dateOfBirth = null;
  status = null;
  numberPerPage = 50;
  isPatientsLoading = false;
  patientFilter_patientName = '';
  patientFilter_status = 'All';
  patientFilter_NHSNumber = '';
  patientFilter_dateOfBirth = '';
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  toggleSearchBox = false;
  CustomerText = 'Patient';
  CustomerTextPlural = 'Patients';
  isSearched = false;
  numberOfPatients = 0;
  hasSearched$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  nhsNumberValidationMessage$:   BehaviorSubject<string> = null;
  dateOfBirthValidationMessage$: BehaviorSubject<string> = null;
  @ViewChildren(FilterControlDirective) appFilterItems: QueryList<FilterControlDirective>;
  @ViewChildren(SortControlDirective) appSorterItems: QueryList<SortControlDirective>;
  @ViewChild('statusMobile', {static: true}) statusMobile: ElementRef;
  @ViewChild('filterStatusMobile') filterStatusMobile: ElementRef;

  public nhsNumberValidator: (item: FilterControlDirective) => string = (item: FilterControlDirective): string => {
    return this.nhsNumberErrorMessage(item.GetValue());
  }
  public dateOfBirthValidator: (item: FilterControlDirective) => string = (item: FilterControlDirective): string => {
    return this.dateErrorMessage(item.GetValue());
  }

  constructor(
    private comms: CommsModule,
    private api: IAPIService,
    private configService: AppConfigService,
    private dialogs: DialogService,
    public dataService: DataService,
    private router: Router,
    ) {
      this.API = api;
  }
  ngOnInit() {
    this.config = this.configService.getConfig();
    if (this.config && this.config.features && this.config.features.ShowCustomerText) {
        this.CustomerText = 'Customer';
        this.CustomerTextPlural = 'Customers';
    }
    this.comms.setPage({pageTitle: `${this.CustomerTextPlural}`, page: 'patientlist', pageIcon: ''});
    this.datacontroller.datasource.setPageSize(20);
    this.datacontroller.datasource.isPageLoading$.subscribe(value => {
      this.isPatientsLoading = value;
      this.numberOfPatients = this.datacontroller.datasource._cachedData.length;
      if (value) {
        this.hasSearched$.next(true);
      }
    });
  }
  ngAfterViewInit() {
    this.setUpSanitizeCallbacks();
    this.datacontroller.InitialiseSortersIndirectly(this.appSorterItems);
    this.datacontroller.InitialiseFiltersIndirectly(this.appFilterItems);
  }
  setUpSanitizeCallbacks() {
    this.appFilterItems.forEach(item => {
      const itemName = item.GetName();
      switch (itemName) {
        case 'NHSNumber':
          item.sanitizeValueCallback = (value) => this.apiFormat_NhsNumber(value);
          this.nhsNumberValidationMessage$ = item.OnValidationError();
          break;
        case 'dateOfBirth':
          item.sanitizeValueCallback = (value) => this.apiFormat_DateOfBirth(value);
          this.dateOfBirthValidationMessage$ = item.OnValidationError();
          break;
        case 'Status':
          item.optionsPreprocessor = (filterOptions) => this.localizeStatusOptions(filterOptions);
          break;
      }
    });
  }


  searchPatient() {
    this.datacontroller.enableDataLoading();
  }

  patientNameMobileOnChange() {
    this.doFilter('PatientName', this.patientFilter_patientName);
  }

  statusMobileOnChange() {
    this.doFilter('Status', this.patientFilter_status);
  }

  doFilter(appFilterName, appFilterValue) {
    this.isSearched = true;
    this.appFilterItems.forEach(item => {
      if (item.GetName() === appFilterName) {
        item.applyFilter(appFilterValue);
      }
    });
  }

  invite() {
    if (this.config.features.InvitePatientPage) {
      this.router.navigateByUrl('/patients/invite');
    } else {
      const dialogConfig = new DialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.width = '1200px';
      dialogConfig.height = '690px';
      this.dialogs.OpenComponentDialog(PatientAddComponent, { ShowCancel: true }, dialogConfig).then((result) => {
        if (result != null) {
          console.log('New patient code was: ' + result.data.id);
        } else {
          console.log('Closed externally');
        }
      });
    }
  }
  displayNSHNumber(record): string {
    const NHSnumber = record && record.patientCode;
    return (NHSnumber && NHSnumber.replace(/^(.{3})(.{3})(.*)$/, '$1 $2 $3')) || '';
  }
  displayZipCode(record): string {
    return (record && record.zipCode) || '';
  }
  displayPostCode(record): string {
    return (record && record.zipCode && record.zipCode.replace(/ /g, '').replace(/(?=.{3}$)/, ' ')) || '';
  }
  displayPhone(record): string {
    return (record && UtilsService.formatPhone(record.phone)) || '';
  }
  displayPhoneByNPAFormat(record): string {
    const phone = record && record.phone;
    return (phone && phone.replace(/^(.{3})(.{4})(.*)$/, '$1 $2 $3')) || '';
  }
  formatGPName(record): string {
    return record && record.practiceName ? record.practiceName.toString().toLowerCase().replace(/\b\w/g, l => l.toUpperCase()) : '';
  }
  displayDOB(record): string {
    return (record && record.dateOfBirth) || '';
  }
  displayIcon(record): string {
    switch (record.carerStatus) {
      case CaregiverStatus.IsCaringFor:
        return 'people';
      case CaregiverStatus.IsCaredFor:
        return 'people_outline';
      default:
        return '';
    }
  }
  displayStatus(record, mobile = false): string {
    switch (record && record.status) {
      case MixedPatientStatus.Invite:
        return 'Invited';
      case MixedPatientStatus.Active:
        return 'Activated ';
      case MixedPatientStatus.RemoteSignupRequested:
        return mobile ? 'Signup Requested' : 'Remote signup requested';
      case MixedPatientStatus.RemoteSignupConfirmed:
        return mobile ? 'Signup Confirmed' : 'Remote signup confirmed ';
      default:
        return 'Invited';
    }
  }
  displayMedication(record): string {
    return record && record.medications ? record.medications.map( x => x.name).join(', ') : 'N/A';
  }
  getRowClass(record) {
    if (_.isEmpty(record)) {
      return 'Loading';
    }
    switch (record) {
      case record:
        return 'PatientList';
      default:
        return 'Loading';
    }
  }
  clearSearch() {
    this.appFilterItems.forEach(item => {
      item.resetValue();
      item.GetValue();
    });
    this.patientFilter_patientName = '';
    this.patientFilter_status      = 'All';
    this.patientFilter_NHSNumber   = '';
    this.patientFilter_dateOfBirth = '';
    this.datacontroller.datasource.empty();
  }
  getStatusColor(status) {
    switch (status) {
      case MixedPatientStatus.Invite:
        return '#0F577B';
      case MixedPatientStatus.Active:
        return '#069B4F';
      case MixedPatientStatus.RemoteSignupRequested:
      case MixedPatientStatus.RemoteSignupConfirmed:
        return '#FFA500';
      default:
        return '#222222';
    }
  }
  updateFilterStatusMobile(e) {
    this.statusMobile.nativeElement.innerHTML = e;
  }
  openSearchFilter() {
    this.toggleSearchBox = !this.toggleSearchBox;
  }
  displayCarerMode(record): string {
    switch (record.carerStatus) {
      case CaregiverStatus.IsCaringFor:
        return record && record.caringFor === CaregiverStatus.IsCaringFor ? `(Cares for: ${record.careeName})` : `(Cares for: ${record.caringFor} patients)`;
      case CaregiverStatus.IsCaredFor:
        return record && record.carerName ? `(Carer: ${record.carerName})` : '';
      default:
        return '';
    }
  }

  private nhsNumberErrorMessage(nhsNumber: string) {
    let errorMessage = null;
    if (nhsNumber !== null && nhsNumber !== '' && !UtilsService.validateNHS(nhsNumber)) {
      errorMessage = 'Invalid NHS number';
    }
    return errorMessage;
  }

  private dateErrorMessage(dateString: string) {
    // dateString has already been put throught he sanitizeValueCallback, and apiFormat_DateOfBirth()
    // returns null when dates are badly formatted and YYYY-MM-DD when dates are well formatted.
    if (dateString === null) {
      return 'Use dd/mm/yyyy:';
    }
    return null;
  }

  private localizeStatusOptions(options: FilterOptions): FilterOptions {
    // ensure the status search options match the values we use in the status column
    options.fieldOptions.forEach(item => {
      if (Object.keys(MixedPatientStatus).includes(item.key)) {
        // unfortunately typescript numeric enum members get a reverse mapping added, so
        // we still can't be sure whether the item "key" we have is the enum name or its number value.
        // displayStatus() is expecting the numeric value of the enum.
        const key = typeof(item.key) === 'number' ? item.key : MixedPatientStatus[item.key];
        item.label = this.displayStatus({status: key});
      }
    });
    return options;
  }

  private apiFormat_NhsNumber(nhsNumber: string | null | undefined): string | null | undefined {
    // Different to the autoFormat, because until backend SystemCode searching is region specific,
    // the frontend must ensure NHS number format will match how it's stored in the DB: digits only.
    if (typeof(nhsNumber) === 'string' && nhsNumber.length > 0) {
      nhsNumber = nhsNumber.replace(/[^0-9]/g, '');
    }
    return nhsNumber;
  }
  private apiFormat_DateOfBirth(dateString: string | null | undefined): string | null | undefined {
    // If a date is ambiguous, the backend will assume mm/dd/yyyy , so reformat the date
    // as an unambigious yyyy-mm-dd
    if (typeof(dateString) === 'string' && dateString.length > 0) {
      dateString = UtilsService.formatDate_DayMonthYear_to_YearMonthDay(dateString);
    }
    return dateString;
  }
  autoFormat_NhsNumber(nhsNumber: string): string {
    return UtilsService.formatNhsNumber(nhsNumber);
  }
  private autoFormat_DateOfBirth(dateString: string): string {
    if (typeof(dateString) === 'string' && dateString.length > 0) {
      dateString = UtilsService.formatDate_DayMonthYear(dateString);
    }
    return dateString;
  }

  // Reformat input fields once they lose focus
  // (this part is cosmetic as a search can be initiated before the input field losing focus, e.g. hitting return)
  onNhsNumberBlur() {
    this.patientFilter_NHSNumber = this.autoFormat_NhsNumber(this.patientFilter_NHSNumber);
  }
  onDateOfBirthBlur() {
    this.patientFilter_dateOfBirth = this.autoFormat_DateOfBirth(this.patientFilter_dateOfBirth);
  }
}
