 <div *ngFor="let prescreenResult of prescreenResults; let j = index">
    <div *ngIf="prescreenResult?.officeOnlyMode" style="font-weight: bold; padding: 5px">
      <span>{{!prescreenResult?.isCompleted ? "To be completed at the Pharmacy" : "Completed " + formatCompletedDate(prescreenResult?.completedDate)}}</span>
      <span *ngIf="prescreenResult?.startDate" style="float: right">{{"Appointment Date " + formatStartDate(prescreenResult?.startDate)}}</span>
    </div>
    <div class="detailSection">
      <div class="sectionHeader">Consumer Information</div>
      <table>
        <tr>
          <td width="50%">Name: <span class="first-name">{{prescreenResult?.firstName}}</span> <span>&nbsp;</span> <span class="last-name">{{prescreenResult?.lastName}}</span>
          </td>
          <td>Phone: {{prescreenResult?.contactNumber}}</td>
        </tr>
        <tr>
          <td colspan="2">Email Address: {{prescreenResult?.emailAddress}}</td>
        </tr>
        <tr>
          <td colspan="2">Address: {{prescreenResult?.address}}</td>
        </tr>
        <tr>
          <td colspan="2">Date of Birth: {{formatDateOfBirth(prescreenResult?.dateOfBirth)}}
          </td>
        </tr>
        <tr>
          <td colspan="2">Allergies:</td>
        </tr>
      </table>
    </div>
    <br/>
    <div>
      <div style="font-weight:bold">This checklist helps us decide about vaccinating you. Please fill in the following
        information for your pharmacist/doctor/nurse.
      </div>

      <div style="font-weight: bold; padding-top: 10px">Please indicate if:</div>

      <form [formGroup]="prescreenFormGroup">
        <fieldset style="border: none">
          <div *ngFor="let question of prescreenResult?.formField as Array;let i = index">
            <div class="form-question">
              <ng-container [ngSwitch]="i">
                <ng-container *ngSwitchDefault>
                  <p>
                    <span id="text_{{question?.question}}">{{question?.questionText}}</span>
                  </p>

                  <ng-container [ngSwitch]="question?.answer">
                    <p *ngSwitchDefault>
                      <label>Yes</label>
                      <input type="radio" name="text_{{question?.question}}" value="true"/>
                      <label>No</label>
                      <input type="radio" name="text_{{question?.question}}" value="false" />
                    </p>
                    <p *ngSwitchCase="'true'">
                      <label>Yes</label>
                      <input type="radio" name="text_{{question?.question}}" value="true" checked />
                      <label>No</label>
                      <input type="radio" name="text_{{question?.question}}" value="false" />
                    </p>
                    <p *ngSwitchCase="'false'">
                      <label>Yes</label>
                      <input type="radio" name="text_{{question?.question}}" value="true" />
                      <label>No</label>
                      <input type="radio" name="text_{{question?.question}}" value="false" checked/>
                    </p>
                    <ng-container *ngIf="i === 12">
                      <div *ngIf="prescreenResult?.officeOnlyMode">
                        <span style="float:left"><em>Please turn over to complete the form</em></span><span style="float:right">Page 1/3</span>
                      </div>
                      <div class="break"></div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="17">
                    <div *ngIf="prescreenResult?.officeOnlyMode" style="border: solid 1px black; min-height: 70px; padding: 5px; margin-top: 5px; font-size: 13px">
                      {{question?.answer}}
                    </div>
                </ng-container>
              </ng-container>
            </div>

          </div>
        </fieldset>
      </form>
      <div *ngIf="!prescreenResult?.officeOnlyMode" class="col-xs-offset-1 col-xs-10" style="background-color: #e5e5e5;width: 100%;margin: 0 0 15px 0;padding: 15px;">
          Before you are given your vaccination, you will be asked to complete a consent form, confirming that you
          <ol>
              <li>
                  I have been provided with, read and understood information regarding the possible side effects of each vaccine, and if I
                  have further questions, I will ask the immuniser prior to being vaccinated.
              </li>
              <li>I request to have each vaccine and understand that it is completely voluntary.</li>
              <li>I have been informed of, and agree to pay the fees.</li>
              <li>I consent to the pharmacy or service provider issuing a copy of your vaccination statement to your nominated primary healthcare provider.</li>
              <li>I agree to remain in the general vicinity of the pharmacy for 15 minutes following vaccination to enable the provision of medical assistance or treatment if required.</li>
              <li>I consent to the provision of emergency care if required, and authorise the pharmacy or service provider to access medical care on your behalf as required, and understand that you are responsible for any costs associated with any emergency care that may be provided.</li>
          </ol>
      </div>
    </div>
    <div *ngIf="prescreenResult?.officeOnlyMode">
      <div class="detailSection" style="font-size:8px">
        <div class="sectionHeader">Important Information</div>
        <p>
          <b>What are the possible side effects from the influenza vaccine?</b><br/>
          Common side effects following seasonal influenza vaccination include tiredness, muscle aches and pain, redness,
          itching or swelling at the injection site. Occasionally, people can develop a slight fever 1-2 days after the
          injection. These side effects are usually mild and resolve quickly without any treatment. If a high temperature
          above 38.5°C persists, contact your doctor or seek medical assistance.
        </p>

        <p>
          <b>How long after the influenza vaccine can these reactions occur?</b><br/>
          Generally, reactions occur within the first 24 to 48 hrs. These reactions commonly begin 6 to 12 hours
          vaccination.
        </p>

        <p>
          <b>If I get an unusual side effect after influenza vaccine, where can I report it?</b><br/>
          It is easiest to contact the pharmacy where you received the vaccine and inform them, and the pharmacist will be
          able to give you advice and answer your questions. Alternatively, you can report side effects to your doctor, or
          to the Therapeutic Goods Administration (TGA) Adverse Medicine Events Line on 1300 134 237.<br/>
          Severe allergic reaction (anaphylaxis) is a rare side effect of vaccines. It occurs suddenly, usually within 15
          minutes but can occur within hours of vaccine administration. Early signs of anaphylaxis include significant
          redness and/or itching of the skin, swelling of the face, lips or tongue, and breathing difficulties.<br/>
          The person who administers your vaccine willrequire you to stay for 15 minutes after your vaccination to monitor
          you for any immediate allergies or side effects. If you have any concerns or questions, please speak to your
          doctor, nurse or pharmacist.
        </p>
      </div>
      <br/>

      <div class="detailSection">
        <div class="sectionHeader">Consent</div>
        <ol>
          <li>
            I have been provided with, read and understood information regarding the possible side effects of each
            vaccine, and if I
            have further questions, I will ask the immuniser prior to being vaccinated.
          </li>
          <li>I request to have each vaccine and understand that it is completely voluntary.</li>
          <li>I have been informed of, and agree to pay the fees.</li>
          <li>I consent to the pharmacy or service provider issuing a copy of your vaccination statement to your nominated
            primary healthcare provider.
          </li>
          <li>I agree to remain in the general vicinity of the pharmacy for 15 minutes following vaccination to enable the
            provision of medical assistance or treatment if required.
          </li>
          <li>I consent to the provision of emergency care if required, and authorise the pharmacy or service provider to
            access medical care on your behalf as required, and understand that you are responsible for any costs
            associated with any emergency care that may be provided.
          </li>
          <li>I have indicated to the immuniser whether or not I am unwell today.</li>

        </ol>
        <table width="100%">
          <tr>
            <td width="50%">Signature:</td>
            <td>Date:</td>
          </tr>
        </table>
      </div>

      <div class="page-break" style="padding-top:5px;text-align:right">Page 2/3</div>
      <div class="break"></div>

      <div class="detailSection">
        <div class="officeSection">FOR OFFICE USE ONLY</div>
        <div class="sectionHeader">Immuniser Details</div>
        <table>
          <tr>
            <td width="50%">Pharmacist name:</td>
            <td>Contact number:</td>
          </tr>
          <tr>
            <td>Pharmacy Name and Address: <br/>{{prescreenResult.pharmacyName}}<br/>{{prescreenResult.pharmacyAddress}}</td>
            <td>Date:</td>
          </tr>
          <tr>
            <td colspan="2">Signature:</td>
          </tr>
        </table>
        <div>
          <table style="border-collapse: collapse;">
            <tr>
              <td style="padding:0;">
                <div class="sectionHeader">Influenza vaccination history</div>
                <table>
                  <tr>
                    <td>
                      When did the consumer last receive an influenza vaccine?
                      <ul class="radioList">
                        <li>Last year</li>
                        <li>2-3 years</li>
                        <li>3-5 years</li>
                        <li>&gt; 5 years</li>
                        <li>Never</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Is the consumer at high risk of influenza or complications related to influenza?
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Does the consumer belong to any of the below categories?
                      <ul class="radioList">
                        <li>Aboriginal/Torres Strait Islander ≥ 15 years</li>
                        <li>Pregnant</li>
                        <li>COPD, severe asthma, lung or heart disease, diabetes, low immunity</li>
                        <li>≥ 65 years</li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </td>
              <td style="padding:0;">
                <div class="sectionHeader">Current influenza vaccine administration</div>
                <table>
                  <tr>
                    <td>Date of vaccination:</td>
                  </tr>
                  <tr>
                    <td>Time:</td>
                  </tr>
                  <tr>
                    <td>Name of vaccine (including Brand):</td>
                  </tr>
                  <tr>
                    <td>Dose:</td>
                  </tr>
                  <tr>
                    <td>Batch number:</td>
                  </tr>
                  <tr>
                    <td>
                      Injection site:
                      <ul class="radioList">
                        <li>Right arm</li>
                        <li>Left arm</li>
                        <li>Right leg</li>
                        <li>Left leg</li>
                        <li>Other</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Date the next vaccination is due<br/>(if applicable):</td>
                  </tr>
                  <tr>
                    <td>
                      Was the vaccine provided/supplied through the National Immunisation Program?
                      <ul class="radioList">
                        <li>Yes</li>
                        <li>No</li>
                      </ul>
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
          </table>
        </div>
        <div class="detailSection" style="clear:both">
          <div class="sectionHeader">Adverse events</div>
          Was an adverse event observed in the time period the consumer remained in the pharmacy post vaccination?
          <ul class="radioList">
            <li>Yes</li>
            <li>No</li>
          </ul>

          Pharmacist Immunisers must report any Adverse Event Following Immunisation (AEFI) to the Surveillance of Adverse
          Events following Vaccination in the Community (SAEFVIC). The SAEFVIC requirements for reporting are available
          from:
          <br/>
          https://www2.health.vic.gov.au/public-health/immunisation/adverse-events-following-immunisation-reporting
        </div>
        <div class="detailSection">
          <div class="sectionHeader">Continuity of care</div>
          Was the consumer’s vaccination record sent to the primary care provider and any other relevant medical
          professional suggested by the consumer (e.g. obstetrician)?
          <ul class="radioList">
            <li>Yes</li>
            <li>No</li>
          </ul>
        </div>
      </div>
      <div class="page-break" style="padding-top:5px;text-align:right">Page 3/3</div>
    </div>
   <div class="break" *ngIf="j < prescreenResults.length - 1"></div>
  </div>
