import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from 'src/app/utility/utilsservice/loader.services';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(public loaderService: LoaderService) { }
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (req.url.includes('/PendingTask/CreateFilter')
          || req.url.includes('/Patient/List/CreateFilter')
          || /\/Pharmacy\/\d+\/practice/.test(req.url)) {

        // console.log("request began: " + req.url);
        this.loaderService.show();

        return next.handle(req).pipe(
          finalize(() => {
            // console.log("request finalized: " + req.url);

            if (req.url.includes('/CreateFilter')) {
              // A call to CreateFilter is followed by a call to GetFilter (to obtain the data), so I
              // suspect this timer was put here to keep the spinner spinning until GetFilter was called... maybe.
              // LoaderInterceptor was later made to trigger only on a CreateFilter, to limit the spinner
              // it to message loading requests.
              // This code is why the spinner sometimes dissapears before patient search results are ready.
              setTimeout(() => { this.loaderService.hide(); }, 1000);
            } else {
              this.loaderService.hide();
            }
          })
        );
      }
      return next.handle(req);
    }
}
