import { Component, ViewEncapsulation, EventEmitter, Output, Input, ChangeDetectionStrategy } from '@angular/core';

import { IAPIService } from '../../../api/iapi.service';
import { IDialogEmbeddable } from 'src/app/utility/base-dialog/dialog.service';
import { PracticeDetails } from 'src/app/api/ApiRecordTypes/PracticeRecord';
import { Router } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-surgerysummary',
  templateUrl: './surgerysummary.component.html',
  styleUrls: ['./surgerysummary.component.css'],
})
export class SurgerySummaryComponent implements IDialogEmbeddable<PracticeDetails, boolean> {
  @Input() public data: PracticeDetails = new PracticeDetails();
  @Output() public OnComplete: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(
    private api: IAPIService,
    public router: Router
  ) {
  }

  public setData(data: PracticeDetails): void {
    this.data = data;
  }
  deleteSurgery() {
    this.api.deletePractice(this.data.pharmacyId, this.data.practiceId).subscribe(result => {
      if (result !== null) {
        this.OnComplete.emit(true);
      }
    });
  }
  cancelDeleteSurgery() {
    this.OnComplete.emit(null);
  }
}
