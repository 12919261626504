import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/utility/utilsservice/loader.services';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent {
  mode = 'indeterminate';
  value = 50;
  @Input() showOverlay: false;
  @Input() loadPageRequest: boolean;
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  color: any;
  constructor(private loaderService: LoaderService) {}
}
