  <div
    *ngIf="displayScreen === 'confirmPatientDetails' && customizedSiteConfig === 'DayLewis' && screenMode==='InvitePatient'"
    id="patientDetails"
    class="patient-details"

  >
    <div class="patient-consent">
      <div [hidden]="true">{{ patientSearch$ | async }}</div>
      <div class="patient-name">
        {{ displayPatientName() }} 
      </div>
      <div *ngIf="patientSearch.identityPrivate.invitedDate !== null && ((isVerifiedSCRConsent | async) || customizedSiteConfig === 'DayLewis')" class="patient-sent">
        An invite was sent to this patient on {{invitedDate() |  date: 'dd-MM-yyyy' }}.  Send a new invite?
      </div>
      <div class="patient-item">
        <div class="patient-label">
          NHS
        </div>
        <div class="patient-info">
          {{ NHSnumber() }}
        </div>
      </div>
      <div class="patient-item">
        <div class="patient-label">
          DoB
        </div>
        <div class="patient-info">
          {{ displayDOB() | date: "dd/MM/yyyy" }}
        </div>
      </div>
      <div class="patient-item">
        <div
          [className]="
            !validPhone ? 'patient-label field-required' : 'patient-label'
          "
        >
          Mobile<BR/>(UK Only)
        </div>
        <div class="patient-info">
          <div *ngIf="(isVerifiedSCRConsent | async) === false && customizedSiteConfig !== 'DayLewis'">{{ MobileNumber }}</div>
          <div>
            <input *ngIf="(isVerifiedSCRConsent | async) || customizedSiteConfig === 'DayLewis'" id="phoneNumberInput" [(ngModel)]="MobileNumber" (blur)="phoneFormat()" class="input-box" maxlength="12" placeholder="07xx xxxxxx"/>
            <div class="mobile-number-note">
              <p *ngIf="customizedSiteConfig === 'DayLewis'">Changing details here will not update PMR</p>
            </div>
          </div>
        </div>

        <div class="phone-info-page" *ngIf="(isVerifiedSCRConsent | async) || customizedSiteConfig === 'DayLewis'">
            <div *ngIf="!validPhone" class="field-required">Mobile number is required.</div>
            <div *ngIf="errorMessage$ | async" class="field-required">{{errorMessage$ | async}}</div>
        </div>
      </div>
      <div class="patient-item">
        <div class="patient-label">
          Address
        </div>
        <div class="patient-info">
          {{ address() }}
        </div>
      </div>
      <div class="patient-item">
        <div class="patient-label">
          Patient's GP
        </div>
        <div class="patient-info">
          {{ displayGP() }}
        </div>
      </div>

      <div class="checkbox-area" *ngIf="customizedSiteConfig === 'DayLewis' && screenMode==='InvitePatient'">
        <div class="checkbox-info">
          <div class="patient-info">
            <p [class]="showConsentErr ? 'patient-label field-required' : 'patient-label'">
              <b>I have checked that:</b>
            </p>
            <mat-checkbox class="patient-checkbox" color="primary" [(ngModel)]="verifyPatientConsent">Patient has nominated this pharmacy on the NHS electronic prescription service. <br/>Patient has consented to receive this invite.</mat-checkbox>
          </div>
          <div *ngIf="showConsentErr"
          class="checkbox-invalid">
            Patient consent is required
          </div>
          <div class="invite-patient-btn-wrapper">
            <button mat-raised-button [disabled]="!isInviteEnabled" [style.pointer-events]="disableInviteButton" (click)="addNewPatient()" name="invite">
              INVITE PATIENT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
  *ngIf="displayScreen === 'confirmPatientDetails' && (screenMode === 'InviteCarer' ||  customizedSiteConfig === 'NPA')"
  id="patientDetails"
  class="patient-details">
  <div class="patient-consent">
    <div [hidden]="true">{{ patientSearch$ | async }}</div>
    <div class="patient-name">
      {{ displayPatientName() }}
    </div>
    <div *ngIf="patientSearch.identityPrivate.invitedDate !== null" class="patient-sent">
      An invite was sent to this patient on {{invitedDate() |  date: 'dd-MM-yyyy' }}.  Send a new invite?
    </div>
    <div class="patient-items">
        <div class="patient-item">
          <div class="patient-label">
            NHS:
          </div>
          <div class="patient-info">
            {{ NHSnumber() }}
          </div>
        </div>
        <div class="patient-item">
          <div class="patient-label">
            DoB:
          </div>
          <div class="patient-info">
            {{ displayDOB() | date: "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="patient-item">
          <div class="patient-label">
            Sex:
          </div>
          <div class="patient-info">
            {{ displaySex() }}
          </div>
          <span class="info-icon material-icons"
          mat-raised-button #tooltip="matTooltip"
          matTooltip="Sex assigned at birth"
          matTooltipPosition="right"
          >
            info
            </span>
        </div>
        <div class="patient-item">
          <div class="gp-label">
            GP:
          </div>
          <div class="patient-info">
            {{ displayGP() }}
          </div>
        </div>
        <div class="patient-item patient-address-spanning">
          <div class="patient-label-expand">
            Address:
          </div>
          <div class="patient-info">
            {{ address() }}
          </div>
        </div>
      </div>
      <div class="patient-items">
        <div class="patient-item" *ngIf="screenMode === 'InvitePatient'">
          <div [className]="!validPhone ? 'patient-label-expand field-required' : 'patient-label-expand'" *ngIf="screenMode === 'InvitePatient'">
            Mobile:<BR/>(UK Only)
          </div>
          <div class="patient-info">
            <div class="mobile-edit-panel">
              <div class="mobile-edit-row">
                <input  id="phoneNumberInput" [(ngModel)]="MobileNumber" (blur)="onMobileBlur()" (focus)="isTyping()"  class="mobile-input" maxlength="40" placeholder="07xx xxxxxx"/>
                <button *ngIf="!validPhone || isTypingInMobileField" class="btn btn-primary btn-mobile-update" (click)="onMobileBlur()">Update</button>
                <div *ngIf="validPhone && !isTypingInMobileField"><mat-icon class="success-icon">check_circle</mat-icon></div>
              </div>
              <div class="phone-warning-dialog">
                <div *ngIf="!validPhone && MobileNumber" class="field-required">Mobile number is invalid.</div>
                <div *ngIf="!validPhone && !MobileNumber" class="field-required">Mobile number is required.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
