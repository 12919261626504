import { Inject, Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {first, map, take} from 'rxjs/operators';
import { APIService } from 'src/app/api/api.service';
import { EventPreferenceModel } from 'src/app/api/ApiRecordTypes/EventPreferenceModel';
import { EventBookingModel, EventResourceModel } from 'src/app/api/ApiRecordTypes/EventResourceModel';
import { EventResourceResult } from 'src/app/api/ApiRecordTypes/EventResourceResult';
import { EventServiceModel } from 'src/app/api/ApiRecordTypes/EventServiceModel';
import { PharmacyModel } from 'src/app/api/ApiRecordTypes/PharmacyRecord';
import { EventBookedSlotModel } from '../../api/ApiRecordTypes/EventBookingResult';
import { NoData } from '../../api/ApiRecordTypes/OperationResults';
import { PrescreenFormModel } from '../../api/ApiRecordTypes/PreScreenFormModel';
import { CalendarAccessTokenModel } from '../../api/ApiRecordTypes/CalendarAccessTokenModel';
import { PatientSearchModel } from '../../api/ApiRecordTypes/PatientSearchModel';
import { HSHCoreStructurePatientPatientModel } from 'src/app/api/ApiRecordTypes/swaggerSchema-hsh-v1';
import {DataService} from '../../utility/dataservice/data.service';

@Injectable()
export class CalendarEventService {
  // #region Properties
  private eventGuid = 0;

  private _eventResources = new BehaviorSubject<EventResourceResult>(null);

  private _eventPreferenceResources = new BehaviorSubject<EventPreferenceModel>(null);

  private _pharmacyInfo: PharmacyModel;

  public toastSubject = new Subject<string>();

  public loadingSubject = new BehaviorSubject<boolean>(false);

  // #endregion

  // #region Constructor
  // eslint-disable-next-line no-useless-constructor
  public constructor (
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any,
    private dataService: DataService,
    protected apiService: APIService) {
  }

  // #endregion

  // #region Methods
  public getBusinessHours () {
    return this._pharmacyInfo.fields.open.split('|').map(hourAndDay => {
      const hourAndDayArray = hourAndDay.split('#');
      const timeArray = hourAndDayArray[1].split('-');

      const daysOfWeek = [this.matDayToDow(hourAndDayArray[0])];
      const startTime = timeArray[0];
      const endTime = timeArray[1];
      return { daysOfWeek, startTime, endTime };
    });
  }

  private matDayToDow (day: string): number {
    switch (day) {
      case 'mon':
        return 1;
      case 'tue':
        return 2;
      case 'wed':
        return 3;
      case 'thur':
        return 4;
      case 'fri':
        return 5;
      case 'sat':
        return 6;
      case 'sun':
        return 0;
    }
  }

  // #region Pharmacy info
  public setPharmacyInfo (info: PharmacyModel): void {
    this._pharmacyInfo = info;
  }

  public getPharmacyInfo (): PharmacyModel {
    return this._pharmacyInfo;
  }
  // #endregion

  // #region Resource
  // Load all resources (calendar rooms and calendar layout setting) in resolver
  public loadResources (pharmacyId?: number): Observable<EventResourceResult> {
    const pharmacyID = this._pharmacyInfo.id || pharmacyId;
    return this.apiService.retrieveResources(pharmacyID).pipe(
      map(resourceSetting => {
        this._eventResources.next(resourceSetting);
        const eventPreferences = new EventPreferenceModel(
          this._pharmacyInfo.id,
          resourceSetting.calendarLayout,
          resourceSetting.smsConfirmation,
          resourceSetting.smsReminder
        );
        this._eventPreferenceResources.next(eventPreferences);
        // return resourceSetting.resources
        //   ? resourceSetting.resources.map(resource => ({id: resource.id, title: resource.name}))
        //   : [];
        return resourceSetting;
      })
    );
  }

  public addResources (event: EventResourceModel) {
    const resources = this._eventResources.getValue();
    resources.resources.push(event);
    this._eventResources.next(resources);
  }

  public removeResources (resourceId: number) {
    const resources = this._eventResources.getValue();
    resources.resources = resources.resources.filter(resource => resource.id !== resourceId);
    this._eventResources.next(resources);
  }

  public getCachedResources (): Observable<EventResourceResult> {
    return this._eventResources.asObservable();
  }

  public getCachedResourcesAsPromise (): Promise<EventResourceResult> {
    const resources = this._eventResources.getValue();
    return new Promise((resolver) => {
      return resolver(resources);
    });
  }

  // get calendar layout and sms options
  public getEventResourcesPreference (): Observable<EventPreferenceModel> {
    return this._eventPreferenceResources.asObservable();
  }

  public getEventResourcesPreferenceAsPromise (): Promise<EventPreferenceModel> {
    const eventPreferenceResources = this._eventPreferenceResources.getValue();

    return new Promise((resolve) => {
      resolve(eventPreferenceResources);
    });
  }

  // Update calendar layout and sms options
  public updateEventResourcesPreference (layout, smsConfirmation, smsReminder): Observable<void> {
    const eventPreference = new EventPreferenceModel(this._pharmacyInfo.id, layout, smsConfirmation, smsReminder);
    this._eventPreferenceResources.next(eventPreference);

    return this.apiService.updateEventPreferences(eventPreference);
  }

  // Creates a new event resource.
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public createsNewResource (resourceName: string): Observable<any> {
    return this.apiService.createsNewEventResource(this._pharmacyInfo.id, resourceName);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public updateResources (model: EventResourceModel): Observable<any> {
    const resources = this._eventResources.getValue();
    const indexEditResource = resources.resources.map(resource => resource.id).indexOf(model.id);
    resources.resources[indexEditResource] = model;
    if (!model.pharmacyId) {
      model.pharmacyId = this._pharmacyInfo.id;
    }
    this._eventResources.next(resources);
    return this.apiService.updateEventResources(model);
  }

  public getResourceById (resourceId: number): Observable<EventResourceResult> {
    return this.apiService.retrieveResourceById(this._pharmacyInfo.id, resourceId);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public deleteEventResourceById (resourceId: number): Observable<any> {
    return this.apiService.deleteEventResourceById(this._pharmacyInfo.id, resourceId);
  }
  // #endregion

  // #region Bookings

  // Get all event with resourceId = 0, get events by resourceId
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public retrieveEventBookings (startDate: string, endDate: string, resourceId: number): Observable<any> {
    return this.apiService.retrieveEventBookings(this._pharmacyInfo.id, startDate, endDate, resourceId, true);
  }

  public retrieveAnEventBookings (eventId: number): Observable<EventBookingModel> {
    return this.apiService.retrieveAnEventBookings(this._pharmacyInfo.id, eventId);
  }

  // Create and update event
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public createOrEditEventBooking (eventBookingBody: EventBookingModel): Observable<any> {
    eventBookingBody.pharmacyId = this._pharmacyInfo.id;
    return this.apiService.createOrEditEventBooking(eventBookingBody);
  }

  public retrieveAvailableTimeslotNewBooking (resourceId: number,
    serviceId: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
    date: string, noOfDays: number): Observable<EventBookedSlotModel[]> {
    return this.apiService.retrieveAvailableTimeslotNewBooking(this._pharmacyInfo.id, resourceId, serviceId, date, 1);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public deleteBookingById (resourceId: number, bookingId: number): Observable<any> {
    return this.apiService.deleteBookingById(this._pharmacyInfo.id, resourceId, bookingId);
  }
  // #endregion

  // #region Services
  public creatANewService (payload: EventServiceModel) {
    payload.pharmacyId = this._pharmacyInfo.id;
    payload.supplemental = '';
    return this.apiService.createANewService(payload);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public updateServiceAsync (model: EventServiceModel): Observable<any> {
    model.pharmacyId = this._pharmacyInfo.id;
    return this.apiService.updateAService(model);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public deleteServiceAsync (serviceId: number): Observable<any> {
    const pharmacyId = this._pharmacyInfo.id;
    return this.apiService.deleteServiceAsync(pharmacyId, serviceId);
  }

  public retrieveServices (): Observable<EventServiceModel[]> {
    return this.apiService.retrieveServices(this._pharmacyInfo.id);
  }

  public retrieveServicesIncludeDeleted (): Observable<EventServiceModel[]> {
    return this.apiService.retrieveServices(this._pharmacyInfo.id, true);
  }

  public updateServiceLinkedToResource (resourceId: number, model: number[]): Observable<NoData> {
    return this.apiService.updateServiceLinkedToResource(this._pharmacyInfo.id, resourceId, model);
  }

  public printPreScreenFormAsync (bookingCode: string): Observable<PrescreenFormModel[]> {
    return this.apiService.printPreScreenFormAsync(this._pharmacyInfo.id, bookingCode);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public retrieveAPatientAsync (patientId: number): Observable<any> {
    return this.apiService.retrieveRelatedPatientAsync(patientId);
  }

  public getCalendarTokenAsync (resetStaffUrl = false): Promise<CalendarAccessTokenModel> {
    return this.apiService.getCalendarTokenAsync(this._pharmacyInfo.id, resetStaffUrl).pipe(take(1)).toPromise();
  }

  public retrieveListPatientAsync (): Observable<HSHCoreStructurePatientPatientModel[]> {
    const patientSearchModel = new PatientSearchModel(this._pharmacyInfo.id);
    return this.apiService.retrievePatientListAsync(patientSearchModel);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public retrievePharmacySettings (): Observable<any> {
    const accountSettings = JSON.parse(this.sessionStorage.getItem('accountSettings'));
    const pharmacyId = accountSettings ? accountSettings.id : +this.sessionStorage.getItem('pharmacyId');
    if (this._pharmacyInfo) {
      return of('INVALID');
    }
    return this.apiService.getPharmacySettings(pharmacyId).pipe(first());

  }

  public setToast (msg: string) {
    this.toastSubject.next(msg);
  }

  public getToast (): Observable<string> {
    return this.toastSubject.asObservable();
  }

  public setLoading () {
    this.loadingSubject.next(true);
  }

  public stopLoading () {
    this.loadingSubject.next(false);
  }

  public loadingState (): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }
  // #endregion
}
