<div *ngIf="!showOverlay && isLoading | async" class="overlay">
  <mat-spinner
    class="spinner"
    [color]="color"
    [mode]="mode"
    [value]="value"
    [diameter]="32"
  >
  </mat-spinner>
</div>

<div *ngIf="showOverlay && isLoading | async" class="overlay-wrapper"> 
  <div class="overlay">
    <mat-spinner
      class="spinner"
      [color]="color"
      [mode]="mode"
      [value]="value"
      [diameter]="32"
    >
    </mat-spinner>
  </div>
</div>
