import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PatientRecord} from 'src/app/api/ApiRecordTypes/PatientRecord';
import {PatientModel} from 'src/app/api/ApiRecordTypes/PendingTaskRecord';
import {IAPIService} from 'src/app/api/iapi.service';
import {AppConfigService} from 'src/app/app-config.service';
import {BasicDialogComponent} from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import {DialogService} from 'src/app/utility/base-dialog/dialog.service';
import {CommsModule} from 'src/app/utility/comms/comms.module';
import {DataService} from 'src/app/utility/dataservice/data.service';
import {MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {MatPaginator } from '@angular/material/paginator';
import {CalendarDialogData} from '../../models/view-models/layout-calendar-dialog.view-model';
import {CalendarEventService} from '../../services/calendar-event.service';
import {tap} from 'rxjs/operators';
import {cloneDeep} from 'lodash';
import {UntypedFormControl} from '@angular/forms';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
class PatientSearchText {
  //#region Properties
  public static cancelText = 'Cancel';

  public static acceptText = 'Accept';

  public static addBookingCalendar = 'Add Booking';

  public static editBookingCalendar = 'Edit Booking';
  //#endregion
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum MixedPatientStatus {
  Invite = 0,
  RemoteSignupRequested = 1,
  RemoteSignupConfirmed = 2,
  Active = 3,
}

@Component({
  selector: 'app-patient-search-dialog',
  templateUrl: './patient-search-dialog.component.html',
  styleUrls: ['./patient-search-dialog.component.css']
})
export class PatientSearchDialogComponent extends BasicDialogComponent {
  //#region Properties

  public pageSizeOptions = [10, 25, 50, 100];

  public pageSize = this.pageSizeOptions[0];

  public pageLength;

  public selectedRowId = 0;

  public selectedPatient;

  public displayedColumns: string[] = ['firstName', 'lastName', 'email', 'mobile'];

  public dataSource: MatTableDataSource<PatientRecord[]>;

  private _paginator: MatPaginator;

  @ViewChild('paginator')
  public set paginator (paginator: MatPaginator) {
    this._paginator = paginator;
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort)
  public set sort (sort: MatSort) {
    this.dataSource.sort = sort;
    if (!sort) {return; }
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      if (!data[`${sortHeaderId}`]) {
        return '';
      }
      return data[`${sortHeaderId}`].toLocaleLowerCase();
    };
  }

  public pageSizeOptionControl = new UntypedFormControl('');

  //#endregion

  //#region Constructor
  public constructor(
    protected comms: CommsModule,
    protected api: IAPIService,
    protected configService: AppConfigService,
    protected dialogs: DialogService,
    public dataService: DataService,
    protected router: Router,
    protected changeDetector: ChangeDetectorRef,
    protected calendarService: CalendarEventService
  ) {
    super(router);
    this.pageSizeOptionControl.patchValue(10);
    this.headerTitle = 'Select Patient';
    this.acceptButtonTitle = 'Select';
  }
  //#endregion

  //#region Life cycle

  //#endregion

  //#region Methods

  public setData(data: CalendarDialogData) {
    super.setData(data);
    const patientListData = data.settings.patientList;
    if (!patientListData) {
      return;
    }
    this.pageLength = patientListData.length;
    const patientRecords = patientListData.map(patient => {
      const patientModel = new PatientModel();
      patientModel.id = patient.id;
      patientModel.firstName = patient.identityPrivate.firstName;
      patientModel.lastName = patient.identityPrivate.lastName;
      patientModel.email = patient.identityPrivate.email;
      patientModel.phone = patient.identityPrivate.phone;
      return patientModel;
    }).sort(function (a, b) {
      return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
    });
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    this.dataSource = new MatTableDataSource<PatientRecord[]>(patientRecords as any);
  }

  public handleChoosePatient(row: PatientModel) {
    this.selectedPatient = row;
    this.selectedRowId = row.id;
  }

  public handleAccept() {
    if (!this.selectedPatient) {
      return;
    }
    if (this.selectedPatient && this.selectedPatient.phone && this.selectedPatient.email) {
      const patientSelected = this.data.settings.patientList.find(patient => patient.id === this.selectedPatient.id);
      this.OnComplete.emit(patientSelected);
    }
    const getPatientDetail = this.calendarService.retrieveAPatientAsync(this.selectedPatient.id)
      .pipe(
        tap(value => {
          if (value) {
            const carerInfo = value.related[0];
            const patientSelected = this.data.settings.patientList.find(patient => patient.id === this.selectedPatient.id);
            const selectedPatient = cloneDeep(patientSelected);
            selectedPatient.identityPrivate.email = carerInfo.identity.email;
            selectedPatient.identityPrivate.phone = carerInfo.identity.phone;
            this.OnComplete.emit(selectedPatient);
          }
        })
      )
      .subscribe();
    this._subscription.add(getPatientDetail);
  }

  public handleFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public handlePageChangeEvent(event: any) {
    console.log(event);
  }

  public handlePageSizeOptionsChange (pageSize: number) {
    this._paginator._changePageSize(pageSize);
    this.pageSize = pageSize;
  }


  public handlePageChange (numPage: {page: number, itemsPerPage: number}) {
    if (!this._paginator) { return; }
    this._paginator.pageIndex = numPage.page - 1;
    this._paginator.page.next({
      pageIndex: this._paginator.pageIndex,
      pageSize: this.pageSize,
      length: this.pageLength
    });
  }

  //#endregion
}
