import { Component, OnInit, Injectable, EventEmitter, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { IDialogEmbeddable } from '../dialog.service';

export enum DialogResponse {
  none, // used to remove a button
  accept, // Info dialog accepted
  cancel, // Confirmation dialog denied
  confirm, // Confirmation dialog confirmed
}

export class DialogData {
  success: DialogResponse;
  fail: DialogResponse;
  successString: string;
  failString?: string = null;
  title?: string = null;
  bodyText: string[];
  HasCancel = false;
}

@Injectable({
  providedIn: 'root',
})
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-general-dialog',
  templateUrl: './general-dialog.component.html',
  styleUrls: ['./general-dialog.component.css']
})
export class GeneralDialogComponent implements OnInit, IDialogEmbeddable<DialogData, DialogResponse> {
  public data: DialogData;
  public OnComplete: EventEmitter<DialogResponse> = new EventEmitter<DialogResponse>(true);

  BodyText = ['Nothing here yet'];
  Title = 'Title not yet available to configure';
  CancelText = 'Cancel';
  AcceptText = 'Accept';
  HasCancel = false;
  HasAccept = true;
  
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  public setData(data: DialogData) {
    this.data = data;
  }
  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    this.BodyText = this.data.bodyText;
    this.CancelText = this.data.failString;
    this.AcceptText = this.data.successString;
    this.HasCancel = (this.data.fail !== null);
    this.HasAccept = (this.data.success !== null);
  }

  HandleCancel() {
    this.OnComplete.emit(this.data.fail);
  }
  HandleAccept() {
    this.OnComplete.emit(this.data.success);
  }
}

