import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../app-config.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint insists 'The selector should be kebab-cased and include a dash (https://angular.io/guide/styleguide#style-05-02)'
  // As far as I can see, it complies with this.
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'footer[app-footer]',
  templateUrl: './footer.component.html'
})
/* tslint:enable:component-selector */
export class FooterComponent {
  warning = '';
  version = '';
  copyrightYear = '';

  constructor(private config: AppConfigService) {
    this.warning = environment.contextWarning;
    this.version = window['appversion'] + ' ' + environment.version;
    this.copyrightYear = (new Date()).getFullYear().toString();
  }
}
