
<div [ngClass]="{'sidebar':true, 'responsive': (showSidebar$ | async)}">
    <div class="brand desktop-brand">
      <div class="title-brand">
          PlusOne <br />
          <span class="small">by MedAdvisor</span>
      </div>
    </div>
    <div class="sidebar-container">
        <app-menu></app-menu>
        <app-card-status></app-card-status>
        <div class="profile-avatar" >
            <div class="profile-content">
                <div class="img-logo">
                    <img class="img" appDefault="../../../assets/images/fallbackPlusOneLogo.svg"
                    [src]="this.settingService.setting && this.settingService.setting.logoURL" />
                </div>
                <div class="pharmacy-name">{{this.settingService.setting && this.settingService.setting.name}}</div>
                <div class="pharmacy-info">{{this.settingService.setting && this.settingService.setting.address && this.settingService.setting.address.line1}}</div>
                <div class="pharmacy-info">{{this.settingService.setting && this.settingService.setting.address && this.settingService.setting.address.line2}}</div>
                <div class="pharmacy-info">{{formatAddress(this.settingService.setting)}}</div>
            </div>               
        </div>
    </div>
</div>
