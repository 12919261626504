import { PracticeModel } from './PendingTaskRecord';
import { PharmacyModel } from './PharmacyRecord';

export class PracticePharmacyCommunicationChannels {
  usePracticeEndpoint: boolean;
  chosenMethod: string;
  endpoints: [
    {
      method: string,
      target: string
    }
  ];
}

export class PracticeRecord {
  communicationChannels: PracticePharmacyCommunicationChannels;
  id: number;
  practiceId: number;
  pharmacyId: number;
  preferences: {
    hoursuntilscriptoverdue: string,
    notes: string
  };
  updateDate: Date;
  practice: PracticeModel;
  pharmacy: PharmacyModel;
}

export class PracticeDetails {
  communicationChannels: PracticePharmacyCommunicationChannels;
  id = 0;
  practiceId = 0;
  pharmacyId = 0;
  preferences = {
    hoursuntilscriptoverdue: '',
    notes: ''
  };
  updateDate = null;
  pharmacy = {
    id: 0,
    systemCode: '',
    systemKey: '',
    name: '',
    phone: '',
    email: '',
    fax: '',
    address: {
      line1: '',
      line2: '',
      suburb: '',
      state: '',
      country: '',
      postCode: '',
      latitude: '',
      longitude: ''
    },
    logoURL: '',
    externalUpdateDate: null,
    fields: null,
    skin: '',
  };
  practice = {
    id: 0,
    systemCode: '',
    systemKey: '',
    name: '',
    addressId: 0,
    phone: '',
    fax: '',
    email: '',
    logoUrl: '',
    preferences: {
      IM1_SoftwareProviderPreference: ''
    },
    address: {
      createDate: null,
      line1: '',
      line2: '',
      suburb: '',
      state: '',
      country: '',
      postCode: '',
      latitude: '',
      longitude: ''
    }
  };
}
