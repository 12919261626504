import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  private loadingStateSubject = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public constructor () {
  }

  public loadingState (): Observable<boolean> {
    return this.loadingStateSubject.asObservable();
  }

  public startLoading () {
    this.loadingStateSubject.next(true);
  }

  public stopLoading () {
    this.loadingStateSubject.next(false);
  }
}
