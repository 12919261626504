<section class="medication">
  <h2>
    <ng-container *ngIf="isNewPatient">
      {{ medicationSectionlabel(patientInformation$ | async, false) }}
    </ng-container>
    <ng-container *ngIf="!isNewPatient">
      <span class="first-name">{{
        (patientInformation$ | async)?.patient_FirstName
      }}</span>
      <span>&nbsp;</span>
      <span class="last-name">{{
        (patientInformation$ | async)?.patient_LastName
      }}</span>
      <span>{{
        medicationSectionlabel(patientInformation$ | async, true)
      }}</span>
    </ng-container>
  </h2>

  <section *ngIf="cardInserted" class="scr-display">
    <h3>
      Summary Care Record
      <span *ngIf="(scrDisplayLevel$ | async) === 'ScrLoaded'">
        - Retrieved at
        {{ toLocalDateString((DisplayMedicationList$ | async)?.retrievalTime) }}
      </span>
    </h3>
    <p
      class="warning-message"
      *ngIf="(scrDisplayLevel$ | async) === 'ScrLoaded'"
    >
      The patient’s Summary Care Record is only accurate at the time of
      retrieval
    </p>
    <ng-container [ngSwitch]="scrDisplayLevel$ | async">
      <div class="loadingScr" *ngSwitchCase="'Loading'">
        <div class="spinner">
          <mat-spinner diameter="50"></mat-spinner>
          <div>loading</div>
        </div>
      </div>
      <div class="loadingAvail" *ngSwitchCase="'LoadingAvail'">
        <div class="spinner">
          <mat-spinner diameter="50"></mat-spinner>
          <div>checking SCR availability</div>
        </div>
      </div>
      <div class="loadingDisabled" *ngSwitchCase="'Disabled'">
        <p>
          You have indicated this patient has not yet granted permission to view
          their SCR
        </p>
        <p>
          This can be altered by reloading the page or on future accessing of
          the patient record.
        </p>
      </div>
      <div class="noNhsCard" *ngSwitchCase="'NoNhsCard'">
        <p>
          To access a patient's SCR record you must be authenticated with your
          NHS identity service card.
        </p>
        <p class="card-required-warning" *ngIf="!cardInserted">
          <mat-icon class="prefix">error_outline</mat-icon>Insert NHS smart card
          to continue
        </p>
      </div>
      <div class="PatientScrUnavail" *ngSwitchCase="'NoScr'">
        <p>
          <span class="first-name">{{
            (patientInformation$ | async)?.patient_FirstName
          }}</span>
          <span class="last-name">{{
            (patientInformation$ | async)?.patient_LastName
          }}</span>
          has no SCR record.
        </p>
      </div>
      <div class="PatientScrUnavail" *ngSwitchCase="'NoPermission'">
        <p>
          <span class="first-name">{{
            (patientInformation$ | async)?.patient_FirstName
          }}</span>
          <span class="last-name">{{
            (patientInformation$ | async)?.patient_LastName
          }}</span>
          has not given permission to view their SCR record.
        </p>
      </div>
      <div class="PatientScrUnavail" *ngSwitchCase="'NoPermissionNoScr'">
        <p>
          <span class="first-name">{{
            (patientInformation$ | async)?.patient_FirstName
          }}</span
          ><span>&nbsp;</span>
          <span class="last-name">{{
            (patientInformation$ | async)?.patient_LastName
          }}</span>
          has not given permission to view their SCR record, nor is one
          available.
        </p>
      </div>
      <div class="PatientScrUnavail" *ngSwitchCase="'WrongPermission'">
        <p>
          You do not have the correct permission to view
          <span class="first-name">{{
            (patientInformation$ | async)?.patient_FirstName
          }}</span
          ><span>&nbsp;</span>
          <span class="last-name">{{
            (patientInformation$ | async)?.patient_LastName
          }}</span
          >'s SCR record.
        </p>
      </div>
      <div class="PatientScrUnavail" *ngSwitchCase="'ErrorNoScr'">
        <p>
          Unable to determine if
          <span class="first-name">{{
            (patientInformation$ | async)?.patient_FirstName
          }}</span
          ><span>&nbsp;</span>
          <span class="last-name">{{
            (patientInformation$ | async)?.patient_LastName
          }}</span>
          has an SCR record.
        </p>
        <p>{{ (ScrErrorState$ | async).error }}</p>
      </div>
      <div class="PatientScrAvail" *ngSwitchCase="'ScrAvail'">
        <p>
          Has this patient given permission to view their Summary Care Record?
        </p>
        <p>
          The usual legal, ethical and professional obligations apply when
          accessing a patient's clinical record.
        </p>
        <p>
          Choosing to download the SCR constitutes a statement that you have the
          patient's authority to access their SCR data.
        </p>
      </div>
      <div class="patientScrLoaded" *ngSwitchCase="'ScrLoaded'">
        <iframe
          class="html_summary"
          sandbox
          [srcdoc]="
            encodeHtml((DisplayMedicationList$ | async)?.html_Summary)
              | safe : 'html'
          "
        ></iframe>
        <div class="patient-permission">
          <p
            *ngIf="
              (DisplayMedicationList$ | async)?.properties
                ?.isEmergencyAccess === 'True'
            "
          >
            Emergency access
          </p>
          <p
            *ngIf="
              (DisplayMedicationList$ | async)?.properties
                ?.isEmergencyAccess !== 'True'
            "
          >
            The patient has given permission to view the SCR
          </p>
          <p>
            <strong>CONFIDENTIAL</strong>: PERSONAL PATIENT DATA accessed by
            {{ cardHolderName }}
          </p>
        </div>
      </div>
      <div class="patientScrFailed" *ngSwitchCase="'ScrLoadError'">
        <p>The patient SCR has could not be loaded.</p>
        <p>{{ (ScrErrorState$ | async).error }}</p>
      </div>
      <div class="error" *ngSwitchDefault>
        Something has gone wrong - Display level =
        {{ scrDisplayLevel$ | async }}
      </div>
    </ng-container>
    <div class="button-set">
      <button
        class="primary"
        (click)="retreivePatientDrugRecord()"
        [disabled]="disableScrFetchButton || !allowedScrAccessWithConsent"
      >
        <mat-icon class="prefix">vertical_align_bottom</mat-icon>Yes, retrieve
        the summary care record from NHS
      </button>
      <button
        class="cancel nhs-compliance-button"
        (click)="disableScrButton()"
        [disabled]="disableScrFetchButton"
      >
        No
      </button>
    </div>
    <div class="button-set">
      <button
        class="primary warning-button emergency-access-button"
        *ngIf="!disableScrFetchButton && allowedScrAccessInEmergency"
        (click)="popupEmergencyAccessComponent()"
      >
        <mat-icon class="prefix">error_outline</mat-icon>Emergency Access
      </button>
    </div>
  </section>
  <section class="drug-display">
    <div class="buttons-after header-underline medication-header">
      <div>
        <h3 class="no-underline">Medications visible in MedAdvisor App</h3>
      </div>
      <div class="im1LinkageKeyContainer" *ngIf="!isNewPatient">
        <button
          class="btn-default-blue btn-text"
          [disabled]="isIM1Patient"
          (click)="addIm1Creditials()"
          id="button-add-im1-creditials"
        >
          <mat-icon class="prefix">add_circle_outline</mat-icon>Add IM1 Linkage
          Key
        </button>
      </div>
      <div [matTooltip]="addButtonToolTip" matTooltipPosition="above">
        <button
          class="btn-default-blue btn-text"
          [disabled]="disableAddButton"
          (click)="addDrugNew()"
          (drugCancelRequest)="cancelUpdate()"
          id="button-add-medication"
        >
          <mat-icon class="prefix">add_circle_outline</mat-icon>Add Medication
        </button>
      </div>
    </div>
    <mat-spinner
      class="drug-loading-spinner"
      diameter="50"
      strokeWidth="5"
      *ngIf="isMedicationLoading$ | async"
    >
    </mat-spinner>
    <div
      #drugList
      [ngClass]="[showDrugs]"
      *ngIf="
        (isMedicationLoading$ | async) === false || isNewPatient
      "
    >
      <app-patientdrugrecord
        #addDrug
        [isIM1Patient]="isIM1Patient"
        [editDaysSupply]="editDaysSupply"
        (drugSaveRequest)="saveUpdates()"
        (drugCancelRequest)="cancelUpdate()"
        (drugAdditionRequest)="addMedication($event)"
        [forceStopEdit$]="forceStopEdit$"
        (drugSelectedForOrderChange)="drugSelectedForOrderChange()"
      ></app-patientdrugrecord>
      <ng-container
        class="drug-item"
        *ngFor="let item of (DisplayMedicationList$ | async)?.drugEntries"
      >
        <app-patientdrugrecord
          [record]="item"
          [isIM1Patient]="isIM1Patient"
          [editDaysSupply]="editDaysSupply"
          (drugSaveRequest)="saveUpdates()"
          (drugDeletionRequest)="deleteMedication($event)"
          (drugCancelRequest)="cancelUpdate()"
          [forceStopEdit$]="forceStopEdit$"
          (drugSelectedForOrderChange)="drugSelectedForOrderChange()"
        >
        </app-patientdrugrecord>
      </ng-container>
    </div>
    <div
      class="buttonbar"
      *ngIf="
        !isNewPatient &&
        (DisplayMedicationList$ | async)?.drugEntries?.length > 0
      "
    >
      <button
        *ngIf="config.features.EditPatientPage.AllowPharmacyOrdering"
        [ngClass]="{
          'btn-disable': !hasDrugsSelected,
          'btn-primary btn-text': hasDrugsSelected
        }"
        (click)="pharmacyOrderDrugs()"
        [disabled]="!hasDrugsSelected"
        id="button-pharmacy-send-order"
      >
        <mat-icon class="prefix">send</mat-icon>Send order to GP
      </button>
      <button
        (click)="showAllDrugs()"
        *ngIf="(DisplayMedicationList$ | async)?.drugEntries?.length > 3"
        class="btn-default-blue btn-text"
      >
        {{ expandIcon ? "Less" : "Show All" }}
        <mat-icon *ngIf="expandIcon"> expand_less </mat-icon>
        <mat-icon *ngIf="!expandIcon"> expand_more </mat-icon>
      </button>
    </div>
    <div
      *ngIf="
        errorMessageNoDrug &&
        (DisplayMedicationList$ | async)?.drugEntries?.length <= 0
      "
    >
      <p class="error-message-scr">{{ errorMessageNoDrug }}</p>
    </div>
    <div
      *ngIf="
        duplicatedrug !== '' &&
        (DisplayMedicationList$ | async)?.drugEntries?.length > 0
      "
      class="warning-message"
    >
      The following medications from SCR are duplicated with the manually added
      medications in the list: {{ duplicatedrug }}. The information added by
      Pharmacy will be kept. Please be informed.
    </div>
    <p
      *ngIf="
        !(DisplayMedicationList$ | async)?.drugEntries?.length &&
        !errorMessageNoDrug
      "
      class="medication-info"
    >
      No medications added yet.
    </p>
  </section>
</section>
