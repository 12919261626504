<ng-container *ngIf="!isLoading; else loadSpinner">
  <div class="patient-group">
    <h3 class="patient-group__title">Patient group</h3>
    <div class="patient-group__content">
      <p class="patient-group__instruction">
        Choose the patients you want to send an announcement to.
        <br/>
        If a patient has a carer, only the carer will be sent the announcement.
      </p>
      <form [formGroup]="patientGroupForm" (ngSubmit)="savePatientGroup()">
        <select [formControl]="patientGroupIdControl" name="patient-group__select-group"
                (change)="selectPatientGroup()">
          <option value="">Create a new group</option>
          <ng-container *ngIf="patientGroups">
            <option *ngFor="let patientGroup of patientGroups" [value]="patientGroup.id">{{patientGroup.name}}</option>
          </ng-container>
        </select>
        <input *ngIf="!isDefaultPatientGroup" type="text" [formControl]="patientGroupNameControl"
               placeholder="Enter a name for this group" maxlength="500"
               class="patient-group__input-group-name">
        <p class="patient-group__input-error"
           *ngIf="patientGroupNameControl.touched && patientGroupNameControl.errors?.required">
          You must enter a group name
        </p>
        <p class="patient-group__input-error" *ngIf="patientGroupNameControl.errors?.uniqueName">
          {{patientGroupNameControl.errors?.uniqueName}}
        </p>
        <ng-container *ngIf="!isDefaultPatientGroup">
          <h4 class="patient-group__criteria-title">Criteria for group</h4>
          <div class="patient-group__settings" [formGroup]="patientGroupCriteriaForm">
            <div class="patient-group__gender-option">
              <div class="patient-group__criteria-section">
                <div class="patient-group__radio-section">
                  <input type="radio" value="" id="any-sex" name="gender"
                         [formControl]="genderCriteriaControl" (change)="handlePatientGroupCriteriaChange()">
                  <label for="any-sex">Any sex</label>
                </div>
                <div class="patient-group__radio-section">
                  <input type="radio" [value]="true" id="filter-sex" name="gender"
                         [formControl]="genderCriteriaControl" (change)="handlePatientGroupCriteriaChange()">
                  <label for="filter-sex">Filter on sex</label>
                </div>
              </div>
              <ng-container *ngIf="genderCriteriaControl.value">
                <div class="option-group" *ngFor="let genderControl of patientGroupGenderArray.controls; index as i">
                  <input type="checkbox" id="{{genderOptions[i].label}}-option"
                         (change)="handlePatientGroupCriteriaChange()" [formControl]="genderControl">
                  <label for="{{genderOptions[i].label}}-option">{{genderOptions[i].label}}</label>
                </div>
                <p *ngIf="patientGroupGenderArray.errors?.noSelectedGender" class="patient-group__input-error">
                  At least one filter must be selected
                </p>
              </ng-container>
            </div>
            <div class="patient-group__age-range">
              <div class="patient-group__criteria-section">
                <div class="patient-group__radio-section">
                  <input type="radio" value="" id="any-age" name="age"
                         [formControl]="ageCriteriaControl" (change)="handlePatientGroupCriteriaChange()">
                  <label for="any-age">Any age</label>
                </div>
                <div class="patient-group__radio-section">
                  <input type="radio" [value]="true" id="filter-age" name="age"
                         [formControl]="ageCriteriaControl" (change)="handlePatientGroupCriteriaChange()">
                  <label for="filter-age">Filter on age</label>
                </div>
              </div>
              <ng-container *ngIf="ageCriteriaControl.value">
                <div class="age-range-input">
                  <input type="number" (change)="handlePatientGroupCriteriaChange()"
                         (focusout)="handleAgeFocusOut('from')"
                         [formControl]="patientGroupAgeFromControl" min="0" max="200" placeholder="Any">
                  <span>To</span>
                  <input type="number" (change)="handlePatientGroupCriteriaChange()"
                         (focusout)="handleAgeFocusOut('to')"
                         [formControl]="patientGroupAgeToControl" min="0" max="200" placeholder="Any">
                </div>
                <p class="patient-group__input-error"
                   *ngIf="patientGroupAgeToControl.touched && patientGroupCriteriaForm.errors?.invalidAgeRange">
                  The To value must be larger than the From value.
                </p>
                <p *ngIf="patientGroupAgeFromControl.errors?.missingAge && patientGroupAgeToControl.errors?.missingAge"
                   class="patient-group__input-error">At least one filter must be selected</p>
              </ng-container>
            </div>
            <div class="patient-group__ingredient">
              <div class="patient-group__criteria-section">
                <div class="patient-group__radio-section">
                  <input type="radio" value="" id="any-ingredient" name="ingredient"
                         [formControl]="ingredientCriteriaControl"
                         (change)="handlePatientGroupCriteriaChange(); shouldDisplayActiveIngredientAutocomplete = false">
                  <label for="any-ingredient">Patients taking any active ingredient</label>
                </div>
                <div class="patient-group__radio-section">
                  <input type="radio" [value]="true" id="filter-ingredient" name="ingredient"
                         [formControl]="ingredientCriteriaControl" (change)="handlePatientGroupCriteriaChange()">
                  <label for="filter-ingredient">Filter on patients taking a specific active ingredient(s)</label>
                </div>
              </div>
              <div class="patient-group__active-ingredient" *ngIf="ingredientCriteriaControl.value">
                <form [formGroup]="ingredientSearchForm" (ngSubmit)="addActiveIngredient()">
                  <div class="active-ingredient-form">
                    <input type="text" (focus)="shouldDisplayActiveIngredientAutocomplete = true"
                           [formControl]="ingredientSearchControl" (input)="searchActiveIngredient()"
                           class="active-ingredient-search">
                    <div class="active-ingredient-autocomplete" *ngIf="shouldDisplayActiveIngredientAutocomplete">
                      <ng-container
                        *ngIf="activeIngredientsAutocomplete && activeIngredientsAutocomplete.length && isSearchIngredientComplete === true">
                        <div class="active-ingredient-option"
                             (click)="selectSuggestion(option)"
                             *ngFor="let option of activeIngredientsAutocomplete">{{option}}</div>
                      </ng-container>
                      <div *ngIf="isSearchIngredientComplete === false" class="active-ingredient-spinner">
                        <app-load-spinner [diameter]="32"></app-load-spinner>
                      </div>
                      <div class="active-ingredient-no-result"
                           *ngIf="(!activeIngredientsAutocomplete || !activeIngredientsAutocomplete.length) && isSearchIngredientComplete === null">
                        No Result
                      </div>
                    </div>
                    <button class="active-ingredient-add" type="submit">
                      <span class="material-icons">add</span>
                    </button>
                  </div>
                  <p class="patient-group__input-error"
                     *ngIf="ingredientSearchControl.touched && ingredientSearchControl.errors?.minlength">Please enter
                    at least three letters of the medication name.</p>
                </form>
                <select size="10" class="active-ingredient-list" #activeIngredientSelect>
                  <ng-container *ngIf="activeIngredients">
                    <option *ngFor="let ingredient of activeIngredients" [value]="ingredient">{{ingredient}}</option>
                  </ng-container>
                </select>
                <p class="patient-group__input-error" *ngIf="ingredientSearchForm.errors?.missingIngredient">
                  Include at least one active ingredient
                </p>
                <div class="active-ingredient-delete">
                  <button class="active-ingredient-delete-btn" type="button"
                          (click)="deleteActiveIngredient(activeIngredientSelect)">Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="patient-group__control-patient">
            <h4>Manually manage group</h4>
            <button class="control-patient-btn" [disabled]="isDefaultPatientGroup" type="button"
                    (click)="displayBroadcastPatientDialog()">
              Manually add/remove patients
            </button>
          </div>
          <div class="patient-group__footer">
            <div class="patient-group__save">
              <button class="btn-default-blue btn-text" [disabled]="isSubmittingPatientGroup || isDefaultPatientGroup"
                      type="submit">Save Group
              </button>
              <p class="patient-group__unsaved-hint" *ngIf="isPatientGroupNotSaved">
                Unsaved: Save before sending announcement
              </p>
            </div>
            <button class="btn-default-blue btn-text" type="button"
                    [disabled]="!isDeletePatientGroupAvailable || isDefaultPatientGroup"
                    (click)="deletePatientGroup()">
              <span class="material-icons">delete</span>
            </button>
          </div>
        </ng-container>
      </form>
    </div>
  </div>

  <div class="message-template">
    <h3 class="message-template__title">Message</h3>
    <div class="d-flex">
      <div class="message-template__content">
        <form [formGroup]="messageTemplateForm" (ngSubmit)="saveMessageTemplate()">
          <div class="message-template__form-control">
            <select [formControl]="templateIdControl" class="message-template__select-template"
                    (change)="selectMessageTemplate()">
              <option value="">Create new template</option>
              <ng-container *ngIf="messageTemplates">
                <option *ngFor="let template of messageTemplates" [value]="template.id">{{template.name}}</option>
              </ng-container>
            </select>
          </div>
          <div class="message-template__form-control">
            <input type="text" [formControl]="templateNameControl" placeholder="Enter a name for this template"
                   class="message-template__input-template-name" maxlength="500">
            <p class="message-template__input-error"
               *ngIf="templateNameControl.touched && templateNameControl.errors?.required">
              You must enter a template name
            </p>
            <p class="patient-group__input-error" *ngIf="templateNameControl.errors?.uniqueName">
              {{templateNameControl.errors?.uniqueName}}
            </p>
          </div>
          <div class="message-template__form-control">
            <label>Subject of message</label>
            <input type="text" [formControl]="templateSubjectControl" placeholder="e.g: Flu vaccination available now"
                   class="message-template__input-template-subject" maxlength="500">
            <p class="message-template__input-error"
               *ngIf="templateSubjectControl.touched && templateSubjectControl.errors?.required">
              You must enter a subject
            </p>
          </div>
          <div class="message-template__form-control">
            <label>Main body of message</label>
            <ckeditor (blur)="focusoutEditor()" #richTextEditor
                      (change)="handleEditorChange($event)"
                      [config]="editorConfig"
                      [formControl]="templateBodyControl"
                      [editor]="editor"></ckeditor>
            <p class="message-template__input-error"
               *ngIf="templateBodyControl.touched && templateBodyControl.errors?.required">
              You must enter the message body
            </p>
            <p class="message-template__input-hint">Approx {{messageBodyCount}} characters</p>
          </div>
          <div class="message-template__footer">
            <div class="message-template__save">
              <button type="submit" class="btn-default-blue btn-text message-template__save-btn">Save Template</button>
              <p class="message-template__unsaved-hint" *ngIf="isTemplateNotSaved">
                Unsaved: Save before sending announcement
              </p>
            </div>
            <button class="btn-default-blue btn-text" type="button"
                    [disabled]="!isDeleteMessageTemplateAvailable"
                    (click)="deleteMessageTemplate()">
              <span class="material-icons">delete</span>
            </button>
          </div>
        </form>
      </div>
      <div class="message-template__help">
        <h4 class="message-template__help-title">Template help</h4>
        <p>Content tags can be used to personalise your message e.g. “Hi Jane”. Copy and paste to include in your message.</p>
        <div class="message-template__help-tags">
          <p *ngFor="let tag of helpTags" (click)="selectTemplateHelpTag($event)" class="template-tag">{{tag.title}}</p>
        </div>
        <p class="message-template__help-instruction">
          Remember: Your message will go to patients and carers of patients.
        </p>
      </div>
    </div>
  </div>

  <div class="send-broadcast">
    <button *ngIf="!isDefaultPatientGroup" (click)="displayBroadcastPatientDialog()"
            class="send-broadcast__count-patient">Review patients
    </button>
    <button class="btn-primary btn-text" [disabled]="!isSendBroadcastAvailable" (click)="sendBroadcastMessage()">
      Send Announcement
    </button>
  </div>
</ng-container>

<ng-template #loadSpinner>
  <div class="broadcast-loading">
    <app-load-spinner [diameter]="32"></app-load-spinner>
  </div>
</ng-template>
