import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TemplateUpdatedEvent } from './headerUpdateEvent';
import { filter } from 'rxjs/operators';
import { NAVIGATION_BAR_SECTIONS } from './headerConstants';
import { INavigationBarService } from './headerServiceInterface';

@Injectable()
export class NavigationBarService implements INavigationBarService {
  private readonly _templateUpdatedSubject: Subject<TemplateUpdatedEvent>;
  public templateUpdatedEvent: Observable<TemplateUpdatedEvent>;

  public constructor() {
    this._templateUpdatedSubject = new BehaviorSubject<TemplateUpdatedEvent>(
      null
    );
    this.templateUpdatedEvent = this._templateUpdatedSubject.pipe(
      filter((event) => !!event),
      filter((event) => event.section === 'title')
    );
  }

  public editTemplate(
    section: 'title',
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    template: TemplateRef<any>
  ): void {
    const event = new TemplateUpdatedEvent();
    event.section = section;
    event.templateRef = template;

    this._templateUpdatedSubject.next(event);
  }

  public deleteTemplate(section: NAVIGATION_BAR_SECTIONS): void {
    const event = new TemplateUpdatedEvent();
    event.section = section;
    event.templateRef = null;
    this._templateUpdatedSubject.next(event);
  }
}
