import {gzip} from 'pako';
import {Buffer} from 'buffer';
import { LogLevelEnum } from './log-level.enum';

export class LogEntryModel {
  entryDate: string = new Date().toISOString();
  message = '';
  stackTrace = '';
  logLevel: LogLevelEnum = LogLevelEnum.debug;
  extraInfo: string[];

  buildLogString(): string {
    let logString = '';
    logString = `Entry date: ${new Date()}\n`;
    logString += `Type: ${LogLevelEnum[this.logLevel].toUpperCase()}\n`;
    logString += `Message: ${this.message}\n`;
    if (this.extraInfo && this.extraInfo.length) {
      logString += `Extra info: ${this.formatParams(this.extraInfo)}\n`;
    }
    logString += `Stack trace: ${this.stackTrace}\n`;
    return logString;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  formatParams(params: any[]): string {
    return params.join(' , ');
  }

  /** returns a base64 encoding of a string that's truncated by charsToCrop, and then gzip'd */
  truncateAndCompress(text: string, charsToCrop: number): string {
    text = charsToCrop >= text.length ? '' : text.substring(0, text.length - charsToCrop);
    if (charsToCrop > 0) { text += '\u2026'; } // end on an ellipsis to indicate it was truncated

    try {
      text = Buffer.from(gzip(text)).toString('base64');
    } catch (ex) {
      // this shouldn't happen, but fall back to not gzipping the text
      text = btoa(text);
    }
    return text;
  }

  /**
   * @param maxSize, if specified then the stackTrace part may be compressed/truncated/dropped to
   *        try to keep serialization size under maxSize. (webapi logger needs to fit LogEntryModels
   *        into a WAF size limit)
   */
  base64Encode(maxSize = 0) {
    this.message = btoa(this.message);
    if (this.extraInfo) {
      this.extraInfo = this.extraInfo.map(x => btoa(x));
    }

    if (maxSize > 0 && this.stackTrace) {
      // attempt to limit the size of the serialized LogEntryModel, by compressing
      // and cropping the stacktrace, as they are often oversized
      const tempStackTrace = this.stackTrace;
      this.stackTrace = '_';
      const overheadSize = JSON.stringify(this).length - 1; // -1 for the '_' char in fake stackTrace
      const targetSize = maxSize - overheadSize;

      let charsToCrop = 0;
      do {
        this.stackTrace = this.truncateAndCompress(tempStackTrace, charsToCrop);
        charsToCrop += tempStackTrace.length / 8; // limit the number of tries to 8, hopefully none/few are needed
      } while (this.stackTrace.length > targetSize && charsToCrop < tempStackTrace.length);

      if (this.stackTrace.length > targetSize) {
        this.stackTrace = '<removed>'; // no room to include the stackTrace
      }

    } else {
      this.stackTrace = this.truncateAndCompress(this.stackTrace, 0);
    }

  }
}
