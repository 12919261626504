<app-basic-dialog
  [buttonAcceptTitle]="acceptButtonTitle"
  [buttonCancel]="cancelButtonTitle"
  (OnComplete)="listenAction($event)">
    <div class="toast-inline-container" toastContainer></div>
    <div class="dialog-body">
            <div>
                <p class="dialog-body__text">{{comfirmationText}}</p>
                <div class="dialog-body__label">{{selectedTime}}</div>
                <p class="dialog-body__text">{{bookingResourceWarningText}}</p>
            </div>
    </div>
</app-basic-dialog>