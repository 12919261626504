import {  Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { PDSRecord } from 'src/app/api/ApiRecordTypes/PatientRecord';
import { AppConfigService } from 'src/app/app-config.service';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
  useUtc: true,
};

enum ScreenState {
  search = 'Search',
  confirmPatientDetails = 'confirmPatientDetails',
  notFound = 'notFound',
  inviteSucess = 'inviteSucess',
  errorMessage = 'errorMessage',
}

enum SearchType {
  nhsNumber = 'NHS',
}

@Component({
  selector: 'app-patientexternalsearch',
  templateUrl: './patientexternalsearch.component.html',
  styleUrls: ['./patientexternalsearch.component.scss'],
})
export class PatientExternalSearchComponent implements OnInit {
  @Input() displayScreen = ScreenState.search;
  searchingState = null;
  maxDate: Date = new Date();

  // data fields
  @Input() NHSNumber: string;
  @Input() DateOfBirth: Date;
  MobileNumber: string;
  @Input() patientFirstName;
  @Input() patientSurName: string;
  @Input() postCode: string;
  @Input() gender: string;
  @Input() prescriptionCode: string;

  // Validate Search Fields
  isNHSValid = true;
  isDOBValid = true;
  isFirstnameValid = true;
  isPostcodeValid = true;
  isSurnameValid = true;
  checkPostcodeRequired = false;
  isPrescriptionCodeValid = true;
  // searchingInProgress: boolean = false;
  @Input() messageErr$ = new BehaviorSubject<string> ('');
  // Inital patientSearchModel
  patientSearch = new PDSRecord();
  patientSearch$ = new BehaviorSubject<PDSRecord>(this.patientSearch);
  isVerifiedSCRConsent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading = false;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  customizedSiteConfig: string;
  @Input() toggleNHSPatientName;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() screenValueChanged = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() searchForPatient = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() searchingInProgress = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() scriptSearchEvent = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() phoneStatusOutput = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() toggleNHSPatientNameEvent = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() firstNameChanged = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() surNameChanged = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() sexChanged = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() DOBChanged = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() barcodeChanged = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() postCodeChanged = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() NHSchanged = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() validPhoneOutput = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() resetIsHtmlNotNullAndDrugNull = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() sourceChangeOutput = new EventEmitter<any>();

  constructor(private configService: AppConfigService) {}

  ngOnInit() {
    this.config = this.configService.getConfig();
    this.customizedSiteConfig = this.config.features.CustomConfig;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chunk(str: any, position: number) {
    const ret = [];

    for (let i = 0; i < str.length; i += position) {
      ret.push(str.substr(i, position));
    }

    return ret;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  onNHSBlur() {
    if (!this.NHSNumber || this.NHSNumber.length < 12) {
      this.isNHSValid = false;
    }

    if (this.NHSNumber !== undefined) {
      this.NHSNumber = this.NHSNumber.replace(/[^0-9]/g, '');
      this.NHSNumber = this.chunk(this.NHSNumber, 3).join(' ');
      this.NHSNumber = this.NHSNumber.substr(0, 11) + '' + this.NHSNumber.substr(12);
    }
    this.NHSchanged.emit(this.NHSNumber);
  }

  validatePostCode(e) {
    const regex = /^[a-zA-Z0-9]{5,7}$/g;
    const validation = regex.test(e);
    this.isPostcodeValid = validation;
    return validation;
  }

  onTextFirstNameBlur() {
    !this.patientFirstName ? (this.isFirstnameValid = false) : (this.isFirstnameValid = true);
    this.firstNameChanged.emit(this.patientFirstName);
  }

  onFirstNameChanged(e) {
    this.firstNameChanged.emit(e.target.value);
  }
  onSurNameChanged(e) {
    this.surNameChanged.emit(e.target.value);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSexChanged(e) {
    this.sexChanged.emit(this.gender);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDateInput(e) {
    this.isDOBValid = this.DateOfBirth && moment(this.DateOfBirth).isValid();
  }
  onPostCodeChanged(e) {
    this.postCodeChanged.emit(e.target.value);
  }
  onBarcodeBlur() {
    this.barcodeChanged.emit(this.prescriptionCode);
  }

  onTextLastNameBlur() {
    !this.patientSurName ? (this.isSurnameValid = false) : (this.isSurnameValid = true);
    this.surNameChanged.emit(this.patientSurName);
  }

  onTextPostcodeBlur() {
    if (this.postCode) {
      this.checkPostcodeRequired = false;
      if (!this.validatePostCode(this.postCode.replace(' ', ''))) {
        this.isPostcodeValid = false;
      } else {
        this.isPostcodeValid = true;
      }
    } else {
      this.checkPostcodeRequired = true;
    }

    if (this.postCode && this.postCode.length >= 5) {
      this.postCode = this.postCode.replace(' ', '');
      this.postCode = this.postCode.replace(/.{3}$/, ' $&');
    }

    this.postCodeChanged.emit(this.postCode);
  }

  onTogglingNHSPatientName() {
    if (this.toggleNHSPatientName) {
      this.NHSNumber = null;
    }
    this.toggleNHSPatientNameEvent.emit();
    this.isVerifiedSCRConsent$.next(false);
    this.clearPatientFields();
  }

  validPDSSearch(): boolean {
    if (!this.toggleNHSPatientName) {
      if (this.NHSNumber == null && this.DateOfBirth == null) {
        this.displayScreen = ScreenState.errorMessage;
        this.screenValueChanged.emit(this.displayScreen);
        this.isNHSValid = false;
        this.isDOBValid = false;
        this.isVerifiedSCRConsent$.next(false);
        return false;
      }
      if (
        (this.NHSNumber == null || this.NHSNumber === '') &&
        this.checkNullDOB()
      ) {
        this.displayScreen = ScreenState.errorMessage;
        this.screenValueChanged.emit(this.displayScreen);
        this.isNHSValid = false;
        this.isVerifiedSCRConsent$.next(false);
        return false;
      }

      const NHSParams = this.NHSNumber && this.NHSNumber.replace(/\s/g, '');
      if (!UtilsService.validateNHS(NHSParams)) {
        this.displayScreen = ScreenState.errorMessage;
        this.screenValueChanged.emit(this.displayScreen);
        this.isNHSValid = false;
        this.isDOBValid = true;
        if (!this.checkNullDOB()) {
          this.isDOBValid = false;
        }
        this.isVerifiedSCRConsent$.next(false);
        this.messageErr$.next ('Invalid NHS Number');
        return false;
      }

      this.isNHSValid = true;

      if (this.NHSNumber !== null && this.NHSNumber.length < 12) {
        if (!this.checkNullDOB()) {
          this.isDOBValid = false;
        }
        return true;
      }
    } else {
      if (!this.patientFirstName || !this.patientSurName || !this.postCode || !this.checkNullDOB()) {
        !this.patientFirstName ? (this.isFirstnameValid = false) : (this.isFirstnameValid = true);
        !this.patientSurName ? (this.isSurnameValid = false) : (this.isSurnameValid = true);

        this.onTextPostcodeBlur();

        !this.checkNullDOB() ? (this.isDOBValid = false) : (this.isDOBValid = true);

        return false;
      } else {
        this.onTextPostcodeBlur();

        this.isFirstnameValid = true;
        this.isPostcodeValid = true;
        this.isDOBValid = true;
      }
    }

    if (!this.checkNullDOB()) {
      this.displayScreen = ScreenState.errorMessage;
      this.screenValueChanged.emit(this.displayScreen);
      this.isVerifiedSCRConsent$.next(false);
      this.isDOBValid = false;
      return false;
    }

    this.isFirstnameValid = true;
    this.isSurnameValid = true;
    this.isPostcodeValid = true;
    this.isDOBValid = true;

    return true;
  }

  checkNullDOB(): boolean {
    if (!this.DateOfBirth) {
      this.isVerifiedSCRConsent$.next(false);
      this.isDOBValid = false;
      return false;
    }
    return true;
  }

  onSearch() {
    this.messageErr$.next('');
    this.validPhoneOutput.emit(false);
    this.resetIsHtmlNotNullAndDrugNull.emit();
    this.phoneStatusOutput.emit('');
    this.sourceChangeOutput.emit('');
    this.isVerifiedSCRConsent$.next(false);
    if (this.validPDSSearch()) {
      this.DOBChanged.emit(new Date(this.DateOfBirth));
      const NHSParams = this.NHSNumber && this.NHSNumber.replace(/\s/g, '');
      const patient = {
        dateOfBirth: moment(this.DateOfBirth).format('YYYY-MM-DD'),
        ...(this.toggleNHSPatientName && {firstName: this.patientFirstName}),
        ...(this.toggleNHSPatientName && {lastName: this.patientSurName}),
        ...(this.toggleNHSPatientName && {gender: this.gender}),
        ...(this.toggleNHSPatientName && {zipCode: this.postCode}),
        ...(!this.toggleNHSPatientName && NHSParams && {patientCode: NHSParams})
      };

      this.displayScreen = ScreenState.search;
      this.screenValueChanged.emit(this.displayScreen);
      this.searchingState = SearchType.nhsNumber;
      this.searchingInProgress.emit(true);
      this.searchForPatient.emit(patient);
    }
  }

  clearPatientFields() {
    this.NHSNumber = '';
    this.DateOfBirth = null;
    this.isNHSValid = true;
    this.isDOBValid = true;
    this.patientFirstName = '';
    this.patientSurName = '';
    this.postCode = '';
    this.gender = 'F';
    this.isFirstnameValid = true;
    this.isDOBValid = true;
    this.isSurnameValid = true;
    this.isPostcodeValid = true;
    this.checkPostcodeRequired = false;
    this.isVerifiedSCRConsent$.next(false);
    this.messageErr$.next('');
  }

  onScriptSearch() {
    this.isVerifiedSCRConsent$.next(false);
    this.isPrescriptionCodeValid = this.prescriptionCode ? true : false;
    if (!this.isPrescriptionCodeValid) {
      return;
    }
    this.onBarcodeBlur();
    this.scriptSearchEvent.emit();
  }
}
