import { NgModule } from '@angular/core';


// Contains the private data used in controlling menu display
// Used to avoid overloading the outer
export class MenuItemData {
    baseOptions: MenuOption = null;
    isEnabled = false;
    isHidden = false;
    isActive = false;
    refresh = false;
}

export class MenuOption {
    id?: string;
    label: string;
    target?: string = '';
    icon?: string = '';
    requireRole?: string[] = null;
    childOf?: string; // Not yet used.
    showAlways?: boolean = false; // Show this whether the user role matches or not
    classes?: string = null; // Additional classes to add on the button.
    enableKey?: string = null; // Identifier for a function to call to initialise the menu item with conditional behaviour.
    disabledTooltip?: string = null; // Tool tip to show if the menu item is disabled
}

export class MenuOptions {
    [Id: string]: MenuOption
}

// Menu options can all be overridden on a case-by-case basis in config by providing override values in the menu mappings.
// New options cannot be added in this way.  To appear in this menu, a MenuOption object must exist in this list.
export const menuOptions: MenuOptions = {
    'Inbox': {
        id: 'homeNavigation',
        label: 'Home',
        showAlways: true,
        target: '/messages',
        icon: 'home',
        requireRole: ['Pharmacy']
    },
    'Patients': {
        id: 'patientsNavigation',
        label: 'Patients',
        showAlways: true,
        target: '/patients',
        icon: 'person',
        requireRole: ['Pharmacy']
    },
    'Surgeries': {
        id: 'surgeriesNavigation',
        label: 'Surgeries',
        showAlways: true,
        target: '/surgeries',
        icon: 'local_hospital',
        requireRole: ['Pharmacy']
    },
    'Settings': {
        id: 'settingsNavigation',
        label: 'Settings',
        showAlways: true,
        target: '/settings',
        icon: 'settings',
        requireRole: ['Pharmacy', 'Audit'],
        classes: 'minor'
    },
    'Audit': {
        id: 'auditNavigation',
        label: 'Audit',
        target: '/audit',
        icon: '',
        requireRole: ['Audit']
    },
    'Logout': {
        id: 'logoutNavigation',
        label: 'Logout',
        target: '',
        icon: 'power_settings_new'
    },
    'Calendar': {
        id: 'calendarNavigation',
        label: 'Services',
        target: '/calendar',
        icon: 'event',
        requireRole: ['Pharmacy'],
        classes: 'minor',
        showAlways: true
    },
};

@NgModule({}) export class AppMenuModule { }
