
<div class="dialog-header-container">
  <div class="header">
    <strong>SCR Emergency Access</strong>
    <div id="closeButton" class="close-button" (click)="closeDialog()">
      <i class="material-icons">
        clear
      </i>
    </div>
  </div>
</div>
<div class ='dialog-content'>
  <p>
    You may access this patient's record in the best interests of the patient if they are not able to give permission themselves, the patient is unconscious or confused. 
    This action will be audited by the system and an alert will be sent to your privacy officer for monitoring purposes. 
    Any breaches of patient confidentiality will be investigated and may be a matter for disciplinary proceedings. 
    If in doubt, speak to your manager or privacy officer.
  </p>
  <p>What is the reason for emergency access?</p>
  <div class="form-group" enctype="multipart/form-data">
    <div class="input-wrapper">
      <textarea [(ngModel)]="emergencyAccessReason" (input)="validateEmergencyAccessReason()" rows="6" cols="100" class="form-input message-form" placeholder=""></textarea>
    </div>
  </div>
  <app-validation-error [errorMessage$]="validEmergencyAccessReason$"></app-validation-error>
  
  <div class="button-set">
    <button mat-raised-button class="primary" (click)="onUpdateMedication()">Continue</button>
    <button mat-raised-button class="cancel" (click)="closeDialog()">Cancel</button>
  </div>
</div>
