import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PDSRecord } from 'src/app/api/ApiRecordTypes/PatientRecord';
import { AppConfigService } from 'src/app/app-config.service';
import * as moment from 'moment';
import { PatientDetails } from 'src/app/api/iapi.service';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';
import { PatientModel } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'lodash';

@Component({
  selector: 'app-patientexternaldetail',
  templateUrl: './patientexternaldetail.component.html',
  styleUrls: ['./patientexternaldetail.component.scss'],
})
export class PatientExternalDetailComponent implements OnInit {
  // patientSearch = new PDSRecord();
  @Input() isVerifiedSCRConsent$;
  @Input() validPhone = false;
  @Input() isLoading: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  isTypingInMobileField = false;
  customizedSiteConfig: string;
  verifyPatientConsent = false;
  isMobileEnabled = false;
  showConsentErr = false;
  @Input() patientSearch = new PDSRecord();
  patientSearch$ = new BehaviorSubject<PDSRecord>(this.patientSearch);
  @Input() Html_Summary = '';
  Html_Summary$ = new BehaviorSubject<string>(this.Html_Summary);
  @Input() displayScreen = null;
  @Input() DateOfBirth: Date;
  @Input() MobileNumber: string;
  @Input() errorMessage$: BehaviorSubject<string>;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() phoneChanged = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() phoneStatusChanged = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() invitePatient = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() validPhoneOutput = new EventEmitter<any>();
  @Input() isInviteEnabled: boolean;  // Do not alter this in this component.  It blockes external modification.
  @Input() carerPatient: PatientModel;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Input() screenMode: any;
  disableInviteButton = 'auto';
  constructor(
    private configService: AppConfigService
  ) { }

  ngOnInit() {
    this.config = this.configService.getConfig();
    this.customizedSiteConfig = this.config.features.CustomConfig;
  }

  address(): string {
    return this.patientSearch &&
      this.patientSearch.identityPrivate &&
      this.patientSearch.identityPrivate.address
      ? `${this.patientSearch.identityPrivate.address.line1} ${this.patientSearch.identityPrivate.address.line2} ${this.patientSearch.identityPrivate.address.suburb} ${this.patientSearch.identityPrivate.address.postCode} ${this.patientSearch.identityPrivate.address.state}`
      : '';
  }

  NHSnumber(): string {
    const NHSnumber =
      this.patientSearch.identityPrivate.patientCode ||
      this.patientSearch.identityPrivate.externalSystemCode;
    return NHSnumber && NHSnumber.replace(/^(.{3})(.{3})(.*)$/, '$1 $2 $3');
  }

  invitedDate() {
    return (
      this.patientSearch &&
      this.patientSearch.identityPrivate &&
      moment(this.patientSearch.identityPrivate.invitedDate).format()
    );
  }

  displayDOB() {
    return this.patientSearch &&
      this.patientSearch.identityPrivate &&
      this.patientSearch.identityPrivate.dateOfBirth
      ? this.patientSearch.identityPrivate.dateOfBirth
      : '';
  }
  displaySex() {
    return this.patientSearch &&
      this.patientSearch.identityPrivate &&
      this.patientSearch.identityPrivate.gender === 'M'
      ? 'Male'
      : 'Female';
  }
  displayPatientName() {
    return this.patientSearch && this.patientSearch.identityPrivate
      ? `${this.patientSearch.identityPrivate.title} ${this.patientSearch.identityPrivate.firstName} ${this.patientSearch.identityPrivate.lastName}`
      : '';
  }

  displayGP(): string[] {
    return (
      this.patientSearch &&
      this.patientSearch.practices &&
      this.patientSearch.practices.map((practice) => practice.name + ' ')
    );
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  chunk(str: any, position: number) {
    const ret = [];

    for (let i = 0; i < str.length; i += position) {
      ret.push(str.substr(i, position));
    }

    return ret;
  }

  validPatientConsent(): boolean {
    if (this.verifyPatientConsent) {
      this.showConsentErr = false;
      return true;
    } else {
      this.showConsentErr = true;
      return false;
    }
  }
  onMobileChange() {
    this.isMobileEnabled = true;

  }
  onMobileBlur () {
    this.isMobileEnabled = false;
    this.phoneFormat();
  }

  addNewPatient() {
    this.isTypingInMobileField = false;
    if (this.validPatientConsent() && this.MobileNumber !== '' && UtilsService.UKPhoneVaild(this.MobileNumber)) {
      this.validPhone = true;
      const phone = this.MobileNumber && this.MobileNumber.replace(/\s/g, '');
      const nhsNumber = this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.patientCode
                        || this.patientSearch.identityPrivate.externalSystemCode ;
      const details: PatientDetails = new PatientDetails();
      details.patientcode = nhsNumber;
      details.practice = this.patientSearch && this.patientSearch.practices[0];
      details.address = this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.address;
      details.properties = {
        firstname: this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.firstName,
        lastname: this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.lastName,
        zipcode: this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.zipCode,
        gender: this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.gender ?
                this.patientSearch.identityPrivate.gender : '',
        phone: phone,
        dateofbirth: moment(this.patientSearch && this.patientSearch.identityPrivate &&
                    this.patientSearch.identityPrivate.dateOfBirth).format('YYYY-MM-DD'),
                    // ...(this.screenMode === 'InviteCarer' && {carerId: this.carerPatient.patientCode_MedAdvisorGUID})
        };
      this.invitePatient.emit(details);
      this.disableInviteButton = 'none';
    } else {
      this.validPhone = false;
      this.validPhoneOutput.emit(this.validPhone);
    }
  }

  isTyping() {
    this.isTypingInMobileField = true;
  }

  phoneFormat() {
    this.isTypingInMobileField = false;
    // Validate Phone AU || UK
    this.phoneStatusChanged.emit('');

    this.MobileNumber = this.MobileNumber && this.MobileNumber.replace(/\s/g, '');
    this.MobileNumber = UtilsService.formatPhone(this.MobileNumber);

    if (UtilsService.UKPhoneVaild(this.MobileNumber)) {
      this.validPhone = true;
    } else {
      this.validPhone = false;
    }

    this.phoneChanged.emit(this.MobileNumber);
  }
}
