export class CardDetails {
    cardsUsed: boolean;
    cardOwner: string;
    cardValidated: boolean;
    cardToken: string;
}

export class ICardIconDisplay {
    getIconData(): CardDetails {
        throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCardToken(token: string): CardDetails {
        throw new Error('Method not implemented.');
    }
}
