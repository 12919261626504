<div class="message-information-container">
  <p class="title-message-pharmacy" *ngIf="fromPharmacy">You replied to 
    <span class="first-name">{{firstName}}</span><span>&nbsp;</span>
    <span class="last-name">{{lastName}}</span>
    with:</p>
  <div class="message-content">
    <p>{{content}}</p>
  </div>
  <div *ngIf="count > 1" class="count-message">
    <p>{{count}} Messages</p>
  </div>
</div>