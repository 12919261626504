import { Component, ViewEncapsulation, ChangeDetectionStrategy,
  EventEmitter, Output, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MenuOption, MenuItemData} from 'src/app/app-menu.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-menuitem',
  templateUrl: './menuitem.component.html'
})
export class MenuItemComponent  {
  @Input() set menuItemData(value: MenuItemData) {
    this.menuItemData$.next(value.baseOptions);
    this.isEnabled$.next(value.isEnabled);
    this.isHidden$.next(value.isHidden);
    this.isActive$.next(value.isActive);
  }

  @Output() menuItemSelected = new EventEmitter<MenuOption>();
  menuItemData$ = new BehaviorSubject<MenuOption>(null);

  isHidden$ = new BehaviorSubject<boolean>(false);
  isEnabled$ = new BehaviorSubject<boolean>(false);
  isActive$ = new BehaviorSubject<boolean>(false);

  constructor(
  ) {
      // if menuitem is active page use iconClass = 'material-icons-outlined'
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  MenuSelected(uri: string) {
    this.menuItemSelected.emit(this.menuItemData$.value);
  }
}
