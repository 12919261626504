import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { PendingTaskRecord } from '../../../api/iapi.service';
import { Router } from '@angular/router';
import { DialogConfig, DialogService } from 'src/app/utility/base-dialog/dialog.service';
import { OrderComponent } from '../../order/order.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-msg-patientrequest',
  templateUrl: './patientrequest.component.html'
})
export class PatientRequestComponent {
  @Input() message: PendingTaskRecord;
  @Input() isMobile = false;
  numberOfDrugs: BehaviorSubject<number> = new BehaviorSubject(0);
  constructor(
    private dialogs: DialogService,
    private router: Router,
  ) {
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  drugs(): any[] {
    const taskData = JSON.parse(this.message.jSonTaskData);
    const drugs = taskData && taskData.Drugs && taskData['Drugs'];
    this.numberOfDrugs.next(taskData['Drugs'].length);
    return drugs && drugs.slice(0, 3);
  }

  drugDetails(): string {
    const taskData = JSON.parse(this.message.jSonTaskData);
    if (taskData && taskData.Drugs && taskData['Drugs']) {
      const result: string[] = [];
      for (const drug of taskData.Drugs) {
        if (drug && drug.labelName.length >= 50) {
          result.push(`${drug.labelName.slice(0, 50)}... - ${drug.activeName.toUpperCase()}`);
        } else {
          result.push(`${drug.labelName} - ${drug.activeName.toUpperCase()}`);
        }
      }
      return result.join('\n ');
    } else {
      return 'No drug information provided';
    }
  }
  daysLeft(item): string {
    console.log('item.DaysLeft', item.DaysLeft);
    switch (item.DaysLeft) {
      case 0:
        return `${item.DaysLeft} left`;
      default:
        return `${item.DaysLeft} days`;
    }
  }
  drugSummaries(item): string {
    if (item) {
      let result = '';
      if (item && item.labelName.length >= 50) {
        result = `${item.labelName.slice(0, 50)}... - ${item.activeName.toUpperCase()}`;
      } else {
        result = `${item.labelName} - ${item.activeName.toUpperCase()}`;
      }

      return result;
    } else {
      return '';
    }
  }
  Notes(): string {
    const taskData = JSON.parse(this.message.jSonTaskData);
    if (taskData && taskData.Drugs && taskData['Notes']) {
      return `Note from patient: '${taskData.Notes}'`;
    } else {
      return '';
    }
  }
  manageOrder(data: PendingTaskRecord) {
    const dialogConfig = new DialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    this.dialogs.OpenComponentDialog(OrderComponent, data, dialogConfig).then((result) => {
      if (result != null) {
        this.router.navigate(['/messages']);
      } else {
        console.log('Closed externally');
      }
    });
  }
  displayDrugsTooltip(): string {
    const taskData = JSON.parse(this.message.jSonTaskData);
    if (taskData && taskData.Drugs && taskData['Drugs']) {
      const result: string[] = [];
      for (const drug of taskData.Drugs) {
        if (drug) {
          result.push(`${drug.labelName} - ${drug.activeName.toUpperCase()}`);
        }
      }
      return result.join('\n ');
    }
  }
}
