<div class="message-booking-dialog-title">
  <strong>Task</strong>
  <span class="message-booking-dialog-close-button message-booking-dialog-x-button" (click)="closeDialog()">X</span>
</div>
<div class="message-booking-dialog-header">
  <div>
    <i class="fa fa-bell" aria-hidden="true"></i> {{taskData?.Title}}
  </div>
</div>
<div class="message-booking-dialog-header-content">
  <div>
    <i class="fa fa-calendar" aria-hidden="true"></i> {{currentDate}}
  </div>
  <fieldset class="message-booking-dialog-task-detail-section">
    <legend>Task</legend> 
    <ng-container [ngSwitch]="data?.type">
      <ng-container *ngSwitchCase="'CalendarBooking'">
        <div>
          <strong><span class="first-name">{{data?.patient?.firstName}}</span><span>&nbsp;</span> <span class="last-name">{{data?.patient?.lastName}}</span></strong> has booked a {{taskData?.ServiceName}} with your pharmacy at {{taskData?.BookingTime}} on {{taskData?.BookingDate}}.
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'BookingCancelled'">
        <div>
          <strong><span class="first-name">{{data?.patient?.firstName}}</span><span>&nbsp;</span> <span class="last-name">{{data?.patient?.lastName}}</span></strong> has cancelled a {{taskData?.ServiceName}} with your pharmacy at {{taskData?.BookingTime}} on {{taskData?.BookingDate}}.
        </div>
      </ng-container>
      <ng-container class="message" *ngSwitchDefault>
        <app-msg-loadingrequest class="message gridrow"></app-msg-loadingrequest>
      </ng-container>
    </ng-container>
    <span (click)="onViewCalendar()" class="message-booking-dialog-show-calendar-button">
      <strong>Show Calendar</strong>
    </span>
  </fieldset>
</div>
<div class="message-booking-dialog-footer">
  <button class="btn btn-light message-booking-dialog-close-button" (click)="closeDialog()">Close</button>
</div>
