import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation,
        ViewChild, ElementRef, TemplateRef, Inject, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { CommsModule } from 'src/app/utility/comms/comms.module';
import { IAPIService, PatientDetails } from 'src/app/api/api.service';
import { Router, ActivatedRoute } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DrugModel, PatientModel } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';
import { MedicationModel, PatientPharmacyInformation, PatientPageState, ScrPageState  } from 'src/app/api/ApiRecordTypes/PatientRecord';
import { DialogConfig, DialogService } from 'src/app/utility/base-dialog/dialog.service';
import { NAVIGATION_BAR_SERVICE_PROVIDER } from 'src/app/navigation/header/headerDirectives/headerConstants';
import { INavigationBarService } from 'src/app/navigation/header/headerDirectives/headerServiceInterface';
import { AppConfigService } from 'src/app/app-config.service';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';
import { DomSanitizer } from '@angular/platform-browser';
import { CaregiverStatus } from 'src/app/api/ApiRecordTypes/PatientRecord';
import { NhsScrRequest, ParsedScrData, ParsedScrRow, ExternalPatientProfileError, ExternalPatientProfileStatus, SourceMedicines } from 'src/app/api/ApiRecordTypes/NHSRecords';
import { ErrorResult } from 'src/app/api/ApiRecordTypes/OperationResults';
import { PharmacyModel } from 'src/app/api/ApiRecordTypes/PharmacyRecord';
import { environment } from 'src/environments/environment';
import { PatientGroupUpdateDialogComponent } from '../patientgroupupdatedialog/patientgroupupdatedialog.component';
import { PatientGroupModel } from '../../api/ApiRecordTypes/PatientGroup';
import { PracticeConstant } from 'src/app/constants/practice.constant';


@Component({
  selector: 'app-patientdetails',
  templateUrl: './patientdetails.component.html',
  styleUrls: ['./patientdetails.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PatientDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  patientPageState: PatientPageState = new PatientPageState();
  scrPageState: ScrPageState = new ScrPageState();
  env = environment;

  patient$ = new BehaviorSubject<PatientModel>(null);
  firstName$ = new BehaviorSubject<string>('');
  lastName$ = new BehaviorSubject<string>('');
  medications = [];
  medications$ = new  BehaviorSubject<MedicationModel[]>(this.medications);
  isMedicationLoading$ = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  listCaree: any = [];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  listCaree$ = new  BehaviorSubject<any>(this.listCaree);
  drugReloadText = 'Reload Drug Profile';
  drugReloadDisabled = false;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  CustomerText = 'Patient';
  CustomerTextPlural = 'Patients';
  isLoading = true;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  caregiverStatus: any;
  customizedSiteConfig: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  initialMedicationList: any = [];
  displayScreen = 'confirmPatientDetails';
  fromPatientDetails = true;
  MedicationList = new ParsedScrData();
  MedicationList$ = new BehaviorSubject<ParsedScrData>(this.MedicationList);
  duplicatedrug = '';
  isVerifiedSCRConsent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isIM1Patient = false;
  isScrAvailable$: BehaviorSubject<ExternalPatientProfileStatus> = new BehaviorSubject<ExternalPatientProfileStatus>(null);
  ScrErrorState$: BehaviorSubject<ExternalPatientProfileError> = new BehaviorSubject<ExternalPatientProfileError>(null);
  patientInformation: PatientPharmacyInformation = new PatientPharmacyInformation();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  patientInformation$ = new BehaviorSubject<any>(this.patientInformation);
  pharmacy: PharmacyModel;
  pcomms = false;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  pcomms$ = new BehaviorSubject<any>(this.pcomms);
  @ViewChild('navigationTitle', {static: true} ) navigationTitleElement: ElementRef<HTMLElement>;
  @ViewChild('navigationEditCarerRolesEnd') editCarerRolesElement: ElementRef<HTMLElement>;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public readonly navBarTitle: TemplateRef<any>;
  linkPatientGroups: PatientGroupModel[] = [];
  patientId: number;

  constructor(
    private comms: CommsModule,
    private api: IAPIService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private dialogs: DialogService,
    private cdr: ChangeDetectorRef,
    private configService: AppConfigService,
    private sanitizer: DomSanitizer,
    @Inject(NAVIGATION_BAR_SERVICE_PROVIDER) protected navigationBarService: INavigationBarService,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any,
    protected chagneDetector: ChangeDetectorRef,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.patientPageState.patientCode_MedAdvisorGuid = params.patientId;
      this.isMedicationLoading$.next(true);
    });
    this.patient$.subscribe((patient) => {
      this.updatePatientDependentValues(patient);
    });
  }

  updatePatientDependentValues(patient: PatientModel) {
    if (patient == null) {
      this.patientInformation = null;
      this.patientInformation$.next(null);
      this.firstName$.next('');
      this.lastName$.next('');
      this.comms.setPage({pageTitle: 'Patient - Loading' , page: 'patientdetails', pageIcon: ''});
      this.isIM1Patient = false;
    } else {
      if (this.patientInformation == null) {
        this.patientInformation = new PatientPharmacyInformation();
      }
      this.patientInformation.patient_FirstName = patient.firstName;
      this.patientInformation.patient_LastName = patient.lastName;
      this.patientInformation$.next(this.patientInformation);
      this.firstName$.next(patient.firstName);
      this.lastName$.next(patient.lastName);
      this.comms.setPage({pageTitle: `Patient - <span class="first-name">${patient.firstName}</span> <span class="last-name">${patient.lastName}</span>`, page: 'patientdetails', pageIcon: ''});
      this.isIM1Patient = patient.isConnectedToIM1;
    }
  }

  ngOnInit() {
    this.caregiverStatus = CaregiverStatus;
    this.config = this.configService.getConfig();
    this.customizedSiteConfig = this.config.features.CustomConfig;
    this.patientPageState.isCarerMode = this.config.features.CarerMode;

    if (this.config && this.config.features && this.config.features.ShowCustomerText) {
      this.CustomerText = 'Customer';
      this.CustomerTextPlural = 'Customers';
    }
    const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
    this.api.getPharmacySettings(accountSetting.id).subscribe((result) => {
      this.pharmacy = result;
      if (this.patientInformation == null) {
        this.patientInformation = new PatientPharmacyInformation();
      }
      this.patientInformation.pharmacy_Name = result && result.name;
      this.patientInformation$.next(this.patientInformation);
      if (this.pharmacy.fields && this.pharmacy.fields.pcomms === '1') {
        this.pcomms = true;
      }
    });

    this.api.getPatientDetails(this.patientPageState.patientCode_MedAdvisorGuid).subscribe(result => {
      if (result) {
        this.patient$.next(result);
        this.ScrErrorState$.next(null);
        this.isIM1Patient = result.practice.channel === PracticeConstant.Im1Channel;
        this.patientPageState.isIM1Patient = this.isIM1Patient;
        if (this.patientPageState.isCarerMode && result.carerStatus === CaregiverStatus.IsCaringFor) {
          this.api.getRelated(this.patientPageState.patientCode_MedAdvisorGuid).subscribe(item => {
            this.listCaree = item.data.related;
            this.listCaree.forEach(caree => {
              caree.listDrug = [];
              if (caree.identity) {
                this.api.retrivePatientDrugs(caree.identity.patientCode_MedAdvisorGuid).subscribe(
                  (drug) => {
                    caree.listDrug = drug;
                    this.listCaree$.next(this.listCaree);
                  },
                  (err) => console.log(err));
              }
            });
            this.listCaree$.next(this.listCaree);
          },
          (err) => console.log(err));
        }

        const card = this.comms.getCardDetailsAccess().value;
        const nhsSessionId = (card && card.sessionId) || null;

        const dob = new Date(result.dateOfBirth).toLocaleDateString();
        this.api.checkIsStatusAvailable(result.externalSystemCode, dob, nhsSessionId).subscribe(
          (statusResult) => {
            this.isScrAvailable$.next(statusResult);
          },
          (err) => {
            if (err instanceof ErrorResult && err.errorType === 'ExternalPatientProfileError') {
              this.ScrErrorState$.next(err.errorData);
            }
            console.error(err);
          }
        );

        this.isMedicationLoading$.next(true);
        this.api.retrivePatientDrugs(this.patientPageState.patientCode_MedAdvisorGuid).subscribe(drug => {
          if (this.customizedSiteConfig === 'NPA') {
            this.MedicationList.html_Summary = '';
            if (!this.MedicationList.drugEntries) {
              this.MedicationList.drugEntries = [];
            }

            if (this.isIM1Patient) {
              // connected
              drug = drug.filter(d => d.source === SourceMedicines.IM1);
            }
            drug.forEach(item => {
              const drugModel = new ParsedScrRow();
              drugModel.activeName = item.activeName;
              drugModel._drugtext = item.labelName;
              drugModel.form = item.form;
              drugModel.strength = item.strength;
              drugModel.startDate = item.startDate;
              if (item.relationshipProperties) {
                drugModel.erd = item.relationshipProperties.hasrepeats === '1';
                drugModel.supply = item.relationshipProperties.supply;
                drugModel.repeatCycle = item.relationshipProperties.repeatcycle;
                drugModel.repeatCycleFrequency = item.relationshipProperties.repeatcyclefrequency;
                drugModel.repeatLeft = item.relationshipProperties.numberofrefill;
              }
              drugModel.quantity = item.daysLeft;
              drugModel.source = item.source;
              drugModel.maDrugId = item.id;
              drugModel.systemKey = item.systemKey;
              this.MedicationList.drugEntries.push(drugModel);

            });

            this.initialMedicationList = drug;
          }
          this.medications = drug;
          this.medications$.next(this.medications);
          this.MedicationList$.next(this.MedicationList);
          this.isLoading = false;
          this.isMedicationLoading$.next(false);
        },
        (err) => {
          this.isMedicationLoading$.next(false);
          console.log(err);
        },
        () => this.isMedicationLoading$.next(false));

        this.patientId = result && result.id;
        if (this.config.features && this.config.features.EditPatientPage && this.config.features.EditPatientPage.ShowPatientGroup) {
          this.api.retrievePatientGroupForPatientAsync(accountSetting.id, result.id)
            .subscribe(patientGroups => {
              this.linkPatientGroups = patientGroups || [];
              this.linkPatientGroups = this.linkPatientGroups
                .filter(link => !(link.preferences && link.preferences.includes('DefaultGroup')))
                .sort((firstLink, secondLink) => {
                return firstLink.name.localeCompare(secondLink.name);
              });
              this.cdr.markForCheck();
            });
        }
      }
    });
  }

  // THis is duplicated code from Add patient.  It should be refactored into the patientDrugUpdateComponent
  onPatientDrugRecordRetreivalRequested(data) {
    this.scrPageState.errorMessageNoDrug = '';
    this.scrPageState.errorMessageNoSCR = '';
    this.scrPageState.is500InternalServer = false;
    this.isLoading = true;
    let detail = new NhsScrRequest();
    detail = data.request;
    if (detail) {
      detail.patientCode = this.patientPageState.patientCode_MedAdvisorGuid;
    }

    const card = this.comms.getCardDetailsAccess().value;
    detail.nhsSessionId = card && card.sessionId;
    this.api.fetchPatientExternalDruglist(detail, this.config.ParseExternalDrugLists).subscribe(result => {
      this.ScrErrorState$.next(null);
      if (result.html_Summary === '' && result.drugEntries.length === 0) {
        this.scrPageState.errorMessageNoSCR = 'No SCR available';
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        this.scrPageState.errorMessageNoDrug = 'No SCR. Add any repeat medications manually.';
      }
      if (result.html_Summary !== '' && result.drugEntries.length === 0) {
        this.scrPageState.errorMessageNoDrug = 'No medications added yet';
        this.scrPageState.isHtmlNotNullAndDrugNull = true;
      }
      if (this.MedicationList) {
        const tempMedicationList = this.MedicationList;
        this.MedicationList = result;

        if (tempMedicationList && tempMedicationList.drugEntries) {
          tempMedicationList.drugEntries.forEach(element => {
            const index = this.MedicationList.drugEntries.findIndex(m => m.maDrugId === element.maDrugId);
            if (index >= 0) {
              this.MedicationList.drugEntries[index] = element;
              this.duplicatedrug += element._drugtext + ', ';
            } else {
              this.MedicationList.drugEntries.push(element);
            }
          });
        }

        this.duplicatedrug = this.duplicatedrug.substring(0, this.duplicatedrug.length - 2);

        this.MedicationList$.next(this.MedicationList);
      } else {
        this.MedicationList = result;
        this.MedicationList$.next(this.MedicationList);
      }
      this.isVerifiedSCRConsent$.next(true);
      this.isLoading = false;
      this.cdr.markForCheck();
    }, (err) => {
      // handle special case errors.
      if (err instanceof ErrorResult && err.errorType === 'ExternalPatientProfileError') {
        this.ScrErrorState$.next(err.errorData);
      }
      if (err === 'nhsSessionId must have a value' || err.status === 500) {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        this.scrPageState.errorMessageNoSCR = 'Error retrieving SCR. Please add any drugs manually';
        this.scrPageState.is500InternalServer = true;
      }
      this.isLoading = false;
      this.cdr.markForCheck();
    }
    );
  }

  ngAfterViewInit(): void {
    this.navigationBarService.editTemplate('title', this.navBarTitle);
    this.chagneDetector.detectChanges();
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.navigationBarService.deleteTemplate('title');
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  NHSnumber(patient: PatientModel): string {
    const NHSnumber = patient && patient.externalSystemCode;
    return  NHSnumber && NHSnumber.replace(/^(.{3})(.{3})(.*)$/, '$1 $2 $3');
  }


  GPName(patient: PatientModel): string {
    return patient && patient.practice && patient.practice.name;
  }

  formatPhone(phoneNumber: string): string {
      return phoneNumber && UtilsService.formatPhone(phoneNumber);
  }

  postCode(patient: PatientModel): string {
    return (patient && patient.address && patient.address.postCode) || '';
  }

  displayGender(gender: string): string {
    switch (gender) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      case '-':
        return 'Unspecified';
      default:
        return '';
      }
  }

  address(patient: PatientModel, mobile = false): string {
    const fields = mobile ? patient && patient.address &&
    [patient.address.line1, patient.address.line2,
     patient.address.suburb, patient.address.city, patient.address.postCode ]
     : patient && patient.address &&
      [patient.address.line1, patient.address.line2,
       patient.address.suburb, patient.address.state, patient.address.postCode ];
    return fields && fields.filter( x => x !== null && x !== '').join(', ');
  }

  deletePatient(patientCode: string, deleteCaree = false) {
    const dialogConfig = new DialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '475px';
    const confirmationString = ['Are you sure you want to remove?'];
    this.dialogs.openConfirmationDialog(confirmationString, 'Yes', 'Cancel').then((result) => {
      if (result != null) {
        if (result === 3) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.api.deletePatient(patientCode).subscribe(res => {
              if (deleteCaree) {
                window.location.reload();
              } else {
                this.router.navigate(['/patients']);
              }
            });
        }
      } else {
        console.log('Closed externally');
      }
    });
  }

  reloadDrugProfile(patientMAGuid: string) {
    
    this.isMedicationLoading$.next(true);
    this.medications = null;
    this.api.reloadPatientDrugProfile(patientMAGuid).subscribe(res => {
      this.isMedicationLoading$.next(false);
      if (res.status === 200) {
        this.dialogs.openInformationDialog(['Drug profile is getting loaded. This may take some time.'], 'OK').then(() => {
          this.changeDrugProfileButton('Drug Profile Loading...');
        });
      } else if (res.status === 429) {
        this.dialogs.openInformationDialog([
          'Drug profile has be reloaded recently.', 'If there are issues with this patient\'s profile, please connact support.'], 'OK'
          ).then(() => {
            this.changeDrugProfileButton('Drug Profile Loaded');
        });
      }
    });
  }

  private changeDrugProfileButton(text: string) {
    this.drugReloadText = text;
    this.drugReloadDisabled = true;
    this.ngOnInit();
  }

  openPatientDetails(code: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/patients/' + code]);
    });
  }

  onSaveMedicationChangesRequested(patientMAGuid: string, data: ParsedScrData) {
    this.MedicationList = data;
    const details: PatientDetails = new PatientDetails();
    details.patientcode = patientMAGuid;
    details.drugs = [];
    let _repeatCycle = 0;

    if (this.MedicationList && this.MedicationList.drugEntries) {
      const idsSelectedForOrder = []
      for (let i = 0; i < this.MedicationList.drugEntries.length; i ++) {
        const entry = this.MedicationList.drugEntries[i];
        if (entry.maDrugId) {
            const drug = new DrugModel();
            _repeatCycle = 0;
            if (entry.repeatCycleFrequency !== 'as required') {
                _repeatCycle = entry.repeatCycle ? entry.repeatCycle : undefined;
            }

            drug.id = entry.maDrugId;
            drug.systemKey = entry.systemKey;
            drug.properties = {
              instructionsread: entry._instructions,
              daysleft: entry.quantity,
              deterministic: entry.repeatCycle ? '1' : '0',
              supply: entry.supply,
              repeatcyclefrequency: entry.repeatCycleFrequency,
              repeatcycle: _repeatCycle,
              hasrepeats: entry.erd ? '1' : '0',
              refillnumber: (entry.erd
                            && entry.repeatLeft != null
                            && entry.repeatLeft >= 0)
                            ? 1 : undefined,
              numberofrefill: (entry.erd
                              && entry.repeatLeft != null
                              && entry.repeatLeft >= 0)
                              ? entry.repeatLeft : -1,
              source: entry.source
            };

            details.drugs.push(drug);

            if(entry.selectedForOrder){
              idsSelectedForOrder.push(entry.maDrugId)
            }
        }
      }

      this.isMedicationLoading$.next(true);
      
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.api.updateMedication(details).subscribe(result => {
        this.api.retrivePatientDrugs(this.patientPageState.patientCode_MedAdvisorGuid).subscribe(drug => {
          if (this.customizedSiteConfig === 'NPA') {
            this.MedicationList.drugEntries = [];
            drug.forEach(item => {
              const drugModel = new ParsedScrRow();
              drugModel.startDate = item.startDate;
              drugModel.activeName = item.activeName;
              drugModel._drugtext = item.labelName;
              drugModel.form = item.form;
              drugModel.strength = item.strength;
              if (item.relationshipProperties) {
                drugModel.erd = item.relationshipProperties.hasrepeats === '1' ? true : false;
                drugModel.supply = item.relationshipProperties.supply;
                drugModel.repeatCycle = item.relationshipProperties.repeatcycle;
                drugModel.repeatCycleFrequency = item.relationshipProperties.repeatcyclefrequency;
                drugModel.repeatLeft = item.relationshipProperties.numberofrefill;
              }
              drugModel.quantity = item.daysLeft;
              drugModel.source = item.source;
              drugModel.maDrugId = item.id;
              drugModel.systemKey = item.systemKey;
              drugModel.selectedForOrder = idsSelectedForOrder.includes(drugModel.maDrugId);
              this.MedicationList.drugEntries.push(drugModel);
            });
            

            this.MedicationList$.next(this.MedicationList);
            this.initialMedicationList = drug;

          }
          this.isMedicationLoading$.next(false);
        },
        (err) => {
          console.log(err);
          this.isMedicationLoading$.next(false);
        },
        () => this.isMedicationLoading$.next(false));
      }, err => {
         console.log('Error, please try again - ', err);
         this.isMedicationLoading$.next(false);
       }
     );
    }
  }

  public scrollToCarerRoles() {
    // scrollIntoView({ behavior:'smooth', block:'center'}) was somehow scrolling the screen (body element?) such that
    // you can't scroll back (noticed in Mozilla, but might be https://stackoverflow.com/a/69800779/3343347).
    // Scrolling to {block: "nearest"} doesn't appear to have the issue, so I'm doing that with an element located below editCarerRoles
    this.editCarerRolesElement.nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest'});
  }

  public changePatientGroup(): void {
    const configDialog = new DialogConfig();
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const dialogData: any = {
      linkPatientGroup: this.linkPatientGroups,
      patientId: this.patientId
    };
    this.dialogs.OpenComponentDialog(PatientGroupUpdateDialogComponent, dialogData, configDialog).then(() => {
      this.cdr.markForCheck();
    });
  }
}
