<div class="toast-header-container" toastContainer></div>
<div *ngIf="this.isEventLoading" id="spinner-container">
  <div id="spinner"></div>
</div>
<div class="view-calendar" [class.calendar-widget]="isCalendarWidget" #viewCalendar>
    <!-- Page Header-->
    <div class="calendar-header">
        <h1 class="calendar-header__left">
            Bookings Calendar
        </h1>
        <div class="calendar-header__right">
            <button type="button"
                    (click)="showAddOrEditBooking()"
                    class="btn-primary btn-icon-text">
                    <span class="material-icons">add</span>
                    <span>New Booking</span>
            </button>
            <button type="button"
                    (click)="showPublicAccessDialog()"
                    class="btn-ghost btn-icon-text">
                    <span class="material-icons">link</span>
                    <span>Public access</span>
            </button>
            <button type="button"
                    (click)="showSettingsDialog()"
                    class="btn-ghost btn-icon-text">
                <span class="material-icons">settings</span>
                <span>Calendar setting</span>
            </button>
        </div>
    </div>

    <!-- Fullcalendar-->
    <div class="calendar-main" id="calendar-main-div">

        <!-- Calendar header-->
        <div class="calendar-main-toolbar" [formGroup]="calendarForm">

            <!-- Calendar header left-->
            <div class="calendar-main-toolbar__left">
                    <!-- Select resource-->
                    <ng-select
                      class="select-resource"
                      [searchable]="false"
                      [clearable]="false"
                      (change)="handleResourceChange($event)"
                      [formControl]="resourceControl">
                      <ng-option [value]="0" [disabled]="isGridWeekView" [class.hidden]="isGridWeekView">Show All</ng-option>
                      <ng-option  *ngFor="let resource of initResources"
                                   [value]="resource?.id">
                        {{resource?.title}}
                      </ng-option>
                    </ng-select>
                    <!-- get today-->
                    <button class="btn-soft btn-text"
                            type="button"
                            (click)="getToday()">
                        Today
                    </button>

                    <!-- Select previous day-->
                    <button class="btn-default-blue btn-icon"
                            type="button"
                            (click)="doPrevious()">
                        <span class="material-icons">
                          chevron_left
                        </span>
                    </button>

                    <!-- Select next day-->
                    <button class="btn-default-blue btn-icon"
                            type="button"
                            (click)="doNext()">
                        <span class="material-icons">
                          chevron_right
                        </span>
                    </button>
            </div>
            <!-- Calendar header title-->
            <h2 class="calendar-main-toolbar__title">{{calendarTitle}}</h2>
            <!-- Calendar header right-->
            <div class="calendar-main-toolbar__right">
                    <!-- Select view layout-->
                    <ng-select
                               class="select-calendar-view"
                               [searchable]="false"
                               [clearable]="false"
                               (change)="setCalendarView($event)"
                               [formControl]="layoutControl">
                      <ng-option  *ngFor="let layout of layouts; let index = i"
                                   [value]="layout.id">
                        {{layout.value}}
                      </ng-option>
                    </ng-select>

                    <!-- Print-->
                    <button class="btn-icon-text"
                            type="button"
                            [ngClass]="isPrintSupport ? 'btn-soft': 'btn-disable'"
                            (click)="printCalendar()">
                      <span class="material-icons">print</span>
                      <span>Print</span>
                    </button>
            </div>
            <div class="calendar-main-toolbar__title-small-screen-wrapper">
              <h2 class="calendar-main-toolbar__title-small-screen">{{calendarTitle}}</h2>
            </div>

        </div>

        <!-- Calendar body-->
        <div class="calendar-main-body" #fcalendar id="calendar">
          <full-calendar
            #calendar
            [options]="calendarOptions">
          </full-calendar>
          <span class="prev-resources"
                [hidden]="reachTheFirstResource"
                *ngIf="shouldShowResourceNavigationButton()"
                (click)="doNavigationResource(activePageOfResource - 1)">&#10094;</span>
          <span class="next-resources"
                [hidden]="reachTheLastResource"
                *ngIf="shouldShowResourceNavigationButton()"
                (click)="doNavigationResource(activePageOfResource + 1)">&#10095;</span>
        </div>

        <!-- Monthly metrics-->
        <div class="calendar-main-monthly-metrics">
          <ng-container  *ngIf="serviceAssociateWithResource?.length > 0 && isMonthViewLayout">
            <h2 class="calendar-main-monthly-metrics__title">Total appointments booked this Month
                {{getCurrentResourceName()}}
            </h2>
            <div class="calendar-main-monthly-metrics__body">
                <ng-container *ngFor="let service of serviceAssociateWithResource">
                    <div class="row calendar-main-monthly-metrics__body-item">
                      <div class="calendar-main-monthly-metrics__body-service-name">{{service?.capabilityName}}</div>
                      <span>{{service?.count}}</span>
                    </div>
                </ng-container>
            </div>
          </ng-container>
        </div>
    </div>

    <ng-template #popoverTemplate let-event="event">
        <div class="popover-header">
          <div class="popover-header__icon-action"></div>
        </div>
        <div class="time">{{ event.extendedProps?.time }}</div>
        <strong>{{ event.extendedProps?.patientName }}</strong>
        <br>
        <strong>{{ event.extendedProps?.serviceName }}</strong>
    </ng-template>
</div>
<iframe id="formFrame" name="formFrame" src="about:blank"></iframe>
<div id="view-print-section">
  <app-flu-vax-form [prescreenResults]="prescreenFormModel">
  </app-flu-vax-form>
</div>
<ng-template #noPrescreenFormContent>
  <div class="content-dialog">
    <div><span class="material-icons icon-warning">warning</span></div>
    <p>No completed pre-screen forms for this date</p>
  </div>
</ng-template>
