<div><strong>{{taskData?.Title}}</strong></div>
<div class="drugs-infomation">
  <table class="drugs-table">
    <tbody>
      <tr class="drug-item">
        <td class="drug-summary">
          <ng-container [ngSwitch]="data?.type">
            <ng-container *ngSwitchCase="'CalendarBooking'">
              <div>
                <span class="first-name">{{data?.patient?.firstName}}</span><span>&nbsp;</span><span class="last-name">{{data?.patient?.lastName}}</span> has booked a {{taskData?.ServiceName}} with your pharmacy at {{taskData?.BookingTime}} on {{taskData?.BookingDate}}.
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'ServiceBooking'">
              <div>
                <span class="first-name">{{data?.patient?.firstName}}</span><span>&nbsp;</span><span class="last-name">{{data?.patient?.lastName}}</span> has requested to book a {{taskData?.ServiceName}}. Please contact the patient to arrange an appointment.
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'BookingCancelled'">
              <div>
                <span class="first-name">{{data?.patient?.firstName}}</span><span>&nbsp;</span><span class="last-name">{{data?.patient?.lastName}}</span> has cancelled a {{taskData?.ServiceName}} with your pharmacy at {{taskData?.BookingTime}} on {{taskData?.BookingDate}}.
              </div>
            </ng-container>
            <ng-container class="message" *ngSwitchDefault>
              <app-msg-loadingrequest class="message gridrow"></app-msg-loadingrequest>
            </ng-container>
          </ng-container>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>

