<h1 class="settings-title">Settings</h1>
<div class="pageContent-notflex settings-columns-container">

    <div class="settings-component settings-column">
        <div class="settings">
            <!-- <div class="messsaging-settings">
                <div class="header-line">
                    Messaging settings
                </div>
                <div class="main-contents">
                    <p class="sub-title">Autoarchive messages?</p>
                    <div>
                        <input [(ngModel)]="settings.messagingSettings.autoarchive" type="radio" name="yes"
                            id="autoarchiveYes" [value]="true" checked='settings.messagingSettings.autoarchive'><label
                            for="AutoarchiveYes">Yes</label>
                        <br />
                        <input [(ngModel)]="settings.messagingSettings.autoarchive" type="radio" name="no"
                            id="autoarchiveNo" [value]="false" checked='!settings.messagingSettings.autoarchive'><label
                            for="AutoarchiveNo">No</label>
                    </div>
                    <div class="label-field">
                        <p class="sub-title">Autoarchive messages after:</p>
                        <div>
                            <div class="input-wrapper">
                                <input [(ngModel)]="settings.messagingSettings.autoarchiveTime" type="number"
                                    name="time-message" id="time-message" class="form-input" placeholder="" />
                                <label class="label-right" for="time-message">days</label>
                            </div>
                        </div>
                    </div>
                    <div class="welcome-messages">
                        <p class="sub-title">Welcome message</p>
                        <div>
                            <div class="input-wrapper">
                                <textarea [(ngModel)]="settings.messagingSettings.welcomeMesssage" rows="4" cols="83"
                                    type="text" name="time-message" id="welcome-messages" class="form-input"
                                    placeholder=""></textarea>
                            </div>

                        </div>
                    </div>
                </div>
            </div> -->
            <div *ngIf="config?.features?.ShowSurgeries" class="script-request-settings">
                <div class="header-line">
                    Prescription request settings
                </div>
                <div class="main-contents">
                    <!-- <div class="label-field">
                        <p class="sub-title">Expected pick up time after script order first received through app</p>
                        <div>
                            <div class="input-wrapper">
                                <input [(ngModel)]="settings.scriptRequestSetting.pickupTime" type="number"
                                    name="time-message" id="time-message" class="form-input" placeholder="" />
                                <label class="label-right" for="time-message">hours</label>
                            </div>

                        </div>
                    </div> -->
                    <div [hidden]=true>{{ (settings$ | async) }}</div>
                    <div class="label-field">
                        <div class="form-group" enctype="multipart/form-data">
                            <label for="GPoverdue">Time before prescriptions are overdue from GP</label>
                            <div class="gp-overdue">
                                <div class="input-wrapper">
                                    <input
                                        [(ngModel)]="settings && settings.fields && settings.fields.hoursuntilscriptoverdue"
                                        type="number" name="time-message" id="time-message" class="form-input"
                                        placeholder="" />
                                    <label class="label-right" for="time-message">Hours</label>
                                </div>
                                <mat-checkbox class="mat-checkbox" color="primary" [(ngModel)]="gpApprovedAction">GP
                                    approved action</mat-checkbox>
                                <span class="material-icons"
                                    matTooltip="Selecting this checkbox will add the manual GP Approved option to orders in the inbox">
                                    info
                                </span>
                            </div>
                        </div>
                    <!-- This is hidden untill dispense integration is implemented https://medadvisor.atlassian.net/browse/BREN-2399.  remove the hidden class to diplay the Dispense system-->
                        <div class="form-group hidden" enctype="multipart/form-data">
                            <label for="PMR">PMR / Dispense system</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="dispense" placeholder="Not integrated" type="text" name="PMR" id="PMR"
                                    class="form-input-readonly" [readonly]="true" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pharmacy-settings">
                <div class="header-line">
                    Pharmacy settings viewable by {{customerTextPlural | lowercase}}
                </div>

                <div class="main-contents">
                    <div class="label-field">
                        <div class="form-group" enctype="multipart/form-data">
                            <label for="phone">Pharmacy display name</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="settings && settings.name" type="text" name="name"
                                    id="name" class="form-input" placeholder="" />
                            </div>
                            <app-validation-error [errorMessage$]="fieldValidation.pharmacyNameErrorMessage$"></app-validation-error>
                        </div>
                        <div class="form-group" enctype="multipart/form-data">
                            <label for="fax">Phone number</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="settings && settings.phone" type="text" name="phone"
                                    id="phone" class="form-input input-phone" placeholder="" />
                            </div>
                            <app-validation-error [errorMessage$]="fieldValidation.phoneErrorMessage$"></app-validation-error>
                        </div>
                        <div class="form-group" enctype="multipart/form-data">
                            <label for="email">Email</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="settings && settings.email" type="text" name="email"
                                    id="email" class="form-input input-email" placeholder="" />
                            </div>
                            <app-validation-error [errorMessage$]="fieldValidation.emailErrorMessage$"></app-validation-error>
                        </div>
                        <div class="form-group" enctype="multipart/form-data">
                            <label for="webAddress">Web address</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="webAddress" type="text" name="webAddress"
                                    id="phone" class="form-input input-webAddress" placeholder="" />
                            </div>
                            <app-validation-error [errorMessage$]="fieldValidation.webAddressErrorMessage$"></app-validation-error>
                        </div>
                        <div class="form-group" enctype="multipart/form-data">
                            <label for="fax">Address line 1</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="settings && settings.address && settings.address.line1" type="text"
                                    name="addressLine1" id="addressLine1" class="form-input" placeholder="" />
                            </div>
                            <app-validation-error [errorMessage$]="fieldValidation.addressLine1ErrorMessage$"></app-validation-error>
                        </div>
                        <div class="form-group" enctype="multipart/form-data">
                            <label for="fax">Address line 2 (optional)</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="settings && settings.address && settings.address.line2" type="text"
                                    name="addressLine2" id="addressLine2" class="form-input" placeholder="" />
                            </div>
                            <app-validation-error [errorMessage$]="fieldValidation.addressLine2ErrorMessage$"></app-validation-error>
                        </div>
                        <div class="form-group" enctype="multipart/form-data">
                            <label for="fax">Town</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="settings && settings.address && settings.address.suburb" type="text" name="postTown"
                                    id="postTown" class="form-input input-post-city" placeholder="" />
                            </div>
                            <app-validation-error [errorMessage$]="fieldValidation.suburbErrorMessage$"></app-validation-error>
                        </div>
                        <div class="form-group" enctype="multipart/form-data">
                            <label for="fax">City</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="settings && settings.address && settings.address.city" type="text" name="postCity"
                                    id="postCity" class="form-input input-post-city" placeholder="" />
                            </div>
                            <app-validation-error [errorMessage$]="fieldValidation.cityErrorMessage$"></app-validation-error>
                        </div>
                        <div class="form-group" enctype="multipart/form-data">
                            <label for="fax">Postcode</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="settings && settings.address && settings.address.postCode" type="text" name="postCode"
                                    id="postCode" class="form-input input-post-code" placeholder="" />
                            </div>
                            <app-validation-error [errorMessage$]="fieldValidation.postCodeErrorMessage$"></app-validation-error>
                        </div>
                        <div class="form-group hours-noticed">

                            <label >Opening hours</label>

                            <div class = "settings-form">
                            <div class="opening-header" style="margin-top: 21px;">
                                <div class = "opening-column"></div>
                                <div class = "opening-column">From</div>
                                <div class = "opening-column">To</div>
                            </div>

                            <div *ngFor="let item of listOpenHour; let i= index" class="row-date">
                                <div class="opening-row">
                                    <div class = "opening-column title-day"><b>{{item.Title}}</b></div>
                                    <div class = "opening-column">
                                        <div class="full-width">
                                            <select [(ngModel)]="item.Open" (change) = "selectOpenDate($event.target.value, item.Code)" class="selectTime">
                                                <option *ngFor="let time of listHours" [value]="time">{{time}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class = "opening-column last-column">
                                        <div class="full-width">
                                            <select [(ngModel)]="item.Close" [disabled] ="item.Disabled" (change) = "selectCloseDate($event.target.value, item.Code)" class="selectTime">
                                                <option *ngFor="let time of getItemsHourList('Closed')" [value]="time">{{time}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="error-color error-font-size" style="margin-bottom: 5px;" *ngIf = "item.Validate">Opening hour must be earlier than closing hour.</div>
                            </div>
                        </div>
                        </div>

                        <div class="form-group" enctype="multipart/form-data">
                            <label for="openingHoursNote">Opening hours note</label>
                            <div class="input-wrapper">
                                <input [(ngModel)]="openingHoursNote" type="text" name="openingHoursNote"
                                    id="openingHoursNote" class="form-input input-opening-hours-note" placeholder="" />
                            </div>
                        </div>
                            <app-validation-error [errorMessage$]="fieldValidation.openingHoursErrorMessage$"></app-validation-error>
                    </div>
                    <app-validation-error [errorMessage$]="fieldValidation.overallErrorMessage$"></app-validation-error>

                </div>
                <div class="space"></div>
            </div>
            <div class="pharmacy-service-booking">
                <div class="header-line">
                    Service booking requests settings
                </div>
                <div class="main-contents">
                    <div class="service-booking">
                        <div class="service-booking__checkbox">
                            <mat-checkbox class="mat-checkbox" color="primary" [(ngModel)]="serviceBookingRequest"></mat-checkbox>
                        </div>
                        <div class="service-booking__instruction">
                            Specifies whether a patient can submit a booking request for pharmacy professional services
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group button-group" enctype="multipart/form-data">
                <button type="button"
                (click)="onSave()"
                class="button-dialog btn-submit">
                <span>Save</span>
                </button>
                <button type="button"
                (click)="onCancel()"
                class="button-dialog btn-cancel-dialog">
                <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>


    <div class="settings-component settings-column" *ngIf="helpfulLink_proteQt || helpfulLink_remoteSupport">
        <div class="settings-sidebar">
            <div class="helpful-links-settings-sidebar">
                <div class="header-line">
                    Helpful links
                </div>

                <div *ngIf="helpfulLink_proteQt"       class="settings-handy-link space-top"><a href="{{ helpfulLink_proteQt }}">Download ProteQt Installer</a></div>
                <div *ngIf="helpfulLink_remoteSupport" class="settings-handy-link"><a href="{{ helpfulLink_remoteSupport }}">Launch remote support</a></div>
            </div>
        </div>
    </div>

</div>
