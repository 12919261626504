export class EventPreferenceModel {
  public pharmacyId: number;
  public type: number;
  public layout: string;
  public supplemental: string;
  public smsConfirmation: boolean;
  public smsReminder: boolean;

  constructor(pharmacyId: number,
              layout: string,
              smsConfirmation: boolean,
              smsReminder: boolean,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              supplemental?: string) {
    this.pharmacyId = pharmacyId;
    this.type = 0;
    this.layout = layout;
    this.smsConfirmation = smsConfirmation;
    this.smsReminder = smsReminder;
  }
}
