<app-basic-dialog
        [dialogTitle]="headerTitle"
        [buttonAcceptTitle]="acceptButtonTitle"
        (OnComplete)="listenAction($event)"
>
    <div class="toast-inline-container" toastContainer></div>
    <div class="dialog-body">
            <div>
                <div class="dialog-body__label">{{customerBookingLabel}}</div>
                <p class="dialog-body__text">{{customerBookingIntructionText}}</p>
                <input type="text" [value]="customerBooking" readonly>
                <span>
                  <button type="button"
                          class="btn-ghost btn-text"
                          (click)="copyCustomerLink()">
                    Copy
                  </button>
                </span>
            </div>

            <div *ngIf="!isCalendarWidget&&config?.features?.BookingCalender?.ShowStaffBookingUrl">
              <div class="dialog-body__label">{{staffBookingLabel}}</div>
              <p class="dialog-body__text">{{staffBookingIntructionText}}</p>
              <input type="text" [value]="staffBooking" readonly>
              <span>
                        <button type="button"
                                class="btn-ghost btn-text"
                                (click)="copyStaffLink()">
                          Copy
                        </button>
                      </span>
            </div>

    </div>
</app-basic-dialog>
