<div class="heading-invite">
  <div class="invite-header">
    <strong>Invite Patient</strong>
    <div
      id="closeButton"
      *ngIf="data.ShowCancel"
      (click)="cancelAddPatient()"
      class="close-button"
    >
      <i class="material-icons"> clear </i>
    </div>
  </div>
  <app-patient-addpage></app-patient-addpage>
</div>