export enum ServiceTypeEnum {
  notAvailable,
  listOnly,
  standard,
  custom
}

export enum ServiceVisibleEnum {
  invisible,
  visible
}

export enum RecurrenceType {
  daily,
  weekly,
  monthly,
  yearly
}
