import {Component, OnDestroy } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { BasicDialogComponent } from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import { Im1PatientPracticeModel } from 'src/app/api/regional/uk/ApiRecordsTypes/Im1PatientPracticeModel';
import { IUkApiService } from 'src/app/api/regional/uk/uk-api.interface';

export enum DisplayMode {
  enterDetails = 'enterDetails',
  success = 'success',
  error = 'error'
}

@Component({
  selector: 'app-im1-connect',
  templateUrl: './im1-connect.component.html',
  styleUrls: ['./im1-connect.component.scss']
})
export class Im1ConnectComponent extends BasicDialogComponent implements OnDestroy {

  private patientCode_MedAdvisorGUID: string;
  private practiceId: number;
  public practiceName: string;
  public linkageKeyControl: UntypedFormControl;
  public accountIDControl: UntypedFormControl;
  public im1ConnectFormGroup: UntypedFormGroup;
  public display: DisplayMode = DisplayMode.enterDetails;
  public inProgress = false;

  // default max input length
  public defaultMaxInputLength = 200;
  // default max input length
  public defaultMinInputLength = 2;

  constructor(
      protected route: ActivatedRoute,
      protected api: IUkApiService,
      protected router: Router
  ) {
    super(router);
    this.linkageKeyControl = new UntypedFormControl('', [
      Validators.required, Validators.minLength(this.defaultMinInputLength), Validators.maxLength(this.defaultMaxInputLength)]);
    this.accountIDControl = new UntypedFormControl('', [
      Validators.required, Validators.minLength(this.defaultMinInputLength), Validators.maxLength(this.defaultMaxInputLength)]);
    this.im1ConnectFormGroup = new UntypedFormGroup({
      linkageKeyControl: this.linkageKeyControl,
      accountIDControl: this.accountIDControl
    });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public setData (data: any) {
    super.setData(data);
    this.patientCode_MedAdvisorGUID = data.patientCode_MedAdvisorGUID;
    this.practiceId = data.practiceId;
    this.practiceName = data.practiceName;
  }

  // connect patientWithPracticeIM1
  public connectPatientWithPracticeByIM1(): void {
    this.inProgress = true;
    const im1Request: Im1PatientPracticeModel = {
      patientCode_MedAdvisorGUID: this.patientCode_MedAdvisorGUID,
      practiceId: this.practiceId,
      accountId: this.accountIDControl.value,
      linkageKey: this.linkageKeyControl.value
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.api.connectToIM1Async(im1Request).subscribe((_) => {
      this.inProgress = false;
      this.display = DisplayMode.success;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }, (_) => {
      this.inProgress = false;
      this.display = DisplayMode.error;

    });
  }
  public goToConnectScreen() {
    this.display = DisplayMode.enterDetails;
  }

  // call when destroy component
  public ngOnDestroy() {
    if (this._subscription && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }
}
