export class EventServiceModel {
  pharmacyId: number;
  capabilityId: number;
  name: string;
  description: string;
  supplemental: string;
  duration: number;
  bookingType: number;
  visible: number;

  constructor(
    serviceId: number,
    name: string,
    description: string,
    duration: number) {
    this.capabilityId = serviceId;
    this.name  = name;
    this.description = description;
    this.duration = duration;
  }
}
