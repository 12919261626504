import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {EventResourceModel} from 'src/app/api/ApiRecordTypes/EventResourceModel';
import {BasicDialogComponent} from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import {CalendarDialogData} from 'src/app/booking-calendar/models/view-models/layout-calendar-dialog.view-model';
import {CalendarEventService} from '../../services/calendar-event.service';
import {ToastContainerDirective, ToastrService} from 'ngx-toastr';
import {CalendarDialogService} from '../../services/calendar-dialog.service';
import {EventServiceModel} from '../../../api/ApiRecordTypes/EventServiceModel';
import {from, of, throwError} from 'rxjs';
import {catchError, first, mergeMap, tap} from 'rxjs/operators';
import {DialogResponse} from '../../../utility/base-dialog/general-dialog/general-dialog.component';
import {ServiceTypeEnum, ServiceVisibleEnum} from '../../constant/service.enum';

@Component({
  selector: 'app-service-dialog',
  templateUrl: './service-dialog.component.html',
  styleUrls: ['./service-dialog.component.css']
})
export class ServiceDialogComponent extends BasicDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  //#region Properties
  @ViewChild('deleteServiceContent', {static: true})
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public confirmDeleteDialogContent: TemplateRef<any>;

  @ViewChildren('serviceTemplate')
  public servicesChildren: QueryList<ElementRef>;

  @ViewChild(ToastContainerDirective)
  toastContainer: ToastContainerDirective;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public recentServiceClickedDelete: any;

  public durations = [5, 10, 15, 20, 30, 45, 60];

  public serviceKeyAllowToEdit = {
    name: 'name',
    duration: 'duration',
    description: 'description',
    visible: 'visible'
  };

  public resource: EventResourceModel;

  public services: EventServiceModel[] = [];

  public serviceFormArray: UntypedFormArray;

  public editServiceForm: UntypedFormGroup;

  public activeDescriptionInput: number;

  private pharmacyId: number;
  //#endregion

  //#region Accessors

  public get serviceFormArrayControls(): UntypedFormGroup[] {
    if (!this.serviceFormArray) {
      return [];
    }
    return this.serviceFormArray.controls as UntypedFormGroup[];
  }

  //#endregion

  //#region Constructor
  public constructor(
    protected router: Router,
    protected calendarService: CalendarEventService,
    protected toastr: ToastrService,
    protected dialogService: CalendarDialogService,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any
  ) {
    super(router);
    this.acceptButtonTitle = 'Done';

    this.serviceFormArray = new UntypedFormArray([]);

    this.editServiceForm = new UntypedFormGroup({
      serviceFormArray: this.serviceFormArray
    });
  }

  //#endregion

  //#region Life cycle
  public ngOnInit(): void {
    this.toastr.overlayContainer = this.toastContainer;
    const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
    if (!accountSetting || !accountSetting.id) {
      return;
    }

    this.pharmacyId = accountSetting.id;
  }

  public ngAfterViewInit(): void {
    this.toastr.overlayContainer = this.toastContainer;
  }

  //#endregion

  //#region Methods
  public setData(data: CalendarDialogData) {
    super.setData(data);

    if (!data) {
      return;
    }

    if (data.settings) {
      this.resource = data.settings.resource;
      this.services = data.settings.services;
      this.services.sort((firstService, secondService) => {
        return firstService.name.localeCompare(secondService.name);
      });
    }

    this.services.forEach(service => {
      const serviceItemForm = new UntypedFormGroup({
        name: new UntypedFormControl(service.name, [Validators.required]),
        duration: new UntypedFormControl(service.duration),
        description: new UntypedFormControl(service.description)
      });
      if (service.bookingType !== ServiceTypeEnum.custom) {
        serviceItemForm.controls['name'].disable();
        serviceItemForm.controls['description'].disable();
      }
      this.serviceFormArray.push(serviceItemForm);
    });
  }

  public updateService(service: EventServiceModel, serviceForm: UntypedFormGroup, currentInputFocus: string): void {
    if (!service || !serviceForm) {
      return;
    }

    if (currentInputFocus === this.serviceKeyAllowToEdit.description && service.bookingType !== ServiceTypeEnum.custom) {
      return;
    }

    if (currentInputFocus === this.serviceKeyAllowToEdit.visible) {
      if (!service.capabilityId) {
        return;
      }
    }

    if (currentInputFocus === this.serviceKeyAllowToEdit.description ||
      currentInputFocus === this.serviceKeyAllowToEdit.duration) {
      if (!service.capabilityId) {
        return;
      }
      const newValue = serviceForm.value[currentInputFocus];
      if (service[currentInputFocus] === newValue) {
        return;
      }
      service[currentInputFocus] = newValue;
    }

    if (currentInputFocus === this.serviceKeyAllowToEdit.name) {
      if (service.bookingType !== ServiceTypeEnum.custom) {
        return;
      }
      const newServiceName = serviceForm.value[currentInputFocus];
      if (!newServiceName) {
        return;
      }
      if (service.capabilityId && service.name === newServiceName) {
        return;
      }

      const serviceNames = this.services.map(r => r.name && r.name.toLowerCase());
      if (serviceNames.includes(newServiceName.toLowerCase())) {
        return;
      }
      service.name = serviceForm.value[this.serviceKeyAllowToEdit.name];
    }

    const updateServiceSubscription = this.calendarService.updateServiceAsync(service).subscribe(
      id => {
        if (id < 0) {
          this.showToastMessage('An error occurred', 'custom-toast-error');
          return;
        }
        if (!service.capabilityId) {
          service.capabilityId = Number(id);
          this.showToastMessage('Service is created', 'custom-toast-success');
          return;
        }
        this.showToastMessage('Service is updated', 'custom-toast-success');
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _ => this.showToastMessage('An error occurred', 'custom-toast-error')
    );
    this._subscription.add(updateServiceSubscription);
  }

  public addNewService(): void {
    const newService = new EventServiceModel(0, null, null, 5);
    newService.bookingType = ServiceTypeEnum.custom;
    newService.visible = ServiceVisibleEnum.visible;
    this.serviceFormArray.push(new UntypedFormGroup({
      name: new UntypedFormControl(newService.name, [Validators.required]),
      duration: new UntypedFormControl(newService.duration),
      description: new UntypedFormControl(newService.description)
    }));
    this.services.push(newService);
    const scrollToNewServiceSubscription = this.servicesChildren.changes.pipe(first()).subscribe(changes => {
      changes.last.nativeElement.scrollIntoView(false);
    });
    this._subscription.add(scrollToNewServiceSubscription);
  }

  public deleteService(service: EventServiceModel): void {
    if (!service) {
      return;
    }
    const deletedServiceIndex = this.services.findIndex(s => s.capabilityId === service.capabilityId);
    if (!service.capabilityId) {
      this.services.splice(deletedServiceIndex, 1);
      this.serviceFormArray.removeAt(deletedServiceIndex);
      return;
    }
    const setting = {content: this.confirmDeleteDialogContent, cancelButtonTitle: 'No', acceptButtonTitle: 'Yes, delete'};
    const openConfirmDialogPromise = this.dialogService.openConfirmDialog(setting);
    const deleteResourceSubscription = from(openConfirmDialogPromise).pipe(
      mergeMap(response => {
        if (response === DialogResponse.confirm) {
          return this.calendarService.deleteServiceAsync(service.capabilityId);
        }
        return throwError(DialogResponse.cancel);
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap(_ => {
        this.showToastMessage('Service is deleted', 'custom-toast-success');
        this.services.splice(deletedServiceIndex, 1);
        this.serviceFormArray.removeAt(deletedServiceIndex);
      }),
      catchError(error => {
        if (error !== DialogResponse.cancel) {
          this.showToastMessage('An error occurred', 'custom-toast-error');
        }
        return of(null);
      })
    ).subscribe();
    this._subscription.add(deleteResourceSubscription);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private showToastMessage(message: string, toastClass: string, timeOut = 3000): any {
    this.toastr.clear();
    const positionClass = new Date().getTime().toString() + Math.random().toString();
    return this.toastr.show(message, null, {toastClass, timeOut, positionClass});
  }

  public handleChangeServiceName(service: EventServiceModel, serviceForm: AbstractControl): void {
    const serviceNameControl = serviceForm && (serviceForm as UntypedFormGroup).controls &&
      (serviceForm as UntypedFormGroup).controls[this.serviceKeyAllowToEdit.name];
    if (!serviceNameControl) {
      return;
    }
    if (!serviceNameControl.value) {
      return;
    }

    if (service.name === serviceNameControl.value) {
      serviceNameControl.setErrors(null);
      return;
    }

    const serviceNames = this.services.map(r => r.name && r.name.toLowerCase());
    if (serviceNames.includes(serviceNameControl.value.toLowerCase())) {
      serviceNameControl.setErrors({uniqueName: true});
    }
  }

  public handleAccept () {
    this.calendarService.setToast('Service Updated');
    super.handleCancel();
  }

  public checkVisibility(index, isServiceVisible) {
    const value = isServiceVisible ? 0 : 1;
    this.services[index].visible = value;
  }
  //#endregion
}
