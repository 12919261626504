export class PrescreenFormModel {
  public bookingCode: string;
  public nullable: true;
  public readOnlyMode: boolean;
  public officeOnlyMode: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public formField: any;
  public firstName: string;
  public lastName: string;
  public medicareNumber: string;
  public irn: string;
  public medicareExpiry: string;
  public dateOfBirth: string;
  public address: string;
  public contactNumber: string;
  public emailAddress: string;
  public pharmacyName: string;
  public pharmacyAddress: string;
  public isCompleted: boolean;
  public startDate: string;
  public completedDate: string;
  public eventBookingCapabilityName: string;
  public prescreenView: string;
  public prescreenRequirement: number;
}
