<app-basic-dialog
  [dialogTitle]="headerDialog"
  [dialogFooter]="dialogFooter"
  [buttonAcceptTitle]="acceptButtonTitle"
  (OnComplete)="listenAction($event)">
  <div class="edit-resource">
    <div class="edit-resource__header">
      <h4 class="edit-resource__title">
        Resource or room name
      </h4>
    </div>

    <form class="edit-resource__form" [formGroup]="resourceForm">
      <div class="resource">
        <ng-container *ngFor="let resourceControl of resourceArrayControl.controls; index as i">
          <div class="resource__item d-flex" #resourceTemplate>
            <div class="edit-resource__input-name">
              <input type="text" [formControl]="resourceControl" placeholder="Name"
                     maxlength="255"
                     (input)="handleChangeResourceName(resources[i], resourceControl)"
                     (focusout)="saveResourceName(resources[i], resourceControl.value)">
            </div>
            <div class="edit-resource__color-picker">
              <input class="resource__color-picker" #colorPicker
                     [style.background]="resources[i].color"
                     [cpPresetColors]="palette"
                     [colorPicker]="resources[i].color"
                     [cpOKButton]="true"
                     [cpOKButtonText]="'Choose'"
                     (colorPickerSelect)="colorPicked($event, resources[i])"
                     readonly/>
              <span class="material-icons" (click)="colorPicker.focus()">expand_more</span>
            </div>
            <div class="edit-resource__delete">
              <button class="btn btn-basic" mat-button
                      matTooltip="Click here to permanently remove this resource or room"
                      (click)="deleteResource(resources[i]); recentResourceClickedDelete = resources[i]">
                <span class="material-icons">delete</span>
              </button>
            </div>
          </div>
          <ng-container *ngIf="resourceControl.touched">
            <p class="edit-resource__input-error" *ngIf="resourceControl.errors?.required">Please enter name for this
              resource or room</p>
            <p class="edit-resource__input-error" *ngIf="resourceControl.errors?.uniqueName">Please specify a unique
              calendar name.</p>
          </ng-container>
        </ng-container>
      </div>
    </form>
  </div>
</app-basic-dialog>

<ng-template #deleteResourceContent>
  <div class="delete-content-dialog">
    <div><span class="material-icons icon-warning">warning</span></div>
    <p>Are you sure you want to permanently delete the specified calendar: <b>{{recentResourceClickedDelete?.title}}</b>?</p>
    <p>Please check if there are any bookings and contact those patients if you are cancelling their bookings.</p>
  </div>
</ng-template>

<ng-template #dialogFooter>
  <button class="btn btn-default-outline btn-add-resource"
          (click)="addNewResource()">
    <span class="material-icons">add</span>
    <span>Add new resource</span>
  </button>
  <button class="button-dialog btn-submit" type="button"
          (click)="handleAccept()">{{acceptButtonTitle}}
  </button>
</ng-template>

<ng-template #headerDialog>
  <h3 class="custom-row-header__left">Edit resources</h3>
  <div class="toast-inline-container" toastContainer></div>
</ng-template>
