import { DrugModel, PatientModel } from './../../api/ApiRecordTypes/PendingTaskRecord';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientDetails } from 'src/app/api/api.service';
import * as moment from 'moment';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';
import { BehaviorSubject } from 'rxjs';
import { IAPIService, ParsedScrData } from 'src/app/api/iapi.service';
import { AppConfigService } from 'src/app/app-config.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-patientinviteconfirmation',
  templateUrl: './patientinviteconfirmation.component.html',
  styleUrls: ['./patientinviteconfirmation.component.scss']
})
export class PatientInviteConfirmationComponent implements OnInit {
  @Input() MobileNumber: string;
  @Input() isVerifiedSCRConsent$: BehaviorSubject<boolean>;
  @Input() isLoading: boolean;
  @Input() validPhone: boolean;
  @Input() patientSearch;
  @Input() displayScreen;
  @Input() errorMessage$: BehaviorSubject<string>;
  @Input() MedicationList = new ParsedScrData();
  MedicationList$ = new BehaviorSubject<ParsedScrData>(this.MedicationList);
  @Input() verifyPatientConsent: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() invitePatient = new EventEmitter<any>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() validPhoneOutput = new EventEmitter<any>();
  @Input() carerPatient: PatientModel;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Input() screenMode: any;
  customizedSiteConfig: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  env = environment;
  message = '';
  message$ = new BehaviorSubject<string>(this.message);
  carerInviteConsent = false;
  constructor(private configService: AppConfigService, private api: IAPIService) { }

  ngOnInit() {
    this.config = this.configService.getConfig();
    this.customizedSiteConfig = this.config.features.CustomConfig;
    this.initialisePatientSMS();
  }

  initialisePatientSMS() {
    this.configService.loadCurrentPharmacySettings().subscribe((pharmacyModel) => {
    if (this.screenMode === 'InvitePatient') {
      this.api.getTemplateSmsText('InviteSmsText').subscribe(result => {
        this.message = result;
        this.message = this.message.replace('{0}',
            this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.firstName);
        this.message = this.message.replace('{1}', 'XXX XXX');
        this.message = this.message.replace('{2}', pharmacyModel.name);
        this.message$.next(this.message);
        });
      }
      if (this.screenMode === 'InviteCarer') {
        this.api.getTemplateSmsText('LinkPatientSmsText').subscribe(result => {
          this.message = result;
          this.message = this.message.split('{0}').join(this.carerPatient.firstName);
          this.message = this.message.split('{1}')
                          .join(this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.firstName);
          this.message = this.message.split('{2}')
                          .join(this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.lastName);
          this.message = this.message.replace('{3}', pharmacyModel.name);
          this.message$.next(this.message);
        });
      }
    });
  }

  displayPatientName() {
    return this.patientSearch && this.patientSearch.identityPrivate ? `${this.patientSearch.identityPrivate.title} ${this.patientSearch.identityPrivate.firstName} ${this.patientSearch.identityPrivate.lastName}` : '';
  }

  validateNewPatientAndEmitErrors() {
    let valid = true;
    this.errorMessage$.next('');
    if ((!this.MobileNumber || !UtilsService.UKPhoneVaild(this.MobileNumber)) && this.screenMode === 'InvitePatient') {
      this.validPhone = false;
      this.validPhoneOutput.emit(this.validPhone);
      this.errorMessage$.next('*Please check the mobile number is valid');
      valid = false;
    }
    if (this.carerInviteConsent === false && this.screenMode === 'InviteCarer') {
      valid = false;
      this.errorMessage$.next('*Please check the caree consent box');
      valid = false;

    }
    return valid;
  }

  addNewPatient() {
    if (this.validateNewPatientAndEmitErrors()) {
      this.validPhone = true;
      let phone = this.MobileNumber && this.MobileNumber.replace(/\s/g, '');
      if (phone === '' || phone === undefined) {
        phone = null;
      }
      const nhsNumber = this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.patientCode
                        || this.patientSearch.identityPrivate.externalSystemCode ;
      const details: PatientDetails = new PatientDetails();
      details.patientcode = nhsNumber;
      details.drugs = [];

      if (this.MedicationList && this.MedicationList.drugEntries) {
        for (let i = 0; i < this.MedicationList.drugEntries.length; i ++) {
          if (this.MedicationList.drugEntries[i].maDrugId) {
              const drug = new DrugModel();
              drug.id = this.MedicationList.drugEntries[i].maDrugId;
              drug.properties = {
                instructionsread: this.MedicationList.drugEntries[i]._instructions,
                daysleft: this.MedicationList.drugEntries[i].quantity,
                deterministic: this.MedicationList.drugEntries[i].supply ? '1' : '0',
                supply: this.MedicationList.drugEntries[i].supply ? this.MedicationList.drugEntries[i].supply : undefined,
                hasrepeats: this.MedicationList.drugEntries[i].erd ? '1' : '0',
                refillnumber: (this.MedicationList.drugEntries[i].erd
                              && this.MedicationList.drugEntries[i].repeatLeft != null
                              && this.MedicationList.drugEntries[i].repeatLeft >= 0)
                              ? 1 : undefined,
                numberofrefill: (this.MedicationList.drugEntries[i].erd
                                && this.MedicationList.drugEntries[i].repeatLeft != null
                                && this.MedicationList.drugEntries[i].repeatLeft >= 0)
                                ? this.MedicationList.drugEntries[i].repeatLeft : undefined,
                source: this.MedicationList.drugEntries[i].source,
                repeatcycle: this.MedicationList.drugEntries[i].repeatCycle,
                repeatcyclefrequency: this.MedicationList.drugEntries[i].repeatCycleFrequency,
              };

              details.drugs.push(drug);
          }
        }
      }

      details.practice = this.patientSearch && this.patientSearch.practices[0];
      details.address = this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.address;
      details.properties = {
        firstname: this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.firstName,
        lastname: this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.lastName,
        zipcode: this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.zipCode,
        gender: this.patientSearch && this.patientSearch.identityPrivate && this.patientSearch.identityPrivate.gender ?
                this.patientSearch.identityPrivate.gender : '',
        phone: phone,
        dateofbirth: moment(this.patientSearch && this.patientSearch.identityPrivate &&
                    this.patientSearch.identityPrivate.dateOfBirth).format('YYYY-MM-DD'),
        ...(this.screenMode === 'InviteCarer' && {carerId: this.carerPatient.patientCode_MedAdvisorGUID})
      };
      this.invitePatient.emit(details);
    }
  }
}
