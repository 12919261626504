import { Observable, Subject } from 'rxjs';
import { OperationResultNew } from './ApiRecordTypes/OperationResults';

export abstract class IApiHelperService {
    public abstract getBaseApiPath (version: string): string;

    public abstract CreateObservableResult<TData, TError> (
        call: Observable<OperationResultNew<TData, TError>>,
        errorType: string
    ): Subject<TData>;

    public abstract ExtractOperationalResult<TData, TError> (result: Subject<TData>,
        call: Observable<OperationResultNew<TData, TError>>,
        errorType: string);
}
