import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDialogEmbeddable, DialogService } from 'src/app/utility/base-dialog/dialog.service';
import { OperationResult, PendingTaskRecord, IAPIService } from 'src/app/api/api.service';
import { DataService } from 'src/app/utility/dataservice/data.service';
import { AppConfigService } from 'src/app/app-config.service';
import { PatientRequestFulfilment } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';

enum PatientRequestStatus {
  Ready = '1',
  UnableToFill = '12',
  OnOrder = '13',
  ReadyForDelivery = '15',
}

export class ManageOrderOutput extends OperationResult<{ id: string }> { }

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})

export class OrderComponent implements IDialogEmbeddable<PendingTaskRecord, ManageOrderOutput>, OnInit {

  @Input() public data: PendingTaskRecord = new PendingTaskRecord();
  @Output() public OnComplete: EventEmitter<ManageOrderOutput> = new EventEmitter<ManageOrderOutput>(true);
  patientMessage = '';
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  dispenseRequest: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  drugs: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  actionAvailable: any;
  componentStatus = [];
  isMessageFormDisabled = true;
  isComponentLoading = true;
  customerText = 'Patient';
  isTerminalState = true;
  messageResendSucces = '';
  sendError = false;
  resendMessageDisabled = false;
  constructor(
    private router: Router,
    private api: IAPIService,
    private dialogs: DialogService,
    private performAction: DataService,
    private config: AppConfigService
  ) {
  }
  public setData(data: PendingTaskRecord): void {
    this.data = data;
  }
  cancelManageOrder() {
    this.OnComplete.emit(null);
  }

  ngOnInit() {
    const config = this.config.getConfig();
    if (config && config.features && config.features.ShowCustomerText) {
      this.customerText = 'Customer';
    }
    this.loadData();
  }

  loadData() {
    this.isComponentLoading = true;
    this.dispenseRequest = null;
    const taskData = JSON.parse(this.data.jSonTaskData);
    this.drugs = taskData && taskData.Drugs;

    this.isTerminalState = ['UnableToFill', 'Cancelled', 'Collected', 'Deleted', 'ReturnedStock'].includes(this.data.statusCode);
    this.api.getDispenseRequest(this.data.patient.patientCode_MedAdvisorGUID, taskData.PatientRequestId).subscribe(result => {
      this.dispenseRequest = result.data;
      this.isComponentLoading = false;
    });
  }

  isDelivery() {
    const record: PendingTaskRecord = this.dispenseRequest;
    if (record && record.fulfilment) {
      return record.fulfilment.toString() === PatientRequestFulfilment.Delivery.toString();
    }
    return false;
  }

  statusToNumber(status: string): number {
    if (this.performAction.getPerformAction() === 'UnableToFill') {
      this.isMessageFormDisabled = false;
      return 12;
    }
    switch (status) {
      case 'Ready': {
        return 1;
      }
      case 'UnableToFill': {
        return 12;
      }
      case 'OnOrder': {
        return 13;
      }
    }
    return 0;
  }
  onViewPatient() {
    const data = this.data;
    const id = data && data.patient && data.patient.patientCode_MedAdvisorGUID;
    if (id !== null) {
      this.router.navigate(['/patients', id]);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayStatus(component: any): string {
    if (component && component.statusModel && component.statusModel.status) {
      switch (component.statusModel.status) {
        case 'Ready':
          return 'Ready To Collect';
        case 'PartiallyReady':
          return 'Partially Ready';
        case 'ReturnedStock':
          return 'Returned Stock';
        case 'AwaitingGP':
          return 'Sent to GP';
        case 'GPApproved':
          return 'GP Approved';
        case 'CollectionOverdue':
          return 'Patient Collection is Overdue';
        case 'AwaitingGPOverdue':
          return 'Sent to GP — Overdue';
        case 'UnableToFill':
          return 'Unable to fill';
        case 'OnOrder':
          return 'Item On order';
        case 'PharmacyProcessing':
          return 'Pharmacy Processing';
        default:
          return this.data.status;
          break;
      }
    } else {
      return this.data.status;
    }
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  displayLastDispense(component: any): string {
    if (component && component.drug && component.drug.lastDispenseDate) {
      return moment(component.drug.lastDispenseDate).format('DD/MM/YYYY');
    } else { return '-'; }
  }
  orderNo(): string {
    return this.data && this.data.orderNo ? `Order #: ${this.data.orderNo}` : '';
  }
  Notes(): string {
    const taskData = JSON.parse(this.data.jSonTaskData);
    if (taskData && taskData.Drugs && taskData['Notes']) {
      return `Note from patient: '${taskData.Notes}'`;
    } else {
      return '';
    }
  }
  onActionChange(value, component) {
    const componentObj = {
      componentId: component && component.id,
      status: value.target.value
    };
    const foundItem = this.componentStatus.find(x => x.componentId === componentObj.componentId);
    if (foundItem) {
      foundItem.status = componentObj.status;
    } else {
      this.componentStatus.push(componentObj);
    }
    if (this.dispenseRequest.components && this.dispenseRequest.components.length === this.componentStatus.length) {
      this.isMessageFormDisabled = false;
    }
  }

  onResendMessage() {
    this.resendMessageDisabled = true;
    const taskData = JSON.parse(this.data.jSonTaskData);

    this.api.resendMessageToGP(taskData.PatientRequestId).subscribe(result => {
      this.messageResendSucces = result.resultMessage.replace('completed successfully.', '');
      this.loadData();
    }, err => {
      this.sendError = true;
      this.messageResendSucces = err.error.resultMessage.replace('not found.', '');
    }
    );
  }

  onSave() {
    const taskData = JSON.parse(this.data.jSonTaskData);
    if (this.performAction.getPerformAction() === 'UnableToFill') {
      this.dispenseRequest.components.forEach(element => {
        const foundItem = this.componentStatus.find(x => x.componentId === element.id);
        if (!foundItem) {
          this.componentStatus.push({
            componentId: element && element.id,
            status: PatientRequestStatus.UnableToFill
          });
        }
      });
    }
    const dispenseDetails = {
      patientRequestId: taskData.PatientRequestId,
      statusMessage: this.patientMessage,
      componentStatus: this.componentStatus,
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.api.updateComponentStatus(dispenseDetails).subscribe(result => {
      this.isComponentLoading = true;
      this.api.getDispenseRequest(this.data.patient.patientCode_MedAdvisorGUID, taskData.PatientRequestId).subscribe(res => {
        this.dispenseRequest = res.data;
        this.isComponentLoading = false;
      });

      Swal.fire({
        title: 'Changes Saved',
        customClass: {
          confirmButton: 'btn btn-primary btn-sweet-confirm'
        },
        icon: 'success'
      }).then((res) => {
        if (res.value) {
          this.OnComplete.emit(null);
          this.router.navigateByUrl('/messages');
        }
      });
    }, err => {
      console.log('Error, please try again - ', err);
    }
    );
  }
}
