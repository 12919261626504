import { Component, OnInit, Output, Input, EventEmitter, ViewEncapsulation, Inject, AfterViewInit, DoCheck } from '@angular/core';
import { CommsModule } from 'src/app/utility/comms/comms.module';
import { ChangeDetectionStrategy } from '@angular/core';
import { IAPIService, OperationResult } from 'src/app/api/iapi.service';
import { PracticeDetails, PracticePharmacyCommunicationChannels } from 'src/app/api/ApiRecordTypes/PracticeRecord';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DataService } from 'src/app/surgeries/practiceData.service';
import { DialogConfig, DialogService } from 'src/app/utility/base-dialog/dialog.service';
import { SurgerySummaryComponent } from './surgerysummary/surgerysummary.component';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
export class PracticeAddOutput extends OperationResult<{ id: string }> { }

@Component({
  selector: 'app-surgerydetails',
  templateUrl: './surgerydetails.component.html',
  styleUrls: ['./surgerydetails.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SurgeryDetailsComponent implements OnInit, DoCheck, AfterViewInit {
  @Input() isUpdated: boolean;
  @Output() public OnComplete: EventEmitter<PracticeAddOutput> = new EventEmitter<PracticeAddOutput>(true);
  current = new PracticeDetails();
  current$ = new BehaviorSubject<PracticeDetails>(this.current);
  saved = new PracticeDetails();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  practiceId: any;
  savingInProgress = false;
  isVaild = true;
  errorMessage = '';
  actions: string;
  pharmacyId: number;
  communicationsTarget = '';
  communicationsMethod = 'Print';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accountSettings: any;

  constructor(
    private comms: CommsModule,
    private api: IAPIService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private practiceService: DataService,
    private dialogs: DialogService,
    private authService: AuthenticationService,
    
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any,
  ) {

    this.activatedRoute.params.subscribe(params => {
      this.practiceId = params.surgeryId;
    });
    this.authService.authDataSource$.subscribe(
      mission => {
        this.pharmacyId = mission.id;
        this.getGPDetail(mission);
      }
    );
    this.actions = this.practiceService.getActions();
    // Update PharmacyPracticeLink

  }
  getGPDetail(account) {

    this.pharmacyId = account && account.id;
    if (!account) {
      const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
      if (accountSetting && accountSetting.role === 1) {
        this.pharmacyId = accountSetting.id;
      }
      account = accountSetting;
    }
    this.api.getPracticeDetails(this.pharmacyId, this.practiceId).subscribe(result => {
      this.current = result && result.data;
      this.saved = JSON.parse(JSON.stringify(this.current));
      delete this.current.preferences['Communication'];
      if (result && result.data && result.data.communicationChannels) {
        const channels = result.data.communicationChannels;
        const method = channels.endpoints.find(x => x.method === channels.chosenMethod);
        this.communicationsTarget = method ? method.target : '';
        this.communicationsMethod = channels && channels.chosenMethod;
      }
      this.current$.next(this.current);
    }, (err) => {
        // alert('Wrong Practice ID');
        console.log('error', err);
      });
  }
  ngAfterViewInit() {
    this.getGPDetail(this.accountSettings);
  }
  ngDoCheck() {
    this.accountSettings = JSON.parse(this.sessionStorage.getItem('accountSettings'));
  }
  dataSaved(): boolean {
    const currentMethod = this.current && this.current.communicationChannels && this.current.communicationChannels.endpoints[0]
     && this.current.communicationChannels.endpoints[0].method;
    const currentTarget = this.current && this.current.communicationChannels && this.current.communicationChannels.endpoints[0]
     && this.current.communicationChannels.endpoints[0].target;
    if ((this.current.preferences.notes !== undefined) && (this.current.preferences.notes !== this.saved.preferences.notes )) {
      return false;
    }
    if ((this.current.preferences.hoursuntilscriptoverdue !== undefined)
          && (this.current.preferences.hoursuntilscriptoverdue !== this.saved.preferences.hoursuntilscriptoverdue )) {
      return false;
    }
    if (this.current.communicationChannels && currentMethod && (currentMethod !== this.communicationsMethod )) {
    return false;
    }
    if (this.current.communicationChannels && currentTarget && (currentTarget !== this.communicationsTarget )) {
      return false;
    }
    return true;
  }
  ngOnInit() {
    this.actions = this.practiceService.getActions();
    if (this.actions && this.actions === 'New') {
      this.comms.setPage({ pageTitle: 'Add New GP Surgery', page: 'surgerydetails', pageIcon: '' });
    } else {
      this.comms.setPage({ pageTitle: 'Update GP Surgery', page: 'surgerydetails', pageIcon: '' });
    }

  }
  isContentSaved(): boolean {
    if (this.savingInProgress) { return true; }
    return this.dataSaved();
  }
  savePracticeDetails() {
    if (this.formValid()) {
      this.savingInProgress = true;
      const channels = new PracticePharmacyCommunicationChannels();
      channels.chosenMethod = this.communicationsMethod;
      channels.endpoints = [
        {
          target: this.communicationsTarget,
          method: this.communicationsMethod
        }
      ];
      channels.usePracticeEndpoint = false;

      const details = {
        id: this.current.id,
        pharmacyId: this.pharmacyId,
        practiceId: this.current.practice.id,
        preferences: this.current.preferences,
        communicationChannels: channels
      };
      this.api.setPracticeDetails(details).subscribe(
        () => {
          this.dialogs.openInformationDialog(['Successfully updated'], 'OK').then(() => {
            this.router.navigate(['/surgeries']);
          });
        }
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleButton(action) {
    this.router.navigate(['/surgeries']);
  }
  address(): string {
    const fields =  this.current && this.current.practice && this.current.practice.address &&
    [this.current.practice.address.line1, this.current.practice.address.line2,
     this.current.practice.address.suburb, this.current.practice.address.postCode, this.current.practice.address.state ];
     return fields && fields.filter( x => x !== null).join(', ');
  }
  deleteSurgery() {
    const dialogConfig = new DialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '475px';
    this.dialogs.OpenComponentDialog(SurgerySummaryComponent, this.current, dialogConfig).then((result) => {
      if (result != null) {
        this.router.navigate(['/surgeries']);
      } else {
        console.log('Closed externally');
      }
    });
  }
  deliveryMethod() {
    switch (this.communicationsMethod) {
      case 'EmailPlainText':
        return 'Email';
      case 'Email':
        return 'Email (nhs.net)';
      case 'Fax':
        return 'Fax number';
      case 'Sms':
        return 'SMS number';
      default:
        return 'Email';
    }
  }
  onEndpointChange(value) {

    const endpoints = this.current && this.current.communicationChannels && this.current.communicationChannels.endpoints;
    if (endpoints) {
      const possibleEndpoint = endpoints.find(x => (x.method && x.method) === value);
      if (possibleEndpoint && possibleEndpoint.target) {
        this.communicationsTarget = possibleEndpoint.target;
      } else {
        this.communicationsTarget = null;
      }
    }
    this.formValid();
  }

  formValid(): boolean {
    const target = this.communicationsTarget;
    const method = this.communicationsMethod;
    this.isVaild = true;
    if (method !== 'Print' && (target === null || target === '' || target === undefined)) {
      this.errorMessage = `${this.deliveryMethod()} is not valid`;
      this.isVaild = false;
      return false;
    }
    if ((method === 'Email') && (!UtilsService.EmailValidate(target))) {

        this.errorMessage = `${this.deliveryMethod()} is not valid`;
        this.isVaild = false;
        return false;
    }
    if ((method === 'Sms') && (!UtilsService.AUPhoneValid(target))) {
      this.errorMessage = 'Phone number is not valid';
      this.isVaild = false;
      return false;
    }

    if ((method === 'Fax') && (!UtilsService.FaxNumberValid(target))) {
      this.errorMessage = 'Fax number is not valid';
      this.isVaild = false;
      return false;
    }
    return true;
  }
}
