import {
  Component, OnInit, ViewEncapsulation, ChangeDetectorRef,
  NgZone, AfterViewInit, ViewChildren, QueryList, ChangeDetectionStrategy, Output, EventEmitter, ViewChild, ElementRef
} from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from '../../authentication/authentication.service';
import { IAPIService, PendingTaskRecord } from '../../api/iapi.service';
import { CommsModule } from 'src/app/utility/comms/comms.module';
import { DataSourceController } from '../../utility/filtering/datasourcecontroller';
import { FilterControlDirective } from 'src/app/utility/filtering/filtercontrol.directive';
import { SortControlDirective } from 'src/app/utility/filtering/sortcontrol.directive';
import { DialogConfig, DialogService } from 'src/app/utility/base-dialog/dialog.service';
import { PatientAddComponent } from 'src/app/patients/patientadd/patientadd.component';
import { OrderComponent } from '../order/order.component';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/utility/utilsservice/UtilsService';
import { retryWhen, delay, take, mergeMap, catchError } from 'rxjs/operators';
import { AppConfigService } from '../../app-config.service';
import { LoaderService } from 'src/app/utility/utilsservice/loader.services';
import { DataService } from 'src/app/utility/dataservice/data.service';
import { Observable, of, Subject } from 'rxjs';
import { NoData, OperationPagedResult } from 'src/app/api/ApiRecordTypes/OperationResults';
import { MessageBookingDialogComponent } from '../messagebookingdialog/messagebookingdialog.component';
import { IGlobalLoggingInterface } from 'src/app/logging/global-logging.interface';
import { EnrichedPatientModel } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-message-list',
  templateUrl: './messagelist.component.html'
})
export class MessageListComponent implements OnInit, AfterViewInit {
  isDownloadedPage = false;
  DownloadScriptsButtonText = '0 of 0';
  DeleteRequestsButtonText = 'Delete 0 Requests';
  showSurgeries: boolean;
  showCustomerText: boolean;
  dateFormat: string;
  API: IAPIService;
  isLoading = false;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  zeroTranslateYId: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  eventOpenMessageInbox = new Subject<any>();
  eventTabSwitchInbox = new Subject<void>();
  // actioncontroller: ActionController = new ActionController();
  message: PendingTaskRecord;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any;
  messageStatus = 'Inbox';
  patientNameSearch = '';
  filterStatus = '';
  isSearched = false;
  numberOfMessages = 0;
  numberOfMessagesLessThanPageSize = true;
  patientNameNotPresentText = 'No patient record';

  datacontroller: DataSourceController<PendingTaskRecord, NoData> = new DataSourceController<PendingTaskRecord, NoData>(
    (x) => this.API.pendingTasks.createListFilter(x),
    (y) => this.API.pendingTasks.getListPage(y),
    null,
    null,
    (z) => { this.EnrichAndFormatData(z); }
  );
  isShowMessageInbox = false;
  NumberOfResults = 0;
  NumberOfSelected = 0;
  isMessageLoading = false;
  isCompleted = false;
  public isBroadcastMessage = false;
  nameText = 'Name, phone and GP';
  CustomerText = 'All Patient Names';
  toggleSearchBox = false;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  dismissedItems: any[] = [];
  @ViewChildren(FilterControlDirective) appFilterItems: QueryList<FilterControlDirective>;
  @ViewChildren(SortControlDirective) appSorterItems: QueryList<SortControlDirective>;
  @ViewChild('homefilter') homefilter: ElementRef;
  @ViewChild('filterStatusMobile') filterStatusMobile: ElementRef;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() goToDetail: EventEmitter<any> = new EventEmitter();
  constructor(
    private cdr: ChangeDetectorRef,
    public zone: NgZone,
    private authenticator: AuthenticationService,
    private api: IAPIService,
    private comms: CommsModule,
    private dialogs: DialogService,
    private router: Router,
    public dataService: DataService,
    private configService: AppConfigService,
    public loaderService: LoaderService,
    private logService: IGlobalLoggingInterface
  ) {
    this.API = api;
  }

  EnrichAndFormatData(data: OperationPagedResult<PendingTaskRecord, NoData>) {

    for (const idx of Object.keys(data.data)) {
      const item: PendingTaskRecord = data.data[idx];
      const patientIsNotPresent = item && !item.patient;
      if (patientIsNotPresent) {
        item.patient = new EnrichedPatientModel();
      }
      item.patient.formattedPhone = item.patient.phone ? UtilsService.formatPhone(item.patient.phone) : '';
      item.patient.formattedFirstName = item.patient.firstName ? item.patient.firstName : '';
      item.patient.formattedLastName = item.patient.lastName ? item.patient.lastName : '';
      item.patient.fullName = patientIsNotPresent ?
                                this.patientNameNotPresentText :
                                item.patient.formattedFirstName + ' ' + item.patient.formattedLastName;
    }
    this.countDistictPhoneNumbers(data);
  }

  ngOnInit() {
    this.config = this.configService.getConfig();
    if (this.config && this.config.features && this.config.features.ShowCustomerText) {
      this.CustomerText = 'All Customer Names';
    }

    if (!this.config.features.ShowSurgeries) {
      this.nameText = 'Name';
    }
    this.comms.setPage({ pageTitle: 'Home', page: 'messages', pageIcon: '' });
    this.datacontroller.isLoading$.subscribe(value => {
      this.isLoading = value;
    });
    this.datacontroller.datasource.isPageLoading$.subscribe(value => {
      this.isMessageLoading = value;
      this.numberOfMessages = this.datacontroller.datasource._cachedData.length;
      if (this.numberOfMessages > 10) {
        this.numberOfMessagesLessThanPageSize = false;
      }
    });
  }
  refresh() {
    this.datacontroller.enableDataLoading();
  }
  countDistictPhoneNumbers(data: OperationPagedResult<PendingTaskRecord, NoData>) {
    const dict = {};
    let errormessage = '';
    for (const idx of Object.keys(data.data)) {
      const item: PendingTaskRecord = data.data[idx];
      if (item && item.type === 'PatientRequest') {
        if (dict[item.patient.id]) {
          if (dict[item.patient.id] !== item.patient.phone) {
            const lastFourDigits = item.patient.phone.substring( item.patient.phone.length - 4);
            errormessage += `id: ${item.patient.id}, phone: ${lastFourDigits};`;
          }
        } else {
          dict[item.patient.id] = item.patient.phone;
        }

      }
    }
    if (errormessage) {
      this.logService.logInformation('Inbox load for pharmacy had patients with multiple phone numbers: ' + errormessage, 'countDistictPhoneNumbers', 'MessageListComponent');

    }
  }

  ngAfterViewInit() {
    this.datacontroller.InitialiseSortersIndirectly(this.appSorterItems);
    this.datacontroller.InitialiseFiltersIndirectly(this.appFilterItems);
    this.datacontroller.enableDataLoading();
  }
  getRowClass(message: PendingTaskRecord): string {
    this.message = message;
    if (message == null) {
      return 'Loading';
    }
    switch (message.type) {
      case 'PatientRequest':
        return 'PatientRequest';
      case 'PatientSignup':
        return 'PatientSignup';
      case 'Message':
        return 'Message';
      case 'CalendarBooking':
        return 'CalendarBooking';
      case 'ServiceBooking':
        return 'ServiceBooking';
      case 'BookingCancelled':
        return 'BookingCancelled';
      default:
        return 'Loading';
    }
  }
  invite() {
    const dialogConfig = new DialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '1200px';
    dialogConfig.height = '650px';
    this.dialogs.OpenComponentDialog(PatientAddComponent, { ShowCancel: true }, dialogConfig).then((result) => {
      if (result === null) {
        console.log('Closed externally');
      }
    });
  }

  stopProppagationHandler(e) {
    e.stopPropagation();
  }

  handleEventPerformServerAction(value) {
    this.action(value.data, value.action);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  action(data: any, action: any) {
    if (action.processing) {
      return;
    }
    const event = {data: data, action: action };
    const actionCode = event.action.code.toString().toLowerCase();
    const actionLabel = event.action.label;
    const defaultActionLabel = this.defaultButtonAction(data).label;
    const previousDefaultButtonAction = this.defaultButtonAction(data);

    previousDefaultButtonAction.processing = true;
    previousDefaultButtonAction.label = 'Processing';

    let result = of(null);
    if ('CalendarBooking' === event.data.type || 'ServiceBooking' === event.data.type || 'BookingCancelled' === event.data.type) {
      switch (actionCode) {
        case 'view':
          this.viewMessageBookingDialog(event.data);
          break;
        case 'dismiss':
          result = this.dismissBookingAction(event);
          break;
      }
    } else if (event.action.clientSide) {
      switch (actionCode) {
        case 'viewmessage':
          this.viewMessage(event.data);
          break;
        case 'view':
          this.replyMessage(event.data);
          break;
        case 'managepatient':
          this.managePatient(event.data);
          break;
        case 'manageorder':
          this.dataService.setPerformAction(event.action.code);
          this.manageOrder(event.data);
          break;
        case 'GPApproved':
          result = this.performGPApprovedAction(event.data);
          /* falls through */
        case 'print':
        case 'generatepdf':
          result = result.pipe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            mergeMap((_) => {
              return this.generatePdf(event.data);
            })
          );
          break;
      }
    } else if (event.action.code === 'UnableToFill') {
      this.dataService.setPerformAction(event.action.code);
      this.manageOrder(event.data);
    } else if (event.action.code === 'GPApproved') {
      result = this.performGPApprovedAction(event);
    } else {
      result = this.performServerAction(event, false, actionLabel);
    }
    result.subscribe(() => {
      previousDefaultButtonAction.processing = false;
      if (previousDefaultButtonAction.label === 'Processing') {
        previousDefaultButtonAction.label = defaultActionLabel;
      }

      this.cdr.markForCheck();
    });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  collectedOrder(data: any): void {
    if (!data) {
      return;
    }
    if (!this.dismissedItems.includes(data.id)) {
      this.dismissedItems.push(data.orderNo);
    }
  }
  performGPApprovedAction(event: {action, data}) {
    return this.api.performGPApprovedAction(event.data.id)
      .pipe(
        mergeMap(result => {
          if (result) {
            event.data.status = event.action.label;
            event.data.statusCode = event.action.code;
          }
          const index = event.data.actionsAvailable.findIndex(x => x.code === 'GPApproved');
          event.data.actionsAvailable.splice(index, 1);
          return of(event);
        }
      )
    );
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  performServerAction(event: {action, data}, changeLabel, actionLabel): Observable<any> {
    // PerformAction with action and id/
    return this.api.performAction(event.action.code, event.data.id)
    .pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap(_ => {
        const taskData = JSON.parse(event.data.jSonTaskData);
        const RequestType = taskData && taskData.RequestType;
        event.data.status = `${actionLabel} ${RequestType && RequestType === 'DispenseRequest' ? '(ERD)' : ''}`;
        event.data.statusCode = event.action.code;
        if (changeLabel) {
          event.data.actionsAvailable[0].label = 'Completed';
        }
        if (event.action.code === 'ReadyToCollect') {
          event.data.actionsAvailable[0].label = 'Collected';
          event.data.actionsAvailable[0].code = 'Collected';
        } else if (event.action.code === 'Collected' || event.action.code === 'Dismiss' || event.action.code === 'Confirm') {
          this.collectedOrder(event.data);
          this.datacontroller.datasource.removeItem(event.data);
        }
        return of(event);
      }),
      catchError(error => {
        console.log(error);
        return of(error);
      })
    );
  }

  dismissBookingAction(event: {action, data}) {
    return this.api.performAction(event.action.code, event.data.id)
    .pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap((_) => {
        this.datacontroller.datasource.removeItem(event.data);
        return of(null);
      }),
      catchError(error => {
        console.log(error);
        return of(error);
      })
    );
  }

  reloadInbox() {
    this.isCompleted = false;
    this.isBroadcastMessage = false;
    this.eventTabSwitchInbox.next();
  }

  reloadBroadcasts() {
    this.isCompleted = false;
    this.isBroadcastMessage = true;
    this.isMessageLoading = true;
  }

  reload() {
    this.isMessageLoading = true;
    this.isCompleted = true;
    this.isBroadcastMessage = false;
    this.eventTabSwitchInbox.next();
    this.datacontroller.ClearSorters();
  }

  inbox() {
    this.isMessageLoading = true;
  }

  goToOrderDetails(data: PendingTaskRecord, action: string) {
    this.dataService.setPerformAction(action);
    this.router.navigateByUrl('messages/order', {state: data});
  }
  onSort() {
    this.datacontroller.enableDataLoading();
  }
  generatePdf(data) {
    const taskData = JSON.parse(data.jSonTaskData);
    const RequestType = taskData && taskData.RequestType;
    // all /GeneratePdf/{pending_task_id}, which returns a uuid
    return this.api.generatePdf(data.id)
      .pipe(
        mergeMap(result => {
          // Only Print Action: Next status
          if (data.statusCode === 'Pending') {
            data.actionsAvailable[0].label = 'Ready to Collect';
            data.actionsAvailable[0].code = 'ReadyToCollect';
            data.actionsAvailable[0].clientSide = false;
            data.statusCode =  'AwaitingGP';
            data.status = `SENT TO GP ${RequestType && RequestType === 'DispenseRequest' ? '(ERD)' : ''}`;
          }

          // Pass the uuid to /PdfUrl/{uuid} until it returns a URL
          return this.api.getPDFUrl(result.data).pipe(
            retryWhen(errors => errors.pipe(delay(3000), take(5)))
          );
      }),
      mergeMap((url) => {
        if (url) {
          window.open(url.data);
        }
        return of(null);
      }),
      catchError((error) => {
        console.log(error);
        return of(null);
      }));
  }
  manageOrder(data: PendingTaskRecord) {
    const dialogConfig = new DialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    this.dialogs.OpenComponentDialog(OrderComponent, data, dialogConfig).then((result) => {
      if (result != null) {
        this.router.navigate(['/messages']);
      } else {
        console.log('Closed externally');
      }
    });
  }

  viewMessageBookingDialog(data: PendingTaskRecord) {
    const dialogConfig = new DialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    this.dialogs.OpenComponentDialog(MessageBookingDialogComponent, data, dialogConfig);
  }

  managePatient(data: PendingTaskRecord) {
    if (data.patient.isGuest) {
      return;
    }
    const id = data && data.patient && data.patient.patientCode_MedAdvisorGUID;
    if (id !== null) {
      this.router.navigate(['/patients', id]);
    }
  }


  createDateString(data: PendingTaskRecord) {
    if (data && data.createDateUtc) {
      return moment(data.createDateUtc).format('DD/MM/YYYY');
    }
    return '';
  }
  createTimeString(data: PendingTaskRecord) {
    if (data.createDateUtc) {
      return moment(data.createDateUtc).format('HH:mm A Z');
    }
    return '';
  }
  gpName(data: PendingTaskRecord): string {
    if (
      data && data.practice &&
      data.practice.name) {
      return data.practice.name;
    }
    return  '';
  }

  displayTooltip(data: PendingTaskRecord): string {
    return data.patient ? `View ${data.patient.fullName} ${moment(data.patient.dateOfBirth).format('DD-MM-YYYY')}
      ${this.showSurgeries ? ', NHS: ' +  data.patient.externalSystemCode &&
      data.patient.externalSystemCode.replace(/^(.{3})(.{3})(.*)$/, '$1 $2 $3') : ''}` : '';
  }

  displayNHS(data: PendingTaskRecord): string {
    return data.patient ? `${data.patient.externalSystemCode &&
      data.patient.externalSystemCode.replace(/^(.{3})(.{3})(.*)$/, '$1 $2 $3') }` : '';
  }
  orderNo(data: PendingTaskRecord): string {
    return data && data.orderNo ? `#${data.orderNo}` : '';
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  calendarTime(date: any): string {
    return UtilsService.calendarTime(date);
  }
  status(data: PendingTaskRecord): string {
    return data && data.status ? data.status : '';
  }

  statusColor(data: PendingTaskRecord): string {
    switch (data.statusCode) {
      case 'Pending':
      case 'Cancelled':
      case 'AwaitingGP':
      case 'GPApproved':
      case 'OnOrder':
      case 'Dismiss':
      case 'AwaitingPharmacyApproval':
      case 'RemoteSignupRequested':
      case 'UnableToFill':
      case 'Collected':
        return '#555555';
      case 'ReadyToCollect':
        return '#069B4F';
      case 'AwaitingGPOverdue':
        return '#D8323E';
      case 'PartiallyReady':
        return '#FFA500';
      default:
        return '';
    }
  }

  statusMobile(data: PendingTaskRecord): string {
    const statusMappings = {
      'Pending': 'Order Received',
      'UnableToFill': 'Unable to Fill',
      'Cancelled': 'Cancelled',
      'Dismiss': 'Cancelled',
      'AwaitingGP': 'Sent to GP',
      'GPApproved': 'Cancelled',
      'AwaitingPharmacyApproval': 'Awaiting Pharmacy Approval',
      'RemoteSignupRequested': 'Remote Signup Requested',
      'ReadyToCollect': 'Ready to Collect',
      'AwaitingGPOverdue': 'Sent to GP — Overdue',
      'PartiallyReady': 'Partially Ready',
      'Collected': 'Collected',
      'OnOrder': 'Item on Order'
    };
    let statusMapping = data && data.statusCode && statusMappings[data.statusCode];
    statusMapping = statusMapping ? statusMapping : 'Unknown';
    return statusMapping;
  }

  materialIcon(data: PendingTaskRecord) {
    switch (data.statusCode) {
      case 'Pending':
        return '../../../assets/images/statusPDFCreated.svg';
      case 'ReadyToCollect':
        return '../../../assets/images/statusReadyToCollect.svg';
      case 'PartiallyReady':
        return '../../../assets/images/statusPartiallyReady.svg';
      case 'Cancelled':
        return '../../../assets/images/statusUnableToFill.svg';
      case 'UnableToFill':
        return '../../../assets/images/statusUnableToFill.svg';
      case 'Dismiss':
          return '../../../assets/images/statusUnableToFill.svg';
      case 'AwaitingGP':
        return '../../../assets/images/statusSentToGP.svg';
      case 'GPApproved':
        return '../../../assets/images/statusGPApproved.svg';
      case 'AwaitingGPOverdue':
        return '../../../assets/images/statusOverdueAlert.svg';
      case 'AwaitingPharmacyApproval':
      case 'OnOrder':
        return '../../../assets/images/statusAwaiting.svg';
      case 'RemoteSignupRequested':
        return '../../../assets/images/statusSignUpRequested.svg';
      case 'Collected' :
          return '../../../assets/images/statusCollected.svg';
      case 'PharmacyProcessing' :
            return '../../../assets/images/statusPharmacyProcessing.svg';
      case 'Read':
      case 'Unread':
        return '../../../assets/images/message.svg';
      default:
        return '';
    }
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  defaultButtonAction(data: PendingTaskRecord): any {
    if (data.type === 'Message' && this.isCompleted) {
      let index = -1;
      for (let i = 0; i < data.actionsAvailable.length; i++) {
        if (data.actionsAvailable[i].code === 'ViewMessage') {
          index = i;
        }
      }
      if (index === -1) {
        data.actionsAvailable.unshift({label: 'View Messages', code: 'ViewMessage', clientSide: true});
      }
    }
    if (data.actionsAvailable && data.actionsAvailable[0].code === 'managePatient' && this.config.features.ShowCustomerText) {
      data.actionsAvailable[0].label = 'Manage Customer';
    }

    return data.actionsAvailable ? data.actionsAvailable[0] : null;
  }

  callGoToDetails() {
    this.goToDetail.next();
  }

  getAvatarName(str: string) {
    const initials = str.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  openSearchFilter() {
    this.toggleSearchBox = !this.toggleSearchBox;
  }

  cdkViewportScroll(event) {
    if (event.target.scrollTop >= 110) {
      if (this.homefilter.nativeElement.style.display !== 'none') {
        this.homefilter.nativeElement.style.display = 'none';
      }
    } else if (this.homefilter.nativeElement.style.display !== 'block')  {
      this.homefilter.nativeElement.style.display = 'block';
    }
  }
  displayCarerMode(record): string {
    return record && record.patient && record.patient.carerName ? `(Carer: ${record.patient.carerName})` : '';
  }

  messageStatusMobileOnChange(e) {
    this.isLoading = true;
    this.dismissedItems = [];
    this.doFilter('MessageStatus', e.target.value);
  }

  statusMobileOnChange() {
    this.isLoading = true;
    this.doFilter('Status', this.filterStatus);
  }

  patientNameMobileOnChange() {
    this.isLoading = true;
    this.doFilter('PatientName', this.patientNameSearch);
  }

  doFilter(appFilterName, appFilterValue) {
    this.numberOfMessagesLessThanPageSize = true;
    this.isLoading = true;
    this.isMessageLoading = true;
    if (this.filterStatus === '' && this.patientNameSearch === '') {
      this.isSearched = false;
    } else {
      this.isSearched = true;
    }
    this.appFilterItems.forEach(item => {
      if (item.GetName() === appFilterName) {
        item.applyFilter(appFilterValue);
        return;
      }
    });
  }

  diableToucheMove(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  updateFilterStatusMobile(e) {
    this.filterStatusMobile.nativeElement.innerHTML = e;
  }
  closeMessageInbox(value: boolean) {
    this.isShowMessageInbox = false;
    if (value === true) {
      setTimeout(() => {
        this.datacontroller.InitialiseSortersIndirectly(this.appSorterItems);
        this.datacontroller.InitialiseFiltersIndirectly(this.appFilterItems);
        this.datacontroller.enableDataLoading();
      }, 3000);
    }
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  viewMessage(data: any) {
    this.isShowMessageInbox = true;
    this.eventOpenMessageInbox.next({
      patientCode: data.patient.patientCode_MedAdvisorGUID,
      firstName: data.patient.firstName,
      lastName: data.patient.lastName,
      viewMessage: true,
      jSonTaskData: data.jSonTaskData
    });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  replyMessage(data: any) {
    this.isShowMessageInbox = true;
    this.eventOpenMessageInbox.next({
      patientCode: data.patient.patientCode_MedAdvisorGUID,
      firstName: data.patient.firstName,
      lastName: data.patient.lastName,
      viewMessage: false,
      jSonTaskData: data.jSonTaskData
    });
  }
}

