



<div class="im1-page text-16-to-14">
  <ng-container [ngSwitch]="display">
    <ng-container *ngSwitchCase="'enterDetails'">
      <div class="row">
        <div class="col-12 px-4">
          <div class="page-title">
            <h2 class='minor-title'>Enter the patient's GP Online Services details:</h2>
          </div>
          <div class="page-body">
            <div class="gp-section">
              <div class="title">Patient's GP Surgery:</div>
              <div class="gp-name">{{practiceName}}</div>
            </div>
            <div class="im1-form-control">
              <form [formGroup]="im1ConnectFormGroup" id="im1-connect">
                <!-- Linkage key or Passphrase input -->
                <div class="input-wrapper mb-3">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Linkage Key or Passphrase</mat-label>
                    <input [formControl]="linkageKeyControl" matInput text-trim [minlength]="defaultMinInputLength"
                          [maxlength]="defaultMaxInputLength"/>
                  </mat-form-field>
                </div>
                <!-- end Linkage key or Passphrase code -->

                <!--  Account ID or AccessID input -->
                <div class="input-wrapper">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Account ID or Access ID</mat-label>
                    <input [formControl]="accountIDControl" matInput text-trim [maxlength]="20"/>
                  </mat-form-field>
                </div>

                <!-- end Account ID or AccessID -->
                <div class="text-center carer-footer">
                  <button [disabled]="im1ConnectFormGroup.invalid || inProgress" mat-flat-button
                          (click)="connectPatientWithPracticeByIM1()" class="btn primary btn-primary">
                          <fa *ngIf='inProgress' name="spinner" animation="spin"></fa>Connect
                  </button>
                  <button mat-flat-button (click)="handleCancel()" class="button-dialog btn-cancel-dialog">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Template registration complete-->

    <ng-container *ngSwitchCase="'error'">
      <div class=" icon-title header-padding icon">
        <div>
          <mat-icon class="text-warning" role="img" aria-hidden="true"> report_problem</mat-icon>
          Unfortunately we could not connect with these credentials. 
          Please ensure you have entered them correctly and their GP matches. 
          If the problem remains, please contact the GP or MedAdvisor support. 

        </div>
        <div class="button-container">
          <button mat-flat-button (click)="goToConnectScreen()" class="btn primary btn-primary">
            Retry
          </button>
          <button mat-flat-button (click)="handleCancel()" class="button-dialog btn-cancel-dialog">
            Cancel
          </button>
        </div>
        
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'success'">
      <div class="icon header-padding">
        <div>
          <mat-icon class="text-success" role="img" aria-hidden="true"> check_circle</mat-icon>
          <h3 role="text"><strong >Successfully connected to GP Online Services.</strong></h3>
          <span>It can take up to 10 minutes for repeat prescriptions listed at the GP surgery to be displayed.</span>
        </div>
        <div class="button-container">
          <button mat-flat-button (click)="handleAccept()" class="btn primary btn-primary">
            Done
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
