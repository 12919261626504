import {Component, EventEmitter, Input, OnDestroy, Output, TemplateRef} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {CalendarDialogData} from 'src/app/booking-calendar/models/view-models/layout-calendar-dialog.view-model';
import {DialogResponse} from 'src/app/utility/base-dialog/general-dialog/general-dialog.component';

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.css']
})
export class BasicDialogComponent implements OnDestroy {
  //#region Input
  @Input()
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public dialogTitle: string | TemplateRef<any>;

  @Input()
  public buttonAcceptTitle: string;

  @Input()
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public buttonCancel: string | TemplateRef<any>;

  @Input()
  public iconClose = true;

  @Input()
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public dialogFooter: TemplateRef<any>;

  @Input()
  public buttonAcceptState = false;

  @Output()
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public OnComplete: EventEmitter<any> = new EventEmitter<any>(true);

  //#endregion

  //#region Properties
  public data: CalendarDialogData = {
    success: DialogResponse.confirm,
    fail: DialogResponse.cancel,
    settings: {}
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public headerTitle: string | TemplateRef<any>;

  public acceptButtonTitle: string;

  public cancelButtonTitle: string;

  // Subscription watch list.
  protected readonly _subscription: Subscription;

  //#endregion

  //#region Accessors

  //#endregion

  //#region Constructor
  public constructor(protected router: Router) {
    this._subscription = new Subscription();
  }

  //#endregion

  //#region Life cycle

  public ngOnDestroy() {
    if (this._subscription && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }

  //#endregion

  //#region Methods

  public navigateTo(code: string) {
    this.handleCancel();
    this.router.navigate([code]);
  }


  public setData(data: CalendarDialogData): void {
    this.data = data;
  }

  public handleCancel() {
    this.OnComplete.emit(this.data.fail);
  }

  public handleAccept() {
    this.OnComplete.emit(this.data.success);
  }

  public listenAction(event: DialogResponse) {
    event === DialogResponse.confirm ? this.handleAccept() : this.handleCancel();
  }

  public headerTitleType(): 'string' | 'template' {
    if (this.dialogTitle instanceof TemplateRef) {
      return 'template';
    }
    return 'string';
  }

  public cancelButtonType(): 'string' | 'template' {
    if (this.buttonCancel instanceof TemplateRef) {
      return 'template';
    }
    return 'string';
  }

  //#endregion

}
