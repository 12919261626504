<!----BODY---->
<div class='pageContent cdk-holder'>
  <!-- <div class = "summary">
		Process script requests and communicate with patients
	</div> -->
  <nav class="page-nav" appFilter="MessageStatus" value="Inbox">
    <input type="radio" name="nav" id="inbox" value="Inbox" checked=true>
    <label for="inbox" class="left" (click)="reloadInbox()">Inbox</label>

    <input *ngIf='config?.features?.Inbox?.ShowAnnouncements' type="radio" name="nav" id="broadcast-message"
      value="BroadcastMessage">
    <label *ngIf='config?.features?.Inbox?.ShowAnnouncements' for="broadcast-message"
      (click)="reloadBroadcasts()">Announcements</label>

    <input type="radio" name="nav" id="completed" value="Completed">
    <label for="completed" (click)="reload()" class="right">Completed</label>
  </nav>
  <div class="page-details" *ngIf="!isBroadcastMessage; else broadcasts">
    <div class="filters" id="filter-header">Filter
      <input class="filter-home-item" type="text" appFilter="PatientName" [placeholder]="CustomerText"
        [(ngModel)]="patientNameSearch" />
      <select class="filter-home-item" appFilter="GPSurgery" *ngIf="config?.features?.ShowSurgeries">
        <option>All GP Surgeries</option>
      </select>
      <select class="filter-home-item" appFilter="Status" [(ngModel)]="filterStatus">
      </select>
      <div class="btn-right">
        <button type="button" class="btn-ghost btn-icon-text" (click)="refresh()" 
          [disabled]="(datacontroller.isLoading$ | async) || (datacontroller.datasource.isPageLoading$ | async)">
          <span class="material-icons">refresh</span>
          <span>Refresh</span></button>
      </div>
    </div>
    <div class="main-grid-panel">
      <div class="message-row message-headers withfilters" id="filter-header-column">
        <div class="header-home-field name-header col1" style="border: 0;">
          <div class="name-header">
            {{nameText}}
          </div>
        </div>
        <div class="header-home-field col2">
          <table class="drugs-header">
            <tr>
              <th class="th-1">Details</th>
              <th class="th-2">
                <nav class="sortOrder" appSorter="dayLeft" value="">
                  <input id="dayLeft" type="checkbox" value="true"><label for="dayLeft">Supply left</label>
                </nav>
              </th>
            </tr>
          </table>
        </div>
        <div class="header-home-field col3 adjust-header">
          <div class="sorting-date">
            <div>Date</div>
            <nav class="sortOrder" appSorter="createDateUtc" value="Desc">
              <input type="radio" name="sortOrder" id="Asc" value="Asc"><label for="Asc" class="sort-icon">
                <i class="fa fa-lg fa-sort-asc" aria-hidden="true"></i>
              </label>
              <input type="radio" name="sortOrder" id="Desc" value="Desc"><label for="Desc" class="sort-icon-desc">
                <i class="fa fa-lg fa-sort-desc" aria-hidden="true"></i>
              </label>
            </nav>
          </div>
        </div>
        <div class="header-home-field col4 adjust-header">Status</div>
        <div class="header-home-field col5 adjust-header">Actions
        </div>
      </div>
      <app-loader></app-loader>
      <cdk-virtual-scroll-viewport class="list-container" id="scroll-content" #mainViewport [itemSize]="70"
        templateCacheSize=0>
        <ng-container *cdkVirtualFor="let data of datacontroller.datasource; templateCacheSize: 0" [ngSwitch]="getRowClass(data)">
          <div
            [ngClass]="{'gridrow message-row': true, 'gridrow message-row-desktop-deleted': dismissedItems.includes(data.id)}"
            *ngIf="message!==null">
            <div class="messagerow col1">
              <div class="messagerow-info">
                <div class="info-item" *ngIf="data.orderNo">
                  <strong>
                    {{orderNo(data)}}
                  </strong>
                </div>
                <div class="info-name-message" (click)="managePatient(data)">
                  <strong [ngClass]="data.patient.isGuest? 'info-name-text' : 'info-name'">
                    <ng-container *ngIf="data.patient">
                      <span class="first-name">{{data.patient.formattedFirstName}}</span><span>&nbsp;</span><span class="last-name">{{data.patient.formattedLastName}}</span>
                    </ng-container>
                    <ng-container *ngIf="!data.patient">
                      {{patientNameNotPresentText}}
                    </ng-container>
                  </strong> {{displayCarerMode(data)}}
                  <div class="only-show-on-hover">
                    <div class="patient-dialog">
                      <div class="dialog-name">
                        <ng-container *ngIf="data.patient">
                          <span class="first-name">{{data.patient.formattedFirstName}}</span><span>&nbsp;</span><span class="last-name">{{data.patient.formattedLastName}}</span>
                        </ng-container>
                        <ng-container *ngIf="!data.patient">
                          {{patientNameNotPresentText}}
                        </ng-container>
                      </div>
                      <div class="dialog-item">
                        <strong *ngIf="data?.patient?.dateOfBirth">Date of birth: </strong>{{data?.patient?.dateOfBirth
                        | date:'dd-MM-yyyy'}}
                      </div>
                      <div class="dialog-item">
                        <strong *ngIf="data?.patient?.dateOfBirth">NHS: </strong>
                        {{displayNHS(data)}}
                      </div>
                    </div>

                  </div>
                </div>
                <div>
                  {{data.patient.formattedPhone}}
                </div>
                <div>
                  {{ gpName(data) }}
                </div>
              </div>
            </div>
            <div class="messagerow col2">
              <ng-container class="message" *ngSwitchCase="'Message'">
                <app-msg-messagerequest [message]="data" style="width: 100%"></app-msg-messagerequest>
              </ng-container>
              <ng-container class="message" *ngSwitchCase="'PatientRequest'">
                <app-msg-patientrequest [message]="data"></app-msg-patientrequest>
              </ng-container>
              <ng-container class="message" *ngSwitchCase="'PatientSignup'">
                <app-msg-patientsignup [patientData]="data" class="gridrow"></app-msg-patientsignup>
              </ng-container>
              <ng-container class="message" *ngSwitchCase="'CalendarBooking'">
                <app-msg-messagebookingdetail (click)="action(data, defaultButtonAction(data))" [data]="data" class="gridrow"></app-msg-messagebookingdetail>
              </ng-container>
              <ng-container class="message" *ngSwitchCase="'ServiceBooking'">
                <app-msg-messagebookingdetail (click)="action(data, defaultButtonAction(data))" [data]="data" class="gridrow"></app-msg-messagebookingdetail>
              </ng-container>
              <ng-container class="message" *ngSwitchCase="'BookingCancelled'">
                <app-msg-messagebookingdetail (click)="action(data, defaultButtonAction(data))" [data]="data" class="gridrow"></app-msg-messagebookingdetail>
              </ng-container>
              <ng-container class="message" *ngSwitchDefault>
                <app-msg-loadingrequest class="message gridrow"></app-msg-loadingrequest>
              </ng-container>
            </div>
            <div class="messagerow col3">
              <div class="date-time-field" [style.color]="statusColor(data)">
                {{createDateString(data)}}<br />{{data?.createDateUtc | date:'shortTime'}}
              </div>
            </div>
            <div class="messagerow col4">
              <div class="status-pending-task message-status-type" [style.color]="statusColor(data)">
                <img class="message-type-icon" [src]="materialIcon(data)" />
                {{status(data)}}
              </div>
            </div>
            <div class="messagerow col5 actions">
              <div class="dropdown action-input" mdbDropdown *ngIf="data?.actionsAvailable?.length > 0">
                <mat-button-toggle-group>
                  <mat-button-toggle [disabled]='defaultButtonAction(data)?.processing'
                    (click)="action(data, defaultButtonAction(data))" class="split-button-2 button-default">
                    <i *ngIf='defaultButtonAction(data)?.processing' class="fa fa-spinner fa-spin login-spinner"></i>
                    {{defaultButtonAction(data)?.label}}
                  </mat-button-toggle>
                  <mat-button-toggle [disabled]="defaultButtonAction(data)?.processing"
                    class="split-button-2 drop-down-button" [matMenuTriggerFor]="dropdownMenuTwo">
                    <mat-icon>arrow_drop_down</mat-icon>
                  </mat-button-toggle>

                </mat-button-toggle-group>

                <mat-menu #dropdownMenuTwo="matMenu" class="custom-drop-menu">
                  <div *ngFor="let item of data?.actionsAvailable, index as i">
                    <button (click)="action(data, item)" mat-menu-item>
                      {{item.label}}
                    </button>
                  </div>
                </mat-menu>
              </div>
            </div>
          </div>
          <div class="gridrow loading-row" *ngIf="!data">
            <app-msg-loadingrequest class="gridrow loading-row"></app-msg-loadingrequest>
          </div>
        </ng-container>
        <div *ngIf="numberOfMessages === 0 && !isSearched && !isLoading && !isMessageLoading"
          class="gridrow messagerow align-center">No Result</div>
      </cdk-virtual-scroll-viewport>
    </div>
    <div class="message-inbox-container">
      <app-message-inbox [isShowMessageInbox]="isShowMessageInbox"
        [eventMessageInbox]="eventOpenMessageInbox.asObservable()"
        [eventTabSwitchMessageInbox]="eventTabSwitchInbox.asObservable()"
        (eventCloseMessageInbox)="closeMessageInbox($event)"
        (eventPerformServerAction)="handleEventPerformServerAction($event)"></app-message-inbox>
    </div>
  </div>
  <ng-template #broadcasts>
    <app-broadcast-message></app-broadcast-message>
  </ng-template>
</div>
<!----END OF BODY---->