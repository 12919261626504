<header class="row-header">
  <ng-container [ngSwitch]="headerTitleType()" *ngIf="dialogTitle">
    <ng-container *ngSwitchCase="'template'">
      <ng-container [ngTemplateOutlet]="dialogTitle"></ng-container>
    </ng-container>
    <span class="row-header__left" *ngSwitchDefault>
      {{dialogTitle}}
    </span>
  </ng-container>
  <div class="row-header__right" *ngIf="iconClose">
    <div class="btn-close" (click)="handleCancel()">
      <span class="material-icons">close</span>
    </div>
  </div>
</header>
<div class="row-body">
  <ng-content></ng-content>
</div>
<footer class="row-footer">
  <ng-container *ngIf="dialogFooter; else defaultFooter">
    <ng-container [ngTemplateOutlet]="dialogFooter"></ng-container>
  </ng-container>
  <ng-template #defaultFooter>
    <ng-container [ngSwitch]="cancelButtonType()">
      <ng-container *ngSwitchCase="'template'">
        <ng-container [ngTemplateOutlet]="buttonCancel"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <button class="button-dialog btn-cancel-dialog" type="button"
          *ngIf="buttonCancel"
          (click)="handleCancel()">
          {{buttonCancel}}
        </button>
      </ng-container>
    </ng-container>
    <button class="button-dialog" type="button"
            [ngClass]="{'btn-disable': buttonAcceptState,
                 'btn-submit': !buttonAcceptState}"
      (click)="handleAccept()">{{buttonAcceptTitle}}
    </button>
  </ng-template>
</footer>
