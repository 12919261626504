import { Injectable } from '@angular/core';
import { IRegionBehaviour } from './iregion-behaviour.module';
import { ICardIconDisplay, DefaultCardIconDisplay } from './Default_Behaviours/DefaultCardIconDisplay.service';
import { IInviteExtensions, DefaultInviteExtensions } from './Default_Behaviours/DefaultInviteExtensions.service';

@Injectable()
export class RegionBehavior implements IRegionBehaviour {
    CardDisplay: ICardIconDisplay = new DefaultCardIconDisplay();
    InviteExtensions: IInviteExtensions = new DefaultInviteExtensions();
}
