<app-basic-dialog
  [dialogTitle]="headerDialog"
  [dialogFooter]="dialogFooter"
  [buttonAcceptTitle]="acceptButtonTitle"
  (OnComplete)="listenAction($event)">
  <div class="edit-service">
    <div class="service__instruction-text">
      <b>* Visible</b>: The service will be visible to patients as offered by the pharmacy
    </div>
    <div class="edit-service__body" [formGroup]="editServiceForm">
      <div class="edit-service__service-table">
        <!-- Edit service Header-->
        <div class="edit-service__service-table-header">
          <div class="edit-service__service-name">
            Service name
          </div>

          <div class="edit-service__service-time">
            Time required
          </div>

          <div class="edit-service__service-desc">
            Description
          </div>

          <div class="edit-service__service-visible">
            Visible
          </div>

          <div class="edit-service__service-action">
          </div>
        </div>
        <!-- Edit service Row-->
        <ng-container *ngFor="let serviceForm of serviceFormArrayControls; index as i">
          <div class="edit-service__service-table-row" #serviceTemplate>
            <ng-container [formGroup]="serviceForm">
              <div class="edit-service__service-name">
                <input maxlength="255" type="text" formControlName="name" placeholder="Service name"
                       (input)="handleChangeServiceName(services[i], serviceForm)"
                       (focusout)="updateService(services[i], serviceForm, serviceKeyAllowToEdit.name)">
              </div>

              <div class="edit-service__service-time">
                <mat-form-field class="width-100" appearance="outline">
                  <mat-select panelClass="select-duration-panel"
                              formControlName="duration"
                              (focusout)="updateService(services[i], serviceForm, serviceKeyAllowToEdit.duration)"
                              disableOptionCentering="true">
                    <mat-option *ngFor="let time of durations" [value]="time">{{time + ' min'}}</mat-option>
                  </mat-select>
                  <mat-icon matSuffix>expand_more</mat-icon>
                </mat-form-field>
              </div>

              <div class="edit-service__service-desc">
            <textarea rows="1" [class.service-desc-active]="activeDescriptionInput === i"
                      [placeholder]="activeDescriptionInput === i ? 'Add a description' : ''" maxlength="4000" formControlName="description"
                      (focus)="activeDescriptionInput = i" (keydown.enter)="$event.preventDefault()"
                      (focusout)="updateService(services[i], serviceForm, serviceKeyAllowToEdit.description); activeDescriptionInput = null">
            </textarea>
              </div>
            </ng-container>

            <div class="edit-service__service-visible">
              <button class="btn btn-basic" mat-button
                      [class.btn-basic]="services[i].visible === 0" [class.btn-default-outline]="services[i].visible === 1"
                      (focusout)="updateService(services[i], serviceForm, serviceKeyAllowToEdit.visible)"
                      (click)="checkVisibility(i, services[i].visible)">
                <span class="material-icons" *ngIf="services[i].visible">visibility</span>
                <span class="material-icons" *ngIf="!services[i].visible">visibility_off</span>
              </button>
            </div>

            <div class="edit-service__service-action">
              <button class="btn btn-basic" *ngIf="services[i].bookingType === 3" mat-button
                      (click)="deleteService(services[i]); recentServiceClickedDelete = services[i]">
                <span class="material-icons">delete</span>
              </button>
            </div>
          </div>
          <ng-container *ngIf="serviceForm.controls['name'].touched">
            <p class="edit-service__input-error" *ngIf="serviceForm.controls['name'].errors?.required">
              This field is required</p>
            <p class="edit-service__input-error" *ngIf="serviceForm.controls['name'].errors?.uniqueName">
              Please enter a unique service name</p>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</app-basic-dialog>

<ng-template #deleteServiceContent>
  <div class="delete-content-dialog">
    <div><span class="material-icons icon-warning">warning</span></div>
    <p>Are you sure you want to permanently delete the specified service: <b>{{recentServiceClickedDelete?.name}}</b>?
    </p>
    <p>Please check if there are any bookings and contact those patients if you are cancelling their bookings.</p>
  </div>
</ng-template>

<ng-template #dialogFooter>
  <button class="btn btn-default-outline btn-add-service"
          matTooltip="Add a new service offered by this pharmacy"
          (click)="addNewService()">
    <span class="material-icons">add</span>
    <span>Add service</span>
  </button>
  <button class="button-dialog btn-submit" type="button"
          (click)="handleAccept()">{{acceptButtonTitle}}
  </button>
</ng-template>

<ng-template #headerDialog>
  <h3 class="custom-row-header__left">Edit services</h3>
  <div class="toast-inline-container" toastContainer></div>
</ng-template>
