import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FullCalendarModule} from '@fullcalendar/angular';
import {ColorPickerModule} from 'ngx-color-picker';
import {ColorSketchModule} from 'ngx-color/sketch';
import {BookingCalendarRoutingModules} from 'src/app/booking-calendar/booking-calendar-routing.modules';
import {AddResourceTimeDialogComponent} from 'src/app/booking-calendar/components/add-resource-time-dialog/add-resource-time-dialog.component';
import {BasicDialogComponent} from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import {ConfirmDialogComponent} from 'src/app/booking-calendar/components/confirm-dialog/confirm-dialog.component';
import {EditResourceDialogComponent} from 'src/app/booking-calendar/components/edit-resource/edit-resource-dialog.component';
import {SettingsDialogComponent} from 'src/app/booking-calendar/components/settings-dialog/settings-dialog.component';
import {
  PopoverComponent,
  ViewCalendarComponent
} from 'src/app/booking-calendar/components/view-calendar/view-calendar.component';
import {CalendarGuard} from 'src/app/booking-calendar/guard/calendar-guard';
import {CalendarDialogService} from 'src/app/booking-calendar/services/calendar-dialog.service';
import {CalendarEventService} from 'src/app/booking-calendar/services/calendar-event.service';
import {BookingDialogComponent} from './components/booking-dialog/booking-dialog.component';
import {PatientSearchDialogComponent} from './components/patient-search-dialog/patient-search-dialog.component';
import {ServiceDialogComponent} from './components/service-dialog/service-dialog.component';
import {WebsiteAccessDialogComponent} from './components/ website-access-dialog/website-access-dialog.component';
import {FilterResultPipe} from './pipes/filter-result.pipe';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {BsDatepickerConfig, BsDatepickerModule, DatepickerModule} from 'ngx-bootstrap/datepicker';

import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import {MatSortModule} from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio'
import {MatTableModule} from '@angular/material/table'
import {MatToolbarModule} from '@angular/material/toolbar'

import {CustomPaginationService} from './services/custom-pagination.service';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgbModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {FormErrorComponent} from './components/form-error/form-error.component';
import {FormSubmitDirective} from './directives/form-submit.directive';
import {FormControlDirective} from './directives/form-control.directive';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { FluVaxFormComponent } from './components/flu-vax-form/flu-vax-form.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastContainerModule} from 'ngx-toastr';
import {CalendarLoaderComponent} from './components/calendar-loader/loader.component';
import {CalendarLayoutComponent} from './components/calendar-layout/calendar-layout.component';
import { ConfirmationDialogComponent } from './components/avail-unavail-dialog/time-confirm-dialog.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    BookingCalendarRoutingModules,
    FullCalendarModule,
    FormsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    MatFormFieldModule,
    MatChipsModule,
    MatInputModule,
    ColorSketchModule,
    ColorPickerModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatButtonModule,
    MatToolbarModule,
    MatDatepickerModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    MatPaginatorModule,
    PaginationModule.forRoot(),
    NgbPopoverModule,
    ScrollingModule,
    MatCheckboxModule,
    NgSelectModule,
    NgbModule,
    MatMenuModule,
    ToastContainerModule,
    MatProgressSpinnerModule,
    RouterModule
  ],
  declarations: [
    ViewCalendarComponent,
    SettingsDialogComponent,
    BookingDialogComponent,
    PatientSearchDialogComponent,
    ServiceDialogComponent,
    BasicDialogComponent,
    AddResourceTimeDialogComponent,
    EditResourceDialogComponent,
    ConfirmDialogComponent,
    WebsiteAccessDialogComponent,
    FilterResultPipe,
    PopoverComponent,
    FormErrorComponent,
    FormSubmitDirective,
    FormControlDirective,
    FluVaxFormComponent,
    CalendarLoaderComponent,
    CalendarLayoutComponent,
    ConfirmationDialogComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  providers: [
    CalendarEventService,
    CalendarDialogService,
    CalendarGuard,
    BsDatepickerConfig,
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginationService
    }
  ],
  exports: [
    BookingDialogComponent,
    PatientSearchDialogComponent,
    ServiceDialogComponent,
    EditResourceDialogComponent,
    ConfirmDialogComponent,
    WebsiteAccessDialogComponent,
    PopoverComponent,
    FormErrorComponent,
    CalendarLoaderComponent,
    CalendarLayoutComponent,
    ConfirmationDialogComponent
  ]
})
export class BookingCalendarModules {

}
