<div class="patient-dialog-header">
  <h3 class="patient-dialog-title">
    {{patientGroup?.name ? patientGroup?.name+'.' : ''}} Review patients
  </h3>
  <button class="btn patient-dialog-close" (click)="handleCancel()">
    <span class="material-icons">close</span>
  </button>
</div>

<form class="broadcast-patient-filter" [formGroup]="patientSearchForm" (ngSubmit)="searchPatient()">
  <div class="broadcast-patient-filter__filter">
    <strong>Filter</strong>
    <div class="input-wrapper">
      <select [formControl]="filterControl" (change)="handleSelectFilter()" class="form-input">
        <option value="0">Included</option>
        <option value="1">Not included</option>
        <option value="2">All</option>
      </select>
    </div>
  </div>

  <div class="broadcast-patient-filter__include-exclude"></div>

  <div class="broadcast-patient-filter__name">
    <strong>Patient name</strong>
    <div class="filter-item">
      <input [formControl]="patientNameControl" type="text"
             (input)="handleInputNameAndDob()"
             (keydown.enter)="searchPatient()" class="patient-search-input"/>
    </div>
  </div>

  <div class="broadcast-patient-filter__dob">
    <strong>DOB</strong>
    <div class="filter-item">
      <input [formControl]="dobControl" type="text" placeholder="dd/mm/yyyy"
             (input)="handleInputNameAndDob()"
             (keydown.enter)="searchPatient()" class="patient-search-input"/>
    </div>
  </div>
</form>

<div class="broadcast-patient-select">
  <div class="broadcast-patient-select__option">
    <div class="input-wrapper">
      <ng-container>
        <img class="select-control" (click)="handleOpenSelectOptions()" src="./assets/images/review-patient-na.svg" alt="N/A">
      </ng-container>
      <div class="option-list" *ngIf="isOptionListShow">
        <div (click)="handleSelectOption('0')">All</div>
        <div (click)="handleSelectOption('1')">None</div>
      </div>
    </div>
  </div>
</div>

<div class="patient-dialog-content">
  <cdk-virtual-scroll-viewport [itemSize]="60">
    <div class="broadcast-patient-list" *cdkVirtualFor="let patient of searchedPatients">
      <ng-container *ngIf="patient">
        <div class="broadcast-patient-list__actions">
          <label class="switch">
            <input type="checkbox" [checked]="isPatientSelected(patient)" (change)="manualSelectPatient(patient)">
            <span class="slider"></span>
          </label>
        </div>

        <div class="broadcast-patient-list__include-exclude">
          <span *ngIf="isIncludePatient(patient)" class="material-icons-outlined"> person_add </span>
          <span *ngIf="isExcludePatient(patient)" class="material-icons-outlined"> person_remove </span>
        </div>

        <div class="broadcast-patient-list__name">
          <div class="row-details">
            <div class="name-wrapper">
              <div class="patient-name-bold">
                <span class="first-name">{{patient.firstName}}</span><span>&nbsp;</span><span class="last-name">{{patient.lastName}}</span>
              </div>
              <div class="carer-mode">{{displayCarerMode(patient)}}</div>
            </div>
            <div>{{displayPhone(patient)}}</div>
            <div *ngIf="patient?.practiceName"><b>GP: </b> {{formatGPName(patient)}}</div>
          </div>
        </div>

        <div class="broadcast-patient-list__dob">
          <div class="row-details">{{displayDOB(patient) | date: 'dd/MM/yyyy'}}</div>
        </div>
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</div>

<div class="patient-dialog-footer">
  <div class="count-patient-group">
    <div>{{checkedPatientIds.length}} in group</div>
    <div>
      <span class="material-icons-outlined"> person_add </span>
      {{includePatientIds.length}} added manually
    </div>
    <div>
      <span class="material-icons-outlined"> person_remove </span>
      {{excludePatientIds.length}} removed manually
    </div>
    <div>{{patientMatchCriteriaIds.length}} match group criteria</div>
  </div>
  <div class="patient-dialog-footer__actions">
    <button class="btn-default-blue btn-text" (click)="handleAccept()">OK</button>
    <a class="clear-button" (click)="handleCancel()">Cancel</a>
  </div>
</div>
<div class="option-list__backdrop" *ngIf="isOptionListShow" (click)="isOptionListShow = false"></div>
