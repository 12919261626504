<app-basic-dialog
  [dialogTitle]="headerDialog"
  [buttonAcceptTitle]="acceptButtonTitle"
  [buttonCancel]="cancelButtonTitle"
  (OnComplete)="listenAction($event)">
  <form [formGroup]="settingsForm">
    <div class="resource">
      <div class="resource__header">
        <h4 class="resource__title">Select resources</h4>
      </div>
      <div class="resource__body row">
        <div class="resource__select">
          <ng-select
            class="select-resource-setting"
            [searchable]="false"
            [clearable]="false"
            (change)="handleSelectResource()"
            [formControl]="resourceControl">
            <ng-option *ngFor="let resource of resources" [value]="resource.id">{{resource.title}}
            </ng-option>
          </ng-select>
        </div>
        <input class="resource__color-picker"
               [style.background]="selectedColor"
               [cpPresetColors]="palette"
               [(colorPicker)]="selectedColor"
               [cpOKButton]="true"
               [cpOKButtonText]="'Choose'"
               (colorPickerSelect)="colorPicked($event)"
               [formControl]="colorControl"
               readonly/>
        <div class="resource__edit-resource col">
          <button class="btn btn-default"  (click)="showEditResourceDialog()">
            <span class="material-icons">edit</span>
            <span>Edit resources</span>
          </button>
        </div>
      </div>
    </div>
    <hr class="line-break">
    <div class="services">
      <div class="services__header row">
        <h4 class="services__title">Select services that can be booked at this resource</h4>
        <div class="services__edit-btn">
          <button class="btn btn-default"
                  matTooltip="Edit the details of services offered by this resource"
                  (click)="showEditServicesDialog()">
            <span class="material-icons">edit</span>
            <span>Edit services</span>
          </button>
        </div>
      </div>
      <div class="services__body">
        <div class="services-list__header d-flex">
          <div class="services-list__action">
          </div>
          <div class="services-list__name">
            Service name
          </div>
          <div class="services-list__time-required">
            Time required
          </div>
          <div class="services-list__description">
            Description
          </div>
        </div>
        <div class="services-list__body" formArrayName="linkServiceToResource" *ngIf="visibleServices">
          <cdk-virtual-scroll-viewport appendOnly itemSize="38"
                                       [style.height.px]="visibleServices.length > 10 ? 380 : visibleServices.length*38">
            <ng-container *cdkVirtualFor="let service of linkServiceToResourceFormArray.controls; index as i">
              <div class="d-flex" *ngIf="visibleServices[i]">
                <div class="services-list__action">
                  <mat-checkbox color="primary" [formControl]="service"
                                (change)="handleServiceCheckboxChange(visibleServices[i], service.value)">
                  </mat-checkbox>
                </div>
                <div class="services-list__name">
                  {{visibleServices[i]?.name}}
                </div>
                <div class="services-list__time-required">
                  {{visibleServices[i]?.duration}} min
                </div>
                <div class="services-list__description" [innerHTML]="visibleServices[i]?.description">
                </div>
              </div>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </div>
    <div class="available-time">
      <div class="available-time__header">
        <h4 class="available-time__title">Available times for this resources</h4>
      </div>
      <div class="row">
        <div class="available-time__edit">
          <!--Settings-->
          <mat-radio-group (change)="availableTimeChange()" [formControl]="timeAvailableControl"
                           class="available-time__radio-group d-flex">
            <mat-radio-button color="primary" id="unavailable-time"
                              (click)="changeTimeSettingOption($event, resourceAvailabilityType.AvailableExcept)"
                              [class.option-active]="timeAvailableControl.value === resourceAvailabilityType.AvailableExcept"
                              [value]="resourceAvailabilityType.AvailableExcept">
              Use opening hours and specify your <b>unavailable times</b>
            </mat-radio-button>
            <div class="available-time__or">OR</div>
            <mat-radio-button color="primary" id="available-time"
                              (click)="changeTimeSettingOption($event, resourceAvailabilityType.AvailableOnly)"
                              [class.option-active]="timeAvailableControl.value === resourceAvailabilityType.AvailableOnly"
                              [value]="resourceAvailabilityType.AvailableOnly">
              Specify all <b>available times</b>
            </mat-radio-button>
          </mat-radio-group>
          <!--Available times-->
          <div class="available-time__edit-textbox">
            <div class="available-time__time-item" [class.time-active]="selectedAvailableTime?.item === timeItem?.item"
                 (click)="selectedAvailableTime = timeItem"
                 *ngFor="let timeItem of availableTimes; index as i">
              {{timeItem.timeDisplay}}
            </div>
          </div>
          <div class="available-time__action-textbox d-flex">
            <button class="btn btn-basic" (click)="addOrEditAvailableTime(true)">
              <span class="material-icons">add</span>
              <span>{{timeAvailableControl.value === resourceAvailabilityType.AvailableOnly ? 'Add an available time' : 'Add an unavailable time'}}</span>
            </button>
            <div class="edit-or-delete-btn-group">
              <button class="btn btn-basic" (click)="addOrEditAvailableTime(false)">
                <span class="material-icons">edit</span>
              </button>
              <button class="btn btn-basic" (click)="deleteAvailableTime()">
                <span class="material-icons">delete</span>
              </button>
            </div>
          </div>
        </div>
        <div class="available-time__opening-hour">
          <div class="opening-hour">
            <div class="opening-hour__title">Your opening hours</div>
            <div class="opening-hour__table">
              <ng-container *ngFor="let item of weekOpenTime">
                <div class="d-flex" *ngIf="item?.day">
                  <div class="left">
                    {{item?.day}}
                  </div>
                  <div class="right">
                    {{item?.time}}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-basic-dialog>

<ng-template #headerDialog>
  <h1 class="dialog-title">
    Assign services to rooms and resources
  </h1>
  <div class="toast-inline-container" toastContainer></div>
</ng-template>
