import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import { OnInit, ViewChild } from '@angular/core';
import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from 'lodash';
import { DrugModel, DrugSearchModel } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';
import { IAPIService } from 'src/app/api/iapi.service';
import { DrugsDataSource } from 'src/app/utility/filtering/drugdatasource';

export interface DialogData {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  selectedDrug: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  medicationList: any;
  mode: string;
}

enum ScreenState {
  search = 'Search',
  edit = 'Edit',
  add = 'Add',
  searchAll = 'SearchAll',
}
enum ScreenTitle {
  search = 'Edit medication',
  edit = 'Edit medication details',
  addDetails = 'Add medication manually',
  add = 'Add medication manually',
  searchAll = 'Search',
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-drug-update',
  templateUrl: './drugupdatedialog.component.html',
})

export class DrugUpdateDialogComponent implements OnInit {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  drugList: any;
  selectedMedication: DrugModel = null;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  selectedDrug: any;
  repeatCycleFrequency = 'days';
  repeatCycle = 28;
  supply = 0;
  searchAll = '';
  erd: boolean;
  matchingErr = false;
  quantity: number;
  source: number;
  screenState: string = ScreenState.search;
  _instructions: string;
  previousScreenState: string = ScreenState.search;
  title: string = ScreenTitle.search;
  dataSource: DrugsDataSource;
  isValidSearchText = true;
  rptsLeft = 0;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  constructor(
    public dialogRef: MatDialogRef<DrugUpdateDialogComponent>,
    private api: IAPIService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      if (data && data.selectedDrug) {
        this.dataSource = new DrugsDataSource(api, data.selectedDrug.activeName, true);
      }
    }

  ngOnInit() {
    if (this.data.mode === ScreenState.edit) {
      this.screenState = ScreenState.search;
      this.title = this.getTitlebyScreenState(this.screenState);
    } else {
      if (this.data.mode === ScreenState.add) {
        this.screenState = ScreenState.add;
        this.title = this.getTitlebyScreenState(this.screenState);
      }
    }
    this.selectedDrug = this.data.selectedDrug;
    this._instructions = this.selectedDrug && this.selectedDrug._instructions;
    this.quantity = this.selectedDrug && this.selectedDrug.quantity;
    this.source = this.selectedDrug && this.selectedDrug.source;

    const drugSearch = new DrugSearchModel();
    drugSearch.activeName = this.data.selectedDrug && this.data.selectedDrug.activeName;
  }

  getTitlebyScreenState(screenState: string) {
    switch (screenState) {
      case ScreenState.search:
        return ScreenTitle.search;
      case ScreenState.edit:
        if (this.data.mode === ScreenState.add) {
          return ScreenTitle.addDetails;
        }
        return ScreenTitle.edit;
      case ScreenState.add:
          return ScreenTitle.add;
      case ScreenState.searchAll:
        return ScreenTitle.searchAll;
    }
  }

  backToSearch() {
    this.screenState = this.previousScreenState;
    this.title = this.getTitlebyScreenState(this.screenState);
    this.selectedMedication = null;
  }

  validateSearchAll() {
    this.isValidSearchText = this.searchAll.length >= 3;
    return this.isValidSearchText;
  }

  openUpdateContent(item: DrugModel) {
    this.previousScreenState = this.screenState;
    this.selectedMedication = item;
    this.matchingErr = false;
    if (this.data.medicationList && this.data.medicationList.drugEntries && this.screenState === ScreenState.add) {
      const foundDuplicated = this.data.medicationList.drugEntries.find((i) => i.maDrugId === this.selectedMedication.id);
      if (foundDuplicated) {
        this.matchingErr = true;
        this.selectedMedication = null;
        return;
      }
    }
    this.screenState = ScreenState.edit;
    this.title = this.getTitlebyScreenState(this.screenState);
  }

  openSearchAll() {
    this.dataSource = null;
    this.screenState = ScreenState.searchAll;
    this.title = this.getTitlebyScreenState(this.screenState);
  }

  doSearchAll() {
    this.matchingErr = false;
    if (this.validateSearchAll()) {
      this.dataSource = new DrugsDataSource(this.api, this.searchAll, false);
    }
  }

  clear() {
    this.searchAll = '';
    this.isValidSearchText = true;
  }

  limitLength(e) {
    if (e.target.value.length === 4) {
      e.preventDefault();
    }
  }

  onUpdateMedication() {
    const returnedDrugModel = {...this.selectedMedication,
      ...(this.repeatCycleFrequency === 'days' && {repeatCycle: this.repeatCycle}),
      _instructions: this._instructions,
      erd: this.erd,
      quantity: this.quantity,
      rptsLeft: this.rptsLeft,
      supply: this.supply,
      repeatCycleFrequency: this.repeatCycleFrequency,
      source: this.source
    };
    this.dialogRef.close({returnedDrugModel: returnedDrugModel});
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
