import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OperationResult, PendingTaskRecord } from 'src/app/api/iapi.service';
import { IDialogEmbeddable } from 'src/app/utility/base-dialog/dialog.service';
import * as moment from 'moment';
export class MessageBookingDialogOutput extends OperationResult<{ id: string }> { }

@Component({
  selector: 'app-msg-messagebookingdialog',
  templateUrl: './messagebookingdialog.component.html',
  styleUrls: ['./messagebookingdialog.component.css']
})
export class MessageBookingDialogComponent implements IDialogEmbeddable<PendingTaskRecord, MessageBookingDialogOutput>, OnInit {

  @Input() public data: PendingTaskRecord = new PendingTaskRecord();
  public OnComplete: EventEmitter<MessageBookingDialogOutput> = new EventEmitter<MessageBookingDialogOutput>(true);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  taskData: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  currentDate: any;

  constructor(private router: Router) {
  }

  public setData(data: PendingTaskRecord): void {
    this.data = data;
  }

  ngOnInit() {
    this.taskData = JSON.parse(this.data.jSonTaskData);
    this.currentDate = moment(new Date()).format('DD/MM/YYYY');
  }

  closeDialog() {
    this.OnComplete.emit(null);
  }

  onViewCalendar() {
    this.OnComplete.emit(null);
    if (this.taskData) {
      const bookingDate = moment(this.taskData.BookingDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      this.router.navigate(
        ['/calendar'],
        { queryParams: { 'message-date': bookingDate } }
      );
    }
  }
}
