<div class="message-inbox" *ngIf="isShowMessageInbox">
  <div [hidden]=true>{{messages$ | async}}</div>
  <div class="message-inbox-header">
    <div class="message-inbox-title-container">
      <span class="minor-title message-inbox-title">Message history with 
        <span class="first-name">{{firstName}}</span><span>&nbsp;</span>
        <span class="last-name">{{lastName}}</span></span>
      <button (click)="closeMessageInbox(false)" class="btn-close-message-inbox"><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <div class="message-inbox-body" #bodyMessageBox (scroll)="onScroll($event)">
    <app-load-spinner *ngIf="isMessageInboxLoading" [diameter]="32"></app-load-spinner>
    <div *ngIf="numberOfmessageInboxs > 0" style="display: flex; flex-direction: column-reverse;">
      <div *ngFor="let message of messageInboxs" #messageElement>
        <div [class] = "message?.fromPharmacy === false ? 'message-inbox-detail message-inbox-detail-patient' : 'message-inbox-detail'">
          <p [class] = "message?.fromPharmacy === false ? 'sender sender-patient' : 'sender'">
            <ng-container *ngIf="message?.fromPharmacy === true">
              {{pharmacyName}}
            </ng-container>
            <ng-container *ngIf="message?.fromPharmacy !== true">
              <span class="first-name">{{firstName}}</span><span>&nbsp;</span> 
              <span class="last-name">{{lastName}}</span>
            </ng-container>
          </p>
          <div [class] = "message?.fromPharmacy === false ? 'content content-patient' : 'content'" [innerHTML]="message?.content | unescape"></div>
          <p [class] = "message?.fromPharmacy === false ? 'send-time send-time-patient' : 'send-time'">{{ displayCreateDate(message?.createDate) }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="!isMessageInboxLoading && numberOfmessageInboxs === 0 && isPatientDetailPage">
        <div class="no-message">
        No message history available for 
        <span class="first-name">{{firstName}}</span><span>&nbsp;</span> 
        <span class="last-name">{{lastName}}</span>
        </div>
    </div>
  </div>
  <div class="message-inbox-footer">
    <div class="input-message-inbox-container">
      <textarea appResizeTextarea class="input-message-inbox" placeholder="Write message" maxlength="4000" rows="1" #messageContent></textarea>
      <button class="btn-send-message-inbox" (click)="sendMessage()"><mat-icon>send</mat-icon></button>
    </div>
    <p style="margin: 0px; margin-top: 5px; color: red" *ngIf="showErrorEmptyMessage">Please enter message to send</p>
  </div>

</div>
