<!-- {{log('app drawn')}} -->
<script>
__Zone_enable_cross_context_check = true;
</script>
<div [ngClass]="{'container mode-script-import': cssmode===DisplayMode.Default, 'container mode-default': cssmode===DisplayMode.Default, 'container mode-prescriber': cssmode===DisplayMode.Prescriber  }" style="width: 100%; height:100%;" id="wrapper">
  <app-sidebar *ngIf="shouldShowSideBarObservable | async"></app-sidebar>
  <div class="main">
    <header app-header [shouldShowIconMenu]="shouldShowSideBarObservable | async"
                        [shouldShowLoginIconMenu]="shouldShowLoginIconObservable | async"
    ></header>
    <main class="pageContainer">
      <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
      <app-loading-spinner></app-loading-spinner>
    </main>
    <footer app-footer></footer>
  </div>
</div>
