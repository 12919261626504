<div class="drugs-table" (click)="manageOrder(message)">
  <strong>
    {{Notes()}}
  </strong>
</div>

<div class="drugs-infomation" *ngIf="drugs().length > 0; else noDrugInfoProvided">
  <table class="drugs-table" (click)="manageOrder(message)">
    <tbody>
      <tr class="drug-item" *ngFor="let item of drugs()">
        <td class="drug-summary">
          {{ drugSummaries(item) }}
        </td>
        <td [class]="item.DaysLeft > 0 ? 'day-left': 'red-warning'">
          {{daysLeft(item)}}
        </td>
      </tr>
    </tbody>

  </table>
</div>

<ng-template class="drugs-infomation" #noDrugInfoProvided>No drug information provided</ng-template>


<div class="drugs-infomation">
  <div *ngIf="!isMobile &&  (numberOfDrugs | async) > 3" (click)="manageOrder(message)" class="drugs-additional">
    {{ (numberOfDrugs | async) > 3 ?  (numberOfDrugs | async) - 3 + ' more' : '' }}

  </div>
  <div class="only-show-on-drugs-hover">
    {{drugDetails()}}
  </div>
</div>

<div *ngIf="isMobile && (numberOfDrugs | async) > 3" class="drugs-additional">
  {{(numberOfDrugs | async) > 3 ? (numberOfDrugs | async)  - 3 + ' more...' : '' }}

</div>