export enum CalendarViewAka {
  month = 'dayGridMonth',
  week = 'dayGridWeek',
  day = 'resourceDayGridDay',
  weekSchedule = 'timeGridWeek',
  daySchedule = 'resourceTimeGridDay',
  listWeek = 'listWeek',
  listDay = 'listDay',
  timelineWeek = 'resourceTimelineWeek',
  timelineDay = 'resourceTimelineDay'
}

export class CalendarConstant {
  public static readonly preVaccinationQuestions = [
    {
      question: 'question1',
      questionText: 'You have a disease that lowers immunity (e.g. leukemia, cancer, HIV/AIDS) or having treatment that lowers immunity (e.g. oral steroid medicines such as cortisone or prednisolone, radiotherapy or chemotherapy)',
      answer: null
    },
    {
      question: 'question2',
      questionText: 'You have severe allergies (to anything)',
      answer: null
    },
    {
      question: 'question3',
      questionText: 'You have had a severe reaction following any vaccine',
      answer: null
    },
    {
      question: 'question4',
      questionText: 'You have had any vaccine in the past month',
      answer: null
    },
    {
      question: 'question5',
      questionText: 'You have had an injection of immunoglobulin, or have received any blood products or a whole blood transfusion within the past year',
      answer: null
    },
    {
      question: 'question6',
      questionText: 'You are pregnant',
      answer: null
    },
    {
      question: 'question7',
      questionText: 'You have a history of Guillain - Barre syndrome',
      answer: null
    },
    {
      question: 'question8',
      questionText: 'You were a pre-term infant',
      answer: null
    },
    {
      question: 'question9',
      questionText: 'You have a chronic illness',
      answer: null
    },
    {
      question: 'question10',
      questionText: 'You have a bleeding disorder',
      answer: null
    },
    {
      question: 'question11',
      questionText: 'You are of Aboriginal or Torres Strait Islander descent',
      answer: null
    },
    {
      question: 'question12',
      questionText: 'You do not have a functioning spleen',
      answer: null
    },
    {
      question: 'question13',
      questionText: 'You are planning pregnancy or anticipating parenthood',
      answer: null
    },
    {
      question: 'question14',
      questionText: 'You are a parent, grandparent or a carer of a newborn',
      answer: null
    },
    {
      question: 'question15',
      questionText: 'You live with someone who has a disease that lowers immunity (e.g. leukemia, cancer, HIV/AIDS) or live with someone who is having treatment that lowers immunity (e.g. oral steroid medicines such as cortisone or prednisolone, radiotherapy or chemotherapy)',
      answer: null
    },
    {
      question: 'question16',
      questionText: 'You are planning travel',
      answer: null
    },
    {
      question: 'question17',
      questionText: 'You have an occupation or lifestyle factor(s) for which vaccination may be needed (discuss with doctor/pharmacist/nurse). Please specify below.',
      answer: null
    },
    {
      question: 'question18',
      questionText: '',
      answer: null
    }
  ];

  public static readonly dobErrorMsg = {
    format: 'Date of birth is invalid. Please enter a valid format eg dd/mm/yyyy',
    dayInPast: 'Date of birth must be in the past. Please enter a valid format eg dd/mm/yyyy',
    dayBefore1901: 'The date of birth must be after 1900.'
  };

  public static readonly dateErrorMsg = {
    required: 'Please select a booking date',
    format: 'Booking date is invalid. Please enter a valid format eg dd/mm/yyyy'
  };

  public static readonly allResourceValue = 0;

  public static readonly formatDatePlusHoursAndMinutes = 'YYYY-MM-DDTHH:mm';

  public static readonly holidayBackground = 'linear-gradient(120deg, #e0e0e0 3.85%, #f6f7fc 3.85%, #f6f7fc 50%, #e0e0e0 50%, #e0e0e0 53.85%, #f6f7fc 53.85%, #f6f7fc 100%)';

  public static readonly holidayAndTodayBackground = 'linear-gradient(120deg, #e0e0e0 3.85%, #fff3de 3.85%, #fff3de 50%, #e0e0e0 50%, #e0e0e0 53.85%, #fff3de 53.85%, #fff3de 100%)';

  public static readonly holidayBackgroundSize = '15px 26.00px';

  public static readonly printFormTemplate = `
     @media print {
        .formFrame {
          display: block;
        }
     }`;

  public static readonly printTemplate = (printContents) => `
  <html>
     <head>
        <title>Print Pre-Screen Form</title>
        <style>
           body{
              width: 99%;
              font-size: 10px;
           }
           label {
             font-weight: 400;
             font-size: 13px;
             padding: 2px;
             margin-bottom: 5px;
           }
           table {
             border-collapse: collapse;
             width: 100%;
           }
           table, td, th {
             border: 1px solid silver;
             font-size: 13px;
           }
           table tr td {
             font-size: 10px;
             padding: 2px;
             border: solid 1px black;
             height: 30px;
             vertical-align:top;
           }
           th {
              height: 26px;
           }
           .detailSection {
             border: solid 1px black;
             background-color: white;
           }
           .officeSection {
             padding: 2px;
             border: solid 1px black;
             font-weight: bold;
             background-color: lightgray;
             text-align: center;
           }
           .sectionHeader {
             padding: 2px;
             border: solid 1px black;
             font-weight: bold;
             background-color: lightgray;
           }
           .radioList li {
              list-style: circle;
           }
           input[type=checkbox] {
              display:none;
           }
           .break {
             page-break-before: always;
           }
           .checkedBox {
             object-fit:none;
             object-position: 0 -29px;
             zoom: 0.5;
             width: 16px;
             height: 16px;
             padding:2px 2px 0 2px;
           }
           .uncheckedBox {
             object-fit:none;
             object-position: 0 0;
             zoom: 0.5;
             width: 16px;
             height: 16px;
             padding:2px 2px 0 2px;
           }
           textarea {
             border: solid 1px black;
             resize:none;
           }
           .section-styling {
             padding: 5px;
           }
        </style>
    </head>
     <body>
          ${printContents}
           <script type="text/javascript">
                window.print();
             setTimeout(() => {
                window.close();
             },500);
          </script>
     </body>
  </html>`

  public static readonly printViewSetupStyle = (pageHeight, pageWidth, marginTopBottom, marginLeftRight, width) => `
      @media print {
        @page {
          margin: ${marginTopBottom}mm ${marginLeftRight}mm!important;
        }
        app-sidebar, header, footer {
          display: none;
        }
        #wrapper .pageContainer {
          position: absolute;
         overflow: unset !important;
         height: unset !important;
         max-height: unset !important;
         display: block !important;
         width: 100%;
        }

        #wrapper .pageContainer app-view-calendar .calendar-widget {
         padding: 0 !important;
        }

        #wrapper .pageContainer app-view-calendar .view-calendar .calendar-header {
         display: none !important;
        }

        #wrapper .pageContainer app-view-calendar .view-calendar .calendar-main {
         overflow: hidden !important;
        }

        #wrapper .pageContainer app-view-calendar .view-calendar .calendar-main .calendar-main-toolbar__left {
         display: none;
        }

        #wrapper .pageContainer app-view-calendar .view-calendar .calendar-main .calendar-main-toolbar__title {
         margin: auto;
        }

        #wrapper .pageContainer app-view-calendar .view-calendar .calendar-main .calendar-main-toolbar__right {
         display: none;
        }

        #wrapper .pageContainer app-view-calendar .view-calendar .calendar-main .calendar-main-toolbar__title {
         width: 100%;
         text-align: center;
        }

        #wrapper .pageContainer app-view-calendar .view-calendar .calendar-main .fc-scrollgrid {
           border: none!important;
        }

        #wrapper .pageContainer app-view-calendar .view-calendar .calendar-main .fc-scrollgrid table {
           border: 1px solid #ddd!important;
        }
        #wrapper .pageContainer app-view-calendar .view-calendar .calendar-main .fc-theme-standard td, .fc-theme-standard th {
            border-right: none!important;
        }
        #wrapper .pageContainer app-view-calendar .view-calendar .calendar-main .calendar-main-toolbar {
            margin-right: 4px!important;
        }
      }
      @media print and (orientation: portrait) {
        .calendar-main {
          zoom: ${(pageWidth - 2 * marginLeftRight) / width};
        }
      }
      @media print and (orientation: landscape) {
        .calendar-main {
          zoom: ${(pageHeight - 2 * marginLeftRight) / width};
        }
      }
    `
}
