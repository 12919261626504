import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ColorPickerService } from 'ngx-color-picker';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import {
  BookingAvailabilityModel,
  EventBookingExceptionModel,
  EventRecurrenceModel,
  EventResourceModel
} from 'src/app/api/ApiRecordTypes/EventResourceModel';
import { EventResourceResult, StoreModel } from 'src/app/api/ApiRecordTypes/EventResourceResult';
import { AddResourceTimeDialogComponent } from 'src/app/booking-calendar/components/add-resource-time-dialog/add-resource-time-dialog.component';
import { BasicDialogComponent } from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import { EditResourceDialogComponent } from 'src/app/booking-calendar/components/edit-resource/edit-resource-dialog.component';
import { ServiceDialogComponent } from 'src/app/booking-calendar/components/service-dialog/service-dialog.component';
import { CalendarDialogData } from 'src/app/booking-calendar/models/view-models/layout-calendar-dialog.view-model';
import { CalendarDialogService } from 'src/app/booking-calendar/services/calendar-dialog.service';
import { CalendarEventService } from 'src/app/booking-calendar/services/calendar-event.service';
import { DialogConfig, DialogResponse, IDialogEmbeddable } from 'src/app/utility/base-dialog/dialog.service';
import { cloneDeep } from 'lodash';
import { CalendarValidation } from '../../utils/calendar-validation.validator';
import { catchError, finalize, mergeMap, pairwise, startWith, tap } from 'rxjs/operators';
import { combineLatest, EMPTY, from, of, Subject } from 'rxjs';
import * as moment from 'moment';
import { EventServiceModel } from '../../../api/ApiRecordTypes/EventServiceModel';
import { ConfirmationDialogComponent } from '../avail-unavail-dialog/time-confirm-dialog.component';

enum RecurrenceType {
  daily,
  weekly,
  monthly,
  yearly
}

@Component({
  selector: 'app-setup-resource',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.css']
})
export class SettingsDialogComponent extends BasicDialogComponent
  implements OnInit, IDialogEmbeddable<CalendarDialogData, DialogResponse>, AfterViewInit {
  // #region Properties

  // #region Color settings
  public palette = [
    '#000',
    '#444',
    '#666',
    '#999',
    '#ccc',
    '#eee',
    '#f3f3f3',
    '#fff',
    '#f4cccc',
    '#fce5cd',
    '#fff2cc',
    '#d9ead3'
  ];

  public selectedColor = '#2889e9';

  // #endregion

  // #region Constant
  // Enum ResourceAvailabilityType
  public resourceAvailabilityType = {
    AvailableExcept: 0,
    AvailableOnly: 1
  };
  // #endregion

  // #region Input properties
  public data: CalendarDialogData;
  // #endregion

  // Resource setting
  public resources: EventResourceModel[] = [];

  public selectedResource: EventResourceModel;

  public pharmacyInfo: StoreModel;

  public services: EventServiceModel[];

  public visibleServices: EventServiceModel[];

  public availableTimes: { timeDisplay: string, item: EventRecurrenceModel | EventBookingExceptionModel, index: number }[] = [];

  public selectedAvailableTime: { timeDisplay: string, item: EventRecurrenceModel | EventBookingExceptionModel, index: number };

  private servicesAvailableOfResource: BookingAvailabilityModel[] = [];

  private selectedServiceIdLinkToResource: number[] = [];

  private dayList = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday'
  };

  private dayOfWeekMapping = {
    0: {
      long: 'Sunday',
      short: 'Sun'
    },
    1: {
      long: 'Monday',
      short: 'Mon'
    },
    2: {
      long: 'Tuesday',
      short: 'Tue'
    },
    3: {
      long: 'Wednesday',
      short: 'Wed'
    },
    4: {
      long: 'Thursday',
      short: 'Thur'
    },
    5: {
      long: 'Friday',
      short: 'Fri'
    },
    6: {
      long: 'Saturday',
      short: 'Sat'
    }
  };

  public weekOpenTime: {day: string, time: string}[];

  private monthList = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };

  @ViewChild(ToastContainerDirective)
  public toastContainer: ToastContainerDirective;

  public showToastSubject = new Subject<string>();
  // #endregion

  // #region Form Control
  public resourceControl: UntypedFormControl;

  public timeAvailableControl: UntypedFormControl;

  public colorControl: UntypedFormControl;

  public linkServiceToResourceFormArray: UntypedFormArray;

  previousSelectedTime: unknown;

  public settingsForm: UntypedFormGroup;
  // #endregion

  // #region Accessors

  // #endregion

  // #region Constructor
  public constructor (
    protected toastr: ToastrService,
    protected router: Router,
    protected cpService: ColorPickerService,
    protected eventService: CalendarEventService,
    protected dialogService: CalendarDialogService
  ) {
    super(router);
    this.headerTitle = 'Assign services to rooms and resources';
    this.acceptButtonTitle = 'Save';
    this.cancelButtonTitle = 'Cancel';
    // this.toastr.toastrConfig.timeOut = 3000;
    // this.toastr.toastrConfig.disableTimeOut = true;
    // this.toastr.toastrConfig.autoDismiss = false;
    // this.toastr.toastrConfig.tapToDismiss = false;
    this.resourceControl = new UntypedFormControl('');
    this.timeAvailableControl = new UntypedFormControl(0);
    this.colorControl = new UntypedFormControl('');
    this.linkServiceToResourceFormArray = new UntypedFormArray([], CalendarValidation.minSelectedCheckboxes(1));

    this.settingsForm = new UntypedFormGroup({
      resource: this.resourceControl,
      time: this.timeAvailableControl,
      color: this.colorControl,
      linkServiceToResource: this.linkServiceToResourceFormArray
    });
  }

  // #endregion

  // #region Life cycle
  public ngOnInit () {
    if (this.data.settings.currentResourceId) {
      this.resourceControl.setValue(this.data.settings.currentResourceId);
      this.selectedColor = this.resources.find(res => res.id === this.data.settings.currentResourceId).color;
    } else {
      if (this.resources[0]) {
        this.resourceControl.setValue(this.resources[0].id);
        this.selectedColor = this.resources[0].color;
      }
    }

    const getAllServiceObservable = this.eventService.retrieveServices().pipe(
      tap((data: EventServiceModel[]) => {
        if (!data) {
          this.services = [];
        }
        this.services = data;
        this.visibleServices = this.services
          .filter(service => service.visible)
          .sort((firstService, secondService) => {
            return firstService.name.localeCompare(secondService.name);
          });
        this.addCheckboxes();
      })
    );

    const getEventResourceObservable = this.eventService.getResourceById(Number(this.resourceControl.value))
      .pipe(
        tap((data: EventResourceResult) => {
          if (!data || !data.resources || !data.resources.length) {
            this.servicesAvailableOfResource = [];
            return;
          }
          this.updateDataWithNewSelectedResource(data);
        })
      );

    const loadOpenedDialogDataSubscription = combineLatest([getAllServiceObservable, getEventResourceObservable])
      .subscribe();
    this._subscription.add(loadOpenedDialogDataSubscription);

    this.timeAvailableControl.valueChanges.pipe(startWith(null), pairwise())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      .subscribe(([prev, _]: [any, any]) => {
        this.previousSelectedTime = prev;
      });
  }

  public ngAfterViewInit () {
    const listenCloseDialogEventSubscription = this.eventService.getToast().subscribe((msg) => {
      this.toastr.overlayContainer = this.toastContainer;
      this.showToastMessage(msg);
    });
    this._subscription.add(listenCloseDialogEventSubscription);
  }

  // #endregion

  // #region Methods

  // #region Common

  // #endregion

  // #region Input
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public setData (data: any) {
    this.resources = data.settings.resources.filter(resource => !resource.isDeleted);
    this.pharmacyInfo = data.settings.store;
    super.setData(data);
    this.weekOpenTime = this.openTime();
  }

  public handleCancel () {
    super.handleCancel();
  }

  public handleAccept () {
    this.saveResource();
    super.handleAccept();
  }

  // #endregion

  // #region Resources
  protected updateDataWithNewSelectedResource (data: EventResourceResult): void {
    this.selectedResource = data.resources[0];
    this.selectedColor = this.selectedResource.color || '#fff';
    this.timeAvailableControl.patchValue(this.selectedResource.availabilityType);
    this.generateAvailableTimes();
    this.selectedAvailableTime = null;
    const servicesAvailable = data.resources[0].availableServices;
    this.servicesAvailableOfResource = servicesAvailable.filter(service => service.resourceAvailable);
    this.selectedResource.availableServices = this.servicesAvailableOfResource;
    this.addCheckboxes();
  }

  public handleSelectResource (): void {
    const resourceId = this.resourceControl.value;
    if (!resourceId) {
      this.servicesAvailableOfResource = [];
      return;
    }

    this.selectedResource = this.resources.find(resource => resource.id === Number(this.resourceControl.value));
    this.selectedColor = this.selectedResource.color || '#fff';
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    this.selectedAvailableTime = {} as any;
    const getEventResourceSubscription = this.eventService.getResourceById(resourceId)
      .subscribe((data: EventResourceResult) => {
        if (!data || !data.resources || !data.resources.length) {
          this.servicesAvailableOfResource = [];
          return;
        }
        this.updateDataWithNewSelectedResource(data);
      });

    this._subscription.add(getEventResourceSubscription);
  }

  public showEditResourceDialog (): void {
    if (!this.resourceControl.value) {
      return;
    }
    const setting = { resources: this.resources };
    const config = new DialogConfig();
    config.width = '80vw';
    config.panelClass = ['settings-dialog'];
    config.autoFocus = false;
    config.maxWidth = '658px';
    const openDialogPromise = this.dialogService.openEditDialog(EditResourceDialogComponent, setting, config);
    const loadResourceSubscription = from(openDialogPromise).pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap(_ => {
        return this.eventService.loadResources();
      }),
      tap(resourceResult => {
        this.resources = resourceResult.resources.filter(res => !res.isDeleted);
        const currentSelectedResource = this.resources.find(r => this.selectedResource && r.id === this.selectedResource.id);
        if (!currentSelectedResource) {
          const newResourceSelectedId = (this.resources.length && this.resources[0].id) || '';
          this.resourceControl.patchValue(newResourceSelectedId);
        }
        this.handleSelectResource();
      })
    ).subscribe(() => this.showToastSubject.next('Resource updated'));
    this._subscription.add(loadResourceSubscription);
  }

  public saveResource (): void {
    const resourceModel = { ...this.selectedResource };
    resourceModel.pharmacyId = this.pharmacyInfo.id;
    resourceModel.availableServices = null;
    resourceModel.availabilityType = this.timeAvailableControl.value;
    resourceModel.supplemental = `{color}${this.selectedResource.color}{/color}`;

    const previousLinkedServices = this.servicesAvailableOfResource.map(s => s.id);
    const unlinkedServices = previousLinkedServices.filter(id => !this.selectedServiceIdLinkToResource.includes(id));
    const linkedServices = this.selectedServiceIdLinkToResource.filter(id => !previousLinkedServices.includes(id));

    let linkServiceToResourceObservable = of(null);
    if (unlinkedServices.length || linkedServices.length) {
      linkServiceToResourceObservable = this.eventService
        .updateServiceLinkedToResource(resourceModel.id, this.selectedServiceIdLinkToResource);
    }

    const updateResourceObservable = this.eventService.updateResources(resourceModel);
    const updateResourceSubscription = combineLatest([linkServiceToResourceObservable, updateResourceObservable])
      .pipe(
        finalize(() => {
          this.eventService.setToast('Success|Resource saved');
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError(err => {
          this.eventService.setToast('Error|An error occurred!');
          return EMPTY;
        })
      )
      .subscribe();
    this._subscription.add(updateResourceSubscription);
  }

  // #endregion

  // #region Services
  public showEditServicesDialog (): void {
    if (!this.services) {
      return;
    }
    const settings = {
      services: this.services
    };
    const clientInnerWidth = window.innerWidth;
    const config = new DialogConfig();
    if (clientInnerWidth < 1500) {
      config.height = '90%';
    }
    config.maxHeight = '636px';
    config.width = '70%';
    config.panelClass = ['edit-service'];
    config.autoFocus = false;
    config.maxWidth = '1024px';
    const openEditServiceDialogSubscription = from(this.dialogService.openEditDialog(ServiceDialogComponent, settings, config)).pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap(_ => {
        return this.eventService.retrieveServices();
      }),
      tap((data: EventServiceModel[]) => {
        if (!data) {
          this.services = [];
        }
        this.services = data;
        this.visibleServices = this.services.filter(service => service.visible)
          .sort((firstService, secondService) => {
            return firstService.name.localeCompare(secondService.name);
          });
        this.addCheckboxes();
      })
    ).subscribe();
    this._subscription.add(openEditServiceDialogSubscription);
  }

  // #endregion

  // #region Times
  public addOrEditAvailableTime (isAddTime: boolean): void {
    const config = new DialogConfig();
    config.width = '540px';
    config.panelClass = ['edit-available-time'];
    config.autoFocus = false;
    const selectedAvailableTime = isAddTime ? null : this.selectedAvailableTime;
    if (!isAddTime && !selectedAvailableTime) {
      return;
    }
    const settings = { resource: this.selectedResource, isAddTime, selectedAvailableTime, availableTimes: this.availableTimes };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.dialogService.openCalendarCustomDialog(AddResourceTimeDialogComponent, settings, config).then(r => {
      this.availableTimes = this.availableTimes.map(t => {
        if (t.item['recurrenceType'] != null) {
          t.timeDisplay = this.generateSingleRecurringTime(t.item as EventRecurrenceModel);
          return t;
        }
        t.timeDisplay = this.generateSingleTime(t.item as EventBookingExceptionModel);
        return t;
      });
    });
  }

  public deleteAvailableTime (): void {
    if (!this.selectedAvailableTime || !this.selectedResource) {
      return;
    }
    if (this.selectedAvailableTime.item['recurrenceType'] != null) {
      this.selectedResource.recurrencePattern.splice(this.selectedAvailableTime.index, 1);
    } else {
      this.selectedResource.availibilityExceptions.splice(this.selectedAvailableTime.index, 1);
    }
    const deletedIndex = this.availableTimes.indexOf(this.selectedAvailableTime);
    this.availableTimes.splice(deletedIndex, 1);
    this.selectedAvailableTime = null;
  }

  public openTime (): { day: string, time: string }[] {
    const openHours = cloneDeep(this.data.settings.openHours);
    openHours.push(openHours.shift());
    const viewMapping: { day: {[key: string]: string}, time: string }[] = openHours.map(time => {
      const day = this.dayOfWeekMapping[time.weekday];
      let startHourString;
      let endHourString;
      let startMinString;
      let endMinString;
      if (time.startMinute < 9) {
        startMinString = `0${time.startMinute}`;
      } else {
        startMinString = time.startMinute;
      }

      if (time.endMinute < 9) {
        endMinString = `0${time.endMinute}`;
      } else {
        endMinString = time.endMinute;
      }

      startHourString = this.generateHour(time.startHour, startMinString);
      endHourString = this.generateHour(time.endHour, endMinString);

      if (time.startHour === 0 && time.endHour === 0) {
        startHourString = 'Closed';
        endHourString = '';
      }
      const timeConvert = `${startHourString}${endHourString ? ' - ' + endHourString : ''}`;
      return { day: day, time: timeConvert };
    });
    let tempOpeningTime = [];
    const result = [];
    for (let index = 0; index < viewMapping.length; index++) {
      const openingTime = viewMapping[index];
      if (openingTime.time === 'Closed') {
        tempOpeningTime.push(openingTime);
        if (index !== viewMapping.length - 1) {
          continue;
        }
      }
      if (tempOpeningTime.length > 1) {
        const day = tempOpeningTime[0].day.short + ' - ' + tempOpeningTime[tempOpeningTime.length - 1].day.short;
        result.push({ day, time: tempOpeningTime[0].time });
      } else if (tempOpeningTime.length) {
        result.push({ day: tempOpeningTime[0].day.long, time: tempOpeningTime[0].time });
      }
      if (!tempOpeningTime.includes(openingTime)) {
        result.push({ day: openingTime.day.long, time: openingTime.time });
      }
      tempOpeningTime = [];
    }
    return result;
  }

  private generateHour (hour: number, min: string) {
    if (hour === 0) {
      return '12:00 AM';
    }
    if (hour <= 12) {
      return hour <= 9 ? `0${hour}:${min} AM` : `${hour}:${min} AM`;
    }
    const calculatedTime = hour - 12;
    if (calculatedTime === 12) {
      return '12:00 PM';
    }
    return calculatedTime <= 9 ? `0${calculatedTime}:${min} PM` : `${calculatedTime}:${min} PM`;
  }

  // #endregion

  // #region Color
  public colorPicked (color: string): void {
    if (!this.selectedResource) {
      return;
    }
    this.selectedColor = color;
    this.selectedResource.color = this.selectedColor;
  }

  // #endregion

  // #endregion

  public availableTimeChange () {
    const prevValue = this.previousSelectedTime;
    this.timeAvailableControl.patchValue(prevValue);
    this.selectedResource.availabilityType = prevValue as number;
  }

  public handleServiceCheckboxChange (service: EventServiceModel, isChecked: boolean): void {
    if (isChecked) {
      this.selectedServiceIdLinkToResource.push(service.capabilityId);
      return;
    }
    const removeServiceIndex = this.selectedServiceIdLinkToResource.indexOf(service.capabilityId);
    this.selectedServiceIdLinkToResource.splice(removeServiceIndex, 1);
  }

  private addCheckboxes () {
    if (!this.visibleServices) {
      return;
    }
    const servicesIdAvailableOfResource = this.servicesAvailableOfResource.map(service => service.id);
    this.selectedServiceIdLinkToResource = servicesIdAvailableOfResource;
    this.linkServiceToResourceFormArray = new UntypedFormArray([], CalendarValidation.minSelectedCheckboxes(1));
    this.visibleServices.forEach(service => {
      if (servicesIdAvailableOfResource.includes(service.capabilityId)) {
        this.linkServiceToResourceFormArray.push(new UntypedFormControl(true));
        return;
      }
      this.linkServiceToResourceFormArray.push(new UntypedFormControl(false));
    });
  }

  // #region Generate Recurring Times
  protected generateAvailableTimes (): void {
    if (!this.selectedResource || !this.selectedResource.recurrencePattern || !this.selectedResource.availibilityExceptions) {
      this.availableTimes = [];
      return;
    }
    this.availableTimes = [];
    this.selectedResource.availibilityExceptions.forEach((single, index) => {
      const time = this.generateSingleTime(single);
      const availableTime = {
        timeDisplay: time,
        item: single as EventBookingExceptionModel,
        index
      };
      this.availableTimes.push(availableTime);
    });
    this.selectedResource.recurrencePattern.forEach((recurrencePattern, index) => {
      const time = this.generateSingleRecurringTime(recurrencePattern);
      const availableTime = {
        timeDisplay: time,
        item: recurrencePattern as EventRecurrenceModel,
        index
      };
      this.availableTimes.push(availableTime);
    });
  }

  protected generateSingleTime (single: EventBookingExceptionModel): string {
    if (!single) {
      return '';
    }
    let result = moment(single.startDate).format('DD/MM/YYYY');
    result += ` (${moment(single.startDate).format('hh:mm A')} - ${moment(single.endDate).format('hh:mm A')})`;
    return result;
  }

  protected generateSingleRecurringTime (recurrencePattern: EventRecurrenceModel): string {
    let ret = '';
    const recurEvery = recurrencePattern.recurEvery;
    switch (recurrencePattern.recurrenceType) {
      case RecurrenceType.daily:
        if (recurEvery === 1) {
          ret = 'Daily ' + this.toFormattedTimeRange(recurrencePattern);
        } else {
          ret = 'Every ' + recurEvery + ' days ' + this.toFormattedTimeRange(recurrencePattern);
        }
        break;
      case RecurrenceType.weekly:
        if (recurEvery === 1) {
          // tslint:disable-next-line:max-line-length
          ret = 'Every week on: ' + this.toFormattedDaysOfWeek(recurrencePattern.weekdays) + ' ' + this.toFormattedTimeRange(recurrencePattern);
        } else {
          // tslint:disable-next-line:max-line-length
          ret = 'Every ' + recurEvery + ' weeks on: ' + this.toFormattedDaysOfWeek(recurrencePattern.weekdays) + ' ' + this.toFormattedTimeRange(recurrencePattern);
        }
        break;
      case RecurrenceType.monthly:
        if (recurEvery === 1) {
          // tslint:disable-next-line:max-line-length
          ret = 'Every month on: ' + this.toFormattedDaysOfMonth(recurrencePattern.dates) + ' ' + this.toFormattedTimeRange(recurrencePattern);
        } else {
          // tslint:disable-next-line:max-line-length
          ret = 'Every ' + recurEvery + ' months on: ' + this.toFormattedDaysOfMonth(recurrencePattern.dates) + ' ' + this.toFormattedTimeRange(recurrencePattern);
        }
        break;
      case RecurrenceType.yearly:
        if (recurEvery === 1) {
          // tslint:disable-next-line:max-line-length
          ret = 'Every year on day ' + recurrencePattern.dayOfMonthInYearlyRecurrence + ' in: ' + this.toFormattedMonthsYear(recurrencePattern.months) + ' ' + this.toFormattedTimeRange(recurrencePattern);
        } else {
          // tslint:disable-next-line:max-line-length
          ret = 'Every ' + recurEvery + ' years on day ' + recurrencePattern.dayOfMonthInYearlyRecurrence + ' in: ' + this.toFormattedMonthsYear(recurrencePattern.months) + ' ' + this.toFormattedTimeRange(recurrencePattern);
        }
        break;
    }

    return ret;
  }

  protected toFormattedMonthsYear (months: number[]): string {
    let result = '';

    for (const month of months) {
      result += (this.monthList[month] + ', ');
    }

    return result.substring(0, result.length - 2);
  }

  protected toFormattedDaysOfWeek (weekdays: number[]): string {
    let result = '';

    for (const day of weekdays) {
      result += (this.dayList[day] + ', ');
    }
    return result.substring(0, result.length - 2);
  }

  protected toFormattedTimeRange (recurrencePattern: EventRecurrenceModel): string {
    if (!recurrencePattern) {
      return '';
    }
    const startTimeArray = recurrencePattern.startTime.split(':');
    const endTimeArray = recurrencePattern.endTime.split(':');
    const startTime = moment(recurrencePattern.startDate).hour(+startTimeArray[0]).minutes(+startTimeArray[1]).format('hh:mm a');
    const endTime = moment(recurrencePattern.endDate).hour(+endTimeArray[0]).minutes(+endTimeArray[1]).format('hh:mm a');
    if (recurrencePattern.useEndTime) {
      return '(' + startTime + ' - ' + endTime + ')';
    }
    return '(' + startTime + ')';
  }

  protected toFormattedDaysOfMonth (dates: number[]): string {
    let result = '';

    for (const day of dates) {
      if (day === 1 || day === 21 || day === 31) {
        result += (day + 'st, ');
        continue;
      }
      if (day === 2 || day === 22) {
        result += (day + 'nd, ');
        continue;
      }
      if (day === 3 || day === 23) {
        result += (day + 'rd, ');
        continue;
      }
      result += (day + 'th, ');
    }
    return result.substring(0, result.length - 2);
  }

  public changeTimeSettingOption (event, value) {
    event.preventDefault();
    this.showConfirmDialog(value);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public showToastMessage (message: string, timeOut = 3000): any {
    this.toastr.clear();
    const positionClass = new Date().getTime().toString() + Math.random().toString();
    return this.toastr.show(message, null, { toastClass: 'custom-toast-success', timeOut, positionClass });
  }
  // #endregion

  public showConfirmDialog (value) {
    const setting = { timeType: this.setTextAvailableOrUnAvailable(value) };
    const dialogConfig = new DialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.panelClass = ['confirm-dialog'];
    dialogConfig.autoFocus = false;
    this.dialogService.openCustomCalendarDialog(ConfirmationDialogComponent, setting, dialogConfig)
      .then(val => {
        if (val === 3) {
          this.timeAvailableControl.patchValue(value);
          this.selectedResource.availabilityType = value;
        }
      });
  }

  setTextAvailableOrUnAvailable (number) {
    switch (number) {
      case 1 :
        return 'Available times';
      case 0 :
        return 'Unavailable times';
    }
  }
}
