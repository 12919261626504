
import { environment as base } from "src/environments/npa/environment.npa";
export const environment = {
  ...base,
  contextWarning: 'NpaTest',
  name: 'npa-test',
  production: false,
  apiPath: 'https://npa-test-public.welamhealth.net/api/',
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  version: require('../../package.json').version,
  zendeskURL: 'medadvisor1532314196.zendesk.com',
  allowedTokenOrigin: [''],
  authservers: ['https://npa-test-auth.welamhealth.net'],
  baseUrl_GlobalPharmacyPortal: 'https://npa-test-global-pharmacy-portal.welamhealth.net/',
  baseUrl_App:                  'https://npa-test-app.welamhealth.net/',
  logging: 'webApi',
  keys: {
  'https://npa-test-auth.welamhealth.net': {'all':
  `-----BEGIN CERTIFICATE-----
  MIIHoTCCBYmgAwIBAgIQD+btrTTtaMuGSzb/b8lc1zANBgkqhkiG9w0BAQsFADBc
  MQswCQYDVQQGEwJVUzEXMBUGA1UEChMORGlnaUNlcnQsIEluYy4xNDAyBgNVBAMT
  K1JhcGlkU1NMIEdsb2JhbCBUTFMgUlNBNDA5NiBTSEEyNTYgMjAyMiBDQTEwHhcN
  MjIwNzIxMDAwMDAwWhcNMjMwNzIxMjM1OTU5WjAcMRowGAYDVQQDDBEqLndlbGFt
  aGVhbHRoLm5ldDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAJkqKSpr
  mSg0JFcbiw9/1WeUUC/NVUeGroY1vdvQqV41OY+sTGOqqdQGqwo7roUem4h2YN5x
  GXSRfOjbHOToPaDTQpQtoS1xaVL7GbMAqjIPE7Qd/vQ6FHU/HqoNhKBK1n5bf9Iz
  vYG+yrbDDwtgm3zk4Z2muFpGkIlwtJOoacWcOVIXzT7k1N1fQ48x6W1HPuF9RKaR
  nyvpUVRoLVNXbBdMtFbO3dYfcfZLR4YmuDzwL4Yl1ZaQDtBFOwp4MXvMcaTuyFLx
  KKcDJ6l+h6G4D52+u2gmC+siNGnUbv3t3TEXCFbyxrBsxTpUEitxfBkRB0Mtc3b/
  1KjNFD6DN8+ZSV8CAwEAAaOCA50wggOZMB8GA1UdIwQYMBaAFPCchf2in32PyWi7
  1dSJTR2+05D/MB0GA1UdDgQWBBQmikiJh4hu7aSfEm1BckJyOWOnjjAtBgNVHREE
  JjAkghEqLndlbGFtaGVhbHRoLm5ldIIPd2VsYW1oZWFsdGgubmV0MA4GA1UdDwEB
  /wQEAwIFoDAdBgNVHSUEFjAUBggrBgEFBQcDAQYIKwYBBQUHAwIwgZ8GA1UdHwSB
  lzCBlDBIoEagRIZCaHR0cDovL2NybDMuZGlnaWNlcnQuY29tL1JhcGlkU1NMR2xv
  YmFsVExTUlNBNDA5NlNIQTI1NjIwMjJDQTEuY3JsMEigRqBEhkJodHRwOi8vY3Js
  NC5kaWdpY2VydC5jb20vUmFwaWRTU0xHbG9iYWxUTFNSU0E0MDk2U0hBMjU2MjAy
  MkNBMS5jcmwwPgYDVR0gBDcwNTAzBgZngQwBAgEwKTAnBggrBgEFBQcCARYbaHR0
  cDovL3d3dy5kaWdpY2VydC5jb20vQ1BTMIGHBggrBgEFBQcBAQR7MHkwJAYIKwYB
  BQUHMAGGGGh0dHA6Ly9vY3NwLmRpZ2ljZXJ0LmNvbTBRBggrBgEFBQcwAoZFaHR0
  cDovL2NhY2VydHMuZGlnaWNlcnQuY29tL1JhcGlkU1NMR2xvYmFsVExTUlNBNDA5
  NlNIQTI1NjIwMjJDQTEuY3J0MAkGA1UdEwQCMAAwggGABgorBgEEAdZ5AgQCBIIB
  cASCAWwBagB2AOg+0No+9QY1MudXKLyJa8kD08vREWvs62nhd31tBr1uAAABgh84
  ZvkAAAQDAEcwRQIgJ2oXVumLux4EY7+ZLy22yIIwoVd9svBSo1xDPngzryECIQCn
  2iRbDgIzheZInpYjwprUaMXPBKOGK2oXy/Py4gBrPwB3ADXPGRu/sWxXvw+tTG1C
  y7u2JyAmUeo/4SrvqAPDO9ZMAAABgh84ZlwAAAQDAEgwRgIhAOMuvg39mNEk6CT7
  KrVgj+g5JMjO+FkAwkJJ+JiZeZtuAiEAofbDMrCS/iBdqH8UZRl6OxY5r5bSMfU8
  5kXU1bAaQX4AdwC3Pvsk35xNunXyOcW6WPRsXfxCz3qfNcSeHQmBJe20mQAAAYIf
  OGZRAAAEAwBIMEYCIQDOBD4F+KMdfAs8n1/dZH6Q9kMMHB9y8+zYL/ODLXKKuwIh
  AOj2lR/TaF/qg9kn0SR1QQ6RRW48Gsm/YekOo0gluoT6MA0GCSqGSIb3DQEBCwUA
  A4ICAQBn/w9C0Sfm7nKGdjDurfIYZSQLBQ/ilyCUN9ZAA8ZRYbERg3RTnpP3+MI1
  os57lvmBYPAXRAQ1rYB6O9p9FS6K9M9DigD+4Xn1ssbi/12lRamzVdO7BvBsUZXv
  E9hy3RLiBxqYnyN+lQP5Y5ErBcITt7uktnHegdQc3AK1LzWo9fJT/JkjTQaLu2yA
  lORA6LM8fW5gttvY4FMmda8yk/0/w6Iopqhvpunm2KsoVIljjqIbW3Kqx5qJQrIv
  Mz12PH+gLflPtTi1Vyd+MdGZ/P2Zke8jDGF0V4c/ZiiGecxK8EogoUriboxzoZEJ
  Z+3boPiYXIUSCec51W58UwOW6IQf7RXmBvTBMSbF/R5AQVlWHZNGfh/EwEfmRVQp
  V4bAL9BRQM+UbCVg/VVfwXmU4XuCYXcF4H/i3YTEcTz7tfHwPJoK0u8LDnl6bOEV
  iSgC5WuqOuo8J2vdz8gSqI56lzttknwz/sz7Y+h/eZT6RCOIwf/gaMeCusvkyx6M
  1Sr/wsHaHfwg2nEhUXFo+c9loZ9e7ZUFehWKwNbei7BRXRjkUsl1d1SLG8TK7g+a
  9mrMw3GOhibhJmIeD2tPuHd8pN+6J/09EkX7kmEjLEKRkY9T1QcniNc8VAIG7uVz
  STrr+FELnooTrpG+V7eKJlsAWQTDIFuB0e3cVjzJhruAi6npJg==
  -----END CERTIFICATE-----
  `}}
};
