import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { IAPIService } from './api/iapi.service';

import { RoleGuardService } from './authentication/roleguard.service';
import { AppConfigService, DisplayMode } from './app-config.service';
import { AuthenticationService } from './authentication/authentication.service';
import { CommsModule } from './utility/comms/comms.module';
import {Observable, of} from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'global-pharmacy-portal';
  public DisplayMode = DisplayMode;
  cssmode: DisplayMode = DisplayMode.Default;
  // Handle hide sidebar in case set password
  public shouldShowSideBarObservable: Observable<boolean>;
  public shouldShowLoginIconObservable: Observable<boolean>;

  constructor(
    private api: IAPIService,
    private roleguard: RoleGuardService,
    private config: AppConfigService,
    private authService: AuthenticationService,
    private commsModule: CommsModule,
    private router: Router) {
    // Handle hide sidebar in case set password
    this.shouldShowSideBarObservable = this.router.events
      .pipe(
        filter((event): event is NavigationStart => event instanceof NavigationStart),
        map(event => {
          let isNotSetPasswordRoute = true;
          if (event.url.includes('/set-password')) {
            isNotSetPasswordRoute = false;
            this.commsModule.setPage({
              pageTitle: 'Set Password',
              pageIcon: '',
              page: 'Set Password'
            });
            this.shouldShowLoginIconObservable = of(false);
            return isNotSetPasswordRoute;
          }
          if (event.url.includes('/Pharmacist/Staff/')) {
            isNotSetPasswordRoute = false;
            this.commsModule.setPage({
              pageTitle: 'Services',
              pageIcon: '',
              page: 'Services'
            });
            this.shouldShowLoginIconObservable = of(true);
          } else {
            this.shouldShowLoginIconObservable = of(false);
          }
          return isNotSetPasswordRoute;
        })
      );

    // merge congig and comms module later (or break Config out of comms module)
    // commsModule is a central distributor for data changes to all subscribers.
    // config could be the core for gathering and aggregating config changes across multiple sources.
    // Which-ever way it's done, responsability for the current config should rest in one place.
    // those needing to know of changes should be able to subscribe for some form of OnConfigChanges
    // those just needing the latest values should be able to call getConfigSettingChanges.
    this.commsModule.setConfigSettings(this.config.getConfig());
    this.cssmode = this.config.getDisplayMode();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.config.watchDisplayModeChanges().subscribe((result) => {
      this.cssmode = this.config.getDisplayMode();
    });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  logObject(obj): any {
    console.log(obj);
    return obj;
  }
  log(obj): string {
    console.log(obj);
    return '';
  }
  onRouterOutletActivate(event) {
    this.roleguard.currentComponent = event;
  }
}
