import { BehaviorSubject } from 'rxjs';
import { PharmacyModel } from '../api/ApiRecordTypes/PharmacyRecord';

export class SettingsValidationService  {
    openingHoursErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    emailErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    pharmacyNameErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    addressLine1ErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    addressLine2ErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    suburbErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    cityErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    postCodeErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    webAddressErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    phoneErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);
    overallErrorMessage$: BehaviorSubject<string> = new BehaviorSubject(null);

    
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor () {
    }

    public validateAll(settings: PharmacyModel, openingHoursNote: string, webAddress: string) {
        let valid = this.validOpeningHoursNote(openingHoursNote);
        valid = this.validEmailAddress(settings && settings.email) && valid;
        valid = this.validPharmacyName(settings && settings.name) && valid;
        valid = this.validAddressLine1(settings && settings.address && settings.address.line1) && valid;
        valid = this.validAddressLine2(settings && settings.address && settings.address.line2) && valid;
        valid = this.validSuburb(settings && settings.address && settings.address.suburb) && valid;
        valid = this.validCity(settings && settings.address && settings.address.city) && valid;
        valid = this.validPostCode(settings && settings.address && settings.address.postCode) && valid;
        valid = this.validWebAddress(webAddress) && valid;
        valid = this.validPhone(settings && settings.phone) && valid;
        if (valid) {
            this.overallErrorMessage$.next(null);
        } else {
            this.overallErrorMessage$.next('There are validation errors');
        }
        return valid;
    }

    public validPhone(phone): boolean {
        const valid = this.validateCharacterCount(phone, 20, 'Phone', this.phoneErrorMessage$);
        if (valid) {
            this.phoneErrorMessage$.next(null);
        }
        return valid;
    }

    public validOpeningHoursNote(openingHoursNote): boolean {
        const valid = this.validateCharacterCount(openingHoursNote, 250, 'Opening hours note', this.openingHoursErrorMessage$);
        if (valid) {
            this.openingHoursErrorMessage$.next(null);
        }
        return valid;
    }

    public validEmailAddress(email): boolean {
        const valid = this.validateCharacterCount(email, 50, 'Email', this.emailErrorMessage$);
        if (valid) {
            this.emailErrorMessage$.next(null);
        }
        return valid;
    }

    public validPharmacyName(pharmacyName): boolean {
        let valid = this.validateCharacterCount(pharmacyName, 50, 'Pharmacy name', this.pharmacyNameErrorMessage$);
        valid = valid && this.validateMandatoryField(pharmacyName, 'Pharmacy name', this.pharmacyNameErrorMessage$);
        if (valid) {
            this.pharmacyNameErrorMessage$.next(null);
        }
        return valid;
    }

    public validAddressLine1(addressLine1): boolean {
        const valid = this.validateCharacterCount(addressLine1, 50, 'Address line 1', this.addressLine1ErrorMessage$);
        if (valid) {
            this.addressLine1ErrorMessage$.next(null);
        }
        return valid;
    }

    public validAddressLine2(addressLine2): boolean {
        const valid = this.validateCharacterCount(addressLine2, 50, 'Address line 2', this.addressLine2ErrorMessage$);
        if (valid) {
            this.addressLine2ErrorMessage$.next(null);
        }
        return valid;
    }

    public validSuburb(suburb): boolean {
        const valid = this.validateCharacterCount(suburb, 30, 'Suburb', this.suburbErrorMessage$);
        if (valid) {
            this.suburbErrorMessage$.next(null);
        }
        return valid;
    }

    public validCity(city): boolean {
        const valid = this.validateCharacterCount(city, 30, 'City', this.cityErrorMessage$);
        if (valid) {
            this.cityErrorMessage$.next(null);
        }
        return valid;
    }

    public validPostCode(postcode): boolean {
        const valid = this.validateCharacterCount(postcode, 10, 'Post code', this.postCodeErrorMessage$);
        if (valid) {
            this.postCodeErrorMessage$.next(null);
        }
        return valid;
    }

    public validWebAddress(webAdddress): boolean {
        const valid = this.validateCharacterCount(webAdddress, 50, 'Web Address', this.webAddressErrorMessage$);
        if (valid) {
            this.webAddressErrorMessage$.next(null);
        }
        return valid;
    }

    public validateMandatoryField(value: string, fieldName: string, errorMessage: BehaviorSubject<string>): boolean {
        if (value === null || fieldName === '') {
            errorMessage.next(`${fieldName} is mandatory`);
            return false;
        }
        return true;
    }

    public validateCharacterCount(value: string, count: number, fieldName: string, errorMessage: BehaviorSubject<string>) {
        if (value && value.length >= count) {
            errorMessage.next(`${fieldName} must be less than ${count} characters`);
            return false;
        }
        return true;
    }



}
