import {Component, Inject, OnDestroy } from '@angular/core';
import { Router} from '@angular/router';
import { BasicDialogComponent } from 'src/app/booking-calendar/components/basic-diglog/basic-dialog.component';
import { IDispenseRequestApiService } from 'src/app/api/methods/dispense-request-api.interface';
import { DrugModel, PatientRequestComponentModel, PatientRequestModel } from 'src/app/api/ApiRecordTypes/PendingTaskRecord';
import { IAPIService, ParsedScrRow } from 'src/app/api/iapi.service';
import { PharmacyModel } from 'src/app/api/ApiRecordTypes/PharmacyRecord';
import { environment } from 'src/environments/environment';

export enum DisplayMode {
  orderPage = 'orderPage',
  success = 'success',
  error = 'error'
}

@Component({
  selector: 'app-pharmacy-order',
  templateUrl: './pharmacy-order.component.html',
  styleUrls: ['./pharmacy-order.component.scss']
})
export class PharmacyOrderComponent extends BasicDialogComponent implements OnDestroy {
  env = environment;
  private dataLoaded = false;
  private patientCode_MedAdvisorGUID: string;
  private practiceId: number;
  public practiceName: string;
  public pharmacyName: string;
  public transmissionMethod: string;
  public lastName: string;
  public firstName: string;
  public display: DisplayMode = DisplayMode.orderPage;
  public inProgress = false;
  public drugs: ParsedScrRow[];
  private gpMessage:string = null;
  private errorMessage: string = null;
  
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  accountSettings: any = null;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  constructor(private dispenseRequestApi: IDispenseRequestApiService, router: Router, @Inject('SESSIONSTORAGE') private sessionStorage: any, private api: IAPIService) {
    super(router);
    this.accountSettings = JSON.parse(this.sessionStorage.getItem('accountSettings'));
    this.api.getPharmacySettings(this.accountSettings.id).subscribe(result => {
       this.pharmacyName = result.name;
       this.resolveDataForGPmessage ()
      });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public setData (data: any) {
    super.setData(data);
    this.patientCode_MedAdvisorGUID = data.patientCode_MedAdvisorGUID;
    this.practiceId = data.practiceId;
    this.practiceName = data.practiceName;
    this.transmissionMethod = data.transmissionMethod;
    this.lastName = data.patientInformation$.value.patient_LastName;
    this.firstName = data.patientInformation$.value.patient_FirstName;
    this.drugs = data.drugs;
    this.resolveDataForGPmessage ()
  }

  public goToOrderScreen() {
    this.display = DisplayMode.orderPage;
  }

  public placeOrder() {
    this.inProgress = true;
    const model:PatientRequestModel = new PatientRequestModel();
    model.patientCode_MedAdvisorGuid = this.patientCode_MedAdvisorGUID;
    model.type = 2;
    model.pharmacy = new PharmacyModel();
    model.pharmacy.id = this.accountSettings.id;
    model.notes = this.gpMessage;
    model.components = [];
    for (const drug of this.drugs) {
      const component = new PatientRequestComponentModel();
      component.drug = new DrugModel();
      component.drug.id = drug.maDrugId;
      component.patientCode_MedAdvisorGuid = this.patientCode_MedAdvisorGUID;
      model.components.push(component)
    }
    this.dispenseRequestApi.createOrUpdateAsync(model).subscribe(
      
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _ => { 
        this.inProgress = false;
        this.goToSuccessScreen();
      }, 
      error => { 
        this.inProgress = false;
        this.errorMessage = error.resultMessage
        this.goToErrorScreen();
      }
    
    );
    
  } 
  
  private resolveDataForGPmessage () {
    if (this.firstName && this.lastName && this.pharmacyName) {
      this.dataLoaded = true
      this.setGPMessage()
    }
  }

  private setGPMessage (){
    if (this.dataLoaded){
      this.gpMessage = 'This order has been placed by ' + this.pharmacyName + ' on behalf of ' + this.firstName + ' ' + this.lastName;
    }
  }

  public goToSuccessScreen() {
    this.display = DisplayMode.success;
  }

  public goToErrorScreen() {
    this.display = DisplayMode.error;
  }

  // call when destroy component
  public ngOnDestroy() {
    if (this._subscription && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }
}