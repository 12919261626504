import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommsModule } from '../comms/comms.module';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'src/app/app-config.service';
@Component({
  selector: 'app-card-status',
  templateUrl: './card-status.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class CardStatusComponent implements OnInit {

  cardInserted$ = new BehaviorSubject<boolean>(false);
  cardName$ = new BehaviorSubject<string>('');
  organisation$ = new BehaviorSubject<string>('');
  jobTitle$ = new BehaviorSubject<string>('');
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;

  constructor(
    private comms: CommsModule,
    private configService: AppConfigService) {

    }

  ngOnInit() {
    this.config = this.configService.getConfig();
    this.comms.getCardDetailsAccess().subscribe(card => {
      this.cardInserted$.next(card !== null && card.sessionId !== null);
      if (this.cardInserted$.value) {
        this.cardName$.next(card.userName);
        this.organisation$.next(card.userOrg);
        this.jobTitle$.next(this.formatJobTitle(card.jobTitle));
      } else {
        this.cardName$.next('');
      }
    });
  }

  formatJobTitle(jobTitle: string) {
    if (jobTitle) {
      return jobTitle.split(':')[2].replace(/"/g, '');
    }
    return jobTitle;
  }
}
