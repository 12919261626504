/* eslint-disable dot-notation */
import { CalendarOptions, EventSourceInput } from '@fullcalendar/core';
import {
  AfterViewChecked,
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { VerboseFormattingArg } from '@fullcalendar/common';
import { ResourceInput } from '@fullcalendar/resource';
import * as moment from 'moment';
import { BookingDialogComponent, PrescreenDisplay } from 'src/app/booking-calendar/components/booking-dialog/booking-dialog.component';
import { SettingsDialogComponent } from 'src/app/booking-calendar/components/settings-dialog/settings-dialog.component';
import { CalendarDialogData } from 'src/app/booking-calendar/models/view-models/layout-calendar-dialog.view-model';
import { CalendarDialogService } from 'src/app/booking-calendar/services/calendar-dialog.service';
import { CalendarEventService } from 'src/app/booking-calendar/services/calendar-event.service';
import { DialogConfig } from '../../../utility/base-dialog/dialog.service';
import { WebsiteAccessDialogComponent } from '../ website-access-dialog/website-access-dialog.component';
import { CommsModule } from '../../../utility/comms/comms.module';
import { EMPTY, fromEvent, merge, of, Subscription } from 'rxjs';
import { catchError, delay, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { StoreModel } from '../../../api/ApiRecordTypes/EventResourceResult';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CalendarConstant, CalendarViewAka } from '../../constant/calendar.constant';
import { EventApi, EventDropArg } from '@fullcalendar/core';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { MatSelectChange } from '@angular/material/select';
import { PrescreenFormModel } from '../../../api/ApiRecordTypes/PreScreenFormModel';
import { cloneDeep } from 'lodash';
import { Location } from '@angular/common';
import { SpinnerService } from '../../../utility/loading-spinner/spinner.service';
import { UtilsService } from '../../../utility/utilsservice/UtilsService';
import { EventBookingModel } from '../../../api/ApiRecordTypes/EventResourceModel';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';

@Component({
  template: `
    <div popoverClass="fc-event_popover" [placement]="['top-left', 'top']" [ngbPopover]="template" container="body" triggers="manual">
      <ng-content></ng-content>
    </div>
  `
})
export class PopoverComponent {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  template: TemplateRef<any>;

  @ViewChild(NgbPopover, { static: true }) popover: NgbPopover;
  constructor (protected config: NgbPopoverConfig) {
    config.openDelay = 1000;
  }
}
class ServiceCountItem {
  public capabilityId: number;
  public capabilityName: string;
  public count: number;
}
@Component({
  selector: 'app-view-calendar',
  templateUrl: './view-calendar.component.html',
  styleUrls: []
})
export class ViewCalendarComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  // #region Propreties
  @ViewChild('calendar')
  public calendarComponent: FullCalendarComponent;

  @ViewChild('fcalendar')
  public fCalendarElement: ElementRef;

  @ViewChild('popoverTemplate', { static: true })
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public popoverTemplateRef: TemplateRef<any>;

  @ViewChild('viewCalendar', { static: true })
  public viewCalendar: ElementRef;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public popoversMap = new Map<any, ComponentRef<PopoverComponent>>();

  public popoverFactory = this.resolver.resolveComponentFactory(
    PopoverComponent
  );

  @ViewChild(ToastContainerDirective)
  public toastContainer: ToastContainerDirective;

  @ViewChild('noPrescreenFormContent', { static: true })
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public noPrescreenFormContent: TemplateRef<any>;

  // #region Condition Properties
  public isPrintSupport = true;

  public isTodayButtonAvailable = false;

  public isGridWeekView = false;

  public isMonthViewLayout = true;
  // #endregion

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public resources: any = [];

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public initResources: any = [];

  public serviceAssociateWithResource: ServiceCountItem[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public eventBookings: any;

  public holidays = [];

  public slotMinTime = '';

  public slotMaxTime = '';

  public isEventLoading = true;

  public resourceOptionForNotWeekScheduleView = 0;

  // #region Pagination for day and day schedule view
  public pagesOfResource: number [] = [];

  public activePageOfResource: number;

  public reachTheLastResource = false;

  public reachTheFirstResource = true;

  public totalResources = 0;

  public resourcePerPage = 8;
  // Dataset call many time so need to compare params to restrict call api multiple
  // #region
  public startDate: string;

  public endDate: string;

  public prescreenFormModel: PrescreenFormModel[];

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public prescreenData: {[key: string]: any};

  public isCalendarWidget = false;

  public timerEventId;
  // #endregion

  // Pharmacy info
  public store: StoreModel;

  // Pharmacy operating hour
  public operatingHours;
  // #endregion

  public layouts = [
    {
      id: CalendarViewAka.month,
      value: 'Month'
    },
    {
      id: CalendarViewAka.week,
      value: 'Week'
    },
    {
      id: CalendarViewAka.day,
      value: 'Day'
    },
    {
      id: CalendarViewAka.weekSchedule,
      value: 'Week Schedule'
    },
    {
      id: CalendarViewAka.daySchedule,
      value: 'Day Schedule'
    },
    {
      id: CalendarViewAka.listWeek,
      value: 'Week List'
    },
    {
      id: CalendarViewAka.listDay,
      value: 'Day List'
    },
    {
      value: 'Week Timeline',
      id: CalendarViewAka.timelineWeek
    },
    {
      value: 'Day Timeline',
      id: CalendarViewAka.timelineDay
    }
  ];

  public timestampLastClick;

  public lastDayClick;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public currentPopover: any;

  public shouldShowPrintButton = true;

  public calendarOptions: Partial<CalendarOptions> = {
    initialView: CalendarViewAka.month,
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
      interactionPlugin,
      resourceTimelinePlugin,
      resourceTimeGridPlugin,
      resourceDayGridPlugin
    ],
    timeZone: 'UTC',
    // Determines if day names and week names are clickable. navLinkDayClick, navLinkWeekClick
    navLinks: false,
    headerToolbar: {
      left: '',
      center: '',
      right: ''
    },
    // Sunday=0, Monday=1, Tuesday=2, etc.
    firstDay: 1,
    views: {
      dayGridMonth: {
        buttonText: 'Month',
        dayMaxEventRows: 5
      },
      dayGridWeek: {
        titleFormat: (arg: VerboseFormattingArg) => {
          const startTime = moment(arg.start).utc().format('MMM DD');
          const endTime = moment(arg.end).utc().format('MMM DD, YYYY');
          return `${startTime} - ${endTime}`;
        },
        buttonText: 'Week'
      },
      resourceDayGridDay: {
        titleFormat: (arg: VerboseFormattingArg) => {
          const day = moment(arg.end).utc().format('dddd MMMM DD, YYYY');
          return `${day}`;
        },
        buttonText: 'Day'
      },
      timeGridWeek: {
        columnFormat: 'ddd D',
        titleFormat: (arg: VerboseFormattingArg) => {
          const dayStart = moment(arg.start).utc().format('MMM DD');
          const dayEnd = moment(arg.end).utc().format('MMM DD, YYYY');
          return `${dayStart} - ${dayEnd}`;
        },
        buttonText: 'Week Schedule'
      },
      resourceTimeGridDay: {
        buttonText: 'Day Schedule'
      },
      listWeek: {
        titleFormat: (arg: VerboseFormattingArg) => {
          const dayStart = moment(arg.start).utc().format('MMM DD');
          const dayEnd = moment(arg.end).utc().format('MMM DD, YYYY');
          return `${dayStart} - ${dayEnd}`;
        },
        buttonText: 'Week List'
      },
      listDay: {
        buttonText: 'Day List'
      },
      resourceTimelineWeek: {
        titleFormat: (arg: VerboseFormattingArg) => {
          const dayStart = moment(arg.start).utc().format('MMM DD');
          const dayEnd = moment(arg.end).utc().format('MMM DD, YYYY');
          return `${dayStart} - ${dayEnd}`;
        },
        buttonText: 'Week Timeline'
      },
      resourceTimelineDay: {
        buttonText: 'Day Timeline'
      }
    },
    resourceOrder: 'title',
    eventMaxStack: 30,
    dayMaxEventRows: 30,
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    editable: false, // this option used to disable drag
    allDaySlot: false,
    weekends: true,
    selectable: false,
    businessHours: [],
    nowIndicator: true,
    dayHeaderDidMount: (event) => {
      const viewType = event.view.type;
      if (viewType === CalendarViewAka.week || viewType === CalendarViewAka.weekSchedule) {
        event.el.setAttribute('data-date', '' + new Date(event.date).getDate());
        event.el.classList.add('week-header');
      }
      if (viewType === CalendarViewAka.month) {
        event.el.setAttribute('data-date', '');
      }
    },
    dayHeaderContent: (event) => {
      const viewType = event.view.type;
      if (viewType === CalendarViewAka.week || viewType === CalendarViewAka.weekSchedule) {
        return moment(event.date).format('ddd');
      }
    },
    dayCellDidMount: (event) => {
      const isDayView = event.view.type === CalendarViewAka.day || event.view.type === CalendarViewAka.daySchedule;
      if (this.holidays.includes(event.dow) && !isDayView) {
        if (event.el.className.includes('fc-day-today')) {
          event.el.style.backgroundImage = CalendarConstant.holidayAndTodayBackground;
        } else {
          event.el.style.backgroundImage = CalendarConstant.holidayBackground;
        }
        event.el.style.backgroundSize = CalendarConstant.holidayBackgroundSize;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viewClassNames: (event) => {
      return [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    viewWillUnmount: (event) => {},
    viewDidMount: (event) => {
      if (event.view.type === CalendarViewAka.weekSchedule) {
        this.isGridWeekView = true;
        const currentResourceId = this.currentResourceId === 0 ? this.initResources[0].id : this.currentResourceId;
        this.handleResourceChange(currentResourceId);
        this.resourceControl.patchValue(currentResourceId);
        this.changeDetector.markForCheck();
      } else {
        this.isGridWeekView = false;
        this.changeDetector.markForCheck();
      }
      if (event.view.type === CalendarViewAka.timelineDay || event.view.type === CalendarViewAka.timelineWeek) {
        const resourceElement = event.el.querySelectorAll('.fc-datagrid-cell.fc-resource');
        resourceElement.forEach(ele => {
          const resourceID = ele.getAttribute('data-resource-id');
          const resourceColor = this.resources.find(res => res.id === +resourceID)
            ? this.resources.find(res => res.id === +resourceID).color
            : '#FFFFFF';
          // add highlight for resource
          ele.querySelector('.fc-datagrid-cell-frame').setAttribute('style', `border-right: 4px solid ${resourceColor};`);
        });
      }
    },
    displayEventTime: true,
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: 'short'
    },
    events: [],
    eventColor: '#FFFFFF',
    eventOrder: ['start', 'duration', 'title', 'extendedProps.createDate'],
    eventWillUnmount: (event) => this.destroyTooltip(event),
    eventDidMount: (event) => {
      if (event.view.type === CalendarViewAka.weekSchedule || event.view.type === CalendarViewAka.daySchedule) {
        const timeElement = event.el.querySelector('.fc-event-time');
        const start = event.event.startStr;
        const end = event.event.endStr;
        const duration = moment(end).utc(false).diff(moment(start).utc(false), 'minutes');
        if (duration < 45) {
          timeElement.innerHTML = moment(start).utc(false).format('hh:mma');
        }
      }

      const viewType = event.view.type;
      if (viewType === CalendarViewAka.weekSchedule || viewType === CalendarViewAka.daySchedule) {
        const timeArray = event.timeText.split('-');
        const startTime = moment(timeArray[0], 'hh:mma');
        const endTime = moment(timeArray[1], 'hh:mma');
        const duration = endTime.diff(startTime, 'minutes');
        event.el.style.height = `${duration}px`;
        if (duration === 5) {
          event.el.style.background = '#5072C6';
        }
      }
      if (event.view.type !== CalendarViewAka.listDay && event.view.type !== CalendarViewAka.listWeek) {
        this.renderTooltip(event);
        const dotEle = event.el.getElementsByClassName('fc-list-event-dot')[0];
        if (dotEle) {
          dotEle.setAttribute('style', `border-color: ${event.borderColor};`);
        }
      }
      const dotElement = document.createElement('div');
      dotElement.className = 'fc-daygrid-event-dot';
      dotElement.style.border = `5px solid ${event.borderColor}`;
      dotElement.style.background = `${event.borderColor}`;
      const fcTimeEventNode = event.el.getElementsByClassName('fc-event-time')[0];
      const parentNode = event.el.getElementsByClassName('fc-event-main-frame')[0];
      if (parentNode) {
        parentNode.insertBefore(dotElement, fcTimeEventNode);
        if (viewType === CalendarViewAka.timelineDay || viewType === CalendarViewAka.timelineWeek) {
          parentNode.classList.add('flex-row-reverse');
        }
      }
      if (event.view.type === CalendarViewAka.listDay) {
        const fcListDayCushion = document.querySelector('.fc-list-day-cushion');
        if (!fcListDayCushion) {
          return;
        }

        if (document.querySelector('.print-completed-screen-form')) {
          return;
        }

        if (!this.shouldShowPrintButton) {
          return;
        }

        const printElement = document.createElement('div');
        printElement.className = 'print-completed-screen-form';
        printElement.innerText = 'Print all completed pre-screening forms';
        const iconElement = document.createElement('span');
        iconElement.className = 'material-icons';
        iconElement.innerText = 'print';

        printElement.insertAdjacentElement('beforeend', iconElement);

        this.handlePrintScreenForm = printElement.addEventListener('click', () => this.printScreeningForm());
        if (fcListDayCushion) {
          fcListDayCushion.insertAdjacentElement('afterbegin', printElement);
        }
      }
    },
    eventMouseEnter: (event) => {
      const shouldNotShowTooltip = event.view.type === CalendarViewAka.listDay || event.view.type === CalendarViewAka.listWeek;
      if (shouldNotShowTooltip) {
        return;
      }
      this.showTooltip(event);
    },
    eventMouseLeave: (event) => this.hideTooltip(event),
    eventClick: (event) => {
      event.jsEvent.preventDefault();
      if (this.timerEventId) {
        return;
      }
      this.timerEventId = setTimeout(() => {
        this.showAddOrEditBooking(+event.event.id);
        this.timerEventId = undefined;
      }, 700);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resources: (fetchInfo, successCallback, failureCallback) => {
      successCallback(this.resources);
    },
    // Called after the calendar’s date range has been initially set or changed in some way and the DOM has been updated.
    datesSet: (arg) => {
      // .fc .fc-timegrid-col.fc-day-today
      const isDayGird = arg.view.type === CalendarViewAka.day || arg.view.type === CalendarViewAka.week;
      if (isDayGird && this.calendarComponent) {
        this.calendarComponent.getApi().setOption('dayMaxEventRows', 30);
      }
      if (arg.view.type === CalendarViewAka.month && this.calendarComponent) {
        this.calendarComponent.getApi().setOption('dayMaxEventRows', 5);
      }
      this._calendarTitle = arg.view.title;
      const startDate = arg.startStr.split('T')[0];
      const endDate = arg.endStr.split('T')[0];
      if (arg.view.type === CalendarViewAka.timelineDay) {
        // Trick to fill background form 23:30 to 24h
        const slotMaxTime = moment(this.slotMaxTime, 'HH:mm:ss').add(29, 'minutes').format('HH:mm:ss');
        this.calendarComponent.getApi().setOption('slotMaxTime', slotMaxTime);
      }
      if (arg.view.type === CalendarViewAka.day || arg.view.type === CalendarViewAka.daySchedule) {
        // Trick to fill background form 23:30 to 24h
        const toDayElement = document.querySelectorAll('.fc-day-today');
        toDayElement.forEach(ele => {
          ele.classList.add('custom-background');
        });
      }
      if (this.currentView === CalendarViewAka.listDay) {
        const currentDate = `Date_${moment(this.calendarTitle, 'MMMM DD,YYYY').format('YYYY-MM-DD')}`;
        const getFormPrintSubscription = this.eventService.printPreScreenFormAsync(currentDate)
          .subscribe(value => {
            if (!value || value.length === 0) {
              this.shouldShowPrintButton = false;
            } else {
              this.shouldShowPrintButton = true;
            }
          });
        this._subscription.add(getFormPrintSubscription);
      }
      // Avoid duplicate api call
      if (this.startDate === startDate && this.endDate === endDate) {
        return;
      }
      this.startDate = startDate;
      this.endDate = endDate;
      this.loadEventBooking(startDate, endDate, arg.view.type);
      this.checkPrintSupport(arg.view.type);
    },
    dateClick: (dateClick) => {
      const viewType = dateClick.view.type;
      if (viewType !== CalendarViewAka.month &&
        viewType !== CalendarViewAka.week &&
        viewType !== CalendarViewAka.day) {
        if (!this.validateDayWithinBusinessHours(dateClick.dateStr)) {
          return;
        }
      }

      if (!(this.dblClickCount % 2 === 0) || this.holidays.includes(moment(dateClick.date).day())) {
        return;
      }
      this.dblClickCount = 0;
      this.showAddOrEditBooking(null, dateClick.dateStr);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    select: (arg: {start, end, jsEvent, view}) => {
    },
    handleWindowResize: true,
    expandRows: true,
    contentHeight: 'auto',
    rerenderDelay: 100,
    fixedWeekCount: false,
    showNonCurrentDates: true,
    moreLinkClassNames: 'popover-grid-view',
    eventResourceEditable: false, // this option used to disable drag with timeline and gridResource
    droppable: false, // this allows things to be dropped onto the calendar
    // Called after the calendar’s date range has been initially set or changed in some way and the DOM has been updated.
    
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    eventsSet: (events: EventApi[]) => {
    },
    eventDrop: (dropEvent: EventDropArg) => {
      const viewName = dropEvent.view.type;
      const eventId = dropEvent.event && +dropEvent.event.id;
      if (!eventId) {
        return;
      }
      this.editEventBooking(dropEvent, null, viewName, eventId);
    },
    eventResize: (resizeEvent: EventResizeDoneArg) => {
      const viewName = resizeEvent.view.type;
      const eventId = +resizeEvent.event.id;
      this.editEventBooking(null, resizeEvent, viewName, eventId);
    },
    loading: (isLoading) => {
      this.isEventLoading = isLoading;
    },
    eventConstraint: 'businessHours',
    moreLinkClick: (info) => {
      setTimeout(() => {
        const closeIcon = document.querySelector('.fc-popover-close');
        if (info.view.type === CalendarViewAka.week) {
          const popover = document.querySelector('.fc-popover');
          popover.classList.add('fc-custom-popover');
        }
        closeIcon.className = 'material-icons icon-x';
        closeIcon.innerHTML = 'close';
      }, 10);
    },
    moreLinkContent: (info) => {
      return info.text.replace('+', '');
    },
    resourceAreaWidth: '220px',
    resourceAreaHeaderClassNames: 'resource-header',
    slotDuration: '00:30',
    slotLabelInterval: '01:00',
    slotLaneClassNames: 'slot-lane',
    slotEventOverlap: false,
    eventMinWidth: 5,
    snapDuration: '00:30:00',
    eventShortHeight: 5,
    
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resourcesSet: (event) => {
      if (this.currentView === CalendarViewAka.timelineDay || this.currentView === CalendarViewAka.timelineWeek) {
        const resourceElement = document.querySelectorAll('.fc-datagrid-cell.fc-resource');
        resourceElement.forEach(ele => {
          const resourceID = ele.getAttribute('data-resource-id');
          const resourceColor = this.resources.find(res => res.id === +resourceID)
            ? this.resources.find(res => res.id === +resourceID).color
            : '#FFFFFF';
          // add highlight for resource
          ele.querySelector('.fc-datagrid-cell-frame').setAttribute('style', `border-right: 4px solid ${resourceColor};`);
        });
      }
    }
  };

  // Subscription watch list.
  protected readonly _subscription: Subscription;

  protected currentResourceId: number;

  private dblClickCount = 0;

  private currentView = 'dayGridMonth';

  private earliestOpening: string;

  private latestClosing: string;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private handlePrintScreenForm: any;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private _calendarTitle: any;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private stylesMap: Map<any, Node> = new Map();

  private host: Node;

  // #endregion

  // #region FormControl
  public layoutControl: UntypedFormControl;

  public resourceControl: UntypedFormControl;

  public calendarForm: UntypedFormGroup;
  showDialogue = true;
  // #endregion

  // #region Accessors
  public get calendarTitle () {
    return this._calendarTitle;
  }
  // #endregion

  // #region Constructor
  public constructor (
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected eventService: CalendarEventService,
    protected dialogService: CalendarDialogService,
    protected changeDetector: ChangeDetectorRef,
    protected commonService: CommsModule,
    protected resolver: ComponentFactoryResolver,
    protected injector: Injector,
    protected appRef: ApplicationRef,
    protected toastr: ToastrService,
    private location: Location,
    private spinnerService: SpinnerService
  ) {
    this.commonService.setPage({ pageTitle: 'Services', page: 'calendar', pageIcon: '' });
    this.isCalendarWidget = location.path().includes('/Pharmacist/Staff/');
    this._subscription = new Subscription();

    this.initializeForm();

    this.activePageOfResource = 1;
    // Assign Id = 0 to load all event for all resources
    this.currentResourceId = 0;
    // Load all resources from server
    this.loadResources();
    // Header host
    this.host = document.head;
  }

  // #endregion

  // #region Lifecycles
  public ngOnInit () {
    // this.loadAllService();
    this.spinnerService.stopLoading();
    const pageCount = this.getPageCount();
    this.pagesOfResource = this.getArrayOfPage(pageCount);
  }

  public ngOnDestroy () {
    if (this._subscription && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }

  public ngAfterViewInit (): void {
    this.setSlotTimeOptionsForCalendar();

    this.handleDoubleClickOnDateCell();

    this.handleScrollEvent();

    const businessHours = this.eventService.getBusinessHours();
    this.calendarComponent.getApi().setOption('businessHours', businessHours);

    const getToastFormChildDialogSubscription = this.eventService.getToast().subscribe(message => {
      this.toastr.overlayContainer = this.toastContainer;
      const messageContent = message.split('|');
      if (!['Success', 'Error'].includes(messageContent[0])) {
        return;
      }
      const toastClass = messageContent[0] === 'Success' ? 'custom-toast-success' : 'custom-toast-error';
      this.showToastMessage(messageContent[1], toastClass, 2000);
    });
    this._subscription.add(getToastFormChildDialogSubscription);
  }


  public ngAfterViewChecked (): void {
    this.listenEventFromMessageBoardCast();
  }

  // #endregion

  // #region Methods

  // #region Load Data for calendar methods
  private loadEventBooking (startDate: string, endDate: string, viewType?: string) {
    const messageDate = this.activatedRoute.snapshot.queryParams['message-date'];
    if (messageDate) {
      return;
    }
    const currentView = viewType || this.currentView;
    const listenEventBookingSubscription =
      this.eventService.retrieveEventBookings(startDate, endDate, this.currentResourceId)
        .pipe(
          tap(data => {
            // Mapping event to EventInput
            const events = data.bookings
              // Open comment to hide event for holiday
              // .filter(eventByDay => {
              //   return !this.holidays.includes(moment(eventByDay.startDate).day());
              // })
              .map(event => {
                const startDateBooking = moment(event.startDate, CalendarConstant.formatDatePlusHoursAndMinutes).valueOf();
                const endDateBooking = moment(event.endDate, CalendarConstant.formatDatePlusHoursAndMinutes).valueOf();
                const firstName = UtilsService.unescapeString(event.firstName);
                const lastName = UtilsService.unescapeString(event.lastName);
                const fullName = `${firstName} ${lastName}`;
                
                let title = `${firstName} ${lastName}` || '';
                const mobile = event.phone || event.mobile;

                // Format event title
                if (currentView === CalendarViewAka.listWeek || currentView === CalendarViewAka.listDay) {
                  const eventTitle = `${firstName} ${lastName}, ` || '';
                  const userMobile = mobile ? `${mobile}, ` : '';
                  const userEmail = event.email ? `${event.email}, ` : '';
                  const capabilityName = event.capabilityName ? `${event.capabilityName}, ` : '';
                  const resourceName = event.resourceName ? `${event.resourceName}` : '';
                  title = `${eventTitle}${userMobile}${userEmail}${capabilityName}${resourceName}`;
                }

                // Format event without start time and end time
                if (currentView === CalendarViewAka.timelineWeek || currentView === CalendarViewAka.timelineDay) {
                  this.calendarComponent.getApi().setOption('displayEventTime', false);
                  return {
                    id: event.id,
                    resourceId: event.resourceId,
                    start: startDateBooking,
                    end: endDateBooking,
                    title,
                    url: event.url,
                    borderColor: event.color,
                    display: event.capabilityName,
                    textColor: '#000',
                    extendedProps: {
                      capabilityName: event.capabilityName,
                      capabilityId: event.capabilityId,
                      createTime: event.createDate,
                      fullName: fullName,
                      duration: event.duration,
                      start: startDateBooking,
                      end: endDateBooking
                    }
                  };
                } else {
                  this.calendarComponent.getApi().setOption('displayEventTime', true);
                }

                return {
                  id: event.id,
                  resourceId: event.resourceId,
                  start: startDateBooking,
                  end: endDateBooking,
                  title,
                  url: event.url,
                  borderColor: event.color,
                  display: event.capabilityName,
                  textColor: '#000',
                  extendedProps: {
                    capabilityName: event.capabilityName,
                    capabilityId: event.capabilityId,
                    createTime: event.createDate,
                    fullName: fullName,
                    duration: event.duration,
                    start: startDateBooking,
                    end: endDateBooking
                  }
                };
              }) as EventSourceInput;

            this.eventBookings = data.bookings;

            this.serviceAssociateWithResource = this.countBookingPerService(data.bookings);

            this.calendarComponent.getApi().refetchResources();
            this.calendarComponent.getApi().setOption('events', events);
            this.calendarComponent.getApi().refetchEvents();
            this.changeDetector.markForCheck();
          }),
          catchError(() => {
            this.showToastMessage('An error occurred!', 'custom-toast-error');
            return EMPTY;
          })
        )
        .subscribe();
    this._subscription.add(listenEventBookingSubscription);
  }

  // Edit event booking
  private editEventBooking (eventDropped: EventDropArg, eventResize: EventResizeDoneArg, viewName: string, eventId: number) {
    const dropEvent = this.eventBookings.filter(event => event.id === eventId)[0];
    if (!dropEvent) {
      this.showToastMessage('An error occurred', 'custom-toast-error');
      return;
    }
    if (eventDropped) {
      console.log(eventDropped);
      const newResource = eventDropped.newResource;

      dropEvent.startDate = eventDropped.event.startStr.replace('Z', '');
      dropEvent.endDate = eventDropped.event.endStr.replace('Z', '');

      if (newResource) {
        dropEvent.resourceId = newResource.id;
        dropEvent.resourceName = newResource.title;
      }
    }

    if (eventResize) {
      const event = eventResize.event;
      const startDateTime = event.startStr.split(':00Z')[0];
      const endDateTime = event.endStr.split(':00Z')[0];
      const duration = moment(event.end).diff(moment(event.start), 'minutes');
      dropEvent.startDate = startDateTime;
      dropEvent.endDate = endDateTime;
      dropEvent.duration = duration;
    }

    const bookingRequestSubscription = this.eventService.createOrEditEventBooking(dropEvent)
      .subscribe(
        () => {
          this.loadEventBooking(this.startDate, this.endDate);
          this.showToastMessage('Booking updated', 'custom-toast-success');
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (err) => this.showToastMessage('An error occurred', 'custom-toast-error')
      );
    this._subscription.add(bookingRequestSubscription);
  }

  // Using cached resources when init component and get from server when resources was updated
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private loadResources (shouldLoadCached = true, shouldReturnResult = false): any {
    const loadResourcesObservable = shouldLoadCached
      ? this.eventService.getCachedResources()
      : this.eventService.loadResources();
    const resourcesObservable = loadResourcesObservable
      .pipe(map(eventResourceResult => {
        if (!eventResourceResult) {
          return true;
        }
        this.store = eventResourceResult.store;
        this.earliestOpening = eventResourceResult.earliestOpening;
        this.latestClosing = eventResourceResult.latestClosing;
        this.operatingHours = eventResourceResult.openHours;
        // Get Holiday
        this.holidays = eventResourceResult.openHours
          .filter(day => {
            return day.startHour === 0 &&
              day.endHour === 0 &&
              day.startMinute === 0 &&
              day.endMinute === 0;
          })
          .map(day => day.weekday);
        this.slotMinTime = eventResourceResult.earliestOpening;
        this.slotMaxTime = eventResourceResult.latestClosing;
        // Mapping resources
        this.initResources = eventResourceResult.resources.filter(resource => !resource.isDeleted).map(resource => ({
          id: resource.id,
          title: resource.title,
          color: resource.color
        })).sort((resourcePrev, resourceNext) => {
          return resourcePrev.title.localeCompare(resourceNext.title);
        }) as ResourceInput;
        this.resources = this.initResources;
        this.totalResources = this.initResources.length;
        return this.resources;
      }));
    if (shouldReturnResult) {
      return resourcesObservable;
    }
    const resourcesSubscription = resourcesObservable.subscribe();
    this._subscription.add(resourcesSubscription);
  }

  // #endregion

  // #region Calendar header methods
  public doPrevious (): void {
    this.calendarComponent.getApi().prev();
    this.removePrintPreScreenButton();
  }

  public doNext (): void {
    this.calendarComponent.getApi().next();
    this.removePrintPreScreenButton();
  }

  public getToday (): void {
    // this.calendarComponent.getApi().gotoDate(Date.now());
    this.calendarComponent.getApi().today();
    this.removePrintPreScreenButton();
  }

  public setCalendarView (viewName: string): void {
    this.currentView = viewName;
    if (this.currentView === CalendarViewAka.timelineDay || this.currentView === CalendarViewAka.timelineWeek) {
      this.calendarComponent.getApi().setOption('slotDuration', '01:00');
    } else {
      this.calendarComponent.getApi().setOption('slotDuration', '00:30');
    }
    if (viewName !== CalendarViewAka.weekSchedule) {
      this.handleResourceChange(this.resourceOptionForNotWeekScheduleView);
      this.resourceControl.patchValue(this.resourceOptionForNotWeekScheduleView);
      this.changeDetector.markForCheck();
    }
    if (this.currentView === CalendarViewAka.listDay) {
      const currentDate = `Date_${moment(this.calendarTitle, 'MMMM DD,YYYY').format('YYYY-MM-DD')}`;
      const getFormPrintSubscription = this.eventService.printPreScreenFormAsync(currentDate)
        .subscribe(value => {
          if (!value || value.length === 0) {
            this.shouldShowPrintButton = false;
          } else {
            this.shouldShowPrintButton = true;
          }
        });
      this._subscription.add(getFormPrintSubscription);
    }
    // Only show event after new title formatted, show set old events to empty array
    this.calendarComponent.getApi().setOption('events', []);
    this.isMonthViewLayout = viewName === CalendarViewAka.month;
    // Handle Schedule view with many resource
    const isSupportResourcePagination = viewName === CalendarViewAka.day || viewName === CalendarViewAka.daySchedule;
    if (!isSupportResourcePagination && this.currentResourceId === 0) {
      this.resources = this.initResources;
      this.calendarComponent.getApi().refetchResources();
    }
    // Day list
    if (isSupportResourcePagination && this.currentResourceId === 0) {
      this.doNavigationResource(1);
    }
    // Handle Print button state
    this.isPrintSupport = this.checkPrintSupport(viewName);
    // Force calendar change view
    this.calendarComponent.getApi().changeView(viewName);
  }

  // #endregion

  // #region Public methods
  public printCalendar (): void {
    if (!this.isPrintSupport) {
      return;
    }
    const originalTitle = document.title;
    document.title = 'MedAdvisor - Calendar';
    this.calendarComponent.getApi().setOption('dayMaxEventRows', 100);
    setTimeout(() => {
      this.setupPrintPage();
      window.print();
    }, 600);
    setTimeout(() => {
      this.removeStyle('print-view');
      if (this.currentView === CalendarViewAka.day || this.currentView === CalendarViewAka.week) {
        this.calendarComponent.getApi().setOption('dayMaxEventRows', 30);
      } else {
        this.calendarComponent.getApi().setOption('dayMaxEventRows', 5);
      }
    }, 1200);
    document.title = originalTitle;
  }

  public printScreeningForm () {
    const setting = { content: this.noPrescreenFormContent, cancelButtonTitle: '', acceptButtonTitle: 'OK' };
    let currentDate;
    if (this.currentResourceId === 0) {
      currentDate = `Date_${moment(this.calendarTitle, 'MMMM DD,YYYY').format('YYYY-MM-DD')}`;
    } else {
      currentDate = `Date_${moment(this.calendarTitle, 'MMMM DD,YYYY').format('YYYY-MM-DD')}_${this.currentResourceId}`;
    }
    this.eventService.printPreScreenFormAsync(currentDate)
      .pipe(
        take(1)
      ).toPromise()
      .then(results => {
        if (!results || results.length === 0) {
          return this.dialogService.openConfirmDialog(setting);
        }
        const hasEmptyAnswer = [];
        const isOptionalAnswer = [];
        results.forEach(bookingForm => {
          hasEmptyAnswer.push(bookingForm.isCompleted);
          if (bookingForm.prescreenRequirement === PrescreenDisplay.Optional) {
            isOptionalAnswer.push(true);
          }
        });
        // tslint:disable-next-line:max-line-length
        const shouldNotShowPrintForm = hasEmptyAnswer.every(answer => answer === false) && isOptionalAnswer.every(answer => answer === true);
        if (shouldNotShowPrintForm) {
          return this.dialogService.openConfirmDialog(setting);
        }
        this.prescreenFormModel = results.map(result => {
          const questions = cloneDeep(CalendarConstant.preVaccinationQuestions);
          questions.forEach(question => {
            question.answer = result.formField[question.question];
          });
          result.formField = questions;
          return result;
        });
        this.changeDetector.detectChanges();
        const originalTitle = document.title;
        document.title = 'MedAdvisor - Calendar';
        const printContents = document.getElementById('view-print-section').innerHTML;
        this.setupPrintForm();
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const frame = document.getElementById('formFrame') as HTMLIFrameElement as any;

        const newDocument = document.implementation.createHTMLDocument('New Document');
        newDocument.write(CalendarConstant.printTemplate(printContents));

        // Copy the new HTML document into the frame
        const destDocument = frame.contentDocument;
        const srcNode = newDocument.documentElement;
        const newNode = destDocument.importNode(srcNode, true);

        destDocument.replaceChild(newNode, destDocument.documentElement);
        setTimeout(() => {
          // eslint-disable-next-line dot-notation
          window.frames['formFrame'].focus();
          window.frames['formFrame'].print();
        }, 400);
        setTimeout(() => {
          this.removeStyle('print-form');
          document.title = originalTitle;
        }, 800);
        return true;
      })
      .catch(err => err);
  }

  public navigateTo (destination: string) {
    this.router.navigate([destination]);
  }

  public checkPrintSupport (viewName: string): boolean {
    switch (viewName) {
      case CalendarViewAka.month:
      case CalendarViewAka.week:
      case CalendarViewAka.day:
      case CalendarViewAka.listWeek:
      case CalendarViewAka.listDay:
        return true;
      default:
        return false;
    }
  }

  // Reload event when change resource
  public handleResourceChange (event: MatSelectChange | number) {
    const resourceId = typeof event === 'number' ? event : +event.value;
    this.currentResourceId = resourceId;
    if (this.currentView !== CalendarViewAka.weekSchedule) {
      this.resourceOptionForNotWeekScheduleView = resourceId;
    } else {
      if (resourceId !== this.initResources[0].id) {
        this.resourceOptionForNotWeekScheduleView = resourceId;
      }
    }
    if (resourceId === 0) {
      this.resources = this.initResources;
      this.changeDetector.markForCheck();
      if (this.shouldShowResourceNavigationButton()) {
        this.doNavigationResource(1);
      }
    } else {
      this.resources = this.initResources.filter(res => res.id === resourceId);
    }
    this.loadEventBooking(this.startDate, this.endDate);
  }

  // Return resource name for metric area
  public getCurrentResourceName (): string {
    const currentResource = this.initResources.find(resource => resource.id === this.currentResourceId);
    return currentResource ? `(${currentResource.title})` : '(All rooms)';
  }

  // Count Booking for each service
  public countBookingPerService (eventBookings: EventBookingModel[]): ServiceCountItem[] {
    const serviceCounts: ServiceCountItem[] = [];
    eventBookings.forEach(event => {
      const serviceItemIndex = serviceCounts.findIndex(serviceItem => serviceItem.capabilityId === +event.capabilityId);
      if (serviceItemIndex >= 0) {
        serviceCounts[serviceItemIndex].count += 1;
        return;
      }
      const newItemServiceCount = new ServiceCountItem();
      newItemServiceCount.capabilityId = +event.capabilityId;
      newItemServiceCount.capabilityName = event.capabilityName;
      newItemServiceCount.count = 1;
      serviceCounts.push(newItemServiceCount);
    });

    return serviceCounts.sort((a, b) => {
      return a.capabilityName.localeCompare(b.capabilityName);
    });
  }

  // Whether next and prev button should show only for day and day schedule view
  public shouldShowResourceNavigationButton () {
    const isViewSupportResourcePagination = this.currentView === CalendarViewAka.day || this.currentView === CalendarViewAka.daySchedule;
    if (isViewSupportResourcePagination && this.currentResourceId === 0) {
      return true;
    }
    return false;
  }

  public doNavigationResource (pageNumber: number): void {
    if (pageNumber === 1) {
      this.reachTheFirstResource = true;
    } else {
      this.reachTheFirstResource = false;
    }

    if (pageNumber === this.pagesOfResource.length) {
      this.reachTheLastResource = true;
    } else {
      this.reachTheLastResource = false;
    }

    if (pageNumber >= 1 && pageNumber <= this.pagesOfResource.length) {
      this.activePageOfResource = pageNumber;
      const startIndex = (pageNumber - 1) * this.resourcePerPage;
      const endIndex = startIndex + this.resourcePerPage;
      this.resources = this.initResources.slice(startIndex, endIndex);
      this.calendarComponent.getApi().refetchResources();
      this.calendarComponent.getApi().refetchEvents();
    }
  }

  // #endregion

  // #region Dialog
  public showSettingsDialog (currentResourceId?: number) {
    const dialogConfig = new DialogConfig();
    dialogConfig.width = '80vw';
    dialogConfig.maxWidth = '1140px';
    return this.eventService.getCachedResourcesAsPromise()
      // tslint:disable-next-line:max-line-length
      .then(settings => this.dialogService.openCalendarCustomDialog(SettingsDialogComponent, { ...settings, currentResourceId }, dialogConfig))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(response => {
        return this.loadResources(false, true).pipe(take(1)).toPromise();
      })
      .then(resource => {
        const isTimeLineView = this.currentView === CalendarViewAka.timelineDay || this.currentView === CalendarViewAka.timelineWeek;
        const isDayScheduleView = this.currentView === CalendarViewAka.daySchedule || this.currentView === CalendarViewAka.day;
        // all resources are showing and resource deleted/added
        if (this.currentResourceId === 0) {
          if (isDayScheduleView) {
            this.doNavigationResource(this.activePageOfResource);
            this.changeDetector.markForCheck();
            return;
          }

          if (isTimeLineView) {
            this.calendarComponent.getApi().setOption('events', this.eventBookings);
            this.loadEventBooking(this.startDate, this.endDate, this.currentView);
            this.changeDetector.markForCheck();
            return;
          }
        }
        // current resource is showing and resource deleted from setting
        if (!resource.find(res => res.id === this.currentResourceId)) {
          if (isDayScheduleView) {
            this.currentResourceId = 0;
            this.resourceControl.patchValue(0);
            this.handleResourceChange(0);
            this.doNavigationResource(1);
            this.changeDetector.markForCheck();
            return;
          }

          if (isTimeLineView) {
            this.currentResourceId = 0;
            this.resourceControl.patchValue(0);
            this.handleResourceChange(0);
            this.calendarComponent.getApi().setOption('events', this.eventBookings);
            this.loadEventBooking(this.startDate, this.endDate, this.currentView);
            this.changeDetector.markForCheck();
            return;
          }
        }

        // With view has no resource axis, reload event
        // tslint:disable-next-line:max-line-length
        if (!isDayScheduleView &&
            this.currentView !== CalendarViewAka.day) {
          this.loadEventBooking(this.startDate, this.endDate, this.currentView);
        }
      })
      .catch(err => err);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public showPublicAccessDialog (): any {
    if (!this.showDialogue) {
      return;
    }
    this.showDialogue = false;
    const dialogConfig = new DialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.panelClass = ['website-access'];
    dialogConfig.autoFocus = false;
    return this.eventService.getCalendarTokenAsync()
      .then(tokens => {
        return this.dialogService.openEditDialog(WebsiteAccessDialogComponent, tokens, dialogConfig)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .then(val => {
            this.showDialogue = true;
          });
      })
      .catch(err => {
        this.showDialogue = true;
        return err;
      });
  }

  public showAddOrEditBooking (eventId?: number, currentDate?: string) {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    let eventBookedObservable: any = of(true);

    // Config data for modal
    const dialogConfig = new DialogConfig();
    dialogConfig.width = '85vw';
    dialogConfig.maxWidth = '1240px';
    const data = new CalendarDialogData({ resources: this.initResources });
    data.settings.operatingHours = this.operatingHours;
    data.settings.earliestOpening = this.earliestOpening;
    data.settings.latestClosing = this.latestClosing;
    data.settings.eventsBooked = this.eventBookings;

    if (eventId === 0 || eventId) {
      data.settings.eventId = eventId;
      eventBookedObservable = this.eventService.retrieveAnEventBookings(eventId)
        .pipe(
          take(1)
        );
    }
    if (currentDate) {
      data.settings.currentDate = currentDate;
    }
    dialogConfig.autoFocus = false;

    // Whether should open settings modal or not
    eventBookedObservable
      .toPromise()
      .then(res => {
        if (res === true) {
          return this.dialogService.openCustomDialog(BookingDialogComponent, dialogConfig, data);
        }
        // Assign event get from server for config object
        data.settings.event = res;
        return this.dialogService.openCustomDialog(BookingDialogComponent, dialogConfig, data);
      })
      .then(response => {
        if (response && response.openSettingModal) {
          return this.showSettingsDialog(response.resourceId);
        }
        return this.loadEventBooking(this.startDate, this.endDate);
      })
      .then(result => result)
      .catch(error => error);
  }

  // #endregion

  // #region Utils methods
  // Init form
  public initializeForm () {
    this.layoutControl = new UntypedFormControl(this.layouts[0].id);

    this.resourceControl = new UntypedFormControl(0);

    this.calendarForm = new UntypedFormGroup({
      layout: this.layoutControl,
      resources: this.resourceControl
    });
  }

  // Listen event form message board cast
  private listenEventFromMessageBoardCast (): void {
    const messageDate = this.activatedRoute.snapshot.queryParams['message-date'];
    if (messageDate) {
      this.router.navigate([]);
      this.calendarComponent.getApi().gotoDate(messageDate);
      const element = document.querySelector('td[data-date=\'' + messageDate + '\']');
      if (element) {
        element.setAttribute('data-day', 'fill');
      }
    }
  }

  private mappingNumberToDayOfWeek (day: number): string {
    switch (day) {
      case 0:
        return 'sun';
      case 1:
        return 'mon';
      case 2:
        return 'tue';
      case 3:
        return 'wed';
      case 4:
        return 'thu';
      case 5:
        return 'fri';
      case 6:
        return 'sat';
      default:
        break;
    }
  }

  private handleDoubleClickOnDateCell () {
    const el = this.fCalendarElement.nativeElement;
    const clickEvent = fromEvent<MouseEvent>(el, 'click');
    const eventSubscription = clickEvent.pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap((event) => {
        this.dblClickCount += 1;
      })
    ).subscribe();
    this._subscription.add(eventSubscription);
  }

  private handleScrollEvent () {
    const clickEvent = fromEvent(window, 'wheel');
    const eventSubscription = clickEvent.pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap((event) => {
        if (this.currentPopover) {
          this.hideTooltip(this.currentPopover);
        }
      })
    ).subscribe();
    this._subscription.add(eventSubscription);
  }

  // Set calendar slot time
  private setSlotTimeOptionsForCalendar () {
    const getOpeningHourSubscription = this.eventService.getCachedResources().subscribe(resourceSetting => {
      if (resourceSetting) {
        this.calendarComponent.getApi().setOption('slotMinTime', resourceSetting.earliestOpening);
        this.calendarComponent.getApi().setOption('slotMaxTime', resourceSetting.latestClosing);
      }
    });
    this._subscription.add(getOpeningHourSubscription);
  }

  // #region Navigate Resource for Weekschedule view
  private getArrayOfPage (pageCount: number): number [] {
    const pageArray = [];

    if (pageCount > 0) {
      for (let i = 1; i <= pageCount; i++) {
        pageArray.push(i);
      }
    }

    return pageArray;
  }

  private getPageCount (): number {
    let totalPage = 0;

    if (this.totalResources > 0 && this.resourcePerPage > 0) {
      const pageCount = this.totalResources / this.resourcePerPage;
      const roundedPageCount = Math.floor(pageCount);

      totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }

    return totalPage;
  }
  // #endregion

  // #region Tooltip
  private renderTooltip (event) {
    const projectableNodes = Array.from(event.el.childNodes);

    const compRef = this.popoverFactory.create(
      this.injector,
      [projectableNodes],
      event.el
    );
    compRef.instance.template = this.popoverTemplateRef;
    // const rootElement = document.querySelector(':root');
    // rootElement.style.setProperty('--event-color', event.borderColor);
    this.appRef.attachView(compRef.hostView);
    this.popoversMap.set(event.el, compRef);
  }

  private destroyTooltip (event) {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      this.appRef.detachView(popover.hostView);
      popover.destroy();
      this.popoversMap.delete(event.el);
    }
  }

  private showTooltip (event) {
    const popover = this.popoversMap.get(event.el);
    this.currentPopover = event;
    if (popover) {
      const startTime = moment.utc(event.event.startStr).format('hh:mm A');
      let endTime = ` - ${moment.utc(event.event.endStr).format('hh:mm A')}`;

      if ((this.currentView === CalendarViewAka.month ||
          this.currentView === CalendarViewAka.day ||
          this.currentView === CalendarViewAka.listDay ||
          this.currentView === CalendarViewAka.listWeek) && event.event.extendedProps) {
        endTime = ` - ${moment.utc(event.event.extendedProps.end).format('hh:mm A')}`;
      }

      if (endTime === ' - Invalid date') {
        endTime = '';
      }

      const time = `${startTime}${endTime}`;
      const patientName = `${event.event.extendedProps.fullName}`;
      const serviceName = `${event.event.display}`;
      const extendedProps = { time, patientName, serviceName };
      const eventElement = event.el;
      const mouseEnterObservable = fromEvent(eventElement, 'mouseenter').pipe(
        mapTo(true)
      );

      const mouseLeaveObservable = fromEvent(eventElement, 'mouseleave').pipe(
        mapTo(false)
      );

      merge(mouseEnterObservable, mouseLeaveObservable)
        .pipe(
          switchMap(value => {
            if (!value) {
              return EMPTY;
            }
            return of(true).pipe(delay(1000));
          })
        )
        .subscribe(result => {
          if (result) {
            popover.instance.popover.open({ event: { extendedProps } });
          }
        });
    }
  }

  private hideTooltip (event) {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      popover.instance.popover.close();
    }
  }
  // #endregion

  public validateDayWithinBusinessHours (date) {
    const dayWeek = moment(date).utc().day();

    const businessDay = this.eventService.getBusinessHours().find(bh => bh.daysOfWeek.includes(dayWeek));

    if (!businessDay) {
      return false;
    }

    const openTimeCurrentDay = moment(businessDay.startTime, 'HH:mm');
    const closeTimeCurrentDay = moment(businessDay.endTime, 'HH:mm');

    const stripTime = moment(moment(date).utc().format('HH:mm'), 'HH:mm').utc();
    return stripTime.isBetween(openTimeCurrentDay, closeTimeCurrentDay);
  }

  // #endregion

  private setupPrintPage () {
    const pageWidth = 215.9;
    const pageHeight = 279.4;
    const marginTopBottom = 15;
    const marginLeftRight = 15;
    const width = Math.floor(document.getElementById('calendar-main-div').offsetWidth * 25.4 / 96);

    const styleElement = this.stylesMap.get('print-view');
    if (styleElement) {
      return;
    }
    const printViewStyle = CalendarConstant.printViewSetupStyle(pageHeight, pageWidth, marginTopBottom, marginLeftRight, width);
    this.addStyle('print-view', printViewStyle);
  }

  private setupPrintForm () {
    const styleElement = this.stylesMap.get('print-form');
    if (styleElement) {
      return;
    }
    const printViewStyle = CalendarConstant.printFormTemplate;
    this.addStyle('print-form', printViewStyle);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public showToastMessage (message: string, toastClass: string, timeOut = 3000): any {
    this.toastr.clear();
    this.toastr.overlayContainer = this.toastContainer;
    const positionClass = new Date().getTime().toString() + Math.random().toString();
    return this.toastr.show(message, null, { toastClass, timeOut, positionClass });
  }

  private createStyleNode (style: string): Node {
    const styleNode = document.createElement('style');
    styleNode.textContent = style;
    return styleNode;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private addStyle (key: any, style: string): void {
    const styleNode = this.createStyleNode(style);
    this.stylesMap.set(key, styleNode);
    this.host.appendChild(styleNode);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private removeStyle (key: any): void {
    const styleNode = this.stylesMap.get(key);
    if (styleNode) {
      this.stylesMap.delete(key);
      this.host.removeChild(styleNode);
    }
  }

  private removePrintPreScreenButton () {
    if (this.currentView === CalendarViewAka.listDay) {
      const printElement = document.querySelector('.print-completed-screen-form');
      if (printElement) {
        printElement.remove();
      }
    }
  }
  // #endregion
}
