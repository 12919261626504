import { LoadingSpinnerComponent } from './loading-spinner.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerService } from './spinner.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    RouterModule
  ],
  declarations: [
    LoadingSpinnerComponent
  ],
  providers: [
    SpinnerService
  ],
  exports: [
    LoadingSpinnerComponent
  ]
})
export class LoadingSpinnerModule {

}
