/* eslint-disable @typescript-eslint/no-unused-vars */
import { Observable } from 'rxjs';
import {EventBookingResult} from 'src/app/api/ApiRecordTypes/EventBookingResult';
import {EventBookingSearchModel} from 'src/app/api/ApiRecordTypes/EventBookingSearchModel';
import {EventPreferenceModel} from 'src/app/api/ApiRecordTypes/EventPreferenceModel';
import {EventResourceModel} from 'src/app/api/ApiRecordTypes/EventResourceModel';
import {EventResourceResult} from 'src/app/api/ApiRecordTypes/EventResourceResult';
import {EventServiceModel} from 'src/app/api/ApiRecordTypes/EventServiceModel';
import { NhsScrRequest, ParsedScrData, ExternalPatientProfileStatus, ParsedScrRow,
  PublicExternalSessionDetailsWithAuth, PatientProfileStatusEnum, ExternalPatientProfileError } from './ApiRecordTypes/NHSRecords';
import {
  MedicationModel,
  PatientActiveIngredientsModel,
  PatientRecord,
  PatientRelationshipModel,
  PDSRecord
} from './ApiRecordTypes/PatientRecord';
import { PracticeRecord } from './ApiRecordTypes/PracticeRecord';
import { OperationResult, OperationResultEnum, PagingContext, OperationPagedResult, NoData } from './ApiRecordTypes/OperationResults';
import { FilterRequest, FilterResult } from '../utility/filtering/filtertypes';
import { CRUD, CRUDPagedList, CrudOps } from './api.generic_accessors';
import { IdentityRecord } from './ApiRecordTypes/IdentityRecord';
import { AddressModel } from './ApiRecordTypes/GenericApiModels';
import { PagedDataSource } from '../utility/filtering/pageddatasource.module';
import { HttpResponse } from '@angular/common/http';
import { AuditRecord, UserAuditError } from './ApiRecordTypes/AuditRecord';
import { PharmacyModel } from './ApiRecordTypes/PharmacyRecord';
import { PracticeModel, PendingTaskRecord, DrugModel, PatientModel } from './ApiRecordTypes/PendingTaskRecord';
import { AuthResponse } from 'src/app/api/ApiRecordTypes/AuthResponse';
import { AuthLogin } from 'src/app/api/ApiRecordTypes/AuthLogin';
import {
  BroadcastModel,
  GroupsForPatientRequestModel,
  IdResultModel,
  LinkPatientGroupModel,
  PatientFromGroupRequestModel,
  PatientGroupModel, TemplateBroadcastModel
} from './ApiRecordTypes/PatientGroup';
import {PrescreenFormModel} from './ApiRecordTypes/PreScreenFormModel';
import {CalendarAccessTokenModel} from './ApiRecordTypes/CalendarAccessTokenModel';
import {CalendarTokenResultModel} from './ApiRecordTypes/CalendarTokenResultModel';
import {PatientSearchModel} from './ApiRecordTypes/PatientSearchModel';
import { HSHCoreStructurePatientPatientModel } from 'src/app/api/ApiRecordTypes/swaggerSchema-hsh-v1';
import { AuthResult } from './ApiRecordTypes/AuthResult';
import { LogEntryModel } from '../logging/log-entry.model';

export { PendingTaskRecord, PatientRecord, PracticeRecord, OperationResult, OperationResultEnum,
         PagingContext, OperationPagedResult, CRUD, CRUDPagedList, CrudOps, IdentityRecord,
         NhsScrRequest, ParsedScrData, ParsedScrRow, PublicExternalSessionDetailsWithAuth, AuditRecord,
         ExternalPatientProfileStatus, PatientProfileStatusEnum, ExternalPatientProfileError};

export class UserDetails {
  email = '';
  fname = '';
  lname = '';
  pending = 0;
  fetchTime = new Date(0).valueOf();
  tokenRemaining = null;
}

export class ApiCallResult {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  context: any;
}

export class IdentifiedObject {
  id: number;
}

export class Practice extends IdentifiedObject {
  systemCode: string;
}

export class PatientDetails {
  patientcode?: string;
  pharmacy: IdentifiedObject;
  pharmacyGroup: IdentifiedObject;
  practice: Practice;
  drugs: DrugModel[];
  properties: {
    firstname?: string;
    lastname?: string;
    dateofbirth?: string;
    phone?: string;
    zipcode?: string;
    gender?: string;
    carerId?: string;
  };
  address: AddressModel;
}

export class MessageInbox {
  id: number;
  messageId: number;
  patientId: number;
  patientCode: string;
  pharmacyId: number;
  content: string;
  createDate: Date;
  fromPharmacy: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  code: any;
  parentId: number;
}

export class IAPIService {
  pendingTasks: CRUDPagedList<PendingTaskRecord, NoData>;
  patientList: CRUDPagedList<PatientRecord, NoData>;
  messageInboxList: CRUDPagedList<MessageInbox, NoData>;
  auditList: CRUDPagedList<AuditRecord, UserAuditError>;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  getSystemSettings(): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getAuthToken(username, password): Observable<OperationResult<AuthResult>>  {
    throw new Error('Method not implemented.');
  }
  invitePatient(details: PatientDetails): Observable<OperationResult<{id: string}>> {
    throw new Error('Method not implemented.');
  }
  invitePatientCarer(details: PatientDetails): Observable<OperationResult<{id: string}>> {
    throw new Error('Method not implemented.');
  }
  getPatientList(settings: PagingContext): Observable<OperationPagedResult<PatientRecord, NoData>> {
    throw new Error('Method not implemented.');
  }
  getAuditList(settings: PagingContext): Observable<OperationPagedResult<AuditRecord, NoData>> {
    throw new Error('Method not implemented.');
  }
  getPracticeList(pharmacyId: number): Observable<PagedDataSource<PracticeRecord, NoData>> {
    throw new Error('Method not implemented.');
  }
  getPracticeDetails(pharmacyId: number, practiceId: number): Observable<OperationResult<PracticeRecord>> {
    throw new Error('Method not implemented.');
  }
  getDispenseRequest(patientguid: string, requestId: number): Observable<OperationResult<PracticeRecord>> {
    throw new Error('Method not implemented.');
  }
  getPractice(practiceId: number): Observable<OperationResult<PracticeModel>> {
    throw new Error('Method not implemented.');
  }
  setPracticeDetails(details): Observable<OperationResult<PracticeRecord>> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  updateComponentStatus(details): Observable<OperationResult<any>> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  resendMessageToGP(id: number): Observable<OperationResult<any>> {
    throw new Error('Method not implemented.');
  }
  setPharmacySettings(details): Observable<OperationResult<PharmacyModel>> {
    throw new Error('Method not implemented.');
  }
  deletePractice(pharmacyId: number, practiceId: number): Observable<OperationResult<PracticeRecord>> {
    throw new Error('Method not implemented.');
  }
  getPatientFilters(settings: FilterRequest): Observable<OperationResult<FilterResult>> {
    throw new Error('Method not implemented.');
  }
  getUserDetails(force = false): Promise<UserDetails> {
    throw new Error('Method not implemented.');
  }
  getAccountDetails(): Observable<ApiCallResult> {
    throw new Error('Method not implemented.');
  }
  getPharmacySettings(pharmacyId: number): Observable<PharmacyModel> {
    throw new Error('Method not implemented.');
  }
  getPatientDetails(patientCode_MedAdvisorGuid: string): Observable<PatientModel> {
    throw new Error('Method not implemented.');
  }
  getRelated(patientCode_MedAdvisorGuid: string): Observable<OperationResult<PatientRelationshipModel>> {
    throw new Error('Method not implemented.');
  }
  retrivePatientDrugs(patientCode_MedAdvisorGUID: string): Observable<MedicationModel[]> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  getExternalPatient(patientDetails: any): Observable<OperationResult<PDSRecord>> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  searchGPSurgery(detail: any): Observable<PagedDataSource<PracticeModel, NoData>> {
    throw new Error('Method not implemented.');
  }
  setAccountDetails(details): Observable<ApiCallResult> {
    throw new Error('Method not implemented.');
  }
  generatePdf(id: number): Observable<ApiCallResult>  {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  getPDFUrl(id: any): Observable<ApiCallResult>  {
    throw new Error('Method not implemented.');
  }
  checkQxlvaToken(token): Observable<PublicExternalSessionDetailsWithAuth> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  performAction(action: any, id: any): Observable<ApiCallResult> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  performGPApprovedAction(id: any): Observable<ApiCallResult> {
    throw new Error('Method not implemented.');
  }
  deletePatient(patientCode_MedAdvisorGUID: string): Observable<OperationResult<IdentityRecord>> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  searchDrug(drugSearch: any): Observable<OperationPagedResult<DrugModel, NoData>> {
    throw new Error('Method not implemented.');
  }
  checkIsStatusAvailable (nhsNumber: string, dob: string, nhsSessionId: string): Observable<ExternalPatientProfileStatus> {
    throw new Error('Method not implemented.');
  }
  fetchPatientExternalDruglist (request: NhsScrRequest, parseExternalDrugList = false): Observable<ParsedScrData> {
    throw new Error('Method not implemented.');
  }
  reloadPatientDrugProfile(patientCode_MedAdvisorGUID: string): Observable<HttpResponse<OperationResult<NoData>>> {
    throw new Error('Method not implemented.');
  }
  getTemplateSmsText(settingName: string): Observable<string> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  updateMedication(details: any): Observable<OperationResult<any>> {
    throw new Error('Method not implemented.');
  }

  verifySetPasswordToken (authLogin: AuthLogin): Observable<AuthResponse> {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setPasswordAsync(authLogin: AuthLogin): Observable<any> {
    throw new Error('Method not implemented.');
  }

  //#region Calendar API

  //#region EventResources API
  // GET: Pharmacy/{id}/eventresource
  retrieveResources(pharmacyId: number): Observable<EventResourceResult> {
    throw new Error('Method not implemented.');
  }

  // GET: Pharmacy/{id}/eventresource/{resourceId}
  retrieveResourceById(pharmacyId: number, resourceId: number): Observable<EventResourceResult> {
    throw new Error('Method not implemented.');
  }

  // POST Pharmacy/{id}/eventresource/{name}
  createsNewEventResource(pharmacyId: number, resourceName: string): Observable<{id: string }> {
    throw new Error('Method not implemented.');
  }

  // POST Pharmacy/eventresource/update
  updateEventResources(model: EventResourceModel): Observable<{id: string }> {
    throw new Error('Method not implemented.');
  }

  // POST Pharmacy/{id}/eventresource/{resourceId}/delete
  deleteEventResourceById(pharmacyId: number, resourceId: number): Observable<{id: string }> {
    throw new Error('Method not implemented.');
  }

  // Update event preferences (calendar layout)
  // POST: /api/v1/Pharmacy/eventpreferences
  updateEventPreferences(eventPreference: EventPreferenceModel): Observable<{id: string }> {
    throw new Error('Method not implemented.');
  }
  //#endregion

  //#region EventServices
  // Get all services
  // GET Pharmacy/{{id}}/services
  retrieveServices(pharmacyId: number): Observable<EventServiceModel[]> {
    throw new Error('Method not implemented.');
  }

  // Create a new service
  // POST Pharmacy/eventservice/update
  createANewService(model: EventServiceModel): Observable<{id: string }> {
    throw new Error('Method not implemented.');
  }

  // Update a service
  // POST Pharmacy/eventservice/update
  updateAService(model: EventServiceModel): Observable<number> {
    throw new Error('Method not implemented.');
  }

  // Set service to others resources
  // POST Pharmacy/eventservice/update
  updateAssociateServiceToResources(model: EventServiceModel, resourcesId: number[]): Observable<{id: string }> {
    throw new Error('Method not implemented.');
  }

  //#endregion

  //#region EventBooking
  // GET: /api/v1/Pharmacy/{id}/RetrieveEventBookings
  retrieveEventBookings(
    id: number,
    startDate: string,
    endDate: string,
    resourceId: number,
    includeResourceAvailability: boolean): Observable<EventBookingResult> {
    throw new Error('Method not implemented.');
  }

  // Create event booking
  // POST Pharmacy/eventbooking/update
  createEventBookings(model: EventBookingSearchModel): Observable<{id: string }> {
    throw new Error('Method not implemented.');
  }
  //#endregion

  //#region Broadcast message
  retrievesPatientGroupAsync(pharmacyId: number): Observable<PatientGroupModel[]> {
    throw new Error('Method not implemented.');
  }

  retrievesPatientGroupByIdAsync(patientGroupId: number): Observable<PatientGroupModel> {
    throw new Error('Method not implemented.');
  }

  createOrUpdatePatientGroupAsync(patientGroupModel: PatientGroupModel): Observable<OperationResult<IdResultModel>> {
    throw new Error('Method not implemented.');
  }

  deletePatientGroupAsync(pharmacyId: number, patientGroupId: number): Observable<NoData> {
    throw new Error('Method not implemented.');
  }

  retrievePatientGroupForPatientAsync(pharmacyId: number, patientId: number): Observable<PatientGroupModel[]> {
    throw new Error('Method not implemented.');
  }

  retrieveLinkPatientGroupAsync(pharmacyId: number): Observable<LinkPatientGroupModel[]> {
    throw new Error('Method not implemented.');
  }

  retrieveLinkPatientGroupByGroupIdAsync(pharmacyId: number, groupId: number): Observable<LinkPatientGroupModel[]> {
    throw new Error('Method not implemented.');
  }

  includeOrExcludePatientFromPatientGroupAsync(requestModel: PatientFromGroupRequestModel): Observable<NoData> {
    throw new Error('Method not implemented.');
  }

  includeOrExcludeGroupForPatientAsync(pharmacyId: number, requestModel: GroupsForPatientRequestModel): Observable<NoData> {
    throw new Error('Method not implemented.');
  }

  retrieveAllTemplateBroadcast(pharmacyId: number): Observable<TemplateBroadcastModel[]> {
    throw new Error('Method not implemented.');
  }

  createOrUpdateBroadcastTemplateAsync(templateBroadcastModel: TemplateBroadcastModel): Observable<OperationResult<IdResultModel>> {
    throw new Error('Method not implemented.');
  }

  deleteMessageTemplateAsync(pharmacyId: number, templateId: number): Observable<NoData> {
    throw new Error('Method not implemented.');
  }

  sendBroadcastMessageAsync(broadcastModel: BroadcastModel): Observable<NoData> {
    throw new Error('Method not implemented.');
  }

  retrievesAllActivePatientsAsync(pharmacyId: number): Observable<PatientActiveIngredientsModel[]> {
    throw new Error('Method not implemented.');
  }

  printPreScreenFormAsync(pharmacyId: number, bookingCode: string): Observable<PrescreenFormModel[]> {
    throw new Error('Method not implemented.');
  }

  getCalendarTokenAsync(pharmacyId: number, resetStaffUrl: boolean): Observable<CalendarAccessTokenModel> {
    throw new Error('Method not implemented.');
  }

  getAuthCalendarTokenAsync (calendarToken: string): Observable<CalendarTokenResultModel> {
    throw new Error('Method not implemented.');
  }

  // PatientPublicModel
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  retrieveRelatedPatientAsync (patientId: number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  retrievePatientListAsync (patientSearhModel: PatientSearchModel): Observable<HSHCoreStructurePatientPatientModel[]> {
    throw new Error('Method not implemented.');
  }

  retrievePharmacySettings (pharmacyId: number): Observable<PharmacyModel> {
    throw new Error('Method not implemented.');
  }

  public getSetting(settingName: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public logAsync(logEntry: LogEntryModel): Observable<OperationResult<any>> {
    throw new Error('Method not implemented.');
  }
  //#endregion
}
