import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import { LogEntryModel } from '../log-entry.model';
import { LogLevelEnum } from '../log-level.enum';
import { ILogService } from '../log-service.interface';

@Injectable()
export class LogConsoleService implements ILogService {
  public logAsync(message: string, stackTrace: string, logLevel: LogLevelEnum, optionalParams: string[]): Observable<void> {
    return of(void (0)).pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tap(_ => {
          const logEntry = new LogEntryModel();
          logEntry.message = message;
          logEntry.stackTrace = stackTrace;
          logEntry.logLevel = logLevel;
          logEntry.extraInfo = optionalParams;

          const logString = logEntry.buildLogString();
          if (logEntry.logLevel >= LogLevelEnum.error ) {
            console.error(logString);
          } else {
            console.log(logString);
          }
        })
    );
  }
}
