import {
  Component, ViewEncapsulation, EventEmitter, Output,
  Input, ChangeDetectionStrategy, DoCheck, AfterContentInit
} from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from 'lodash';
import { IDialogEmbeddable } from 'src/app/utility/base-dialog/dialog.service';

import { OperationResult } from '../../api/iapi.service';

enum ScreenState {
  search = 'Search',
  confirmPatientDetails = 'confirmPatientDetails',
  notFound = 'notFound',
  inviteSucess = 'inviteSucess',
  errorMessage = 'errorMessage'
}

export class PatientAddOutput extends OperationResult<{ id: string }> { }
export class PatientAddConfig {
  public ShowCancel = false;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-patient-add',
  templateUrl: './patientadd.component.html',
  styleUrls: ['./patientadd.component.css'],
})
export class PatientAddComponent implements IDialogEmbeddable<PatientAddConfig, PatientAddOutput>, DoCheck, AfterContentInit {
  @Input() public data: PatientAddConfig = new PatientAddConfig();
  @Output() public OnComplete: EventEmitter<PatientAddOutput> = new EventEmitter<PatientAddOutput>(true);
  // Initial Screen
  displayScreen = ScreenState.search;
  searchingState = null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  public setData(data: PatientAddConfig): void {
    this.data = data;
  }

  cancelAddPatient() {
    this.OnComplete.emit(null);
  }

  ngDoCheck() {
    // eslint-disable-next-line no-self-assign
    this.displayScreen = this.displayScreen;
  }
  ngAfterContentInit() {
    // eslint-disable-next-line no-self-assign
    this.displayScreen = this.displayScreen;
  }
}
