import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { from, of, Subject, Subscription, throwError } from 'rxjs';
import { BroadcastPatientDialogComponent } from '../broadcastpatientdialog/broadcastpatientdialog.component';
import { DialogConfig, DialogService } from '../../utility/base-dialog/dialog.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { APIService } from '../../api/api.service';
import { catchError, debounceTime, delay, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BroadcastModel,
  LinkPatientGroupModel,
  PatientFromGroupRequestModel,
  PatientGroupModel,
  TemplateBroadcastModel } from '../../api/ApiRecordTypes/PatientGroup';
import { DrugModel } from '../../api/ApiRecordTypes/PendingTaskRecord';
import { Observable } from 'rxjs/internal/Observable';
import { PatientActiveIngredientsModel } from '../../api/ApiRecordTypes/PatientRecord';
import * as moment from 'moment';
import { OperationResultEnum } from '../../api/ApiRecordTypes/OperationResults';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class HelpTag {
  title: string;
  escapeTitle: string;

  constructor(title, escapeTitle) {
    this.title = title;
    this.escapeTitle = escapeTitle;
  }
}

const WORD_JOINER_CHAR_CODE = 8288;

@Component({
  selector: 'app-broadcast-message',
  templateUrl: './broadcastmessage.component.html',
})
export class BroadcastMessageComponent implements OnInit, OnDestroy, AfterViewInit {

  //#region Properties
  // Template variable of rich text editor
  @ViewChild('richTextEditor', { read: ElementRef })
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public richTextEditor: ElementRef<any>;

  public editor = ClassicEditor;

  // CDKEditor config
  public editorConfig = {
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'http://',
    },
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'undo', 'redo']
  };

  public pharmacyId: number;

  public shouldDisplayActiveIngredientAutocomplete = false;

  public isLoading = true;

  public isSubmittingPatientGroup = false;

  public isSearchIngredientComplete = true;

  public get isSendBroadcastAvailable(): boolean {
    if (!this.selectedPatientGroup || !this.selectedMessageTemplate) {
      return false;
    }
    if (this.isPatientGroupNotSaved || this.isTemplateNotSaved) {
      return false;
    }
    return true;
  }

  public get isDeletePatientGroupAvailable(): boolean {
    return Boolean(this.patientGroupIdControl.value);
  }

  public get isPatientGroupNotSaved(): boolean {
    if (this.patientGroupNameControl.dirty || this.patientGroupCriteriaForm.dirty) {
      return true;
    }

    if (this.activeIngredientsBeforeChanged.length !== this.activeIngredients.length) {
      return true;
    }

    const removeActiveIngredients = this.activeIngredientsBeforeChanged
      .filter(ingredient => !this.activeIngredients.includes(ingredient));
    const addedActiveIngredients = this.activeIngredients
      .filter(ingredient => !this.activeIngredientsBeforeChanged.includes(ingredient));
    if (removeActiveIngredients.length || addedActiveIngredients.length) {
      return true;
    }

    if (!this.checkedPatientIdsAfterManualEdit) {
      return false;
    }

    if (this.initialCheckedPatientIdsOfSelectedGroup.length !== this.checkedPatientIdsAfterManualEdit.length) {
      return true;
    }

    const removedPatientIds = this.initialCheckedPatientIdsOfSelectedGroup
      .filter(id => !this.checkedPatientIdsAfterManualEdit.includes(id));
    const addedPatientIds = this.checkedPatientIdsAfterManualEdit
      .filter(id => !this.initialCheckedPatientIdsOfSelectedGroup.includes(id));
    if (removedPatientIds.length || addedPatientIds.length) {
      return true;
    }

    return false;
  }

  public get isTemplateNotSaved(): boolean {
    return this.templateNameControl.dirty || this.templateSubjectControl.dirty || this.templateBodyControl.dirty;
  }

  public get isDeleteMessageTemplateAvailable(): boolean {
    return Boolean(this.templateIdControl.value);
  }

  public get isDefaultPatientGroup(): boolean {
    if (this.selectedPatientGroup && this.selectedPatientGroup.preferences &&
      this.selectedPatientGroup.preferences.includes('DefaultGroup')) {
      return true;
    }
    return false;
  }

  // Count number of character of message body
  public messageBodyCount = 0;

  //#region Patient Group Section Properties
  public patientGroupForm: UntypedFormGroup;

  public patientGroupCriteriaForm: UntypedFormGroup;

  public patientGroupIdControl: UntypedFormControl;

  public patientGroupNameControl: UntypedFormControl;

  public patientGroupGenderArray: UntypedFormArray;

  public patientGroupAgeFromControl: UntypedFormControl;

  public patientGroupAgeToControl: UntypedFormControl;

  public ingredientSearchForm: UntypedFormGroup;

  public ingredientSearchControl: UntypedFormControl;

  public genderOptions = [
    {
      label: 'Female',
      key: 'F'
    },
    {
      label: 'Male',
      key: 'M'
    },
    {
      label: 'Other',
      key: 'O'
    }
  ];

  public activeIngredients: string[] = [];

  public activeIngredientsBeforeChanged: string[] = [];

  public activeIngredientsAutocomplete: string[] = [];

  public patientGroups: PatientGroupModel[] = [];

  public linkPatients: LinkPatientGroupModel[];

  public genderCriteriaControl = new UntypedFormControl('');

  public ageCriteriaControl = new UntypedFormControl('');

  public ingredientCriteriaControl = new UntypedFormControl('');

  private selectedPatientGroup: PatientGroupModel;

  private initialCheckedPatientIdsOfSelectedGroup: number[] = [];

  private checkedPatientIds: number[] = [];

  private checkedPatientIdsAfterManualEdit: number[];

  private includePatientIds: number[] = [];

  private excludePatientIds: number[] = [];

  private patientMatchCriteriaIds: number[] = [];

  //#endregion

  //#region Message Template Section Properties

  public messageTemplateForm: UntypedFormGroup;

  public templateIdControl: UntypedFormControl;

  public templateNameControl: UntypedFormControl;

  public templateSubjectControl: UntypedFormControl;

  public templateBodyControl: UntypedFormControl;

  public messageTemplates: TemplateBroadcastModel[];

  public selectedMessageTemplate: TemplateBroadcastModel;

  public helpTags: HelpTag[] = [
    new HelpTag('<<PatientFirstName>>', '&lt;&lt;PatientFirstName&gt;&gt;'),
    new HelpTag('<<PatientLastName>>', '&lt;&lt;PatientLastName&gt;&gt;'),
    new HelpTag('<<PharmacyName>>', '&lt;&lt;PharmacyName&gt;&gt;'),
    new HelpTag('<<PharmacyPhone>>', '&lt;&lt;PharmacyPhone&gt;&gt;')
  ];

  //#endregion

  private searchActiveIngredient$: Subject<string>;

  private patientGroup$: Subject<void>;

  private messageTemplate$: Subject<void>;

  private linkPatientGroup$: Subject<number>;

  private editorChange$: Subject<void>;

  private allPatients: PatientActiveIngredientsModel[] = [];

  private selectedLinkPatientsGroup: LinkPatientGroupModel[] = [];

  private readonly _subscription: Subscription;
  //#endregion

  //#region Constructor
  constructor(
    protected apiService: APIService,
    protected cdr: ChangeDetectorRef,
    protected dialogService: DialogService,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject('SESSIONSTORAGE') private sessionStorage: any
  ) {
    this._subscription = new Subscription();
    this.searchActiveIngredient$ = new Subject();
    this.patientGroup$ = new Subject();
    this.messageTemplate$ = new Subject();
    this.linkPatientGroup$ = new Subject();
    this.editorChange$ = new Subject();
    this.buildPatientGroupSectionForm();
    this.buildMessageTemplateSectionForm();
  }

  //#endregion

  //#region Hook Methods
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private hookSearchIngredientAsync(): Observable<any> {
    return this.searchActiveIngredient$.asObservable().pipe(
      debounceTime(500),
      switchMap((key: string) => {
        this.isSearchIngredientComplete = false;
        this.cdr.markForCheck();
        const searchModel = {
          pageNumber: 1,
          numberPerPage: 20,
          properties: {isMobileSearch: false},
          activeName: key,
        };
        return this.apiService.searchDrug(searchModel).pipe(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          catchError(_ => of(null))
        );
      }),
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      tap((drugList: any) => {
        if (!drugList || !drugList.data || !drugList.data.length) {
          this.activeIngredientsAutocomplete = [];
          this.isSearchIngredientComplete = null;
          this.cdr.markForCheck();
          return;
        }
        this.isSearchIngredientComplete = true;
        const activeIngredientsAutocomplete = new Set((drugList.data as DrugModel[]).map(drug => drug.activeName));
        this.activeIngredientsAutocomplete = Array.from(activeIngredientsAutocomplete);
        this.cdr.markForCheck();
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError(_ => {
        this.isSearchIngredientComplete = null;
        this.activeIngredientsAutocomplete = [];
        return throwError(_);
      })
    );
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private hookPatientGroupAsync(): Observable<any> {
    return this.patientGroup$.asObservable().pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(_ => this.apiService.retrievesPatientGroupAsync(this.pharmacyId)),
      tap((patientGroups: PatientGroupModel[]) => {
        this.patientGroups = patientGroups || [];
        this.patientGroups.sort((firstGroup, secondGroup) => {
          return firstGroup.name.localeCompare(secondGroup.name);
        });
        this.cdr.markForCheck();
      })
    );
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private hookMessageTemplateAsync(): Observable<any> {
    return this.messageTemplate$.asObservable().pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(_ => this.apiService.retrieveAllTemplateBroadcast(this.pharmacyId)),
      tap((messageTemplates: TemplateBroadcastModel[]) => {
        this.messageTemplates = messageTemplates || [];
        this.messageTemplates.sort((firstTemplate, secondTemplate) => {
          return firstTemplate.name.localeCompare(secondTemplate.name);
        });
        this.messageTemplates.forEach(m => {
          m.body = m.body.split('<br/>').join('\n');
        });
        this.cdr.markForCheck();
      })
    );
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private hookLinkPatientGroupAsync(): Observable<any> {
    return this.linkPatientGroup$.asObservable().pipe(
      switchMap(id => this.apiService.retrieveLinkPatientGroupByGroupIdAsync(this.pharmacyId, id)),
      tap((linkPatientsGroup: LinkPatientGroupModel[]) => {
        this.selectedLinkPatientsGroup = [];
        if (linkPatientsGroup && linkPatientsGroup.length) {
          // TODO: Re-check when API fixed
          this.selectedLinkPatientsGroup = linkPatientsGroup.filter(link => {
            const index = this.allPatients.findIndex(patient => patient.mixedPatientModel.id === link.patientId);
            return index !== -1;
          });
        }
        this.selectedPatientGroup.linkPatientPatientGroup = this.selectedLinkPatientsGroup;
        this.getCheckedPatientIds();
        this.initialCheckedPatientIdsOfSelectedGroup = this.checkedPatientIds;
        this.includePatientIds = this.selectedPatientGroup.linkPatientPatientGroup
          .filter(link => link.type === 0).map(link => link.patientId);
        this.excludePatientIds = this.selectedPatientGroup.linkPatientPatientGroup
          .filter(link => link.type === 1).map(link => link.patientId);
        this.cdr.markForCheck();
      })
    );
  }

  //#endregion

  //#region Methods

  public ngOnInit(): void {
    const loadPatientGroupSubscription = this.hookPatientGroupAsync().subscribe();
    this._subscription.add(loadPatientGroupSubscription);

    const loadMessageTemplateSubscription = this.hookMessageTemplateAsync().subscribe();
    this._subscription.add(loadMessageTemplateSubscription);

    const searchActiveIngredientSubscription = this.hookSearchIngredientAsync().subscribe();
    this._subscription.add(searchActiveIngredientSubscription);

    const loadSelectedLinkPatientsGroup = this.hookLinkPatientGroupAsync().subscribe();
    this._subscription.add(loadSelectedLinkPatientsGroup);

    const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
    if (!accountSetting || !accountSetting.id) {
      return;
    }

    this.pharmacyId = accountSetting.id;
    this.messageTemplate$.next();
    this.patientGroup$.next();
    const loadAllPatientsSubscription = this.apiService.retrievesAllActivePatientsAsync(this.pharmacyId).pipe(
      tap(patients => {
        this.allPatients = patients;
        this.checkedPatientIds = this.allPatients.map(mixed => mixed.mixedPatientModel.id);
        this.cdr.markForCheck();
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ).subscribe(_ => this.isLoading = false);
    this._subscription.add(loadAllPatientsSubscription);
  }

  public ngAfterViewInit(): void {
    // Count message body character base on displaying character, not base on HTML content
    const countMessageBodyCharacterSubscription = this.editorChange$.pipe(
      delay(0),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap(_ => {
        if (!this.richTextEditor || !this.richTextEditor.nativeElement) {
          this.messageBodyCount = 0;
          return;
        }
        const editorContentElement = this.richTextEditor.nativeElement.querySelector('.ck-editor__editable') as HTMLElement;
        if (!editorContentElement) {
          this.messageBodyCount = 0;
          return;
        }
        const textContentEditor = editorContentElement.textContent;
        // Remove Word Joiner (&#8288; or &NoBreak;) before counting character to get correct number of characters
        const textContentEditorFormatted = textContentEditor.replace(new RegExp(String.fromCharCode(WORD_JOINER_CHAR_CODE), 'g'), '');
        this.messageBodyCount = textContentEditorFormatted.length;
        this.cdr.markForCheck();
      })
    ).subscribe();
    this._subscription.add(countMessageBodyCharacterSubscription);
  }

  public getPatientBaseOnGroupCriteria(): PatientActiveIngredientsModel[] {
    const genderOptions = [];
    if (this.genderCriteriaControl.value) {
      this.genderOptions.forEach((genderOption, index) => {
        const genderValues = this.patientGroupGenderArray.value;
        if (genderValues[index]) {
          genderOptions.push(genderOption.key);
          return;
        }
      });
    }
    const filterPatientInGroup = this.allPatients.filter(mixedModel => {
      const conditions: boolean[] = [];
      const patientAge = moment.utc().diff(moment(mixedModel.mixedPatientModel.dateOfBirth), 'year');
      if (this.genderCriteriaControl.value && genderOptions.length) {
        let isMatchGender = genderOptions.includes(mixedModel.mixedPatientModel.gender);
        if (!isMatchGender) {
          isMatchGender = genderOptions.includes('O') &&
            mixedModel.mixedPatientModel.gender !== 'F' &&
            mixedModel.mixedPatientModel.gender !== 'M';
        }
        conditions.push(isMatchGender);
      }

      if (this.ageCriteriaControl.value) {
        const fromAge = this.patientGroupAgeFromControl.value || 0;
        const toAge = this.patientGroupAgeToControl.value;
        let isMatchAgeRange = patientAge >= Number(fromAge);
        if (toAge) {
          isMatchAgeRange = isMatchAgeRange && patientAge <= Number(toAge);
        }
        conditions.push(isMatchAgeRange);
      }

      if (this.ingredientCriteriaControl.value && this.activeIngredients && this.activeIngredients.length) {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const isMatchIngredients = this.activeIngredients.some((ingredient: any) => mixedModel.activeIngredients.includes(ingredient));
        conditions.push(isMatchIngredients);
      }

      return conditions.reduce((acc, condition) => acc && condition, true);
    });

    return filterPatientInGroup;
  }

  public handlePatientGroupCriteriaChange(): void {
    this.checkedPatientIdsAfterManualEdit = null;
  }

  //#region Patient Group Methods
  public buildPatientGroupSectionForm(): void {
    this.ingredientSearchControl = new UntypedFormControl(null, [Validators.minLength(3)]);
    this.ingredientSearchForm = new UntypedFormGroup({
      ingredient: this.ingredientSearchControl
    });

    this.patientGroupIdControl = new UntypedFormControl('');
    this.patientGroupNameControl = new UntypedFormControl('', [Validators.required]);
    this.patientGroupGenderArray = new UntypedFormArray([
      new UntypedFormControl(false),
      new UntypedFormControl(false),
      new UntypedFormControl(false)
    ]);
    this.patientGroupAgeFromControl = new UntypedFormControl(null);
    this.patientGroupAgeToControl = new UntypedFormControl(null);
    this.patientGroupCriteriaForm = new UntypedFormGroup({
      groupGenderCriteria: this.genderCriteriaControl,
      groupAgeCriteria: this.ageCriteriaControl,
      groupIngredientCriteria: this.ingredientCriteriaControl,
      groupGender: this.patientGroupGenderArray,
      groupAgeFrom: this.patientGroupAgeFromControl,
      groupAgeTo: this.patientGroupAgeToControl
    }, {
      validators: this.validateAgeRange('groupAgeFrom', 'groupAgeTo')
    });

    this.patientGroupForm = new UntypedFormGroup({
      groupName: this.patientGroupNameControl,
      groupCriteria: this.patientGroupCriteriaForm
    });
  }

  public validateAgeRange(fromAge: string, toAge: string): ValidatorFn {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      if (!this.ageCriteriaControl.value) {
        return null;
      }
      const formControls = form.controls;

      if (!formControls || !formControls[fromAge] || !formControls[toAge]) {
        return null;
      }
      const ageFromControlValue = formControls[fromAge].value || 0;
      const ageToControlValue = formControls[toAge].value;

      if (ageToControlValue != null && Number(ageFromControlValue) >= Number(ageToControlValue)) {
        return {invalidAgeRange: true};
      }
      return null;
    };
  }

  public handleAgeFocusOut(ageControl: string): void {
    if (ageControl === 'from') {
      const ageFrom = this.patientGroupAgeFromControl.value;
      if (ageFrom < 0) {
        this.patientGroupAgeFromControl.patchValue(null);
      }
      if (ageFrom > 200) {
        this.patientGroupAgeFromControl.patchValue(200);
      }
      return;
    }
    const ageTo = this.patientGroupAgeToControl.value;
    if (ageTo > 200) {
      this.patientGroupAgeToControl.patchValue(200);
    }
  }

  public displayBroadcastPatientDialog(): void {
    const config = new DialogConfig();
    config.width = '70%';
    config.height = '80%';
    config.panelClass = ['broadcast-patient-edit'];
    const checkedPatientIds = this.getCheckedPatientIds();
    const broadcastDialogData = {
      allPatients: this.allPatients,
      selectedPatientGroup: this.selectedPatientGroup,
      checkedPatientIds: this.checkedPatientIdsAfterManualEdit ? this.checkedPatientIdsAfterManualEdit : checkedPatientIds,
      patientMatchCriteriaIds: this.patientMatchCriteriaIds,
      includePatientIds: this.includePatientIds,
      excludePatientIds: this.excludePatientIds
    };

    this.dialogService.openBroadcastPatientDialog(BroadcastPatientDialogComponent, config, broadcastDialogData)
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .then((data: {[key: string]: any}) => {
        if (!data) {
          return;
        }
        if (data.checkedPatientIds) {
          this.checkedPatientIdsAfterManualEdit = data.checkedPatientIds;
        }
        if (data.includePatientIds) {
          this.includePatientIds = data.includePatientIds;
        }
        if (data.excludePatientIds) {
          this.excludePatientIds = data.excludePatientIds;
        }
        this.cdr.markForCheck();
      });
  }

  private getCheckedPatientIds(): number[] {
    const patientMatchCriteriaIds = this.getPatientBaseOnGroupCriteria().map(item => item.mixedPatientModel.id);
    this.patientMatchCriteriaIds = patientMatchCriteriaIds;
    let includePatientIds = [];
    let excludePatientIds = [];
    if (this.selectedPatientGroup && this.selectedPatientGroup.linkPatientPatientGroup) {
      includePatientIds = this.selectedPatientGroup.linkPatientPatientGroup.filter(link => link.type === 0).map(link => link.patientId);
      excludePatientIds = this.selectedPatientGroup.linkPatientPatientGroup.filter(link => link.type === 1).map(link => link.patientId);
    }
    this.checkedPatientIds =  Array.from(new Set(patientMatchCriteriaIds.concat(includePatientIds)))
      .filter(id => !excludePatientIds.includes(id));
    return this.checkedPatientIds;
  }

  public selectPatientGroup(): void {
    const selectedPatientGroupId = this.patientGroupIdControl.value;
    const selectedPatientGroup = this.patientGroups.find(patientGroup => patientGroup.id === Number(selectedPatientGroupId));
    this.checkedPatientIdsAfterManualEdit = null;
    this.patientGroupForm.reset();
    this.genderCriteriaControl.patchValue('');
    this.ageCriteriaControl.patchValue('');
    this.ingredientCriteriaControl.patchValue('');
    this.includePatientIds = [];
    this.excludePatientIds = [];
    if (!selectedPatientGroup) {
      this.selectedPatientGroup = null;
      this.activeIngredients = [];
      this.activeIngredientsBeforeChanged = [];
      return;
    }
    this.linkPatientGroup$.next(Number(selectedPatientGroupId));

    const genderOptions = selectedPatientGroup.gender && selectedPatientGroup.gender.split(';') || [];
    const genderFormValues = this.genderOptions.map(genderOption => {
      return genderOptions.includes(genderOption.key);
    });
    if (genderFormValues.reduce((acc, value) => acc || value)) {
      this.genderCriteriaControl.patchValue(true);
    }

    if (selectedPatientGroup.maxAge != null) {
      this.ageCriteriaControl.patchValue(true);
    }

    this.patientGroupForm.patchValue({
      groupName: selectedPatientGroup.name,
    });
    this.patientGroupCriteriaForm.patchValue({
      groupGender: genderFormValues,
      groupAgeFrom: selectedPatientGroup.minAge,
      groupAgeTo: selectedPatientGroup.maxAge
    });

    if (selectedPatientGroup.medications) {
      this.activeIngredients = selectedPatientGroup.medications.split(';');
      this.activeIngredientsBeforeChanged = [...this.activeIngredients];
      this.ingredientCriteriaControl.patchValue(true);
    }
    this.selectedPatientGroup = selectedPatientGroup;
  }

  public searchActiveIngredient(): void {
    const key = this.ingredientSearchControl.value;
    if (!key || key.length < 3) {
      this.activeIngredientsAutocomplete = [];
      return;
    }
    this.searchActiveIngredient$.next(key);
  }

  public selectSuggestion(option: string): void {
    this.ingredientSearchControl.patchValue(option);
    this.shouldDisplayActiveIngredientAutocomplete = false;
  }

  public addActiveIngredient(): void {
    const newActiveIngredient = this.ingredientSearchControl.value;
    if (!newActiveIngredient || this.activeIngredients.includes(newActiveIngredient)) {
      return;
    }

    if (!this.activeIngredientsAutocomplete.includes(newActiveIngredient)) {
      return;
    }

    this.ingredientSearchControl.patchValue('');
    this.activeIngredientsAutocomplete = [];
    if (this.activeIngredients.includes(newActiveIngredient)) {
      return;
    }
    this.activeIngredients.push(newActiveIngredient);
    this.handlePatientGroupCriteriaChange();
  }

  public deleteActiveIngredient(selectElement: HTMLSelectElement): void {
    const selectedActiveIngredient = selectElement.value;
    const selectedActiveIngredientIndex = this.activeIngredients.indexOf(selectedActiveIngredient);
    this.activeIngredients.splice(selectedActiveIngredientIndex, 1);
    this.handlePatientGroupCriteriaChange();
  }

  public savePatientGroup(): void {
    if (this.isDefaultPatientGroup) {
      return;
    }
    this.patientGroupForm.markAllAsTouched();
    this.patientGroupForm.updateValueAndValidity();
    let shouldAllowSavePatientGroup = true;

    if (this.ingredientCriteriaControl.value && !this.activeIngredients.length) {
      this.ingredientSearchForm.setErrors({missingIngredient: true});
      shouldAllowSavePatientGroup = false;
    }

    if (this.ageCriteriaControl.value && this.patientGroupAgeFromControl.value == null && this.patientGroupAgeToControl.value == null) {
      this.patientGroupAgeFromControl.setErrors({missingAge: true});
      this.patientGroupAgeToControl.setErrors({missingAge: true});
      shouldAllowSavePatientGroup = false;
    } else {
      this.patientGroupAgeFromControl.setErrors(null);
      this.patientGroupAgeToControl.setErrors(null);
    }
    const genderOptions = [];
    this.genderOptions.forEach((genderOption, index) => {
      const genderValues = this.patientGroupGenderArray.value;
      if (genderValues[index]) {
        genderOptions.push(genderOption.key);
      }
    });
    if (this.genderCriteriaControl.value && !genderOptions.length) {
      this.patientGroupGenderArray.setErrors({noSelectedGender: true});
      shouldAllowSavePatientGroup = false;
    } else {
      this.patientGroupGenderArray.setErrors(null);
    }

    this.patientGroupForm.updateValueAndValidity();
    if (this.patientGroupForm.invalid) {
      shouldAllowSavePatientGroup = false;
    }

    if (!shouldAllowSavePatientGroup) {
      return;
    }
    const patientGroupRequestBody = new PatientGroupModel();
    patientGroupRequestBody.pharmacyId = this.pharmacyId;
    patientGroupRequestBody.name = this.patientGroupNameControl.value;
    patientGroupRequestBody.id = 0;
    patientGroupRequestBody.minAge = null;
    patientGroupRequestBody.maxAge = null;
    patientGroupRequestBody.medications = null;
    patientGroupRequestBody.gender = null;
    if (this.patientGroupIdControl.value) {
      patientGroupRequestBody.id = Number(this.patientGroupIdControl.value);
    }
    if (this.ageCriteriaControl.value) {
      patientGroupRequestBody.maxAge = this.patientGroupAgeToControl.value;
      patientGroupRequestBody.minAge = this.patientGroupAgeFromControl.value;
    }
    if (this.ingredientCriteriaControl.value && this.activeIngredients.length) {
      patientGroupRequestBody.medications = this.activeIngredients.join(';');
    }
    if (this.genderCriteriaControl.value) {
      patientGroupRequestBody.gender = genderOptions.length ?  genderOptions.join(';') : null;
    }
    const savePatientGroupSubscription = this.apiService.createOrUpdatePatientGroupAsync(patientGroupRequestBody).pipe(
      tap(response => {
        if (response.result === OperationResultEnum.FAILED) {
          this.patientGroupNameControl.setErrors({uniqueName: response.resultMessage});
          this.cdr.markForCheck();
          return;
        }
        const patientGroup = response.data;
        this.patientGroupNameControl.markAsPristine();
        this.patientGroupCriteriaForm.markAsPristine();
        this.activeIngredientsBeforeChanged = [...this.activeIngredients];
        if (this.patientGroupIdControl.value) {
          this.updateLinkPatientGroup(this.selectedPatientGroup, this.checkedPatientIdsAfterManualEdit);
          this.patientGroup$.next();
          return;
        }
        patientGroupRequestBody.id = Number(patientGroup.id);
        this.updateLinkPatientGroup(patientGroupRequestBody, this.checkedPatientIdsAfterManualEdit);
        this.patientGroups.push(patientGroupRequestBody);
        this.patientGroups.sort((firstGroup, secondGroup) => {
          return firstGroup.name.localeCompare(secondGroup.name);
        });
        this.patientGroupIdControl.patchValue(patientGroup.id);
        this.selectPatientGroup();
        this.cdr.markForCheck();
      })
    ).subscribe();
    this._subscription.add(savePatientGroupSubscription);
  }

  public deletePatientGroup(): void {
    const patientGroupId = this.patientGroupIdControl.value;
    if (!patientGroupId || this.isDefaultPatientGroup) {
      return;
    }
    this.dialogService.openConfirmationDialog(
      ['Are you sure you want to delete this patient group?'],
      'OK', 'Cancel').then(() => {
      const deletePatientGroupSubscription = this.apiService.deletePatientGroupAsync(this.pharmacyId, patientGroupId)
        .pipe(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          tap(_ => {
            const deletedPatientGroupIndex = this.patientGroups.findIndex(patientGroup => patientGroup.id === Number(patientGroupId));
            this.patientGroups.splice(deletedPatientGroupIndex, 1);
            this.patientGroupForm.reset();
            this.checkedPatientIdsAfterManualEdit = null;
            this.patientGroupIdControl.patchValue('');
            this.selectPatientGroup();
            this.cdr.markForCheck();
          })
        ).subscribe();
      this._subscription.add(deletePatientGroupSubscription);
    });
  }

  public updateLinkPatientGroup(patientGroup: PatientGroupModel, newCheckedPatientIds: number[]): void {
    let addedPatientIds = [];
    let removedPatientIds = [];

    if (newCheckedPatientIds) {
      const checkedPatientIds = this.getCheckedPatientIds();
      addedPatientIds = newCheckedPatientIds.filter(id => !checkedPatientIds.includes(id));
      removedPatientIds = checkedPatientIds.filter(id => !newCheckedPatientIds.includes(id));
    }
    if (!addedPatientIds.length && !removedPatientIds.length) {
      return;
    }
    const linkPatientRequestBody = new PatientFromGroupRequestModel();
    linkPatientRequestBody.patientGroupId = patientGroup.id;
    linkPatientRequestBody.includedPatientIds = addedPatientIds;
    linkPatientRequestBody.excludedPatientIds = removedPatientIds;
    this.isSubmittingPatientGroup = true;
    const updateLinkPatientGroupSubscription = this.apiService
      .includeOrExcludePatientFromPatientGroupAsync(linkPatientRequestBody).pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError(_ => {
          this.isSubmittingPatientGroup = false;
          return throwError(_);
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ).subscribe(_ => {
        this.linkPatientGroup$.next(patientGroup.id);
        this.checkedPatientIdsAfterManualEdit = null;
        this.isSubmittingPatientGroup = false;
      });
    this._subscription.add(updateLinkPatientGroupSubscription);
  }

  //#endregion

  //#region Message Template Methods
  public buildMessageTemplateSectionForm(): void {
    this.templateIdControl = new UntypedFormControl('');
    this.templateNameControl = new UntypedFormControl('', [Validators.required]);
    this.templateSubjectControl = new UntypedFormControl('', [Validators.required]);
    this.templateBodyControl = new UntypedFormControl('', [Validators.required]);

    this.messageTemplateForm = new UntypedFormGroup({
      templateName: this.templateNameControl,
      templateSubject: this.templateSubjectControl,
      templateBody: this.templateBodyControl
    });
  }

  public selectTemplateHelpTag(event: MouseEvent): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const element = event.target as HTMLElement;
  }

  public selectMessageTemplate(): void {
    const selectedTemplateId = this.templateIdControl.value;
    const selectedTemplate = this.messageTemplates.find(template => template.id === Number(selectedTemplateId));
    this.selectedMessageTemplate = selectedTemplate;
    this.messageTemplateForm.reset();
    if (!selectedTemplate) {
      this.selectedMessageTemplate = null;
      return;
    }
    let selectedTemplateBody = selectedTemplate.body;
    // Handle for previous body new line using /n and <br/> change to <p></p> in rich text editor
    
  // eslint-disable-next-line no-control-regex
    selectedTemplateBody = selectedTemplateBody.replace(new RegExp('\n', 'g'), '<p></p>');
    selectedTemplateBody = selectedTemplateBody.replace(new RegExp('<br/>', 'g'), '<p></p>');

    // Handle change help tag to HTML entities to display correctly in rich text editor
    for (const helpTag of this.helpTags) {
      selectedTemplateBody = selectedTemplateBody.replace(new RegExp(helpTag.title, 'g'), helpTag.escapeTitle);
    }

    this.messageTemplateForm.patchValue({
      templateName: selectedTemplate.name,
      templateSubject: selectedTemplate.subject,
      templateBody: selectedTemplateBody,
    });
  }

  public focusoutEditor(): void {
    if (this.templateBodyControl.pristine) {
      this.templateBodyControl.markAsTouched();
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  public handleEditorChange(event: any): void {
    this.editorChange$.next();
  }

  public saveMessageTemplate(): void {
    this.messageTemplateForm.markAllAsTouched();
    this.messageTemplateForm.updateValueAndValidity();

    if (this.messageTemplateForm.invalid) {
      return;
    }

    const templateMessageBody = new TemplateBroadcastModel();
    templateMessageBody.id = 0;
    templateMessageBody.pharmacyId = this.pharmacyId;
    templateMessageBody.code = this.templateNameControl.value;
    if (this.templateIdControl.value) {
      templateMessageBody.id = Number(this.templateIdControl.value);
      templateMessageBody.code = this.selectedMessageTemplate.code;
    }
    templateMessageBody.name = this.templateNameControl.value;
    templateMessageBody.subject = this.templateSubjectControl.value;

    let templateBody = this.templateBodyControl.value;
    // Handle change help tag HTML entities to normal help tag before save to server
    for (const helpTag of this.helpTags) {
      templateBody = templateBody.replace(new RegExp(helpTag.escapeTitle, 'g'), helpTag.title);
    }
    templateMessageBody.body = templateBody;
    const savePatientGroupSubscription = this.apiService.createOrUpdateBroadcastTemplateAsync(templateMessageBody)
      .subscribe(response => {
        if (response.result === OperationResultEnum.FAILED) {
          this.templateNameControl.setErrors({uniqueName: response.resultMessage});
          this.cdr.markForCheck();
          return;
        }
        this.messageTemplateForm.markAsPristine();
        if (this.templateIdControl.value) {
          this.messageTemplate$.next();
          return;
        }
        const template = response.data;
        templateMessageBody.id = Number(template.id);
        this.messageTemplates.push(templateMessageBody);
        this.messageTemplates.sort((firstTemplate, secondTemplate) => {
          return firstTemplate.name.localeCompare(secondTemplate.name);
        });
        this.templateIdControl.patchValue(template.id);
        this.selectMessageTemplate();
        this.cdr.markForCheck();
      });
    this._subscription.add(savePatientGroupSubscription);
  }

  public deleteMessageTemplate(): void {
    const selectedTemplateId = this.templateIdControl.value;
    if (!selectedTemplateId || !this.selectedMessageTemplate) {
      return;
    }
    this.dialogService.openConfirmationDialog(
      ['Are you sure you want to delete this template?'],
      'OK', 'Cancel').then(() => {
      const deleteTemplateSubscription = this.apiService.deleteMessageTemplateAsync(this.pharmacyId, selectedTemplateId)
        .pipe(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          tap(_ => {
            const deletedMessageTemplateIndex = this.messageTemplates.findIndex(template => template.id === Number(selectedTemplateId));
            this.messageTemplates.splice(deletedMessageTemplateIndex, 1);
            this.patientGroupIdControl.patchValue('');
            this.selectMessageTemplate();
            this.cdr.markForCheck();
          })
        ).subscribe();
      this._subscription.add(deleteTemplateSubscription);
    });
  }

  //#endregion

  public sendBroadcastMessage(): void {
    if (!this.isSendBroadcastAvailable) {
      return;
    }
    const broadcastModel = new BroadcastModel();
    broadcastModel.pharmacyId = this.pharmacyId;
    broadcastModel.broadcastTemplateId = this.selectedMessageTemplate.id;
    broadcastModel.patientGroupId = this.selectedPatientGroup.id;
    const sendBroadcastMessageSubscription = this.apiService.sendBroadcastMessageAsync(broadcastModel)
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        mergeMap(_ => {
          const patientCount = this.getCheckedPatientIds().length;
          const bodyText = `Announcement sent to ${patientCount} ${patientCount === 1 ? 'patient' : 'patients'} successfully`;
          return from(this.dialogService.openInformationDialog([bodyText], 'OK'));
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe(_ => {
        this.patientGroupForm.reset();
        this.messageTemplateForm.reset();
        this.patientGroupIdControl.patchValue('');
        this.templateIdControl.patchValue('');
        this.selectPatientGroup();
        this.selectMessageTemplate();
        this.cdr.markForCheck();
    });
    this._subscription.add(sendBroadcastMessageSubscription);
  }

  public ngOnDestroy(): void {
    if (this._subscription && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }

  //#endregion
}
