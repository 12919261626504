import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Subscriber, Observable } from 'rxjs';
import { IAPIService } from './api/iapi.service';
import { PharmacyModel } from './api/ApiRecordTypes/PharmacyRecord';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const merge = require('lodash.merge');
export enum DisplayMode {
  Default,
  Prescriber
}

export class Localization {
  public CustomerText = 'Patient';
  public CustomerTextPlural = 'Patients';
  public HomeTitleName = 'Name, phone and GP';
  constructor(
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    private config: any
  ) {
    if (this.config && this.config.features && this.config.features.ShowCustomerText) {
      this.CustomerText = 'Customer';
      this.CustomerTextPlural = 'Customers';
    }
    if (this.config && this.config.features && this.config.features.ShowSurgeries) {
      this.HomeTitleName = 'Name';
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig;
  private displayMode: DisplayMode = DisplayMode.Default;
  private displayModeSubscribers: Subscriber<DisplayMode>[] = [];

  private currentState: CurrentState;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public features: any;
  public localization: Localization;

  private pharmacy: PharmacyModel = null;

  public get CurrentState() {
    if (this.currentState != null) {
        return this.currentState;
    }
  }

  public set CurrentState(state: CurrentState) {
    this.currentState = state;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  constructor(private http: HttpClient, private api: IAPIService, @Inject('SESSIONSTORAGE') private sessionStorage: any) {
  }

  loadAppConfig() {
    const timeStamp = +new Date();
    const ecosystem = environment.name.split('-')[0];
    const env = environment.name.split('-')[1];
    return this.http.get('/assets/config/' + ecosystem + '.config.json?' + timeStamp)
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
     .toPromise<any>()
     .then(data => {
        const generalConfig = data.general;
        const specificConfig = data[env];
        this.appConfig = merge(generalConfig, specificConfig);
        this.localization = new Localization(this.appConfig);

      });
  }

  loadCurrentPharmacySettings(): Observable<PharmacyModel> {
    return new Observable<PharmacyModel>((observer) =>  {
      if (this.pharmacy === null) {
        const accountSetting = JSON.parse(this.sessionStorage.getItem('accountSettings'));
        if (accountSetting && accountSetting.role === 1) {
          this.api.getPharmacySettings(accountSetting.id).subscribe(result => {
            this.pharmacy = result;
            observer.next(this.pharmacy);
            observer.complete();
          });
        }
      } else {
        observer.next(this.pharmacy);
        observer.complete();
      }
    });
  }

  getDisplayMode(): DisplayMode {
    return this.displayMode;
  }

  watchDisplayModeChanges(): Observable<DisplayMode> {
    return new Observable<DisplayMode>((observer) =>  {
      this.displayModeSubscribers.push(observer);
    });
  }

  setDisplayMode(mode: DisplayMode) {
    this.displayMode = mode;
    this.displayModeSubscribers.forEach(sub => {
      sub.next(this.displayMode);
    });
  }

  getConfig() {
    return this.appConfig;
  }
}

export class CurrentState {
}
