<div class="drug-item" *ngIf="!hide(editmode$ | async)">
  <div
    *ngIf="(editmode$ | async) === false && item && item.maDrugId > 0"
    class="viewdrug"
  >
    <div class="buttons-after">
      <div class="drug-order">
        <div class="drug-name">
          <strong (click)="edit()">{{ item?._drugtext }}</strong>
        </div>
        <section>
          <mat-checkbox *ngIf='config.features.EditPatientPage.AllowPharmacyOrdering' color="primary" (change)="selectDrug()" [(ngModel)]="item.selectedForOrder" >Add to order</mat-checkbox>
        </section>
      </div>
      <div class="rowaction-set">
        <a class="rowaction-text" (click)="edit()">Edit</a
        ><a
          class="rowaction-text"
          [ngClass]="{ disabled: isIM1Patient }"
          (click)="delete()"
          >Delete</a
        >
      </div>
    </div>
    <div
      class="drug-edit-line drug-qty"
      [ngClass]="{ withSupply: editDaysSupply }"
    >
      <div class="drug-edit-col-1-row-1 drug-edit-text">
        <label>Ordering cycle</label>
        <span *ngIf="item?.repeatCycleFrequency === 'days'">{{
          item?.repeatCycle
        }}</span
        >&nbsp;<span>{{ item?.repeatCycleFrequency }}</span>
      </div>
      <div class="drug-edit-col-2-row-1">
        <label class="drug-edit-text">eRD</label>
        <mat-icon class="erd-icon" *ngIf="item.erd">check</mat-icon>
        <mat-icon class="erd-icon" *ngIf="!item.erd">close</mat-icon>
      </div>
      <div class="drug-edit-col-3-row-1 drug-edit-text">
        <label>Rpts left</label> <span>{{ getRptsLeft() }}</span>
      </div>
      <div *ngIf="editDaysSupply" class="drug-edit-col-4-row-1 drug-edit-text">
        <label>Supply left</label
        ><span> {{ displaySupplyLeft(item?.quantity) }}</span>
      </div>
    </div>
    <div class="drug-des drug-edit-line">
      <div class="drug-edit-col-1-row-2">
        <label>Added by&nbsp;</label>{{ sourcetoText(item?.source) }}
      </div>
      <div class="drug-edit-col-wide" colspan="3">
        <label>Added:&nbsp;</label>{{ formatDate(item?.startDate) }}
      </div>
    </div>
  </div>
  <div *ngIf="editmode$ | async" class="editdrug">
    <div class="search-all-content" (keyup.esc)="doCancelSearchDrugs()">
      <input
        class="search-text"
        [disabled]="isIM1Patient"
        type="text"
        (blur)="validateSearchText()"
        [(ngModel)]="searchText"
        (keyup)="checkLength(3)"
        (keyup.enter)="doSearchDrugs()"
      /><mat-icon class="searchicon" (click)="doSearchDrugs()" tabindex="0"
        >search</mat-icon
      >
      <div class="error-message" *ngIf="!isValidSearchText">
        Please enter at least three letters of the medication name.
      </div>
    </div>

    <div class="drug-dialog-content" *ngIf="dataSource">
      <div class="drug-content">
        <cdk-virtual-scroll-viewport
          class="drug-list-viewport"
          *ngIf="!dataSource?.isEmpty()"
          min-row-height="37"
          [itemSize]="37"
        >
          <div
            class="drug-option-list"
            *cdkVirtualFor="let item of dataSource"
            (click)="doSearchResultItemSelected(item)"
          >
            <div [ngClass]="{ cell: true, 'no-brand': item.labelName === '' }">
              {{
                item?.labelName === ""
                  ? item?.activeName + " " + item?.form + "/" + item?.strength
                  : item?.labelName
              }}
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
      <ng-container *ngIf="dataSource?.isLoading$ | async">
        <app-load-spinner [diameter]="32"></app-load-spinner>
      </ng-container>
    </div>

    <div
      class="drug-edit-line drug-qty"
      [ngClass]="{ withSupply: editDaysSupply }"
    >
      <div class="drug-edit-col-1-row-1">
        <label>Ordering cycle</label>
        <span *ngIf="edititem.repeatCycleFrequency === 'as required'"
          ><a (click)="edititem.repeatCycleFrequency = 'days'; doChange()"
            >As required</a
          ></span
        >
        <span *ngIf="edititem.repeatCycleFrequency === 'days'"
          ><input
            class="tiny-input"
            type="number"
            (keypress)="limitLength($event)"
            maxlength="4"
            max="9999"
            min="0"
            [(ngModel)]="edititem.repeatCycle"
            on-change="doChange()"
          /><a
            (click)="edititem.repeatCycleFrequency = 'as required'; doChange()"
            >days</a
          ></span
        >
      </div>
      <div class="drug-edit-col-2-row-1">
        <label>eRD</label>
        <mat-checkbox
          color="primary"
          [(ngModel)]="edititem.erd"
          on-change="doChange()"
        ></mat-checkbox>
      </div>
      <div class="drug-edit-col-3-row-1">
        <label>Rpts left</label><span [hidden]="edititem.erd">N/A</span
        ><input
          [hidden]="!edititem.erd"
          class="tiny-input"
          type="number"
          (keypress)="limitLength($event)"
          maxlength="4"
          max="9999"
          min="0"
          [(ngModel)]="edititem.repeatLeft"
          on-change="doChange()"
        />
      </div>
      <div *ngIf="editDaysSupply" class="drug-edit-col-4-row-1">
        <label>Supply left</label>
        <input
          class="tiny-input"
          type="number"
          (keypress)="limitLength($event)"
          maxlength="4"
          max="9999"
          min="0"
          [(ngModel)]="edititem.quantity"
          on-change="doChange()"
        />days
      </div>
    </div>
    <div class="drug-des drug-edit-line">
      <div class="drug-edit-col-1-row-2">
        <label>Added by&nbsp;</label>{{ sourcetoText(item?.source) }}
      </div>
      <div class="drug-edit-col-wide" colspan="3">
        <label>Added:&nbsp;</label>{{ formatDate(item?.startDate) }}
      </div>
    </div>
    <div class="drug-button-set-container">
      <div class="button-set drug-button-set">
        <button
          class="secondary-button local-button"
          [disabled]="!(isChanged && isValidEditItem)"
          (click)="saveUpdate()"
        >
          <mat-icon class="prefix">check_circle</mat-icon>Confirm
        </button>
        <button class="secondary-button local-button" (click)="cancelUpdate()">
          <mat-icon class="prefix">cancel</mat-icon>Undo
        </button>
      </div>
    </div>
  </div>
</div>
