import { Injectable } from '@angular/core';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class DataService {

  data: number;
  actions: string;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
  getCurrentPharmacyId(): number {
    return this.data;
  }
  setCurrentPharmacyId(id: number) {
      this.data = id;
  }
  getActions(): string {
    return this.actions;
  }
  setAction(action: string) {
    this.actions = action;
  }
}
