
import { MatPaginatorIntl} from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomPaginationService extends MatPaginatorIntl {
  //#region Constructor
  constructor () {
    super();
    this.getAndInitTranslations();
  }
  //#endregion

  //#region Methods
  public getAndInitTranslations () {
    this.itemsPerPageLabel = '';
    this.nextPageLabel = '';
    this.previousPageLabel = '';
    this.itemsPerPageLabel = 'Item per page';
    this.changes.next();
  }

  public getRangeLabel = (page: number, pageSize: number, length: number) => {
    return `Page ${page + 1} of ${Math.ceil(length / pageSize)}`;
  }
  //#endregion
}
