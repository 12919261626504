export class BookingAvailabilityModel {
  public id: number;
  public type: number;
  public bookingTarget: number;
  public bookingTargetUrl: string;
  public name: string;
  public longName: string;
  public friendlyName: string;
  public blurb: string;
  public iconImage: string;
  public bannerImage: string;
  public suggestionPriority: number;
  public resourceAvailable: boolean;
  public standardDuration: number;
  public prescreenRequirement: number;
  public prescreenView: string;
  public ppsEngine: string;
}

export class EventBookingExceptionModel {
  public id: number;

  public startDate: string;

  public endDate: string;
}


export class EventBookingModel {
  public canBeCancelled: boolean;

  public includeResourceAvailability: boolean;

  public id: number;

  public startDate: string;

  public endDate: string;

  public source: number;

  public title: string;

  public firstName: string;

  public lastName: string;

  public resourceId: number;

  public resourceIds: string;

  public code: string;

  public mobile: string;

  public preferences: string;

  public resourceName: string;

  public type: string;

  public bookingRef: string;

  public isNew: boolean;

  public isDeleted: boolean;

  public capabilityId: number;

  public capabilityName: string;

  public capabilityNameOriginal: string;

  public color: string;

  public notes: string;

  public initials: string;

  public isGuest: boolean;

  public patientId: number;

  public phone: string;

  public email: string;

  public dateOfBirth: string;

  public address: string;

  public street: string;

  public suburb: string;

  public state: string;

  public postCode: string;

  public duration: number;

  public shouldSendConfirmation: boolean;

  public shouldSendReminder: boolean;

  public reminderPriorHours: string;

  public createDate: string;

  public calendarTokenId: string;

  public availableResourceIds: string;

  public pharmacyName: string;

  public pharmacyId: number;

  public pharmacyTimeZone: string;

  prescreenCompleted: string;

  public prescreenData: string;

  public prescreenURL: string;

  public prescreenView: string;

  public calendarToken: string;

  public gCaptchaResponse: string;

  public currentTimezone: number;

}

export class EventRecurrenceModel {
  public isNew: boolean;
  public id: number;
  public recurrenceType: number;
  public months: number[];
  public weekdays: number[];
  public dates: number[];
  public startTime: string;
  public endTime: string;
  public startDate: string;
  public endDate: string;
  public recurEvery: number;
  public endAfterOccurences: number;
  public recurrenceEndType: number;
  public dayOfMonthInYearlyRecurrence: number;
  public useEndTime: boolean;
}

export class EventResourceModel {
  //#region Properties

  availabilityType: number;
  availableServices: BookingAvailabilityModel[];
  calendarContext: number;
  color: string;
  id: number;
  isDeleted: boolean;
  pharmacyId: number;
  preferences: string;
  recurrencePattern: EventRecurrenceModel[];
  availibilityExceptions: EventBookingExceptionModel[];
  supplemental: string;
  title: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
  //#endregion

}
