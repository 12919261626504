<div class="pageContent surgery-details-page">
    <form class="surgery-form-details">
        <div class="row surgery-item">
            <div class="form-group" enctype="multipart/form-data">
                <label for="surgeryname">GP Surgery name</label>
                <div [hidden]=true>{{ (current$ | async).practice.name }}</div>
                <div class="input-wrapper">
                    <input [(ngModel)]="current.practice && current.practice.name" [disabled]="true" [readonly]="true"
                        type="text" name="name" id="name" class="form-input" placeholder="" />
                    <!-- <label  *ngIf="validation.firstnameValidation" for="firstname" class="validation-messages">{{validation.firstnameValidation}}</label> -->
                    <!-- <button (click)="deleteSurgery()" class="btn btn-primary button-delete">Delete Surgery</button> -->
                </div>
            </div>

        </div>
        <div class="container-readonly">
            <div class="form-group" enctype="multipart/form-data">
                <label for="odscode">ODS code</label>
                <div class="input-wrapper">
                    <input [(ngModel)]="current.practice && current.practice.systemCode" [disabled]="true"
                        [readonly]="true" type="text" name="odscode" id="odscode" class="form-input input-readonly"
                        placeholder="" />
                </div>
            </div>
            <div class="form-group" enctype="multipart/form-data">
                <label for="im1provider">IM1 software provider</label>
                <div class="input-wrapper">
                    <input [(ngModel)]="current.practice && current.practice.preferences.IM1_SoftwareProviderPreference"
                        [disabled]="true" [readonly]="true" type="text" name="im1provider" id="im1provider"
                        class="form-input input-readonly" placeholder="None" />
                </div>
            </div>
        </div>
        <div class="form-group" enctype="multipart/form-data">
            <label for="phone">Contact phone number</label>
            <div class="input-wrapper">
                <input [(ngModel)]="current.practice && current.practice.phone" [disabled]="true" [readonly]="true"
                    type="text" name="phone" id="phone" class="form-input input-readonly" placeholder="Not provided" />
                <!-- <label  *ngIf="validation.firstnameValidation" for="firstname" class="validation-messages">{{validation.firstnameValidation}}</label> -->
            </div>
        </div>
        <div class="form-group surgery-item" enctype="multipart/form-data">
            <label for="delivery">Preferred prescription delivery method</label>
            <div class="input-wrapper">
                <select [(ngModel)]="communicationsMethod" name="delivery" id="delivery"
                    (ngModelChange)="onEndpointChange($event)" class="form-input extrawide"
                    placeholder="<Please Select>">
                    <option value="Print">Manual (PDF)</option>
                    <option value="Email">Email</option>
                    <option value="Fax">Fax</option>
                </select>
                <!-- <label *ngIf="validation.roleValidation" for="firstname" class="validation-messages"><sup>&#9642;</sup>{{validation.roleValidation}}</label> -->
            </div>
        </div>
        <div *ngIf="communicationsMethod !== 'Print'" class="form-group" enctype="multipart/form-data">
            <label for="email">{{deliveryMethod()}}</label>
            <div class="input-wrapper">
                <input [(ngModel)]="communicationsTarget" type="text" name="email" (blur)="!formValid()" id="email"
                    [class]="isVaild ? 'form-input' :'form-input error '" placeholder="" />
            </div>
            <div *ngIf="!isVaild" for="firstname" class="validation-messages">{{errorMessage}}</div>
        </div>
        <div class="form-group " enctype="multipart/form-data">
            <label for="address">Address</label>
            <div class="input-wrapper">
                <input [ngModel]="address()" [disabled]="true" [readonly]="true" type="text" name="address" id="address"
                    class="form-input" placeholder="" />
                <!-- <label  *ngIf="validation.firstnameValidation" for="firstname" class="validation-messages">{{validation.firstnameValidation}}</label> -->
            </div>
        </div>
        <div enctype="multipart/form-data">

            <label class="time-title" for="time">How long before prescriptions are overdue?</label>
            <div class="input-wrapper overdue-timer">
                <input [(ngModel)]="current.preferences.hoursuntilscriptoverdue" type="number" step="any" name="time"
                    id="time" class="form-input input-overdue" placeholder="" />
                <div class="hour-des">Hours</div>
            </div>

        </div>
        <div class="form-group" enctype="multipart/form-data">
            <label for="notes">Notes (Optional)</label>
            <div class="input-wrapper">
                <input [(ngModel)]="current.preferences.notes" type="text" name="notes" id="notes" class="form-input"
                    placeholder="" />
                <!-- <label  *ngIf="validation.firstnameValidation" for="firstname" class="validation-messages">{{validation.firstnameValidation}}</label> -->
            </div>
        </div>
        <div class="form-group button-group" enctype="multipart/form-data">
            <button type="button"
            (click)="savePracticeDetails()"
            class="button-dialog btn-submit">
            <span>Save</span>
            </button>
            <button type="button"
            (click)="handleButton('cancel')"
            class="button-dialog btn-cancel-dialog">
            <span>Cancel</span>
            </button>
        </div>
    </form>
</div>