import { Component, OnInit, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

import { PendingTaskRecord } from '../../../api/iapi.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-msg-messagebookingdetail',
  templateUrl: './messagebookingdetail.component.html'
})
export class MessageBookingDetailComponent implements OnInit {
  @Input() data: PendingTaskRecord;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  taskData: any;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {
  }

  ngOnInit() {
    this.taskData = JSON.parse(this.data.jSonTaskData);
  }
}
