<div class='pageContent cdk-holder patient-list-container'>
    <div class="patient-active">
        <div class="patient-list">
            <div class="patient-main-grid-panel">
                <div class="gridrow headers patient-header withfilters">
                    <div class="header-field col1"> {{CustomerText}} name
                        <div class="filter-item">
                            <input appFilter="PatientName" type="text" name="patientName" id="patientName"
                                [(ngModel)]="patientFilter_patientName"
                                class="patient-search-input" placeholder="" />
                        </div>
                    </div>
                    <div *ngIf ="config?.features?.ShowSurgeries" class="header-field col2">NHS Number:
                        <div class="filter-item">
                            <input appFilter="NHSNumber" type="text" name="NHSNumber" id="NHSNumber"
                                [(ngModel)]="patientFilter_NHSNumber" (blur)="onNhsNumberBlur()" [validator]="nhsNumberValidator"
                                class="patient-search-input"  placeholder="xxx xxx xxxx" />
                            <label class="validation-error" >
                                <span *ngIf="(nhsNumberValidationMessage$ | async )?.value !== null">
                                    *{{ nhsNumberValidationMessage$ | async }}
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="header-field col3">Postcode
                        <div class="filter-item">
                            <input maxlength="8" appFilter="postcode" type="text" name="Postcode" id="Postcode"
                                class="patient-search-input" placeholder="XXXX XXX" />
                        </div>
                    </div>

                    <div class="header-field col4">DOB
                        <div class="filter-item">
                            <input appFilter="dateOfBirth" type="text" name="dateOfBirth" id="dateOfBirth"
                                [(ngModel)]="patientFilter_dateOfBirth" (blur)="onDateOfBirthBlur()" [validator]="dateOfBirthValidator"
                                class="patient-search-input" placeholder="dd/mm/yyyy" />
                            <label class="validation-error" >
                                <span *ngIf="(dateOfBirthValidationMessage$ | async )?.value !== null">
                                    *{{ dateOfBirthValidationMessage$ | async }}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="header-field col5">Status
                        <div class="input-wrapper filter-item">
                            <select appFilter="Status" name="status" id="status"
                                [(ngModel)]="patientFilter_status"
                                class="form-input  extrawide" placeholder="Please Select">
                                <option value="All">All</option>
                                <option value="Invite" *ngIf = "config?.features?.ShowSurgeries">Invited</option>
                                <option value="Active">Activated</option>
                                <option value="RemoteSignupRequested" *ngIf = "config?.features?.ShowSurgeries">Remote signup requested</option>
                                <option value="RemoteSignupConfirmed" *ngIf = "config?.features?.ShowSurgeries">Remote signup confirmed</option>
                            </select>
                        </div>
                    </div>
                    <div class="header-field search-field col6">
                        <!--<span class="search-header-field">Find a PlusOne {{CustomerText | lowercase}}</span>-->
                        <button class="btn-default-blue btn-text" (click)="searchPatient()">Search</button>
                        <a class="clear-button" (click)="clearSearch()">Clear</a>
                    </div>

                </div>

                <app-loader></app-loader>

                <cdk-virtual-scroll-viewport class="list-container" [itemSize]="60">
                    <div *ngIf="(hasSearched$ | async) === false || (datacontroller.validFilters$ | async) === false" class="patient-list-des">
                        <p>Find patients using the filters above:</p>
                    </div>
                    <div *ngIf="(datacontroller.datasource._errorData$ | async) === null">
                        <ng-container *cdkVirtualFor="let record of datacontroller.datasource; let i = index" [ngSwitch]="getRowClass(record)">
                            <ng-container class="patients" *ngSwitchCase="'PatientList'">
                                <div class="patient gridrow">
                                    <div class="paient-grid-layout col1">
                                        <div class="patient-name-col">
                                        <div class="patient-name-item" [routerLink]="['/patients', record?.patientCode_MedAdvisorGuid]">
                                            <div class="patient-name-bold">
                                                <span class="first-name">{{record.firstName}}</span><span>&nbsp;</span>
                                                <span class="last-name">{{record.lastName}}</span>
                                            </div>
                                            <div *ngIf="config?.features?.CarerMode" class="carer-mode">{{displayCarerMode(record)}}</div>
                                        </div>
                                        <div>{{displayPhone(record)}}</div>
                                        <div><b>{{record?.practiceName ? 'GP: ': ''}}</b> {{formatGPName(record)}}</div>
                                        </div>
                                    </div>
                                    <div *ngIf ="config?.features?.ShowSurgeries" class="patientrow col2">
                                        <div class="row-details">{{displayNSHNumber(record)}}</div>
                                    </div>
                                    <div class="patientrow col3">
                                        <div class="row-details">{{displayPostCode(record)}}</div>
                                    </div>

                                    <div class="patientrow col4">
                                        <div class="row-details">{{displayDOB(record) | date: 'dd/MM/yyyy'}}</div>
                                    </div>
                                    <div class="patientrow col5">
                                        <div class="row-details">{{displayStatus(record)}}</div>
                                    </div>
                                    <div class="patientrow col6">
                                        <div *ngIf="displayStatus(record) !== 'Invited'" class="row-details">
                                            <button class="btn-default-blue btn-text manage-button" [routerLink]="['/patients', record?.patientCode_MedAdvisorGuid]">
                                                View Patient
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container class="patient" *ngSwitchDefault>
                                <app-msg-loadingrequest class="patient gridrow"></app-msg-loadingrequest>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="(datacontroller.datasource.numberOfResults$ | async) === 0" class="patient gridrow">
                            No Result
                        </div>
                        <div *ngIf="(datacontroller.datasource._errorData$ | async)"   class="patient gridrow data-row-error ">
                            <fa name="exclamation-triangle"></fa>
                            {{ (datacontroller.datasource._errorData$ | async).userFriendlyErrorMessage }}
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
  </div>