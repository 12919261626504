<app-basic-dialog
  [dialogTitle]="headerTitle"
  [buttonAcceptTitle]="submitButton"
  [buttonCancel]="editButton"
  (OnComplete)="listenAction($event)"
  [buttonAcceptState] ="formState"
>
  <div class="booking-dialog-body">
    <!--  Booking form-->
    <form [formGroup]="manageBookingForm">

        <!--   Patient detail title-->
        <div class="section-dialog-title">Patient details</div>

        <!--   Patient detail body-->
        <div class="patient-booking">

          <!--   Patient detail body left-->
          <div class="patient-booking__left">

            <!-- Patient Name-->
            <div class="item-control patient-full-name">
              <mat-label class="input-dialog-label">{{bookingText.patientName}} *</mat-label>
              <div class="d-flex flex-space-between">
                <div class="d-flex flex-column">
                  <mat-form-field appearance="outline" class="patient-first-name">
                    <input matInput #firstNameInput
                           [formControl]="patientFirstNameControl"
                           [placeholder]="bookingText.patientFirstName">
                  </mat-form-field>
                  <div class="errorMessage bookingDialogMessage" *ngIf="(manageBookingForm.controls['firstName'].invalid
                                    && manageBookingForm.controls['firstName'].touched
                                    && manageBookingForm.controls['firstName'].errors.required)">
                    Please provide patient first name
                  </div>
                  <div class="errorMessage bookingDialogMessage" *ngIf="manageBookingForm.controls['firstName'].invalid
                                    && manageBookingForm.controls['firstName'].touched
                                    && manageBookingForm.controls['firstName'].errors.minlength
                                    && !manageBookingForm.controls['firstName'].errors.required">
                    Minimum 2 characters allowed.
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <mat-form-field appearance="outline" class="patient-last-name">
                    <input matInput
                           [formControl]="patientLastNameControl"
                           [placeholder]="bookingText.patientLastName">
                  </mat-form-field>
                  <div class="errorMessage bookingDialogMessage" *ngIf="(manageBookingForm.controls['lastName'].invalid
                                      && manageBookingForm.controls['lastName'].touched
                                      && manageBookingForm.controls['lastName'].errors.required)">
                      Please provide patient last name
                    </div>
                  <div class="errorMessage bookingDialogMessage" *ngIf="manageBookingForm.controls['lastName'].invalid
                                    && manageBookingForm.controls['lastName'].touched
                                    && manageBookingForm.controls['lastName'].errors.minlength
                                    && !manageBookingForm.controls['lastName'].errors.required">
                    Minimum 2 characters allowed.
                  </div>
                </div>
                <button class="btn-soft btn-icon"
                        aria-label="Select a patient"
                        [disabled]="isPatientListNotLoaded"
                        type="button"
                        (click)="handleSearchPatient()">
                  <span class="material-icons">search</span>
                </button>
              </div>
            </div>

            <!--  Date of birth-->
            <div class="item-control">
              <mat-label class="input-dialog-label">Date of birth</mat-label>
              <mat-form-field appearance="outline" class="patient-dob" [class.hasValue]="pickerInput?.value">
                <input matInput
                       [formControl]="dobControl"
                       [matDatepicker]="picker"
                       [max]="maxDate"
                       [min]="minDate"
                       #pickerInput
                       (keydown)="handleDateInput($event)"
                       autocomplete="off"
                       placeholder="dd/mm/yyyy">
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>event</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker panelClass="app-booking-dialog-date-picker"></mat-datepicker>
              </mat-form-field>

              <div class="successMessage bookingDialogMessage"
                   *ngIf="manageBookingForm.controls['dob'].value && manageBookingForm.controls['dob'].valid && manageBookingForm.controls['dob'].touched && pickerInput.value">
                <span>Date of birth is valid</span>
              </div>
              <div class="errorMessage bookingDialogMessage"
                   *ngIf="manageBookingForm.controls['dob'].touched && pickerInput.value">
                {{getErrorMessage(pickerInput.value, 'dob', dobValidation, dobErrorMsg)}}
              </div>
            </div>

            <!--  Email-->
            <div class="item-control patient-email mt-2">
              <mat-label class="input-dialog-label">{{bookingText.patientEmail}} *</mat-label>
              <mat-form-field appearance="outline" class="patient-email" [class.hasValue]="emailInput?.value">
                <input matInput
                       #emailInput
                       [formControl]="emailControl"
                       [placeholder]="bookingText.patientEmail">
              </mat-form-field>
              <div class="errorMessage bookingDialogMessage"
                   *ngIf="manageBookingForm.controls['email'].invalid && manageBookingForm.controls['email'].touched">
                <span
                  *ngIf="manageBookingForm.controls['email'].errors.required">Please provide patient's email address</span>
                <span *ngIf="manageBookingForm.controls['email'].errors.emailFormat">Enter a valid email address</span>
              </div>
              <div class="successMessage bookingDialogMessage"
                   *ngIf="manageBookingForm.controls['email'].valid && manageBookingForm.controls['email'].touched">
                <span>Email is valid</span>
              </div>
            </div>

            <!--  Mobile-->
            <div class="item-control">
              <mat-label class="input-dialog-label">{{bookingText.patientPhone}} *</mat-label>
              <mat-form-field appearance="outline" class="patient-mobile" [class.hasValue]="mobileInput?.value">
                <input matInput
                       #mobileInput
                       [formControl]="mobileControl"
                       [placeholder]="bookingText.patientPhone">
              </mat-form-field>
              <div class="errorMessage bookingDialogMessage"
                   *ngIf="manageBookingForm.controls['mobile'].invalid && manageBookingForm.controls['mobile'].touched">
                <span *ngIf="manageBookingForm.controls['mobile'].errors.required">Please provide patient's mobile number</span>
                <span *ngIf="manageBookingForm.controls['mobile'].errors.mobileNoFormat">Enter a valid mobile number in the form of +447xxxxxxxxx or 07xxxxxxxxx</span>
              </div>
              <div class="successMessage bookingDialogMessage"
                   *ngIf="manageBookingForm.controls['mobile'].valid && manageBookingForm.controls['mobile'].touched">
                <span>Mobile number is valid</span>
              </div>
            </div>
          </div>

          <!--   Patient detail body right-->
          <!--  Notes-->
          <div class="patient-booking__right">
            <div class="d-flex flex-space-between input-dialog-label">
              <mat-label>
                {{bookingText.patientNoteTitle}}
              </mat-label>
              <mat-label *ngIf="bookingRef">
                Booking No: {{bookingRef}}
              </mat-label>
            </div>
            <textarea
                      [formControl]="notesControl"
                      [placeholder]="bookingText.patientNotes"></textarea>
          </div>

        </div>
        <hr>
        <div class="section-dialog-title">Booking details</div>
      <!--    Booking detail-->
      <div class="booking-details">

        <!--      Booking detail title-->
        <div class="booking-details__content">
          <!--        Room/Resource - service type-->
          <div class="d-flex resource-service">
            <!--        Room/Resource-->
            <div class="item-control resource">
              <mat-label class="input-dialog-label">{{bookingText.room}}</mat-label>
              <ng-select
                class="select-resource-booking"
                [searchable]="false"
                [clearable]="false"
                (change)="handleSelectResource($event)"
                (focusout)="handleResourceError()"
                [ngClass]="{ 'is-invalid': manageBookingForm.get('resource').invalid && manageBookingForm.get('resource').touched }"
                [formControl]="resourceControl">
                <ng-option  [hidden]="true" [value]="0">Please select</ng-option>
                <ng-option  *ngFor="let resource of resources" [value]="resource.id">{{resource.title}}
                </ng-option>
              </ng-select>

              <div class="errorMessage bookingDialogMessage warning"
                   *ngIf="manageBookingForm.controls['resource'].touched &&
                          manageBookingForm.controls['resource'].value === 0">
                <span>Please select a room or resource</span>
              </div>
            </div>

            <!--        Service type-->
            <div class="item-control service-type">
              <mat-label class="input-dialog-label">{{bookingText.serviceTypeTitle}}</mat-label>
              <div [ngSwitch]="handleSelectServiceByResource" class="service-type-input">
                <div *ngSwitchCase="bookingText.templateNoServiceAvailable">
                  <button type="button" class="btn-soft btn-text btn-w-100" (click)="handleOpenSettingModal()">Please Select Available Services</button>
                </div>

                <ng-container *ngSwitchCase="bookingText.templateServiceAvailable">
                  <ng-select
                    class="select-service-type"
                    [searchable]="false"
                    [clearable]="false"
                    (focusout)="handleServiceError()"
                    (change)="handleSelectServiceOrSelectDate($event)"
                    [ngClass]="{ 'is-invalid': manageBookingForm.get('service').invalid && manageBookingForm.get('service').touched }"
                    [formControl]="serviceControl">
                    <ng-option  [hidden]="true" [value]="0" [disabled]="deletedServiceId">Please select</ng-option>
                    <ng-option  *ngFor="let service of servicesAvailable"
                                [disabled]="deletedServiceId === service?.id"
                                [value]="service?.id">{{service?.friendlyName}}
                    </ng-option>
                  </ng-select>
                  <div class="errorMessage bookingDialogMessage warning"
                       *ngIf="manageBookingForm.controls['service'].touched &&
                              manageBookingForm.controls['service'].value === 0">
                    <span>Please select a service</span>
                  </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <ng-select
                    class="select-service-type"
                    [searchable]="false"
                    [clearable]="false">
                  </ng-select>
                </ng-container>
              </div>
            </div>

          </div>

          <!--  Date time - duration-->
          <div class="d-flex date-time-duration item-center">
            <!--Date and time-->
            <div class="d-flex">
              <div class="item-control date-time">
                <mat-label class="input-dialog-label">Date and time *</mat-label>
                <mat-form-field appearance="outline" class="date-field">
                  <input matInput
                         [matDatepicker]="dateTimePicker"
                         [formControl]="dateControl"
                         (keydown)="handleDateInput($event)"
                         #dateInput
                         autocomplete="off"
                         (dateChange)="handleDateChange($event)"
                         placeholder="dd/mm/yyyy">
                  <mat-datepicker-toggle matSuffix [for]="dateTimePicker"></mat-datepicker-toggle>
                  <mat-datepicker #dateTimePicker panelClass="app-booking-dialog-date-picker"></mat-datepicker>
                </mat-form-field>
                  <div class="errorMessage bookingDialogMessage messageTop"
                       *ngIf="manageBookingForm.controls['date'].invalid && manageBookingForm.controls['date'].touched">
                    {{getErrorMessage(dateInput.value, 'date', dateValidation, dateErrorMsg)}}
                  </div>
                  <div class="successMessage bookingDialogMessage messageTop"
                     *ngIf="manageBookingForm.controls['date'].valid && manageBookingForm.controls['date'].touched">
                  <span>Booking date is valid</span>
                </div>
              </div>
              <div class="item-control picker-time">
                <timepicker [(ngModel)]="time"
                            #timePicker
                            (ngModelChange)="onTimeChange($event)"
                            class="timepicker"
                            [class.hour-invalid]="timePicker.hours === ''"
                            [class.min-invalid]="timePicker.minutes === ''"
                            [class.form-picker-invalid]="timePicker.hours === '' && timePicker.minutes === ''"
                            name="timepicker"
                            [showMeridian]="true"
                            [mousewheel]="false"
                            [formControl]="timeControl"
                            [minuteStep]="5"></timepicker>
                <div class="errorMessage bookingDialogMessage messageTop"
                     *ngIf="timePicker.hours === '' || timePicker.minutes === ''">
                  Please select time for the booking
                </div>
                <div class="errorMessage bookingDialogMessage messageTop "
                     *ngIf="timePicker.minutes > 59 || timePicker.minutes < 0">
                  Please select valid time for the booking
                </div>
                <div class="errorMessage bookingDialogMessage messageTop"
                     *ngIf="timePicker.hours > 12 || timePicker.hours < 0">
                  Please select valid time for the booking
                </div>
              </div>
            </div>
            <!--Duration-->
            <div class="d-flex duration">
              <div class="item-control">
                <mat-label class="input-dialog-label">Duration</mat-label>
                <ng-select
                  class="select-duration"
                  [searchable]="false"
                  [clearable]="false"
                  (change)="handleDurationChange($event)"
                  [formControl]="durationControl">
                  <ng-option  *ngFor="let time of durations" [value]="time">{{time + ' min'}}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>

          <!--Time line-->
          <div class="time-line" *ngIf="shouldShowAvailabilitySection">
            <mat-label class="input-dialog-label">Check availability</mat-label>
            <div class="timeline-box">

              <div class="timeline" [style.height.px]="(openHourRange.length -1) * 90 > 735 ? 106 : 89">
                <div class="timeline-header">{{dateFormated}} ({{selectedResource?.title}})</div>
                <div class="timeline-content">
<!--                  check hour-indicator-middle class -->
                    <table [style.width.px]="(openHourRange.length -1) * 90">
                      <thead>
                        <tr>
                          <th *ngFor="let hour of openHourRange; let i = index" colspan="12" [class.first-hour]="i === 0" class="top-range">
                            <span [class.before-last-hour]="i === openHourRange.length -1">{{hour}}</span>
<!--                            <span [class.last-hour]="i === openHourRange.length -1" *ngIf="i === openHourRange.length -1">{{generateLastHour(i, hour)}}</span>-->
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th *ngFor="let hour of openHourGapRange" colspan="6"></th>
                        </tr>
                        <tr>
                          <td class="hour-indicator-middle"
                              *ngFor="let min of numberPartOfMinutes" #hourIndicator></td>
                        </tr>
                        <tr>
                          <th *ngFor="let hour of openHourGapRange" colspan="6"></th>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>

              <div class="timeline-instruction">
                      <div class="d-flex">
                        <div class="timeline-sample out-of-booking"></div>
                        <p>Unavailable / booked out times</p>
                      </div>
                      <div class="d-flex">
                        <div class="timeline-sample current-booking"></div>
                        <p>Current booking</p>
                      </div>
              </div>

            </div>
          </div>

          <!--Email and SMS confirmation-->
          <div class="item-control-confirmation">
            <div class="label"
                       title="SMS confirmation">
              Send confirmation
            </div>
            <mat-checkbox
              [formControl]="sendConfirmationControl"
              [checked]="shouldSendEmail"
              title="SMS confirmation">
            </mat-checkbox>
              <div class="d-flex flex-column">
              <div class="instruction">Send confirmation by Email and SMS directly after booking created</div>
                <i>SMS charges apply, up to 2 SMS credits</i>
              </div>
          </div>

          <!--SMS reminder-->
          <div class="d-flex item-control-confirmation" *ngIf="shouldShowReminderCheckbox">
            <div  class="label"
                  title="SMS reminder">
              Send reminder
            </div>
            <mat-checkbox
              [formControl]="sendReminderControl"
              [checked]="shouldSendReminder"
              title="SMS reminder">
            </mat-checkbox>
            <div class="d-flex flex-column">
              <div class="instruction">Send reminder 24 hours before booking date by Email and SMS</div>
              <i>SMS charges apply, up to 2 SMS credits</i>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <iframe id="bookingFormFrame" name="bookingFormFrame" src="about:blank"></iframe>
  <div id="booking-print-section">
    <app-flu-vax-form [prescreenResults]="prescreenFormModel">
    </app-flu-vax-form>
  </div>
</app-basic-dialog>
<ng-template #deleteBookingContent>
  <div class="delete-content-dialog">
    <div><span class="material-icons icon-warning">warning</span></div>
    <p>{{bookingText.deleteDialogContent}}</p>
  </div>
</ng-template>

<ng-template #MoreOption>
  <button mat-button
          class="btn-text btn-more-option"
          [matMenuTriggerFor]="aboveMenu">More options <span class="material-icons">expand_less</span></button>
  <mat-menu #aboveMenu="matMenu" yPosition="above" class="menuItem" panelClass>
    <button mat-menu-item *ngIf="hasPreForm" (click)="printForm()">
      <span class="btn-title">
        Print pre-screening form
      </span>
    </button>
    <button mat-menu-item (click)="createNewBooking()">
      <span class="btn-title">
        Create a follow-up booking
      </span>
    </button>
    <button mat-menu-item (click)="deleteBooking()">
      <span class="btn-title">
        Delete booking
      </span>
    </button>
  </mat-menu>
</ng-template>
